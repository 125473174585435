import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import game from '../../../controller/GameController';
import { au, v } from '../../../koko-framework/shorthand';
import AdvisorIcon from '../../../assets/babcock_menus/in_game/dialog/advisor_dialogue.png';
import DialogArrow from '../../../assets/babcock_menus/in_game/dialog/dialogue_scroll.png';
import PositionableReactView from "../../../koko-framework/ui/react-views/PositionableReactView";
import { PANEL_STATE } from "../../../Bootstrap";
import WorldController from "../../../controller/WorldController";
import { CHANGE_CLOTHES_TASK, getDialogDataFromId, getEntityDataFromId, NEIGHBOUR_NPC } from "../../model/constants";
import { GameState, ProgressBadge, SetNewCvItem } from "../../model/GameState";
import BabcockController from "../../controller/BabcockController";
import SendCvButton from '../../../assets/babcock_menus/in_game/dialog/accept_btn.png';
import CloseButton from '../../../assets/babcock_menus/close_btn.png';
import CvIcon from '../../../assets/babcock_menus/in_game/dialog/CV_dialogue.png';
import { DONT_SEND_CV_DIALOG, SEND_CV_DIALOG } from "../../model/dialog";
import { AIMING_HIGH } from "../../model/badges-config";

export default class SendCVPrompt extends ReactFullScreenView {
    typeId = -1;

    constructor(props) {
        super(props);

        // Fix this panel to the bottom middle. Our css class bottom-panels-bg positions the panel relative to that.
        this.state.left = '50%';
        this.state.top = '100%';

        this.resize();
    }

    closePanel = () => {
        v.hideView(this.props.viewId);
        WorldController.enable();

        BabcockController.showInteractionDialog('Don\'t send CV!', DONT_SEND_CV_DIALOG);

        au.play('closebtn', 1, false, 0, false);
    }

    sendCv = () => {
        ProgressBadge(AIMING_HIGH.id, 1);
        SetNewCvItem(true);

        v.hideView(this.props.viewId);
        GameState.applicationSent = true;
        BabcockController.showInteractionDialog('Send CV!', SEND_CV_DIALOG, 'phoneringing');
        PANEL_STATE.contents = 'Babcock International';

        au.play('primarybtn', 1, false, 0, false);
    }

    renderContent() {
        return (
            <div className="full-screen-holder no-select default-cursor rel">
                <div className="bottom-panels-bg no-select default-cursor flex-rows" style={{ left: this.state.left, top: this.state.top }}>
                    <div className="flex-columns">
                        <div className="shrink">
                            <img className="cv-dialog-icon" src={CvIcon} alt="CV Icon" />
                        </div>
                        <div className="grow"></div>
                        <div className="grow">
                            <div className="view-row grow centered">
                                <p className="grey-info-text cv-prompt-text bold-text">Send your CV?</p>
                            </div>
                            <div className="view-row grow">
                                <img className="cv-prompt-button active-button" src={SendCvButton} onClick={this.sendCv} alt="Click to send your CV" />
                            </div>
                        </div>
                        <div className="grow"></div>
                        <div className="grow"></div>
                    </div>
                    <div className="abs" style={{ right: -10, top: -10 }}>
                        <img className="close-button active-button" src={CloseButton} onClick={this.closePanel} alt="Click to close this panel and send your CV later" />
                    </div>
                </div>
            </div>
        );
    }
}