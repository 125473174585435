import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import game from '../../../controller/GameController';
import { au, v } from '../../../koko-framework/shorthand';
import WorldController from "../../../controller/WorldController";

import AdvisorIcon from '../../../assets/babcock_menus/questions/advisor.png';
import ContinueButton from '../../../assets/babcock_menus/questions/continue_btn.png';

import SubmitButtonActive from '../../../assets/babcock_menus/questions/submit_active.png';
import SubmitButtonInactive from '../../../assets/babcock_menus/questions/submit_inactive.png';
import BabcockController from "../../controller/BabcockController";
import { GameState, ProgressBadge } from "../../model/GameState";
import ApiController from "../../controller/ApiController";

import ReactGA from 'react-ga4';

export const MULTIPLE_CHOICE = 0;
export const SLIDER = 1;
const DUMMY_QUESTION = {
    type: MULTIPLE_CHOICE,
    question: 'Do you have any ways of helping to maintain concentration? ',
    answers: [
        'Making sure you are comfortable, and avoiding distractions e.g. putting phone on flight mode',
        'Always listening to music/watching tv while you work so you don\'t get bored',
        'Trying to concentrate for long periods of time without any breaks'
    ],
}

export default class QuestionPanel extends ReactFullScreenView {
    constructor(props) {
        super(props);

        this.state.stage = 0;
        this.state.question = BabcockController.activeQuestion.questionData /* DUMMY_QUESTION*/ ; 
        this.state.selectedAnswer = -1;
    }

    componentDidMount() {
        super.componentDidMount();

        BabcockController.pauseCharacter();

        ReactGA.send({ hitType: "pageview", page: "/advisor-question/" });
    }

    closePanel = () => {
        if (this.state.selectedAnswer > -1) {
            v.hideView(this.props.viewId);
            // WorldController.enable();
            BabcockController.showInteractionDialog('After question', BabcockController.activeQuestion.feedback);

            GameState.questionsAnswered.push(BabcockController.activeQuestion);
            if (this.state.question.progressBadge[this.state.selectedAnswer] !== null) {
                ProgressBadge(this.state.question.progressBadge[this.state.selectedAnswer].id);
            }
            BabcockController.activeQuestion = null;

            au.play('primarybtn', 1, false, 0, false);

            // BabcockController.unpauseCharacter();

            ApiController.triggerGameDataDump();
        }
    }

    continueToQuestion = () => {
        this.setState({stage: 1});

        au.play('secondarybtn', 1, false, 0, false);
    }

    selectAnswerHandler = (a) => {
        this.setState({selectedAnswer: a});
        BabcockController.activeQuestion.chosenAnswer = a;

        au.play('secondarybtn', 1, false, 0, false);
    }

    renderContent() {
        let activeButtonClass = this.state.selectedAnswer === -1 ? '' : ' active-button';
        return (
            <div className="full-screen-holder black-out no-select default-cursor">
                <div className="gen-white-panel mid-panel">
                    <div className="questions-top">
                        <img className="questions-advisor" src={AdvisorIcon} alt="Skills Launchpad Advisor Portrait" />
                    </div>
                    {this.state.stage === 0 && <QuestionIntro continueFunction={this.continueToQuestion}></QuestionIntro>}
                    {this.state.stage === 1 &&
                    <>

                        <div className="view-row grow">
                            <div className="grow centered">
                                <p className="questions-blurb bold-text">{this.state.question.question}</p>
                            </div>
                        </div>

                        {this.state.question.type === MULTIPLE_CHOICE &&
                            <MultipleChoiceQuestion {...this.state} selectAnswerHandler={this.selectAnswerHandler}></MultipleChoiceQuestion>
                        }
                        {this.state.question.type === SLIDER &&
                            <SliderQuestion {...this.state} selectAnswerHandler={this.selectAnswerHandler}></SliderQuestion>
                        }

                        <div className="view-row grow"></div>

                        <div className="view-row grow">
                            <img className={"questions-continue-button" + activeButtonClass} src={this.state.selectedAnswer === -1 ? SubmitButtonInactive : SubmitButtonActive} onClick={this.closePanel} alt={this.state.selectedAnswer === -1 ? 'Please choose your answer above' : 'Click to submit your answer'} />
                        </div>

                        <div className="view-row grow"></div>
                    </>
                    }
                </div>
            </div>
        );
    }
}

class QuestionIntro extends React.Component {
    render() {
        return (
            <>
                <div className="view-row shrink">
                    <p className="blue-header bold-text">Question Time!</p>
                </div>
                <div className="view-row shrink">
                    <div className="grow centered">
                        <p className="questions-blurb">We need to help build your profile so<br />potential employers can learn a little<br />more about <span className="bold-text">YOU</span>.</p>
                    </div>
                </div>
                <div className="view-row shrink">
                    <div className="grow centered">
                        <p className="questions-blurb">Answer the following question to<br />help us out.</p>
                    </div>
                </div>
                <div className="view-row grow"></div>
                <div className="view-row grow"></div>
                <div className="view-row shrink">
                    <img className="questions-continue-button active-button" src={ContinueButton} onClick={this.props.continueFunction} alt="Click to continue" />
                </div>
                <div className="view-row grow"></div>
            </>
        )
    }
}

class MultipleChoiceQuestion extends React.Component {
    render() {
        let answerClasses = [];
        for (let i = 0; i < 4; i++) {
            if (this.props.selectedAnswer === i) {
                answerClasses.push('question-answer-selected');
            } else {
                answerClasses.push(this.props.selectedAnswer === -1 ? 'question-answer-default' : 'question-answer-unselected');
            }
        }
        return (
            <>
                {this.props.question.answers.length === 4 &&
                    <>
                        <div className="view-row grow">
                            <div className="flex-columns questions-buttons-holder">
                                <div className="shrink">
                                    <div className={"questions-mc-narrow " + answerClasses[0]} onClick={() => this.props.selectAnswerHandler(0)}>
                                        <div className="mc-answer-text">
                                            {this.props.question.answers[0]}
                                        </div>
                                    </div>
                                </div>

                                <div className="grow"></div>

                                <div className="shrink">
                                    <div className={"questions-mc-narrow " + answerClasses[1]} onClick={() => this.props.selectAnswerHandler(1)}>
                                        <div className="mc-answer-text">
                                            {this.props.question.answers[1]}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="view-row grow">
                            <div className="flex-columns questions-buttons-holder">
                                <div className="shrink">
                                    <div className={"questions-mc-narrow " + answerClasses[2]} onClick={() => this.props.selectAnswerHandler(2)}>
                                        <div className="mc-answer-text">
                                            {this.props.question.answers[2]}
                                        </div>
                                    </div>
                                </div>

                                <div className="grow"></div>

                                <div className="shrink">
                                    <div className={"questions-mc-narrow " + answerClasses[3]} onClick={() => this.props.selectAnswerHandler(3)}>
                                        <div className="mc-answer-text">
                                            {this.props.question.answers[3]}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
                {this.props.question.answers.length === 3 &&
                    <>
                        <div className="view-row grow">
                            <div className="grow">
                                <div className={"questions-mc-wide " + answerClasses[0]} onClick={() => this.props.selectAnswerHandler(0)}>
                                    <div className="mc-answer-text">
                                        {this.props.question.answers[0]}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="view-row grow">
                            <div className="grow">
                                <div className={"questions-mc-wide " + answerClasses[1]} onClick={() => this.props.selectAnswerHandler(1)}>
                                    <div className="mc-answer-text">
                                        {this.props.question.answers[1]}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="view-row grow">
                            <div className="grow">
                                <div className={"questions-mc-wide " + answerClasses[2]} onClick={() => this.props.selectAnswerHandler(2)}>
                                    <div className="mc-answer-text">
                                        {this.props.question.answers[2]}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </>
        );
    }
}

class SliderQuestion extends React.Component {
    render() {
        let answerClasses = [];
        for (let i = 0; i < 4; i++) {
            if (this.props.selectedAnswer === i) {
                answerClasses.push('question-slider-selected');
            } else {
                answerClasses.push(this.props.selectedAnswer === -1 ? 'question-slider-default' : 'question-slider-unselected');
            }
        }
        return (
            <>
                <div className="view-row shrink">
                    <div className="flex-columns question-slider-holder question-slider-bg">
                        <div className="question-slider-opt-bg q-opt-1"></div>
                        <div className="question-slider-opt-bg q-opt-2"></div>
                        <div className="question-slider-opt-bg q-opt-3"></div>
                        <div className="question-slider-opt-bg q-opt-4"></div>

                        <div className={"question-slider-opt q-opt-1 " + answerClasses[0]} onClick={() => this.props.selectAnswerHandler(0)}></div>
                        <div className={"question-slider-opt q-opt-2 " + answerClasses[1]} onClick={() => this.props.selectAnswerHandler(1)}></div>
                        <div className={"question-slider-opt q-opt-3 " + answerClasses[2]} onClick={() => this.props.selectAnswerHandler(2)}></div>
                        <div className={"question-slider-opt q-opt-4 " + answerClasses[3]} onClick={() => this.props.selectAnswerHandler(3)}></div>
                    </div>
                </div>
                <div className="view-row shrink">
                    <div className="flex-columns question-slider-holder">
                        <div className={"question-slider-opt-text q-opt-1 " + answerClasses[0] + " slider-text"}>Strongly<br />Disagree</div>
                        <div className={"question-slider-opt-text q-opt-2 " + answerClasses[1] + " slider-text"}>Disagree</div>
                        <div className={"question-slider-opt-text q-opt-3 " + answerClasses[2] + " slider-text"}>Agree</div>
                        <div className={"question-slider-opt-text q-opt-4 " + answerClasses[3] + " slider-text"}>Strongly<br />Agree</div>
                    </div>
                </div>
            </>
        )
    };
}