import { au } from "../shorthand";
import app from '../app'
import * as PIXI from 'pixi.js'

import gsap from "gsap";

const OVER_SCALE = 1.03;
const CLICK_CHECK = {canClick: true};
export function setupClicker(buttonDisplayObj, clickHandler, analyticsId = '', addOverState = true, buttonMode = true, sound = '') {
    if (addOverState) {
        centerAnchor(buttonDisplayObj);
    }

    let f = (e) => {
        // console.log('click function');
        if (CLICK_CHECK.canClick) {
            // console.log('click accepted');
            CLICK_CHECK.canClick = false;
            setTimeout(_enableClick, 20);
            clickHandler(e);
            if (sound !== '') {
                au.play(sound, 1);
            }
            if (window.ga && analyticsId !== '') {
                window.ga('send', 'event', 'game', 'button_click', analyticsId);
            }
        }
    }
    buttonDisplayObj.interactive = true;
    buttonDisplayObj.on('pointerdown', f);
    buttonDisplayObj.on('mousedown', f);
    buttonDisplayObj.on('touchstart', f);
    buttonDisplayObj.buttonMode = buttonMode;

    if (addOverState) {
        let startScale = buttonDisplayObj.scale.x;
        let overScale = startScale * OVER_SCALE;

        let over_f = () => {
            buttonDisplayObj.scale.x = buttonDisplayObj.scale.y = startScale;
            gsap.killTweensOf(buttonDisplayObj.scale);
            gsap.to(buttonDisplayObj.scale, {x: overScale, y: overScale, duration: 0.1});
        }
        buttonDisplayObj.on('mouseover', over_f);

        let out_f = () => {
            buttonDisplayObj.scale.x = buttonDisplayObj.scale.y = overScale;
            gsap.killTweensOf(buttonDisplayObj.scale);
            gsap.to(buttonDisplayObj.scale, {x: startScale, y: startScale, duration: 0.1});
        }
        buttonDisplayObj.on('mouseout', out_f);
    }
}

function _enableClick() {
    // console.log('enabling click');
    CLICK_CHECK.canClick = true;
}

var _muteButton = null;
var _onTextureId = null;
var _offTextureId = null;
export function setupMuteToggle(buttonDisplayObj, onTextureId, offTextureId, sound = '') {
    _muteButton = buttonDisplayObj;
    _onTextureId = onTextureId;
    _offTextureId = offTextureId;

    centerAnchor(buttonDisplayObj);

    let f = () => {
        // console.log('click function');
        if (CLICK_CHECK.canClick) {
            // console.log('click accepted');
            CLICK_CHECK.canClick = false;
            setTimeout(_enableClick, 20);
            au.toggleMute();
            _setMuteButtonTexture();
            if (sound !== '') {
                au.play(sound, 1);
            }

            if (window.ga) {
                window.ga('send', 'event', 'game', 'button_click', 'mute_toggle', au.mute ? 'sound_off' : 'sound_on');
            }
        }
    }
    buttonDisplayObj.interactive = true;
    buttonDisplayObj.on('pointerdown', f);
    buttonDisplayObj.on('mousedown', f);
    buttonDisplayObj.on('touchstart', f);
    buttonDisplayObj.buttonMode = true;
    _setMuteButtonTexture();

    let startScale = buttonDisplayObj.scale.x;
    let overScale = startScale * OVER_SCALE;

    let over_f = () => {
        buttonDisplayObj.scale.x = buttonDisplayObj.scale.y = startScale;
        gsap.killTweensOf(buttonDisplayObj.scale);
        gsap.to(buttonDisplayObj.scale, {x: overScale, y: overScale, duration: 0.1});
    }
    buttonDisplayObj.on('mouseover', over_f);

    let out_f = () => {
        buttonDisplayObj.scale.x = buttonDisplayObj.scale.y = overScale;
        gsap.killTweensOf(buttonDisplayObj.scale);
        gsap.to(buttonDisplayObj.scale, {x: startScale, y: startScale, duration: 0.1});
    }
    buttonDisplayObj.on('mouseout', out_f);
}

function _setMuteButtonTexture() {
    if (au.mute) {
        _muteButton.texture = PIXI.Texture.from(_offTextureId);
    } else {
        _muteButton.texture = PIXI.Texture.from(_onTextureId);
    }
}

function centerAnchor(displayObject) {
    if (displayObject.anchor) {
        if (displayObject.anchor.x !== 0.5) {
            let xOff = (0.5 - displayObject.anchor.x) * displayObject.width;
            displayObject.anchor.x = 0.5;
            displayObject.x += xOff;
        }
        if (displayObject.anchor.y !== 0.5) {
            let yOff = (0.5 - displayObject.anchor.y) * displayObject.height;
            displayObject.anchor.y = 0.5;
            displayObject.y += yOff;
        }
    }
}

// this is good for bypassing safari's pop up blocker when linking to web pages in _blank windows
const LIVE_HTML_CLICKERS = [];
export function setHtmlClicker(displayObject, clickHandler, buttonMode, analyticsId = '', label = '') {
    let el = document.createElement('div');
    el.style.backgroundColor = '#770000';
    el.style.opacity = 0;
    el.style.position = 'fixed';
    el.style.zIndex = '100000';
    if (label !== '') {
        el.ariaLabel = label
    }
    positionElement(displayObject, el);
    document.body.appendChild(el);

    if (buttonMode) {
        el.style.cursor = 'pointer';
    }

    el.onclick = () => {
        if (window.ga && analyticsId !== '') {
            window.ga('send', 'event', 'game', 'button_click', analyticsId);
        }
        clickHandler();
    }

    let oneClicker = {
        displayObject: displayObject,
        el: el,
    }
    LIVE_HTML_CLICKERS.push(oneClicker);

    if (typeof(app.resizeListeners['htmlClickers']) === 'undefined') {
        app.addResizeListener('htmlClickers', resizeHtmlClickers);
    }
}

export function removeHtmlClickers() {
    for (let i = 0; i < LIVE_HTML_CLICKERS.length; i++) {
        let clicker = LIVE_HTML_CLICKERS.pop();
        document.body.removeChild(clicker.el);
    }
    app.removeResizeHandler('htmlClickers');
}

function resizeHtmlClickers() {
    for (let i = 0; i < LIVE_HTML_CLICKERS.length; i++) {
        positionElement(LIVE_HTML_CLICKERS[i].displayObject, LIVE_HTML_CLICKERS[i].el);
    }
}

function positionElement(displayObject, el) {
    let bounds = displayObject.getLocalBounds();
    let topLeftGlobal = displayObject.toGlobal({x: bounds.x, y: bounds.y});
    el.style.left = topLeftGlobal.x + 'px';
    el.style.top = topLeftGlobal.y + 'px';
    el.style.width = (bounds.width * app.viewPort.s * displayObject.scale.x) + 'px';
    el.style.height = (bounds.height * app.viewPort.s * displayObject.scale.y) + 'px';
}