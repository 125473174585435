import React from 'react';
import { au, co } from '../../../../koko-framework/shorthand';

import SoundOffButton from '../../../../assets/babcock_menus/sound_off.png';
import SoundOnButton from '../../../../assets/babcock_menus/sound_on.png';

export class MuteButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            muted: au.mute,
        }
    }

    toggleMute = () => {
        au.toggleMute();
        this.setState({muted: au.mute});

        au.play('secondarybtn', 1, false, 0, false);

        let muteVal = '0';
        if (au.mute) {
            muteVal = '1';
        }
        co.set('muted', muteVal, 1000);
    }

    render() {
        let label = au.mute ? 'Click to switch sound on' : 'Click to switch sound off';
        return (
            <>
                <img className={ (this.props.settings ? "settings-mute-button" : "menu-mute-button") + " active-button"} src={this.state.muted ? SoundOffButton : SoundOnButton} onClick={this.toggleMute} alt={label} />
            </>
        )
    }
}