import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import game from '../../../controller/GameController';
import { au, v } from '../../../koko-framework/shorthand';

import InfoButton1 from '../../../assets/babcock_menus/cta_info/01info_btn.png';
import InfoButton2 from '../../../assets/babcock_menus/cta_info/02video_btn.png';
import InfoButton3 from '../../../assets/babcock_menus/careersBabcockBtn.png';
import CloseButton from '../../../assets/babcock_menus/close_btn.png';

import ReactGA from 'react-ga4';

export default class CtaInfoPanel extends ReactFullScreenView {
    componentDidMount() {
        super.componentDidMount();

        ReactGA.send({ hitType: "pageview", page: "/call-to-action/" });
    }

    closeClicked = () => {
        v.hideView('ctainfo');

        au.play('closebtn', 1, false, 0, false);
    }

    renderContent() {
        return (
            <div className="full-screen-holder no-select default-cursor white-bg rel">
                <div className="cta-header"></div>
                <div className="view-row shrink centered">
                    <p className="blue-header bold-text">Make it Big With a<br />Babcock Apprenticeship</p>
                </div>
                <div className="view-row shrink centered">
                    <p className="cta-blurb">Our highly regarded apprenticeship programme offers you a World of Opportunities’ to develop your career in a supportive environment.</p>
                </div>
                <div className="view-row shrink centered">
                    <a className="auto-margin" href="https://www.babcockinternational.com/careers/apprenticeships/" target="_blank">
                        <img className="cta-info-button active-button" src={InfoButton1} alt="Click to find out more about Babcock Apprenticeships" />
                    </a>
                </div>
                <div className="view-row shrink centered">
                    <a className="auto-margin" href="https://vimeo.com/639965387/bdd57843f9" target="_blank">
                        <img className="cta-info-button active-button" src={InfoButton2} alt="Click to watch a video about Babcock Apprenticeships" />
                    </a>
                </div>
                <div className="view-row shrink centered">
                    <a className="auto-margin" href="https://www.babcockinternational.com/careers/" target="_blank">
                        <img className="cta-info-button-bottom active-button" src={InfoButton3} alt="Click to find out more about Careers with Babcock International" />
                    </a>
                </div>
                <div className="abs" style={{right: 30, top: 20}}>
                    <img className="close-button active-button" src={CloseButton} onClick={this.closeClicked} alt="Click to close this panel" />
                </div>
            </div>
        )
    }
}