import React from 'react';
import ProgressBar from 'react-customizable-progressbar';

export class BadgeProgress extends React.Component {
    render() {
        let badgePerc = Math.round(this.props.badgeData.progress / this.props.badgeData.config.targetValue * 100);
        return (
            <>
                {this.props.badgeData.progress >= this.props.badgeData.config.targetValue &&
                    <img className="cv-badge-unlocked-icon" src={this.props.badgeData.config.unlockedIcon} alt={this.props.badgeData.config.name + ' badge earned'} />
                }
                {this.props.badgeData.progress < this.props.badgeData.config.targetValue &&
                    <>
                        <div className="cv-badge-back"></div>
                        <ProgressBar
                            progress={this.props.badgeData.progress / this.props.badgeData.config.targetValue * 100}
                            radius={70}
                            counterClockwise={false}
                            fillColor="#142659"
                            strokeWidth={16}
                            strokeColor="#D20073"
                            trackStrokeWidth={16}
                            trackStrokeColor="#142659"
                            strokeLinecap="round"
                            className="badge-progress-bar"
                        >
                        </ProgressBar>
                        <div className="cv-badge-border"></div>
                        <img className="cv-badge-locked-icon" src={this.props.badgeData.config.lockedIcon} alt={this.props.badgeData.config.name + ' badge: ' + badgePerc + ' percent complete'} />
                    </>
                }
            </>
        )
    }
}