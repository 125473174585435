import React from "react";
import { v } from '../../../koko-framework/shorthand';
import PositionableReactView from "../../../koko-framework/ui/react-views/PositionableReactView";
import BabcockController from "../../controller/BabcockController";
import { GameState } from "../../model/GameState";

export default class SkipTutorial extends PositionableReactView {

    constructor(props) {
        super(props);

        this.resize();
    }

    componentDidMount = () => {
        super.componentDidMount();

        this.setPosition('100%', '100%', false);
    }

    closePanel = () => {
        v.hideView(this.props.viewId);
    }

    renderContent() {
        return (
            <div className="rel">
                <div className="skip-tutorial-message active-button" onClick={BabcockController.skipTutorial}>
                    <p>Skip &gt;</p>
                </div>
            </div>
        );
    }
}