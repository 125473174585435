import objectPooler from "../objectPooler";
import * as PIXI from 'pixi.js';
import { OrthRenderer } from "./OrthRenderer";
import { ISOMETRIC } from './TileMapEngine'
import WorldController from "../../controller/WorldController";

/** @author Carl Trelfa
 * 
 */
export class IsoRenderer extends OrthRenderer {
    type = ISOMETRIC;
    
    calculateTilePixelPos(activeChunkData, gridX, gridY) {
        let tx = activeChunkData.chunkPlotStartX + gridX * this.tileWidth / 2 - gridY * this.tileWidth / 2;
        let ty = activeChunkData.chunkPlotStartY + gridX * this.tileHeight / 2 + gridY * this.tileHeight / 2;
        return {x: tx, y: ty};
    }

    getPixelCenterTopOfGridSquare(gridX, gridY) {
        let tx = gridX * this.tileWidth / 2 - gridY * this.tileWidth / 2 + this.tileWidth / 2;
        let ty = gridX * this.tileHeight / 2 + gridY * this.tileHeight / 2;
        // console.log('getPixelCenterTop. In: ', gridX, gridY, 'Out: ', tx, ty);
        return {x: tx, y: ty};
    }

    getPixelCenterOfGridSquare(gridX, gridY) {
        let tx = gridX * this.tileWidth / 2 - gridY * this.tileWidth / 2 + this.tilewidth / 2;
        let ty = gridX * this.tileHeight / 2 + gridY * this.tileHeight / 2 + this.tileHeight / 2;
        return {x: tx, y: ty};
    }

    /**
     * Depth sort a layer.
     * @param {number} layer - index of the layer in our layerContainers array
     * 
     * This needs to take into account a tile's config.isoFootprint (if it exists), this is an array of grid offsets for
     * [ left, top, right, bottom ]. If we are to the left of the left-most footprint grid square or above the top-most then
     * we go behind else, we go in front. I guess this only needs to apply to entities and not other grid squares!
     */
     depthSortLayer(layer) {
        // this.layerContainers[layer].children.sort( (a, b) =>  (a.trimBottom ? a.y - a.trimBottom : a.y) - (b.trimBottom ? b.y - b.trimBottom : b.y) );
        
        // standard array sort doesn't work!
        // this.layerContainers[layer].children.sort(this.sortFunction);

        // Instead we are going to go through our active entities, only caring about the visible ones.
        // Then we will find the nearest non-entity tile and decide if the entity belongs in front or behind
        // and set it's child index accordingly!
        /*
        let entitiesArray = WorldController.scrollingTilemapController.activeEntities;
        for (let i = 0; i < entitiesArray.length; i++) {
            if (entitiesArray[i].characterSprite && entitiesArray[i].characterSprite.visible && entitiesArray[i].characterSprite.parent && entitiesArray[i].characterSprite.parent === this.layerContainers[layer]) {
                // we need to depth sort this entity!
                // find closest non-entity display object
                let nearestDist = Infinity;
                let nearestNonEntity = null;
                let nonEntityIndex = -1;
                for (let j = 0; j < this.layerContainers[layer].children.length; j++) {
                    if (this.layerContainers[layer].children[j].controller === undefined) {
                        // not an entity!
                        let dist = Math.sqrt(((entitiesArray[i].characterSprite.x - this.layerContainers[layer].children[j].x) ** 2) + ((entitiesArray[i].characterSprite.y - this.layerContainers[layer].children[j].y) ** 2));
                        if (dist < nearestDist) {
                            nearestDist = dist;
                            nearestNonEntity = this.layerContainers[layer].children[j];
                            nonEntityIndex = j;
                        }
                    }
                }
                if (nearestNonEntity) {
                    let entityIndex = nonEntityIndex + this.sortFunction(entitiesArray[i].characterSprite, nearestNonEntity);
                    if (entityIndex < nonEntityIndex) {
                        entityIndex = nonEntityIndex;
                    }
                    if (entityIndex >= this.layerContainers[layer].children.length) {
                        entityIndex = this.layerContainers[layer].children.length - 1;
                    }
                    this.layerContainers[layer].setChildIndex(entitiesArray[i].characterSprite, entityIndex);
                }
            }
        }
        */

        // console.log('Iso Depth Sort');
        this.isoDepthSort(this.layerContainers[layer].children);
    }

    sortFunction = (a, b) => {
        if (a.controller && b.controller && (a.controller.npc || a.controller.npcId === 'main_character') && (a.controller.npc || a.controller.npcId === 'main_character')) {
            // sorting 2 npcs works differently
            if (a.y <= b.y) {
                return 1;
            } else {
                return -1;
            }
        } else {
        // if (a.visible && b.visible) {
            if (a.minGX === undefined) {
                let aFootprint = [0,0,0,0];
                if (a.config && a.config.isoFootprint) {
                    aFootprint = a.config.isoFootprint;
                }
                let aMinGX = (a.gridX || a.controller.actualGridX) + aFootprint[0];
                let aMinGY = (a.gridY || a.controller.actualGridY) + aFootprint[1];
                let aMaxGX = (a.gridX || a.controller.actualGridX) + aFootprint[2];
                let aMaxGY = (a.gridY || a.controller.actualGridY) + aFootprint[3];

                a.minGX = aMinGX;
                a.minGY = aMinGY;
                a.maxGX = aMaxGX;
                a.maxGY = aMaxGY;

                // console.log('Set aabb: ', a);
            }

            if (b.minGX === undefined) {
                let bFootprint = [0,0,0,0];
                if (b.config && b.config.isoFootprint) {
                    bFootprint = b.config.isoFootprint;
                }
                let bMinGX = (b.gridX || b.controller.actualGridX) + bFootprint[0];
                let bMinGY = (b.gridY || b.controller.actualGridY) + bFootprint[1];
                let bMaxGX = (b.gridX || b.controller.actualGridX) + bFootprint[2];
                let bMaxGY = (b.gridY || b.controller.actualGridY) + bFootprint[3];

                b.minGX = bMinGX;
                b.minGY = bMinGY;
                b.maxGX = bMaxGX;
                b.maxGY = bMaxGY;

                // console.log('Set aabb: ', b);
            }

            /*
            if (((a.config && a.config.prioritiseDepth) || (b.config && b.config.prioritiseDepth)) && (a.controller || b.controller)) {
                if (a.minGX === b.minGX && a.minGY === b.minGY && a.maxGX === b.maxGX && a.maxGY === b.maxGY) {
                    console.log('Depth sorting: ', a, b);
                }
            }
            */
            // if sharing a grid square we can prioritise one or the other...
            if (a.minGX === b.minGX && a.minGY === b.minGY && a.maxGX === b.maxGX && a.maxGY === b.maxGY && b.config && b.config.prioritiseDepth === true) {
                return 1;
            } else
            if (a.minGX === b.minGX && a.minGY === b.minGY && a.maxGX === b.maxGX && a.maxGY === b.maxGY && a.config && a.config.prioritiseDepth === true) {
                return -1;
            } else
            if (b.minGX <= a.maxGX && b.minGY <= a.maxGY) {
            // if (a.maxGX < b.minGX || a.maxGY < b.minGY) {
                return -1;
            } else {
            // if (b.maxGX < a.minGX || b.maxGY < a.minGY) {
                return 1;
            }
        }
        return 0;
    }

    _sortDepth = 0;
    isoDepthSort(objs) {
        const isoSpritesLength = objs.length;
        for (let i = 0; i < isoSpritesLength; i++) {
            let a = objs[i];
            let behindIndex = 0;

            // if (a.visible) {
                a.isoSpritesBehind = [];
                for (let j = 0; j < isoSpritesLength; j++) {
                    if (i != j) {
                        let b = objs[j];

                        // if (b.visible) {
                            if (this.sortFunction(a, b) === -1) {
                                a.isoSpritesBehind[behindIndex] = b;
                                behindIndex++
                            }
                        // s}
                    }
                }
            // }

            a.isoVisitedFlag = 0;
        }
        
        this._sortDepth = 0;
        for (let i = 0; i < isoSpritesLength; ++i) {
            // if (objs[i].visible) {
                this.visitNode(objs[i]);
            // }
        }

        objs.sort( (a, b) =>  a.isoDepth - b.isoDepth );
    }

    visitNode = (n) => {
        if (n.isoVisitedFlag == 0) {
            n.isoVisitedFlag = 1;

            const spritesBehindLength = n.isoSpritesBehind.length;
            for (let i = 0; i < spritesBehindLength; i++) {
                if (n.isoSpritesBehind[i] == null) {
                    break;
                }
                else {
                    this.visitNode(n.isoSpritesBehind[i]);
                    n.isoSpritesBehind[i] = null;
                }
            }

            n.isoDepth = this._sortDepth;
            this._sortDepth++;
        }
    }
}