import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import game from '../../../controller/GameController';
import { v, au } from '../../../koko-framework/shorthand';
import LetsGoButton from '../../../assets/babcock_menus/enter_name/confirm_btn.png';
import SkipButton from '../../../assets/babcock_menus/video/skip.png';
import Video from '../../../assets/babcock_menus/video/intro_vid.mp4';
import { GameState } from "../../model/GameState";
import ApiController from "../../controller/ApiController";

import ReactGA from 'react-ga4';

export default class VideoView extends ReactFullScreenView {
    componentDidMount() {
        super.componentDidMount();
        au.stop('menu_music');

        ReactGA.send({ hitType: "pageview", page: "/intro-video/" });
    }

    skipVideo = (e) => {
        au.play('primarybtn', 1, false, 0, false);
        let vid = window.document.getElementById('introVid');
        vid.pause();
        this.startGame(e);
    }

    startGame = (e) => {
        v.hideView('introvideo');
        
        v.showView('greenbg');
        game.initWorld();
        game.gotoWorldView(true);
        v.showView('hudview');
        if (!GameState.firstPlay) {
            v.showView('skiptutorial');
        }
        au.play('babcock_ingame_music', 1, true, 0, false);

        setTimeout(() => v.hideView('blackfade'), 400);
    }

    renderContent() {
        return (
            <div className="full-screen-holder main-menu no-select default-cursor">
                <div className="full-screen-holder black-out rel">
                    <div className="name-entry-panel">
                        <div className="view-row grow"></div>
                        <div className="view-row shrink">
                            <video id="introVid" className="intro-video" autoPlay controls onEnded={this.startGame}>
                                <source src={Video} type="video/mp4" />
                            </video>
                        </div>
                        <div className="view-row grow"></div>
                        <div className="view-row name-entry-button-holder">
                            <img className="skip-vid-button active-button" src={SkipButton} onClick={this.skipVideo} alt="Click to start" />
                        </div>
                        <div className="view-row grow"></div>
                    </div>
                </div>
            </div>
        );
    }
}