import { KOKO_DEBUG, kokoDebug } from '../model/config'
import * as PIXI from 'pixi.js'
import TileObject from './TileObject'
import {TILE_WIDTH, TILE_HEIGHT} from '../model/GameConfig'
import { Rectangle } from 'pixi.js';
import koko from '../../../koko-framework/koko'

export class PackageSprite extends PIXI.Container {

    packageShape = null;
    useCount = 0;
    packageHeight = 0;
    packageWidth = 0;
    debugTargetRowText = null;
    clickDisbaled = false;

    constructor(packageArr) {
        super();
    }

    create = (packageArr) =>
    {
        this.clickDisbaled = false;
        this.packageShape = packageArr
       
        this.createGraphic();
        this.useCount ++;
        this.createHitArea();
        //kokoDebug('CREATE NEW PACKAGE = '+packageArr+" Use Count: "+this.useCount)
    }
    createHitArea = () => {
        this.hitArea = new Rectangle(-TILE_WIDTH, -TILE_HEIGHT, this.width + TILE_WIDTH * 2, this.height + TILE_HEIGHT * 2)
       /* let newHitGraphic = new PIXI.Graphics();
        newHitGraphic.beginFill(0xFF0000);
        newHitGraphic.drawRect(0, 0, this.width, this.height);
        this.addChild(newHitGraphic);*/
    }
    createGraphic = () => {

        //Cycle through the given shape and create the assets accordingly
        let packageGraphic = 'box_'+Math.ceil(Math.random()*12)+'.jpg';
        for(let i = 0; i < this.packageShape.length; i++)
        {
            let row = this.packageShape[i];
            for(let j = 0; j < row.length; j++)
            {
                let isTile = row[j];
                if(isTile){
                    //This tile space containes a box so add the graphic to this container
                    let newGraphic = new PIXI.Sprite.from(packageGraphic);
                    kokoDebug('TILE SIZE = x:'+newGraphic.width+' y:'+newGraphic.height);
                    newGraphic.x = j * TILE_WIDTH;
                    newGraphic.y = i * TILE_HEIGHT;
                    this.addChild(newGraphic);
                }
            }
        }



        this.debugTargetRowText = new PIXI.Text('', {fontFamily:'Arial', fontSize:40, fill:0xFFFFFF, align:'center'});
        //this.addChild(  this.debugTargetRowText);


        this.packageHeight = this.packageShape.length * TILE_HEIGHT;
        this.packageWidth = this.packageShape[0].length * TILE_WIDTH
    }

    destroy = () => {

        while(this.children[0])
        {
            this.removeChild(this.children[0]);
        }
        this.scale.x = this.scale.y = 1;
    }


}