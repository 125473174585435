import gsap from 'gsap'
import { KOKO_DEBUG, kokoDebug } from '../model/config'
import {Back, Bounce} from 'gsap'
import * as PIXI from 'pixi.js'
import {TRUCK_COLUMNS, TRUCK_ROWS, TILE_WIDTH, TILE_HEIGHT, TRAILER_OFFSET_X, TRAILER_OFFSET_Y, TRUCK_DELIVERY_TIME, PARCEL_COLLECTED_EVENT,TRUCK_REVERSE_TIME, TRUCK_LEAVE_TIME} from '../model/GameConfig'
import {TileObject} from './TileObject'
import koko from '../../../koko-framework/koko'
import { useCallback } from 'react'

export class TruckSprite extends PIXI.Container{

    tileGrid = [];
    trailerBounds;
    isActive = true;

    parcelContainer = null;
    
    animSprites = {a1:null, a2:null, a3:null, a4:null};


    constructor() {
        super();
        this.init();
    }

    init = () => {
       
        this.setupGraphics();
        this.setupTiles();
        this.setupBounds();
    }

    setupBounds = () =>{
       this.trailerBounds = new PIXI.Rectangle(TRAILER_OFFSET_X, TRAILER_OFFSET_Y - TILE_HEIGHT/2, TRUCK_COLUMNS * TILE_WIDTH, TRUCK_ROWS*TILE_HEIGHT+TILE_HEIGHT)
    }

    setupGraphics = () =>{

        let truckGraphic = new PIXI.Sprite.from('truck.png');
        this.addChild(truckGraphic);
        this.parcelContainer = new PIXI.Container();
        this.addChild(this.parcelContainer);



        let availableAnims = [
        {name:'Spark Element 08_', frames:13, lZero:4}, 
        {name:'Spark Element 10_', frames:14, lZero:4}, 
        {name:'Spark Element 15_', frames:11, lZero:4},
        {name:'Spark Element 16_', frames:11, lZero:4}]

        for(let i=0; i<availableAnims.length; i++)
        {
            let td = availableAnims[i];
            let targetAnim = koko.display.createAnim(td.name, td.frames, td.lZero,0,1);
            targetAnim.pivot.x =   targetAnim.width/2;
            targetAnim.pivot.y =   targetAnim.height/2;
            targetAnim.visible = false;
            targetAnim.loop = false;


            this.animSprites['a'+(i+1)] = targetAnim;

            targetAnim.onComplete = () =>{
                targetAnim.visible = false;
            }
            //this.animSprite1.play();
            this.parcelContainer.addChild(targetAnim)
        }
        console.log('Spark anim',this.animSprites);
       
    }

    hideAllHighlightTiles = () =>{
        for(let i=0; i<  this.tileGrid.length; i++)
        {
            for(let j=0; j<this.tileGrid[i].length; j++)
            {
                this.tileGrid[i][j].debugGraphic.visible = false;
            }
        }
    }

    emptyLoad = () =>{
     
        while(this.parcelContainer.children[0])
        {
            this.parcelContainer.removeChild(this.parcelContainer.children[0])
        };

        for(let i=0; i<  this.tileGrid.length; i++)
        {
            for(let j=0; j<this.tileGrid[i].length; j++)
            {
                this.tileGrid[i][j].debugGraphic.visible = false;
                if(this.tileGrid[i][j].isOccupied) this.emit('parcelCollected');
                this.tileGrid[i][j].isOccupied = false;
            }
        }
    }


    highlightAvailableSlot = (packageShape, targetRow, targetColumn) =>{
        for(let i=0; i<packageShape.length; i++)
        {
            for(let j=0; j<packageShape[i].length; j++)
            {
                this.tileGrid[targetRow+i][targetColumn+j].debugGraphic.visible = packageShape[i][j];
               
            }
        }
    }
    loadPackageIntoTruck = (packageSprite, targetRow, targetColumn) =>{
        let packageShape = packageSprite.packageShape;
        for(let i=0; i<packageShape.length; i++)
        {
            for(let j=0; j<packageShape[i].length; j++)
            {
                this.tileGrid[targetRow+i][targetColumn+j].isOccupied = packageShape[i][j];
                console.log(  this.tileGrid[targetRow+i][targetColumn+j])
            }
        }

        let targetTileObj =  this.tileGrid[targetRow][targetColumn];
        packageSprite.x -= packageSprite.pivot.x;
        packageSprite.y -= packageSprite.pivot.y;
        packageSprite.pivot.x = packageSprite.pivot.y = 0;
     
        let hitPoint = new PIXI.Point(targetTileObj.x, targetTileObj.y );

        koko.audio.play('boxImpact2',.5);
        gsap.to(packageSprite, {duration:.3, x:targetTileObj.x, y:targetTileObj.y,ease:Back.easeIn, onComplete:() =>{this.shakeTruck(); this.showSpark(hitPoint);}})


    }

    showSpark = (packagePosition) =>{
        console.log('Package Pos - x:', packagePosition.x, ' y:', packagePosition.y);
        let randomAnim =   this.animSprites['a'+Math.ceil(Math.random()*4)];

        randomAnim.x = packagePosition.x;
        randomAnim.y = packagePosition.y;
        randomAnim.visible = true;
        randomAnim.gotoAndPlay(1);

        let randSound = Math.ceil(Math.random()*3);
        koko.audio.play('boxImpact3',.5);
      
    }
    shakeTruck = () =>{

        this.x = 0;
        this.y = 0;
        gsap.to(this, {duration:.05, x:(Math.random()*12)-6, y:(Math.random()*12)-6, yoyo:true, repeat:1})

    }

    setupTiles = () =>{
        for(let i=0; i<TRUCK_ROWS; i++)
        {
            let newRow = [];
            for(let j=0; j<TRUCK_COLUMNS; j++)
            {
                let newTileObject = new TileObject();
                newTileObject.x = j * TILE_WIDTH + TRAILER_OFFSET_X;
                newTileObject.y = i * TILE_HEIGHT + TRAILER_OFFSET_Y;
                newRow.push(newTileObject);
/*
                let debugGraphic = new PIXI.Graphics();
                debugGraphic.beginFill(0x666666);
                debugGraphic.drawRect(1,1,TILE_WIDTH-2, TILE_HEIGHT-2)
                debugGraphic.x = newTileObject.x;
                debugGraphic.y = newTileObject.y;
                newTileObject.debugGraphic = debugGraphic;*/
                let debugGraphic = new PIXI.Sprite.from('boxGuide.png');
                debugGraphic.x = newTileObject.x;
                debugGraphic.y = newTileObject.y;
                newTileObject.debugGraphic = debugGraphic
                this.addChild(debugGraphic); 
            }
            this.tileGrid.push(newRow);
        }
    }

    isGlobalPointInTrailer = (globalPoint) =>{
        let localPoint = this.toLocal(globalPoint);
        let success = false;
        if(localPoint.y > this.trailerBounds.top && localPoint.y < this.trailerBounds.bottom)  success = true;
        return success;
    }



    getAvailableSlots(packageSprite, isOnClick = false)
    {
        let returnObj = {canFitInTruck:false, targetRow:0, targetColumn:0 }
        let packageShape = packageSprite.packageShape;
        let packageLocalPos = this.toLocal(packageSprite.toGlobal(new PIXI.Point(0, packageSprite.pivot.y)))
        let targetRow = Math.floor((packageLocalPos.y - TRAILER_OFFSET_Y/2)/TILE_HEIGHT) - packageShape.length;
        if(targetRow == -1) targetRow = 0

        let targetColumn = TRUCK_COLUMNS - 1;

        for(let i=0; i<packageShape.length; i++)
        {
            let tileGridRow = this.tileGrid[i+targetRow];
            let nextAvailableTruckColumn = this.nextAvaialbaleSlotInTileGridRow(tileGridRow);

            for(let j=packageShape[i].length-1; j>=0; j--)
            {
                if(packageShape[i][j])
                {
                    let tempTargetColumn = nextAvailableTruckColumn - j;
                    if(tempTargetColumn < targetColumn) targetColumn = tempTargetColumn; 
                }
            }
        }

        if(targetColumn >= 0)
        {
            this.highlightAvailableSlot(packageShape, targetRow, targetColumn);
            if(isOnClick) 
            {
                this.loadPackageIntoTruck(packageSprite, targetRow, targetColumn);
            }
            returnObj.canFitInTruck = true;
        }   

        returnObj.targetRow = targetRow;
        returnObj.targetColumn = targetColumn;
        return returnObj;
    }

    nextAvaialbaleSlotInTileGridRow = (tileGridRowArr) =>
    {
        let nextSlot = -1;
        if(tileGridRowArr)
        {
            for(let i=0; i<tileGridRowArr.length; i++)
            {
                let tileObj = tileGridRowArr[i];
                if(tileObj.isOccupied == false) nextSlot = i
                else break;
            }
        }
        return nextSlot;
    }

    sendForDelivery = () =>{
        this.emit('sendTruckForDelivery');
        koko.audio.play('truckOut',.5);
        kokoDebug("Send truck out for delivery:"+new Date().getTime());
        this.isActive = false;
        gsap.to(this, {duration:2.5, x: 1500, ease:Back.easeIn, onComplete:()=>{this.emptyLoad()}});
        // gsap.to(this, {delay:TRUCK_DELIVERY_TIME - TRUCK_REVERSE_TIME, duration:TRUCK_REVERSE_TIME, x: 0,onPlay:() =>{koko.audio.play('truckIn',.5);}, onComplete:()=>{this.isActive = true; this.emit('reactivateTruck')}});

    }

    bringBackIn = () => {
        gsap.to(this, {duration:TRUCK_REVERSE_TIME, x: 0,onPlay:() =>{koko.audio.play('truckIn',.5);}, onComplete:()=>{this.isActive = true; this.emit('reactivateTruck')}});
    }
}