import { au, v } from '../../koko-framework/shorthand';
import BabcockController from '../controller/BabcockController';
import { GetQuestionToAsk } from './advisor-questions';
import * as BABCOCK_CONSTANTS from './constants';
import BADGES_ORDER from '../model/badges-config';
import ApiController from '../controller/ApiController';

import ReactGA from 'react-ga4';

export const GameState = {
    selectedCharacter: 0,
    name: '',

    // extended data capture
    phone: '',
    email: '',
    age: '',
    lastName: '',
    termsAgreed: false,
    privacyAgreed: false,
    marketingOptIn: false,

    selfiesTaken: null,

    inventory: null,
    itemsCollected: null,
    itemsGiven: null,

    starsEarnedLastGame: 0,
    starsBeforeLastGame: 0,
    gameResults: null,
    gameRecords: null,
    gameLastScores: null,
    gamePreviousBests: null,

    npcTasksCompleted: null,
    npcsInteractedWith: null,

    attributesGained: null,

    newCvItem: false,
    newInventoryItem: false,

    applicationSent: false,
    wearingSuit: false,
    gettingLift: true,
    gotLift: false,

    currentGameTask: BABCOCK_CONSTANTS.NO_TASK,
    currentSubTask: BABCOCK_CONSTANTS.NO_TASK,
    litterCollected: 0,
    taskCountdown: 72.36521,
    timerActive: false,
    introPhase: 0,

    cvTutorialShown: false,
    cvTutorialActive: false,
    cvTutorialPhase: 0,

    questionsAnswered: [],

    playingMiniGame: null,

    badgeProgress: null,

    gameComplete: false,

    // we don't show the skip button on our first play
    firstPlay: true,
}

export function ResetGameState() {
    GameState.selectedCharacter = 0;
    GameState.selfiesTaken = [];
    GameState.inventory = [];
    GameState.itemsCollected = [];
    GameState.itemsGiven = [];
    GameState.attributesGained = [];
    GameState.gameResults = [0, 0, 0];
    GameState.gameRecords = [0, 0, 0];
    GameState.gameLastScores = [0, 0, 0];
    GameState.gamePreviousBests = [0, 0, 0];
    GameState.npcTasksCompleted = [];
    GameState.npcsInteractedWith = [];
    GameState.newCvItem = false;
    GameState.newInventoryItem = false;
    GameState.currentGameTask = BABCOCK_CONSTANTS.INTRO_TASK;
    GameState.currentSubTask = BABCOCK_CONSTANTS.NO_TASK;
    GameState.litterCollected = 0;
    GameState.taskCountdown = 0;
    GameState.introPhase = 0;
    GameState.applicationSent = false;
    GameState.gettingLift = false;
    GameState.gotLift = false;
    GameState.wearingSuit = false;
    GameState.timerActive = false;
    GameState.cvTutorialShown = false;
    GameState.cvTutorialActive = false;
    GameState.cvTutorialPhase = 0;
    GameState.questionsAnswered = [];
    GameState.playingMiniGame = null;
    GameState.gameComplete = false;

    GameState.badgeProgress = [];
    for (let i = 0; i < BADGES_ORDER.length; i++) {
        let oneBadge = {
            config: BADGES_ORDER[i],
            progress: 0,
        }
        GameState.badgeProgress.push(oneBadge);
    }

    // console.log('Game State Reset: ', GameState);
}

export function IsSelfieTaken(locationId) {
    return GameState.selfiesTaken.indexOf(locationId) >= 0;
}

export function CompleteSelfie(locationId) {
    if (!IsSelfieTaken(locationId)) {
        GameState.selfiesTaken.push(locationId);
        for (let i = 0; i < BABCOCK_CONSTANTS.SELFIE_PROGRESS_BADGES.length; i++) {
            ProgressBadge(BABCOCK_CONSTANTS.SELFIE_PROGRESS_BADGES[i].id);
        }
        SetNewCvItem(true);

        ReactGA.event({
            category: "Main Game Action",
            action: "Photo Taken",
            label: locationId,
            value: GameState.selfiesTaken.length
        });
    }
}

export function IsItemGot(item) {
    return GameState.itemsCollected.indexOf(item) >= 0;
}

export function SetMiniGameResult(gameId, stars, score) {
    console.log('Game result: ', gameId, stars, score);
    if (typeof stars === 'number') {
        GameState.starsEarnedLastGame = stars;
        GameState.starsBeforeLastGame = GameState.gameResults[gameId];

        if (GameState.gameResults[gameId] < stars) {
            let starsBefore = GameState.gameResults[gameId];
            GameState.gameResults[gameId] = stars;
            if (starsBefore === 0) {
                SetNewCvItem(true);
            }
        }
        
        let gameData = BABCOCK_CONSTANTS.getGameDataFromId(gameId);
        let betterScore = false;
        if (gameData.higherScoreIsBetter && GameState.gameRecords[gameId] < score) {
            betterScore = true;
        } else
        if (gameData.higherScoreIsBetter === false && GameState.gameRecords[gameId] > score) {
            betterScore = true;
        }
        GameState.gameLastScores[gameId] = score;
        GameState.gamePreviousBests[gameId] = GameState.gameRecords[gameId];
        if (GameState.gameRecords[gameId] === 0 || betterScore) {
            GameState.gameRecords[gameId] = score;
        }

        ReactGA.event({
            category: "Mini Game Result",
            action: "Stars Earned",
            label: gameData.name,
            value: stars
        });
    }
}

export function CollectItem(item) {
    if (GameState.itemsCollected.indexOf(item) === -1) {
        GameState.itemsCollected.push(item);
        GameState.inventory.push(item);
        SetNewInventoryItem(item !== BABCOCK_CONSTANTS.ITEMS.CLOTHES || !GameState.applicationSent ? true : false);
        // SetNewCvItem(true);

        au.play('taskstep', 1, false, 0, false);

        v.showView('newitempop');

        ReactGA.event({
            category: "Main Game Action",
            action: "Item Collected",
            label: item.description,
        });
    }
}

export function GiveItem(item, entityData) {
    let index = GameState.inventory.indexOf(item);
    if (index >= 0) {
        GameState.itemsGiven.push(item);
        GameState.inventory.splice(index, 1);
        // GameState.npcTasksCompleted.push(entityData);
        NpcTaskCompleted(entityData);

        ReactGA.event({
            category: "Main Game Action",
            action: "Item Used",
            label: item.description,
        });

        return true;
    }
    return false;
}

export function NpcTaskCompleted(entityData) {
    // console.log('Npc task completed: ', entityData);
    if (GameState.npcTasksCompleted.indexOf(entityData) === -1 && entityData.progressBadges) {
        for (let i = 0; i < entityData.progressBadges.length; i++) {
            ProgressBadge(entityData.progressBadges[i].id);
        }
    }

    GameState.npcTasksCompleted.push(entityData);
    BabcockController.addRemoveNpcAlerts();

    if (entityData !== BABCOCK_CONSTANTS.HOSPITAL_GROUNDSKEEPER) {
        au.play('taskstep', 1, false, 0, false);
    }

    let questionToAsk = GetQuestionToAsk(entityData);
    if (questionToAsk) {
        if (GameState.questionsAnswered.indexOf(questionToAsk) === -1) {
            BabcockController.queuedQuestion = questionToAsk;
        }
    }

    ApiController.triggerGameDataDump();

    ReactGA.event({
        category: "Main Game Action",
        action: "NPC Task Completed",
        label: entityData.id,
    });
}

export function NpcInteractedWith(entityData) {
    if (GameState.npcsInteractedWith.indexOf(entityData) === -1) {
        GameState.npcsInteractedWith.push(entityData);
    }

    ReactGA.event({
        category: "Main Game Action",
        action: "NPC Interaction",
        label: entityData.id,
    });
}

export function SetNewInventoryItem(newItem) {
    GameState.newInventoryItem = newItem;
    v.get('hudview').reflectGameState();
}

export function SetNewCvItem(newItem) {
    GameState.newCvItem = newItem;
    v.get('hudview').reflectGameState();
}

export function GetCvStarsEarned() {
    let starsEarned = 0
    for (let i = 0; i < GameState.gameResults.length; i++) {
        if (GameState.gameResults[i] > 0) {
            starsEarned++;
        }
    }
    if (GameState.selfiesTaken.length === BABCOCK_CONSTANTS.SELFIE_LOCATIONS.length) {
        starsEarned++;
    }

    if (GetBadgesCompleteCount() === GameState.badgeProgress.length) {
        starsEarned++;
    }

    return starsEarned;
}

export function ProgressBadge(id, amount = 1) {
    for (let i = 0; i < GameState.badgeProgress.length; i++) {
        if (GameState.badgeProgress[i].config.id === id) {
            if (GameState.badgeProgress[i].progress < GameState.badgeProgress[i].config.targetValue) {
                console.log('Progressing badge: ', GameState.badgeProgress[i], amount);
                GameState.badgeProgress[i].progress += amount;
                if (GameState.badgeProgress[i].progress > GameState.badgeProgress[i].config.targetValue) {
                    GameState.badgeProgress[i].progress = GameState.badgeProgress[i].config.targetValue;
                }
                SetNewCvItem(true);
            }
            ApiController.triggerGameDataDump();

            ReactGA.event({
                category: "Main Game Action",
                action: "Badge Progressed",
                label: GameState.badgeProgress[i].config.name,
                value: Math.round((GameState.badgeProgress[i].progress / GameState.badgeProgress[i].config.targetValue) * 100)
            });

            return GameState.badgeProgress[i];
        }
    }
    return null;
}

export function GetBadgesCompleteCount() {
    let count = 0;
    for (let i = 0; i < GameState.badgeProgress.length; i++) {
        if (GameState.badgeProgress[i].progress >= GameState.badgeProgress[i].config.targetValue) {
            count++;
        }
    }
    return count;
}

export function GetCvPercentage() {
    let pointsEarned = 0;
    let pointsTarget = 0;

    for (let i = 0; i < GameState.gameResults.length; i++) {
        pointsTarget += 3;
        pointsEarned += GameState.gameResults[i];
    }

    pointsTarget += BABCOCK_CONSTANTS.SELFIE_LOCATIONS.length;
    pointsEarned += GameState.selfiesTaken.length;

    for (let i = 0; i < GameState.badgeProgress.length; i++) {
        pointsTarget += GameState.badgeProgress[i].config.targetValue;
        pointsEarned += GameState.badgeProgress[i].progress;
    }

    return pointsEarned / pointsTarget;
}