import { v } from '../koko-framework/shorthand'
import { ORTHAGONAL, ISOMETRIC } from '../koko-framework/tile-engine/TileMapEngine'

import WorldController from './WorldController'
import { TILESETS, MAPS, MAP_LABELS, START_MAP} from '../model/MapConfig'

import { PANEL_STATE } from '../Bootstrap'
import { GENERIC_NPC_INTERACTIONS } from '../model/Interactions'
import koko from '../koko-framework/koko'

import { FlyAwayBird } from './SpecialEntities/FlyAwayBird'
import BabcockController from '../babcock-neet/controller/BabcockController'

const NON_STANDARD_ENTITY_CLASSES = {
    FlyAwayBird: FlyAwayBird,
}

class GameController {
    initWorld() {
        WorldController.allowDiagonalMovement = false;
        WorldController.useDoubleGridForPathFinding = false;
        WorldController.allowGenericNpcInteractions = false;
        WorldController.init(TILESETS, MAPS, MAP_LABELS, ISOMETRIC, 155, 90);
        WorldController.interactCallback = this.interactionHandler;
        WorldController.clickCallback = this.clickHandler;
        WorldController.nonStandardEntityClasses = NON_STANDARD_ENTITY_CLASSES;

        // initialise out babcock controller
        BabcockController.init();
    }

    gotoWorldView(reset) {
        if (reset) {
            WorldController.reset(START_MAP);
        }
        WorldController.activate(v.get('worldview'));
    }

    deactivateWorldView() {
        WorldController.deactivate();
    }

    interactionHandler = (tiles, entity) => {
        if (entity) {
            if (entity.allowInteraction) {
                console.log('Entity interaction: ', entity);
                let characterDataId = null;
                for (let i = 0; i < entity.originalConfigData.length; i++) {
                    if (entity.originalConfigData[i].characterDataId) {
                        characterDataId = entity.originalConfigData[i].characterDataId;
                    }
                }
                if (BabcockController.handleEntityInteraction(entity.npcId, entity) === false) {
                    let interactionArray = GENERIC_NPC_INTERACTIONS[characterDataId] || GENERIC_NPC_INTERACTIONS['NPC'];
                    PANEL_STATE.panelOpen = true;
                    PANEL_STATE.contents = interactionArray[Math.floor(Math.random() * interactionArray.length)];
                    koko.views.showView('interactionpanel');
                    WorldController.disable();
                }
            }
        } else {
            // console.log('Tiles interaction: ', tiles);
            let doneInteraction = false;
            if (WorldController.allowGenericNpcInteractions) {
                for (let i = 0; i < tiles.length; i++) {
                    if (tiles[i].config.npc === true && tiles[i].config.allowInteraction !== false) {
                        let characterDataId = null;
                        for (let j = 0; j < tiles[i].config.length; j++) {
                            if (tiles[i].config[j].characterDataId) {
                                characterDataId = tiles[i].config[j].characterDataId;
                            }
                        }
                        if (characterDataId === null && tiles[i].localConfig) {
                            for (let j = 0; j < tiles[i].localConfig.length; j++) {
                                if (tiles[i].localConfig[j].characterDataId) {
                                    characterDataId = tiles[i].localConfig[j].characterDataId;
                                }
                            }
                        }
                        let interactionArray = GENERIC_NPC_INTERACTIONS[characterDataId] || GENERIC_NPC_INTERACTIONS['NPC'];
                        PANEL_STATE.panelOpen = true;
                        PANEL_STATE.contents = interactionArray[Math.floor(Math.random() * interactionArray.length)];
                        koko.views.showView('interactionpanel');
                        WorldController.disable();
                        doneInteraction = true;
                        break;
                    }
                }
            }
            if (!doneInteraction) {
                for (let i = 0; i < tiles.length; i++) {
                    if (!tiles[i].requiresClickToInteract) {
                        if (tiles[i].config && tiles[i].config.action === 'inspect' && (tiles[i].config.description)) {
                            // this.gameView.showTextPop(this.targetSquareSprites[i].config.description);
                            PANEL_STATE.panelOpen = true;
                            PANEL_STATE.contents = tiles[i].config.description;
                            koko.views.showView('interactionpanel');
                            WorldController.disable();
                            break;
                        } else
                        if (tiles[i].localConfig && tiles[i].localConfig.action === 'inspect' && (tiles[i].localConfig.description)) {
                            PANEL_STATE.panelOpen = true;
                            PANEL_STATE.contents = tiles[i].localConfig.description;
                            koko.views.showView('interactionpanel');
                            WorldController.disable();
                            break;
                        } else
                        if (tiles[i].config && tiles[i].config.storyLocation) {
                            if (BabcockController.handleInteraction(tiles[i]) === false) {
                                // this.gameView.showTextPop(this.targetSquareSprites[i].config.description);
                                PANEL_STATE.panelOpen = true;
                                PANEL_STATE.contents = tiles[i].config.storyLocation;
                                koko.views.showView('interactionpanel');
                                WorldController.disable();
                                break;
                            }
                        }
                    }
                }
            }
        }
    }

    clickHandler = (e) => {
        // console.log('Clicker: ', e);
        if (PANEL_STATE.panelOpen) {
            koko.views.hideView('interactionpanel');
            PANEL_STATE.panelOpen = false;
            WorldController.enable();
            WorldController.interactionEnded(true);
        }
    }
}

const game = new GameController();
export { game as default }