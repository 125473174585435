// An extension of the character follower entity that will stop following when 
// it gets near to it's destination and then walk to the destination.

import { CharacterFollowerNPCEntity } from "./CharacterFollowerNPCEntity";
import gsap from 'gsap';
import WorldController from "../../../controller/WorldController";

export class LeadToDestinationNPCEntity extends CharacterFollowerNPCEntity {
    leadToDestination = true;

    maxDistFromFinalDest = 500;
    maxDistFromLeaderWhenAtDest = 260;
    finalDestination = null;
    reachedFinalDestination = false;
    reachedFinalDestinationCallback = null;

    leftTheScene = false;
    leaveTheScene = false;      // leave the scene is essentially a 1-shot path, it will fade out at the end and trigger the leftSceneCallback
    leftSceneCallback = null;

    waitingAtStart = true;
    randomMovementDistance = 3;
    randomlyMoveWhileWaiting = true;

    applySettingsConfig(configArray) {
        super.applySettingsConfig(configArray);

        let applyableSettings = ['maxDistFromFinalDest', 'leaveTheScene', 'randomlyMoveWhileWaiting', 'randomMovementDistance'];
        this._applySettings(configArray, applyableSettings);
    }

    findFinalDestination = () => {
        if (this.npcId) {
            let mapWayPoints = this.scrollingTilemapController.tileMapEngine.getSpecialSpritesWithProperty('waypoint');
            for (let i = 0; i < mapWayPoints.length; i++) {
                if (mapWayPoints[i].localConfig.waypoint === true) {
                    if (mapWayPoints[i].localConfig.waypoint_npcs) {
                        let wayPointNpcs = JSON.parse(mapWayPoints[i].localConfig.waypoint_npcs);
                        for (let j = 0; j < wayPointNpcs.length; j++) {
                            if (wayPointNpcs[j].split('_')[0] === this.npcId) {
                                // we set our final destination to the first qualifying waypoint
                                this.finalDestination = mapWayPoints[i];
                                return;
                            }
                        }
                    }
                }
            }
        }
    }

    update(delta) {
        if (!this.reachedFinalDestination) {
            super.update(delta);
        } else {
            if (this.currentPath === undefined || this.currentPath === null || this.currentPath.length === 0) {
                if (this.actualGridX !== this.finalDestination.gridX || this.actualGridY !== this.finalDestination.gridY) {
                    this.gotoFinalDestination();
                }
            }
        }
        /*
        if (this.currentPath === undefined || this.currentPath === null || this.currentPath.length === 0) {
            if (!this.characterFollower && (this.characterGridX !== this.characterStartGridX || this.characterGridY !== this.characterStartGridY)) {
                this.cancelLeading();
            }
        }
        */
    }

    startLeading = (followNpc) => {
        this.waitingAtStart = false;
        this.pauseAtStart = false;
        this.randomiseDestGridPos = true;
        this.characterFollower = true;
        this.characterToFollow = followNpc;

        this.pathNodeReachedCallback = this.checkIfLedToFinalDest;
    }

    cancelLeading = () => {
        this.characterFollower = false;
        this.characterToFollow = null;
        this.reachedFinalDestination = false;
        this.randomiseDestGridPos = false;
        this.pauseCountdown = 0;
        this.movementPaused = false;
        this.randomiseDestGridPos = false;
        this.randomDestOffsetX = this.randomDestOffsetY = 0;
        this.moveToGridPos(this.characterStartGridX, this.characterStartGridY);
    }

    randomlyMove = () => {
        if (this.inited && !this.reachedFinalDestination) {
            // console.log('Lead char random movement called: ', this);
            if (!this.movementPaused && this.randomlyMoveWhileWaiting && this.characterToFollow === null && (this.currentPath === undefined || this.currentPath === null || this.currentPath.length ===0)) {
                // console.log('Moving!')
                this.moveToGridPos(this.characterStartGridX - this.randomMovementDistance + Math.floor(Math.random() * this.randomMovementDistance * 2), this.characterStartGridY - this.randomMovementDistance + Math.floor(Math.random() * this.randomMovementDistance * 2));
            }
            if (this.characterSprite && this.characterSprite.parent) {
                setTimeout(this.randomlyMove, 3000);
            }
        }
    }

    reachedDestination = () => {
        // console.log('Reached dest!');
        if (!this.reachedFinalDestination) {
            // super.reachedDestination();
            if (this.resetViewOnPause) {
                this.updateCharacterSprite();
                this.playAnim();
            } else {
                this.pauseAnim();
            }
            
            // are we close enough to our final destination (or the character we are following is)
            // if so, trigger walking to our final destination and stop following the character - 
            // the next call to this will fade us out and call the leftSceneCallback
            if (!this.checkIfLedToFinalDest()) {
                if (!this.reachedFinalDestination && !this.characterFollower && this.characterGridX === this.characterStartGridX && this.characterGridY === this.characterStartGridY) {
                    // console.log('Lead to dest char back at start, pausing');
                    // this.pauseAtStart = true;
                    // this.pauseMovement(Infinity);
                    this.waitingAtStart = true;
                } else
                    if (!this.reachedFinalDestination && !this.characterFollower && (this.characterGridX !== this.characterStartGridX || this.characterGridY !== this.characterStartGridY)) {
                        this.cancelLeading();
                    }
            }
        } else {
            // time to leave the scene!
            // console.log('Leaving scene: ', this, this.leaveTheScene, this.leftTheScene);
            let atTarget = false;
            if (this.actualGridX === this.finalDestination.gridX && this.actualGridY === this.finalDestination.gridY) {
                atTarget = true;
            }
            /*
            if (!atTarget && this.characterDestinationGridX === this.finalDestination.gridX && this.characterDestinationGridY === this.finalDestination.gridY) {
                atTarget = true;
            }
            */
            if (!atTarget) {
                this.gotoFinalDestination();
            } else
            if (this.leaveTheScene && !this.leftTheScene) {
                this.leftTheScene = true;
                gsap.to(this.characterSprite, {alpha: 0, duration: 0.3, onComplete: this.leftTheSceneDone});
            }
        }
    }

    checkIfLedToFinalDest = () => {
        if (!this.reachedFinalDestination && this.finalDestination && this.characterToFollow) {
            // console.log('have we reached our final dest? ', this.characterFollower, this.finalDestination);
            let dist = Math.sqrt(((this.characterToFollow.characterSprite.x - this.finalDestination.x) ** 2) + ((this.characterToFollow.characterSprite.y - this.finalDestination.y) ** 2));
            let distFromLeader = Math.sqrt(((this.characterToFollow.characterSprite.x - this.characterSprite.x) ** 2) + ((this.characterToFollow.characterSprite.y - this.characterSprite.y) ** 2));
            // console.log('distance from final dest: ', dist, this.maxDistFromFinalDest);
            if (dist <= this.maxDistFromFinalDest && distFromLeader <= this.maxDistFromLeaderWhenAtDest) {
                // console.log('Final Destination reached!');
                // we reached our destination!
                this.characterFollower = false;
                this.characterToFollow = null;
                this.reachedFinalDestination = true;
                this.pauseCountdown = 0;
                this.movementPaused = false;
                this.randomiseDestGridPos = false;
                this.randomDestOffsetX = this.randomDestOffsetY = 0;
                this.moveToGridPos(this.finalDestination.gridX, this.finalDestination.gridY);
                // this.restartMovement();
                if (this.reachedDestinationCallback) {
                    this.reachedDestinationCallback(this);
                }
                if (this.reachedFinalDestinationCallback) {
                    this.reachedFinalDestinationCallback(this);
                }
                return true;
            }
        }
        return false;
    }

    gotoFinalDestination = () => {
        // console.log('Lead to dest char moving to: ', this, this.finalDestination)
        this.pauseCountdown = 0;
        this.movementPaused = false;
        this.randomiseDestGridPos = false;
        this.randomDestVariation = 0;
        this.randomDestOffsetX = 0;
        this.randomDestOffsetY = 0;
        this.moveToGridPos(this.finalDestination.gridX, this.finalDestination.gridY);
    }

    leftTheSceneDone = () => {
        if (this.leftSceneCallback) {
            this.leftSceneCallback(this);
        }
    }
}