export const TRUCK_COLUMNS = 7;
export const TRUCK_ROWS = 4;
export const TILE_WIDTH = 60;
export const TILE_HEIGHT = 60;
export const ASSETS_SCALE = .5;

//export const PACKAGE_1 = [[0,0,0],[0,0,0],[0,0,0]]

//[0,0,0],
//[0,0,1]
//[0,1,1]

//PACKAGE SETTINGS
export const PACKAGE_1 = [[1],[1],[1]];
export const PACKAGE_2 = [[1,1,1]];
export const PACKAGE_3 = [[1,1],[1,0]];
export const PACKAGE_4 = [[1,0],[1,1]];
export const PACKAGE_5 = [[0,1],[1,1]];
export const PACKAGE_6 = [[1,1],[0,1]];
export const PACKAGE_7 = [[1,1],[1,1]];
export const PACKAGE_8 = [[1,1,1],[0,1,0]];
export const PACKAGE_9 = [[0,1,0],[1,1,1]];


//CONVEYOR BELT SETTINGS
export const CONVEYER_START_SPEED = 2;
export const CONVEYER_MAX_SPEED = 6;
export const CONVEYER_START_Y = 0;
export const CONVEYER_END_Y = 2424;
export const CONVEYER_X = 400;

export const RELEASE_SPEED_START = 5;
export const RELEASE_SPEED_MIN = 2;
export const RELEASE_TIME_DEDUCTION = .2; // This is the amount of seconds to reduce form the release time after each new package is created

//TRUCK SETTINGS
export const NUMBER_OF_LOADING_BAYS = 3;
export const LOADING_BAY_HEIGHT = 500;
export const LOADINGBAY_FIRST_Y = 500;
export const LOADINGBAY_X = 680;
export const TRAILER_OFFSET_X = 30;
export const TRAILER_OFFSET_Y = 70;

export const TRUCK_DELIVERY_TIME = 7;
export const TRUCK_REVERSE_TIME = 2.5;
export const TRUCK_LEAVE_TIME = .7;

export const PARCEL_COLLECTED_EVENT = new Event('c');

export const HUD_100_SCORE = 50;
export const HUD_0_SCORE = 0;
export const STAR1_SCORE = 14;
export const STAR2_SCORE = 30;
export const STAR3_SCORE = 40;
export const HUD_PROGRESS_ASCENDING = true;