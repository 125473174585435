import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import game from '../../../controller/GameController';
import { v } from '../../../koko-framework/shorthand';
import app from '../../../koko-framework/app';

import AdvisorIcon from '../../../assets/babcock_menus/in_game/dialog/advisor_dialogue.png';
import DialogArrow from '../../../assets/babcock_menus/in_game/dialog/dialogue_scroll.png';
import TimerBg from '../../../assets/babcock_menus/timer_HUD.png';
import LitterBg from '../../../assets/babcock_menus/litter_HUD.png'

import PositionableReactView from "../../../koko-framework/ui/react-views/PositionableReactView";
import { PANEL_STATE } from "../../../Bootstrap";
import WorldController from "../../../controller/WorldController";
import { getDialogDataFromId, getEntityDataFromId, LITTER_COUNT, NEIGHBOUR_NPC } from "../../model/constants";
import { GameState } from "../../model/GameState";
import BabcockController from "../../controller/BabcockController";

export default class LitterCounter extends PositionableReactView {
    constructor(props) {
        super(props);

        // Fix this panel to the bottom middle. Our css class bottom-panels-bg positions the panel relative to that.
        this.state.left = 20;
        this.state.top = 140;
        this.state.litterCollected = GameState.litterCollected;
    }

    updateLitterCount = () => {
        this.setState({
            litterCollected: GameState.litterCollected
        });
    }

    closePanel = () => {
        v.hideView(this.props.viewId);
        WorldController.enable();
    }

    renderContent() {
        return (
            <div className="hud-timer-panel rel no-select default-cursor">
                <img className="hud-timer-bg" src={LitterBg} />
                <p className="hud-timer-readout" aria-label={this.state.litterCollected + ' pieces of pitter collected out of ' + LITTER_COUNT}>{this.state.litterCollected}/{LITTER_COUNT}</p>
            </div>
        );
    }
}