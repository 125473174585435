import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import game from '../../../controller/GameController';
import { au, v } from '../../../koko-framework/shorthand';
import WorldController from "../../../controller/WorldController";

import BabcockController from "../../controller/BabcockController";
import * as BABCOCK_CONSTANTS from '../../model/constants';

import SmeatonsSelfie from '../../../assets/babcock_menus/selfie_location/reward/polaroid_temp.png';

import ProgressBg from '../../../assets/babcock_menus/selfie_location/reward/progress_bg.png';
import ProgressTop from '../../../assets/babcock_menus/selfie_location/reward/progress_top.png';
import ProgressSegment from '../../../assets/babcock_menus/selfie_location/reward/progress_segment.png';
import ContinueButton from '../../../assets/babcock_menus/selfie_location/reward/continue_btn.png';
import { GameState } from "../../model/GameState";

const PROGRESS_SEGMENT_SPACING = 42.5;

export default class SelfieResult extends ReactFullScreenView {
    closePanel = () => {
        v.hideView(this.props.viewId);
        WorldController.enable();

        au.play('selfiecont', 1, false, 0, false);
    }

    renderContent() {
        let locationData = BABCOCK_CONSTANTS.getLocationDataFromId(BabcockController.lastInteraction);
        return (
            <div className="full-screen-holder reward-bg no-select default-cursor rel">

                <div className="view-row grow"></div>

                <div className="view-row shrink centered">
                    <p className="white-header bold-text">{locationData.name}<br />Photo Taken</p>
                </div>

                <div className="view-row shrink centered">
                    <img className="selfie-result-pic" src={locationData.selfiePic} alt={'Photo of ' + locationData.name} />
                </div>

                <div className="view-row shrink">
                    <p className="white-sub-heading">Photo task progress:</p>
                </div>

                <div className="view-row shrink">
                    <div className="selfie-result-progress-bar rel">
                        <img className="selfie-result-progress-bar abs" src={ProgressBg} alt="" />
                        {
                            GameState.selfiesTaken.map(function(item, i) {
                                let left = 5 + i * PROGRESS_SEGMENT_SPACING;
                                return (
                                    <img key={'progress_' + i} className="selfie-result-progress-bar-segment abs" style={{left: left, top: 4}} src={ProgressSegment} />
                                )
                            })
                        }
                        <img className="selfie-result-progress-bar abs" src={ProgressTop} alt="" />
                    </div>
                </div>

                <div className="view-row shrink">
                    <p className="selfie-result-progress-stat" aria-label={GameState.selfiesTaken.length + ' out of 5 photos taken'}>{GameState.selfiesTaken.length} / 5</p>
                </div>

                <div className="view-row shrink centered">
                    <p className="white-sub-heading small-text">To complete this task, take a photo of each<br />of the locations on your CV.</p>
                </div>

                <div className="view-row shrink">
                    <img className="take-selfie-button active-button" src={ContinueButton} onClick={this.closePanel} alt="Click to Continue" />
                </div>

                <div className="view-row grow"></div>

            </div>
        );
    }
}