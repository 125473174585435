import koko from "../koko";
import * as PIXI from 'pixi.js'
import objectPooler from "../objectPooler";

/** @author Carl Trelfa
 * 
 * Usage:
 * 
 * You will need to precess your raw tileset data with some handy exported functions before they can be used by the tile engines (isometric or othagonal).
 * 
 * The process is roughly:
 * 
 *      import { ProcessJsonTileset, InitTilesets } from './here'
 * 
 *      // if you want to use json files that have been outputted from Tiled (recommended):
 *      let rawTileData = {};
 * 
 *      // Do this for each tileset
 *      let oneTileSet = ProcessJsonTileset(jsonData, gridHeight);
 *      rawTileData[oneTileSet.name] = oneTileSet.tiles;
 * 
 * You can then post process your tileset raw data if you want to make some of them support additional features, 
 * such as animated tiles, state switchers, dynamic tiles or change offsets. Most of this data can be added within Tiled, however,
 * so post processing is mostly useful if you want to change the anchor or render offset for a tile (default anchor is bottom - middle, pinned to bottom),
 * who knows, maybe you want some tiles centered or something...
 * 
 * OR you can just manually enter data for all your tiles...
 * 
 * Then you need to process the raw data in to TileSet class objects that can be used by our renderer.
 * 
 *      InitTilesets(rawTileData);
 * 
 * The exported const ProcessedTilesets now contains the processed tilesets.
 * 
 *      import { ProcessedTilesets } from './here'
 * 
 * You can now use your tilesets with the tile engine!
 * 
 * You only need to do this once, but if you need to change your tilesets, you can call UnloadTilesets and re-process them.
 * 
 * The next step is to process your map chunks.
 * 
 */

// No need to export this class as you will never need to directly instantiate it
class TileSet {
    id = '';
    configLookup = null; /* an object for easy lookup of our tiles */
    
    // You'll need to set these directly if used
    leadingZerosForAnims = 3;
    startFrameForAnims = 1;

    /* id - should match the filename of your tsx file from Tiled (minus the .tsx part, 
    *       you can export tilesets as json and process them in to this format)
    *       the engine will map the tilesets from the "source" field in your tile maps
    *  tilesArray - an array of tile objects which should match the tiles in your Tiled tilesets
    *       Object format:
    *               {
    *                   id: int (the id of the tiles in Tiled),
    *                   texture: string (the texture frameID for pixi) (or an array if you want to randomise the textures),
    *                       [optional] animation: string, Anim Id Prefix
    *                       [optional] animationFrames: number, the number of frames
    *                   anchor: the anchor of the texture {x: 0 - 1, y: 0 - 1} like pixi, although for json style this will be 0.5, 1
    *                   offsetX / offsetY: where the 0, 0 point will be inside our tile {x: 0, y: 0} would be the center
    *                       [optional] + any extra properties you want to add for your tile
    *               }
    * 
    *       We also support dynamic sprites and state switcher sprites, but these are not well commented. They were used in Pokemon, Crazy Cliffs.
    *       Additional comments about those will be added if I need to use them as I need to look at the Pokemon code to refresh my memory of it.
    */
    constructor(id, tilesArray) {
        this.id = id;
        this.configLookup = {};
        for (var i = 0; i < tilesArray.length; i++) {
            var tid = tilesArray[i].id;
            this.configLookup[tid] = tilesArray[i];
        }
    }

    getTile(tid) {
        return this.configLookup[tid];
    }

    getTileSprite(tid, distortionPoints = null) {
        var tConfig = this.getTile(tid);
        var tSprite = null;
        var spriteID = null;
        if (tConfig.renderAs && typeof tConfig.renderAs === 'string') {
            spriteID = tConfig.renderAs;
        } else
        if (tConfig.randomise && typeof tConfig.texture != 'string') {
            spriteID = tConfig.texture[Math.floor(Math.random() * tConfig.texture.length)];
        } else {
            spriteID = tConfig.texture;
        }
        if (typeof tConfig.blocker_grid === 'string') {
            tConfig.blocker_grid = JSON.parse(tConfig.blocker_grid);
        }
        if (typeof tConfig.exit_dir === 'string') {
            tConfig.exit_dir = JSON.parse(tConfig.exit_dir);
        }
        if (typeof tConfig.isoFootprint === 'string') {
            tConfig.isoFootprint = JSON.parse(tConfig.isoFootprint);
        }
        if (typeof objectPooler != 'undefined') {
            tSprite = objectPooler.getObjectFromPool(spriteID);
        }
        // console.log('from pool: ', tSprite);
        if (tSprite == null) {
            tSprite = new PIXI.Container();
            tSprite.trimBottom = 0;
            
            var tSprite_default = null;
            if (tConfig.animation && typeof tConfig.animation == 'string' && tConfig.animationFrames && typeof tConfig.animationFrames == 'number') {
                let leadingZeros = tConfig.animationLeadingZeros || this.leadingZerosForAnims;
                tSprite_default = this.createAnim(tConfig.animation, tConfig.animationFrames, leadingZeros);
                tSprite_default.animationSpeed = tConfig.animSpeed || 0.5;
                if (tConfig.randomAnimPlayMin && tConfig.randomAnimPlayMax) {
                    tSprite_default.loop = false;
                    this.startRandomAnimPlay(tSprite_default, tConfig);
                } else {
                    tSprite_default.loop = true;
                    let startFrame = 0;
                    if (tConfig.randomStartFrame === 'true' || tConfig.randomStartFrame === true) {
                        startFrame = Math.floor(Math.random() * tConfig.animationFrames);
                    }
                    if (tSprite_default.loop) {
                        tSprite_default.gotoAndPlay(startFrame);
                    }
                }
                tSprite_default.anchor.x = tConfig.anchor.x;
                tSprite_default.anchor.y = tConfig.anchor.y;
                tSprite.defaultSprite = tSprite_default;
                tSprite.addChild(tSprite_default);
            } else {
                if (tConfig.use_water_distorter && distortionPoints !== null) {
                    let t = PIXI.Texture.from(spriteID);
                    tSprite_default = new PIXI.SimpleRope(t, distortionPoints);
                    tSprite_default.pivot.x = 0.5 * t.width;
                    tSprite_default.pivot.y = 0.5 * t.height;
                    tSprite_default.scale.y = 1.02;
                    tSprite_default.scale.x = 1.1;
                } else {
                    tSprite_default = PIXI.Sprite.from(spriteID);
                    tSprite_default.anchor.x = tConfig.anchor.x;
                    tSprite_default.anchor.y = tConfig.anchor.y;
                    if (tSprite_default._texture.trim) {
                        tSprite.trimBottom = tSprite_default.height - (tSprite_default._texture.trim.y + tSprite_default._texture.trim.height);
                    }
                }
                tSprite.defaultSprite = tSprite_default;
                tSprite.addChild(tSprite_default);
            }
            // optional dynamic sprites
            // these have a reference to some data that your game can use to hide or show the relevant sprite
            // possibly based on surrounding tiles, eg. a wall that can have parts destroyed will need it's graphics updating
            // so the wall looks nice in all cases
            //
            // CURRENTLY ONLY SUPPORTED ON ORTHAGONAL TILE MAPS
            tSprite.dynamicSprites = [];
            if (tConfig.dynamic && tConfig.dynamicTextures && tConfig.dynamicTextures.length) {
                for (var i = 0; i < tConfig.dynamicTextures.length; i++) {
                    var tSprite_dynamic = PIXI.Sprite.from(tConfig.dynamicTextures[i].texture);
                    tSprite_dynamic.anchor.x = typeof tConfig.dynamicTextures[i].anchor != 'undefined' ? tConfig.dynamicTextures[i].anchor.x : tConfig.anchor.x;
                    tSprite_dynamic.anchor.y = typeof tConfig.dynamicTextures[i].anchor != 'undefined' ? tConfig.dynamicTextures[i].anchor.y : tConfig.anchor.y;
                    if (typeof tConfig.dynamicTextures[i].offset != 'undefined') {
                        tSprite_dynamic.x = tConfig.dynamicTextures[i].offset.x;
                        tSprite_dynamic.y = tConfig.dynamicTextures[i].offset.y;
                    }
                    if (typeof tConfig.dynamicTextures[i].scale != 'undefined') {
                        tSprite_dynamic.scale.x = tConfig.dynamicTextures[i].scale.x;
                        tSprite_dynamic.scale.y = tConfig.dynamicTextures[i].scale.y;
                    }
                    tSprite_dynamic.dynamicData = tConfig.dynamicTextures[i];
                    tSprite_dynamic.visible = false;
                    tSprite.dynamicSprites.push(tSprite_dynamic);
                    tSprite.addChild(tSprite_dynamic);
                }
            }
            // optional switcher tiles, these switch between 2 states, active and inactive
            // activate and deactivate anims must be included, along with an activateion time (in seconds)
            tSprite.stateSwitchAnims = [];
            if (tConfig.stateSwitcher) {
                // we'll just assume we have all the config data we need
                var activateAnim = objectPooler.getObjectFromPool(tConfig.activateAnim);
                if (activateAnim == null) {
                    activateAnim = this.createAnim(tConfig.activateAnim, tConfig.activateAnimFrames);
                    activateAnim.loop = false;
                }
                activateAnim.anchor.x = tConfig.anchor.x;
                activateAnim.anchor.y = tConfig.anchor.y;
                activateAnim.visible = false;
                activateAnim.gotoAndStop(0);
                tSprite.addChild(activateAnim);
                tSprite.stateSwitchAnims.push(activateAnim);

                var deactivateAnim = objectPooler.getObjectFromPool(tConfig.deactivateAnim);
                if (deactivateAnim == null) {
                    deactivateAnim = this.createAnim(tConfig.deactivateAnim, tConfig.deactivateAnimFrames);
                    deactivateAnim.loop = false;
                }
                deactivateAnim.anchor.x = tConfig.anchor.x;
                deactivateAnim.anchor.y = tConfig.anchor.y;
                deactivateAnim.visible = false;
                deactivateAnim.gotoAndStop(0);
                tSprite.addChild(deactivateAnim);
                tSprite.stateSwitchAnims.push(deactivateAnim);
            }

            tSprite.spriteID = spriteID + '_tile';
        }

        if (tConfig.scale) {
            tSprite.scale.x = tSprite.scale.y = tConfig.scale;
            if (tConfig.flipY) {
                tSprite.scale.y = -tConfig.scale;
            } 
            if (tConfig.flipX) {
                tSprite.scale.x = -tConfig.scale;
            }
        }
        if (tConfig.scaleup) {
            tSprite.scale.x = 1 + tConfig.scaleup;
            // tSprite.scale.x = 1 + tConfig.scaleup / 2;
        }
        if (tConfig.random_flip == true) {
            tSprite.scale.x = Math.random() < 0.5 ? -1 : 1;
        }

        tSprite.collected = false;
        tSprite.collided = false;
        tSprite.alpha = 1;
        tSprite.visible = true;
        if (tConfig.moving && typeof tConfig.velocityX != 'undefined' && typeof tConfig.velocityY != 'undefined') {
            tSprite.moving = true;
            tSprite.active = false;
            var vMult = 1;
            if (typeof tConfig.randomiseSpeed == 'number') {
                vMult = 1 + Math.random() * (tConfig.randomiseSpeed - 1);
            }
            tSprite.velocity = {
                    x: tConfig.velocityX * vMult,
                    y: tConfig.velocityY * vMult,
                };
        } else
        if (typeof tConfig.startActive != 'undefined') {
            tSprite.active = tConfig.startActive;
        } else {
            tSprite.active = true;
        }
        tSprite.defaultSprite.visible = true;
        tSprite.defaultSprite.alpha = 1;

        if (tConfig.stateSwitcher) {
            tSprite.switchCountdown = tConfig.switchTime;
            tSprite.stateSwitchAnims[0].visible = false;
            tSprite.stateSwitchAnims[1].visible = false;
            // console.log('got switcher: ', tSprite);
        }

        /*
        if (this.id === 'PennaKloos_objects1') {
            console.log(tSprite);
        }
        */

        return tSprite;
    }

    startRandomAnimPlay(anim, config) {
        let timeOutInterval = (config.randomAnimPlayMin * 1000) + (Math.random() * (config.randomAnimPlayMax - config.randomAnimPlayMin) * 1000);
        setTimeout(() => {this.randomAnimPlay(anim, config)}, timeOutInterval);
    }

    randomAnimPlay(anim, config) {
        if (anim && config) {
            anim.gotoAndPlay(1);
            anim.onComplete = () => {
                this.startRandomAnimPlay(anim, config);
            }
        }
    }

    createAnim(animID, frameCount, leadingZeros = null) {
        /*
        var animFrames = [];
        for (var i = 1; i <= frameCount; i++) {
            var tid = i < 10 ? animID + '0' + i + '.png' : animID + i + '.png';
            var t = PIXI.Texture.from(tid);
            animFrames.push(t);
        }
        var a = new PIXI.AnimatedSprite(animFrames);
        // a.animationSpeed = 0.5;
        */
        return koko.display.createAnim(animID, frameCount, leadingZeros || this.leadingZerosForAnims, this.startFrameForAnims);
        // return a;
    }
}

/* Process raw tilesets in to TileSet classes.
*  On the face of it this seems a little pointless, like I may as well just have the data in the right format
*  to start, but I'm hoping the raw passed in data ends up easier to manage in the source code.
*  
*   tilesetsRawData is an object in the following format:
*       {
*           'tilesetid' : tilesArray,
*           'tilesetid' : tilesArray,
*                   ...
*           'tilesetid' : tilesArray,
*       }
*
*   We putting out processed tilesets in to an export global called ProcessedTilesets
*/
export const ProcessedTilesets = {};
export function InitTilesets(tilesetsRawData) {
    for (var prop in tilesetsRawData) {
        var tileSet = new TileSet(prop, tilesetsRawData[prop]);
        ProcessedTilesets[prop] = tileSet;
    }
}

export function InitJsonTilesets(jsonTilesetsArray, gridHeight = 64, texturePrefixes = null) {
    let tileSetsReadyForProcessing = {};
    for (let i = 0; i < jsonTilesetsArray.length; i++) {
        let rawTileset = ProcessJsonTileset(jsonTilesetsArray[i], gridHeight, texturePrefixes === null ? '' : texturePrefixes[i]);
        tileSetsReadyForProcessing[rawTileset.name] = rawTileset.tiles;
    }
    InitTilesets(tileSetsReadyForProcessing);
    console.log('Processed Tilesets: ', ProcessedTilesets);
}

/**
 * This might be useful if you want to change the tilesets, but use the same maps (maybe change the colour of the tiles?)
 */
export function UnloadTilesets() {
    for (var prop in ProcessedTilesets) {
        ProcessedTilesets[prop] = null;
        delete ProcessedTilesets[prop];
    }
}

/**
 * Process json data for tilesets as outputted by Tiled.
 * Always aligns tiles to the bottom center of the grid square, only supports static tiles.
 * This is meant as a quick way to get your tilesets set up so you don't need to manually enter
 * data for every tile.
 * If you want to used advanced features such as animated tiles, dynamic tiles or state switching tiles you will
 * need to add this extra data to the returned tileset data, or if you are clever you can add the data in Tiled.
 * @param {string} jsonData - json data output from Tiled
 * @param {*} gridHeight - we only need the grid height to align the tiles
 * @param {*} texturePrefix - if your TP datasheet has a prefix for your tile textures, you can pass it in
 * @returns 
 */
export function ProcessJsonTileset(jsonData, gridHeight = 64, texturePrefix = '') {
    let jsonObj = typeof jsonData === 'string' ? JSON.parse(jsonData) : jsonData;
    let tileSetData = {};
    tileSetData.name = jsonObj.name;
    tileSetData.tiles = [];
    for (let i = 0; i < jsonObj.tiles.length; i++) {
        let tileDataObj = {};
        tileDataObj.id = jsonObj.tiles[i].id;
        // image data in Tiled's tileset json files may contain path data, we only need the filename as it comes from a TP Spritesheet
        // we can optionally include a texturePrefix if we are using smart folders in TP
        let imgSplit = jsonObj.tiles[i].image.split('/');
        tileDataObj.texture = texturePrefix + imgSplit[imgSplit.length - 1];
        // default anchor in Tiled is bottom left, but we are going to anchor at the bottom middle
        tileDataObj.anchor = {x: 0.5, y: 1};
        // align our tile to the bottom of the grid square (our renderer treats the middle of the square as 0, 0)
        tileDataObj.offset = {x: 0, y: (gridHeight / 2) - 1};
        if (typeof jsonObj.tiles[i].properties !== 'undefined') {
            for (let j = 0; j < jsonObj.tiles[i].properties.length; j++) {
                tileDataObj[jsonObj.tiles[i].properties[j].name] = jsonObj.tiles[i].properties[j].value;
                if (jsonObj.tiles[i].properties[j].name === 'offsetX') {
                    tileDataObj.offset.x += jsonObj.tiles[i].properties[j].value;
                }
                if (jsonObj.tiles[i].properties[j].name === 'offsetY') {
                    tileDataObj.offset.y += jsonObj.tiles[i].properties[j].value;
                }
            }
        }
        tileSetData.tiles.push(tileDataObj);
    }
    return tileSetData;
}