import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import { v } from '../../../koko-framework/shorthand'
import CookiesLogo from '../../../assets/babcock_menus/cookies_pop/cookies_img.png';
import LearnMoreButton from '../../../assets/babcock_menus/cookies_pop/learnmore_btn.png';
import AcceptButton from '../../../assets/babcock_menus/cookies_pop/accept_btn.png';

export default class BottomBehindGradient2 extends ReactFullScreenView {
    renderContent() {
        return (
            <div className="full-screen-holder no-select default-cursor">
                <div className="behind-bottom-gradient"></div>
            </div>
        )
    }
}