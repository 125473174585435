const client = {
    ua: '',
    cssprops: {},
    isTouch: (("ontouchstart" in window) || (window.navigator && window.navigator.msPointerEnabled && window.MSGesture && navigator.msMaxTouchPoints > 0) /*|| window.DocumentTouch && document instanceof DocumentTouch*/),

    calculatesupportedprop: function (name, proparray) {
        var root = document.documentElement;
        for (var i = 0; i < proparray.length; i++) {
            if (proparray[i] in root.style) {
                this.cssprops[name] = proparray[i];
                break;
            }
        }
    },

    init: function () {
        this.ua = navigator.userAgent.toLowerCase();
        this.browser.standalone = !!navigator.standalone;
        this.browser.facebook = this.ua.indexOf("fbav") > -1;
        //claculate all browser, engine and system info
        this.calculateInfo();
        //orientation properties
        this.calculateOrientation();
        this.calculatesupportedprop('transform', ['transform', 'MozTransform', 'WebkitTransform', 'msTransform', 'OTransform']);
    },

    calculateOrientation: function () {
        var o = window.orientation;
        if (o === 0 || o === 180) this.browser.orientation = 'portrait';
        else if (o === 90 || o === -90) this.browser.orientation = 'landscape';
        else {
            if (document.documentElement.clientWidth > document.documentElement.clientHeight) {
                this.browser.orientation = 'landscape';
            } else {
                this.browser.orientation = 'portrait';
            }
        }
        return this.browser.orientation;
    },

    test: function (r) {
        return new RegExp(r.toLowerCase()).test(this.ua);
    },

    engine: { ie: 0, gecko: 0, webkit: 0, khtml: 0, opera: 0, ver: null },
    browser: { facebook: false, standalone: true, orientation: 'landscape', ie: 0, firefox: 0, safari: 0, konq: 0, opera: 0, chrome: 0, ver: null },
    system: { win: false, mac: false, x11: false, iphone: false, ipod: false, ipad: false, nokiaN: false, winMobile: false, macMobile: false, android: false, wii: false, ps: false, isMobile: false },
    calculateInfo: function () {
        var ua = this.ua;
        var engine = this.engine;
        var browser = this.browser;
        var system = this.system;
        if (window.opera) {
            engine.ver = browser.ver = window.opera.version();
            engine.opera = browser.opera = parseFloat(engine.ver);
        } else if (/applewebkit\/(\S+)/.test(ua)) {
            engine.ver = RegExp["$1"];
            engine.webkit = parseFloat(engine.ver);
            //figure out if it's Chrome or Safari
            if (/chrome\/(\S+)/.test(ua)) {
                browser.ver = RegExp["$1"];
                browser.chrome = parseFloat(browser.ver);
            } else if (/version\/(\S+)/.test(ua)) {
                browser.ver = RegExp["$1"];
                browser.safari = parseFloat(browser.ver);
            } else {
                //approximate version
                var safariVersion = 1;
                if (engine.webkit < 100) {
                    safariVersion = 1;
                } else if (engine.webkit < 312) {
                    safariVersion = 1.2;
                } else if (engine.webkit < 412) {
                    safariVersion = 1.3;
                } else {
                    safariVersion = 2;
                }
                browser.safari = browser.ver = safariVersion;
            }
        } else if (/khtml\/(\S+)/.test(ua) || /konqueror\/([^;]+)/.test(ua)) {
            engine.ver = browser.ver = RegExp["$1"];
            engine.khtml = browser.konq = parseFloat(engine.ver);
        } else if (/rv:([^)]+)\) gecko\/\d{8}/.test(ua)) {
            engine.ver = RegExp["$1"];
            engine.gecko = parseFloat(engine.ver);
            //determine if it's Firefox
            if (/firefox\/(\S+)/.test(ua)) {
                browser.ver = RegExp["$1"];
                browser.firefox = parseFloat(browser.ver);
            }
        } else if (/msie ([^;]+)/.test(ua) || ua.match(/trident/)) {
            engine.ver = browser.ver = RegExp["$1"];
            engine.ie = browser.ie = isNaN(engine.var) ? 11 : parseFloat(engine.ver);
        }
        //detect browsers
        browser.ie = engine.ie;
        browser.opera = engine.opera;
        //detect platform
        var p = navigator.platform;
        system.win = p.indexOf("Win") === 0;
        system.mac = p.indexOf("Mac") === 0;
        system.x11 = (p === "X11") || (p.indexOf("Linux") === 0);
        //detect windows operating systems
        if (system.win) {
            if (/win(?:dows )?([^do]{2})\s?(\d+\.\d+)?/.test(ua)) {
                if (RegExp["$1"] === "NT") {
                    switch (RegExp["$2"]) {
                        case "5.0":
                            system.win = "2000";
                            break;
                        case "5.1":
                            system.win = "XP";
                            break;
                        case "6.0":
                            system.win = "Vista";
                            break;
                        default:
                            system.win = "NT";
                            break;
                    }
                } else if (RegExp["$1"] === "9x") {
                    system.win = "ME";
                } else {
                    system.win = RegExp["$1"];
                }
            }
        }
        //mobile devices
        system.iphone = ua.indexOf("iphone") > -1;
        system.iphone4 = system.iphone && (window.screen.height === 480);
        system.ipad = ua.indexOf("ipad") > -1;
        system.ipod = ua.indexOf("ipod") > -1;
        system.nokiaN = ua.indexOf("nokian") > -1;
        system.winMobile = (system.win === "CE");
        system.macMobile = (system.iphone || system.ipod || system.ipad);
        system.android = ua.toLowerCase().indexOf("android") > -1;
        system.isMobile = system.winMobile || system.macMobile || system.android;
        //gaming systems
        system.wii = ua.indexOf("wii") > -1;
        system.ps = /playstation/i.test(ua);
    },

    events: {
        down: ['mousedown', 'touchstart', 'MSPointerDown'],
        move: ['mousemove', 'touchmove', 'MSPointerMove'],
        up: ['mouseup', 'touchend', 'MSPointerUp'],
        out: ['mouseout', 'touchleave', 'mouseout']
    },

    eventName: function (def) {
        return this.events[def][this.isTouch ? window.navigator.msPointerEnabled ? 2 : 1 : 0];
    },

    getStyle: function (oElm, strCssRule) {
        var strValue = "";
        if (document.defaultView && document.defaultView.getComputedStyle) {
            strValue = document.defaultView.getComputedStyle(oElm, "").getPropertyValue(strCssRule);
        }
        else if (oElm.currentStyle) {
            strCssRule = strCssRule.replace(/-(\w)/g, function (strMatch, p1) {
                return p1.toUpperCase();
            });
            strValue = oElm.currentStyle[strCssRule];
        }
        return strValue;
    }
}

export { client as default };
