import koko from './koko'

const cookies = {
    set: function (name, value, days) {
        var date, expires;
        if (days) {
            date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        } else expires = "";
        document.cookie = name + "=" + value + expires + "; path=/";
    },
    get: function (name) {
        var coname = name + "=";
        var co = document.cookie.split(';');
        for (var i = 0; i < co.length; i++) {
            var c = co[i];
            c = c.replace(/^\s+/, '');
            if (c.indexOf(coname) === 0) return c.substring(coname.length, c.length);
        }
        return null;
    },
    remove: function (name) {
        koko.cookies.set(name, "", -1);
    },
    getAll: function () {
        var splits = document.cookie.split(";");
        var cookies = {};
        for (var i = 0; i < splits.length; i++) {
            var split = splits[i].split("=");
            cookies[split[0]] = unescape(split[1]);
        }
        return cookies;
    },
    removeAll: function () {
        var cookies = koko.cookies.getAll();
        for (var key in cookies) {
            if (cookies.hasOwnProperty(key)) {
                koko.cookies.remove(key);
            }
        }
    }
}

export { cookies as default }