//Containers
export const NO_OF_CONTAINERS = 6;
export const LAYOUT_CUP_DISTANCE_X = 185;
export const LAYOUT_CUP_DISTANCE_Y = 280;
export const ASSETS_SCALE = .5;

//Liquid Object Vars
export const LIQUID_COLOURS = [0x997444, 0xF3D034, 0x48AD40, 0x1C3E90, 0xCC0066]; //[Brown, Yellow, Green, Blue, Pink]
export const LIQUID_VOLUME_TOTAL = 100;
export const LIQUID_VOLUME_SPLIT = 4;
export const MAX_STARTING_COLOUR_SPLITS = 2;    // only allow this number of each colour in a cup at the start, 2 should be fine with 6 cups / 4 split
                                                // but may need raising for higher split values, less colours etc (otherwise you might get infinite loops)

//GRAPHICS
export const MASK_SPRITE_PATTERNS = ['brownPattern.png','yellowPattern.png','greenPattern.png','bluePattern.png','pinkPattern.png'];
export const ASSET_SCALE = .5;
export const ASSET_LIQUID_CONTAINER_WIDTH = 195;
export const ASSET_LIQUID_CONTAINER_HEIGHT = 245;
export const ASSET_ANGLED_SIDE_X_OFFSET = 45;
export const ASSET_LIQUID_TOP_RADIUS = 35;

export const HUD_100_SCORE = 0;
export const HUD_0_SCORE = 40;
export const STAR1_SCORE = 40;
export const STAR2_SCORE = 30;
export const STAR3_SCORE = 20;
export const HUD_PROGRESS_ASCENDING = false;