import koko from './koko-framework/koko'
import { Howler } from 'howler'
import { PreloaderView } from './view/preloader'
import { InGameView } from './view/InGameView'
import { WorldView } from './view/WorldView'
import app from './koko-framework/app'
import { v, a, co, au } from './koko-framework/shorthand'
import { HudView } from './babcock-neet/view/HudView'
import { GreenBg } from './babcock-neet/view/GreenBg'

import {REACT_PRELOADER} from './babcock-neet/view/react-views/PreloadReactAssets'
import { LL_HUDView } from './babcock-neet/coffee-game/view/LL_HUDView'
import { LiquidLevelController } from './babcock-neet/coffee-game/controller/LiquidLevelController'
import { FB_HUDView } from './babcock-neet/food-bank-game/view/FB_HUDView'
import { DeliveryGameController } from './babcock-neet/food-bank-game/controller/DeliveryGameController'
import { PipeDreamController } from './babcock-neet/hotel-game/controller/PipeDreamController'
import { PG_HUDView } from './babcock-neet/hotel-game/view/PG_HUDView'
import ReactGA from 'react-ga4';

const main = {
    cookiesAccepted: false,
    soundsToLoad: 0,
    loadTestMap: false,
    testMapData: null,

    init: function () {
        ReactGA.initialize('G-TH4DF8726N');
        console.log('initialise GA')
        this.setupViews();
        
        if (!this.loadTestMap) {
            this.setupComplete();
        } else {
            this.loadTestMapNow();
        }
    },

    setupViews: function () {
        koko.views.add('greenbg', new GreenBg(), app.stage);

        koko.views.add('preloader', new PreloaderView(), app.stage);
        // koko.views.add('mainmenu', new MenuView(), app.stage);
        // koko.views.add('ingame', new InGameView(), app.stage);
        koko.views.add('worldview', new WorldView(), app.stage);
        koko.views.add('hudview', new HudView(), app.stage);
        // koko.views.add('ingamehud', new InGameHUDView(), app.stage);
        // koko.views.add('pausescreen', new PauseScreenView(), app.stage);
        // console.log('setting up views: ', koko);

        // mini games
        koko.views.add('coffeegame_view', new LiquidLevelController(), app.stage);
        koko.views.add('coffeegame_hudView', new LL_HUDView(), app.stage);

        koko.views.add('fb_gameView', new DeliveryGameController(), app.stage);
        koko.views.add('fb_hudView', new FB_HUDView(), app.stage);

        koko.views.add('pg_gameView', new PipeDreamController(), app.stage);
        koko.views.add('pg_hudView', new PG_HUDView(), app.stage);
    },

    setupAudio: function () {
        koko.audio.dir = 'audio/'; // local pathname
        //koko.audio.dir = '//s3.amazonaws.com/kokostatic/satsumabowl/audio/';  //AMAZON S3 PATH

        koko.audio.init();
        koko.audio.addSoundGroup('music', 1);
        koko.audio.addSoundGroup('sfx', 1);

        // koko.audio.add(name, url, volume, group, size || 1);
        // AUTOMATICALLY SEARCHES FOR .ogg && .mp3 DEPENDING ON BROWSER CAPABILITY
        // this.addSound('music', 'MUSIC_PLACEHOLDER', 1, 'music', 1);
        //
        // this.addSound('sound', 'SOUND_PLACEHOLDER', 1, 'sfx', 1);

        this.addSound('ocean-waves-1', 'ocean-waves-1', 0.6, 'sfx', 1);
        this.addSound('ocean-waves-2', 'ocean-waves-2', 0.6, 'sfx', 1);
        this.addSound('ocean-waves-3', 'ocean-waves-3', 0.6, 'sfx', 1);

        /*
        this.addSound('gull1', 'gull1', 0.5, 'sfx', 1);
        this.addSound('gull2', 'gull2', 0.5, 'sfx', 1);
        this.addSound('gull3', 'gull3', 0.5, 'sfx', 1);
        this.addSound('gull4', 'gull1', 0.5, 'sfx', 1);
        this.addSound('gull5', 'gull2', 0.5, 'sfx', 1);
        this.addSound('gull6', 'gull3', 0.5, 'sfx', 1);
        this.addSound('gull7', 'gull1', 0.5, 'sfx', 1);
        this.addSound('gull8', 'gull2', 0.5, 'sfx', 1);
        this.addSound('gull9', 'gull3', 0.5, 'sfx', 1);
        this.addSound('gull10', 'gull1', 0.5, 'sfx', 1);
        this.addSound('gull11', 'gull2', 0.5, 'sfx', 1);
        this.addSound('gull12', 'gull3', 0.5, 'sfx', 1);
        this.addSound('gull13', 'gull1', 0.5, 'sfx', 1);
        this.addSound('gull14', 'gull2', 0.5, 'sfx', 1);
        this.addSound('gull15', 'gull3', 0.5, 'sfx', 1);
        this.addSound('gull16', 'gull1', 0.5, 'sfx', 1);
        this.addSound('gull17', 'gull2', 0.5, 'sfx', 1);
        this.addSound('gull18', 'gull3', 0.5, 'sfx', 1);
        this.addSound('gull19', 'gull1', 0.5, 'sfx', 1);
        this.addSound('gull20', 'gull2', 0.5, 'sfx', 1);
        this.addSound('gull21', 'gull3', 0.5, 'sfx', 1);
        this.addSound('gull22', 'gull1', 0.5, 'sfx', 1);
        this.addSound('gull23', 'gull2', 0.5, 'sfx', 1);
        this.addSound('gull24', 'gull3', 0.5, 'sfx', 1);

        this.addSound('pigeon1', 'pigeon1', 0.5, 'sfx', 1);
        this.addSound('pigeon2', 'pigeon2', 0.5, 'sfx', 1);
        this.addSound('pigeon3', 'pigeon3', 0.5, 'sfx', 1);
        this.addSound('pigeon4', 'pigeon1', 0.5, 'sfx', 1);
        this.addSound('pigeon5', 'pigeon2', 0.5, 'sfx', 1);
        this.addSound('pigeon6', 'pigeon3', 0.5, 'sfx', 1);
        this.addSound('pigeon7', 'pigeon1', 0.5, 'sfx', 1);
        this.addSound('pigeon8', 'pigeon2', 0.5, 'sfx', 1);
        this.addSound('pigeon9', 'pigeon3', 0.5, 'sfx', 1);
        this.addSound('pigeon10', 'pigeon1', 0.5, 'sfx', 1);
        this.addSound('pigeon11', 'pigeon2', 0.5, 'sfx', 1);
        this.addSound('pigeon12', 'pigeon3', 0.5, 'sfx', 1);
        this.addSound('pigeon13', 'pigeon1', 0.5, 'sfx', 1);
        this.addSound('pigeon14', 'pigeon2', 0.5, 'sfx', 1);
        this.addSound('pigeon15', 'pigeon3', 0.5, 'sfx', 1);
        this.addSound('pigeon16', 'pigeon1', 0.5, 'sfx', 1);
        this.addSound('pigeon17', 'pigeon2', 0.5, 'sfx', 1);
        this.addSound('pigeon18', 'pigeon3', 0.5, 'sfx', 1);
        this.addSound('pigeon19', 'pigeon1', 0.5, 'sfx', 1);
        this.addSound('pigeon20', 'pigeon2', 0.5, 'sfx', 1);
        this.addSound('pigeon21', 'pigeon3', 0.5, 'sfx', 1);
        this.addSound('pigeon22', 'pigeon1', 0.5, 'sfx', 1);
        this.addSound('pigeon23', 'pigeon2', 0.5, 'sfx', 1);
        this.addSound('pigeon24', 'pigeon3', 0.5, 'sfx', 1);

        this.addSound('fountain', 'fountain', 0.5, 'sfx', 1);

        this.addSound('seaside_music', '26391049_beside-the-seaside_by_davidbeardmusicproduction_preview', 0.5, 'music', 1);
        */

        /* BABCOCK SOUNDS */
        this.addSound('babcock_ingame_music', 'BabcockInGameMusic', 0.3, 'music', 1);

        this.addSound('townambience', 'babcock/TownAmbience', 1, 'sfx', 1);
        this.addSound('seagulls', 'babcock/Seagulls', 0.3, 'sfx', 1);

        this.addSound('step1', 'babcock/step_01', 0.4, 'sfx', 1);
        this.addSound('step2', 'babcock/step_02', 0.4, 'sfx', 1);
        this.addSound('step3', 'babcock/step_03', 0.4, 'sfx', 1);
        this.addSound('step4', 'babcock/step_04', 0.4, 'sfx', 1);
        this.addSound('step5', 'babcock/step_05', 0.4, 'sfx', 1);
        this.addSound('step6', 'babcock/step_06', 0.4, 'sfx', 1);
        this.addSound('step7', 'babcock/step_07', 0.4, 'sfx', 1);
        this.addSound('step8', 'babcock/step_08', 0.4, 'sfx', 1);
        this.addSound('step9', 'babcock/step_09', 0.4, 'sfx', 1);
        this.addSound('step10', 'babcock/step_10', 0.4, 'sfx', 1);

        this.addSound('busdriveloop', 'babcock/busdriving_loop', 0.7, 'sfx', 1);
        this.addSound('busarrive', 'babcock/BusArriveDing', 0.75, 'sfx', 1);
        this.addSound('taxidriveloop', 'babcock/taxi_loop', 0.7, 'sfx', 1);
        this.addSound('taxiarrive', 'babcock/taxi_arrived', 0.75, 'sfx', 1);

        this.addSound('menu_music', 'mainmenu_loop', 0.3, 'music', 1);

        this.addSound('radio', 'babcock/interactions/radio_alert', 0.7, 'sfx', 1);
        this.addSound('phone', 'babcock/interactions/TelephoneRing', 0.5, 'sfx', 1);
        this.addSound('tutorial', 'babcock/interactions/TutorialInfo_popup', 1, 'sfx', 1);
        this.addSound('taskstep', 'babcock/interactions/Minitasks/Minitask_StepComplete', 0.5, 'sfx', 1);
        this.addSound('tasktrash', 'babcock/interactions/Minitasks/minitask_TrashCollect', 0.5, 'sfx', 1);
        this.addSound('timerloop', 'babcock/interactions/Minitasks/TimerLoop', 0.5, 'sfx', 1);
        this.addSound('timerout', 'babcock/interactions/Minitasks/TimerRunout', 0.5, 'sfx', 1);
        this.addSound('selfietake', 'babcock/interactions/Buttons/01_SelfieTaken', 0.5, 'sfx', 1);
        this.addSound('selfiecont', 'babcock/interactions/Buttons/02_SELFIE_CONTINUE', 0.5, 'sfx', 1);
        this.addSound('closebtn', 'babcock/interactions/Buttons/Close_button', 1, 'sfx', 1);
        this.addSound('itemunlockedcont', 'babcock/interactions/Buttons/ItemUnlocked_ContinueButton', 0.5, 'sfx', 1);
        this.addSound('primarybtn', 'babcock/interactions/Buttons/PrimaryButton', 0.7, 'sfx', 1);
        this.addSound('secondarybtn', 'babcock/interactions/Buttons/secondaryButton', 0.3, 'sfx', 1);

        // delivery game sound
        this.addSound('boxMissed1', 'foodbankgame/BoxMissed1', 0.2, 'sfx', 1);
        this.addSound('boxMissed2', 'foodbankgame/BoxMissed2', 0.2, 'sfx', 1);
        this.addSound('boxMissed3', 'foodbankgame/BoxMissed3', 0.2, 'sfx', 1);
        this.addSound('boxMissed4', 'foodbankgame/BoxMissed4', 0.2, 'sfx', 1);
        this.addSound('boxImpact1', 'foodbankgame/boxImpact1', 0.2, 'sfx', 1);
        this.addSound('boxImpact2', 'foodbankgame/boxImpact2', 0.2, 'sfx', 1);
        this.addSound('boxImpact3', 'foodbankgame/boxImpact3', 0.2, 'sfx', 1);
        this.addSound('impossibleMove', 'foodbankgame/impossibleMove', 0.2, 'sfx', 1);
        this.addSound('fb_level_complete', 'foodbankgame/LevelCompleted', 0.2, 'sfx', 1);
        this.addSound('truckIn', 'foodbankgame/TruckDriveIN', 0.2, 'sfx', 1);
        this.addSound('truckOut', 'foodbankgame/truckDriveOff', 0.2, 'sfx', 1);
        this.addSound('fb_music', 'foodbankgame/TruckGame_tempLoop', 0.2, 'music', 1);

        // liquid levels sound
        this.addSound('pour', 'coffeegame/coffee_pour', 1, 'sfx', 1);      
        this.addSound('pour1', 'coffeegame/coffee_pour', 1, 'sfx', 1);
        this.addSound('select1', 'coffeegame/select1', 0.4, 'sfx', 1);
        this.addSound('select2', 'coffeegame/select2', 0.4, 'sfx', 1);
        this.addSound('select3', 'coffeegame/select3', 0.4, 'sfx', 1);
        this.addSound('select4', 'coffeegame/select4', 0.4, 'sfx', 1);
        this.addSound('lands', 'coffeegame/lands', 0.4, 'sfx', 1);
        this.addSound('magic', 'coffeegame/magic', 0.4, 'sfx', 1);
        this.addSound('magic1', 'coffeegame/magic', 1, 'sfx', 1);      
        this.addSound('drip', 'coffeegame/drip', 0.4, 'sfx', 1);        
        this.addSound('ll_level_complete', 'coffeegame/LevelCompleted', 0.2, 'sfx', 1);
        this.addSound('ll_music', 'coffeegame/LiquidLevels_tempLoop', 0.2, 'music', 1);

        // plumbing game
        this.addSound('pipe_turn', 'hotelgame/PipeTurn', 1, 'sfx', 1);
        this.addSound('steam', 'hotelgame/steam', 0.5, 'sfx', 1);
        this.addSound('steam1', 'hotelgame/steam', 0.5, 'sfx', 1);
        this.addSound('water_drip', 'hotelgame/water-dripping-loop', 0.2, 'sfx', 1);
        this.addSound('drip1', 'hotelgame/drip_01', 0.4, 'sfx', 1);
        this.addSound('drip2', 'hotelgame/drip_02', 0.4, 'sfx', 1);
        this.addSound('drip3', 'hotelgame/drip_03', 0.4, 'sfx', 1);
        this.addSound('drip4', 'hotelgame/drip_04', 0.4, 'sfx', 1);
        this.addSound('drip5', 'hotelgame/drip_05', 0.4, 'sfx', 1);
        this.addSound('pp_level_complete', 'hotelgame/LevelCompleted', 0.2, 'sfx', 1);
        this.addSound('pp_music', 'hotelgame/PipeGame_tempLoop', 0.2, 'music', 1);

        /*
        if (MUTE == 'true' || MUTE == true) {
            koko.audio.setMute(true);
        } else {
            koko.audio.setMute(false);
        }
        */

        Howler.volume(0.7);
    },

    // Use this addSound function if you want the preloader to take in to account audio loading
    addSound: function (name, src, volume, group, size) {
        this.soundsToLoad++;
        koko.audio.add(name, src, volume, group, size);
    },

    loadTestMapNow: function () {
        var xobj = new XMLHttpRequest();
        xobj.overrideMimeType("application/json");
        xobj.open('GET', './testmap/testmap.json', true);
        xobj.onreadystatechange = function () {
            if (xobj.readyState == 4 && xobj.status == "200") {
                main.testMapData = JSON.parse(xobj.responseText);
                // console.log(main.testMapData);
                main.setupComplete();
            }
        };
        xobj.send(null);
    },

    setupComplete: function () {
        // check if mobile, if so add style to mobile-push-header
        if (koko.client.system.isMobile) {
            // not needed on android, maybe ios?
            // document.getElementById('mobile-push-header').style.height = '10px';
        }

        // Do we need to try loading user? Load from cookie?
        // debug.log('load user');
        // mainUser.load()
        // v.get()

        // do we got a muted cookie?
        let mutedCookieSetting = co.get('muted');
        if (mutedCookieSetting !== null) {
            au.setMute(mutedCookieSetting === '1');
        }
        // do we got a cookies allowed cookie?
        let acceptCookieSetting = co.get('accept-cookie');
        if (acceptCookieSetting !== null) {
            this.cookiesAccepted = true;
        }

        // load the preloader graphics, before showing anything
        v.each(['preloader'], 'queueAssets');
        //load manifest
        a.loadManifest(function (p) {
            //LOADING PROGRESS EVENT
        }, function () {

            REACT_PRELOADER.ref.queueUpMenuAssets();

            //queue assets from all other menus
            v.allBut(['preloader'], 'queueAssets');

            //show the preloader
            v.each(['preloader'], 'transitionIn');
        });
        // preloader triggers loading
    },

    //if extra load called, use handler like this
    // userLoaded: function(){
    // 	//load the preloader graphics, before showing anything
    // 	v.each(['preloader'], 'loadAssets');
    // 	v.each(['preloader'], 'queueAssets');
    // 	//load manifest
    // 	a.loadManifest(function(p){
    // 		//LOADING PROGRESS EVENT
    // 	}, function() {
    //
    // 		//queue assets from all other menus
    // 		v.allBut(['preloader'], 'queueAssets');
    //
    // 		//show the preloader
    // 		v.each(['preloader'], 'transitionIn');
    //
    // 		//load them sounds yo
    // 		//au.loadSounds();
    // 	});
    // }
}

export { main as default }

