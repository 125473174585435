import gsap from 'gsap'
import {Back, Bounce} from 'gsap'
import koko from '../../../koko-framework/koko'
import app from '../../../koko-framework/app'
import { FRAME_TIME, FRAME_DELTA_LIMIT } from '../model/config'
import { LiquidObject } from './LiquidObject';
import { LiquidContainerSprite } from './LiquidContainerSprite';
import { ASSET_SCALE, LIQUID_VOLUME_SPLIT, LIQUID_VOLUME_TOTAL, LAYOUT_CUP_DISTANCE_X, LAYOUT_CUP_DISTANCE_Y, NO_OF_CONTAINERS, LIQUID_COLOURS, MAX_STARTING_COLOUR_SPLITS, STAR1_SCORE} from '../model/GameConfig'
import * as PIXI from 'pixi.js'
import { setupClicker } from '../../../koko-framework/ui/ButtonController'
import { isFunction } from 'underscore'
import ReactGA from 'react-ga4'

export class LiquidLevelController extends koko.view {
    assets = [
        { id: 'liquid_levels_ingame', src: 'img/coffee-game/liquid_levels_ingame.json' },
        { id: 'CARL_CHANGE', src: 'img/coffee-game/anim_assets.json' },
    ];

    liquidContainers = [];
    startingContainer = null;
    removeAnimSprite = null;
    addAnimSprite = null;
    movesTaken = 0;
    isPaused = false;
    isGameOver = false;

    buildView() {
        var g = app.guiSize, vp = app.viewPort;
        this.children = koko.display.buildLayout([
            {name: 'guiSize', type: 'shape', width: g.width, height: g.height, x: 0, y: 0, fill: 0, alpha: 0.2},
            {name:'bg', type:'sprite', id:'gameBk_ll', x:vp.centerX, y:vp.centerY, anchorX: 0.5, anchorY: 0.5, scale:ASSET_SCALE},
            {name:'cupContainer', type:'container', x:vp.centerX, y:vp.centerY+200},
            {name: 'blackOverlay', type: 'shape', width: g.width, height: g.height, x: 0, y: 0, fill: 0, alpha: 1},

        ], this.container);

        gsap.to(this.children.blackOverlay, {duration:.8, alpha:0})
        this.resize();
        document.addEventListener("unpause", this.unpauseGame);
        koko.audio.play('ll_music',.5, true); 
    }

    resize() {

    }

    unpauseGame = () =>{
        this.isPaused = false;
    }

    transitionIn() {

        this.liquidContainers = [];
        this.startingContainer = null;
        this.removeAnimSprite = null;
        this.addAnimSprite = null;
        this.movesTaken = 0;
        this.isPaused = false;
        this.isGameOver = false;


        super.transitionIn();
        app.addResizeListener('gameresize', function(){
            this.resize();
        }.bind(this));

        this.setupLiquids();

        
        app.removeUpdateListener('assetUpdater');
           
		app.addUpdateListener('assetUpdater', (time, delta) => { 
            let deltaRatio = 1;
            deltaRatio = delta / FRAME_TIME;
            if (deltaRatio > FRAME_DELTA_LIMIT) {
                deltaRatio = FRAME_DELTA_LIMIT;
            }
            this.update(deltaRatio);
        });
        setTimeout(() => {  for(let i=0; i< this.liquidContainers.length; i++)
            {
                this.liquidContainers[i].updateContainerLiquids(true);
            }}, 500)
      
            ReactGA.event({
                category: "Coffee Shop Game",
                action: "Coffee Shop Game - Started",
              });

    }
    update(delta)
    {
        for(let i=0; i< this.liquidContainers.length; i++)
        {
            this.liquidContainers[i].updateContainerLiquids();
        }
    }

    transitionOutDone = () => {
        // this.stopUpdate();
        app.removeUpdateListener('assetUpdater');

        document.removeEventListener("unpause", this.unpauseGame);
        app.removeResizeListener('gameresize');
        while (app.resizeListeners['gameresize']) {
            app.removeResizeListener('gameresize');
        }
        koko.audio.stop('ll_music');
        this.destroyView();
        this.hide();
        // super.transitionOutDone();
        this.destroyView();
        this.hide();
    }
    
    setupLiquids = () => {

        //1. CREATE THE CONTAINERS
        for(let i=1; i<=NO_OF_CONTAINERS; i++)
        {
            //Add
            let containerX = (i % 3 * LAYOUT_CUP_DISTANCE_X) - LAYOUT_CUP_DISTANCE_X;
            let containerY = (i % 2 * LAYOUT_CUP_DISTANCE_Y) - LAYOUT_CUP_DISTANCE_Y;

            //Create the coasters
            let coaster = PIXI.Sprite.from('coaster.png');
            coaster.scale.x = coaster.scale.y = ASSET_SCALE;
            coaster.pivot.x = coaster.width/2/ASSET_SCALE;
            coaster.x = containerX;
            coaster.y = containerY + 145;
            this.children.cupContainer.addChild(coaster)

            //Setup the empty glass containers
            let newLiquidContainer = new LiquidContainerSprite();
            this.liquidContainers.push(newLiquidContainer);
            this.children.cupContainer.addChild(newLiquidContainer);
            newLiquidContainer.pivot.x = 82;
            newLiquidContainer.x = containerX;
            newLiquidContainer.y = containerY;
            newLiquidContainer.startX = containerX;
            newLiquidContainer.startY = containerY;
            newLiquidContainer.interactive = true;
            
            newLiquidContainer.on('mousedown',  this.clickHandler);
            newLiquidContainer.on('tap',  this.clickHandler);
        }

        //2. CREATE THE STARTING LIQUID PORTIONS
        let unsortedLiquids = [];
        for(let i=0; i<LIQUID_COLOURS.length; i++)
        {
            let currentColour = LIQUID_COLOURS[i];
            let startingVolume = LIQUID_VOLUME_TOTAL/LIQUID_VOLUME_SPLIT;
            for(let j=0; j<LIQUID_VOLUME_SPLIT; j++)
            {
                let newLiquidObj = new LiquidObject(currentColour, startingVolume, i);
                unsortedLiquids.push(newLiquidObj);
            }
        }

        //3. DISTRIBUTE THE LIQUIDS TO CONTAINERS
        this.shuffle(unsortedLiquids);
        for(let i=0; i<unsortedLiquids.length; i++)
        {
            let success = false;
            let currentLiquid = unsortedLiquids[i];
            while(!success)
            {
                let randomContainerID = Math.ceil(Math.random()* this.liquidContainers.length)-1;
                success = this.liquidContainers[randomContainerID].addStartingLiquid(currentLiquid, (LIQUID_VOLUME_TOTAL/LIQUID_VOLUME_SPLIT)*MAX_STARTING_COLOUR_SPLITS);
                if(success) this.liquidContainers[randomContainerID].updateContainerLiquids(true)
            }
        }
        //let newColour = new LiquidObject();

        //4. CREATE ADD/REMOVE ANIMATED SPRITES
        this.removeAnimSprite = koko.display.createAnim('Element-', 30, 3, 0, 1);
        this.removeAnimSprite.pivot.x =  this.removeAnimSprite.width/2;
        this.removeAnimSprite.loop = false;
        this.removeAnimSprite.scale.x =  this.removeAnimSprite.scale.y = ASSET_SCALE;
        this.addAnimSprite = koko.display.createAnim('Element-', 30, 3, 0, 1);
        this.addAnimSprite.pivot.x =  this.addAnimSprite.width/2;
        this.addAnimSprite.rotation = 180 * (Math.PI/180);
        this.addAnimSprite.loop = false;
        this.addAnimSprite.scale.x =  this.addAnimSprite.scale.y = ASSET_SCALE;
        this.children.cupContainer.addChild(this.removeAnimSprite);
        this.children.cupContainer.addChild(this.addAnimSprite);
        this.addAnimSprite.visible = false;
        this.removeAnimSprite.visible = false;
    }
    shuffle = (array) =>{
        array.sort(() => Math.random() - 0.5);
      }

    clickHandler = (e) =>{
        if(!this.isGameOver && !this.isPaused)
        {
            if(!this.startingContainer) 
            {
                let randomSound = Math.ceil(Math.random()*4);
                koko.audio.play('drip');
                koko.audio.play('select'+randomSound,1); 
                this.startingContainer = e.target;
                gsap.to(e.target, {y:e.target.startY - 60, duration: 0.35});
            
            }
            else
            {
                /*
                e.target.pourLiquidIntoContainer(this.startingContainer.liquidContainerObject);
                this.startingContainer.updateContainerLiquids();
                this.startingContainer.liquidContainerObject.removeEmptyLiquids();
                e.target.updateContainerLiquids();
                this.startingContainer.updateContainerLiquids();
                e.target.liquidContainerObject.removeEmptyLiquids();
            
            
            
                this.removeAnimSprite.x =  this.startingContainer.startX;
                this.removeAnimSprite.y =  this.startingContainer.startY - e.target.liquidSpriteContainer.height;
            
                this.addAnimSprite.x =  e.target.startX;
                this.addAnimSprite.y =  e.target.startY +280 - e.target.liquidSpriteContainer.height;

                this.removeAnimSprite.gotoAndPlay(0);
                setTimeout(() => {  gsap.to( this.startingContainer, {y: this.startingContainer.startY, duration: 0.35, ease:Bounce.easeOut});  this.startingContainer = null;  }, 200);
                setTimeout(() => { this.addAnimSprite.gotoAndPlay(0); }, 400);*/
                
                let successPour = false;
                let ignorePour = true;
                if(e.target != this.startingContainer) 
                {
                    successPour = e.target.pourLiquidIntoContainer(this.startingContainer.liquidContainerObject);
                    ignorePour = false;
                }
 
                if(!ignorePour)
                {
                    this.startingContainer.updateContainerLiquids();
                    this.startingContainer.liquidContainerObject.removeEmptyLiquids();
                    e.target.updateContainerLiquids();
                    this.startingContainer.updateContainerLiquids();
                    if(successPour)
                    {
                        if(koko.audio.isPlaying('pour')) koko.audio.play('pour1',.5); 
                        koko.audio.play('pour',.5); 
                        this.removeAnimSprite.gotoAndPlay(0);
                        this.addAnimSprite.gotoAndPlay(0);
                        this.addAnimSprite.visible = true;
                        this.removeAnimSprite.visible = true;
                    }
            
                }
                this.removeAnimSprite.x =  this.startingContainer.startX;
                this.removeAnimSprite.y =  this.startingContainer.startY;
                this.addAnimSprite.x =  e.target.startX;
                this.addAnimSprite.y =  e.target.startY +200;
                koko.audio.play('lands', .6);
                gsap.to( this.startingContainer, {y: this.startingContainer.startY, duration: 0.6, ease:Bounce.easeOut, onComplete:() =>{ this.calculateScore(successPour)}});
                this.startingContainer = null;
            ;
            }
        }
    }

    calculateScore = (isSuccess) =>
    {
        if(isSuccess)
        {
            this.movesTaken ++;
            let numberContainersComplete = 0;
            for(let i=0; i<this.liquidContainers.length; i++)
            {
                if(this.liquidContainers[i].liquidContainerObject.checkCompleteStatus())
                {
                    numberContainersComplete ++;
                }
            }

            console.log('CONTAINERS COMPLETE =', numberContainersComplete,'Score:',  this.movesTaken)
            koko.views.get('coffeegame_hudView').updateProgressBar( this.movesTaken );
            

            if( numberContainersComplete >= 5 || this.movesTaken >= STAR1_SCORE)
            {
                koko.views.get('coffeegame_hudView').showGameOver();
            
                setTimeout(()=> {document.dispatchEvent(new CustomEvent("gameover", {bubbles: true, detail: { score: this.movesTaken, stars: koko.views.get('coffeegame_hudView').setStarStatus( this.movesTaken) }}));
            }, 2000)


                ReactGA.event({
                    category: "Coffee Shop Game",
                    action: "Coffee Shop Game - Over",
                    label: "Coffee Shop Game - Pours",
                    value: this.movesTaken
                });




                 this.isGameOver = true;
                koko.audio.stop('ll_music');
                koko.audio.play('ll_level_complete',1);
            }
        }
    }
        /*
    startUpdate() {
        app.removeUpdateListener('snowupdater');
           
		app.addUpdateListener('snowupdater', (time, delta) => { 
            let deltaRatio = 1;
            deltaRatio = delta / FRAME_TIME;
            if (deltaRatio > FRAME_DELTA_LIMIT) {
                deltaRatio = FRAME_DELTA_LIMIT;
            }

            this.snowController.update(deltaRatio);
        });
    }

    stopUpdate() { 
        app.removeUpdateListener('snowupdater');
        while (app.updateListeners['snowupdater']) {
            app.removeUpdateListener('snowupdater');
        }
    }
    */
}