const math = {
	//container width, container height, widht height
	center : function(cw, ch, w, h) {
		return { x: (cw/2)-(w/2), y:(ch/2)-(h/2) };	
	},
	//min, max
	randomInt: function(b, t) {
		return Math.floor(Math.random()*(t-b+1))+b;
	},
	randBool: function(perc) {
		return Math.random() < (perc || 0.5);
	},
	//bottom, top, value, clamp?
	percentage: function(b, t, v, c) {
		return c ? this.clamp(0,1, (v-b)/(t-b)) : (v-b)/(t-b);
	},
	//bottom, top, percentge, roundNum?
	percentile: function (b, t, p, r) {
		return r ? Math.round(b + ((t-b)*p)) : b + ((t-b)*p);
	},
	//value, bottom, top, 
	clamp: function(v, b, t) {
		return v < b ? b : v > t ? t : v; 	
	},
	//rect {x:0,y:0,w:0,h:0}, point {x:0,y:0}
	rectContainsPoint: function(r, p) {
		return (r.x < p.x && p.x < (r.x+r.w)) && (r.y < p.y && p.y < (r.y+r.h));
	},
	//point1 {x:0,y:0}, point2 {x:0,y:0}
	pointDistance: function(p, p2) {
		return Math.sqrt((p.x-p2.x)*(p.x-p2.x) + (p.y-p2.y)*(p.y-p2.y));
	},
	//width, height, maxWidth, max Height
	scaleFitRect: function(w, h, mw, mh) {
		var s = (mw/w) < (mh/h) ? mw/w : mh/h;
		if(s > 1) s=1;
		h *= s; w *= s;
		return { h: h, w: w, x: (mw-w)*0.5, y: (mh-h)*0.5, s:s };
	},
	pointFromDistanceAngle: function(point, angle, distance) {
		return { 
			x: Math.cos(angle*Math.PI/180)*distance+point.x, 
			y: Math.sin(angle*Math.PI/180)*distance+point.y
		};
	},
	fitRect: function(rectangle, into, mode) {
		var result = { width:0, height:0, x:0, y:0 };
		
		var width   = rectangle.width;
		var height  = rectangle.height;
		var factorX = into.width  / width;
		var factorY = into.height / height;
		var factor  = 1.0;
		
		switch(mode) {	
			case 'show_all':
				factor = factorX < factorY ? factorX : factorY;
			break;
			case 'no_border':
				factor = factorX > factorY ? factorX : factorY;
			break;
			case 'fit_height':
				factor = factorY;
			break;
			case 'fit_width':
				factor = factorX;
			break;
			default:
				factor = 1.0;
			break;
		}

		width  *= factor;
		height *= factor;
		
		result.x = into.x + (into.width  - width)  / 2;
		result.y = into.y + (into.height - height) / 2;
		result.width = width;
		result.height = height;
		result.s = factor;
		
		return result;
	},
	secondsToTime: function(secs)
	{
	    secs = Math.round(secs);
	    var hours = Math.floor(secs / (60 * 60)).toString();

	    var divisor_for_minutes = secs % (60 * 60);
	    var minutes = Math.floor(divisor_for_minutes / 60).toString();

	    var divisor_for_seconds = divisor_for_minutes % 60;
	    var seconds = Math.ceil(divisor_for_seconds).toString();

	    var obj = {
	        "h": hours.length === 1 ? "0"+hours : hours,
	        "m": minutes.length === 1 ? "0"+minutes : minutes,
	        "s": seconds.length === 1 ? "0"+seconds : seconds,
	    };
	    return obj;
	},
	shuffleArray: function(o) {
		for(var j, x, i = o.length; i; j = Math.floor(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x);
		return o;
	},
	angleToRadians: function(degrees) {
		return degrees * (Math.PI/180);
	},
	radiansToAngle: function(radians) {
		return radians * (180/Math.PI);
	},
	guid: function() {
	  function s4() { return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1); }
	  return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
	}
}

export { math as default };
