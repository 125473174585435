import * as PIXI from 'pixi.js';
import gsap from 'gsap';
import * as BABCOCK_CONSTANTS from '../model/constants';
import WorldController, { AVAILABLE_ZOOMS } from '../../controller/WorldController';
import { au, v, d } from '../../koko-framework/shorthand';
import { setupClicker } from '../../koko-framework/ui/ButtonController';
import { CollectItem, CompleteSelfie, GameState, GiveItem, IsSelfieTaken, NpcInteractedWith, SetMiniGameResult, NpcTaskCompleted, SetNewCvItem, ProgressBadge } from '../model/GameState';
import { PANEL_STATE, REACT_VIEW_CONTROLLER } from '../../Bootstrap';
import { BABCOCK_OFFICE_DIALOG, CV_READY_DIALOG, INTERVIEW_CALL_DIALOG, INTERVIEW_CALL_DIALOG_GOT_CLOTHES, SKILLS_LAUNCHPAD_DIALOG } from '../model/dialog';
import { MapLayouts } from '../../model/tilemaps/MapLayouts';
import { GetQuestionToAsk } from '../model/advisor-questions';
import ApiController from './ApiController';

import ReactGA from 'react-ga4';

import GameRewardCvIcon from '../../assets/babcock_menus/mini_game_flow/CV_rewardUnlock.png';
import GameRewardTrophyIcon from '../../assets/babcock_menus/mini_game_flow/trophyRewardUnlock.png';

const BUTTON_FLOAT_HEIGHT = 320;
const INTRO_ZOOM_SPEED = 0.001;
const PLAYER_HIGHLIGHT_Y_OFFSET = 14;

class BabcockController {
    activeLocationButtons = null;
    activeWayPoints = null;
    lastInteraction = null;
    showViewAfterDialog = '';
    hilightedRoute = '';
    entityBeingLed = null;

    doingIntroZoom = false;
    introZoomFactor = 1;

    originalPathNodeCallback = null;

    queuedQuestion = null;
    activeQuestion = null;

    characterHighlightAnim = null;

    gameRewardQueue = [];

    init = () => {
        if (this.characterHighlightAnim === null) {
            this.characterHighlightAnim = d.createAnim('Pulse2_00', 30, 1, 1, 1);
            this.characterHighlightAnim.anchor.x = this.characterHighlightAnim.anchor.y = 0.5;
            this.characterHighlightAnim.loop = true;
            this.characterHighlightAnim.animationSpeed = 0.5;
            // this.characterHighlightAnim.scale.x = this.characterHighlightAnim.scale.y = 0.5;
            // this.characterHighlightAnim.alpha = 0.65;
        }

        WorldController.mapInitialisingCallback = this.setupMapState;
        WorldController.mapInitialisedCallback = this.mapInitialised;
        WorldController.mapAboutToRemoveCallback = this.removeLocationButtons;
        WorldController.npcsAddedCallback = this.npcsAdded;
        WorldController.collectableCallback = this.entityNearCollectable;
        WorldController.updateCallback = this.update;
        this.hilightedRoute = '';

        // disable the zoom at the start so we can do a slow zoom intro
        WorldController.zoomEnabled = false;
        this.doingIntroZoom = true;
        this.introZoomFactor = AVAILABLE_ZOOMS[2];

        this.gameRewardQueue = [];

        this.activeLocationButtons = null;
        this.activeWayPoints = null;
        this.lastInteraction = null;
        this.showViewAfterDialog = null;
        this.entityBeingLed = null;
        this.queuedQuestion = null;
        this.activeQuestion = null;
        this.gameRewardQueue = [];

        // add some event listeners for mini games
        document.addEventListener("gameover", this.miniGameCompleteEventHandler);
        
        document.addEventListener("pause", (event) => {
            // setTimeout(this.unpause, 100);
            v.showView('pausepanel');
        });

        // to unpause
        // document.dispatchEvent(new CustomEvent("unpause"));
    }

    update = (delta, frameDeltaRatio) => {
        if (GameState.timerActive && REACT_VIEW_CONTROLLER.areViewsActive(['settings', 'inventorypanel', 'cvview', 'tasklist']) === false) {
            GameState.taskCountdown -= delta / 1000;
            if (GameState.taskCountdown <= 0) {
                GameState.taskCountdown = 0;
            }
            let timerView = v.get('hudtimer');
            if (timerView) {
                timerView.updateTimer();
            }
            if (GameState.taskCountdown <= 0) {
                if (this.entityBeingLed) {
                    this.entityBeingLed.cancelLeading();
                }
                // this.entityBeingLed = null;
                GameState.timerActive = false;
                GameState.currentSubTask = BABCOCK_CONSTANTS.NO_TASK;
                v.hideView('hudtimer');
                v.get('hudview').reflectGameState();
                v.get('hudview').showLeadTaskFailMessage();

                au.stop('timerloop');
                au.play('timerout', 1, false, 0, false);

                this.addRemoveNpcAlerts();
                this.showHideAvailableLocations();
                WorldController.canLeaveMap = true;
            }
        }
        if (this.doingIntroZoom) {
            this.introZoomFactor += INTRO_ZOOM_SPEED;
            if (this.introZoomFactor > 1) {
                this.introZoomFactor = 1;
                this.doingIntroZoom = false;
                WorldController.zoomEnabled = true;
                WorldController.zoomLevel = 0;
            }
            WorldController.scrollingTilemapController.setZoom(this.introZoomFactor, WorldController.gameView.children.mapContainer);
            WorldController.scrollingTilemapController.focusOnSprite(WorldController.mainCharacterEntity.characterSprite, WorldController.viewRect, WorldController.gameView.children.mapContainer);
        }
        if (this.characterHighlightAnim.parent) {
            this.characterHighlightAnim.x = WorldController.mainCharacterEntity.characterSprite.x;
            this.characterHighlightAnim.y = WorldController.mainCharacterEntity.characterSprite.y - (WorldController.maincharacterSpriteData.HILIGHT_Y_OFFSET || PLAYER_HIGHLIGHT_Y_OFFSET);
        }
        if (this.entityBeingLed && GameState.currentSubTask !== BABCOCK_CONSTANTS.LEAD_TO_DEST) {
            if (this.entityBeingLed.characterGridX === this.entityBeingLed.characterStartGridX && this.entityBeingLed.characterGridY === this.entityBeingLed.characterStartGridY) {
                this.addRemoveNpcAlerts();
                this.entityBeingLed = null;
            }
        }

        this.updateWayPoints();
    }

    npcsAdded = (npcs) => {
        // we are going to add a callback to our lead to dest entities here
        // console.log('setting up npcs [babcock]: ', npcs);
        for (let i = 0; i < npcs.length; i++) {
            if (npcs[i].leadToDestination) {
                npcs[i].reachedFinalDestinationCallback = this.characterLeadToDestination;
            }
        }

        this.addRemoveNpcAlerts(npcs);

        v.get('worldview').children.Ground_Objects_Layer_2.addChild(this.characterHighlightAnim);
        this.characterHighlightAnim.x = WorldController.mainCharacterEntity.characterSprite.x;
        this.characterHighlightAnim.y = WorldController.mainCharacterEntity.characterSprite.y -  - (WorldController.maincharacterSpriteData.HILIGHT_Y_OFFSET || PLAYER_HIGHLIGHT_Y_OFFSET);
        this.characterHighlightAnim.gotoAndPlay(0);
    }

    playStepSound = (entity) => {
        if (GameState.selectedCharacter !== 2) {
            au.playRandom('step', 1, 10, 1, false, 0);
        }
        if (this.originalPathNodeCallback) {
            this.originalPathNodeCallback(entity);
        }
    }

    pauseCharacter = () => {
        WorldController.mainCharacterEntity.pauseMovement(Infinity);
    }

    unpauseCharacter = () => {
        WorldController.mainCharacterEntity.restartMovement();
    }

    setupMapState = () => {
        this.setupLocationButtons();
        this.updateLocationIcons();
        this.showHideAvailableLocations();
        this.removeNpcsWhoLeft();
        this.removeOldTaskItems();
        this.showHideRouteTiles();
        if (GameState.currentSubTask === BABCOCK_CONSTANTS.COLLECT_LITTER_TASK || GameState.currentSubTask === BABCOCK_CONSTANTS.LEAD_TO_DEST) {
            GameState.currentSubTask = BABCOCK_CONSTANTS.NO_TASK;
            GameState.timerActive = false;
            v.hideView('hudtimer');
            v.hideView('hudlitter');

        }
        if (v.get('hudview').container.visible) {
            v.get('hudview').reflectGameState();
        }
    }

    mapInitialised = () => {
        if (GameState.currentGameTask === BABCOCK_CONSTANTS.INTRO_TASK && GameState.introPhase === 0) {
            this.showIntroPhase();
        }
        if (WorldController.mainCharacterEntity.pathNodeReachedCallback !== this.playStepSound) {
            this.originalPathNodeCallback = WorldController.mainCharacterEntity.pathNodeReachedCallback;
            WorldController.mainCharacterEntity.pathNodeReachedCallback = this.playStepSound;
        }
    }

    hilightRoute = (routeId = '') => {
        this.hilightedRoute = routeId;
        this.showHideRouteTiles();
    }

    hideHilightedRoute = () => {
        this.hilightRoute();
    }

    showHideRouteTiles = () => {
        let routeTiles = WorldController.scrollingTilemapController.tileMapEngine.getSpecialSpritesWithProperty('route');
        console.log('Show / Hide route tiles: ', routeTiles, this.hilightedRoute);
        for (let  i = 0; i < routeTiles.length; i++) {
            if (routeTiles[i].config.route === true && routeTiles[i].localConfig.routeTo === this.hilightedRoute) {
                routeTiles[i].children[0].visible = true;
            } else {
                routeTiles[i].children[0].visible = false;
            }
        }
    }

    setupLocationButtons = () => {
        if (this.activeLocationButtons && this.activeLocationButtons.length) {
            this.removeLocationButtons();
        }
        this.activeLocationButtons = [];
        let buttonsContainer = v.get('worldview').children.Top_Layer_2;
        let storyLocationTiles = WorldController.scrollingTilemapController.tileMapEngine.getSpecialSpritesWithProperty('storyLocation');
        // console.log('Babcock Controller Setting Up Locations: ', storyLocationTiles);
        for (let i = 0; i < storyLocationTiles.length; i++) {
            let storyLocationId = storyLocationTiles[i].config.storyLocation;
            let buttonXOffset = storyLocationTiles[i].config.buttonXOffset || 0;
            let buttonYOffset = storyLocationTiles[i].config.buttonYOffset || 0;
            if (BABCOCK_CONSTANTS.GAME_LOCATIONS.indexOf(storyLocationId) >= 0) {
                let locationData = BABCOCK_CONSTANTS.getLocationDataFromId(storyLocationId);

                // game location - add our game location button
                let gameButton = new PIXI.Container();
                gameButton.x = storyLocationTiles[i].x + buttonXOffset;
                gameButton.y = storyLocationTiles[i].y - BUTTON_FLOAT_HEIGHT + buttonYOffset;
                // this sprite id makes no sense, it should be more generic - it's actually the blue pill for the button
                let gameButtonMain = PIXI.Sprite.from('locationProgress_coffee.png');
                gameButtonMain.anchor.x = 0.5;
                gameButtonMain.anchor.y = 0.5;
                gameButtonMain.scale.x = gameButtonMain.scale.y = 0.5;
                gameButton.addChild(gameButtonMain);

                let gameButtonCvIcon = PIXI.Sprite.from('cv_locked.png');
                gameButtonCvIcon.anchor.x = 0.5;
                gameButtonCvIcon.anchor.y = 1;
                gameButtonCvIcon.scale.x = gameButtonCvIcon.scale.y = 0.5;
                gameButtonCvIcon.x = -12;
                gameButtonCvIcon.y = 21;
                gameButton.cvIcon = gameButtonCvIcon
                gameButton.addChild(gameButtonCvIcon);

                let gameButtonItemIcon = PIXI.Sprite.from(locationData.game.itemEarned.pillIconIdLocked);
                gameButtonItemIcon.anchor.x = 0.5;
                gameButtonItemIcon.anchor.y = 1;
                gameButtonItemIcon.scale.x = gameButtonItemIcon.scale.y = 0.5;
                gameButtonItemIcon.x = 38;
                gameButtonItemIcon.y = 21;
                gameButton.itemIcon = gameButtonItemIcon
                gameButton.addChild(gameButtonItemIcon);

                let gameButtonAttributeIcon = PIXI.Sprite.from('trophy_Locked.png');
                gameButtonAttributeIcon.anchor.x = 0.5;
                gameButtonAttributeIcon.anchor.y = 1;
                gameButtonAttributeIcon.scale.x = gameButtonAttributeIcon.scale.y = 0.5;
                gameButtonAttributeIcon.x = 89;
                gameButtonAttributeIcon.y = 22;
                gameButton.attributeIcon = gameButtonAttributeIcon
                gameButton.addChild(gameButtonAttributeIcon);

                gameButton.storyTile = storyLocationTiles[i];
                gameButton.storyLocationId = storyLocationId;
                buttonsContainer.addChild(gameButton);
                this.activeLocationButtons.push(gameButton);

                /* No clickers!
                storyLocationTiles[i].requiresClickToInteract = true;
                setupClicker(gameButton, this.interactButtonHandler, '', false, true);
                */
            } else
            if (BABCOCK_CONSTANTS.SELFIE_LOCATIONS.indexOf(storyLocationId) >= 0) {
                // selfie location - add our selfie location button
                let selfieButton = new PIXI.Container();
                selfieButton.x = storyLocationTiles[i].x + buttonXOffset;
                selfieButton.y = storyLocationTiles[i].y - BUTTON_FLOAT_HEIGHT + buttonYOffset;
                // this sprite id makes no sense, it should be more generic - it's actually the blue pill for the button
                let imageId = IsSelfieTaken(storyLocationId) ? 'selfie_Unlocked.png' : 'selfie_locked.png';
                let selfieButtonMain = PIXI.Sprite.from(imageId);
                selfieButtonMain.anchor.x = 0.5;
                selfieButtonMain.anchor.y = 0.5;
                selfieButtonMain.scale.x = selfieButtonMain.scale.y = 0.5;
                selfieButton.addChild(selfieButtonMain);
                selfieButton.buttonBg = selfieButtonMain;
                selfieButton.storyTile = storyLocationTiles[i];
                buttonsContainer.addChild(selfieButton);
                this.activeLocationButtons.push(selfieButton);

                /* No clickers!
                storyLocationTiles[i].requiresClickToInteract = true;
                setupClicker(selfieButton, this.interactButtonHandler, '', false, true);
                */
            } else
            if (storyLocationId === BABCOCK_CONSTANTS.BABCOCK_OFFICE) {
                let babcockLozenge = new PIXI.Container();
                babcockLozenge.x = storyLocationTiles[i].x + buttonXOffset;
                babcockLozenge.y = storyLocationTiles[i].y - BUTTON_FLOAT_HEIGHT + buttonYOffset;
                let babcockLozengeMain = PIXI.Sprite.from('babcock_lozenge.png');
                babcockLozengeMain.anchor.x = 0.5;
                babcockLozengeMain.anchor.y = 0.5;
                babcockLozengeMain.scale.x = babcockLozengeMain.scale.y = 0.5;
                babcockLozenge.addChild(babcockLozengeMain);
                babcockLozenge.buttonBg = babcockLozengeMain;
                babcockLozenge.storyTile = storyLocationTiles[i];
                buttonsContainer.addChild(babcockLozenge);
                this.activeLocationButtons.push(babcockLozenge);
            } else
            if (storyLocationId === BABCOCK_CONSTANTS.BIZ_WEAR_SHOP) {
                let shopLozenge = new PIXI.Container();
                shopLozenge.x = storyLocationTiles[i].x + buttonXOffset;
                shopLozenge.y = storyLocationTiles[i].y - BUTTON_FLOAT_HEIGHT + buttonYOffset;
                let shopLozengeMain = PIXI.Sprite.from('formalwearShop_lozenge.png');
                shopLozengeMain.anchor.x = 0.5;
                shopLozengeMain.anchor.y = 0.5;
                shopLozengeMain.scale.x = shopLozengeMain.scale.y = 0.5;
                shopLozenge.addChild(shopLozengeMain);
                shopLozenge.buttonBg = shopLozengeMain;
                shopLozenge.storyTile = storyLocationTiles[i];
                buttonsContainer.addChild(shopLozenge);
                this.activeLocationButtons.push(shopLozenge);
            } else
            if (storyLocationId === BABCOCK_CONSTANTS.SKILLS_LAUNCHPAD) {
                let launchpadLozenge = new PIXI.Container();
                launchpadLozenge.x = storyLocationTiles[i].x + buttonXOffset;
                launchpadLozenge.y = storyLocationTiles[i].y - BUTTON_FLOAT_HEIGHT + buttonYOffset;
                let launchpadLozengeMain = PIXI.Sprite.from('skillsLaunchpad_lozenge.png');
                launchpadLozengeMain.anchor.x = 0.5;
                launchpadLozengeMain.anchor.y = 0.5;
                launchpadLozengeMain.scale.x = launchpadLozengeMain.scale.y = 0.5;
                launchpadLozenge.addChild(launchpadLozengeMain);
                launchpadLozenge.buttonBg = launchpadLozengeMain;
                launchpadLozenge.storyTile = storyLocationTiles[i];
                buttonsContainer.addChild(launchpadLozenge);
                this.activeLocationButtons.push(launchpadLozenge);
            } else
            if (storyLocationId === BABCOCK_CONSTANTS.HOME) {
                let homeLozenge = new PIXI.Container();
                homeLozenge.x = storyLocationTiles[i].x + 130;
                homeLozenge.y = storyLocationTiles[i].y - BUTTON_FLOAT_HEIGHT + 30;
                let homeLozengeMain = PIXI.Sprite.from('homeLozenge.png');
                homeLozengeMain.anchor.x = 0.5;
                homeLozengeMain.anchor.y = 0.5;
                homeLozengeMain.scale.x = homeLozengeMain.scale.y = 0.5;
                homeLozenge.addChild(homeLozengeMain);
                homeLozenge.buttonBg = homeLozengeMain;
                homeLozenge.storyTile = storyLocationTiles[i];
                homeLozenge.storyLocationId = storyLocationId;
                buttonsContainer.addChild(homeLozenge);
                this.activeLocationButtons.push(homeLozenge);
            }
        }
    }

    showHideAvailableLocations = () => {
        let gamesAvailable = true;
        let storyLocationTiles = WorldController.scrollingTilemapController.tileMapEngine.getSpecialSpritesWithProperty('storyLocation');
        let hotspotGlows = WorldController.scrollingTilemapController.tileMapEngine.getSpecialSpritesWithProperty('hotspotGlow');
        let shownHotspots = [];
        for (let i = 0; i < storyLocationTiles.length; i++) {
            let storyLocationId = storyLocationTiles[i].config.storyLocation;
            let locationData = BABCOCK_CONSTANTS.getLocationDataFromId(storyLocationId);
            // console.log('Show/Hide locations: ', storyLocationTiles[i], storyLocationId, locationData, GameState.currentGameTask);
            // hide bus stop in city centre during intro task
            if (storyLocationId === 'Bus Stop' && storyLocationTiles[i].localConfig.exit_to === 'city_north' && GameState.currentGameTask === BABCOCK_CONSTANTS.INTRO_TASK) {
                storyLocationTiles[i].children[0].visible = false;
                storyLocationTiles[i].active = false;
                WorldController.canLeaveMap = false;
            } else
            if ((GameState.currentSubTask === BABCOCK_CONSTANTS.LEAD_TO_DEST && (this.entityBeingLed.finalDestination.gridX !== storyLocationTiles[i].gridX || this.entityBeingLed.finalDestination.gridY !== storyLocationTiles[i].gridY)) || (locationData && locationData.phasesAvailable.indexOf(GameState.currentGameTask) === -1)) {
                storyLocationTiles[i].children[0].visible = false;
                storyLocationTiles[i].active = false;
                if (locationData && (BABCOCK_CONSTANTS.GAME_LOCATIONS.indexOf(locationData.id) >= 0 || BABCOCK_CONSTANTS.SELFIE_LOCATIONS.indexOf(locationData.id) >= 0)) {
                    gamesAvailable = false;
                }
            } else {
                storyLocationTiles[i].children[0].visible = true;
                storyLocationTiles[i].active = true;
                shownHotspots.push(storyLocationTiles[i]);
            }
            for (let j = 0; j < hotspotGlows.length; j++) {
                if (hotspotGlows[j].gridX === storyLocationTiles[i].gridX && hotspotGlows[j].gridY === storyLocationTiles[i].gridY) {
                    hotspotGlows[j].children[0].visible = storyLocationTiles[i].children[0].visible;
                }
            }
        }
        // console.log('Hide / Show location buttons:');
        for (let i = 0; i < this.activeLocationButtons.length; i++) {
            // console.log('Button ' + i + ': ', this.activeLocationButtons[i]);
            // if (this.activeLocationButtons[i].storyLocationId === BABCOCK_CONSTANTS.SKILLS_LAUNCHPAD && GameState.currentGameTask === BABCOCK_CONSTANTS.INTRO_TASK) {
            //     this.activeLocationButtons[i].visible = true;    
            // } else {
                if (this.activeLocationButtons[i].storyLocationId === BABCOCK_CONSTANTS.HARBOUR_HOTEL) {
                    this.activeLocationButtons[i].visible = gamesAvailable;
                } else {
                    this.activeLocationButtons[i].visible = shownHotspots.indexOf(this.activeLocationButtons[i].storyTile) >= 0 || this.activeLocationButtons[i].storyLocationId === BABCOCK_CONSTANTS.HOME; // gamesAvailable;
                }
            // }
        }
    }

    updateWayPoints = () => {
        if (this.activeWayPoints === null) {
            this.activeWayPoints = [];
        }
        if (!WorldController.mainCharacterEntity || !WorldController.mainCharacterEntity.characterSprite) {
            return;
        }
        const wayPointsContainer = v.get('worldview').children.Flyers;
        for (let i = 0; i < BABCOCK_CONSTANTS.WAYPOINTS.length; i++) {
            let wayPointEvaluated = false;

            if (this.activeWayPoints.length < i + 1) {
                this.activeWayPoints.push(null);
            }
            // Check if we should show this waypoint
            let validGameTask = false;
            if (GameState.currentSubTask === BABCOCK_CONSTANTS.NO_TASK && !GameState.timerActive && !GameState.playingMiniGame && WorldController.enabled) {
                if (BABCOCK_CONSTANTS.WAYPOINTS[i].currentGameTask && BABCOCK_CONSTANTS.WAYPOINTS[i].currentGameTask.indexOf(GameState.currentGameTask)) {
                    validGameTask = true;
                }
                if (BABCOCK_CONSTANTS.WAYPOINTS[i].currentGameTaskNot && BABCOCK_CONSTANTS.WAYPOINTS[i].currentGameTaskNot.indexOf(GameState.currentGameTask) === -1) {
                    validGameTask = true;
                }
            }
            if (validGameTask) {
                // console.log('Checking waypoint (valid task): ', BABCOCK_CONSTANTS.WAYPOINTS[i]);
                let storyLocationTiles = WorldController.scrollingTilemapController.tileMapEngine.getSpecialSpritesWithProperty('storyLocation');
                let characterLocationTiles = WorldController.scrollingTilemapController.tileMapEngine.getSpecialSpritesWithProperty('storyCharacter');
                let locationTile = null;
                for (let j = 0; j < storyLocationTiles.length; j++) {
                    let foundLocation = false;
                    // console.log('Checking story tile: ', storyLocationTiles[j]);
                    if (storyLocationTiles[j].config.storyLocation === BABCOCK_CONSTANTS.WAYPOINTS[i].location.id) {
                        foundLocation = true;
                        locationTile = storyLocationTiles[j];
                        if (BABCOCK_CONSTANTS.WAYPOINTS[i].localConfig) {
                            for (let prop in BABCOCK_CONSTANTS.WAYPOINTS[i].localConfig) {
                                if (BABCOCK_CONSTANTS.WAYPOINTS[i].localConfig[prop] !== storyLocationTiles[j].localConfig[prop]) {
                                    foundLocation = false;
                                }
                            }
                        }
                    }
                    if (!foundLocation) {
                        for (let k = 0; k < characterLocationTiles.length; k++) {
                            if (characterLocationTiles[k].localConfig.npc_id === BABCOCK_CONSTANTS.WAYPOINTS[i].npcId) {
                                foundLocation = true;
                                locationTile = characterLocationTiles[k];
                            }
                        }
                    }

                    if (foundLocation && (locationTile.active || (BABCOCK_CONSTANTS.WAYPOINTS[i].location === BABCOCK_CONSTANTS.LOCATIONS.HARBOUR_HOTEL /*&& gamesAvailable*/)) /* && !storyLocationTiles[j].visible */) {
                        /* if (BABCOCK_CONSTANTS.WAYPOINTS[i].location.id === 'Bus Stop') {
                            console.log('Found bus stop! ', storyLocationTiles[j]);
                        } */
                        // Set up / update our waypoint sprite
                        if (this.activeWayPoints[i] === null) {
                            const newWayPointView = new PIXI.Container();
                            const waypointSprite = PIXI.Sprite.from('game-waypoint-marker.png');
                            waypointSprite.x = 0;
                            waypointSprite.y = 0;
                            // Rotate the waypointSprite -90 degrees
                            waypointSprite.pivot.x = 80;
                            waypointSprite.pivot.y = 80;
                            waypointSprite.rotation = -Math.PI / 2;
                            newWayPointView.addChild(waypointSprite);
                            if (BABCOCK_CONSTANTS.WAYPOINTS[i].icon) {
                                const iconSprite = PIXI.Sprite.from(BABCOCK_CONSTANTS.WAYPOINTS[i].icon);
                                iconSprite.anchor.x = 0.5;
                                iconSprite.anchor.y = 0.5;
                                newWayPointView.addChild(iconSprite);
                            }
                            newWayPointView.scale.x = newWayPointView.scale.y = 0.5;
                            newWayPointView.alpha = 0;
                            this.activeWayPoints[i] = newWayPointView;
                            wayPointsContainer.addChild(newWayPointView);

                            // console.log('Adding waypoint: ', newWayPointView);
                        }
                        // Find angle to waypoint
                        const angleToWaypoint = Math.atan2((locationTile.y - locationTile.height / 2) - (WorldController.mainCharacterEntity.characterSprite.y - WorldController.mainCharacterEntity.characterSprite.height / 2), locationTile.x - WorldController.mainCharacterEntity.characterSprite.x);
                        this.activeWayPoints[i].rotation = angleToWaypoint;
                        // Correct icon angle
                        if (this.activeWayPoints[i].children[1]) {
                            this.activeWayPoints[i].children[1].rotation = -angleToWaypoint;
                        }
                        // Only show waypoints over 60 pixels away from our marker
                        const distanceToWaypoint = Math.sqrt(Math.pow((locationTile.y - locationTile.height / 2) - (WorldController.mainCharacterEntity.characterSprite.y - WorldController.mainCharacterEntity.characterSprite.height / 2), 2) + Math.pow(storyLocationTiles[j].x - WorldController.mainCharacterEntity.characterSprite.x, 2));
                        const WAY_POINT_ARROW_DISTANCE = distanceToWaypoint - 50 > ( 320 + 500 *  (1 - AVAILABLE_ZOOMS[WorldController.zoomLevel] ) ) ? ( 320 + 500 *  (1 - AVAILABLE_ZOOMS[WorldController.zoomLevel] ) ) : distanceToWaypoint - 50;

                        // Position way point markers close to the edge of the screen
                        const waypointX = WorldController.mainCharacterEntity.characterSprite.x + Math.cos(angleToWaypoint) * WAY_POINT_ARROW_DISTANCE;
                        const waypointY = (WorldController.mainCharacterEntity.characterSprite.y - WorldController.mainCharacterEntity.characterSprite.height / 2) + Math.sin(angleToWaypoint) * WAY_POINT_ARROW_DISTANCE;
                        // const wayPointPos = {x: waypointX, y: waypointY};
                        this.activeWayPoints[i].x = waypointX;
                        this.activeWayPoints[i].y = waypointY;
                        // this.activeWayPoints[i].visible = true;

                        if (distanceToWaypoint > WAY_POINT_ARROW_DISTANCE + 60) {
                            if (this.activeWayPoints[i].alpha < 1) {
                                this.activeWayPoints[i].alpha += 0.05;
                            }
                            // console.log('Waypoint visible: ', this.activeWayPoints[i]);
                        } else {
                            // this.activeWayPoints[i].visible = false;
                            if (this.activeWayPoints[i].alpha > 0) {
                                this.activeWayPoints[i].alpha -= 0.05;
                            }
                        }

                        wayPointEvaluated = true;
                    }

                    if (wayPointEvaluated) {
                        break;
                    }
                }
                if (!wayPointEvaluated) {
                    if (this.activeWayPoints[i]) {
                        // this.activeWayPoints[i].visible = false;
                        if (this.activeWayPoints[i].alpha > 0) {
                            this.activeWayPoints[i].alpha -= 0.05;
                        }
                    }
                }
            } else {
                if (this.activeWayPoints[i]) {
                    // this.activeWayPoints[i].visible = false;
                    if (this.activeWayPoints[i].alpha > 0) {
                        this.activeWayPoints[i].alpha -= 0.01;
                    }
                }
            }
        }
    }

    removeNpcsWhoLeft = () => {
        // console.log('Removing npcs who left');
        let npcs = WorldController.scrollingTilemapController.tileMapEngine.getSpecialSpritesWithProperty('npc');
        for (let i = 0; i < npcs.length; i++) {
            let npcId = npcs[i].localConfig.npc_id || npcs[i].config.npc_id || '';
            // console.log('npc id: ', npcId);
            if (npcs[i].localConfig && typeof npcs[i].localConfig.char === 'number' && npcs[i].localConfig.char === GameState.selectedCharacter) {
                // remove any npcs that have a char setting that is the same as our selected character
                // this allows us to use the un-selected characters as npcs-about-town!
                WorldController.scrollingTilemapController.removeTileFromMap(npcs[i], true);
            } else
            if (npcId && npcId != '') {
                let npcData = BABCOCK_CONSTANTS.getEntityDataFromId(npcId);
                // console.log('npc data: ', npcData);
                if (npcData) {
                    if (npcData.leaveAfterTask && GameState.npcTasksCompleted.indexOf(npcData) >= 0) {
                        // remove the tile!
                        // console.log('Removing tile! ', npcs[i]);
                        WorldController.scrollingTilemapController.removeTileFromMap(npcs[i], true);
                    }
                }
            }
        }
        // console.log('>>>>>>>>>>');
    }

    removeOldTaskItems = () => {
        for (let i = 0; i < GameState.npcTasksCompleted.length; i++) {
            if (GameState.npcTasksCompleted[i].removeTilesAfterTask) {
                for (let p in GameState.npcTasksCompleted[i].removeTilesAfterTask) {
                    console.log('removing tiles with property: ', p);
                    let taskTiles = WorldController.scrollingTilemapController.tileMapEngine.getSpecialSpritesWithProperty(p);
                    if (taskTiles) {
                        for (let j = 0; j < taskTiles.length; j++) {
                            if (taskTiles[j].config[p] === GameState.npcTasksCompleted[i].removeTilesAfterTask[p] || (taskTiles[j].localConfig && taskTiles[j].localConfig[p] === GameState.npcTasksCompleted[i].removeTilesAfterTask[p])) {
                                WorldController.scrollingTilemapController.removeTileFromMap(taskTiles[j], true);
                            }
                        }
                    }
                }
            }
        }
    }

    addRemoveNpcAlerts = (npcs = null) => {
        if (npcs === null) {
            npcs = WorldController.scrollingTilemapController.activeEntities;
        }
        if (npcs) {
            for (let i = 0; i < npcs.length; i++) {
                let npcData = BABCOCK_CONSTANTS.getEntityDataFromId(npcs[i].npcId);
                if (BABCOCK_CONSTANTS.INTERACTIVE_NPCS.indexOf(npcData) >= 0) {
                    let needsAlert = false;
                    if ((GameState.npcTasksCompleted.indexOf(npcData) === -1 && npcData.phasesAvailable.indexOf(GameState.currentGameTask) >= 0)
                        || (npcData.id === 'neighbour' && GameState.npcTasksCompleted.indexOf(npcData) >= 0 && GameState.currentGameTask === BABCOCK_CONSTANTS.GET_TO_INTERVIEW_TASK)) 
                    {
                        needsAlert = true;
                    }

                    // a couple of exceptions...
                    if (npcData.taskType === BABCOCK_CONSTANTS.SUB_TASK && GameState.currentSubTask === npcData.startTask) {
                        needsAlert = false;
                    }
                    if (npcData.taskType === BABCOCK_CONSTANTS.LEAD_TO_DEST && !npcs[i].waitingAtStart/*(npcs[i].characterGridX != npcs[i].characterStartGridX || npcs[i].characterGridY != npcs[i].characterStartGridY)*/) {
                        needsAlert = false;
                    }
                    if (GameState.currentSubTask === BABCOCK_CONSTANTS.LEAD_TO_DEST) {
                        needsAlert = false;
                    }


                    if (needsAlert) {
                        // needs alert bubble
                        if (npcs[i].characterSprite.alertBubble === undefined || npcs[i].characterSprite.alertBubble === null) {
                            let alertBubble = PIXI.Sprite.from('person_questIndicator.png');
                            alertBubble.anchor.x = 1;
                            alertBubble.anchor.y = 1;
                            alertBubble.scale.x = alertBubble.scale.y = 0.5;
                            alertBubble.x = -5;
                            alertBubble.y = -npcs[i].characterSprite.height - 5;
                            npcs[i].characterSprite.addChild(alertBubble);
                            npcs[i].characterSprite.alertBubble = alertBubble;
                        }
                        npcs[i].characterSprite.alertBubble.visible = true;
                    } else {
                        // doesn't need alert bubble
                        if (npcs[i].characterSprite.alertBubble) {
                            let alertBubble = npcs[i].characterSprite.alertBubble;
                            npcs[i].characterSprite.removeChild(alertBubble);
                            npcs[i].characterSprite.alertBubble = null;
                        }
                    }
                }
            }
        }
    }

    takeSelfie = () => {
        CompleteSelfie(this.lastInteraction);
        this.updateLocationIcons();
    }

    miniGameComplete = (stars, score) => {
        let locationData = BABCOCK_CONSTANTS.getLocationDataFromId(this.lastInteraction);
        
        let prevStars = GameState.gameResults[locationData.game.id];
        let starsUp = stars - prevStars;
        if (starsUp > 0) {
            for (let i = 0; i < locationData.game.progressBadges.length; i++) {
                ProgressBadge(locationData.game.progressBadges[i].id, starsUp);
            }
        }

        // figure out our rewards
        for (let i = 1; i <= stars; i++) {
            if (i > prevStars) {
                // we earned a reward!
                let rewardData = {};
                switch (i) {
                    case 1:
                        rewardData.icon = GameRewardCvIcon;
                        rewardData.title = 'CV Item';
                        rewardData.itemName = 'Work Reference';
                        rewardData.addedText = 'Added to your CV!';
                        break;
                    case 2:
                        rewardData.icon = locationData.game.itemEarned.rewardIcon;
                        rewardData.title = 'Task Item';
                        rewardData.itemName = locationData.game.itemEarned.description;
                        rewardData.addedText = 'Added to your backpack!';
                        this.earnedGameItem = true;
                        break;
                    case 3:
                        rewardData.icon = GameRewardTrophyIcon;
                        rewardData.title = 'Minigame Trophy';
                        rewardData.itemName = 'Well Done';
                        rewardData.addedText = 'You gained 3 stars on this minigame!';
                        break;
                }
                this.gameRewardQueue.push(rewardData);
                // console.log('Reward queued: ', rewardData);
            }
        }
        // console.log('Rewards queue: ', this.gameRewardQueue);

        SetMiniGameResult(locationData.game.id, stars, score);
        if (stars >= 2) {
            CollectItem(locationData.game.itemEarned);
            if (locationData === BABCOCK_CONSTANTS.LOCATIONS.HARBOUR_HOTEL) {
                NpcTaskCompleted(BABCOCK_CONSTANTS.HOTEL_NPC);
            }
        }
        this.updateLocationIcons();

        if (stars >= 1) {
            let questionToAsk = GetQuestionToAsk(locationData.game);
            if (questionToAsk) {
                if (GameState.questionsAnswered.indexOf(questionToAsk) === -1) {
                    this.queuedQuestion = questionToAsk;
                }
            }
        }

        ApiController.triggerGameDataDump();
    }

    updateLocationIcons = () => {
        for (let i = 0; i < this.activeLocationButtons.length; i++) {
            let storyLocationId = this.activeLocationButtons[i].storyTile.config.storyLocation;
            if (BABCOCK_CONSTANTS.SELFIE_LOCATIONS.indexOf(storyLocationId) >= 0) {
                let imageId = IsSelfieTaken(storyLocationId) ? 'selfie_Unlocked.png' : 'selfie_locked.png';
                this.activeLocationButtons[i].buttonBg.texture = PIXI.Texture.from(imageId);
            } else
            if (BABCOCK_CONSTANTS.GAME_LOCATIONS.indexOf(storyLocationId) >= 0) {
                let locationData = BABCOCK_CONSTANTS.getLocationDataFromId(storyLocationId);
                let gameStars = GameState.gameResults[locationData.game.id];
                if (gameStars >= 1) {
                    this.activeLocationButtons[i].cvIcon.texture = PIXI.Texture.from('cv_Unlocked.png');
                }
                if (gameStars >= 2) {
                    this.activeLocationButtons[i].itemIcon.texture = PIXI.Texture.from(locationData.game.itemEarned.pillIconIdUnocked);
                }
                if (gameStars >= 3) {
                    this.activeLocationButtons[i].attributeIcon.texture = PIXI.Texture.from('trophy_Unlocked.png');
                }
            }
        }
    }

    removeLocationButtons = () => {
        if (this.characterHighlightAnim.parent) {
            this.characterHighlightAnim.parent.removeChild(this.characterHighlightAnim);
        }
        if (this.activeLocationButtons && this.activeLocationButtons.length) {
            for (let i = 0; i < this.activeLocationButtons.length; i++) {
                this.activeLocationButtons[i].parent.removeChild(this.activeLocationButtons[i]);
                this.activeLocationButtons[i].destroy();
            }
            this.activeLocationButtons = null
        }
    }

    interactButtonHandler = (e) => {
        console.log('Interact button clicked: ', e, e.target);
        this.handleInteraction(e.target.storyTile);
    }

    handleInteraction = (storyTile) => {
        // no interaction with invisible tiles
        if (storyTile.active === false || GameState.currentSubTask === BABCOCK_CONSTANTS.LEAD_TO_DEST) {
            return true;
        }
        // console.log('Babcock handle interaction: ', storyTile);
        let storyLocationId = storyTile.config.storyLocation;
        ReactGA.event({
            category: "Main Game Action",
            action: "Location Interaction",
            label: storyLocationId,
        });
        if (GameState.currentGameTask === BABCOCK_CONSTANTS.GET_TO_INTERVIEW_TASK && storyLocationId === BABCOCK_CONSTANTS.BABCOCK_OFFICE) {
            this.lastInteraction = storyLocationId;
            this.showInteractionDialog('Game complete!', BABCOCK_OFFICE_DIALOG.completeTask);
            return true;
        }
        if (BABCOCK_CONSTANTS.SELFIE_LOCATIONS.indexOf(storyLocationId) >= 0) {
            this.lastInteraction = storyLocationId;
            v.showView('selfielocation');
            WorldController.disable();
            return true;
        }
        if (BABCOCK_CONSTANTS.GAME_LOCATIONS.indexOf(storyLocationId) >= 0) {
            this.lastInteraction = storyLocationId;
            let locationData = BABCOCK_CONSTANTS.getLocationDataFromId(storyLocationId);
            if (locationData.complexDialog && GameState.npcsInteractedWith.indexOf(locationData) === -1) {
                let complexDialog = locationData.complexDialog.beforeTask;
                this.showInteractionDialog('Starting game...', complexDialog);
                GameState.npcsInteractedWith.push(locationData);
            } else {
                v.showView('startgame');
                WorldController.disable();
            }
            return true;
        }
        if (GameState.currentGameTask === BABCOCK_CONSTANTS.MAIN_TASK && storyLocationId === BABCOCK_CONSTANTS.SKILLS_LAUNCHPAD) {
            this.lastInteraction = storyLocationId;
            if (GameState.gameResults.indexOf(0) === -1) {
                let complexDialog = SKILLS_LAUNCHPAD_DIALOG.afterTask_cvReady;
                this.showInteractionDialog('CV ready...', complexDialog, 'cvview');
            } else {
                let complexDialog = SKILLS_LAUNCHPAD_DIALOG.afterTask;
                this.showInteractionDialog('CV not ready...', complexDialog);
            }
            return true;
        }
        if (BABCOCK_CONSTANTS.SIMPLE_TASK_LOCATIONS.indexOf(storyLocationId) >= 0) {
            this.lastInteraction = storyLocationId;
            let interacted = false;
            let locationData = BABCOCK_CONSTANTS.getLocationDataFromId(storyLocationId);
            // console.log('Try simple task: ', locationData);
            if (locationData.complexDialog) {
                let dialogData = locationData.complexDialog;
                if (GameState.npcTasksCompleted.indexOf(locationData) >= 0) {
                    // task already completed!
                    let complexDialog = null;
                    if (dialogData) {
                        complexDialog = GameState.npcsInteractedWith.indexOf(locationData) === -1 ? dialogData.afterTask : dialogData.afterTaskRevisit || dialogData.afterTask;
                    }
                    this.showInteractionDialog('Task already complete!', complexDialog);
                    // return true;
                    interacted = true;
                } else {
                    switch (locationData.taskType) {
                        case BABCOCK_CONSTANTS.INTRO_TASK:
                            if (!GameState.firstPlay) {
                                v.showView('skiptutorial');
                            }
                        case BABCOCK_CONSTANTS.NO_TASK:
                        case BABCOCK_CONSTANTS.SUB_TASK:
                            let showViewAfterDialog = locationData.showViewAfterDialog || '';
                            if (locationData.hideRoutes) {
                                this.hideHilightedRoute();
                            }
                            let complexDialog = null;
                            if (dialogData) {
                                complexDialog = GameState.npcsInteractedWith.indexOf(locationData) === -1 ? dialogData.beforeTask : dialogData.beforeTaskRevisit || dialogData.beforeTask;
                            }
                            this.showInteractionDialog('Task not complete!', complexDialog, showViewAfterDialog);
                            // return true;
                            interacted = true;
                            if (locationData.startTask && GameState.currentSubTask !== locationData.startTask) {
                                GameState.currentSubTask = locationData.startTask;
                                GameState.litterCollected = 0;
                            }
                            break;
                        case BABCOCK_CONSTANTS.REQUIRES_ITEM:
                            if (GameState.inventory.indexOf(locationData.requiresItem) === -1 || (locationData.id === BABCOCK_CONSTANTS.HOME && GameState.applicationSent === false)) {
                                let complexDialog = null;
                                if (dialogData) {
                                    complexDialog = GameState.npcsInteractedWith.indexOf(locationData) === -1 ? dialogData.beforeTask : dialogData.beforeTaskRevisit || dialogData.beforeTask;
                                }
                                this.showInteractionDialog('Task not complete!', complexDialog);
                            } else {
                                let complexDialog = null;
                                if (dialogData) {
                                    complexDialog = GameState.npcsInteractedWith.indexOf(locationData) === -1 ? dialogData.completeTask : dialogData.completeTaskRevisit || dialogData.completeTask;
                                }
                                this.showInteractionDialog('Completing task!', complexDialog);
                                // GiveItem(locationData.requiresItem, locationData);
                                // Now doing this on the actual dialogue panel so it can change after the first page
                                // if (GameState.wearingSuit === false && locationData.id === BABCOCK_CONSTANTS.HOME && GameState.applicationSent === true) {
                                    // we are changing into our suit. Defo need a better system for this kind of stuff for the next game!
                                    // GameState.wearingSuit = true;
                                    // the actual change is now happening at the end of the dialog
                                    // let suitedCharacterData = BABCOCK_CONSTANTS.CHARACTER_SUITS[GameState.selectedCharacter];
                                    // WorldController.maincharacterSpriteData = suitedCharacterData;
                                    // WorldController.mainCharacterEntity.updateSpriteData(suitedCharacterData);
                                // }
                            }
                            // return true;
                            interacted = true;
                            break;
                    }
                    if (interacted) {
                        NpcInteractedWith(locationData);
                        // console.log(GameState);
                        v.get('hudview').reflectGameState();
                        return true;
                    }
                }
            } else {
                this.dialogCompleted();
            }
            v.get('hudview').reflectGameState();
            return true;
        }
        
        // return false;
        return true;
    }

    phoneAnswered = () => {
        if (REACT_VIEW_CONTROLLER.isViewActive('cvview') && !GameState.applicationSent) {
            this.showInteractionDialog('CV Ready!', CV_READY_DIALOG);
        } else
        if (GameState.currentGameTask === BABCOCK_CONSTANTS.INTRO_TASK) {
            this.advanceIntroPhase();
        } else
        if (GameState.applicationSent) {
            GameState.currentGameTask = BABCOCK_CONSTANTS.CHANGE_CLOTHES_TASK;
            if (GameState.itemsCollected.indexOf(BABCOCK_CONSTANTS.CLOTHES) >= 0) {
                this.showInteractionDialog('Interview offer!', INTERVIEW_CALL_DIALOG_GOT_CLOTHES);
            } else {
                this.showInteractionDialog('Interview offer!', INTERVIEW_CALL_DIALOG);
            }
            this.queuedQuestion = null;
            this.activeQuestion = null;
        } else
        if (this.queuedQuestion) {
            this.showDelayedQuestion();
        }
    }

    dialogCompleted = () => {
        if (this.showViewAfterDialog != '') {
            v.showView(this.showViewAfterDialog);
            WorldController.disable();
            this.showViewAfterDialog = '';
            // this.lastInteraction = null;
            /*
            if (this.showViewAfterDialog === 'cvview') {
                this.startCvTutorial();
                return;
            }
            */
            // ApiController.triggerGameDataDump();
        }
        /* Now doing this on the actual dialogue panel so it can change after the first page
        if (GameState.wearingSuit === false && this.lastInteraction === BABCOCK_CONSTANTS.HOME && GameState.applicationSent === true) {
            // we are changing into our suit. Defo need a better system for this kind of stuff for the next game!
            GameState.wearingSuit = true;
            let suitedCharacterData = BABCOCK_CONSTANTS.CHARACTER_SUITS[GameState.selectedCharacter];
            WorldController.maincharacterSpriteData = suitedCharacterData;
            WorldController.mainCharacterEntity.updateSpriteData(suitedCharacterData);
        }
        */
        if (GameState.currentGameTask === BABCOCK_CONSTANTS.GET_TO_INTERVIEW_TASK && this.lastInteraction === BABCOCK_CONSTANTS.BABCOCK_OFFICE) {
            // game end!
            WorldController.deactivate();
            v.hideView('hudview');
            v.showView('resultscreen');
            ApiController.triggerGameDataDump();
        }
        if (GameState.currentGameTask === BABCOCK_CONSTANTS.GET_TO_INTERVIEW_TASK && GameState.gettingLift) {
            WorldController.entryPoint = 'city_centre_lift';
            WorldController.transitionToMap(MapLayouts['city_centre']);
        }
        if (BABCOCK_CONSTANTS.GAME_LOCATIONS.indexOf(this.lastInteraction) >= 0) {
            v.showView('startgame');
            WorldController.disable();
        }
        if (!GameState.applicationSent && this.queuedQuestion) {
            WorldController.enable();
            this.startQuestionFlow();
        } else {
            this.queuedQuestion = null;
        }
        if (BABCOCK_CONSTANTS.SIMPLE_TASK_LOCATIONS.indexOf(this.lastInteraction) >= 0) {
            let locationData = BABCOCK_CONSTANTS.getLocationDataFromId(this.lastInteraction);
            if (locationData.requiresItem) {
                if (locationData.id !== BABCOCK_CONSTANTS.HOME || GameState.applicationSent) {
                    if (GiveItem(locationData.requiresItem, locationData)) {
                        if (locationData.givesItem) {
                            CollectItem(locationData.givesItem);
                        }
                        if (locationData.startTaskPhase && (locationData.id !== BABCOCK_CONSTANTS.BIZ_WEAR_SHOP || GameState.applicationSent)) {
                            GameState.currentGameTask = locationData.startTaskPhase;
                        }
                    } else {
                        if (locationData.id === BABCOCK_CONSTANTS.BIZ_WEAR_SHOP && GameState.applicationSent) {
                            GameState.currentGameTask = locationData.startTaskPhase;
                        }
                    }
                }
            } else
            if (locationData.startTaskPhase) {
                if (GameState.currentGameTask === locationData.taskType || (locationData.id === BABCOCK_CONSTANTS.BIZ_WEAR_SHOP && GameState.applicationSent)) {
                    GameState.currentGameTask = locationData.startTaskPhase;
                    if (locationData.startTaskPhase !== BABCOCK_CONSTANTS.MAIN_TASK) {
                        NpcTaskCompleted(locationData);
                    }
                }
            }
        }
        if (GameState.cvTutorialActive) {
            this.advanceCvPhase();
        } else
        if (GameState.currentGameTask === BABCOCK_CONSTANTS.INTRO_TASK) {
            this.advanceIntroPhase();
        }
        if (/*GameState.currentSubTask === BABCOCK_CONSTANTS.GET_TO_INTERVIEW_TASK || */GameState.currentSubTask === BABCOCK_CONSTANTS.LEAD_TO_DEST) {
            GameState.timerActive = true;
            au.play('timerloop', 1, true, 0, false);
            this.entityBeingLed.followCharacter();
            WorldController.canLeaveMap = false;
        } else {
            GameState.timerActive = false;
            v.hideView('hudtimer');
            WorldController.canLeaveMap = true;
            if (this.entityBeingLed) {
                this.entityBeingLed.restartMovement();
            }
        }

        this.addRemoveNpcAlerts();
        this.showHideAvailableLocations();
        v.get('hudview').reflectGameState();
    }

    earnedGameItem = false;
    showAfterGameDialog = () => {
        let locationData = BABCOCK_CONSTANTS.getLocationDataFromId(this.lastInteraction);
        if (locationData && locationData.complexDialog && GameState.npcTasksCompleted.indexOf(locationData) === -1) {
            if (GameState.gameResults[locationData.game.id] > 0) {
                let complexDialog = locationData.complexDialog.afterTask;
                this.showInteractionDialog('Completed game...', complexDialog, this.earnedGameItem ? 'newitempop' : '');
                GameState.npcTasksCompleted.push(locationData);
            } else {
                let complexDialog = locationData.complexDialog.taskFailed;
                this.showInteractionDialog('Failed game...', complexDialog);
            }
            this.pauseCharacter();
        } else {
            if (this.earnedGameItem) {
                v.showView('newitempop');
            }
        }
        this.earnedGameItem = false;
        this.lastInteraction = null;
    }

    handleEntityInteraction(entityId, entity) {
        if (GameState.currentSubTask === BABCOCK_CONSTANTS.LEAD_TO_DEST) {
            return true;
        }
        if (entityId) {
            // console.log('Interacting with entity: ', entity);
            let entityData = BABCOCK_CONSTANTS.getEntityDataFromId(entityId);
            if (entityData) {
                let interacted = false;
                let dialogData = entityData.complexDialog;
                // do the interaction!
                // one hard-coded exception - neighbour giving us a lift!
                // I'm sure in the next iteration of this type of game we'll dispense with these hard-coded things
                if (entityId === 'neighbour' && GameState.currentGameTask === BABCOCK_CONSTANTS.GET_TO_INTERVIEW_TASK && GameState.npcTasksCompleted.indexOf(entityData) >= 0) {
                    GameState.gettingLift = GameState.gotLift = true;
                    let complexDialog = dialogData.getLiftBefore;
                    this.showInteractionDialog(entityData.afterTaskDialog, complexDialog);
                    // return true;
                    interacted = true;
                } else
                if (GameState.npcTasksCompleted.indexOf(entityData) >= 0 && entityData.taskType !== BABCOCK_CONSTANTS.GAME_TASK) {
                    // task already completed!
                    let complexDialog = null;
                    if (dialogData) {
                        complexDialog = GameState.npcsInteractedWith.indexOf(entityData) === -1 ? dialogData.afterTask : dialogData.afterTaskRevisit || dialogData.afterTask;
                    }
                    this.showInteractionDialog(entityData.afterTaskDialog, complexDialog);
                    // return true;
                    interacted = true;
                } else {
                    if (entityData.phasesAvailable.indexOf(GameState.currentGameTask) === -1) {
                        let complexDialog = null;
                        if (dialogData.taskUnavailable) {
                            complexDialog = dialogData.taskUnavailable;
                        }
                        this.showInteractionDialog('I\'m kinda busy right now!', complexDialog);
                        return true;
                    }
                    switch (entityData.taskType) {
                        case BABCOCK_CONSTANTS.SUB_TASK:
                            let complexDialog = null;
                            if (dialogData) {
                                complexDialog = GameState.npcsInteractedWith.indexOf(entityData) === -1 ? dialogData.beforeTask : dialogData.beforeTaskRevisit || dialogData.beforeTask;
                            }
                            this.showInteractionDialog(entityData.beforeTaskDialog, complexDialog);
                            // return true;
                            interacted = true;
                            if (entityData.startTask && GameState.currentSubTask !== entityData.startTask) {
                                GameState.currentSubTask = entityData.startTask;
                                GameState.litterCollected = 0;
                                v.get('hudview').reflectGameState();
                                if (GameState.currentSubTask === BABCOCK_CONSTANTS.COLLECT_LITTER_TASK) {
                                    v.showView('hudlitter');
                                }
                            }
                            break;
                        case BABCOCK_CONSTANTS.REQUIRES_ITEM:
                            if (GameState.inventory.indexOf(entityData.requiresItem) === -1) {
                                let complexDialog = null;
                                if (dialogData) {
                                    complexDialog = GameState.npcsInteractedWith.indexOf(entityData) === -1 ? dialogData.beforeTask : dialogData.beforeTaskRevisit || dialogData.beforeTask;
                                }
                                this.showInteractionDialog(entityData.beforeTaskDialog, complexDialog);
                            } else {
                                let complexDialog = null;
                                if (dialogData) {
                                    complexDialog = GameState.npcsInteractedWith.indexOf(entityData) === -1 ? dialogData.completeTask : dialogData.completeTaskRevisit || dialogData.completeTask;
                                }
                                this.showInteractionDialog(entityData.completeTaskDialog, complexDialog);
                                GiveItem(entityData.requiresItem, entityData);
                                if (entityData.leaveAfterTask === true) {
                                    entity.restartMovementAfterInteraction = true;
                                }
                            }
                            // return true;
                            interacted = true;
                            break;
                        case BABCOCK_CONSTANTS.LEAD_TO_DEST:
                            if (!entity.reachedFinalDestination && entity.waitingAtStart /*entity.characterGridX === entity.characterStartGridX && entity.characterGridY === entity.characterStartGridY*/) {
                                let complexDialog = null;
                                if (dialogData) {
                                    complexDialog = GameState.npcsInteractedWith.indexOf(entityData) === -1 ? dialogData.beforeTask : dialogData.beforeTaskRevisit || dialogData.beforeTask;
                                }
                                this.showInteractionDialog(entityData.beforeTaskDialog, complexDialog);
                                /*
                                entity.pauseAtStart = false;
                                entity.randomiseDestGridPos = true;
                                entity.characterFollower = true;
                                entity.characterToFollow = WorldController.mainCharacterEntity;
                                */
                                entity.startLeading(WorldController.mainCharacterEntity);
                                // entity.followCharacter();
                                this.entityBeingLed = entity;
                                /*
                                if (entity.characterSprite.alertBubble) {
                                    entity.characterSprite.alertBubble.visible = false;
                                }
                                */
                                GameState.currentSubTask = BABCOCK_CONSTANTS.LEAD_TO_DEST;
                                GameState.taskCountdown = BABCOCK_CONSTANTS.LEAD_PERSON_TIME;
                                v.get('hudview').reflectGameState();
                                v.showView('hudtimer');
                            }
                            // return true;
                            interacted = true;
                            break;
                        case BABCOCK_CONSTANTS.GAME_TASK:
                            let storyLocationId = entityData.locationId;
                            this.lastInteraction = storyLocationId;
                            let locationData = BABCOCK_CONSTANTS.getLocationDataFromId(storyLocationId);
                            if (locationData.complexDialog && GameState.npcsInteractedWith.indexOf(locationData) === -1) {
                                let complexDialog = locationData.complexDialog.beforeTask;
                                this.showInteractionDialog('Starting game...', complexDialog);
                                GameState.npcsInteractedWith.push(locationData);
                            } else {
                                let complexDialog = locationData.complexDialog.beforeTaskRevisit;
                                this.showInteractionDialog('Starting game...', complexDialog);
                                // GameState.npcsInteractedWith.push(locationData);
                                // v.showView('startgame');
                                // WorldController.disable();
                            }
                            // return true;
                            interacted = true;
                            break;
                    }
                }
                if (interacted) {
                    NpcInteractedWith(entityData);
                    // console.log(GameState);
                    return true;
                }
            }
        }
        return false;
    }

    characterLeadToDestination = (entity) => {
        // console.log('character lead to dest: ', entity);
        let entityId = entity.npcId;
        let entityData = BABCOCK_CONSTANTS.getEntityDataFromId(entityId);
        let dialogData = entityData.complexDialog;
        if (entityData) {
            if (GameState.npcTasksCompleted.indexOf(entityData) === -1) {
                // GameState.npcTasksCompleted.push(entityData);
                NpcTaskCompleted(entityData);
                let complexDialog = null;
                if (dialogData) {
                    complexDialog = GameState.npcsInteractedWith.indexOf(entityData) === -1 ? dialogData.completeTask : dialogData.completeTaskRevisit || dialogData.completeTask;
                }
                this.showInteractionDialog(entityData.completeTaskDialog, complexDialog);
                entity.pauseMovement(Infinity);
                GameState.currentSubTask = BABCOCK_CONSTANTS.NO_TASK;
                v.get('hudview').reflectGameState();

                GameState.timerActive = false;
                au.stop('timerloop');
            }
        }
    }

    showInteractionDialog(dialogText = 'Nothing', complexDialog = null, showViewAfterDialog = '') {
        // console.log('Dialog started: ', complexDialog, showViewAfterDialog);
        this.showViewAfterDialog = showViewAfterDialog;
        // PANEL_STATE.panelOpen = true;
        PANEL_STATE.complexDialog = complexDialog;
        PANEL_STATE.contents = dialogText;
        v.showView('advisordialog');
        WorldController.disable();
    }

    entityNearCollectable = (entity, collectable) => {
        if (GameState.currentSubTask === BABCOCK_CONSTANTS.COLLECT_LITTER_TASK) {
            // console.log('Collect litter! ', entity, collectable);
            if (!collectable.collected && entity === WorldController.mainCharacterEntity && collectable.config.collectableId === 'hospital_litter') {
                // GameState.litterCollected++;
                collectable.collected = true;
                gsap.to(collectable, {alpha: 0, duration: 0.3});
                v.get('hudview').flyLitterPieceToHud(collectable.toGlobal(collectable.defaultSprite.position), collectable);
                /*
                let hudlitter = v.get('hudlitter');
                if (hudlitter) {
                    hudlitter.updateLitterCount();
                }
                if (GameState.litterCollected === BABCOCK_CONSTANTS.LITTER_COUNT) {
                    let taskEntityData = BABCOCK_CONSTANTS.getEntityDataFromId('hospitalgroundsman');
                    let dialogData = taskEntityData.complexDialog;
                    let complexDialog = null;
                    if (dialogData) {
                        complexDialog = dialogData.completeTask;
                    }
                    this.showInteractionDialog(taskEntityData.completeTaskDialog, complexDialog);

                    NpcTaskCompleted(taskEntityData);
                    GameState.currentSubTask = BABCOCK_CONSTANTS.NO_TASK;
                    v.get('hudview').reflectGameState();
                    v.hideView('hudlitter');

                    au.play('tasktrash', 1, false, 0, false);
                }
                */
            }
        }
    }

    updateLitterCount = () => {
        au.play('tutorial', 1, false, 0, false);
        GameState.litterCollected++;
        let hudlitter = v.get('hudlitter');
        if (hudlitter) {
            hudlitter.updateLitterCount();
        }
        if (GameState.litterCollected === BABCOCK_CONSTANTS.LITTER_COUNT) {
            let taskEntityData = BABCOCK_CONSTANTS.getEntityDataFromId('hospitalgroundsman');
            let dialogData = taskEntityData.complexDialog;
            let complexDialog = null;
            if (dialogData) {
                complexDialog = dialogData.completeTask;
            }
            this.showInteractionDialog(taskEntityData.completeTaskDialog, complexDialog);

            NpcTaskCompleted(taskEntityData);
            GameState.currentSubTask = BABCOCK_CONSTANTS.NO_TASK;
            v.get('hudview').reflectGameState();
            v.hideView('hudlitter');

            au.play('tasktrash', 1, false, 0, false);
        }
    }

    questionId = -1;
    startQuestionFlow = () => {
        clearTimeout(this.questionId);
        setTimeout(this.doQuestionCall, 2000);
    }

    viewsThatBlockQuestions = [
        'cvview',
        'phoneringing',
        'advisordialog',
        'prompts',
        'settings',
        'ctainfo',
        'inventorypanel',
        'startgame',
        'endofgame',
        'gametutorial',
        'gamereward',
        'selfielocation',
        'selfieresult',
        'maptransition',
        'questions',
        'sendcvprompt',
        'tasklist',
        'hudtimer',
        'hudlitter',
        'pausepanel',
        'badgeinfopop',
        'contactform'
    ];

    areGamesActive = () => {
        let gameViews = v.getMany(['coffeegame_view', 'fb_gameView', 'pg_gameView']);
        for (let i = 0; i < gameViews.length; i++) {
            if (gameViews[i].container.visible) {
                return true;
            }
        }
        return false;
    }

    doQuestionCall = () => {
        if (REACT_VIEW_CONTROLLER.areViewsActive(this.viewsThatBlockQuestions) === false && this.areGamesActive() === false && this.queuedQuestion && WorldController.enabled) {
            this.showViewAfterDialog = '';
            this.lastInteraction = null;
            PANEL_STATE.contents = 'Sarah @ Skills Launchpad';
            v.showView('phoneringing');
            WorldController.disable();
        } else
        if (GameState.currentGameTask === BABCOCK_CONSTANTS.MAIN_TASK) {
            this.startQuestionFlow();
        } else {
            this.queuedQuestion = null;
            this.activeQuestion = null;
        }
    }

    showDelayedQuestion = () => {
        // if (this.queuedQuestion && WorldController.enabled) {
            this.showInteractionDialog('Question intro', this.queuedQuestion.introDialog, 'questions');
            this.activeQuestion = this.queuedQuestion;
            this.queuedQuestion = null;
        // } else
        // if (GameState.currentGameTask === BABCOCK_CONTROLLER.MAIN_TASK) {
            // this.startQuestionFlow();
        // } else {
            // this.queuedQuestion = null;
            // this.activeQuestion = null;
        // }
    }

    advanceIntroPhase = () => {
        GameState.introPhase++;
        if (GameState.introPhase < BABCOCK_CONSTANTS.INTRO_PHASES.length) {
            this.showIntroPhase();
            if (!GameState.firstPlay) {
                v.showView('skiptutorial');
            }
        } else {
            this.hilightRoute('Skills Launchpad');

            v.hideView('skiptutorial');
            ReactGA.event({
                category: "Main Game Action",
                action: "Intro Tutorial Complete",
            });
        }
    }

    skipIntroPhase = () => {
        // console.log('Skip intro: ', GameState.currentGameTask);
        if (GameState.currentGameTask === BABCOCK_CONSTANTS.INTRO_TASK) {
            // console.log('Skipped!');
            GameState.currentGameTask = BABCOCK_CONSTANTS.MAIN_TASK;
            GameState.cvTutorialShown = true
            this.addRemoveNpcAlerts();
            this.showHideAvailableLocations();
            this.hilightRoute('');
            WorldController.canLeaveMap = true;
        }
    }

    showIntroPhase = () => {
        switch (BABCOCK_CONSTANTS.INTRO_PHASES[GameState.introPhase].type) {
            case 'dialog':
                this.showInteractionDialog('intro_dialog', BABCOCK_CONSTANTS.INTRO_PHASES[GameState.introPhase].dialog);
                break;
            case 'phone':
                PANEL_STATE.contents = BABCOCK_CONSTANTS.INTRO_PHASES[GameState.introPhase].caller;
                v.showView('phoneringing');
                WorldController.disable();
                break;
            case 'prompts':
                PANEL_STATE.promptMessages = BABCOCK_CONSTANTS.INTRO_PHASES[GameState.introPhase].prompts;
                PANEL_STATE.promptImages = BABCOCK_CONSTANTS.INTRO_PHASES[GameState.introPhase].promptImages;
                PANEL_STATE.promptArias = BABCOCK_CONSTANTS.INTRO_PHASES[GameState.introPhase].promptArias;
                v.showView('prompts');
                WorldController.disable();
                break;
        }
    }

    skipTutorial = () => {
        au.play('secondarybtn', 1, false, 0, true);

        if (GameState.cvTutorialActive && GameState.cvTutorialPhase < BABCOCK_CONSTANTS.CV_TUTORIAL_PHASES.length) {
            GameState.cvTutorialPhase = BABCOCK_CONSTANTS.CV_TUTORIAL_PHASES.length;
            GameState.cvTutorialActive = false;
            let cvView = v.get('cvview');
            if (cvView) {
                cvView.highlightSection('');
            }
            let dialogView = v.get('advisordialog');
            dialogView.closePanel();
            v.hideView('bottomgrad');
            v.hideView('bottomgrad2');
            v.hideView('bottomgrad3');

            WorldController.enable();
            v.get('hudview').fade(1);

            ReactGA.event({
                category: "Main Game Action",
                action: "Intro Tutorial Skipped",
            });
        } else
        if (GameState.currentGameTask === BABCOCK_CONSTANTS.INTRO_TASK && GameState.introPhase < BABCOCK_CONSTANTS.INTRO_PHASES.length) {
            GameState.introPhase = BABCOCK_CONSTANTS.INTRO_PHASES.length;
            this.hilightRoute('Skills Launchpad');
            let dialogView = v.get('advisordialog');
            if (dialogView) {
                dialogView.closePanel();
            }
            v.hideView('prompts');
            v.hideView('phoneringing');
            v.hideView('bottomgrad');
            v.hideView('bottomgrad2');
            v.hideView('bottomgrad3');

            WorldController.enable();
            v.get('hudview').fade(1);

            ReactGA.event({
                category: "Main Game Action",
                action: "CV Tutorial Skipped",
            });
        } else {
            let dialogView = v.get('advisordialog');
            if (dialogView) {
                dialogView.closePanel();
                this.dialogCompleted();
            }
        }
        v.hideView('skiptutorial');
    }

    startCvTutorial = () => {
        GameState.cvTutorialActive = true;
        GameState.cvTutorialPhase = 0;
        this.showCvPhase();
        // setTimeout(this.showCvPhase, 300);
        WorldController.canLeaveMap = true;
        if (!GameState.firstPlay) {
            v.showView('skiptutorial');
        }
    }

    advanceCvPhase = () => {
        GameState.cvTutorialPhase++;
        if (GameState.cvTutorialPhase < BABCOCK_CONSTANTS.CV_TUTORIAL_PHASES.length) {
            // this.showCvPhase();
            setTimeout(this.showCvPhase, 500);
        } else {
            GameState.cvTutorialActive = false;
            GameState.firstPlay = false;

            let cvView = v.get('cvview');
            if (cvView) {
                cvView.highlightSection('');
            }

            v.hideView('skiptutorial');
            ReactGA.event({
                category: "Main Game Action",
                action: "CV Tutorial Complete",
            });
        }
    }

    showCvPhase = () => {
        console.log('show cv phase: ', BABCOCK_CONSTANTS.CV_TUTORIAL_PHASES[GameState.cvTutorialPhase]);
        switch (BABCOCK_CONSTANTS.CV_TUTORIAL_PHASES[GameState.cvTutorialPhase].type) {
            case 'dialog':
                this.showInteractionDialog('cv_dialog', BABCOCK_CONSTANTS.CV_TUTORIAL_PHASES[GameState.cvTutorialPhase].dialog);
                break;
            case 'prompts':
                PANEL_STATE.promptMessages = BABCOCK_CONSTANTS.CV_TUTORIAL_PHASES[GameState.cvTutorialPhase].prompts;
                PANEL_STATE.promptImages = BABCOCK_CONSTANTS.CV_TUTORIAL_PHASES[GameState.cvTutorialPhase].promptImages;
                PANEL_STATE.promptArias = BABCOCK_CONSTANTS.CV_TUTORIAL_PHASES[GameState.cvTutorialPhase].promptArias;
                v.showView('prompts');
                WorldController.disable();
                break;
        }
        let cvView = v.get('cvview');
        if (cvView) {
            cvView.highlightSection(BABCOCK_CONSTANTS.CV_TUTORIAL_PHASES[GameState.cvTutorialPhase].hilightSection);
            let dialogBox = v.get('advisordialog');
            if (dialogBox) {
                if (BABCOCK_CONSTANTS.CV_TUTORIAL_PHASES[GameState.cvTutorialPhase].hilightSection === 'hobbies') {
                    dialogBox.setState({top: '75%'});
                } else {
                    dialogBox.setState({top: '100%'});
                }
            }
        }
    }

    miniGameCompleteEventHandler = (event) => {
        // alert("Players Score" + event.detail.score);
        // alert("Players Stars" + event.detail.stars);
        console.log('Minigame complete handler: ', event);
        let starsEarned = event.detail.stars;
        let score = event.detail.score;
        this.miniGameComplete(starsEarned, score);
        v.hideViews(GameState.playingMiniGame.game.gameViews);
        this.lastInteraction = GameState.playingMiniGame.id;
        GameState.playingMiniGame = null;
        if (this.gameRewardQueue.length === 0) {
            v.showViews(['endofgame', 'hudview']);
        } else {
            v.showView('gamerewardbg');
            v.showView('gamereward');
        }
        au.play('babcock_ingame_music', 1, true, 0, false);
        WorldController.scrollingTilemapController.startMapSounds();
    }

    unpause = () => {
        document.dispatchEvent(new CustomEvent("unpause"));
    }
}

const BABCOCK_CONTROLLER = new BabcockController();
export { BABCOCK_CONTROLLER as default }