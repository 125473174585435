export const GENERIC_NPC_INTERACTIONS = {
    NPC: [
        'Good Morning!',
        'Hello!',
        'Nice to meet you.',
        'I\'m kinda busy right now.',
        'I don\'t have time to speak, sorry.',
        'Hi!',
        'Good day to you.',
        'What a lovely day.',
    ],
    KLOOS: [
        'Woof, woof!',
        'Grrrrr!',
    ] 
}