import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import game from '../../../controller/GameController';
import { au, v } from '../../../koko-framework/shorthand';
import CloseButton from '../../../assets/babcock_menus/close_btn.png';

import ProgressBar from 'react-customizable-progressbar';

import BADGES_ORDER from '../../model/badges-config';

import ProfilePic from '../../../assets/babcock_menus/cv/top/profilepic.png';
import ProfilePic1 from '../../../assets/babcock_menus/cv/top/mc_cv_1.jpg';
import ProfilePic2 from '../../../assets/babcock_menus/cv/top/mc_cv_2.jpg';
import ProfilePic3 from '../../../assets/babcock_menus/cv/top/mc_cv_3.jpg';
import ProfilePic4 from '../../../assets/babcock_menus/cv/top/mc_cv_4.jpg';
import ProfilePic5 from '../../../assets/babcock_menus/cv/top/mc_cv_5.jpg';

import CvCarrier from '../../../assets/babcock_menus/cv/top/CV_carrier.png';
import ExperienceIcon from '../../../assets/babcock_menus/cv/experience/experience_icon.png';
import AttributesIcon from '../../../assets/babcock_menus/cv/attributes/attribute_icon.png';
import BadgesIcon from '../../../assets/babcock_menus/cv/badges_icon.png';
import BadgesInfo from '../../../assets/babcock_menus/cv/badges_info.png';
import HobbiesIcon from '../../../assets/babcock_menus/cv/selfies/selfie_icon.png';
import SegmentCompleteStar from '../../../assets/babcock_menus/cv/attribute_segment_complete.png';
import SegmentIncompleteStar from '../../../assets/babcock_menus/cv/attribute_segment_incomplete.png';
import CoffeeUnlocked from '../../../assets/babcock_menus/cv/experience/coffee_Unlocked.png';
import CoffeeLocked from '../../../assets/babcock_menus/cv/experience/coffee_Locked.png';
import FoodUnlocked from '../../../assets/babcock_menus/cv/experience/food_Unlocked.png';
import FoodLocked from '../../../assets/babcock_menus/cv/experience/food_locked.png';
import MoneyUnlocked from '../../../assets/babcock_menus/cv/experience/money_Unlocked.png';
import MoneyLocked from '../../../assets/babcock_menus/cv/experience/money_Locked.png';
import ExpUnlockedStar from '../../../assets/babcock_menus/cv/experience/unlocked_icon.png';
import ExpLockedStar from '../../../assets/babcock_menus/cv/experience/Locked_icon.png';
import AttComplete from '../../../assets/babcock_menus/cv/attributes/attribute_complete.png';
import AttIncomplete from '../../../assets/babcock_menus/cv/attributes/attribute_incomplete.png';
import SelfieUnlocked from '../../../assets/babcock_menus/cv/selfies/selfie_unlocked.png';
import SelfieLocked from '../../../assets/babcock_menus/cv/selfies/selfie_Locked.png';

import LeftArrow from '../../../assets/babcock_menus/select_character/arrow_left.png';
import RightArrow from '../../../assets/babcock_menus/select_character/arrow_right.png';

import WorldController from "../../../controller/WorldController";
import { GameState, GetBadgesCompleteCount, GetCvPercentage, GetCvStarsEarned, NpcTaskCompleted, SetNewCvItem } from "../../model/GameState";
import * as BABCOCK_CONSTANTS from '../../model/constants';
import BabcockController from "../../controller/BabcockController";
import { CV_READY_DIALOG, CV_READY_DIALOG_AT_OFFICE } from "../../model/dialog";
import { BadgeProgress } from "./components/badge-progress";

import { PANEL_STATE } from "../../../Bootstrap";

import ReactGA from 'react-ga4';

export default class CvView extends ReactFullScreenView {
    constructor(props) {
        super(props);

        this.state.hilightedSection = '';
        this.state.badgeIndex = 0;
    }

    closeClicked = () => {
        v.hideView('cvview');
        WorldController.enable();
        SetNewCvItem(false);

        au.play('closebtn', 1, false, 0, true);

        BabcockController.unpauseCharacter();

        NpcTaskCompleted(BABCOCK_CONSTANTS.LOCATIONS.SKILLS_LAUNCHPAD);
    }

    badgeRight = () => {
        let badgeIndex = this.state.badgeIndex;
        badgeIndex++;
        if (badgeIndex <= 3) {
            this.setState({badgeIndex: badgeIndex});
            au.play('secondarybtn', 1, false, 0, true);
        }
    }

    badgeLeft = () => {
        let badgeIndex = this.state.badgeIndex;
        badgeIndex--;
        if (badgeIndex >= 0) {
            this.setState({badgeIndex: badgeIndex});
            au.play('secondarybtn', 1, false, 0, true);
        }
    }

    componentDidMount() {
        super.componentDidMount();
        if (GameState.cvTutorialShown === false) {
            GameState.cvTutorialShown = true;
            setTimeout(BabcockController.startCvTutorial, 500);
        } else
        if (GameState.currentGameTask === BABCOCK_CONSTANTS.MAIN_TASK && GameState.gameResults.indexOf(0) === -1) {
            if (BabcockController.lastInteraction === BABCOCK_CONSTANTS.SKILLS_LAUNCHPAD) {
                // console.log('Show cv ready dialogue');
                setTimeout(() => BabcockController.showInteractionDialog('CV Ready!', CV_READY_DIALOG_AT_OFFICE, 'sendcvprompt'), 500); 
            } else {
                PANEL_STATE.contents = 'Sarah @ Skills Launchpad';
                v.showView('phoneringing');
            }
            // BabcockController.showInteractionDialog('CV Ready!', BabcockController.lastInteraction === BABCOCK_CONSTANTS.SKILLS_LAUNCHPAD ? CV_READY_DIALOG_AT_OFFICE : CV_READY_DIALOG, BabcockController.lastInteraction === BABCOCK_CONSTANTS.SKILLS_LAUNCHPAD ? 'sendcvprompt' : '');
        }

        BabcockController.pauseCharacter();

        ReactGA.send({ hitType: "pageview", page: "/cv/" });
    }

    highlightSection(sectionId = '') {
        this.setState({hilightedSection: sectionId});
    }

    showBadgeInfoPop = (item) => {
        PANEL_STATE.contentData = item;
        v.showView('badgeinfopop');
    }

    renderContent() {
        let starsEarned = GetCvStarsEarned();
        let gamesPlayed = 0;
        for (let i = 0; i < GameState.gameResults.length; i++) {
            if (GameState.gameResults[i] > 0) {
                gamesPlayed++;
            }
        }
        const PROFILE_PICS = [ProfilePic1, ProfilePic2, ProfilePic3, ProfilePic4, ProfilePic5];

        let topHighlight = this.state.hilightedSection === 'overall' ? 'cv-section-highlight' : this.state.hilightedSection !== '' ? 'cv-section-darken' : '';
        let expHighlight = this.state.hilightedSection === 'experience' ? 'cv-section-highlight' : this.state.hilightedSection !== '' ? 'cv-section-darken' : '';
        let attHighlight = this.state.hilightedSection === 'attributes' ? 'cv-section-highlight' : this.state.hilightedSection !== '' ? 'cv-section-darken' : '';
        let hobHighlight = this.state.hilightedSection === 'hobbies' ? 'cv-section-highlight' : this.state.hilightedSection !== '' ? 'cv-section-darken' : '';

        let badgesComplete = GetBadgesCompleteCount();

        let cvBarFillWidth = 220 * GetCvPercentage();
        let cvBarFillHeight = cvBarFillWidth < 10 ? 12 + cvBarFillWidth : 26;
        let cvBarRadius = cvBarFillHeight < 20 ? cvBarFillHeight - 12 : 10;
        let cvBarTop = 88 + (26 - cvBarFillHeight) / 2;
        let perc = Math.round(GetCvPercentage() * 100);

        let fadeLeftButton = this.state.badgeIndex > 0 ? false : true;
        let fadeRightButton = this.state.badgeIndex < 3 ? false : true;

        return (
            <div className="full-screen-holder gradient-bg no-select default-cursor">
                <div className={"view-row grow " + topHighlight}>
                <div className="cv-section-content-holder">
                    <div className="cv-section">
                        <div className="flex-columns">
                            <div className="shrink">
                                <img className="cv-profile-pic" src={PROFILE_PICS[GameState.selectedCharacter]} alt="Profile picture" />
                            </div>
                            <div className="grow"></div>
                            <div className="shrink">
                                <div className="flex-rows">
                                    <div className="shrink">
                                        <p className="cv-name">{GameState.name + ' ' + GameState.lastName}</p>
                                    </div>
                                    <div className="grow rel">
                                        <img className="cv-carrier" src={CvCarrier} alt={"CV Progress: " + perc + '% complete'} />
                                            {starsEarned >= 1 &&
                                                <img className="cv-top-star abs" style={{ left: 116, top: 35 }} src={SegmentCompleteStar} alt={starsEarned + ' star'} />
                                            }
                                            {starsEarned >= 2 &&
                                                <img className="cv-top-star abs" style={{ left: 161, top: 35 }} src={SegmentCompleteStar} alt={starsEarned + ' stars'} />
                                            }
                                            {starsEarned >= 3 &&
                                                <img className="cv-top-star abs" style={{ left: 206, top: 35 }} src={SegmentCompleteStar} alt={starsEarned + ' stars'} />
                                            }
                                            {starsEarned >= 4 &&
                                                <img className="cv-top-star abs" style={{ left: 251, top: 35 }} src={SegmentCompleteStar} alt={starsEarned + ' stars'} />
                                            }
                                            {starsEarned >= 5 &&
                                                <img className="cv-top-star abs" style={{ left: 296, top: 35 }} src={SegmentCompleteStar} alt={starsEarned + ' stars'} />
                                            }
                                            <div className="cv-progress-bar cv-progress-back"></div>
                                            <div className="cv-progress-bar cv-progress-fill" style={{width: cvBarFillWidth, height: cvBarFillHeight, borderRadius: cvBarRadius, top: cvBarTop}}></div>
                                            <div className="cv-progress-bar cv-progress-border"></div>

                                            <p className="cv-progress-perc" aria-label={perc + '% complete'}>{perc}%</p>
                                    </div>
                                </div>
                            </div>
                            <div className="grow"></div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className={"view-row grow cv-exp-bg " + expHighlight}>
                <div className="cv-section-content-holder">
                    <div className="cv-section flex-rows">
                        <div className="cv-section-heading-container">
                            <div className="shrink">
                                <img className="cv-exp-section-icon" src={ExperienceIcon} alt="" />
                            </div>
                            <div className="grow">
                                <p className="cv-section-heading" aria-label="Experience Section">Experience</p>
                            </div>
                            <div className="shrink">
                                <p className="cv-complete-stat" aria-label={gamesPlayed + ' out of ' + GameState.gameResults.length + ' Complete'}>{gamesPlayed} / {GameState.gameResults.length} Complete</p>
                            </div>
                            {/*
                            <div className="shrink">
                                <img className="cv-section-star" src={GameState.itemsCollected.length === BABCOCK_CONSTANTS.ITEMS_FLAT_ARRAY.length ? SegmentCompleteStar : SegmentIncompleteStar} />
                            </div>
                            */}
                        </div>
                        <div className="flex-columns">
                            <div className="grow">
                                <div className="cv-exp-icon-holder">
                                    <img className="cv-exp-main-icon" src={GameState.gameResults[BABCOCK_CONSTANTS.COFFEE_SHOP_GAME.id] > 0 ? CoffeeUnlocked : CoffeeLocked} alt={"Coffee Shop Reference " + (GameState.gameResults[BABCOCK_CONSTANTS.COFFEE_SHOP_GAME.id] > 0 ? 'added to CV' : 'not yet added to CV')} />
                                    {GameState.gameResults[BABCOCK_CONSTANTS.COFFEE_SHOP_GAME.id] > 0 &&
                                        <img className="cv-exp-unlocked-star" src={ExpUnlockedStar} alt="You have earned this star" />
                                    }
                                    {GameState.gameResults[BABCOCK_CONSTANTS.COFFEE_SHOP_GAME.id] === 0 &&
                                        <>
                                        <div className="cv-exp-locked-star-bg"></div>
                                        <img className="cv-exp-unlocked-star" src={ExpLockedStar} alt="You have not earned this star" />
                                        </>
                                    }
                                </div>
                                <p className={"cv-exp-subtitle" + (GameState.gameResults[BABCOCK_CONSTANTS.COFFEE_SHOP_GAME.id] > 0 ? '' : ' knock-back')}>Reference</p>
                            </div>
                            <div className="grow">
                                <div className="cv-exp-icon-holder">
                                    <img className="cv-exp-main-icon" src={GameState.gameResults[BABCOCK_CONSTANTS.DELIVERY_GAME.id] > 0 ? FoodUnlocked : FoodLocked} alt={"Food Bank Work Experience " + (GameState.gameResults[BABCOCK_CONSTANTS.DELIVERY_GAME.id] > 0 ? 'added to CV' : 'not yet added to CV')} />
                                    {GameState.gameResults[BABCOCK_CONSTANTS.DELIVERY_GAME.id] > 0 &&
                                        <img className="cv-exp-unlocked-star" src={ExpUnlockedStar} alt="You have earned this star" />
                                    }
                                    {GameState.gameResults[BABCOCK_CONSTANTS.DELIVERY_GAME.id] === 0 &&
                                        <>
                                        <div className="cv-exp-locked-star-bg"></div>
                                        <img className="cv-exp-unlocked-star" src={ExpLockedStar} alt="You have not earned this star" />
                                        </>
                                    }
                                </div>
                                <p className={"cv-exp-subtitle" + (GameState.gameResults[BABCOCK_CONSTANTS.DELIVERY_GAME.id] > 0 ? '' : ' knock-back')}>Volunteer Work</p>
                            </div>
                            <div className="grow">
                                <div className="cv-exp-icon-holder">
                                    <img className="cv-exp-main-icon" src={GameState.gameResults[BABCOCK_CONSTANTS.PLUMBING_GAME.id] > 0 ? MoneyUnlocked : MoneyLocked} alt={"Plumbing Work Reference " + (GameState.gameResults[BABCOCK_CONSTANTS.PLUMBING_GAME.id] > 0 ? 'added to CV' : 'not yet added to CV')} />
                                    {GameState.gameResults[BABCOCK_CONSTANTS.PLUMBING_GAME.id] > 0 &&
                                        <img className="cv-exp-unlocked-star" src={ExpUnlockedStar} alt="You have earned this star" />
                                    }
                                    {GameState.gameResults[BABCOCK_CONSTANTS.PLUMBING_GAME.id] === 0 &&
                                        <>
                                        <div className="cv-exp-locked-star-bg"></div>
                                        <img className="cv-exp-unlocked-star" src={ExpLockedStar} alt="You have not earned this star" />
                                        </>
                                    }
                                </div>
                                <p className={"cv-exp-subtitle" + (GameState.gameResults[BABCOCK_CONSTANTS.PLUMBING_GAME.id] > 0 ? '' : ' knock-back')}>Job Experience</p>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className={"view-row grow " + attHighlight}>
                <div className="cv-section-content-holder">
                    <div className="cv-section flex-rows">
                        <div className="cv-section-heading-container">
                            <div className="shrink">
                                <img className="cv-att-section-icon" src={BadgesIcon} alt="" />
                            </div>
                            <div className="grow">
                                <p className="cv-section-heading" aria-label="Skills Builder Badges Section">Skills Builder Badges</p>
                            </div>
                            <div className="shrink">
                                <p className="cv-complete-stat" aria-label={badgesComplete + ' out of ' + GameState.badgeProgress.length + 'Complete'}>{badgesComplete} / {GameState.badgeProgress.length} Complete</p>
                            </div>
                            <div className="shrink">
                                <img className="cv-section-star" src={badgesComplete === GameState.badgeProgress.length ? SegmentCompleteStar : SegmentIncompleteStar} aria-label={badgesComplete === GameState.badgeProgress.length ? 'You have completed this section' : 'You have not completed this section'} />
                            </div>
                        </div>
                        
                        <div className="flex-rows">

                            <div className="shrink">
                                <img className="cv-badges-info" src={BadgesInfo} alt="Click on a badge to find out more information about it" />
                            </div>

                            <div className="shrink rel">
                                <div className="cv-badges-scroller rel">
                                    <div className="flex-columns abs" style={{left: (-this.state.badgeIndex * 380) + 30, transition: "left 0.2s"}}>
                                    {
                                        GameState.badgeProgress.map(function(item, i) {
                                            // console.log('Badge item: ', item);
                                            return (
                                                <div key={'badge_' + i} className="cv-one-badge-holder shrink active-button" onClick={() => { PANEL_STATE.contentData = item; v.showView('badgeinfopop'); au.play('primarybtn', 1, false, 0, true); }}>
                                                    <BadgeProgress badgeData={item}></BadgeProgress>
                                                </div>
                                                )
                                            }
                                        )
                                    }
                                    </div>
                                </div>

                                <div className="cv-left-arrow">
                                    <img className={"cv-scroll-arrow active-button" + (fadeLeftButton ? " half-fade" : '')} src={LeftArrow} onClick={this.badgeLeft} alt="Click to scroll badges left" />
                                </div>
                                <div className="cv-right-arrow">
                                    <img className={"cv-scroll-arrow active-button" + (fadeRightButton ? " half-fade" : '')} src={RightArrow} onClick={this.badgeRight} alt="Click to scroll badges right" />
                                </div>

                            </div>

                        </div>
                        </div>
                    </div>
                </div>
                <div className={"view-row grow cv-hob-bg " + hobHighlight}>
                <div className="cv-section-content-holder">
                    <div className="cv-section flex-rows">
                        <div className="cv-section-heading-container">
                            <div className="shrink">
                                <img className="cv-hob-section-icon" src={HobbiesIcon} alt="" />
                            </div>
                            <div className="grow">
                                <p className="cv-section-heading" aria-label="Hobbies and Interests Section">Hobbies + Interests</p>
                            </div>
                            <div className="shrink">
                                <p className="cv-complete-stat" aria-label={GameState.selfiesTaken.length + ' photos taken out of ' + BABCOCK_CONSTANTS.SELFIE_LOCATIONS.length}>{GameState.selfiesTaken.length} / {BABCOCK_CONSTANTS.SELFIE_LOCATIONS.length} Complete</p>
                            </div>
                            <div className="shrink">
                                <img className="cv-section-star" src={GameState.selfiesTaken.length == BABCOCK_CONSTANTS.SELFIE_LOCATIONS.length ? SegmentCompleteStar : SegmentIncompleteStar} aria-label={GameState.selfiesTaken.length == BABCOCK_CONSTANTS.SELFIE_LOCATIONS.length ? 'You have completed this section' : 'You have not completed this section'} />
                            </div>
                        </div>
                        <div className="cv-selfies-holder">
                            <div className="flex-columns shrink">

                                {
                                    GameState.selfiesTaken.map(function(item, i) {
                                        let locationData = BABCOCK_CONSTANTS.getLocationDataFromId(item);
                                        return (
                                            <div key={'cvpic_' + i} className="cv-selfie-icon-holder">
                                                <img className="cv-selfie-status-icon" src={locationData.selfieCvPic} alt={'Photo of ' + locationData.name} />
                                            </div>
                                        )
                                    })
                                }

                                    {GameState.selfiesTaken.length === 0 &&
                                        <div className="cv-selfie-icon-holder">
                                            <img className="cv-selfie-status-icon" src={SelfieLocked} alt="Photo 1 not taken" />
                                        </div>
                                    }
                                    {GameState.selfiesTaken.length <= 1 &&
                                        <div className="cv-selfie-icon-holder">
                                            <img className="cv-selfie-status-icon" src={SelfieLocked} alt="Photo 2 not taken" />
                                        </div>
                                    }
                                    {GameState.selfiesTaken.length <= 2 &&
                                        <div className="cv-selfie-icon-holder">
                                            <img className="cv-selfie-status-icon" src={SelfieLocked} alt="Photo 3 not taken" />
                                        </div>
                                    }
                                    {GameState.selfiesTaken.length <= 3 &&
                                        <div className="cv-selfie-icon-holder">
                                            <img className="cv-selfie-status-icon" src={SelfieLocked} alt="Photo 4 not taken" />
                                        </div>
                                    }
                                    {GameState.selfiesTaken.length <= 4 &&
                                        <div className="cv-selfie-icon-holder">
                                            <img className="cv-selfie-status-icon" src={SelfieLocked} alt="Photo 5 not taken" />
                                        </div>
                                    }
                            </div>
                            </div>
                        </div>
                        <div className="grow"></div>
                    </div>
                </div>
                {/*<div className={"view-row grow cv-hob-bg " + hobHighlight}></div>*/}
                {GameState.cvTutorialActive === false && GameState.cvTutorialShown === true &&
                <div className="abs" style={{right: 30, top: 20}}>
                    <img className="close-button active-button" src={CloseButton} onClick={this.closeClicked} alt="Click to close your CV" />
                </div>
                }
            </div>
        );
    }
}