import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import game from '../../../controller/GameController';
import { au, v } from '../../../koko-framework/shorthand';
import PhoneIcon from '../../../assets/babcock_menus/in_game/dialog/Phone_dialogue.png';
import DialogArrow from '../../../assets/babcock_menus/in_game/dialog/dialogue_scroll.png';
import PositionableReactView from "../../../koko-framework/ui/react-views/PositionableReactView";
import { PANEL_STATE } from "../../../Bootstrap";
import BabcockController from "../../controller/BabcockController";

export default class PhoneRinging extends ReactFullScreenView {
    canClickTimeoutId = -1;

    constructor(props) {
        super(props);

        // Fix this panel to the bottom middle. Our css class bottom-panels-bg positions the panel relative to that.
        this.state.left = '50%';
        this.state.top = '100%';
        this.state.caller = PANEL_STATE.contents;
        this.state.canClick = false;

        this.resize();
    }

    componentDidMount() {
        super.componentDidMount();

        v.showView('bottomgrad2');

        au.play('phone', 1, false, 0, false);

        this.delayClick();

        BabcockController.pauseCharacter();

        v.get('hudview').fade();
    }

    closePanel = () => {
        if (this.state.canClick && this.state.visible) {
            au.play('primarybtn', 1, false, 0, false);
        
            v.hideView(this.props.viewId);
            BabcockController.phoneAnswered();

            v.hideView('bottomgrad2');

            // BabcockController.unpauseCharacter();

            v.get('hudview').fade(1);
        }
    }

    delayClick = () => {
        // if (this.state.canClick === false) {
            clearTimeout(this.canClickTimeoutId);
            this.canClickTimeoutId = setTimeout(() => this.setState({canClick: true}), 400);
        // }
    }

    renderContent() {
        return (
            <div className="full-screen-holder no-select default-cursor rel" onClick={this.closePanel}>
                <div className="bottom-panels-bg no-select default-cursor" style={{ left: this.state.left, top: this.state.top }}>
                    <div className="flex-columns">
                        <div className="shrink">
                            <img className="phone-ringing-icon" src={PhoneIcon} alt="Phone Ringing" />
                        </div>
                        <div className="grow centered">
                            <p className="blue-header bold-text" aria-label="Phone Ringing">Phone Ringing...</p>
                            <p className="grey-info-text">{this.state.caller}</p>
                        </div>
                    </div>
                    <div className="dialog-arrow-holder">
                        <img className="dialog-arrow" src={DialogArrow} alt="" />
                    </div>
                </div>
            </div>
        );
    }
}