import * as PIXI from 'pixi.js'
import gsap from 'gsap'
import koko from './koko'

const display = {
    convertProps: function (props) {
        for (var key in props) {
            switch (key) {
                case 'scaleX':
                    if (props.scale === undefined) props.scale = {};
                    props.scale.x = props.scaleX;
                    delete props.scaleX;
                    break;
                case 'scaleY':
                    if (props.scale === undefined) props.scale = {};
                    props.scale.y = props.scaleY;
                    delete props.scaleY;
                    break;
                case 'scale':
                    if (Object.prototype.toString.call(props.scale) !== '[object Object]') {
                        var scale = parseFloat(props.scale);
                        //delete props.scale;
                        props.scale = { x: scale, y: scale };
                    }
                    break;
                case 'regX':
                    if (props.pivot === undefined) props.pivot = {};
                    props.pivot.x = props.regX;
                    delete props.regX;
                    break;
                case 'regY':
                    if (props.pivot === undefined) props.pivot = {};
                    props.pivot.y = props.regY;
                    delete props.regY;
                    break;
                case 'anchorX':
                    if (props.anchor === undefined) props.anchor = {};
                    props.anchor.x = props.anchorX;
                    break;
                case 'anchorY':
                    if (props.anchor === undefined) props.anchor = {};
                    props.anchor.y = props.anchorY;
                    break;
                case 'x':
                case 'y':
                    if (props.position === undefined) props.position = {};
                    props.position[key] = props[key];
                    delete props[key];
                    break;
                default:
                    break;
            }
        }

        if (props.scale) {
            props.scale = new PIXI.Point(props.scale.x, props.scale.y);
        }
        return props;
    },

    convert: function (props) {
        return this.convertProps(props);
    },

    applyProps: function (itm, props) {
        props = this.convertProps(props);

        for (var key in props) {
            switch (key) {
                case 'scale':
                case 'position':
                case 'pivot':
                    //if(!itm.hasOwnProperty(key)) return;
                    if (props[key].x != null) itm[key].x = props[key].x;
                    if (props[key].y != null) itm[key].y = props[key].y;
                    break;
                case 'anchor':
                    if (props[key].x == null && props[key].y == null) {
                        break;
                    } else if (props[key].x == null) {
                        props[key].x = props[key].y;
                    } else if (props[key].y == null) {
                        props[key].y = props[key].x;
                    }

                    itm.anchor.set(props.anchor.x, props.anchor.y);
                    break;
                case 'rotation':
                    itm[key] = koko.math.angleToRadians(props[key]);
                    break;
                case 'parent':
                    break;
                case 'style':
                    for (var styleKey in props[key]) {
                        var style = props[key][styleKey];
                        itm.style[styleKey] = style;
                    }
                    break;
                case 'underline':
                case 'fill':
                    break;
                default:
                    itm[key] = props[key];
                    break;
            }
        }

        //after other props, check for underline
        if (props['underline']) {
            const ULT = new PIXI.Sprite(PIXI.Texture.WHITE);
            ULT.tint = itm.style.fill;
            ULT.position.y = itm.height - itm.height * itm.anchor.y;
            ULT.width = itm.width
            ULT.height = 2
            ULT.anchor = itm.anchor;
            itm.addChild(ULT);
        }
    },

    apply: function (itm, props) {
        this.applyProps(itm, props);
    },

    buildLayout: function (objs, container) {
        var itms = {};
        for (var i = 0; i < objs.length; i++) {

            var obj = objs[i];
            // var type = obj.type || 'container';
            var itm, props, texture, spriteId = null

            switch (obj.type || 'container') {
                /*
                case 'spritebatch':
                    itm = new PIXI.SpriteBatch();
                    props = this.convertProps(obj);
                    break;
                    */
                case 'particlecontainer':
                    itm = new PIXI.ParticleContainer();
                    props = this.convertProps(obj);
                    break;
                case 'bitmap':
                    var assetUrl = koko.assets.getAssetURL(obj.id);
                    texture = PIXI.Texture.from(assetUrl);

                    itm = new PIXI.Sprite(texture);

                    props = this.convertProps(obj);
                    break;
                case 'sprite':
                    spriteId = obj.id;

                    if (PIXI.utils.TextureCache[spriteId] == null) spriteId = obj.id + '.png';
                    if (PIXI.utils.TextureCache[spriteId] == null) spriteId = obj.id + '.jpg';
                    if (PIXI.utils.TextureCache[spriteId] == null) alert('no texture found for name ' + obj.id);

                    texture = PIXI.utils.TextureCache[spriteId];
                    itm = PIXI.Sprite.from(texture);
                    props = this.convertProps(obj);
                    break;
                case 'animatedsprite':
                    var id = obj.id;
                    var animationId = obj.animationId;

                    var animations = [];

                    var sheet;

                    if (id.indexOf('{n}') > -1) {

                        var isDone = false;
                        for (var j = 0; isDone === false; ++j) {

                            var sheetId = id.replace('{n}', j);
                            if (animationId == null) {
                                animationId = id.replace('{n}', '');
                            }

                            var resource = PIXI.Loader.shared.resources[sheetId];
                            if (!resource) {
                                isDone = true;
                                continue;
                            }

                            sheet = resource.spritesheet;

                            if (!sheet || !sheet.animations || !sheet.animations[animationId]) {
                                isDone = true;
                                continue;
                            }
                            // var newAnimations = sheet.animations[animationId];
                            animations = animations.concat(sheet.animations[animationId]);
                        }

                        if (animations.length === 0) {
                            console.log("no animation frames found!");
                        }

                        animations.sort(
                            function (a, b) {
                                if (a.textureCacheIds[0] < b.textureCacheIds[0]) {
                                    return -1;
                                }
                                if (a.textureCacheIds[0] > b.textureCacheIds[0]) {
                                    return 1;
                                }
                                return 0;
                            }
                        );
                        itm = new PIXI.AnimatedSprite(animations);
                        //props = this.convertProps(obj);

                    } else if (PIXI.Loader.shared.resources[id] && PIXI.Loader.shared.resources[id].spritesheet) {
                        //found spritesheet
                        sheet = PIXI.Loader.shared.resources[id].spritesheet;

                        itm = new PIXI.AnimatedSprite(sheet.animations[animationId || id]);
                        //	props = this.convertProps(obj);
                    } else {
                        //warning, using very slow colelction of textures
                        alert('no spritesheet found for name ' + obj.id);

                        // var slidesCount = obj.slidesCount;
                        //
                        // var slides = [];
                        // for(var j = 1; j<=slidesCount; ++j) {
                        // 	var indexLength = j.toString().length;
                        // 	var nameIndexPrefix = "";
                        // 	for(var k = 0; k<4 - indexLength; ++k) {
                        // 		nameIndexPrefix += "0";
                        // 	}
                        //
                        // 	var baseName = id +  nameIndexPrefix + j;
                        // 	var spriteId = baseName;
                        //
                        // 	if(PIXI.utils.TextureCache[spriteId] == null) spriteId = baseName + ".png";
                        // 	if(PIXI.utils.TextureCache[spriteId] == null) spriteId = baseName + ".jpg";
                        // 	if(PIXI.utils.TextureCache[spriteId] == null) {
                        // 		continue;
                        // 	}
                        //
                        // 	slides.push(PIXI.utils.TextureCache[spriteId]);
                        // }
                        // //var texture = PIXI.Texture.from(spriteId);
                        // //itm = newPIXI.Sprite.from(texture);
                        // itm = new PIXI.AnimatedSprite(slides);
                    }
                    props = this.convertProps(obj);
                    break;
                case 'vid':
                    spriteId = obj.id;

                    //check for video asset
                    var asset = koko.assets.getAssetURL(obj.id);

                    if (asset) {
                        texture = PIXI.Texture.from(asset); //asset id given
                    } else {
                        texture = PIXI.Texture.from(spriteId); //video url
                    }

                    itm = new PIXI.Sprite.from(texture);
                    props = this.convertProps(obj);
                    break;
                case 'shape':
                    itm = new PIXI.Graphics();
                    itm.beginFill(obj.fill || '#000000');
                    itm.drawRect(0, 0, obj.width || obj.w || 100, obj.height || obj.h || 100);
                    props = this.convertProps(obj);
                    break;
                case 'mask':
                    console.error('MASKS cannot be defined in buildLayout now, use koko.display.mask(cont, x, y, width, height) || koko.display.maskPoly(cont, points)');
                    continue;
                case 'text':
                    props = this.convertProps(obj);
                    if (!props.fill) props.fill = props.tint
                    itm = new PIXI.Text(obj.text || "", props);
                    break;
                case 'bmptext':
                    props = this.convertProps(obj);
                    // if (Object.prototype.toString.call(props.font) !== '[object Object]') {
                        var style = { fontName: props.font, fontSize: props.size || 24, tint: props.tint || 0xffffff, align: props.align || 'left' };
                    // }
                    itm = new PIXI.BitmapText("", style);
                    itm.fitWidth = obj.fitWidth;
                    itm.text = obj.text;
                    // console.log('bmptext: ', style, itm);
                    break;
                /*
                case 'textinput':
                    props = this.convertProps(obj);
                    itm = new PIXI.TextInput({ input: props.input, box: props.box });
                    //do not apply these style props
                    delete props.input;
                    delete props.box;
                    break;
                    */
                case 'container':
                default:
                    itm = new PIXI.Container();
                    props = this.convertProps(obj);
                    break;
            }

            //parent will be main container passed through unless item has parent's name (or parent object) passed through must have already been created
            var parent = container;
            if (obj.hasOwnProperty('parent')) {
                if (typeof obj.parent == 'string' || obj.parent instanceof String) {
                    parent = itms[obj.parent];
                } else {
                    parent = obj.parent;
                }
            }

            //add eet
            parent.addChild(itm);

            itm.__name = obj.name;

            //extend properties to itm
            this.applyProps(itm, props);

            //push into itms
            itms[obj.name] = itm;
        }
        //return the itms
        return itms;
    },

    mask: function (c, x, y, w, h) {
        var shape = new PIXI.Graphics();
        shape.beginFill(0xffffff);
        shape.drawRect(x, y, w, h);
        shape.endFill();

        c.parent.addChildAt(shape, 0);
        c.mask = shape;
        shape.x = c.x;
        shape.y = c.y;
    },

    maskPoly: function (c, p) {
        var shape = new PIXI.Graphics();
        shape.beginFill(0xffffff);
        shape.drawPolygon(p);
        shape.endFill();

        c.parent.addChildAt(shape, 0);
        c.mask = shape;
        shape.x = c.x;
        shape.y = c.y;
    },

    build: function (objs, container) {
        return this.buildLayout(objs, container);
    },

    getProps: function (itm, props) {
        var retProps = {};
        for (var key in props) {
            if (Object.prototype.toString.call(props[key]) === '[object Object]') {
                retProps[key] = {};
                for (var keyy in props[key]) {
                    retProps[key][keyy] = itm[key][keyy];
                }
            } else {
                retProps[key] = itm[key];
            }
        }
        return retProps;
    },

    get: function (itm, props) {
        return this.getProps(itm, props);
    },

    allAnimationSpecificProperties: ['delay', 'ease', 'onComplete', 'onCompleteParams', 'onCompleteScope', 'useFrames',
        'immediateRender', 'onStart', 'onStartParams', 'onStartScope', 'onUpdate', 'onUpdateParams', 'onUpdateScope', 'onReverseComplete',
        'onReverseCompleteParams', 'onReverseCompleteScope', 'paused', 'overwrite', 'yoyo', 'repeat', 'repeatDelay', 'reversed', 'paused'
    ],

    simpleAnimationProperties: ['delay', 'ease', 'yoyo', 'repeat', 'onComplete', 'onUpdate', 'onStart'],
    animateObject: function (obj, duration, props, useAllAnimationProperties) {
        //props = this.convertProps(props);

        // var tweenProps = {};
        //
        // var animProps = useAllAnimationProperties ? this.allAnimationSpecificProperties : this.simpleAnimationProperties;
        // for( var i = 0; i < animProps.length; i++ ){
        // 	var propName = animProps[i];
        // 	if(props.hasOwnProperty(propName)){
        // 		tweenProps[propName] = props[propName];
        // 		delete props[propName];
        // 	}
        // }
        //
        // //set the pixiPlugin
        // tweenProps.pixiPlugin = props;
        //
        // //boosh
        // if(tweenProps.pixiPlugin.rotation) tweenProps.pixiPlugin.rotation = koko.math.angleToRadians(tweenProps.pixiPlugin.rotation);
        if (props.rotation) {
            props.rotation = koko.math.angleToRadians(props.rotation);
        }

        props.duration = duration;

        //tween it
        return gsap.to(obj, props);
    },

    animate: function (obj, duration, props, useAllAnimationProperties) {
        return this.animateObject(obj, duration, props, useAllAnimationProperties);
    },

    killAnimations: function (object) {
        gsap.killTweensOf(object);
    },

    applyThenAnimate: function (obj, initialProps, duration, animateProps) {
        this.applyProps(obj, initialProps);
        return this.animateObject(obj, duration, animateProps);
    },

    destroyContainerChildren: function (cont) {
        for (var i = 0, l = cont.children.length; i < l; i++) {
            this._destroyContainerChildrenLoop(cont.children[0]);
        }
    },

    _destroyContainerChildrenLoop: function (cont) {
        if (cont.children && cont.children.length > 0) {
            for (var i = 0, l = cont.children.length; i < l; i++) {
                this._destroyContainerChildrenLoop(cont.children[0]);
            }
        }

        //remove it
        if (cont.parent) cont.parent.removeChild(cont);

        //if its a movie clip then destroy it
        if (cont.textures && cont.textures.length > 0) cont.destroy();
    },

    /**
     * Create a PIXI.AnimatedSprite from a texture prefix and frame numbers.
     * If a frame doesn't exist in the texture cache then the first frame will be used,
     * this reduces errors and allows us to have delayed anims by just setting the number
     * of frames to a higher value, repeating the first frame for a while.
     * @param {string} animID - Anim ID Prefix (for 'myAnim01.png' - 'myAnim99.png', this would be 'myAnim')
     * @param {number} frameCount - The number of frames in your animation
     * @param {number} leadingZeros - Number of leading zeros, for 2 digit frame ids (01 - 99) this is 1, for 4 digit (0001 - 9999) this is 3
     * @param {number} startFrame - The first frame number, usually 1, but sometimes 0
     * @param {number} step - if you want to skip frames, defaults to 1, but if you have frame 0, 2, 4 etc. you need this to be 2
     * @returns {PIXI.AnimatedSprite}
     */
    createAnim: function(animID, frameCount, leadingZeros = 1, startFrame = 1, step = 1) {
        let zeroComp = [0];
        let zComp = 1;
        let firstTid = animID;
        for (let i = 1; i <= leadingZeros; i++) {
            zComp *= 10;
            zeroComp.push(zComp);
            firstTid += '0';
        }
        firstTid += startFrame + '.png';
        let animFrames = [];
        for (let i = 0; i < frameCount; i += step) {
            let leadingZerosId = '';
            for (let j = leadingZeros; j > 0; j--) {
                if (i + startFrame < zeroComp[j]) {
                    leadingZerosId += '0';
                }
            }
            leadingZerosId += (i + startFrame);
            var tid = animID + leadingZerosId + '.png';
            // console.log('anim frame id: ', tid, firstTid);
            var t = typeof PIXI.utils.TextureCache[tid] != 'undefined' ? PIXI.Texture.from(tid) : PIXI.Texture.from(firstTid);
            animFrames.push(t);
        }
        var a = new PIXI.AnimatedSprite(animFrames);
        // a.animationSpeed = 0.5;
        return a;
    }
}

export { display as default };
