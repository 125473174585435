import { PathFindingEntity } from "./PathFindingEntity";
import gsap from 'gsap';

export class WayPointFollwerNPCEntity extends PathFindingEntity {
    npc = true;
    wayPointFollower = true;
    wayPoints = null;
    nextWayPoint = -1;

    leaveTheScene = false;      // leave the scene is essentially a 1-shot path, it will fade out at the end and trigger the leftSceneCallback
    leftSceneCallback = null;

    pauseAtStart = false;                       // don't do anything right away - good for leave the scene types
    restartMovementAfterInteraction = true;     // movements auto-restart after interaction
    randomPauseAtWayPoints = false;
    randomPauseMin = 2;
    randomPauseMax = 5;
    randomPauseChance = 0.4;

    resetViewOnPause = false;

    randomiseWayPointOrder = false;

    // randomlyVaryTweenDest = true;
    regularlyRecalculatePath = true;
    useFastPathfinding = true;
    lookAheadForBlockagesDistance = 2;

    applySettingsConfig(configArray) {
        super.applySettingsConfig(configArray);

        let applyableSettings = ['restartMovementAfterInteraction', 'leaveTheScene', 'pauseAtStart', 'randomPauseAtWayPoints', 'randomPauseMin', 'randomPauseMax', 'randomPauseChance', 'randomiseWayPointOrder'];
        this._applySettings(configArray, applyableSettings);
    }

    /* -- Path Following functions -- */
    findWaypoints() {
        // this.npcId = this.characterSprite.localConfig.npc_id;
        if (this.npcId) {
            let mapWayPoints = this.scrollingTilemapController.tileMapEngine.getSpecialSpritesWithProperty('waypoint');
            let ourWayPoints = [];
            let wayPointIds = [];
            for (let i = 0; i < mapWayPoints.length; i++) {
                if (mapWayPoints[i].localConfig.waypoint === true) {
                    if (mapWayPoints[i].localConfig.waypoint_npcs) {
                        let wayPointNpcs = JSON.parse(mapWayPoints[i].localConfig.waypoint_npcs);
                        for (let j = 0; j < wayPointNpcs.length; j++) {
                            if (wayPointNpcs[j].split('_')[0] === this.npcId/*startsWith(this.npcId)*/ && wayPointIds.indexOf(wayPointNpcs[j]) === -1) {
                                ourWayPoints.push(mapWayPoints[i]);
                                wayPointIds.push(wayPointNpcs[j]);
                            }
                        }
                    }
                }
            }
            let wayPointsExtractedData = [];
            wayPointsExtractedData.push({x: this.characterStartGridX, y: this.characterStartGridY});
            for (let i = 0; i < ourWayPoints.length; i++) {
                let idSplit = wayPointIds[i].split('_');
                wayPointsExtractedData[i] = {
                    x: ourWayPoints[i].gridX,
                    y: ourWayPoints[i].gridY,
                    order: Number(idSplit[idSplit.length - 1]),
                }
            }
            wayPointsExtractedData.sort(
                (a, b) => {
                    return a.order - b.order;
                }
            );
            if (this.randomiseWayPointOrder) {
                wayPointsExtractedData.sort(() => Math.random() - 0.5);
            }
            this.wayPoints = wayPointsExtractedData;
            // console.log('WayPoints: ', this.wayPoints);
        }
    }

    startFollowingPath = () => {
        // console.log('Start waypoints');
        this.nextWayPoint = -1;
        this.reachedDestinationCallback = this.moveToNextWayPoint;
        if (!this.pauseAtStart) {
            this.startingMovement = true;
            this.moveToNextWayPoint();
        } else {
            this.pauseMovement(Infinity);
        }
    }

    restartMovement() {
        if (this.pauseAtStart) {
            this.pauseAtStart = false;
            setTimeout(this.startFollowingPath, 50);
        } else {
            super.restartMovement();
        }
    }

    moveToNextWayPoint = () => {
        // console.log('Next waypoints: ', this);
        if (!this.startingMovement && this.randomPauseAtWayPoints && Math.random() <= this.randomPauseChance) {
            this.pauseMovement(Math.random() * (this.randomPauseMax - this.randomPauseMin) + this.randomPauseMin);
        }
        if (!this.movementPaused) {
            this.nextWayPoint++;
            if (this.nextWayPoint >= this.wayPoints.length) {
                this.nextWayPoint = 0;
                if (this.leaveTheScene) {
                    gsap.to(this.characterSprite, {alpha: 0, duration: 0.3, onComplete: this.leftTheScene});
                    this.nextWayPoint = -1;
                    this.wayPoints = null;
                    return;
                }
            }
            // if (this.nextWayPoint < this.wayPoints.length) {
                this.setDestinationGridPos(this.wayPoints[this.nextWayPoint].x, this.wayPoints[this.nextWayPoint].y);
            // } else {
            //     this.nextWayPoint = 0;
            //     this.setDestinationGridPos(this.characterStartGridX, this.characterStartGridY);
            // }
            this.calculatePathToDestination();
            if (!this.currentPath) {
                // console.log('Cant move to next waypoint!', this);
                this.pathBlocked = true;
                if (this.startingMovement) {
                    this.pauseMovement(Math.random() * 0.2 + 0.1);
                } else {
                    this.pauseMovement(this.pauseTimeWhenBlocked);
                }
            } else {
                // console.log('moving!')
                this.moveToNextTargetNode();
            }
        }
    }

    endInteraction() {
        if (this.restartMovementAfterInteraction && (!this.leaveTheScene || this.interactionComplete) ) {
            this.pauseMovement(0.5);
        }
    }

    leftTheScene = () => {
        if (this.leftSceneCallback) {
            this.leftSceneCallback(this);
        }
    }
}