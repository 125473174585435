/**
    pixi loader now using this instead
    https://github.com/englercj/resource-loader
    https://github.com/englercj/resource-loader/blob/master/src/Loader.js

**/
import * as PIXI from 'pixi.js'
import app from './app'

const assets = {
    queue: null,
    pool: {},
    manifest: [],
    verbose: false,

    init: function () {
        if (this.verbose) console.log('create loader');
    },

    reset: function () {
        PIXI.Loader.shared.reset();
    },
    
    loadManifest: function (progress, callback) {
        if (this.manifest.length === 0) {
            callback();
        }
        else {
            if (this.verbose) console.log('reset');

            PIXI.Loader.shared.reset();

            if (this.verbose) console.log('addem');
            for (var i = 0; i < this.manifest.length; i++) {
                var m = this.manifest[i];
                if (this.verbose) console.log('add', m.id);

                PIXI.Loader.shared.add(m.id, m.src, { crossOrigin: true });
            }

            if (this.verbose) console.log('finished adding');

            PIXI.Loader.shared.onComplete.once(function (e) {
                if (this.verbose) console.log('complete', e);

                this.clearManifest();
                callback();
                // PIXI.Loader.shared = null;
            }.bind(this));

            PIXI.Loader.shared.onProgress.add(function (e) {
                progress(e.progress * 0.01);
            });

            PIXI.Loader.shared.onError.add(function (e) {
                if (this.verbose) console.log('error', e);
            }.bind(this));

            PIXI.Loader.shared.onLoad.add(function (e) {
                if (this.verbose) console.log('load', e);
            }.bind(this));

            PIXI.Loader.shared.onStart.add(function (e) {

            });

            if (this.verbose) console.log('load');
            PIXI.Loader.shared.load();
        }
    },

    getAssetURL: function (id) {
        return this.pool[id];
    },

    //may need update
    destroyAsset: function (id) {
        if (!this.pool.hasOwnProperty(id)) return;
        PIXI.Texture.removeTextureFromCache(this.getAssetURL(id));
        delete this.pool[id];
    },

    clearManifest: function () {
        this.manifest = [];
    },

    addToManifest: function (newManifest) {
        for (var i = 0; i < newManifest.length; i++) {
            if (app.isRetina) newManifest[i].src = newManifest[i].src.replace('.json', '_retina.json');

            this.manifest.push(newManifest[i]);
            this.pool[newManifest[i].id] = newManifest[i].src;
        }
    },

    addToPool: function (id, value) {
        this.pool[id] = value;
    }
}

export { assets as default };
