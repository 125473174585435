import ArtillerySelfieThumb from '../../assets/babcock_menus/selfie_location/intro/Selfielocation_artillery.png';
import CollegeSelfieThumb from '../../assets/babcock_menus/selfie_location/intro/Selfielocation_college.png';
import DevonportSelfieThumb from '../../assets/babcock_menus/selfie_location/intro/Selfielocation_devonport.png';
import ScienceParkSelfieThumb from '../../assets/babcock_menus/selfie_location/intro/Selfielocation_science.png';
import SmeatonsSelfieThumb from '../../assets/babcock_menus/selfie_location/intro/Selfielocation_smeatons.png';
import ArtillerySelfiePic from '../../assets/babcock_menus/selfie_location/reward/polaroid_TheArtilleryTower.png';
import CollegeSelfiePic from '../../assets/babcock_menus/selfie_location/reward/polaroid_CityCollegePlymouth.png';
import DevonportSelfiePic from '../../assets/babcock_menus/selfie_location/reward/polaroid_DevonportColumn.png';
import ScienceParkSelfiePic from '../../assets/babcock_menus/selfie_location/reward/polaroid_PlymouthSciencePark.png';
import SmeatonsSelfiePic from '../../assets/babcock_menus/selfie_location/reward/polaroid_SmeatonsTower.png';
import ArtillerySelfieCv from '../../assets/babcock_menus/cv/selfies/CVpolaroid_TheArtilleryTower.png';
import CollegeSelfieCv from '../../assets/babcock_menus/cv/selfies/CVpolaroid_CityCollegePlymouth.png';
import DevonportSelfieCv from '../../assets/babcock_menus/cv/selfies/CVpolaroid_DevonportColumn.png';
import ScienceParkSelfieCv from '../../assets/babcock_menus/cv/selfies/CVpolaroid_PlymouthSciencePark.png';
import SmeatonsSelfieCv from '../../assets/babcock_menus/cv/selfies/CVpolaroid_SmeatonsTower.png';

import CoffeeGameThumb from '../../assets/babcock_menus/mini_game_flow/coffee_shop/thumbnail.png';
import CoffeeGameBuildingIcon from '../../assets/babcock_menus/mini_game_flow/coffee_shop/building_icon.png';
import CoffeeGameItemLocked from '../../assets/babcock_menus/mini_game_flow/coffee_shop/coffee_locked.png';
import CoffeeGameItemUnlocked from '../../assets/babcock_menus/mini_game_flow/coffee_shop/coffee_Unlocked.png';
import CoffeeInventoryIcon from '../../assets/babcock_menus/inventory/itembag_coffee.png';
import CoffeeRewardIcon from '../../assets/babcock_menus/mini_game_flow/Coffee_rewardUnlock.png';

import FoodBankGameThumb from '../../assets/babcock_menus/mini_game_flow/foodbank/foodbank_thumbnail.png';
import FoodBankGameBuildingIcon from '../../assets/babcock_menus/mini_game_flow/foodbank/foodbank_icon.png';
import VegGameItemLocked from '../../assets/babcock_menus/mini_game_flow/foodbank/veg_Locked.png';
import VegGameItemUnlocked from '../../assets/babcock_menus/mini_game_flow/foodbank/veg_Unlocked.png';
import VegInventoryIcon from '../../assets/babcock_menus/inventory/itembag_foodbank.png';
import VegRewardIcon from '../../assets/babcock_menus/mini_game_flow/foodbank_rewardUnlock.png';

import HotelGameThumb from '../../assets/babcock_menus/mini_game_flow/hotel/hotel_thumbnail.png';
import HotelGameBuildingIcon from '../../assets/babcock_menus/mini_game_flow/hotel/hotel_icon.png';
import MoneyGameItemLocked from '../../assets/babcock_menus/mini_game_flow/hotel/money_locked.png';
import MoneyGameItemUnlocked from '../../assets/babcock_menus/mini_game_flow/hotel/money_Unlocked.png';
import MoneyInventoryIcon from '../../assets/babcock_menus/inventory/itembag_money.png';
import MoneyRewardIcon from '../../assets/babcock_menus/mini_game_flow/hotel_rewardUnlock.png';

import ClothesInventoryIcon from '../../assets/babcock_menus/inventory/itembag_clothes.png';

import Char1Dialog from '../../assets/babcock_menus/in_game/dialog/mc_1.png';
import Char2Dialog from '../../assets/babcock_menus/in_game/dialog/mc_2.png';
import Char3Dialog from '../../assets/babcock_menus/in_game/dialog/mc_3.png';
import Char4Dialog from '../../assets/babcock_menus/in_game/dialog/mc_4.png';
import Char5Dialog from '../../assets/babcock_menus/in_game/dialog/mc_5.png';
import Char1DialogSuited from '../../assets/babcock_menus/in_game/dialog/mc_1Suit.png';
import Char2DialogSuited from '../../assets/babcock_menus/in_game/dialog/mc_2Suit.png';
import Char3DialogSuited from '../../assets/babcock_menus/in_game/dialog/mc_3Suit.png';
import Char4DialogSuited from '../../assets/babcock_menus/in_game/dialog/mc_4Suit.png';
import Char5DialogSuited from '../../assets/babcock_menus/in_game/dialog/mc_5Suit.png';

import NeightbourDialogIcon from '../../assets/babcock_menus/in_game/dialog/neighbour.png';
import LighthouseDialogIcon from '../../assets/babcock_menus/in_game/dialog/lighthouseWorker.png';
import YoungPersonDialogIcon from '../../assets/babcock_menus/in_game/dialog/workPlacement.png';
import GroundsmanDialogIcon from '../../assets/babcock_menus/in_game/dialog/groundskeeper.png';
import CoffeeShopDialogIcon from '../../assets/babcock_menus/in_game/dialog/coffeeManager.png';
import HotelDialogIcon from '../../assets/babcock_menus/in_game/dialog/plumber.png';
import FoodBankDialogIcon from '../../assets/babcock_menus/in_game/dialog/foodBankOrganiser.png';
import InterviewDialogIcon from '../../assets/babcock_menus/in_game/dialog/babcock_int.png';
import AdvisorDialogIcon from '../../assets/babcock_menus/in_game/dialog/skillsLaunchpadAdvisor.png';
import AdvisorDialogIconNoPhone from '../../assets/babcock_menus/in_game/dialog/skillsLaunchpadAdvisor2.png';
import ClothesShopDialogIcon from '../../assets/babcock_menus/in_game/dialog/shopOwner.png';
import RadioIcon from '../../assets/babcock_menus/in_game/dialog/radio.png';

import CoffeePop from "../../assets/babcock_menus/popup_coffee.png";
import MoneyPop from "../../assets/babcock_menus/popup_money.png";
import ClothesPop from "../../assets/babcock_menus/popup_formalwear.png";
import VegPop from "../../assets/babcock_menus/popup_veg.png";

import { MAIN_CHARACTER, MAIN_CHARACTER_2, MAIN_CHARACTER_3, MAIN_CHARACTER_4, MAIN_CHARACTER_5, MAIN_CHARACTER_SUIT, MAIN_CHARACTER_2_SUIT, MAIN_CHARACTER_3_SUIT, MAIN_CHARACTER_4_SUIT, MAIN_CHARACTER_5_SUIT } from '../../model/charactersSprites';
import { BABCOCK_OFFICE_DIALOG, BIZ_WEAR_DIALOG, COFFEE_SHOP_DIALOG, CV_TUTORIAL_DIALOG_1, CV_TUTORIAL_DIALOG_2, CV_TUTORIAL_DIALOG_3, CV_TUTORIAL_DIALOG_4, CV_TUTORIAL_DIALOG_5, FOODBANK_DIALOG, GROUNDSKEEPER_DIALOG, HOME_DIALOG, HOTEL_DIALOG, INTRO_DIALOG_1, INTRO_DIALOG_2, LIGHTHOUSE_DIALOG, NEIGHBOUR_DIALOG, SKILLS_LAUNCHPAD_DIALOG, YOUNG_PERSON_DIALOG } from './dialog';
import { CV_INSTRUCTION_PROMPT_IMAGES, CV_INTRUCTION_ARIA_LABELS, CV_INTRUCTION_PROMPTS, INTRO_PROMPTS, INTRO_PROMPT_ARIA_LABELS, INTRO_PROMPT_IMAGES } from './prompts';

import * as BADGES from './badges-config';

export const VERSION = 'v2';

export const LITTER_COUNT = 10;
export const LEAD_PERSON_TIME = 60;
export const GET_TO_INTERVIEW_TIME = 120;

export const CHARACTER_ORDER = [
    MAIN_CHARACTER_4,
    MAIN_CHARACTER,
    MAIN_CHARACTER_5,
    MAIN_CHARACTER_2,
    MAIN_CHARACTER_3
];

export const CHARACTER_SUITS = [
    MAIN_CHARACTER_4_SUIT,
    MAIN_CHARACTER_SUIT,
    MAIN_CHARACTER_5_SUIT,
    MAIN_CHARACTER_2_SUIT,
    MAIN_CHARACTER_3_SUIT
];

export const INTRO_PHASES = [
    {
        type: 'dialog',
        dialog: INTRO_DIALOG_1
    },
    {
        type: 'phone',
        caller: 'Sarah @ Skills Launchpad'
    },
    {
        type: 'dialog',
        dialog: INTRO_DIALOG_2
    },
    {
        type: 'prompts',
        prompts: INTRO_PROMPTS,
        promptImages: INTRO_PROMPT_IMAGES,
        promptArias: INTRO_PROMPT_ARIA_LABELS,
    }
]

export const CV_TUTORIAL_PHASES = [
    {
        type: 'dialog',
        dialog: CV_TUTORIAL_DIALOG_1,
        hilightSection: ''
    },
    {
        type: 'dialog',
        dialog: CV_TUTORIAL_DIALOG_2,
        hilightSection: 'experience'
    },
    {
        type: 'dialog',
        dialog: CV_TUTORIAL_DIALOG_3,
        hilightSection: 'attributes'
    },
    {
        type: 'dialog',
        dialog: CV_TUTORIAL_DIALOG_4,
        hilightSection: 'hobbies'
    },
    {
        type: 'dialog',
        dialog: CV_TUTORIAL_DIALOG_5,
        hilightSection: 'overall'
    },
    {
        type: 'prompts',
        prompts: CV_INTRUCTION_PROMPTS,
        promptImages: CV_INSTRUCTION_PROMPT_IMAGES,
        promptArias: CV_INTRUCTION_ARIA_LABELS,
        hilightSection: ''
    }
]

// task types
export const NO_TASK = 'notask';
export const LEAD_TO_DEST = 'leadtodest';
export const REQUIRES_ITEM = 'requiresitem';
export const SUB_TASK = 'subtask';
export const GET_TO_LOCATION = 'gettolocation';
export const MAIN_TASK = 'maintask';
export const INTRO_TASK = 'introtask';
export const COLLECT_LITTER_TASK = 'collectlittertask';
export const CV_SENT_TASK = 'cvsent';
export const GET_TO_INTERVIEW_TASK = 'gettointerview';
export const CHANGE_CLOTHES_TASK = 'change_clothes';
export const GAME_TASK = 'game_task';

// items
const VEGETABLES = {
    icon: '',
    description: 'Bag of Vegetables',
    gamePanelsItemLocked: VegGameItemLocked,
    gamePanelsItemUnlocked: VegGameItemUnlocked,
    inventoryIcon: VegInventoryIcon,
    rewardIcon: VegRewardIcon,
    pillIconIdLocked: 'veg_locked.png',
    pillIconIdUnocked: 'veg_unlocked.png',
    popPanel: VegPop,
};

const MONEY = {
    icon: '',
    description: 'Money for Business Attire',
    gamePanelsItemLocked: MoneyGameItemLocked,
    gamePanelsItemUnlocked: MoneyGameItemUnlocked,
    inventoryIcon: MoneyInventoryIcon,
    rewardIcon: MoneyRewardIcon,
    pillIconIdLocked: 'money_locked.png',
    pillIconIdUnocked: 'money_unlocked.png',
    popPanel: MoneyPop,
};

const COFFEE = {
    icon: '',
    description: 'Cup of Coffee',
    gamePanelsItemLocked: CoffeeGameItemLocked,
    gamePanelsItemUnlocked: CoffeeGameItemUnlocked,
    inventoryIcon: CoffeeInventoryIcon,
    rewardIcon: CoffeeRewardIcon,
    pillIconIdLocked: 'coffee_locked.png',
    pillIconIdUnocked: 'coffee_Unlocked.png',
    popPanel: CoffeePop,
};

export const CLOTHES = {
    icon: '',
    description: 'Formal clothes',
    inventoryIcon: ClothesInventoryIcon,
    popPanel: ClothesPop,
}

export const ITEMS = {
    VEGETABLES: VEGETABLES,
    MONEY: MONEY,
    COFFEE: COFFEE,
    CLOTHES: CLOTHES,
};

export const ITEMS_FLAT_ARRAY = [VEGETABLES, MONEY, COFFEE, CLOTHES];

// games
export const PLUMBING_GAME = {
    id: 0,
    name: 'Pipes Game',
    itemEarned: MONEY,
    // will have data about the class or view to bring in to start the game
    // will also have data about cv attributes that you earn
    gameDescription: 'Help out to get fantastic work experience!',
    gameEndDescription: 'You fixed every leak!',

    gameViews: ['pg_gameView', 'pg_hudView'],

    higherScoreIsBetter: false,
    scoreUnits: 'moves',

    progressBadges: [
        BADGES.PROBLEM_SOLVING,
        BADGES.STAYING_POSITIVE,
        BADGES.TEAMWORK,
    ]
}

export const COFFEE_SHOP_GAME = {
    id: 1,
    name: 'Coffee Game',
    itemEarned: COFFEE,
    // will have data about the class or view to bring in to start the game
    // will also have data about cv attributes that you earn
    gameDescription: 'Help out to get a glowing reference.',
    gameEndDescription: 'Looks like someone spilled the ... beans!',

    gameViews: ['coffeegame_view', 'coffeegame_hudView'],

    higherScoreIsBetter: false,
    scoreUnits: 'moves',

    progressBadges: [
        BADGES.PROBLEM_SOLVING,
        BADGES.STAYING_POSITIVE,
        BADGES.TEAMWORK,
    ]
}

export const DELIVERY_GAME = {
    id: 2,
    name: 'Delivery Game',
    itemEarned: VEGETABLES,
    // will have data about the class or view to bring in to start the game
    // will also have data about cv attributes that you earn
    gameDescription: 'Help out to get voluntary experience!',
    gameEndDescription: 'Deliveries completed!',

    gameViews: ['fb_gameView', 'fb_hudView'],

    higherScoreIsBetter: true,
    scoreUnits: 'packages',

    progressBadges: [
        BADGES.PROBLEM_SOLVING,
        BADGES.STAYING_POSITIVE,
        BADGES.TEAMWORK,
    ]
}

const GAMES_FLAT_ARRAY = [PLUMBING_GAME, COFFEE_SHOP_GAME, DELIVERY_GAME];

// locations
const COUNTY_COUNCIL = 'County Council';
const DERRIFORD_HOSPITAL = 'Derriford Hospital';
const DEVONPORT_COLUMN = 'Devonport Column';
const FOOD_BANK = 'Food Bank';
export const HARBOUR_HOTEL = 'Harbour Hotel';
const LIGHTHOUSE_RENOVATE = 'Lighthouse';
const NEIGHBOUR = 'Neighbour';
const SCIENCE_PARK = 'Science Park';
export const SKILLS_LAUNCHPAD = 'Skills Launchpad';
const SMEATONS_TOWER = 'Smeatons Tower';
const UNI_CAMPUS = 'Uni Campus';
const ARTILLERY_TOWER = 'Artillery Tower';
export const BABCOCK_OFFICE = 'Babcock Office';
export const BIZ_WEAR_SHOP = 'Biz Wear Shop';
const CITY_COLLEGE = 'City College';
const COFFEE_SHOP = 'Coffee Shop';
export const HOME = 'Home';

export const LOCATIONS = {
    COUNTY_COUNCIL: {
        id: COUNTY_COUNCIL,
        name: 'Plymouth City Council',

        phasesAvailable: [],
    },
    DERRIFORD_HOSPITAL: {
        id: DERRIFORD_HOSPITAL,
        name: 'Derriford Hospital',

        phasesAvailable: [],
    },
    DEVONPORT_COLUMN: {
        id: DEVONPORT_COLUMN,
        name: 'Devonport Column',
        description: 'Devonport Guildhall and Column remains the heart of historic Devonport. It offers visitors the chance to step back in time to Regency Plymouth and experience the city from a totally unique angle — 124 ft off the ground!',
        selfieThumb: DevonportSelfieThumb,
        selfiePic: DevonportSelfiePic,
        selfieCvPic: DevonportSelfieCv,

        phasesAvailable: [MAIN_TASK, CHANGE_CLOTHES_TASK],
    },
    FOOD_BANK: {
        id: FOOD_BANK,
        name: 'Food Bank',

        game: DELIVERY_GAME,
        gameStartThumb: FoodBankGameThumb,
        gameEndBuildingIcon: FoodBankGameBuildingIcon,
        // gameItemLocked: FoodBankGameItemLocked,
        // gameItemUnlocked: FoodBankGameItemUnlocked,
        // gameDescription: 'Load the trucks.',
        // gameEndDescription: 'Deliveries completed!',

        complexDialog: FOODBANK_DIALOG,

        phasesAvailable: [MAIN_TASK, CHANGE_CLOTHES_TASK],
    },
    HARBOUR_HOTEL: {
        id: HARBOUR_HOTEL,
        name: 'Harbour Hotel',

        game: PLUMBING_GAME,
        gameStartThumb: HotelGameThumb,
        gameEndBuildingIcon: HotelGameBuildingIcon,
        // gameItemLocked: HotelGameItemLocked,
        // gameItemUnlocked: HotelGameItemUnlocked,
        // gameDescription: 'Fix the pipes.',
        // gameEndDescription: 'Leaks fixed!',

        complexDialog: HOTEL_DIALOG,

        phasesAvailable: [MAIN_TASK, CHANGE_CLOTHES_TASK],
    },
    LIGHTHOUSE_RENOVATE: {
        id: LIGHTHOUSE_RENOVATE,
        name: 'Lighthouse Renovations',

        phasesAvailable: [INTRO_TASK, MAIN_TASK, CHANGE_CLOTHES_TASK],
    },
    NEIGHBOUR: {
        id: NEIGHBOUR,
        name: 'Neighbour\'s House',

        phasesAvailable: [MAIN_TASK, INTRO_TASK, GET_TO_INTERVIEW_TASK, CHANGE_CLOTHES_TASK],
    },
    SCIENCE_PARK: {
        id: SCIENCE_PARK,
        name: 'Plymouth Science Park',
        description: 'A vibrant and thriving community of innovators, with a global reach, Plymouth Science Park is home to international businesses, start-ups and academics and a hub for inspirational health tech, advanced engineering, marine and technological advancements.',
        selfieThumb: ScienceParkSelfieThumb,
        selfiePic: ScienceParkSelfiePic,
        selfieCvPic: ScienceParkSelfieCv,

        phasesAvailable: [MAIN_TASK, CHANGE_CLOTHES_TASK],
    },
    SKILLS_LAUNCHPAD: {
        id: SKILLS_LAUNCHPAD,
        name: 'Skills Launchpad (Barclays Armada Building)',

        taskType: INTRO_TASK,
        complexDialog: SKILLS_LAUNCHPAD_DIALOG,
        showViewAfterDialog: 'contactform',
        hideRoutes: true,

        startTaskPhase: MAIN_TASK,

        phasesAvailable: [INTRO_TASK, MAIN_TASK],

        progressBadges: [
            BADGES.AIMING_HIGH
        ]
    },
    SMEATONS_TOWER: {
        id: SMEATONS_TOWER,
        name: 'Smeaton\'s Tower',
        description: 'Standing at 72 foot high, Smeaton\'s Tower offers fantastic views of Plymouth and the city from its lantern room which, along with the rest of the building, has been painstakingly restored to its original glory.',
        selfieThumb: SmeatonsSelfieThumb,
        selfiePic: SmeatonsSelfiePic,
        selfieCvPic: SmeatonsSelfieCv,

        phasesAvailable: [MAIN_TASK, CHANGE_CLOTHES_TASK],
    },
    UNI_CAMPUS: {
        id: UNI_CAMPUS,
        name: 'Plymouth University Campus',

        phasesAvailable: [],
    },
    ARTILLERY_TOWER: {
        id: ARTILLERY_TOWER,
        name: 'Artillery Tower',
        description: 'Firestone Bay Artillery Tower dates from the early 1500s, and is one of the oldest complete military buildings on Plymouth\'s sea front. This tower protected the deep water passage at the North of Plymouth Sound, between Drake\'s Island and the main water front.',
        selfieThumb: ArtillerySelfieThumb,
        selfiePic: ArtillerySelfiePic,
        selfieCvPic: ArtillerySelfieCv,

        phasesAvailable: [MAIN_TASK, CHANGE_CLOTHES_TASK],
    },
    BABCOCK_OFFICE: {
        id: BABCOCK_OFFICE,
        name: 'Babcock Office',

        taskType: NO_TASK,

        phasesAvailable: [MAIN_TASK, GET_TO_INTERVIEW_TASK, CHANGE_CLOTHES_TASK],

        complexDialog: BABCOCK_OFFICE_DIALOG,
    },
    BIZ_WEAR_SHOP: {
        id: BIZ_WEAR_SHOP,
        name: 'Business Wear Shop',

        taskType: REQUIRES_ITEM,
        requiresItem: MONEY,
        givesItem: CLOTHES,

        startTaskPhase: GET_TO_INTERVIEW_TASK,
        complexDialog: BIZ_WEAR_DIALOG,

        phasesAvailable: [MAIN_TASK, CHANGE_CLOTHES_TASK],

        progressBadges: [BADGES.STAYING_POSITIVE, BADGES.LISTENING, BADGES.TEAMWORK],
    },
    CITY_COLLEGE: {
        id: CITY_COLLEGE,
        name: 'City College Plymouth',
        description: 'City College Plymouth is a tertiary institution and further education college in South West England offering a fantastic range of technical, professional and vocational qualifications, Apprenticeships, Access to Higher Education and Foundation Degree courses, plus professional and bespoke training to local employers.',

        selfieThumb: CollegeSelfieThumb,
        selfiePic: CollegeSelfiePic,
        selfieCvPic: CollegeSelfieCv,

        phasesAvailable: [MAIN_TASK, CHANGE_CLOTHES_TASK],
    },
    COFFEE_SHOP: {
        id: COFFEE_SHOP,
        name: 'Coffee Shop',
        game: COFFEE_SHOP_GAME,

        gameStartThumb: CoffeeGameThumb,
        gameEndBuildingIcon: CoffeeGameBuildingIcon,
        // gameItemLocked: CoffeeGameItemLocked,
        // gameItemUnlocked: CoffeeGameItemUnlocked,
        // gameDescription: 'Help out to get a glowing reference.',
        // gameEndDescription: 'Looks like someone spilled the ... beans!',

        complexDialog: COFFEE_SHOP_DIALOG,

        phasesAvailable: [MAIN_TASK, CHANGE_CLOTHES_TASK],
    },
    HOME: {
        id: HOME,
        name: 'Home',
        taskType: REQUIRES_ITEM,
        requiresItem: CLOTHES,

        startTaskPhase: GET_TO_INTERVIEW_TASK,
        complexDialog: HOME_DIALOG,

        phasesAvailable: [MAIN_TASK, CHANGE_CLOTHES_TASK],
    }
};

// this is here just do our dialog panel can reference the icon
export const PLAYER_CHARACTER = {
    id: 'you',
    ariaLabel: 'You:',
    dialogIcon: [Char1Dialog, Char2Dialog, Char3Dialog, Char4Dialog, Char5Dialog],
    dialogIconSuited: [Char1DialogSuited, Char2DialogSuited, Char3DialogSuited, Char4DialogSuited, Char5DialogSuited],
}

export const COFFEE_SHOP_NPC = {
    id: 'coffeeshop',
    ariaLabel: 'Steve:',
    dialogIcon: CoffeeShopDialogIcon,
}

export const HOTEL_NPC = {
    id: 'hotel',
    ariaLabel: 'Rowan:',
    dialogIcon: HotelDialogIcon,

    // game tasks are linked to the locations if triggered by an npc
    taskType: GAME_TASK,
    locationId: 'Harbour Hotel',

    phasesAvailable: [MAIN_TASK, CHANGE_CLOTHES_TASK],

    complexDialog: HOTEL_DIALOG,
}

export const FOODBANK_NPC = {
    id: 'foodbank',
    ariaLabel: 'Foodbank Manager:',
    dialogIcon: FoodBankDialogIcon,
}

export const CLOTHES_SHOP_NPC = {
    id: 'clothesshop',
    ariaLabel: 'Clothes Shop Worker:',
    dialogIcon: ClothesShopDialogIcon,
}

export const ADVISOR_NPC = {
    id: 'advisor',
    ariaLabel: 'Sarah:',
    dialogIcon: AdvisorDialogIcon,
}

export const INTERVIEWER_NPC = {
    id: 'interviewer',
    ariaLabel: 'Babcock Interviewer:',
    dialogIcon: InterviewDialogIcon,
}

export const ADVISOR_NPC_NO_PHONE = {
    id: 'advisor_nophone',
    ariaLabel: 'Sarah:',
    dialogIcon: AdvisorDialogIconNoPhone,
}

export const RADIO_NPC = {
    id: 'radio',
    ariaLabel: 'Radio:',
    dialogIcon: RadioIcon,
}

// interactive npcs
export const NEIGHBOUR_NPC = {
    id: 'neighbour',
    ariaLabel: 'Neighbour:',
    taskType: REQUIRES_ITEM,
    requiresItem: VEGETABLES,
    beforeTaskDialog: 'I need vegetables',
    completeTaskDialog: 'Thanks for the vegetables, if you need a lift, just ask',
    afterTaskDialog: 'Thanks for the vegetables, if you need a lift, just ask',
    leaveAfterTask: false,

    complexDialog: NEIGHBOUR_DIALOG,
    dialogIcon: NeightbourDialogIcon,

    phasesAvailable: [MAIN_TASK, INTRO_TASK, GET_TO_INTERVIEW_TASK, CHANGE_CLOTHES_TASK],

    progressBadges: [BADGES.STAYING_POSITIVE, BADGES.LISTENING, BADGES.TEAMWORK],
}

export const LIGHTHOUSE_NPC = {
    id: 'lighthouse',
    ariaLabel: 'Lighthouse Worker:',
    taskType: REQUIRES_ITEM,
    requiresItem: COFFEE,
    beforeTaskDialog: 'I need coffee',
    completeTaskDialog: 'Thanks for the coffee',
    afterTaskDialog: 'Thanks for the coffee',
    leaveAfterTask: false,

    complexDialog: LIGHTHOUSE_DIALOG,
    dialogIcon: LighthouseDialogIcon,

    phasesAvailable: [MAIN_TASK, INTRO_TASK, CHANGE_CLOTHES_TASK],

    progressBadges: [BADGES.STAYING_POSITIVE, BADGES.LISTENING, BADGES.TEAMWORK],
}

export const FOLLOWER_NPC = {
    id: 'leadToInterview',
    ariaLabel: 'Young Person:',
    taskType: LEAD_TO_DEST,
    dest: 'Artillery Tower',
    beforeTaskDialog: 'I need to get to the Artillery Tower',
    completeTaskDialog: 'Thanks for the help!',
    afterTaskDialog: 'Thanks for the help!',
    leaveAfterTask: true,

    complexDialog: YOUNG_PERSON_DIALOG,
    dialogIcon: YoungPersonDialogIcon,

    phasesAvailable: [MAIN_TASK, CHANGE_CLOTHES_TASK],

    progressBadges: [BADGES.STAYING_POSITIVE, BADGES.LISTENING, BADGES.TEAMWORK],
}

export const HOSPITAL_GROUNDSKEEPER = {
    id: 'hospitalgroundsman',
    ariaLabel: 'Hospital Groundskeeper:',
    taskType: SUB_TASK,
    startTask: COLLECT_LITTER_TASK,
    beforeTaskDialog: 'I need help cleaning the hospital grounds',
    completeTaskDialog: 'Thanks for the help!',
    afterTaskDialog: 'Thanks for the help!',
    leaveAfterTask: false,

    complexDialog: GROUNDSKEEPER_DIALOG,
    dialogIcon: GroundsmanDialogIcon,
    removeTilesAfterTask: {
        collectableId: 'hospital_litter',
    },

    phasesAvailable: [MAIN_TASK, CHANGE_CLOTHES_TASK],

    progressBadges: [BADGES.STAYING_POSITIVE, BADGES.LISTENING, BADGES.TEAMWORK],
}


export const SELFIE_LOCATIONS = [DEVONPORT_COLUMN, ARTILLERY_TOWER, CITY_COLLEGE, SMEATONS_TOWER, SCIENCE_PARK];
export const SELFIE_PROGRESS_BADGES = [BADGES.CREATIVITY, BADGES.STAYING_POSITIVE, BADGES.LISTENING];
export const GAME_LOCATIONS = [FOOD_BANK, HARBOUR_HOTEL, COFFEE_SHOP];
export const SIMPLE_TASK_LOCATIONS = [BIZ_WEAR_SHOP, HOME, SKILLS_LAUNCHPAD, BABCOCK_OFFICE];
export const INTERACTIVE_NPCS = [NEIGHBOUR_NPC, LIGHTHOUSE_NPC, FOLLOWER_NPC, HOSPITAL_GROUNDSKEEPER, HOTEL_NPC];
export const DIALOG_ENTITIES = [NEIGHBOUR_NPC, LIGHTHOUSE_NPC, FOLLOWER_NPC, HOSPITAL_GROUNDSKEEPER, PLAYER_CHARACTER, COFFEE_SHOP_NPC, FOODBANK_NPC, HOTEL_NPC, CLOTHES_SHOP_NPC, ADVISOR_NPC, RADIO_NPC, ADVISOR_NPC_NO_PHONE, INTERVIEWER_NPC];

export function getLocationDataFromId(locationId) {
    for (let prop in LOCATIONS) {
        if (LOCATIONS[prop].id === locationId){
            return LOCATIONS[prop];
        }
    }
    return null;
}

export function getEntityDataFromId(entityId) {
    for (let i = 0; i < INTERACTIVE_NPCS.length; i++) {
        if (INTERACTIVE_NPCS[i].id === entityId){
            return INTERACTIVE_NPCS[i];
        }
    }
    return null;
}

export function getDialogDataFromId(dialogId) {
    for (let i = 0; i < DIALOG_ENTITIES.length; i++) {
        if (DIALOG_ENTITIES[i].id === dialogId){
            return DIALOG_ENTITIES[i];
        }
    }
    return null;
}

export function getGameDataFromId(gameId) {
    for (let i = 0; i < GAMES_FLAT_ARRAY.length; i++) {
        if (GAMES_FLAT_ARRAY[i].id === gameId) {
            return GAMES_FLAT_ARRAY[i];
        }
    }
    return null;
}

// task list items
export const TASK_LIST = [
    {
        description: 'Ask your manager at the coffee shop for a reference',
        validGamePhases: [MAIN_TASK],
        completion: {
            playedGame: COFFEE_SHOP_GAME
        }
    },
    {
        description: 'Volunteer your time by helping at the Food Bank',
        validGamePhases: [MAIN_TASK],
        completion: {
            playedGame: DELIVERY_GAME
        }
    },
    {
        description: 'Meet Rowan (plumber) at the Harbour Hotel',
        validGamePhases: [MAIN_TASK],
        completion: {
            playedGame: PLUMBING_GAME
        }
    },
    {
        description: 'Find a box of veg to help your neighbour',
        validGamePhases: [MAIN_TASK, CHANGE_CLOTHES_TASK],
        showWhen: {
            npcsInteractedWith: NEIGHBOUR_NPC
        },
        completion: {
            itemsCollected: VEGETABLES
        }
    },
    {
        description: 'Take the box of veg back to your neighbour in \'City North\'',
        validGamePhases: [MAIN_TASK, CHANGE_CLOTHES_TASK],
        showWhen: {
            itemsCollected: VEGETABLES,
            npcsInteractedWith: NEIGHBOUR_NPC
        },
        completion: {
            npcTasksCompleted: NEIGHBOUR_NPC
        }
    },
    {
        description: 'Help the lighthouse worker by finding a cup of coffee',
        validGamePhases: [MAIN_TASK, CHANGE_CLOTHES_TASK],
        showWhen: {
            npcsInteractedWith: LIGHTHOUSE_NPC
        },
        completion: {
            npcTasksCompleted: LIGHTHOUSE_NPC
        }
    },
    {
        description: 'You can now submit your CV at the Skills Launchpad or you can continue to complete tasks around the map!',
        validGamePhases: [MAIN_TASK, CHANGE_CLOTHES_TASK],
        showWhen: {
            cvStars: 3
        },
        completion: {
            applicationSent: true
        }
    },
    {
        description: 'Buy some new formalwear for your interview',
        validGamePhases: [MAIN_TASK, CHANGE_CLOTHES_TASK],
        showWhen: {
            applicationSent: true
        },
        completion: {
            itemsCollected: CLOTHES
        }
    },
    {
        description: 'Head home to get changed ready for your interview',
        validGamePhases: [CHANGE_CLOTHES_TASK],
        completion: {
            itemsGiven: CLOTHES
        }
    },
    {
        description: 'Ask your neighbour for a ride to the Babcock Offices in \'City Centre & Port\'',
        validGamePhases: [GET_TO_INTERVIEW_TASK],
        showWhen: {
            npcTasksCompleted: NEIGHBOUR_NPC
        },
        completion: {
            gotLift: true
        }
    },
    {
        description: 'Attend interview at Babcock offices',
        validGamePhases: [GET_TO_INTERVIEW_TASK],
        completion: {
            gameComplete: true
        }
    },
]

// Waypoint to show on the map
export const WAYPOINTS = [
    {
        location: {id: 'Bus Stop'},
        localConfig: {
            exit_to: 'city_centre'
        },
        icon: 'game-waypoint-icon.png',
        currentGameTaskNot: [
            INTRO_TASK,
            GET_TO_INTERVIEW_TASK
        ]
    },
    /* {
        location: {id: 'Bus Stop'},
        localConfig: {
            exit_to: 'city_centre'
        },
        icon: null,
        currentGameTask: [
            GET_TO_INTERVIEW_TASK
        ]
    }, */
    {
        location: LOCATIONS.COFFEE_SHOP,
        icon: 'game-waypoint-icon.png',
        currentGameTaskNot: [
            INTRO_TASK,
            GET_TO_INTERVIEW_TASK
        ]
    },
    {
        location: LOCATIONS.FOOD_BANK,
        icon: 'game-waypoint-icon.png',
        currentGameTaskNot: [
            INTRO_TASK,
            GET_TO_INTERVIEW_TASK
        ]
    },
    {
        location: LOCATIONS.HARBOUR_HOTEL,
        npcId: 'hotel',
        icon: 'game-waypoint-icon.png',
        currentGameTaskNot: [
            INTRO_TASK,
            GET_TO_INTERVIEW_TASK
        ]
    },
]
