export const ROTATE_TIME = .4;
export const POP_SCALE = 1.5;
export const TILE_WIDTH = 88;
export const TILE_HEIGHT = 88;
export const ASSET_SCALE = .5;
export const ASSETS_SCALE = .5;
export const TILE_AMOUNT_X = 6;
export const TILE_AMOUNT_Y = 6;
export const TILE_X_NAME = "ABCDEF";
export const STARTING_ROTATIONS = 28;
export const STRAIGHT_PIECES = ['A4', 'B1', 'B5', 'C5', 'C6', 'D2', 'D4', 'E6'];


export const HUD_100_SCORE = 0;
export const HUD_0_SCORE = 51;
export const STAR1_SCORE = 50;
export const STAR2_SCORE = 40;
export const STAR3_SCORE = 30;
export const HUD_PROGRESS_ASCENDING = false;