import React from 'react'
import PositionableReactView from '../../koko-framework/ui/react-views/PositionableReactView';
import { PANEL_STATE } from '../../Bootstrap';
import GameController from '../../controller/GameController';

export default class GenInteractionPanel extends PositionableReactView {
    renderContent() {
        return (
            <div className="example-panel no-select default-cursor" style={{minWidth: 300, minHeight: 200, textAlign: 'center'}} onClick={GameController.clickHandler}>
                <h2>Interaction</h2>
                <p>{PANEL_STATE.contents}</p>
            </div>
        );
    }
}