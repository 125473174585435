import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import { v } from '../../../koko-framework/shorthand';
import BabcockController from "../../controller/BabcockController";

export default class GameRewardBG extends ReactFullScreenView {
    closePanel = () => {
        v.hideView(this.props.viewId);
    }

    renderContent() {
        return (
            <div className="full-screen-holder reward-bg no-select default-cursor">
            </div>
        );
    }
}