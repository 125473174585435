import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import { au, v, co } from '../../../koko-framework/shorthand'
import CookiesLogo from '../../../assets/babcock_menus/cookies_pop/cookies_img.png';
import LearnMoreButton from '../../../assets/babcock_menus/cookies_pop/learnmore_btn.png';
import AcceptButton from '../../../assets/babcock_menus/cookies_pop/accept_btn.png';

import ReactGA from 'react-ga4';
import main from "../../../main";

export default class CookiesPanel extends ReactFullScreenView {
    closeCookiesPanel = () => {
        au.play('primarybtn', 1, false, 0, false);

        v.hideView('cookiespanel');

        co.set('accept-cookie', '1', 1000);
        co.set('muted', '0', 1000);

        v.get('mainmenu').cookiesAccepted();
        main.cookiesAccepted = true;

        ReactGA.event({
            category: "UI Action",
            action: "Cookies Accepted",
        });
    }

    renderContent() {
        return (
            <div className="full-screen-holder no-select default-cursor">
                {<div className="view-row grow black-out"></div>}
                <div className="cookies-bottom">
                    <div className="view-row shrink">
                        <img className="cookies-logo" src={CookiesLogo} alt="Cookies" />
                    </div>
                    <div className="view-row shrink">
                        <p className="blue-header bold-text">We use cookies!</p>
                    </div>
                    <div className="view-row shrink centered">
                        <p className="grey-info-text"><span className="bold-text">No not those ones...</span> We need your consent to use functional and analytical cookies to make sure you get the best experience when using this site.</p>
                    </div>
                    <div className="view-row shrink centered">
                        <div className="auto-margin">
                            <a href="https://www.babcockinternational.com/privacy/" target="_blank"><img className="cookies-button active-button" src={LearnMoreButton} alt="Click to earn more about our cookies" /></a>
                            <img className="cookies-button active-button" src={AcceptButton} onClick={this.closeCookiesPanel} alt="Click to accept our cookies" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}