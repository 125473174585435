import * as PIXI from 'pixi.js'

export const MAIN_CHARACTER = {
    ANIM_SPEED: 0.5,
    SPRITES: {
        STILL: {
            xDir: 0,
            yDir: 0,
            // scaleX: -1,
            texture: 'char1/c1_down/mc_2_idle_down.png',
        },
        UP_IDLE: {
            idleFrame: true,
            xDir: 1,
            yDir: -1,
            texture: 'char1/c1_up/mc_2_idle_up.png',
        },
        UP: {
            xDir: 1,
            yDir: -1,
            texture: 'char1/c1_up/mc_2_walk_up_0001.png',
            animFrames: [
                'char1/c1_up/mc_2_walk_up_0001.png',
                'char1/c1_up/mc_2_walk_up_0002.png',
                'char1/c1_up/mc_2_walk_up_0003.png',
                'char1/c1_up/mc_2_walk_up_0004.png',
                'char1/c1_up/mc_2_walk_up_0005.png',
                'char1/c1_up/mc_2_walk_up_0006.png',
                'char1/c1_up/mc_2_walk_up_0007.png',
                'char1/c1_up/mc_2_walk_up_0008.png',
                'char1/c1_up/mc_2_walk_up_0009.png',
                'char1/c1_up/mc_2_walk_up_0010.png',
                'char1/c1_up/mc_2_walk_up_0011.png',
                'char1/c1_up/mc_2_walk_up_0012.png',
                'char1/c1_up/mc_2_walk_up_0013.png',
                'char1/c1_up/mc_2_walk_up_0014.png',
                'char1/c1_up/mc_2_walk_up_0015.png',
                'char1/c1_up/mc_2_walk_up_0016.png',
            ]
        },
        LEFT_IDLE: {
            idleFrame: true,
            xDir: -1,
            yDir: -1,
            scaleX: -1,
            texture: 'char1/c1_up/mc_2_idle_up.png',
        },
        LEFT: {
            xDir: -1,
            yDir: -1,
            scaleX: -1,
            texture: 'char1/c1_up/mc_2_walk_up_0001.png',
            animFrames: [
                'char1/c1_up/mc_2_walk_up_0001.png',
                'char1/c1_up/mc_2_walk_up_0002.png',
                'char1/c1_up/mc_2_walk_up_0003.png',
                'char1/c1_up/mc_2_walk_up_0004.png',
                'char1/c1_up/mc_2_walk_up_0005.png',
                'char1/c1_up/mc_2_walk_up_0006.png',
                'char1/c1_up/mc_2_walk_up_0007.png',
                'char1/c1_up/mc_2_walk_up_0008.png',
                'char1/c1_up/mc_2_walk_up_0009.png',
                'char1/c1_up/mc_2_walk_up_0010.png',
                'char1/c1_up/mc_2_walk_up_0011.png',
                'char1/c1_up/mc_2_walk_up_0012.png',
                'char1/c1_up/mc_2_walk_up_0013.png',
                'char1/c1_up/mc_2_walk_up_0014.png',
                'char1/c1_up/mc_2_walk_up_0015.png',
                'char1/c1_up/mc_2_walk_up_0016.png',
            ]
        },
        DOWN_IDLE: {
            idleFrame: true,
            xDir: -1,
            yDir: 1,
            scaleX: -1,
            texture: 'char1/c1_down/mc_2_idle_down.png',
        },
        Down: {
            xDir: -1,
            yDir: 1,
            scaleX: -1,
            texture: 'char1/c1_down/mc_2_walk_down_0001.png',
            animFrames: [
                'char1/c1_down/mc_2_walk_down_0001.png',
                'char1/c1_down/mc_2_walk_down_0002.png',
                'char1/c1_down/mc_2_walk_down_0003.png',
                'char1/c1_down/mc_2_walk_down_0004.png',
                'char1/c1_down/mc_2_walk_down_0005.png',
                'char1/c1_down/mc_2_walk_down_0006.png',
                'char1/c1_down/mc_2_walk_down_0007.png',
                'char1/c1_down/mc_2_walk_down_0008.png',
                'char1/c1_down/mc_2_walk_down_0009.png',
                'char1/c1_down/mc_2_walk_down_0010.png',
                'char1/c1_down/mc_2_walk_down_0011.png',
                'char1/c1_down/mc_2_walk_down_0012.png',
                'char1/c1_down/mc_2_walk_down_0013.png',
                'char1/c1_down/mc_2_walk_down_0014.png',
                'char1/c1_down/mc_2_walk_down_0015.png',
                'char1/c1_down/mc_2_walk_down_0016.png',
            ]
        },
        RIGHT_IDLE: {
            idleFrame: true,
            xDir: 1,
            yDir: 1,
            texture: 'char1/c1_down/mc_2_idle_down.png',
        },
        Right: {
            xDir: 1,
            yDir: 1,
            texture: 'char1/c1_down/mc_2_walk_down_0001.png',
            animFrames: [
                'char1/c1_down/mc_2_walk_down_0001.png',
                'char1/c1_down/mc_2_walk_down_0002.png',
                'char1/c1_down/mc_2_walk_down_0003.png',
                'char1/c1_down/mc_2_walk_down_0004.png',
                'char1/c1_down/mc_2_walk_down_0005.png',
                'char1/c1_down/mc_2_walk_down_0006.png',
                'char1/c1_down/mc_2_walk_down_0007.png',
                'char1/c1_down/mc_2_walk_down_0008.png',
                'char1/c1_down/mc_2_walk_down_0009.png',
                'char1/c1_down/mc_2_walk_down_0010.png',
                'char1/c1_down/mc_2_walk_down_0011.png',
                'char1/c1_down/mc_2_walk_down_0012.png',
                'char1/c1_down/mc_2_walk_down_0013.png',
                'char1/c1_down/mc_2_walk_down_0014.png',
                'char1/c1_down/mc_2_walk_down_0015.png',
                'char1/c1_down/mc_2_walk_down_0016.png',
            ]
        },
    }
}

export const MAIN_CHARACTER_2 = {
    ANIM_SPEED: 0.5,
    SPRITES: {
        STILL: {
            xDir: 0,
            yDir: 0,
            // scaleX: -1,
            texture: 'char2/c2_down/mc_4_idle_down.png',
        },
        UP_IDLE: {
            idleFrame: true,
            xDir: 1,
            yDir: -1,
            texture: 'char2/c2_up/mc_4_idle_up.png',
        },
        UP: {
            xDir: 1,
            yDir: -1,
            texture: 'char2/c2_up/mc_4_walk_up_0001.png',
            animFrames: [
                'char2/c2_up/mc_4_walk_up_0001.png',
                'char2/c2_up/mc_4_walk_up_0002.png',
                'char2/c2_up/mc_4_walk_up_0003.png',
                'char2/c2_up/mc_4_walk_up_0004.png',
                'char2/c2_up/mc_4_walk_up_0005.png',
                'char2/c2_up/mc_4_walk_up_0006.png',
                'char2/c2_up/mc_4_walk_up_0007.png',
                'char2/c2_up/mc_4_walk_up_0008.png',
                'char2/c2_up/mc_4_walk_up_0009.png',
                'char2/c2_up/mc_4_walk_up_0010.png',
                'char2/c2_up/mc_4_walk_up_0011.png',
                'char2/c2_up/mc_4_walk_up_0012.png',
                'char2/c2_up/mc_4_walk_up_0013.png',
                'char2/c2_up/mc_4_walk_up_0014.png',
                'char2/c2_up/mc_4_walk_up_0015.png',
                'char2/c2_up/mc_4_walk_up_0016.png',
            ]
        },
        LEFT_IDLE: {
            idleFrame: true,
            xDir: -1,
            yDir: -1,
            scaleX: -1,
            texture: 'char2/c2_up/mc_4_idle_up.png',
        },
        LEFT: {
            xDir: -1,
            yDir: -1,
            scaleX: -1,
            texture: 'char2/c2_up/mc_4_walk_up_0001.png',
            animFrames: [
                'char2/c2_up/mc_4_walk_up_0001.png',
                'char2/c2_up/mc_4_walk_up_0002.png',
                'char2/c2_up/mc_4_walk_up_0003.png',
                'char2/c2_up/mc_4_walk_up_0004.png',
                'char2/c2_up/mc_4_walk_up_0005.png',
                'char2/c2_up/mc_4_walk_up_0006.png',
                'char2/c2_up/mc_4_walk_up_0007.png',
                'char2/c2_up/mc_4_walk_up_0008.png',
                'char2/c2_up/mc_4_walk_up_0009.png',
                'char2/c2_up/mc_4_walk_up_0010.png',
                'char2/c2_up/mc_4_walk_up_0011.png',
                'char2/c2_up/mc_4_walk_up_0012.png',
                'char2/c2_up/mc_4_walk_up_0013.png',
                'char2/c2_up/mc_4_walk_up_0014.png',
                'char2/c2_up/mc_4_walk_up_0015.png',
                'char2/c2_up/mc_4_walk_up_0016.png',
            ]
        },
        DOWN_IDLE: {
            idleFrame: true,
            xDir: -1,
            yDir: 1,
            scaleX: -1,
            texture: 'char2/c2_down/mc_4_idle_down.png',
        },
        Down: {
            xDir: -1,
            yDir: 1,
            scaleX: -1,
            texture: 'char2/c2_down/mc_4_walk_down_0001.png',
            animFrames: [
                'char2/c2_down/mc_4_walk_down_0001.png',
                'char2/c2_down/mc_4_walk_down_0002.png',
                'char2/c2_down/mc_4_walk_down_0003.png',
                'char2/c2_down/mc_4_walk_down_0004.png',
                'char2/c2_down/mc_4_walk_down_0005.png',
                'char2/c2_down/mc_4_walk_down_0006.png',
                'char2/c2_down/mc_4_walk_down_0007.png',
                'char2/c2_down/mc_4_walk_down_0008.png',
                'char2/c2_down/mc_4_walk_down_0009.png',
                'char2/c2_down/mc_4_walk_down_0010.png',
                'char2/c2_down/mc_4_walk_down_0011.png',
                'char2/c2_down/mc_4_walk_down_0012.png',
                'char2/c2_down/mc_4_walk_down_0013.png',
                'char2/c2_down/mc_4_walk_down_0014.png',
                'char2/c2_down/mc_4_walk_down_0015.png',
                'char2/c2_down/mc_4_walk_down_0016.png',
            ]
        },
        RIGHT_IDLE: {
            idleFrame: true,
            xDir: 1,
            yDir: 1,
            texture: 'char2/c2_down/mc_4_idle_down.png',
        },
        Right: {
            xDir: 1,
            yDir: 1,
            texture: 'char2/c2_down/mc_4_walk_down_0001.png',
            animFrames: [
                'char2/c2_down/mc_4_walk_down_0001.png',
                'char2/c2_down/mc_4_walk_down_0002.png',
                'char2/c2_down/mc_4_walk_down_0003.png',
                'char2/c2_down/mc_4_walk_down_0004.png',
                'char2/c2_down/mc_4_walk_down_0005.png',
                'char2/c2_down/mc_4_walk_down_0006.png',
                'char2/c2_down/mc_4_walk_down_0007.png',
                'char2/c2_down/mc_4_walk_down_0008.png',
                'char2/c2_down/mc_4_walk_down_0009.png',
                'char2/c2_down/mc_4_walk_down_0010.png',
                'char2/c2_down/mc_4_walk_down_0011.png',
                'char2/c2_down/mc_4_walk_down_0012.png',
                'char2/c2_down/mc_4_walk_down_0013.png',
                'char2/c2_down/mc_4_walk_down_0014.png',
                'char2/c2_down/mc_4_walk_down_0015.png',
                'char2/c2_down/mc_4_walk_down_0016.png',
            ]
        },
    }
}

export const MAIN_CHARACTER_3 = {
    ANIM_SPEED: 0.5,
    SPRITES: {
        STILL: {
            xDir: 0,
            yDir: 0,
            // scaleX: -1,
            texture: 'char3/c3_down/mc_5_idle_down.png',
        },
        UP_IDLE: {
            idleFrame: true,
            xDir: 1,
            yDir: -1,
            texture: 'char3/c3_up/mc_5_idle_up.png',
        },
        UP: {
            xDir: 1,
            yDir: -1,
            texture: 'char3/c3_up/mc_5_walk_up_0001.png',
            animFrames: [
                'char3/c3_up/mc_5_walk_up_0001.png',
                'char3/c3_up/mc_5_walk_up_0002.png',
                'char3/c3_up/mc_5_walk_up_0003.png',
                'char3/c3_up/mc_5_walk_up_0004.png',
                'char3/c3_up/mc_5_walk_up_0005.png',
                'char3/c3_up/mc_5_walk_up_0006.png',
                'char3/c3_up/mc_5_walk_up_0007.png',
                'char3/c3_up/mc_5_walk_up_0008.png',
                'char3/c3_up/mc_5_walk_up_0009.png',
                'char3/c3_up/mc_5_walk_up_0010.png',
                'char3/c3_up/mc_5_walk_up_0011.png',
                'char3/c3_up/mc_5_walk_up_0012.png',
                'char3/c3_up/mc_5_walk_up_0013.png',
                'char3/c3_up/mc_5_walk_up_0014.png',
                'char3/c3_up/mc_5_walk_up_0015.png',
                'char3/c3_up/mc_5_walk_up_0016.png',
            ]
        },
        LEFT_IDLE: {
            idleFrame: true,
            xDir: -1,
            yDir: -1,
            scaleX: -1,
            texture: 'char3/c3_up/mc_5_idle_up.png',
        },
        LEFT: {
            xDir: -1,
            yDir: -1,
            scaleX: -1,
            texture: 'char3/c3_up/mc_5_walk_up_0001.png',
            animFrames: [
                'char3/c3_up/mc_5_walk_up_0001.png',
                'char3/c3_up/mc_5_walk_up_0002.png',
                'char3/c3_up/mc_5_walk_up_0003.png',
                'char3/c3_up/mc_5_walk_up_0004.png',
                'char3/c3_up/mc_5_walk_up_0005.png',
                'char3/c3_up/mc_5_walk_up_0006.png',
                'char3/c3_up/mc_5_walk_up_0007.png',
                'char3/c3_up/mc_5_walk_up_0008.png',
                'char3/c3_up/mc_5_walk_up_0009.png',
                'char3/c3_up/mc_5_walk_up_0010.png',
                'char3/c3_up/mc_5_walk_up_0011.png',
                'char3/c3_up/mc_5_walk_up_0012.png',
                'char3/c3_up/mc_5_walk_up_0013.png',
                'char3/c3_up/mc_5_walk_up_0014.png',
                'char3/c3_up/mc_5_walk_up_0015.png',
                'char3/c3_up/mc_5_walk_up_0016.png',
            ]
        },
        DOWN_IDLE: {
            idleFrame: true,
            xDir: -1,
            yDir: 1,
            scaleX: -1,
            texture: 'char3/c3_down/mc_5_idle_down.png',
        },
        Down: {
            xDir: -1,
            yDir: 1,
            scaleX: -1,
            texture: 'char3/c3_down/mc_5_walk_down_0001.png',
            animFrames: [
                'char3/c3_down/mc_5_walk_down_0001.png',
                'char3/c3_down/mc_5_walk_down_0002.png',
                'char3/c3_down/mc_5_walk_down_0003.png',
                'char3/c3_down/mc_5_walk_down_0004.png',
                'char3/c3_down/mc_5_walk_down_0005.png',
                'char3/c3_down/mc_5_walk_down_0006.png',
                'char3/c3_down/mc_5_walk_down_0007.png',
                'char3/c3_down/mc_5_walk_down_0008.png',
                'char3/c3_down/mc_5_walk_down_0009.png',
                'char3/c3_down/mc_5_walk_down_0010.png',
                'char3/c3_down/mc_5_walk_down_0011.png',
                'char3/c3_down/mc_5_walk_down_0012.png',
                'char3/c3_down/mc_5_walk_down_0013.png',
                'char3/c3_down/mc_5_walk_down_0014.png',
                'char3/c3_down/mc_5_walk_down_0015.png',
                'char3/c3_down/mc_5_walk_down_0016.png',
            ]
        },
        RIGHT_IDLE: {
            idleFrame: true,
            xDir: 1,
            yDir: 1,
            texture: 'char3/c3_down/mc_5_idle_down.png',
        },
        Right: {
            xDir: 1,
            yDir: 1,
            texture: 'char1/c1_down/mc_5_walk_down_0001.png',
            animFrames: [
                'char3/c3_down/mc_5_walk_down_0001.png',
                'char3/c3_down/mc_5_walk_down_0002.png',
                'char3/c3_down/mc_5_walk_down_0003.png',
                'char3/c3_down/mc_5_walk_down_0004.png',
                'char3/c3_down/mc_5_walk_down_0005.png',
                'char3/c3_down/mc_5_walk_down_0006.png',
                'char3/c3_down/mc_5_walk_down_0007.png',
                'char3/c3_down/mc_5_walk_down_0008.png',
                'char3/c3_down/mc_5_walk_down_0009.png',
                'char3/c3_down/mc_5_walk_down_0010.png',
                'char3/c3_down/mc_5_walk_down_0011.png',
                'char3/c3_down/mc_5_walk_down_0012.png',
                'char3/c3_down/mc_5_walk_down_0013.png',
                'char3/c3_down/mc_5_walk_down_0014.png',
                'char3/c3_down/mc_5_walk_down_0015.png',
                'char3/c3_down/mc_5_walk_down_0016.png',
            ]
        },
    }
}

export const MAIN_CHARACTER_4 = {
    ANIM_SPEED: 0.5,
    SPRITES: {
        STILL: {
            xDir: 0,
            yDir: 0,
            // scaleX: -1,
            texture: 'char4/c4_down/mc_1_idle_down.png',
        },
        UP_IDLE: {
            idleFrame: true,
            xDir: 1,
            yDir: -1,
            texture: 'char4/c4_up/mc_1_idle_up.png',
        },
        UP: {
            xDir: 1,
            yDir: -1,
            texture: 'char4/c4_up/mc_1_walk_up_0001.png',
            animFrames: [
                'char4/c4_up/mc_1_walk_up_0001.png',
                'char4/c4_up/mc_1_walk_up_0002.png',
                'char4/c4_up/mc_1_walk_up_0003.png',
                'char4/c4_up/mc_1_walk_up_0004.png',
                'char4/c4_up/mc_1_walk_up_0005.png',
                'char4/c4_up/mc_1_walk_up_0006.png',
                'char4/c4_up/mc_1_walk_up_0007.png',
                'char4/c4_up/mc_1_walk_up_0008.png',
                'char4/c4_up/mc_1_walk_up_0009.png',
                'char4/c4_up/mc_1_walk_up_0010.png',
                'char4/c4_up/mc_1_walk_up_0011.png',
                'char4/c4_up/mc_1_walk_up_0012.png',
                'char4/c4_up/mc_1_walk_up_0013.png',
                'char4/c4_up/mc_1_walk_up_0014.png',
                'char4/c4_up/mc_1_walk_up_0015.png',
                'char4/c4_up/mc_1_walk_up_0016.png',
            ]
        },
        LEFT_IDLE: {
            idleFrame: true,
            xDir: -1,
            yDir: -1,
            scaleX: -1,
            texture: 'char4/c4_up/mc_1_idle_up.png',
        },
        LEFT: {
            xDir: -1,
            yDir: -1,
            scaleX: -1,
            texture: 'char4/c4_up/mc_1_walk_up_0001.png',
            animFrames: [
                'char4/c4_up/mc_1_walk_up_0001.png',
                'char4/c4_up/mc_1_walk_up_0002.png',
                'char4/c4_up/mc_1_walk_up_0003.png',
                'char4/c4_up/mc_1_walk_up_0004.png',
                'char4/c4_up/mc_1_walk_up_0005.png',
                'char4/c4_up/mc_1_walk_up_0006.png',
                'char4/c4_up/mc_1_walk_up_0007.png',
                'char4/c4_up/mc_1_walk_up_0008.png',
                'char4/c4_up/mc_1_walk_up_0009.png',
                'char4/c4_up/mc_1_walk_up_0010.png',
                'char4/c4_up/mc_1_walk_up_0011.png',
                'char4/c4_up/mc_1_walk_up_0012.png',
                'char4/c4_up/mc_1_walk_up_0013.png',
                'char4/c4_up/mc_1_walk_up_0014.png',
                'char4/c4_up/mc_1_walk_up_0015.png',
                'char4/c4_up/mc_1_walk_up_0016.png',
            ]
        },
        DOWN_IDLE: {
            idleFrame: true,
            xDir: -1,
            yDir: 1,
            scaleX: -1,
            texture: 'char4/c4_down/mc_1_idle_down.png',
        },
        Down: {
            xDir: -1,
            yDir: 1,
            scaleX: -1,
            texture: 'char4/c4_down/mc_1_walk_down_0001.png',
            animFrames: [
                'char4/c4_down/mc_1_walk_down_0001.png',
                'char4/c4_down/mc_1_walk_down_0002.png',
                'char4/c4_down/mc_1_walk_down_0003.png',
                'char4/c4_down/mc_1_walk_down_0004.png',
                'char4/c4_down/mc_1_walk_down_0005.png',
                'char4/c4_down/mc_1_walk_down_0006.png',
                'char4/c4_down/mc_1_walk_down_0007.png',
                'char4/c4_down/mc_1_walk_down_0008.png',
                'char4/c4_down/mc_1_walk_down_0009.png',
                'char4/c4_down/mc_1_walk_down_0010.png',
                'char4/c4_down/mc_1_walk_down_0011.png',
                'char4/c4_down/mc_1_walk_down_0012.png',
                'char4/c4_down/mc_1_walk_down_0013.png',
                'char4/c4_down/mc_1_walk_down_0014.png',
                'char4/c4_down/mc_1_walk_down_0015.png',
                'char4/c4_down/mc_1_walk_down_0016.png',
            ]
        },
        RIGHT_IDLE: {
            idleFrame: true,
            xDir: 1,
            yDir: 1,
            texture: 'char4/c4_down/mc_1_idle_down.png',
        },
        Right: {
            xDir: 1,
            yDir: 1,
            texture: 'char4/c4_down/mc_1_walk_down_0001.png',
            animFrames: [
                'char4/c4_down/mc_1_walk_down_0001.png',
                'char4/c4_down/mc_1_walk_down_0002.png',
                'char4/c4_down/mc_1_walk_down_0003.png',
                'char4/c4_down/mc_1_walk_down_0004.png',
                'char4/c4_down/mc_1_walk_down_0005.png',
                'char4/c4_down/mc_1_walk_down_0006.png',
                'char4/c4_down/mc_1_walk_down_0007.png',
                'char4/c4_down/mc_1_walk_down_0008.png',
                'char4/c4_down/mc_1_walk_down_0009.png',
                'char4/c4_down/mc_1_walk_down_0010.png',
                'char4/c4_down/mc_1_walk_down_0011.png',
                'char4/c4_down/mc_1_walk_down_0012.png',
                'char4/c4_down/mc_1_walk_down_0013.png',
                'char4/c4_down/mc_1_walk_down_0014.png',
                'char4/c4_down/mc_1_walk_down_0015.png',
                'char4/c4_down/mc_1_walk_down_0016.png',
            ]
        },
    }
}

export const MAIN_CHARACTER_5 = {
    ANIM_SPEED: 0.5,
    Y_OFFSET: 60,
    X_OFFSET: 0,
    HILIGHT_Y_OFFSET: 28,
    SPRITES: {
        STILL: {
            xDir: 0,
            yDir: 0,
            // scaleX: -1,
            texture: 'char5/c5_down/mc_3_idle_down.png',
        },
        UP_IDLE: {
            idleFrame: true,
            xDir: 1,
            yDir: -1,
            texture: 'char5/c5_up/mc_3_idle_up.png',
        },
        UP: {
            xDir: 1,
            yDir: -1,
            texture: 'char5/c5_up/mc_3_walk_up_0001.png',
            animFrames: [
                'char5/c5_up/mc_3_walk_up_0001.png',
                'char5/c5_up/mc_3_walk_up_0002.png',
                'char5/c5_up/mc_3_walk_up_0003.png',
                'char5/c5_up/mc_3_walk_up_0004.png',
                'char5/c5_up/mc_3_walk_up_0005.png',
                'char5/c5_up/mc_3_walk_up_0006.png',
                'char5/c5_up/mc_3_walk_up_0007.png',
                'char5/c5_up/mc_3_walk_up_0008.png',
                'char5/c5_up/mc_3_walk_up_0009.png',
                'char5/c5_up/mc_3_walk_up_0010.png',
                'char5/c5_up/mc_3_walk_up_0011.png',
                'char5/c5_up/mc_3_walk_up_0012.png',
                'char5/c5_up/mc_3_walk_up_0013.png',
                'char5/c5_up/mc_3_walk_up_0014.png',
                'char5/c5_up/mc_3_walk_up_0015.png',
                'char5/c5_up/mc_3_walk_up_0016.png',
            ]
        },
        LEFT_IDLE: {
            idleFrame: true,
            xDir: -1,
            yDir: -1,
            scaleX: -1,
            texture: 'char5/c5_up/mc_3_idle_up.png',
        },
        LEFT: {
            xDir: -1,
            yDir: -1,
            scaleX: -1,
            texture: 'char5/c5_up/mc_3_walk_up_0001.png',
            animFrames: [
                'char5/c5_up/mc_3_walk_up_0001.png',
                'char5/c5_up/mc_3_walk_up_0002.png',
                'char5/c5_up/mc_3_walk_up_0003.png',
                'char5/c5_up/mc_3_walk_up_0004.png',
                'char5/c5_up/mc_3_walk_up_0005.png',
                'char5/c5_up/mc_3_walk_up_0006.png',
                'char5/c5_up/mc_3_walk_up_0007.png',
                'char5/c5_up/mc_3_walk_up_0008.png',
                'char5/c5_up/mc_3_walk_up_0009.png',
                'char5/c5_up/mc_3_walk_up_0010.png',
                'char5/c5_up/mc_3_walk_up_0011.png',
                'char5/c5_up/mc_3_walk_up_0012.png',
                'char5/c5_up/mc_3_walk_up_0013.png',
                'char5/c5_up/mc_3_walk_up_0014.png',
                'char5/c5_up/mc_3_walk_up_0015.png',
                'char5/c5_up/mc_3_walk_up_0016.png',
            ]
        },
        DOWN_IDLE: {
            idleFrame: true,
            xDir: -1,
            yDir: 1,
            scaleX: -1,
            texture: 'char5/c5_down/mc_3_idle_down.png',
        },
        Down: {
            xDir: -1,
            yDir: 1,
            scaleX: -1,
            texture: 'char3/c3_down/mc_5_walk_down_0001.png',
            animFrames: [
                'char5/c5_down/mc_3_walk_down_0001.png',
                'char5/c5_down/mc_3_walk_down_0002.png',
                'char5/c5_down/mc_3_walk_down_0003.png',
                'char5/c5_down/mc_3_walk_down_0004.png',
                'char5/c5_down/mc_3_walk_down_0005.png',
                'char5/c5_down/mc_3_walk_down_0006.png',
                'char5/c5_down/mc_3_walk_down_0007.png',
                'char5/c5_down/mc_3_walk_down_0008.png',
                'char5/c5_down/mc_3_walk_down_0009.png',
                'char5/c5_down/mc_3_walk_down_0010.png',
                'char5/c5_down/mc_3_walk_down_0011.png',
                'char5/c5_down/mc_3_walk_down_0012.png',
                'char5/c5_down/mc_3_walk_down_0013.png',
                'char5/c5_down/mc_3_walk_down_0014.png',
                'char5/c5_down/mc_3_walk_down_0015.png',
                'char5/c5_down/mc_3_walk_down_0016.png',
            ]
        },
        RIGHT_IDLE: {
            idleFrame: true,
            xDir: 1,
            yDir: 1,
            texture: 'char5/c5_down/mc_3_idle_down.png',
        },
        Right: {
            xDir: 1,
            yDir: 1,
            texture: 'char5/c5_down/mc_3_walk_down_0001.png',
            animFrames: [
                'char5/c5_down/mc_3_walk_down_0001.png',
                'char5/c5_down/mc_3_walk_down_0002.png',
                'char5/c5_down/mc_3_walk_down_0003.png',
                'char5/c5_down/mc_3_walk_down_0004.png',
                'char5/c5_down/mc_3_walk_down_0005.png',
                'char5/c5_down/mc_3_walk_down_0006.png',
                'char5/c5_down/mc_3_walk_down_0007.png',
                'char5/c5_down/mc_3_walk_down_0008.png',
                'char5/c5_down/mc_3_walk_down_0009.png',
                'char5/c5_down/mc_3_walk_down_0010.png',
                'char5/c5_down/mc_3_walk_down_0011.png',
                'char5/c5_down/mc_3_walk_down_0012.png',
                'char5/c5_down/mc_3_walk_down_0013.png',
                'char5/c5_down/mc_3_walk_down_0014.png',
                'char5/c5_down/mc_3_walk_down_0015.png',
                'char5/c5_down/mc_3_walk_down_0016.png',
            ]
        },
    }
}

export const MAIN_CHARACTER_SUIT = {
    ANIM_SPEED: 0.5,
    SPRITES: {
        STILL: {
            xDir: 0,
            yDir: 0,
            // scaleX: -1,
            texture: 'char1/c1_down/mc_2suit_idle_down.png',
        },
        UP_IDLE: {
            idleFrame: true,
            xDir: 1,
            yDir: -1,
            texture: 'char1/c1_up/mc_2suit_idle_up.png',
        },
        UP: {
            xDir: 1,
            yDir: -1,
            texture: 'char1/c1_up/mc_2suit_walk_up_0001.png',
            animFrames: [
                'char1/c1_up/mc_2suit_walk_up_0001.png',
                'char1/c1_up/mc_2suit_walk_up_0002.png',
                'char1/c1_up/mc_2suit_walk_up_0003.png',
                'char1/c1_up/mc_2suit_walk_up_0004.png',
                'char1/c1_up/mc_2suit_walk_up_0005.png',
                'char1/c1_up/mc_2suit_walk_up_0006.png',
                'char1/c1_up/mc_2suit_walk_up_0007.png',
                'char1/c1_up/mc_2suit_walk_up_0008.png',
                'char1/c1_up/mc_2suit_walk_up_0009.png',
                'char1/c1_up/mc_2suit_walk_up_0010.png',
                'char1/c1_up/mc_2suit_walk_up_0011.png',
                'char1/c1_up/mc_2suit_walk_up_0012.png',
                'char1/c1_up/mc_2suit_walk_up_0013.png',
                'char1/c1_up/mc_2suit_walk_up_0014.png',
                'char1/c1_up/mc_2suit_walk_up_0015.png',
                'char1/c1_up/mc_2suit_walk_up_0016.png',
            ]
        },
        LEFT_IDLE: {
            idleFrame: true,
            xDir: -1,
            yDir: -1,
            scaleX: -1,
            texture: 'char1/c1_up/mc_2suit_idle_up.png',
        },
        LEFT: {
            xDir: -1,
            yDir: -1,
            scaleX: -1,
            texture: 'char1/c1_up/mc_2suit_walk_up_0001.png',
            animFrames: [
                'char1/c1_up/mc_2suit_walk_up_0001.png',
                'char1/c1_up/mc_2suit_walk_up_0002.png',
                'char1/c1_up/mc_2suit_walk_up_0003.png',
                'char1/c1_up/mc_2suit_walk_up_0004.png',
                'char1/c1_up/mc_2suit_walk_up_0005.png',
                'char1/c1_up/mc_2suit_walk_up_0006.png',
                'char1/c1_up/mc_2suit_walk_up_0007.png',
                'char1/c1_up/mc_2suit_walk_up_0008.png',
                'char1/c1_up/mc_2suit_walk_up_0009.png',
                'char1/c1_up/mc_2suit_walk_up_0010.png',
                'char1/c1_up/mc_2suit_walk_up_0011.png',
                'char1/c1_up/mc_2suit_walk_up_0012.png',
                'char1/c1_up/mc_2suit_walk_up_0013.png',
                'char1/c1_up/mc_2suit_walk_up_0014.png',
                'char1/c1_up/mc_2suit_walk_up_0015.png',
                'char1/c1_up/mc_2suit_walk_up_0016.png',
            ]
        },
        DOWN_IDLE: {
            idleFrame: true,
            xDir: -1,
            yDir: 1,
            scaleX: -1,
            texture: 'char1/c1_down/mc_2suit_idle_down.png',
        },
        Down: {
            xDir: -1,
            yDir: 1,
            scaleX: -1,
            texture: 'char1/c1_down/mc_2suit_walk_down_0001.png',
            animFrames: [
                'char1/c1_down/mc_2suit_walk_down_0001.png',
                'char1/c1_down/mc_2suit_walk_down_0002.png',
                'char1/c1_down/mc_2suit_walk_down_0003.png',
                'char1/c1_down/mc_2suit_walk_down_0004.png',
                'char1/c1_down/mc_2suit_walk_down_0005.png',
                'char1/c1_down/mc_2suit_walk_down_0006.png',
                'char1/c1_down/mc_2suit_walk_down_0007.png',
                'char1/c1_down/mc_2suit_walk_down_0008.png',
                'char1/c1_down/mc_2suit_walk_down_0009.png',
                'char1/c1_down/mc_2suit_walk_down_0010.png',
                'char1/c1_down/mc_2suit_walk_down_0011.png',
                'char1/c1_down/mc_2suit_walk_down_0012.png',
                'char1/c1_down/mc_2suit_walk_down_0013.png',
                'char1/c1_down/mc_2suit_walk_down_0014.png',
                'char1/c1_down/mc_2suit_walk_down_0015.png',
                'char1/c1_down/mc_2suit_walk_down_0016.png',
            ]
        },
        RIGHT_IDLE: {
            idleFrame: true,
            xDir: 1,
            yDir: 1,
            texture: 'char1/c1_down/mc_2suit_idle_down.png',
        },
        Right: {
            xDir: 1,
            yDir: 1,
            texture: 'char1/c1_down/mc_2suit_walk_down_0001.png',
            animFrames: [
                'char1/c1_down/mc_2suit_walk_down_0001.png',
                'char1/c1_down/mc_2suit_walk_down_0002.png',
                'char1/c1_down/mc_2suit_walk_down_0003.png',
                'char1/c1_down/mc_2suit_walk_down_0004.png',
                'char1/c1_down/mc_2suit_walk_down_0005.png',
                'char1/c1_down/mc_2suit_walk_down_0006.png',
                'char1/c1_down/mc_2suit_walk_down_0007.png',
                'char1/c1_down/mc_2suit_walk_down_0008.png',
                'char1/c1_down/mc_2suit_walk_down_0009.png',
                'char1/c1_down/mc_2suit_walk_down_0010.png',
                'char1/c1_down/mc_2suit_walk_down_0011.png',
                'char1/c1_down/mc_2suit_walk_down_0012.png',
                'char1/c1_down/mc_2suit_walk_down_0013.png',
                'char1/c1_down/mc_2suit_walk_down_0014.png',
                'char1/c1_down/mc_2suit_walk_down_0015.png',
                'char1/c1_down/mc_2suit_walk_down_0016.png',
            ]
        },
    }
}

export const MAIN_CHARACTER_2_SUIT = {
    ANIM_SPEED: 0.5,
    SPRITES: {
        STILL: {
            xDir: 0,
            yDir: 0,
            // scaleX: -1,
            texture: 'char2/c2_down/mc_4suit_idle_down.png',
        },
        UP_IDLE: {
            idleFrame: true,
            xDir: 1,
            yDir: -1,
            texture: 'char2/c2_up/mc_4suit_idle_up.png',
        },
        UP: {
            xDir: 1,
            yDir: -1,
            texture: 'char2/c2_up/mc_4suit_walk_up_0001.png',
            animFrames: [
                'char2/c2_up/mc_4suit_walk_up_0001.png',
                'char2/c2_up/mc_4suit_walk_up_0002.png',
                'char2/c2_up/mc_4suit_walk_up_0003.png',
                'char2/c2_up/mc_4suit_walk_up_0004.png',
                'char2/c2_up/mc_4suit_walk_up_0005.png',
                'char2/c2_up/mc_4suit_walk_up_0006.png',
                'char2/c2_up/mc_4suit_walk_up_0007.png',
                'char2/c2_up/mc_4suit_walk_up_0008.png',
                'char2/c2_up/mc_4suit_walk_up_0009.png',
                'char2/c2_up/mc_4suit_walk_up_0010.png',
                'char2/c2_up/mc_4suit_walk_up_0011.png',
                'char2/c2_up/mc_4suit_walk_up_0012.png',
                'char2/c2_up/mc_4suit_walk_up_0013.png',
                'char2/c2_up/mc_4suit_walk_up_0014.png',
                'char2/c2_up/mc_4suit_walk_up_0015.png',
                'char2/c2_up/mc_4suit_walk_up_0016.png',
            ]
        },
        LEFT_IDLE: {
            idleFrame: true,
            xDir: -1,
            yDir: -1,
            scaleX: -1,
            texture: 'char2/c2_up/mc_4suit_idle_up.png',
        },
        LEFT: {
            xDir: -1,
            yDir: -1,
            scaleX: -1,
            texture: 'char2/c2_up/mc_4suit_walk_up_0001.png',
            animFrames: [
                'char2/c2_up/mc_4suit_walk_up_0001.png',
                'char2/c2_up/mc_4suit_walk_up_0002.png',
                'char2/c2_up/mc_4suit_walk_up_0003.png',
                'char2/c2_up/mc_4suit_walk_up_0004.png',
                'char2/c2_up/mc_4suit_walk_up_0005.png',
                'char2/c2_up/mc_4suit_walk_up_0006.png',
                'char2/c2_up/mc_4suit_walk_up_0007.png',
                'char2/c2_up/mc_4suit_walk_up_0008.png',
                'char2/c2_up/mc_4suit_walk_up_0009.png',
                'char2/c2_up/mc_4suit_walk_up_0010.png',
                'char2/c2_up/mc_4suit_walk_up_0011.png',
                'char2/c2_up/mc_4suit_walk_up_0012.png',
                'char2/c2_up/mc_4suit_walk_up_0013.png',
                'char2/c2_up/mc_4suit_walk_up_0014.png',
                'char2/c2_up/mc_4suit_walk_up_0015.png',
                'char2/c2_up/mc_4suit_walk_up_0016.png',
            ]
        },
        DOWN_IDLE: {
            idleFrame: true,
            xDir: -1,
            yDir: 1,
            scaleX: -1,
            texture: 'char2/c2_down/mc_4suit_idle_down.png',
        },
        Down: {
            xDir: -1,
            yDir: 1,
            scaleX: -1,
            texture: 'char2/c2_down/mc_4suit_walk_down_0001.png',
            animFrames: [
                'char2/c2_down/mc_4suit_walk_down_0001.png',
                'char2/c2_down/mc_4suit_walk_down_0002.png',
                'char2/c2_down/mc_4suit_walk_down_0003.png',
                'char2/c2_down/mc_4suit_walk_down_0004.png',
                'char2/c2_down/mc_4suit_walk_down_0005.png',
                'char2/c2_down/mc_4suit_walk_down_0006.png',
                'char2/c2_down/mc_4suit_walk_down_0007.png',
                'char2/c2_down/mc_4suit_walk_down_0008.png',
                'char2/c2_down/mc_4suit_walk_down_0009.png',
                'char2/c2_down/mc_4suit_walk_down_0010.png',
                'char2/c2_down/mc_4suit_walk_down_0011.png',
                'char2/c2_down/mc_4suit_walk_down_0012.png',
                'char2/c2_down/mc_4suit_walk_down_0013.png',
                'char2/c2_down/mc_4suit_walk_down_0014.png',
                'char2/c2_down/mc_4suit_walk_down_0015.png',
                'char2/c2_down/mc_4suit_walk_down_0016.png',
            ]
        },
        RIGHT_IDLE: {
            idleFrame: true,
            xDir: 1,
            yDir: 1,
            texture: 'char2/c2_down/mc_4suit_idle_down.png',
        },
        Right: {
            xDir: 1,
            yDir: 1,
            texture: 'char2/c2_down/mc_4suit_walk_down_0001.png',
            animFrames: [
                'char2/c2_down/mc_4suit_walk_down_0001.png',
                'char2/c2_down/mc_4suit_walk_down_0002.png',
                'char2/c2_down/mc_4suit_walk_down_0003.png',
                'char2/c2_down/mc_4suit_walk_down_0004.png',
                'char2/c2_down/mc_4suit_walk_down_0005.png',
                'char2/c2_down/mc_4suit_walk_down_0006.png',
                'char2/c2_down/mc_4suit_walk_down_0007.png',
                'char2/c2_down/mc_4suit_walk_down_0008.png',
                'char2/c2_down/mc_4suit_walk_down_0009.png',
                'char2/c2_down/mc_4suit_walk_down_0010.png',
                'char2/c2_down/mc_4suit_walk_down_0011.png',
                'char2/c2_down/mc_4suit_walk_down_0012.png',
                'char2/c2_down/mc_4suit_walk_down_0013.png',
                'char2/c2_down/mc_4suit_walk_down_0014.png',
                'char2/c2_down/mc_4suit_walk_down_0015.png',
                'char2/c2_down/mc_4suit_walk_down_0016.png',
            ]
        },
    }
}

export const MAIN_CHARACTER_3_SUIT = {
    ANIM_SPEED: 0.5,
    SPRITES: {
        STILL: {
            xDir: 0,
            yDir: 0,
            // scaleX: -1,
            texture: 'char3/c3_down/mc_5suit_idle_down.png',
        },
        UP_IDLE: {
            idleFrame: true,
            xDir: 1,
            yDir: -1,
            texture: 'char3/c3_up/mc_5suit_idle_up.png',
        },
        UP: {
            xDir: 1,
            yDir: -1,
            texture: 'char3/c3_up/mc_5suit_walk_up_0001.png',
            animFrames: [
                'char3/c3_up/mc_5suit_walk_up_0001.png',
                'char3/c3_up/mc_5suit_walk_up_0002.png',
                'char3/c3_up/mc_5suit_walk_up_0003.png',
                'char3/c3_up/mc_5suit_walk_up_0004.png',
                'char3/c3_up/mc_5suit_walk_up_0005.png',
                'char3/c3_up/mc_5suit_walk_up_0006.png',
                'char3/c3_up/mc_5suit_walk_up_0007.png',
                'char3/c3_up/mc_5suit_walk_up_0008.png',
                'char3/c3_up/mc_5suit_walk_up_0009.png',
                'char3/c3_up/mc_5suit_walk_up_0010.png',
                'char3/c3_up/mc_5suit_walk_up_0011.png',
                'char3/c3_up/mc_5suit_walk_up_0012.png',
                'char3/c3_up/mc_5suit_walk_up_0013.png',
                'char3/c3_up/mc_5suit_walk_up_0014.png',
                'char3/c3_up/mc_5suit_walk_up_0015.png',
                'char3/c3_up/mc_5suit_walk_up_0016.png',
            ]
        },
        LEFT_IDLE: {
            idleFrame: true,
            xDir: -1,
            yDir: -1,
            scaleX: -1,
            texture: 'char3/c3_up/mc_5suit_idle_up.png',
        },
        LEFT: {
            xDir: -1,
            yDir: -1,
            scaleX: -1,
            texture: 'char3/c3_up/mc_5suit_walk_up_0001.png',
            animFrames: [
                'char3/c3_up/mc_5suit_walk_up_0001.png',
                'char3/c3_up/mc_5suit_walk_up_0002.png',
                'char3/c3_up/mc_5suit_walk_up_0003.png',
                'char3/c3_up/mc_5suit_walk_up_0004.png',
                'char3/c3_up/mc_5suit_walk_up_0005.png',
                'char3/c3_up/mc_5suit_walk_up_0006.png',
                'char3/c3_up/mc_5suit_walk_up_0007.png',
                'char3/c3_up/mc_5suit_walk_up_0008.png',
                'char3/c3_up/mc_5suit_walk_up_0009.png',
                'char3/c3_up/mc_5suit_walk_up_0010.png',
                'char3/c3_up/mc_5suit_walk_up_0011.png',
                'char3/c3_up/mc_5suit_walk_up_0012.png',
                'char3/c3_up/mc_5suit_walk_up_0013.png',
                'char3/c3_up/mc_5suit_walk_up_0014.png',
                'char3/c3_up/mc_5suit_walk_up_0015.png',
                'char3/c3_up/mc_5suit_walk_up_0016.png',
            ]
        },
        DOWN_IDLE: {
            idleFrame: true,
            xDir: -1,
            yDir: 1,
            scaleX: -1,
            texture: 'char3/c3_down/mc_5suit_idle_down.png',
        },
        Down: {
            xDir: -1,
            yDir: 1,
            scaleX: -1,
            texture: 'char3/c3_down/mc_5suit_walk_down_0001.png',
            animFrames: [
                'char3/c3_down/mc_5suit_walk_down_0001.png',
                'char3/c3_down/mc_5suit_walk_down_0002.png',
                'char3/c3_down/mc_5suit_walk_down_0003.png',
                'char3/c3_down/mc_5suit_walk_down_0004.png',
                'char3/c3_down/mc_5suit_walk_down_0005.png',
                'char3/c3_down/mc_5suit_walk_down_0006.png',
                'char3/c3_down/mc_5suit_walk_down_0007.png',
                'char3/c3_down/mc_5suit_walk_down_0008.png',
                'char3/c3_down/mc_5suit_walk_down_0009.png',
                'char3/c3_down/mc_5suit_walk_down_0010.png',
                'char3/c3_down/mc_5suit_walk_down_0011.png',
                'char3/c3_down/mc_5suit_walk_down_0012.png',
                'char3/c3_down/mc_5suit_walk_down_0013.png',
                'char3/c3_down/mc_5suit_walk_down_0014.png',
                'char3/c3_down/mc_5suit_walk_down_0015.png',
                'char3/c3_down/mc_5suit_walk_down_0016.png',
            ]
        },
        RIGHT_IDLE: {
            idleFrame: true,
            xDir: 1,
            yDir: 1,
            texture: 'char3/c3_down/mc_5suit_idle_down.png',
        },
        Right: {
            xDir: 1,
            yDir: 1,
            texture: 'char1/c1_down/mc_5suit_walk_down_0001.png',
            animFrames: [
                'char3/c3_down/mc_5suit_walk_down_0001.png',
                'char3/c3_down/mc_5suit_walk_down_0002.png',
                'char3/c3_down/mc_5suit_walk_down_0003.png',
                'char3/c3_down/mc_5suit_walk_down_0004.png',
                'char3/c3_down/mc_5suit_walk_down_0005.png',
                'char3/c3_down/mc_5suit_walk_down_0006.png',
                'char3/c3_down/mc_5suit_walk_down_0007.png',
                'char3/c3_down/mc_5suit_walk_down_0008.png',
                'char3/c3_down/mc_5suit_walk_down_0009.png',
                'char3/c3_down/mc_5suit_walk_down_0010.png',
                'char3/c3_down/mc_5suit_walk_down_0011.png',
                'char3/c3_down/mc_5suit_walk_down_0012.png',
                'char3/c3_down/mc_5suit_walk_down_0013.png',
                'char3/c3_down/mc_5suit_walk_down_0014.png',
                'char3/c3_down/mc_5suit_walk_down_0015.png',
                'char3/c3_down/mc_5suit_walk_down_0016.png',
            ]
        },
    }
}

export const MAIN_CHARACTER_4_SUIT = {
    ANIM_SPEED: 0.5,
    SPRITES: {
        STILL: {
            xDir: 0,
            yDir: 0,
            // scaleX: -1,
            texture: 'char4/c4_down/mc_1suit_idle_down.png',
        },
        UP_IDLE: {
            idleFrame: true,
            xDir: 1,
            yDir: -1,
            texture: 'char4/c4_up/mc_1suit_idle_up.png',
        },
        UP: {
            xDir: 1,
            yDir: -1,
            texture: 'char4/c4_up/mc_1suit_walk_up_0001.png',
            animFrames: [
                'char4/c4_up/mc_1suit_walk_up_0001.png',
                'char4/c4_up/mc_1suit_walk_up_0002.png',
                'char4/c4_up/mc_1suit_walk_up_0003.png',
                'char4/c4_up/mc_1suit_walk_up_0004.png',
                'char4/c4_up/mc_1suit_walk_up_0005.png',
                'char4/c4_up/mc_1suit_walk_up_0006.png',
                'char4/c4_up/mc_1suit_walk_up_0007.png',
                'char4/c4_up/mc_1suit_walk_up_0008.png',
                'char4/c4_up/mc_1suit_walk_up_0009.png',
                'char4/c4_up/mc_1suit_walk_up_0010.png',
                'char4/c4_up/mc_1suit_walk_up_0011.png',
                'char4/c4_up/mc_1suit_walk_up_0012.png',
                'char4/c4_up/mc_1suit_walk_up_0013.png',
                'char4/c4_up/mc_1suit_walk_up_0014.png',
                'char4/c4_up/mc_1suit_walk_up_0015.png',
                'char4/c4_up/mc_1suit_walk_up_0016.png',
            ]
        },
        LEFT_IDLE: {
            idleFrame: true,
            xDir: -1,
            yDir: -1,
            scaleX: -1,
            texture: 'char4/c4_up/mc_1suit_idle_up.png',
        },
        LEFT: {
            xDir: -1,
            yDir: -1,
            scaleX: -1,
            texture: 'char4/c4_up/mc_1suit_walk_up_0001.png',
            animFrames: [
                'char4/c4_up/mc_1suit_walk_up_0001.png',
                'char4/c4_up/mc_1suit_walk_up_0002.png',
                'char4/c4_up/mc_1suit_walk_up_0003.png',
                'char4/c4_up/mc_1suit_walk_up_0004.png',
                'char4/c4_up/mc_1suit_walk_up_0005.png',
                'char4/c4_up/mc_1suit_walk_up_0006.png',
                'char4/c4_up/mc_1suit_walk_up_0007.png',
                'char4/c4_up/mc_1suit_walk_up_0008.png',
                'char4/c4_up/mc_1suit_walk_up_0009.png',
                'char4/c4_up/mc_1suit_walk_up_0010.png',
                'char4/c4_up/mc_1suit_walk_up_0011.png',
                'char4/c4_up/mc_1suit_walk_up_0012.png',
                'char4/c4_up/mc_1suit_walk_up_0013.png',
                'char4/c4_up/mc_1suit_walk_up_0014.png',
                'char4/c4_up/mc_1suit_walk_up_0015.png',
                'char4/c4_up/mc_1suit_walk_up_0016.png',
            ]
        },
        DOWN_IDLE: {
            idleFrame: true,
            xDir: -1,
            yDir: 1,
            scaleX: -1,
            texture: 'char4/c4_down/mc_1suit_idle_down.png',
        },
        Down: {
            xDir: -1,
            yDir: 1,
            scaleX: -1,
            texture: 'char4/c4_down/mc_1suit_walk_down_0001.png',
            animFrames: [
                'char4/c4_down/mc_1suit_walk_down_0001.png',
                'char4/c4_down/mc_1suit_walk_down_0002.png',
                'char4/c4_down/mc_1suit_walk_down_0003.png',
                'char4/c4_down/mc_1suit_walk_down_0004.png',
                'char4/c4_down/mc_1suit_walk_down_0005.png',
                'char4/c4_down/mc_1suit_walk_down_0006.png',
                'char4/c4_down/mc_1suit_walk_down_0007.png',
                'char4/c4_down/mc_1suit_walk_down_0008.png',
                'char4/c4_down/mc_1suit_walk_down_0009.png',
                'char4/c4_down/mc_1suit_walk_down_0010.png',
                'char4/c4_down/mc_1suit_walk_down_0011.png',
                'char4/c4_down/mc_1suit_walk_down_0012.png',
                'char4/c4_down/mc_1suit_walk_down_0013.png',
                'char4/c4_down/mc_1suit_walk_down_0014.png',
                'char4/c4_down/mc_1suit_walk_down_0015.png',
                'char4/c4_down/mc_1suit_walk_down_0016.png',
            ]
        },
        RIGHT_IDLE: {
            idleFrame: true,
            xDir: 1,
            yDir: 1,
            texture: 'char4/c4_down/mc_1suit_idle_down.png',
        },
        Right: {
            xDir: 1,
            yDir: 1,
            texture: 'char4/c4_down/mc_1suit_walk_down_0001.png',
            animFrames: [
                'char4/c4_down/mc_1suit_walk_down_0001.png',
                'char4/c4_down/mc_1suit_walk_down_0002.png',
                'char4/c4_down/mc_1suit_walk_down_0003.png',
                'char4/c4_down/mc_1suit_walk_down_0004.png',
                'char4/c4_down/mc_1suit_walk_down_0005.png',
                'char4/c4_down/mc_1suit_walk_down_0006.png',
                'char4/c4_down/mc_1suit_walk_down_0007.png',
                'char4/c4_down/mc_1suit_walk_down_0008.png',
                'char4/c4_down/mc_1suit_walk_down_0009.png',
                'char4/c4_down/mc_1suit_walk_down_0010.png',
                'char4/c4_down/mc_1suit_walk_down_0011.png',
                'char4/c4_down/mc_1suit_walk_down_0012.png',
                'char4/c4_down/mc_1suit_walk_down_0013.png',
                'char4/c4_down/mc_1suit_walk_down_0014.png',
                'char4/c4_down/mc_1suit_walk_down_0015.png',
                'char4/c4_down/mc_1suit_walk_down_0016.png',
            ]
        },
    }
}

export const MAIN_CHARACTER_5_SUIT = {
    ANIM_SPEED: 0.5,
    Y_OFFSET: 60,
    X_OFFSET: 0,
    HILIGHT_Y_OFFSET: 28,
    SPRITES: {
        STILL: {
            xDir: 0,
            yDir: 0,
            // scaleX: -1,
            texture: 'char5/c5_down/mc_3suit_idle_down.png',
        },
        UP_IDLE: {
            idleFrame: true,
            xDir: 1,
            yDir: -1,
            texture: 'char5/c5_up/mc_3suit_idle_up.png',
        },
        UP: {
            xDir: 1,
            yDir: -1,
            texture: 'char5/c5_up/mc_3suit_walk_up_0001.png',
            animFrames: [
                'char5/c5_up/mc_3suit_walk_up_0001.png',
                'char5/c5_up/mc_3suit_walk_up_0002.png',
                'char5/c5_up/mc_3suit_walk_up_0003.png',
                'char5/c5_up/mc_3suit_walk_up_0004.png',
                'char5/c5_up/mc_3suit_walk_up_0005.png',
                'char5/c5_up/mc_3suit_walk_up_0006.png',
                'char5/c5_up/mc_3suit_walk_up_0007.png',
                'char5/c5_up/mc_3suit_walk_up_0008.png',
                'char5/c5_up/mc_3suit_walk_up_0009.png',
                'char5/c5_up/mc_3suit_walk_up_0010.png',
                'char5/c5_up/mc_3suit_walk_up_0011.png',
                'char5/c5_up/mc_3suit_walk_up_0012.png',
                'char5/c5_up/mc_3suit_walk_up_0013.png',
                'char5/c5_up/mc_3suit_walk_up_0014.png',
                'char5/c5_up/mc_3suit_walk_up_0015.png',
                'char5/c5_up/mc_3suit_walk_up_0016.png',
            ]
        },
        LEFT_IDLE: {
            idleFrame: true,
            xDir: -1,
            yDir: -1,
            scaleX: -1,
            texture: 'char5/c5_up/mc_3suit_idle_up.png',
        },
        LEFT: {
            xDir: -1,
            yDir: -1,
            scaleX: -1,
            texture: 'char5/c5_up/mc_3suit_walk_up_0001.png',
            animFrames: [
                'char5/c5_up/mc_3suit_walk_up_0001.png',
                'char5/c5_up/mc_3suit_walk_up_0002.png',
                'char5/c5_up/mc_3suit_walk_up_0003.png',
                'char5/c5_up/mc_3suit_walk_up_0004.png',
                'char5/c5_up/mc_3suit_walk_up_0005.png',
                'char5/c5_up/mc_3suit_walk_up_0006.png',
                'char5/c5_up/mc_3suit_walk_up_0007.png',
                'char5/c5_up/mc_3suit_walk_up_0008.png',
                'char5/c5_up/mc_3suit_walk_up_0009.png',
                'char5/c5_up/mc_3suit_walk_up_0010.png',
                'char5/c5_up/mc_3suit_walk_up_0011.png',
                'char5/c5_up/mc_3suit_walk_up_0012.png',
                'char5/c5_up/mc_3suit_walk_up_0013.png',
                'char5/c5_up/mc_3suit_walk_up_0014.png',
                'char5/c5_up/mc_3suit_walk_up_0015.png',
                'char5/c5_up/mc_3suit_walk_up_0016.png',
            ]
        },
        DOWN_IDLE: {
            idleFrame: true,
            xDir: -1,
            yDir: 1,
            scaleX: -1,
            texture: 'char5/c5_down/mc_3suit_idle_down.png',
        },
        Down: {
            xDir: -1,
            yDir: 1,
            scaleX: -1,
            texture: 'char3/c3_down/mc_5_walk_down_0001.png',
            animFrames: [
                'char5/c5_down/mc_3suit_walk_down_0001.png',
                'char5/c5_down/mc_3suit_walk_down_0002.png',
                'char5/c5_down/mc_3suit_walk_down_0003.png',
                'char5/c5_down/mc_3suit_walk_down_0004.png',
                'char5/c5_down/mc_3suit_walk_down_0005.png',
                'char5/c5_down/mc_3suit_walk_down_0006.png',
                'char5/c5_down/mc_3suit_walk_down_0007.png',
                'char5/c5_down/mc_3suit_walk_down_0008.png',
                'char5/c5_down/mc_3suit_walk_down_0009.png',
                'char5/c5_down/mc_3suit_walk_down_0010.png',
                'char5/c5_down/mc_3suit_walk_down_0011.png',
                'char5/c5_down/mc_3suit_walk_down_0012.png',
                'char5/c5_down/mc_3suit_walk_down_0013.png',
                'char5/c5_down/mc_3suit_walk_down_0014.png',
                'char5/c5_down/mc_3suit_walk_down_0015.png',
                'char5/c5_down/mc_3suit_walk_down_0016.png',
            ]
        },
        RIGHT_IDLE: {
            idleFrame: true,
            xDir: 1,
            yDir: 1,
            texture: 'char5/c5_down/mc_3suit_idle_down.png',
        },
        Right: {
            xDir: 1,
            yDir: 1,
            texture: 'char5/c5_down/mc_3suit_walk_down_0001.png',
            animFrames: [
                'char5/c5_down/mc_3suit_walk_down_0001.png',
                'char5/c5_down/mc_3suit_walk_down_0002.png',
                'char5/c5_down/mc_3suit_walk_down_0003.png',
                'char5/c5_down/mc_3suit_walk_down_0004.png',
                'char5/c5_down/mc_3suit_walk_down_0005.png',
                'char5/c5_down/mc_3suit_walk_down_0006.png',
                'char5/c5_down/mc_3suit_walk_down_0007.png',
                'char5/c5_down/mc_3suit_walk_down_0008.png',
                'char5/c5_down/mc_3suit_walk_down_0009.png',
                'char5/c5_down/mc_3suit_walk_down_0010.png',
                'char5/c5_down/mc_3suit_walk_down_0011.png',
                'char5/c5_down/mc_3suit_walk_down_0012.png',
                'char5/c5_down/mc_3suit_walk_down_0013.png',
                'char5/c5_down/mc_3suit_walk_down_0014.png',
                'char5/c5_down/mc_3suit_walk_down_0015.png',
                'char5/c5_down/mc_3suit_walk_down_0016.png',
            ]
        },
    }
}

export const NPC_TEMP = {
    SPRITES: {
        STILL: {
            xDir: 0,
            yDir: 0,
            texture: 'npc-1.png',
            animFrames: [
                'npc-1.png',
            ]
        }
    }
}

export const YOUNG_PERSON = {
    ANIM_SPEED: 0.5,
    SPRITES: {
        STILL: {
            xDir: 0,
            yDir: 0,
            texture: 'npcs/youngperson_idle.png',
            /*
            animFrames: [
                'npc-1.png',
            ]
            */
        },
        UP_IDLE: {
            idleFrame: true,
            xDir: 1,
            yDir: -1,
            texture: 'npcs/young_person/up/yp_idle_up.png',
        },
        UP: {
            xDir: 1,
            yDir: -1,
            texture: 'npcs/young_person/up/yp_walk_up_0001.png',
            animFrames: [
                'npcs/young_person/up/yp_walk_up_0001.png',
                'npcs/young_person/up/yp_walk_up_0002.png',
                'npcs/young_person/up/yp_walk_up_0003.png',
                'npcs/young_person/up/yp_walk_up_0004.png',
                'npcs/young_person/up/yp_walk_up_0005.png',
                'npcs/young_person/up/yp_walk_up_0006.png',
                'npcs/young_person/up/yp_walk_up_0007.png',
                'npcs/young_person/up/yp_walk_up_0008.png',
                'npcs/young_person/up/yp_walk_up_0009.png',
                'npcs/young_person/up/yp_walk_up_0010.png',
                'npcs/young_person/up/yp_walk_up_0011.png',
                'npcs/young_person/up/yp_walk_up_0012.png',
                'npcs/young_person/up/yp_walk_up_0013.png',
                'npcs/young_person/up/yp_walk_up_0014.png',
                'npcs/young_person/up/yp_walk_up_0015.png',
                'npcs/young_person/up/yp_walk_up_0016.png',
            ]
        },
        LEFT_IDLE: {
            idleFrame: true,
            xDir: -1,
            yDir: -1,
            scaleX: -1,
            texture: 'npcs/young_person/up/yp_idle_up.png',
        },
        LEFT: {
            xDir: -1,
            yDir: -1,
            scaleX: -1,
            texture: 'npcs/young_person/up/yp_walk_up_0001.png',
            animFrames: [
                'npcs/young_person/up/yp_walk_up_0001.png',
                'npcs/young_person/up/yp_walk_up_0002.png',
                'npcs/young_person/up/yp_walk_up_0003.png',
                'npcs/young_person/up/yp_walk_up_0004.png',
                'npcs/young_person/up/yp_walk_up_0005.png',
                'npcs/young_person/up/yp_walk_up_0006.png',
                'npcs/young_person/up/yp_walk_up_0007.png',
                'npcs/young_person/up/yp_walk_up_0008.png',
                'npcs/young_person/up/yp_walk_up_0009.png',
                'npcs/young_person/up/yp_walk_up_0010.png',
                'npcs/young_person/up/yp_walk_up_0011.png',
                'npcs/young_person/up/yp_walk_up_0012.png',
                'npcs/young_person/up/yp_walk_up_0013.png',
                'npcs/young_person/up/yp_walk_up_0014.png',
                'npcs/young_person/up/yp_walk_up_0015.png',
                'npcs/young_person/up/yp_walk_up_0016.png',
            ]
        },
        DOWN_IDLE: {
            idleFrame: true,
            xDir: -1,
            yDir: 1,
            scaleX: -1,
            texture: 'npcs/young_person/down/yp_idle_down.png',
        },
        Down: {
            xDir: -1,
            yDir: 1,
            scaleX: -1,
            texture: 'npcs/young_person/down/yp_walk_down_0001.png',
            animFrames: [
                'npcs/young_person/down/yp_walk_down_0001.png',
                'npcs/young_person/down/yp_walk_down_0002.png',
                'npcs/young_person/down/yp_walk_down_0003.png',
                'npcs/young_person/down/yp_walk_down_0004.png',
                'npcs/young_person/down/yp_walk_down_0005.png',
                'npcs/young_person/down/yp_walk_down_0006.png',
                'npcs/young_person/down/yp_walk_down_0007.png',
                'npcs/young_person/down/yp_walk_down_0008.png',
                'npcs/young_person/down/yp_walk_down_0009.png',
                'npcs/young_person/down/yp_walk_down_0010.png',
                'npcs/young_person/down/yp_walk_down_0011.png',
                'npcs/young_person/down/yp_walk_down_0012.png',
                'npcs/young_person/down/yp_walk_down_0013.png',
                'npcs/young_person/down/yp_walk_down_0014.png',
                'npcs/young_person/down/yp_walk_down_0015.png',
                'npcs/young_person/down/yp_walk_down_0016.png',
            ]
        },
        RIGHT_IDLE: {
            idleFrame: true,
            xDir: 1,
            yDir: 1,
            texture: 'npcs/young_person/down/yp_idle_down.png',
        },
        Right: {
            xDir: 1,
            yDir: 1,
            texture: 'npcs/young_person/down/yp_walk_down_0001.png',
            animFrames: [
                'npcs/young_person/down/yp_walk_down_0001.png',
                'npcs/young_person/down/yp_walk_down_0002.png',
                'npcs/young_person/down/yp_walk_down_0003.png',
                'npcs/young_person/down/yp_walk_down_0004.png',
                'npcs/young_person/down/yp_walk_down_0005.png',
                'npcs/young_person/down/yp_walk_down_0006.png',
                'npcs/young_person/down/yp_walk_down_0007.png',
                'npcs/young_person/down/yp_walk_down_0008.png',
                'npcs/young_person/down/yp_walk_down_0009.png',
                'npcs/young_person/down/yp_walk_down_0010.png',
                'npcs/young_person/down/yp_walk_down_0011.png',
                'npcs/young_person/down/yp_walk_down_0012.png',
                'npcs/young_person/down/yp_walk_down_0013.png',
                'npcs/young_person/down/yp_walk_down_0014.png',
                'npcs/young_person/down/yp_walk_down_0015.png',
                'npcs/young_person/down/yp_walk_down_0016.png',
            ]
        },
    }
}

export const WALKER_1 = {
    ANIM_SPEED: 0.5,
    SPRITES: {
        STILL: {
            xDir: 0,
            yDir: 0,
            // scaleX: -1,
            texture: 'npcs/walks/nw1/down/mc_1_idle_down.png',
        },
        UP_IDLE: {
            idleFrame: true,
            xDir: 1,
            yDir: -1,
            texture: 'npcs/walks/nw1/up/mc_1_idle_up.png',
        },
        UP: {
            xDir: 1,
            yDir: -1,
            texture: 'npcs/walks/nw1/up/mc_1_walk_up_0001.png',
            animFrames: [
                'npcs/walks/nw1/up/mc_1_walk_up_0001.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0002.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0003.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0004.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0005.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0006.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0007.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0008.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0009.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0010.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0011.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0012.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0013.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0014.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0015.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0016.png',
            ]
        },
        LEFT_IDLE: {
            idleFrame: true,
            xDir: -1,
            yDir: -1,
            scaleX: -1,
            texture: 'npcs/walks/nw1/up/mc_1_idle_up.png',
        },
        LEFT: {
            xDir: -1,
            yDir: -1,
            scaleX: -1,
            texture: 'npcs/walks/nw1/up/mc_1_walk_up_0001.png',
            animFrames: [
                'npcs/walks/nw1/up/mc_1_walk_up_0001.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0002.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0003.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0004.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0005.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0006.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0007.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0008.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0009.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0010.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0011.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0012.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0013.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0014.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0015.png',
                'npcs/walks/nw1/up/mc_1_walk_up_0016.png',
            ]
        },
        DOWN_IDLE: {
            idleFrame: true,
            xDir: -1,
            yDir: 1,
            scaleX: -1,
            texture: 'npcs/walks/nw1/down/mc_1_idle_down.png',
        },
        Down: {
            xDir: -1,
            yDir: 1,
            scaleX: -1,
            texture: 'npcs/walks/nw1/down/mc_1_walk_down_0001.png',
            animFrames: [
                'npcs/walks/nw1/down/mc_1_walk_down_0001.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0002.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0003.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0004.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0005.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0006.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0007.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0008.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0009.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0010.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0011.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0012.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0013.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0014.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0015.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0016.png',
            ]
        },
        RIGHT_IDLE: {
            idleFrame: true,
            xDir: 1,
            yDir: 1,
            texture: 'npcs/walks/nw1/down/mc_1_idle_down.png',
        },
        Right: {
            xDir: 1,
            yDir: 1,
            texture: 'npcs/walks/nw1/down/mc_1_walk_down_0001.png',
            animFrames: [
                'npcs/walks/nw1/down/mc_1_walk_down_0001.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0002.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0003.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0004.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0005.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0006.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0007.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0008.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0009.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0010.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0011.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0012.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0013.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0014.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0015.png',
                'npcs/walks/nw1/down/mc_1_walk_down_0016.png',
            ]
        },
    }
}

export const WALKER_2 = {
    ANIM_SPEED: 0.5,
    SPRITES: {
        STILL: {
            xDir: 0,
            yDir: 0,
            // scaleX: -1,
            texture: 'npcs/walks/nw2/down/mc_4_idle_down.png',
        },
        UP_IDLE: {
            idleFrame: true,
            xDir: 1,
            yDir: -1,
            texture: 'npcs/walks/nw2/up/mc_4_idle_up.png',
        },
        UP: {
            xDir: 1,
            yDir: -1,
            texture: 'npcs/walks/nw2/up/mc_4_walk_up_0001.png',
            animFrames: [
                'npcs/walks/nw2/up/mc_4_walk_up_0001.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0002.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0003.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0004.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0005.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0006.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0007.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0008.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0009.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0010.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0011.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0012.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0013.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0014.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0015.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0016.png',
            ]
        },
        LEFT_IDLE: {
            idleFrame: true,
            xDir: -1,
            yDir: -1,
            scaleX: -1,
            texture: 'npcs/walks/nw2/up/mc_4_idle_up.png',
        },
        LEFT: {
            xDir: -1,
            yDir: -1,
            scaleX: -1,
            texture: 'npcs/walks/nw2/up/mc_4_walk_up_0001.png',
            animFrames: [
                'npcs/walks/nw2/up/mc_4_walk_up_0001.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0002.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0003.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0004.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0005.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0006.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0007.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0008.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0009.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0010.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0011.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0012.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0013.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0014.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0015.png',
                'npcs/walks/nw2/up/mc_4_walk_up_0016.png',
            ]
        },
        DOWN_IDLE: {
            idleFrame: true,
            xDir: -1,
            yDir: 1,
            scaleX: -1,
            texture: 'npcs/walks/nw2/down/mc_4_idle_down.png',
        },
        Down: {
            xDir: -1,
            yDir: 1,
            scaleX: -1,
            texture: 'npcs/walks/nw2/down/mc_4_walk_down_0001.png',
            animFrames: [
                'npcs/walks/nw2/down/mc_4_walk_down_0001.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0002.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0003.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0004.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0005.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0006.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0007.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0008.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0009.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0010.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0011.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0012.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0013.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0014.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0015.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0016.png',
            ]
        },
        RIGHT_IDLE: {
            idleFrame: true,
            xDir: 1,
            yDir: 1,
            texture: 'npcs/walks/nw2/down/mc_4_idle_down.png',
        },
        Right: {
            xDir: 1,
            yDir: 1,
            texture: 'npcs/walks/nw2/down/mc_4_walk_down_0001.png',
            animFrames: [
                'npcs/walks/nw2/down/mc_4_walk_down_0001.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0002.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0003.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0004.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0005.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0006.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0007.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0008.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0009.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0010.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0011.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0012.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0013.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0014.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0015.png',
                'npcs/walks/nw2/down/mc_4_walk_down_0016.png',
            ]
        },
    }
}

export const WALKER_3 = {
    ANIM_SPEED: 0.5,
    SPRITES: {
        STILL: {
            xDir: 0,
            yDir: 0,
            // scaleX: -1,
            texture: 'npcs/walks/nw3/down/mc_5_idle_down.png',
        },
        UP_IDLE: {
            idleFrame: true,
            xDir: 1,
            yDir: -1,
            texture: 'npcs/walks/nw3/up/mc_5_idle_up.png',
        },
        UP: {
            xDir: 1,
            yDir: -1,
            texture: 'npcs/walks/nw3/up/mc_5_walk_up_0001.png',
            animFrames: [
                'npcs/walks/nw3/up/mc_5_walk_up_0001.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0002.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0003.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0004.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0005.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0006.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0007.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0008.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0009.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0010.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0011.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0012.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0013.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0014.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0015.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0016.png',
            ]
        },
        LEFT_IDLE: {
            idleFrame: true,
            xDir: -1,
            yDir: -1,
            scaleX: -1,
            texture: 'npcs/walks/nw3/up/mc_5_idle_up.png',
        },
        LEFT: {
            xDir: -1,
            yDir: -1,
            scaleX: -1,
            texture: 'npcs/walks/nw3/up/mc_5_walk_up_0001.png',
            animFrames: [
                'npcs/walks/nw3/up/mc_5_walk_up_0001.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0002.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0003.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0004.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0005.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0006.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0007.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0008.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0009.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0010.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0011.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0012.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0013.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0014.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0015.png',
                'npcs/walks/nw3/up/mc_5_walk_up_0016.png',
            ]
        },
        DOWN_IDLE: {
            idleFrame: true,
            xDir: -1,
            yDir: 1,
            scaleX: -1,
            texture: 'npcs/walks/nw3/down/mc_5_idle_down.png',
        },
        Down: {
            xDir: -1,
            yDir: 1,
            scaleX: -1,
            texture: 'npcs/walks/nw3/down/mc_5_walk_down_0001.png',
            animFrames: [
                'npcs/walks/nw3/down/mc_5_walk_down_0001.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0002.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0003.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0004.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0005.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0006.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0007.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0008.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0009.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0010.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0011.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0012.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0013.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0014.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0015.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0016.png',
            ]
        },
        RIGHT_IDLE: {
            idleFrame: true,
            xDir: 1,
            yDir: 1,
            texture: 'npcs/walks/nw3/down/mc_5_idle_down.png',
        },
        Right: {
            xDir: 1,
            yDir: 1,
            texture: 'npcs/walks/nw3/down/mc_5_walk_down_0001.png',
            animFrames: [
                'npcs/walks/nw3/down/mc_5_walk_down_0001.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0002.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0003.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0004.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0005.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0006.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0007.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0008.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0009.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0010.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0011.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0012.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0013.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0014.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0015.png',
                'npcs/walks/nw3/down/mc_5_walk_down_0016.png',
            ]
        },
    }
}

export const NEIGHBOUR = {
    SPRITES: {
        STILL: {
            xDir: 0,
            yDir: 0,
            texture: 'npcs/Neighbour_idle.png',
            /*
            animFrames: [
                'npc-1.png',
            ]
            */
            scaleX: -1,
        }
    }
}

export const GROUNDSKEEPER = {
    SPRITES: {
        STILL: {
            xDir: 0,
            yDir: 0,
            texture: 'npcs/groundsman_idle.png',
            /*
            animFrames: [
                'npc-1.png',
            ]
            */
        }
    }
}

export const LIGHTHOUSE_WORKER = {
    SPRITES: {
        STILL: {
            xDir: 0,
            yDir: 0,
            texture: 'npcs/lighthouseworker_idle.png',
            /*
            animFrames: [
                'npc-1.png',
            ]
            */
            scaleX: -1,
        }
    }
}

export const PLUMBER = {
    SPRITES: {
        STILL: {
            xDir: 0,
            yDir: 0,
            texture: 'npcs/rowanPlumber_idle.png',
            /*
            animFrames: [
                'npc-1.png',
            ]
            */
            scaleX: -1,
        }
    }
}

/*
export const IZZY = {
    SPRITES: {
        STILL: {
            xDir: 0,
            yDir: 0,
            texture: 'izzy_stand_front_1.png',
            animFrames: [
                'izzy_stand_front_1.png',
            ]
        },
        UP: {
            xDir: 0,
            yDir: -1,
            texture: 'izzy_stand_back_1.png',
            animFrames: [
                'izzy_stand_back_1.png',
                'izzy_stand_back_2.png',
                'izzy_stand_back_1.png',
                'izzy_stand_back_3.png',
            ]
        },
        DOWN: {
            xDir: 0,
            yDir: 1,
            texture: 'izzy_stand_front_1.png',
            animFrames: [
                'izzy_stand_front_1.png',
                'izzy_stand_front_2.png',
                'izzy_stand_front_1.png',
                'izzy_stand_front_3.png',
            ]
        },
        LEFT: {
            xDir: -1,
            yDir: 0,
            scaleX: 1,
            texture: 'izzy_stand_side_1.png',
            animFrames: [
                'izzy_stand_side_1.png',
                'izzy_stand_side_2.png',
                'izzy_stand_side_1.png',
                'izzy_stand_side_3.png',
            ]
        },
        RIGHT: {
            xDir: 1,
            yDir: 0,
            scaleX: -1,
            texture: 'izzy_stand_side_1.png',
            animFrames: [
                'izzy_stand_side_1.png',
                'izzy_stand_side_2.png',
                'izzy_stand_side_1.png',
                'izzy_stand_side_3.png',
            ]
        }
    }
}

export const KLOOS = {
    SPRITES: {
        STILL: {
            xDir: 0,
            yDir: 0,
            texture: 'sit/front/kloos_sit_front_01.png',
            animFrames: [
                'sit/front/kloos_sit_front_01.png',
                'sit/front/kloos_sit_front_02.png',
                'sit/front/kloos_sit_front_03.png',
                'sit/front/kloos_sit_front_04.png',
            ]
        },
        UP: {
            xDir: 0,
            yDir: -1,
            texture: 'run/side/kloos_run_side_01.png',
            animFrames: [
                'run/side/kloos_run_side_01.png',
                'run/side/kloos_run_side_02.png',
                'run/side/kloos_run_side_03.png',
                'run/side/kloos_run_side_04.png',
            ]
        },
        DOWN: {
            xDir: 0,
            yDir: 1,
            texture: 'run/front/kloos_run_front_01.png',
            animFrames: [
                'run/front/kloos_run_front_01.png',
                'run/front/kloos_run_front_02.png',
                'run/front/kloos_run_front_03.png',
                'run/front/kloos_run_front_04.png',
            ]
        },
        LEFT: {
            xDir: -1,
            yDir: 0,
            scaleX: 1,
            texture: 'run/side/kloos_run_side_01.png',
            animFrames: [
                'run/side/kloos_run_side_01.png',
                'run/side/kloos_run_side_02.png',
                'run/side/kloos_run_side_03.png',
                'run/side/kloos_run_side_04.png',
            ]
        },
        RIGHT: {
            xDir: 1,
            yDir: 0,
            scaleX: -1,
            texture: 'run/side/kloos_run_side_01.png',
            animFrames: [
                'run/side/kloos_run_side_01.png',
                'run/side/kloos_run_side_02.png',
                'run/side/kloos_run_side_03.png',
                'run/side/kloos_run_side_04.png',
            ]
        }
    }
}

export const PETE = {
    SPRITES: {
        STILL: {
            xDir: 0,
            yDir: 0,
            texture: 'pete_stand_front_1.png',
            animFrames: ['pete_stand_front_1.png',],
        },
        UP: {
            xDir: 0,
            yDir: -1,
            texture: 'pete_stand_back_1.png',
            animFrames: ['pete_stand_back_1.png',],
        },
        DOWN: {
            xDir: 0,
            yDir: 1,
            texture: 'pete_stand_front_1.png',
            animFrames: ['pete_stand_front_1.png',]
        },
        LEFT: {
            xDir: -1,
            yDir: 0,
            scaleX: 1,
            texture: 'pete_stand_side_1.png',
            animFrames: ['pete_stand_side_1.png',],
        },
        RIGHT: {
            xDir: 1,
            yDir: 0,
            scaleX: -1,
            texture: 'pete_stand_side_1.png',
            animFrames: ['pete_stand_side_1.png',],
        },
    }
}

export const PETE_KID_1 = {
    SPRITES: {
        STILL: {
            xDir: 0,
            yDir: 0,
            texture: 'petekid1_stand_front_1.png',
        },
        UP: {
            xDir: 0,
            yDir: -1,
            texture: 'petekid1_stand_back_1.png',
        },
        DOWN: {
            xDir: 0,
            yDir: 1,
            texture: 'petekid1_stand_front_1.png',
        },
        LEFT: {
            xDir: -1,
            yDir: 0,
            scaleX: 1,
            texture: 'petekid1_stand_side_1.png'
        },
        RIGHT: {
            xDir: 1,
            yDir: 0,
            scaleX: -1,
            texture: 'petekid1_stand_side_1.png'
        },
    }
}

export const PETE_KID_2 = {
    SPRITES: {
        STILL: {
            xDir: 0,
            yDir: 0,
            texture: 'petekid2_stand_front_1.png',
        },
        UP: {
            xDir: 0,
            yDir: -1,
            texture: 'petekid2_stand_back_1.png',
        },
        DOWN: {
            xDir: 0,
            yDir: 1,
            texture: 'petekid2_stand_front_1.png',
        },
        LEFT: {
            xDir: -1,
            yDir: 0,
            scaleX: 1,
            texture: 'petekid2_stand_side_1.png'
        },
        RIGHT: {
            xDir: 1,
            yDir: 0,
            scaleX: -1,
            texture: 'petekid2_stand_side_1.png'
        },
    }
}

export const FLY_OFF_PIGEON = {
    SPRITES: {
        STILL: {
            xDir: 0,
            yDir: 0,
            texture: 'bird_takeoff_0001.png',
            animFrames: [
                'bird_takeoff_0001.png',
                'bird_takeoff_0002.png',
                'bird_takeoff_0003.png',
                'bird_takeoff_0004.png',
            ]
        }
    }
}

/*
export const KLOOS2 = {
    SPRITES: {
        STILL: {
            xDir: 0,
            yDir: 0,
            texture: 'sit/front/kloos_sit_front_01.png',
            animFrames: [
                'sit/front/kloos_sit_front_01.png',
                'sit/front/kloos_sit_front_02.png',
                'sit/front/kloos_sit_front_03.png',
                'sit/front/kloos_sit_front_04.png',
            ]
        },
        UP: {
            xDir: 0,
            yDir: -1,
            texture: 'run/side/kloos_run_side_01.png',
            animFrames: [
                'run/side/kloos_run_side_01.png',
                'run/side/kloos_run_side_02.png',
                'run/side/kloos_run_side_03.png',
                'run/side/kloos_run_side_04.png',
            ]
        },
        DOWN: {
            xDir: 0,
            yDir: 1,
            texture: 'run/front/kloos_run_front_01.png',
            animFrames: [
                'run/front/kloos_run_front_01.png',
                'run/front/kloos_run_front_02.png',
                'run/front/kloos_run_front_03.png',
                'run/front/kloos_run_front_04.png',
            ]
        },
        LEFT: {
            xDir: -1,
            yDir: 0,
            scaleX: 1,
            texture: 'run/side/kloos_run_side_01.png',
            animFrames: [
                'run/side/kloos_run_side_01.png',
                'run/side/kloos_run_side_02.png',
                'run/side/kloos_run_side_03.png',
                'run/side/kloos_run_side_04.png',
            ]
        },
        RIGHT: {
            xDir: 1,
            yDir: 0,
            scaleX: -1,
            texture: 'run/side/kloos_run_side_01.png',
            animFrames: [
                'run/side/kloos_run_side_01.png',
                'run/side/kloos_run_side_02.png',
                'run/side/kloos_run_side_03.png',
                'run/side/kloos_run_side_04.png',
            ]
        }
    }
}
*/

function generateOneAnim(spriteData) {
    let animTextures = [];
    if (spriteData.animFrames) {
        for (let i = 0; i < spriteData.animFrames.length; i++) {
            animTextures.push(PIXI.Texture.from(spriteData.animFrames[i]));
        }
    }
    return animTextures;
}

function generateAnimsForCharacter(characterData) {
    for (let p in characterData.SPRITES) {
        if (characterData.SPRITES[p].animFrames) {
            characterData.SPRITES[p].animTextures = generateOneAnim(characterData.SPRITES[p]);
            // characterData.SPRITES[p].anim = new PIXI.AnimatedSprite(characterData.SPRITES[p].animTextures);
            // characterData.SPRITES[p].anim.gotoAndStop(1);
            // characterData.SPRITES[p].anim.animationSpeed = 0.15;
        }
    }
}

export function generateCharacterAnims() {
    // generateAnimsForCharacter(IZZY);
    // generateAnimsForCharacter(KLOOS);
    // generateAnimsForCharacter(KLOOS2);
    generateAnimsForCharacter(MAIN_CHARACTER);
}

function destroyAnimsForCharacter(characterData) {
    for (let p in characterData.SPRITES) {
        if (characterData.SPRITES[p].anim) {
            characterData.SPRITES[p].anim.destroy();
            characterData.SPRITES[p].anim = null;
        }
        if (characterData.SPRITE[p].animTextures) {
            characterData.SPRITES[p].animTextures = null;
        }
    }
}

export function destroyCharacterAnims() {
    // destroyAnimsForCharacter(IZZY);
    // destroyAnimsForCharacter(KLOOS);
    destroyAnimsForCharacter(MAIN_CHARACTER);
}