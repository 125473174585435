// import koko from "../koko";
import { TileMapEngine, ORTHAGONAL, DEBUG_CSS } from './TileMapEngine';
import * as MapChunk from './MapChunk'
import * as PIXI from 'pixi.js'
import { au } from '../shorthand'
import { DEBUG } from "../../model/config";

/**
 * @author Carl Trelfa
 * 
 * A useful controller for scrolling Orthagonal Maps.
 * 
 * Set up is almost identical to using the OrthEngine directly, but this class will handle maps
 * made from smaller chunks (the chunks should all be the same width / height).
 * There is a good chance your maps will consist of only one chunk, so I guess you could just use
 * the OrthEngine directly, however, this does have some useful extra funtionality specific to scrolling maps.
 * 
 * Handles scrolling and hiding / showing off-screen / on-screen tiles and grid setup for path finding.
 * The hiding and showing works in conjunction with koko.app.viewPort to automatically know the view area.
 * 
 * Also as a bonus will handle sounds which will vary in volume based on your position in the map,
 * generating the blockers map for a-star and making changes to the active map (although you can do this directly via OrthEngine).
 * 
 * You might find some useful functions in orthEngine and orthEngine.renderer (depth sort springs to mind in renderer) 
 * 
 * If you need to update switcher sprites, moving sprites etc you will need to call some orthEngine functions.
 * OrthEngine also has useful functions for collision detection (eg. finding tiles by pixel pos / tile pos etc).
 * 
 */

export class ScrollingMapController {
    type = null;
    tileMapEngine = null;
    weHaveAnActiveMap = false;
    mapBounds = null;
    mapScrollBounds = null;
    mapTilesAcross = 0;
    mapTilesDown = 0;
    lastFocusSprite = null;
    blockingLayers = null;
    zoom = 1;

    activeEntities = null;

    doubleBlockingGrid = false;
    currentRawPathfindingData = null;
    // If we are using a double grid, we still generate a single grid version for efficient use in-game
    // as a single grid may be suitable for some things still and will generate paths at up to 4x quicker
    singleGridPathfindingData = null;

    /**
     * Construct a ScrolingOrthMapController instance
     * @param {object} tileSets - these should be initialised by calling TileSet.InitTilesets before being passed in
     * @param {array} layerContainers - an array of Pixi Containers 
     * @param {number} tileWidth - width of a grid square in pixels
     * @param {number} tileHeight - height of a grid square in pixels
     * @param {string} type - use consts ISOMETRIC or ORTHAGONAL from TileMapEngine.js
     * @param {array} layerLabels (optional) - used by renderer, if we force layers for tiles we can use an id string so we don't need to keep renumbering
     * @param {array | null} blockingLayers (optional) - any layer set to false in this array is ignored when generating our blockers grid, null or no entry for a layer counts as blocking
     * @param {array | null} tileBuffers (optional) - iso tiles can gain cracks between them, tileBuffers are used to make tiles on some layers larger to avoid this
     *                                                this is an array of numbers representing how many pixels to expand.
     */
    constructor(tileSets, layerContainers, tileWidth, tileHeight, type = ORTHAGONAL, layerLabels = [], blockingLayers = null, tileBuffers = null) {
        console.log('New Scrolling Map Controller: ', tileWidth, tileHeight);
        this.blockingLayers = blockingLayers;
        this.tileMapEngine = new TileMapEngine(tileSets, layerContainers, tileWidth, tileHeight, type, layerLabels, tileBuffers);
        // console.log(PIXI);
        if (DEBUG) {
            window.ScrollingMapController = this;
            console.log('%c[TILE ENGINE DEBUG]%c window.ScrollingMapController: ', DEBUG_CSS, '', this);
        }
    }

    setLayers(layerContainers, layerLabels = null) {
        this.tileMapEngine.renderer.setLayers(layerContainers, layerLabels);
    }

    /**
     * Set the map for the controller.
     * We can pass in a 2d array of map chunk ids, if we are using a single id it should still be a 2d array: [['mapId']].
     * @param {array} mapArray 
     * @param {boolean} doublePathGrid - true to treat each grid square as 2x2 for pathfinding (more versatile for large grid sizes)
     */
    setMap(mapArray, doublePathGrid = true) {
        if (this.weHaveAnActiveMap) {
            this.clearCurrentMap();
        }
        let mapChunkWidth = 0;
        let mapChunkHeight = 0;
        for (let i = 0; i < mapArray.length; i++) {
            for (let j = 0; j < mapArray[i].length; j++) {
                this.tileMapEngine.addMapChunk(MapChunk.ProcessedMapChunks[mapArray[i][j]], j * mapChunkWidth, i * mapChunkHeight);
                if (i === 0 && j === 0) {
                    mapChunkWidth = this.tileMapEngine.activeMapChunks[0].bounds.width;
                    mapChunkHeight = this.tileMapEngine.activeMapChunks[0].bounds.height;
                }
            }
        }
        this.mapBounds = new PIXI.Rectangle();
        for (let i = 0; i < this.tileMapEngine.activeMapChunks.length; i++) {
            this.mapBounds.enlarge(this.tileMapEngine.activeMapChunks[i].bounds);
        }
        this.mapTilesAcross = this.mapBounds.width / this.tileMapEngine.renderer.tileWidth;
        this.mapTilesDown = this.mapBounds.height / this.tileMapEngine.renderer.tileHeight;
        this.mapScrollBounds = {
            top: this.mapBounds.top,
            right: this.mapBounds.right,
            bottom: this.mapBounds.bottom,
            left: this.mapBounds.left,
        }

        // find scroll edges
        let mapScrollEdges = [];
        let scrollEdgeTiles = this.tileMapEngine.getSpecialSpritesWithProperty('scrolledge');
        for (let i = 0; i < scrollEdgeTiles.length; i++) {
            // console.log('Found scrolledge: ', scrollEdgeTiles[i]);
            if (typeof scrollEdgeTiles[i].config.scrolledge === 'string') {
                mapScrollEdges.push(scrollEdgeTiles[i]);
            }
        }
        for (let i = 0; i < mapScrollEdges.length; i++) {
            let scrollEdge = mapScrollEdges[i];
            // console.log('Found scrolledge: ', scrollEdge);
            switch (scrollEdge.config.scrolledge) {
                case 'top':
                    let newTop = scrollEdge.baseTileY - this.tileMapEngine.renderer.tileHeight / 2;
                    if (newTop > this.mapScrollBounds.top) {
                        this.mapScrollBounds.top = newTop;
                    }
                    break;
                case 'bottom':
                    let newBottom = scrollEdge.baseTileY + this.tileMapEngine.renderer.tileHeight / 2;
                    if (newBottom < this.mapScrollBounds.bottom) {
                        this.mapScrollBounds.bottom = newBottom;
                    }
                    break;
                case 'left':
                    let newLeft = scrollEdge.baseTileX - this.tileMapEngine.renderer.tileWidth / 2;
                    if (newLeft > this.mapScrollBounds.left) {
                        this.mapScrollBounds.left = newLeft;
                    }
                    break;
                case 'right':
                    let newRight = scrollEdge.baseTileX + this.tileMapEngine.renderer.tileWidth / 2;
                    if (newRight < this.mapScrollBounds.right) {
                        this.mapScrollBounds.right = newRight;
                    }
                    break;
                default:
                    break;
            }
        }
        this.mapScrollBounds.width = this.mapScrollBounds.right - this.mapScrollBounds.left;
        this.mapScrollBounds.height = this.mapScrollBounds.bottom - this.mapScrollBounds.top;

        console.log('--- map bounds : ', this.mapBounds, this.mapScrollBounds);

        this.weHaveAnActiveMap = true;

        this.startMapSounds();
        this.generatePathFindingData(doublePathGrid);
    }

    /**
     * You'll need to clear the current map if you decide to change the map you are using.
     */
    clearCurrentMap() {
        this.removeEntities();

        while (this.tileMapEngine.activeMapChunks.length > 0) {
            this.tileMapEngine.removeActiveChunk(0);
        }
        this.weHaveAnActiveMap = false;

        this.lastFocusSprite = null;
        this.resetSounds();

        this.currentRawPathfindingData = null;
    }

    /**
     * Change a tile on live map chunks - this will change the tile on all active versions of the live map chunk.
     * In most case where you will need this you will only have a single active version of the map chunk anyway, 
     * so this shouldn't be a problem.
     * Sprites are actually swapped using swapTile below or if no tile exists in the position, a new tile is added.
     * @param {string} mapChunkId 
     * @param {number} layer 
     * @param {number} gridX 
     * @param {number} gridY 
     * @param {string} newTilesetId - pass null to remove the tile
     * @param {number} newTileId    - pass null to remove the tile
     * 
     * @returns {object} spritesChanged {oldSprites:array, newSprites:array}
     *                   You will need to remove the old sprites from the view yourself, this allows you to transition between them.
     */
    makeLiveChangeToMapChunk(mapChunkId, layer, gridX, gridY, newTilesetId = null, newTileId = null) {
        // we just return the result of the equivilent function on OrthEngine - our gameController should go via this class at all times
        let changes = this.tileMapEngine.makeLiveChangeToMapChunk(mapChunkId, layer, gridX, gridY, newTilesetId, newTileId);
        this.generatePathFindingData(this.doubleBlockingGrid);
        return changes;
    }

    removeTileFromMap(tile, removeFromView = false) {
        return this.swapTile(tile, null, null, removeFromView);
    }

    swapTile(tile, newTilesetId, newTileId, removeFromView = false) {
        let mapChanges =  this.makeLiveChangeToMapChunk(tile.mapChunk.id, tile.tileData.layer, tile.gridX, tile.gridY, newTilesetId, newTileId);
        if (removeFromView) {
            for (let i = 0; i < mapChanges.oldSprites.length; i++) {
                if (mapChanges.oldSprites[i].parent) {
                    mapChanges.oldSprites[i].parent.removeChild(mapChanges.oldSprite);
                }
                mapChanges.oldSprites[i].destroy();
            }
        }
        return mapChanges;
    }

    /**
     * Private internal function used by the focus on sprite functions
     * @param {PIXI.Sprite} sprite 
     * @param {PIXI.Rectangle} viewRect 
     * @returns 
     */
    _getScrollOffset(sprite, viewRect) {
        let zoomedViewRect = viewRect.clone();
        zoomedViewRect.width /= this.zoom;
        zoomedViewRect.height /= this.zoom;

        let scrollOffset = {x: 0, y: 0};
        let spriteBounds = sprite.getLocalBounds();
        if (this.mapScrollBounds.width > zoomedViewRect.width) {
            scrollOffset.x = -(sprite.x + spriteBounds.x + spriteBounds.width / 2) + zoomedViewRect.width / 2;
            if (scrollOffset.x > -this.mapScrollBounds.left) {
                scrollOffset.x = -this.mapScrollBounds.left;
            }
            if (scrollOffset.x < -(this.mapScrollBounds.right - zoomedViewRect.width)) {
                scrollOffset.x = -(this.mapScrollBounds.right - zoomedViewRect.width);
            }
        } else {
            let mapMidX = this.mapScrollBounds.left + this.mapScrollBounds.width / 2;
            // scrollOffset.x = (viewRect.width - this.mapScrollBounds.width) / 2;
            scrollOffset.x = zoomedViewRect.width / 2 - mapMidX;
        }
        if (this.mapScrollBounds.height > zoomedViewRect.height) {
            scrollOffset.y = -(sprite.y + spriteBounds.y + spriteBounds.height / 2) + zoomedViewRect.height / 2;
            if (scrollOffset.y > -this.mapScrollBounds.top) {
                scrollOffset.y = -this.mapScrollBounds.top;
            }
            if (scrollOffset.y < -(this.mapScrollBounds.bottom - zoomedViewRect.height)) {
                scrollOffset.y = -(this.mapScrollBounds.bottom - zoomedViewRect.height);
            }
        } else {
            let mapMidY = this.mapScrollBounds.top + this.mapScrollBounds.height / 2;
            // scrollOffset.y = (viewRect.height - this.mapScrollBounds.height) / 2;
            scrollOffset.y = zoomedViewRect.height  / 2 - mapMidY;
        }
        scrollOffset.x *= this.zoom;
        scrollOffset.y *= this.zoom;
        scrollOffset.x += viewRect.x;
        scrollOffset.y += viewRect.y;
        scrollOffset.x = Math.round(scrollOffset.x);
        scrollOffset.y = Math.round(scrollOffset.y);
        // console.log('scrolloffset: ', scrollOffset);
        return scrollOffset;
    }

    registerEntity(newEntity) {
        if (this.activeEntities === null) {
            this.activeEntities = [];
        }
        this.activeEntities.push(newEntity);
    }

    removeEntities() {
        if (this.activeEntities) {
            for (let i = 0; i < this.activeEntities.length; i++) {
                this.activeEntities[i].removeFromPlay();
            }
        }
        this.activeEntities = null;
    }

    removeEntity(entity) {
        let entityIndex = this.activeEntities.indexOf(entity);
        if (entityIndex >= 0) {
            this.activeEntities[entityIndex].removeFromPlay();
            this.activeEntities.splice(entityIndex, 1);
        }
    }

    updateEntities(delta) {
        if (this.activeEntities) {
            for (let i = 0; i < this.activeEntities.length; i++) {
                if (this.activeEntities[i].updateable) {
                    this.activeEntities[i].update(delta);
                }
            }
            // activate proximity alarms
            for (let i = 0; i < this.activeEntities.length; i++) {
                let closeEntities = this.getCloseEntities(this.activeEntities[i]);
                if (closeEntities.length) {
                    this.activeEntities[i].otherCharacterNearBy(closeEntities);
                }
            }
        }
    }

    hideOffScreenEntities(viewRect, mapContainer, buffer = 0) {
        let localRect = new PIXI.Rectangle(viewRect.x - buffer, viewRect.y - buffer, viewRect.width + buffer * 2, viewRect.height + buffer * 2);
        localRect.x /= this.zoom;
        localRect.y /= this.zoom;
        localRect.width /= this.zoom;
        localRect.height /= this.zoom;
        localRect.x -= mapContainer.x / this.zoom;
        localRect.y -= mapContainer.y / this.zoom;
        for (let i = 0; i < this.activeEntities.length; i++) {
            var sprite = this.activeEntities[i].characterSprite;
            // var spriteBounds = sprite.getLocalBounds();
            var spriteBounds = sprite.cachedBounds;
            if (!sprite.cachedBounds) {
                sprite.cachedBounds = sprite.getLocalBounds();
                /*
                if (sprite.children[0] && sprite.children[0]._texture && sprite.children[0]._texture.trim) {
                    sprite.cachedBounds.x += sprite.children[0]._texture.trim.x;
                    sprite.cachedBounds.y += sprite.children[0]._texture.trim.y;
                    sprite.cachedBounds.width = sprite.children[0]._texture.trim.width;
                    sprite.cachedBounds.height = sprite.children[0]._texture.trim.height;
                }
                */
                spriteBounds = sprite.cachedBounds;
            }
            // console.log(spriteBounds);
            // spriteBounds.x += sprite.x;
            // spriteBounds.y += sprite.y;

            if (spriteBounds.left + sprite.x <= localRect.right && spriteBounds.right + sprite.x >= localRect.left
                && spriteBounds.top + sprite.y <= localRect.bottom && spriteBounds.bottom + sprite.y >= localRect.top) {
                sprite.visible = true;
                if (!sprite.inView) {
                    sprite.inView = true;
                    if (sprite.controller && sprite.controller.enteredView) {
                        sprite.controller.enteredView();
                    }
                }
            } else {
                sprite.visible = false;
                if (sprite.inView) {
                    sprite.inView = false;
                    if (sprite.controller && sprite.controller.leftView) {
                        sprite.controller.leftView();
                    }
                }
            }
        }
    }

    getEntitiesAtPixel(pixelX, pixelY, ignoreList = []) {
        let foundEntities = [];
        for (let i = 0; i < this.activeEntities.length; i++) {
            if (ignoreList.indexOf(this.activeEntities[i]) === -1) {
                var spriteBounds = this.activeEntities[i].characterSprite.cachedBounds || this.activeEntities[i].characterSprite.getLocalBounds();
                // spriteBounds.x += this.activeEntities[i].characterSprite.x;
                // spriteBounds.y += this.activeEntities[i].characterSprite.y;
                if (pixelX + 2 >= spriteBounds.left + this.activeEntities[i].characterSprite.x && pixelX - 2 <= spriteBounds.right + this.activeEntities[i].characterSprite.x && 
                    pixelY + 2 >= spriteBounds.top + this.activeEntities[i].characterSprite.y && pixelY - 2 <= spriteBounds.bottom + this.activeEntities[i].characterSprite.y) {
                        foundEntities.push(this.activeEntities[i]);
                }
            }
        }
        return foundEntities;
    }

    setZoom(zoom, mapContainer) {
        this.zoom = zoom;
        mapContainer.scale.x = mapContainer.scale.y = this.zoom;
    }

    smoothZoom(zoomTo, mapContainer, speed = 0.5) {
        if (Math.abs(zoomTo - this.zoom) > 0.01) {
            this.zoom += (zoomTo - this.zoom) / (1 / speed);
        } else {
            this.zoom = zoomTo;
        }
        mapContainer.scale.x = mapContainer.scale.y = this.zoom;
    }

    /**
     * Immediately focus on a sprite, usually immediately after intitalising your map.
     * @param {PIXI.Sprite} sprite 
     * @param {PIXI.Rectangle} viewRect 
     * @param {PIXI.Container} mapContainer 
     */
    focusOnSprite(sprite, viewRect, mapContainer) {
        let scrollOffset = this._getScrollOffset(sprite, viewRect);
        mapContainer.x = scrollOffset.x;
        mapContainer.y = scrollOffset.y;
        this.lastFocusSprite = sprite;
        this.setSoundVolumes(sprite);
    }

    /**
     * Smoothly focus on a sprite, usually used while playing.
     * @param {PIXI.Sprite} sprite 
     * @param {PIXI.Rectangle} viewRect 
     * @param {PIXI.Container} mapContainer 
     * @param {number} speed - number between 0 and 1, 1 is immediate, 0.2 means we will only move 1/20th of the distance needed, so call every frame
     */
    smoothFocusOnSprite(sprite, viewRect, mapContainer, speed) {
        let scrollOffset = this._getScrollOffset(sprite, viewRect);
        if (Math.abs(mapContainer.x - scrollOffset.x) > 0 || Math.abs(mapContainer.y - scrollOffset.y) > 0) {
            mapContainer.x += (scrollOffset.x - mapContainer.x) / (1 / speed);
            mapContainer.y += (scrollOffset.y - mapContainer.y) / (1 / speed);
            if (Math.abs(mapContainer.x - scrollOffset.x) < 1) {
                mapContainer.x = scrollOffset.x;
            }
            if (Math.abs(mapContainer.y - scrollOffset.y) < 1) {
                mapContainer.y = scrollOffset.y;
            }
            this.setSoundVolumes(sprite);
        }
        this.lastFocusSprite = sprite;
    }

    /**
     * You normally call this every frame, but could get away with only calling it when you move your map container / resize the viewRect.
     * @param {PIXI.Rectangle} viewRect 
     * @param {PIXI.Container} mapContainer 
     */
    hideOffScreenTiles(viewRect, mapContainer, buffer = 0) {
        let localRect = new PIXI.Rectangle(viewRect.x - buffer, viewRect.y - buffer, viewRect.width + buffer * 2, viewRect.height + buffer * 2);
        localRect.x /= this.zoom;
        localRect.y /= this.zoom;
        localRect.width /= this.zoom;
        localRect.height /= this.zoom;
        localRect.x -= mapContainer.x / this.zoom;
        localRect.y -= mapContainer.y / this.zoom;
        this.tileMapEngine.hideShowSprites(localRect);
    }

    /**
     * We could include all the path finding code, but don't want to lock us in to a particular library for this.
     * I'm using fast-astar https://github.com/sbfkcel/fast-astar
     * @param {boolean} doubleGrid - Double up our grid, so each square is a 2x2 in the path finder grid. Tiles can have a blocker_grid assigned,
     * eg [0,1,0,1] would open the left side up which you would use for a thin wall that sits on the right of a grid square. A normal blocker results
     * in a 2x2 blocker_grid of 1s and a normal open square results in a 2x2 blocker_grid of 0s.
     * In Penna Kloos I am still moving the character to the center of the grid square, but this technique allows us to get close to walls by allowing 
     * us to enter the grid square from a specific side(s), so when moving to the next grid square, I divide the gridX / gridY by 2 (Math.floored) and
     * ignore any nodes that result in the same grid pos as the one the character currently occupies.
     * @returns 2d array of 1s and 0s. 1 is a blocked tile.
     */
    getMapDataForPathFinding(doubleGrid = false) {
        let gridMult = doubleGrid ? 2 : 1;
        let mapBlockersArray = [];
        for (let mapGridX = 0; mapGridX < this.mapTilesAcross * gridMult; mapGridX++) {
            mapBlockersArray.push([]);
            for (let mapGridY = 0; mapGridY < this.mapTilesDown * gridMult; mapGridY++) {
                mapBlockersArray[mapGridX].push(0);
            }
        }
        // console.log('mapBlockersArray: ', mapBlockersArray);
        for (let i = 0; i < this.tileMapEngine.activeMapChunks.length; i++) {
            let mapChunk = this.tileMapEngine.activeMapChunks[i];
            // console.log('map chunk: ', mapChunk);
            let chunkGridX = mapChunk.chunkX / this.tileMapEngine.renderer.tileWidth;
            let chunkGridY = mapChunk.chunkY / this.tileMapEngine.renderer.tileHeight;
            for (let l = 0; l < mapChunk.spriteGrid.length; l++) {
                if (this.blockingLayers === null || this.blockingLayers.length <= l || this.blockingLayers[l] !== false) {
                    for (let gx = 0; gx < mapChunk.spriteGrid[l].length; gx++) {
                        for (let gy = 0; gy < mapChunk.spriteGrid[l][gx].length; gy++) {
                            if (mapChunk.spriteGrid[l][gx][gy] !== null && typeof mapChunk.spriteGrid[l][gx][gy].config.blocker !== 'undefined' && (mapChunk.spriteGrid[l][gx][gy].config.blocker === true || mapChunk.spriteGrid[l][gx][gy].config.blocker === 'true')) {
                                let mbGX = (gx + chunkGridX) * gridMult;
                                let mbGY = (gy + chunkGridY) * gridMult;
                                if (doubleGrid) {
                                    if (typeof mapChunk.spriteGrid[l][gx][gy].config.blocker_grid !== 'undefined') {
                                        mapBlockersArray[mbGX][mbGY] = mapChunk.spriteGrid[l][gx][gy].config.blocker_grid[0];
                                        mapBlockersArray[mbGX + 1][mbGY] = mapChunk.spriteGrid[l][gx][gy].config.blocker_grid[1];
                                        mapBlockersArray[mbGX][mbGY + 1] = mapChunk.spriteGrid[l][gx][gy].config.blocker_grid[2];
                                        mapBlockersArray[mbGX + 1][mbGY + 1] = mapChunk.spriteGrid[l][gx][gy].config.blocker_grid[3];
                                    } else {
                                        mapBlockersArray[mbGX][mbGY] = 1;
                                        mapBlockersArray[mbGX + 1][mbGY] = 1;
                                        mapBlockersArray[mbGX][mbGY + 1] = 1;
                                        mapBlockersArray[mbGX + 1][mbGY + 1] = 1;
                                    }
                                } else {
                                    mapBlockersArray[mbGX][mbGY] = 1;
                                }
                                // console.log(gx, gy, chunkGridX, chunkGridY);
                            }
                        }
                    }
                }
            }
        }
        return mapBlockersArray;
    }

    generatePathFindingData(doubleGrid = false) {
        this.doubleBlockingGrid = doubleGrid;
        this.currentRawPathfindingData = this.getMapDataForPathFinding(doubleGrid);
        if (!doubleGrid) {
            this.singleGridPathfindingData = this.currentRawPathfindingData;
        } else {
            this.singleGridPathfindingData = this.getMapDataForPathFinding(false);
        }
    }

    getPathFindingGridWithEntities(ignoreEntity = null, forceSingleGrid = false) {
        let rawPathfindingData = forceSingleGrid ? this.singleGridPathfindingData : this.currentRawPathfindingData;
        if (this.activeEntities) {
            let doubleGrid = forceSingleGrid ? false : this.doubleBlockingGrid;
            let combinedPathfindingGrid = [];
            for (let i = 0; i < rawPathfindingData.length; i++) {
                combinedPathfindingGrid.push([]);
                for (let j = 0; j < rawPathfindingData.length; j++) {
                    combinedPathfindingGrid[i][j] = rawPathfindingData[i][j];
                }
            }
            for (let i = 0; i < this.activeEntities.length; i++) {
                if (this.activeEntities[i] !== ignoreEntity) {
                    this.activeEntities[i].applyBlockingArea(combinedPathfindingGrid, doubleGrid);
                }
            }
            return combinedPathfindingGrid;
        } else
        return rawPathfindingData;
    }

    getCloseEntities(entity) {
        let closeEntities = [];
        for (let i = 0; i < this.activeEntities.length; i++) {
            if (entity !== this.activeEntities[i]) {
                let distanceToCheck = entity.proximityAlarmDistance >= this.activeEntities[i].proximityAlarmDistance ? entity.proximityAlarmDistance : this.activeEntities[i].proximityAlarmDistance;
                let distanceX = Math.abs(entity.characterSprite.x - this.activeEntities[i].characterSprite.x);
                let distanceY = Math.abs(entity.characterSprite.y - this.activeEntities[i].characterSprite.y);
                let distance = Math.sqrt(distanceX ** 2 + distanceY ** 2);
                if (distance <= distanceToCheck) {
                    closeEntities.push(this.activeEntities[i]);
                }
            }
        }
        return closeEntities;
    }

    // stuff for sounds
    soundsPlayed = {};
    soundsActive = false;

    startMapSounds() {
        if (!this.soundsActive) {
            this.soundsActive = true;
            let soundSprites = [];
            for (let i = 0; i < this.tileMapEngine.activeMapChunks.length; i++) {
                soundSprites = soundSprites.concat(this.tileMapEngine.activeMapChunks[i].specialSprites);
            }
            for (let i = 0; i < soundSprites.length; i++) {
                if (soundSprites[i].config.play_sound) {
                    // Since all sounds are linked to objects, non-looped sounds linked to randomly playing anims
                    // are always volumed as they play. These should be short on-off sounds which should 
                    // be triggerd on a specific frame (or the frame 1 if this is not set)
                    if (soundSprites[i].config.randomAnimPlayMin && soundSprites[i].config.randomAnimPlayMax && !soundSprites[i].config.sound_loop) {
                        let s = soundSprites[i];
                        let triggerFrame = soundSprites[i].config.sound_trigger_frame || 1;
                        soundSprites[i].config.noRealtimeVolumeChanges = true;
                        s.defaultSprite.onFrameChange = (f) => {
                            if (s.parent && f === triggerFrame && this.lastFocusSprite) {
                                let v = this.calculateSoundVolume(s, this.lastFocusSprite);
                                if (v > 0) {
                                    this.playSpriteSound(s, v);
                                }
                            }
                        }
                    } else {
                        this.playSpriteSound(soundSprites[i]);
                        if (!soundSprites[i].config.sound_loop && typeof soundSprites[i].config.animation === 'string') {
                            let s = soundSprites[i];
                            s.defaultSprite.onLoop = () => this.playSpriteSound(s);
                        }
                    }
                }
            }
        }
        // console.log('Sounds playing: ', this.soundsPlayed);
    }

    resetSounds() {
        // reset the record of our sounds and stop any that are playing
        for (let p in this.soundsPlayed) {
            for (let v = 0; v < this.soundsPlayed[p].variations; v++) {
                let sN = this.soundsPlayed[p].name;
                if (this.soundsPlayed[p].variations > 1) {
                    sN += (v + 1);
                }
                // au.sounds[sN].au.stop();
                au.stop(sN);
            }
        }
        this.soundsPlayed = {};
        this.soundsActive = false;
    }

    playSpriteSound(sprite, volume = 0) {
        // console.log('play sound: ', sprite);
        if (this.soundsActive && (sprite.parent || sprite.config.sound_loop === true)) {
            if (sprite.config.play_sound) {
                let time = new Date().getTime();
                if (typeof this.soundsPlayed[sprite.config.play_sound] === 'undefined' || time - this.soundsPlayed[sprite.config.play_sound].timePlayed > 50) {
                    let v = sprite.config.sound_variations ? sprite.config.sound_variations : 1;
                    let loop = sprite.config.sound_loop ? sprite.config.sound_loop : false;
                    // we're playing these with 0 volume by default - the volume will be sorted afterwards for loops
                    if (v > 1) {
                        au.playRandomSound(sprite.config.play_sound, volume, v, 0);
                    } else {
                        au.play(sprite.config.play_sound, volume, loop, 0);
                    }
                    let soundPlayedObj = {
                        name: sprite.config.play_sound,
                        variations: v,
                        timePlayed: time,
                    }
                    this.soundsPlayed[sprite.config.play_sound] = soundPlayedObj;
                    if (this.lastFocusSprite && volume === 0) {
                        this.setSoundVolumes(this.lastFocusSprite);
                    }
                    // console.log('Sound played: ', soundPlayedObj, au);
                }
            }
        }
    }

    calculateSoundVolume(sprite, focusSprite, fadeEase = 'quart', zeroVolumeDistance = 1280) {
        let dist = Math.sqrt((focusSprite.x - sprite.x) * (focusSprite.x - sprite.x) + (focusSprite.y - sprite.y) * (focusSprite.y - sprite.y));
        let zeroDist = typeof sprite.config.sound_distance === 'number' ? sprite.config.sound_distance : zeroVolumeDistance;
        let volume = 1 - dist / zeroDist;
        volume = volume < 0 ? 0 : volume;
        volume = volume > 1 ? 1 : volume;
        if (fadeEase === 'sine') {
            volume = Math.sin(volume * Math.PI);
        }
        if (fadeEase === 'quad') {
            volume = volume * volume;
        }
        if (fadeEase === 'quart') {
            volume = volume * volume * volume * volume;
        }
        return volume;
    }

    setSoundVolumes(focusSprite, fadeEase = 'quart', zeroVolumeDistance = 1280) {
        let soundSprites = [];
        for (let i = 0; i < this.tileMapEngine.activeMapChunks.length; i++) {
            soundSprites = soundSprites.concat(this.tileMapEngine.activeMapChunks[i].specialSprites);
        }
        for (let p in this.soundsPlayed) {
            // find closest sprite with this sound
            let closestSprite = null;
            let closestDist = Infinity;
            for (let i = 0; i < soundSprites.length; i++) {
                if (soundSprites[i].config.play_sound === this.soundsPlayed[p].name) {
                    let dist = Math.sqrt( (focusSprite.x - soundSprites[i].x) * (focusSprite.x - soundSprites[i].x) + (focusSprite.y - soundSprites[i].y) * (focusSprite.y - soundSprites[i].y) );
                    if (dist < closestDist) {
                        closestDist = dist;
                        closestSprite = soundSprites[i];
                    }
                }
            }
            if (closestSprite && closestSprite.config.noRealtimeVolumeChanges !== true) {
                // we have a sprite
                let zeroDist = typeof closestSprite.config.sound_distance === 'number' ? closestSprite.config.sound_distance : zeroVolumeDistance;
                let volume = 1 - closestDist / zeroDist;
                volume = volume < 0 ? 0 : volume;
                volume = volume > 1 ? 1 : volume;
                if (fadeEase === 'sine') {
                    volume = Math.sin(volume * Math.PI);
                }
                if (fadeEase === 'quad') {
                    volume = volume * volume;
                }
                if (fadeEase === 'quart') {
                    volume = volume * volume * volume * volume;
                }
                au.setSoundVolume(this.soundsPlayed[p].name, volume, this.soundsPlayed[p].variations);
            }
        }
    }
}