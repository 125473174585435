import LEADERSHIP_LOCKED from '../../assets/babcock_menus/badges/b_leadership_small.png';
import LEADERSHIP_UNLOCKED from '../../assets/babcock_menus/badges/b_leadership_large.png';
import SPEAKING_LOCKED from '../../assets/babcock_menus/badges/b_speaking_small.png';
import SPEAKING_UNLOCKED from '../../assets/babcock_menus/badges/b_speaking_large.png';
import PROBLEM_SOLVING_LOCKED from '../../assets/babcock_menus/badges/b_problemSolving_small.png';
import PROBLEM_SOLVING_UNLOCKED from '../../assets/babcock_menus/badges/b_problemSolving_large.png';
import CREATIVITY_LOCKED from '../../assets/babcock_menus/badges/b_creativity_small.png';
import CREATIVITY_UNLOCKED from '../../assets/babcock_menus/badges/b_creativity_large.png';
import STAYING_POSITIVE_LOCKED from '../../assets/babcock_menus/badges/b_stayingPositive_small.png';
import STAYING_POSITIVE_UNLOCKED from '../../assets/babcock_menus/badges/b_stayingPositive_large.png';
import AIMING_HIGH_LOCKED from '../../assets/babcock_menus/badges/b_aimingHigh_small.png';
import AIMING_HIGH_UNLOCKED from '../../assets/babcock_menus/badges/b_aimingHigh_large.png';
import LISTENING_LOCKED from '../../assets/babcock_menus/badges/b_listening_small.png';
import LISTENING_UNLOCKED from '../../assets/babcock_menus/badges/b_listening_large.png';
import TEAMWORK_LOCKED from '../../assets/babcock_menus/badges/b_teamwork_small.png';
import TEAMWORK_UNLOCKED from '../../assets/babcock_menus/badges/b_teamwork_large.png';

export const LEADERSHIP = {
    id: 'leadership',
    targetValue: 1,
    lockedIcon: LEADERSHIP_LOCKED,
    unlockedIcon: LEADERSHIP_UNLOCKED,

    name: 'Leadership',
    description: 'I understand others feelings.'
}
export const SPEAKING = {
    id: 'speaking',
    targetValue: 1,
    lockedIcon: SPEAKING_LOCKED,
    unlockedIcon: SPEAKING_UNLOCKED,

    name: 'Speaking',
    description: 'I am a good communicator.',
}
export const PROBLEM_SOLVING = {
    id: 'problemsolving',
    targetValue: 10,
    lockedIcon: PROBLEM_SOLVING_LOCKED,
    unlockedIcon: PROBLEM_SOLVING_UNLOCKED,

    name: 'Problem Solving',
    description: 'I complete tasks.',
}
export const CREATIVITY = {
    id: 'creativity',
    targetValue: 5,
    lockedIcon: CREATIVITY_LOCKED,
    unlockedIcon: CREATIVITY_UNLOCKED,

    name: 'Creativity',
    description: 'I can think creatively.'
}
export const STAYING_POSITIVE = {
    id: 'stayingpositive',
    targetValue: 20,
    lockedIcon: STAYING_POSITIVE_LOCKED,
    unlockedIcon: STAYING_POSITIVE_UNLOCKED,

    name: 'Staying Positive',
    description: 'I am resilient.'
}
export const AIMING_HIGH = {
    id: 'aiminghigh',
    targetValue: 3,
    lockedIcon: AIMING_HIGH_LOCKED,
    unlockedIcon: AIMING_HIGH_UNLOCKED,

    name: 'Aiming High',
    description: 'I approach new challenges positively.'
}
export const LISTENING = {
    id: 'listening',
    targetValue: 7,
    lockedIcon: LISTENING_LOCKED,
    unlockedIcon: LISTENING_UNLOCKED,

    name: 'Listening',
    description: 'I follow instructions.',
}
export const TEAMWORK = {
    id: 'teamwork',
    targetValue: 14,
    lockedIcon: TEAMWORK_LOCKED,
    unlockedIcon: TEAMWORK_UNLOCKED,

    name: 'Teamwork',
    description: 'I take responsibility.'
}

const BADGES_ORDER = [
    LISTENING,
    SPEAKING,
    PROBLEM_SOLVING,
    CREATIVITY,
    STAYING_POSITIVE,
    AIMING_HIGH,
    LEADERSHIP,
    TEAMWORK,
];

export { BADGES_ORDER as default };