import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import game from '../../../controller/GameController';
import { au, v } from '../../../koko-framework/shorthand';
import WorldController from "../../../controller/WorldController";

import BabcockController from "../../controller/BabcockController";
import * as BABCOCK_CONSTANTS from '../../model/constants';

import SmeatonsSelfie from '../../../assets/babcock_menus/selfie_location/reward/polaroid_temp.png';

import ProgressBg from '../../../assets/babcock_menus/selfie_location/reward/progress_bg.png';
import ProgressTop from '../../../assets/babcock_menus/selfie_location/reward/progress_top.png';
import ProgressSegment from '../../../assets/babcock_menus/selfie_location/reward/progress_segment.png';
import ContinueButton from '../../../assets/babcock_menus/mini_game_flow/resume_btn.png';
import QuitButton from '../../../assets/babcock_menus/mini_game_flow/QuitGame_btn.png';

import { GameState } from "../../model/GameState";

export default class PausePanel extends ReactFullScreenView {
    closePanel = () => {
        v.hideView(this.props.viewId);
        BabcockController.unpause();

        au.play('primarybtn', 1, false, 0, false);
    }

    quitGame = () => {
        v.hideViews(GameState.playingMiniGame.game.gameViews);
        this.lastInteraction = null;
        GameState.playingMiniGame = null;
        v.showViews(['hudview']);
        au.play('babcock_ingame_music', 1, true, 0, false);
        WorldController.scrollingTilemapController.startMapSounds();

        v.hideView(this.props.viewId);
        WorldController.enable();
        v.get('worldview').container.visible = true;
        au.play('secondarybtn', 1, false, 0, false);
    }

    renderContent() {
        return (
            <div className="full-screen-holder black-out no-select default-cursor rel">
                <div className="gen-white-panel mid-panel pause-panel flex-rows">
                    <div className="view-row grow"></div>
                    <div className="view-row grow"></div>

                    <div className="view-row shrink centered">
                        <p className="blue-header bold-text">Game Paused</p>
                    </div>

                    <div className="view-row grow"></div>
                    <div className="view-row grow"></div>

                    <div className="view-row shrink">
                        <img className="take-selfie-button active-button" src={ContinueButton} onClick={this.closePanel} alt="Click to continue playing" />
                    </div>

                    <div className="view-row grow"></div>

                    <div className="view-row shrink">
                        <img className="take-selfie-button active-button" src={QuitButton} onClick={this.quitGame} alt="Click to quit game" />
                    </div>

                    <div className="view-row grow"></div>
                    <div className="view-row grow"></div>
                </div>
            </div>
        );
    }
}