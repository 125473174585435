import gsap from 'gsap'
import {Back, Bounce} from 'gsap'
import koko from '../../../koko-framework/koko'
import app from '../../../koko-framework/app'
import { FRAME_TIME, FRAME_DELTA_LIMIT } from '../model/config'
import { KOKO_DEBUG, kokoDebug } from '../model/config'
import * as PIXI from 'pixi.js'
import { PackageSprite } from './PackageSprite'
import * as GameConfig from '../model/GameConfig'
import { LoadingBaySprite } from './LoadingBaySprite'
import { FB_HUDView } from '../view/FB_HUDView'
import { v } from '../../../koko-framework/shorthand'
import gsapCore, { _numWithUnitExp } from 'gsap/gsap-core'
import ReactGA from 'react-ga4'

export class DeliveryGameController extends koko.view {

    gameOverEvent = new Event("gameover", {bubbles: true}); // (2)
    activePackages = [];
    packagePool = [];

    loadingBayArr = [];
    currentConveyerSpeed = 0;

    lastPackageReleaseTime = 0;
    timeBetweenPackageRelease = 0;
    countdownToNextPackage = 0;

    playerScore = 0;
    parcelsLost = 0;
    playerScoreEvent = null;
    isGameOver = false;
    isPaused = false;

    recycleLight1 = null;
    recycleLight2 = null;
    recycleLight3 = null;

    conveyor1 = null;
    conveyor2 = null;
    assets = [
       { id: 'delivery_mania-assets', src: 'img/food-bank-game/delivery_mania-assets.json' },
       { id: 'delivery_mania-assets_0', src: 'img/food-bank-game/delivery_mania_anim_assets_0.json' },
       { id: 'delivery_mania-assets_1', src: 'img/food-bank-game/delivery_mania_anim_assets_1.json' },
       // { id: 'pipe_dream_anims', src: 'img/pipe_dream_anims.json' },
    ];

    buildView() {
        var g = app.guiSize, vp = app.viewPort;
        this.children = koko.display.buildLayout([
            {name: 'guiSize', type: 'shape', width: g.width, height: g.height, x: 0, y: 0, fill: 0, alpha: 0.2},
            {name:'gameBk', type:'sprite', id:'gameBk_fb'},
            {name:'conveyorContainer', type:'container', y:vp.top},
            {name:'packageContainer', type:'container', y:vp.top}, 
            {name:'loadingBayContainer', type:'container', y:vp.top}, 
            {name:'recyclingContainer', type:'container', y:vp.top}, 
            {name:'packagesCollected', type:'text', text:'Score: 0', font:'Arial', fontSize:70, align:'center', tint:0, x:630, y:200},
            {name:'livesLeft', type:'text', text:'Parcels Lost:  0', font:'Arial', fontSize:70, align:'center', tint:0, x:630, y:270},
            {name: 'blackOverlay', type: 'shape', width: g.width, height: g.height, x: 0, y: 0, fill: 0, alpha: 1},

        ], this.container);

        gsap.to(this.children.blackOverlay, {duration:.2, alpha:0})
        this.resize();
        this.container.scale.x = this.container.scale.y = GameConfig.ASSETS_SCALE;

        this.children.packagesCollected.visible=false;
        this.children.livesLeft.visible=false;

        document.addEventListener("unpause", this.unpauseGame);

          koko.audio.play('fb_music',.5, true);
    }

    pauseGame = () => {
        this.isPaused = true;

        for (let i = 0; i < this.loadingBayArr.length; i++) {
            let tweens = gsap.getTweensOf(this.loadingBayArr[i].truckSprite);
            if (tweens && tweens.length) {
                for (let j = 0; j < tweens.length; j++) {
                    tweens[j].pause();
                }
            }
        }
    }

    unpauseGame = () =>{
        this.isPaused = false;

        for (let i = 0; i < this.loadingBayArr.length; i++) {
            let tweens = gsap.getTweensOf(this.loadingBayArr[i].truckSprite);
            if (tweens && tweens.length) {
                for (let j = 0; j < tweens.length; j++) {
                    tweens[j].resume();
                }
            }
        }
    }
    
    transitionIn() {
        this.activePackages = [];
        this.packagePool = [];
    
        this.loadingBayArr = [];
        this.currentConveyerSpeed = 0;
    
        this.lastPackageReleaseTime = 0;
        this.timeBetweenPackageRelease = 0;
    
    
        this.playerScore = 0;
        this.parcelsLost = 0;
        this.playerScoreEvent = null;
        this.isGameOver = false;
        this.isPaused = false;
    
        this.recycleLight1 = null;
        this.recycleLight2 = null;
        this.recycleLight3 = null;
    
        this.conveyor1 = null;
        this.conveyor2 = null;


        super.transitionIn();
        app.addResizeListener('gameresize', function(){
            this.resize();
        }.bind(this));

        app.removeUpdateListener('assetUpdater');           
		app.addUpdateListener('assetUpdater', (time, delta) => { 
            let deltaRatio = 1;
            deltaRatio = delta / FRAME_TIME;
            if (deltaRatio > FRAME_DELTA_LIMIT) {
                deltaRatio = FRAME_DELTA_LIMIT;
            }
            this.update(deltaRatio, delta);
        });

        // this.createTestPackageSprite();
        this.timeBetweenPackageRelease = GameConfig.RELEASE_SPEED_START * 1000;
        this.setupGame();
        this.createNewPackage();
        this.countdownToNextPackage = this.timeBetweenPackageRelease;

        ReactGA.event({
            category: "Food Bank Game",
            action: "Food Bank Game - Started",
          });
    }

    setupGame = () =>{

        this.currentConveyerSpeed = GameConfig.CONVEYER_START_SPEED;


        let conveyorBeltSprite = new PIXI.Sprite.from('conveyorBelt.png');
        conveyorBeltSprite.anchor.x = 0.5;
        //conveyorBeltSprite.scale.x = conveyorBeltSprite.scale.y = GameConfig.ASSETS_SCALE;
        conveyorBeltSprite.x = GameConfig.CONVEYER_X;
        this.conveyor1 = conveyorBeltSprite
        this.children.conveyorContainer.addChild(conveyorBeltSprite);

        let conveyorBeltSprite1 = new PIXI.Sprite.from('conveyorBelt.png');
        conveyorBeltSprite1.anchor.x = 0.5;
        //conveyorBeltSprite.scale.x = conveyorBeltSprite.scale.y = GameConfig.ASSETS_SCALE;
        conveyorBeltSprite1.x = GameConfig.CONVEYER_X;
        conveyorBeltSprite1.y = -conveyorBeltSprite1.height + 32;
        this.conveyor2 = conveyorBeltSprite1;
        this.children.conveyorContainer.addChild(conveyorBeltSprite1);

        let recyclingBinSprite = new PIXI.Sprite.from('recyclingBin.png');
        recyclingBinSprite.pivot.x = recyclingBinSprite.width/2;
        recyclingBinSprite.x = GameConfig.CONVEYER_X;
        recyclingBinSprite.y = GameConfig.CONVEYER_END_Y - recyclingBinSprite.height;
        //recyclingBinSprite.scale.x = recyclingBinSprite.scale.y = GameConfig.ASSETS_SCALE;
        this.children.recyclingContainer.addChild(recyclingBinSprite);

        let recyclingLightContainer = new PIXI.Container();
        for(let i=1; i<=3; i++)
        {
           
            let lightOff = new PIXI.Sprite.from('LED_off.png')
            lightOff.x = (i-1)*100;
            recyclingLightContainer.addChild(lightOff);
            let lightOn = new PIXI.Sprite.from('LED_on.png');
            lightOn.x = (i-1)*100;
            lightOn.visible = false;
            recyclingLightContainer.addChild(lightOn);
            this['recycleLight' +i] = lightOn;
        }
        recyclingLightContainer.pivot.x = recyclingLightContainer.width/2;
        recyclingLightContainer.y = GameConfig.CONVEYER_END_Y - recyclingBinSprite.height - recyclingLightContainer.height;
        recyclingLightContainer.x = GameConfig.CONVEYER_X;
        this.children.recyclingContainer.addChild(recyclingLightContainer);




        this.setupLoadingBays();


    }
    setupLoadingBays = () =>{

        for(let i=0; i<GameConfig.NUMBER_OF_LOADING_BAYS; i++)
        {
            let newLoadingBay = new LoadingBaySprite();
            newLoadingBay.x = GameConfig.LOADINGBAY_X;
            newLoadingBay.y = GameConfig.LOADINGBAY_FIRST_Y + GameConfig.LOADING_BAY_HEIGHT*i;
           // newLoadingBay.scale.x = newLoadingBay.scale.y = GameConfig.ASSETS_SCALE;

            this.children.loadingBayContainer.addChild(newLoadingBay);
            this.loadingBayArr.push(newLoadingBay);
            console.log('Loading bay: ', this.loadingBayArr);

            newLoadingBay.truckSprite.on('parcelCollected', () =>{ this.updateScore();});
      
        }
    }


    updateScore = () =>{
        if( !this.isGameOver )
        {
            this.playerScore ++;
            this.children.packagesCollected.text = 'Score: '+ this.playerScore;
            koko.views.get('fb_hudView').updateProgressBar( this.playerScore);
        }
    }

    transitionOutDone = () => {
        for (let i = 0; i < this.loadingBayArr.length; i++) {
            gsap.killTweensOf(this.loadingBayArr[i].truckSprite);
        }

        // this.stopUpdate();
        app.removeUpdateListener('assetUpdater');
        document.removeEventListener("unpause", this.unpauseGame);
        app.removeResizeListener('gameresize');
        while (app.resizeListeners['gameresize']) {
            app.removeResizeListener('gameresize');
        }
        koko.audio.stop('fb_music');
        this.destroyView();
        this.hide();
        // super.transitionOutDone();

        // this.destroyView();
        // this.hide();
    }

    createNewPackage = () => {

        let randomPackageShape = GameConfig['PACKAGE_'+Math.ceil(Math.random()*9)];
        let newPackage = this.packagePool.length > 0 ?  this.packagePool.shift() : new PackageSprite();


        console.log(newPackage);
        newPackage.create(randomPackageShape);

        //Set position of the package and add it to the visual container
        newPackage.pivot.x = newPackage.width/2;
        newPackage.pivot.y = newPackage.height;
       // newPackage.scale.x = newPackage.scale.y = GameConfig.ASSETS_SCALE;
        newPackage.x = GameConfig.CONVEYER_X -.5
        newPackage.y = GameConfig.CONVEYER_START_Y;
        this.children.packageContainer.addChild(newPackage);


        newPackage.interactive = true;
        newPackage.on('mousedown', this.clickHandler);
        newPackage.on('tap', this.clickHandler);
        newPackage.clickDisabled = false;

        //Add the package to the active package pool
        this.activePackages.push(newPackage);
        this.lastPackageReleaseTime = Date.now();

    }





    createTestPackageSprite = () => {

        let yPos = 0;
        for(let i=0; i<8; i++)
        {
       
            let tempContainer = new PackageSprite(GameConfig['PACKAGE_'+(i+1)])
            tempContainer.x = (i % 2) * 160;
            tempContainer.y = yPos;
            if(i%2) yPos += 160;

            this.children.packageContainer.addChild(tempContainer);
        }

            this.children.packageContainer.pivot.x =  this.children.packageContainer.width /2;
            this.children.packageContainer.pivot.y =  this.children.packageContainer.height /2;
            this.children.packageContainer.x =  app.viewPort.centerX;
            this.children.packageContainer.y =  app.viewPort.centerY;
    }

    controlConveyerBelt = (delta) =>{


        this.conveyor1.y += this.currentConveyerSpeed * delta;
        this.conveyor2.y += this.currentConveyerSpeed * delta;
        if(this.conveyor1.y > this.conveyor1.height - 100)this.conveyor1.y = this.conveyor2.y - this.conveyor1.height;
        if(this.conveyor2.y > this.conveyor2.height - 100)this.conveyor2.y = this.conveyor1.y - this.conveyor2.height

        for(let i=0; i<this.activePackages.length; i++)
        {
            let currentActivePackage = this.activePackages[i];
            currentActivePackage.y += this.currentConveyerSpeed * delta;

            let packageInTruckBounds = this.checkPackageInTruckBounds(currentActivePackage)

            if(packageInTruckBounds.success)
            {
                //currentActivePackage.alpha = 1;
                let truckVailableSlots = packageInTruckBounds.targetLoadingBay.truckSprite.getAvailableSlots(currentActivePackage);
                currentActivePackage.debugTargetRowText.text = truckVailableSlots.targetRow;

                if(truckVailableSlots.canFitInTruck)
                {
                   
                }


            } else {
                //currentActivePackage.alpha = .5;
                currentActivePackage.debugTargetRowText.text = '';
            }

            //Parcel has reached the recycling point so pool the object
            if(currentActivePackage.y >= GameConfig.CONVEYER_END_Y)
            {
                kokoDebug(' *** PACKAGE NEEDS TO BE RECYCLED *** ');
                currentActivePackage.destroy();
                this.packagePool.push(this.activePackages.shift());
                koko.audio.play('boxMissed1',.5);
                this.parcelsLost ++;
                this.children.livesLeft.text = 'Parcels Lost: '+ this.parcelsLost 
                
                for(i=1; i<= this.parcelsLost; i++)
                {
                    this['recycleLight'+i].visible = true;
                }


                if( this.parcelsLost >= 3)
                {
                    koko.audio.play('fb_level_complete',.5);
                    
                    koko.views.get('fb_hudView').showGameOver();
            
                setTimeout(()=> {document.dispatchEvent(new CustomEvent("gameover", {bubbles: true, detail: { score: this.playerScore, stars: koko.views.get('fb_hudView').setStarStatus( this.playerScore) }}));
            }, 2000)
                    //document.dispatchEvent(new CustomEvent("gameover", {bubbles: true, detail: { score: this.playerScore, stars: koko.views.get('fb_hudView').setStarStatus( this.playerScore) }}));
                    this.isGameOver = true;
                    koko.audio.stop('fb_music');
                    ReactGA.event({
                        category: "Food Bank Game",
                        action: "Food Bank Game - Over",
                        label: "Food Bank Game - Score",
                        value: this.playerScore
                    });
    
                    for (let i = 0; i < this.loadingBayArr.length; i++) {
                        let tweens = gsap.getTweensOf(this.loadingBayArr[i].truckSprite);
                        if (tweens && tweens.length) {
                            for (let j = 0; j < tweens.length; j++) {
                                tweens[j].pause();
                            }
                        }
                    }
                }
            }
        }
    }

    hideTruckDebugData = () =>{
        for(let i=0; i<this.loadingBayArr.length; i++)
        {
            this.loadingBayArr[i].truckSprite.hideAllHighlightTiles();
        }
    }


    controlPackageRelease = (time) => {
    
        // if(Date.now()-this.lastPackageReleaseTime > this.timeBetweenPackageRelease)
        this.countdownToNextPackage -= time;
        if (this.countdownToNextPackage <= 0)
        {
            //Add a new package to the converyer belt
            this.createNewPackage();
            this.timeBetweenPackageRelease -= GameConfig.RELEASE_TIME_DEDUCTION * 1000;
            if(this.timeBetweenPackageRelease < GameConfig.RELEASE_SPEED_MIN * 1000) this.timeBetweenPackageRelease =  GameConfig.RELEASE_SPEED_MIN * 1000;
            this.countdownToNextPackage = this.timeBetweenPackageRelease;
        }
    }


    checkPackageInTruckBounds = (parcel) =>{
        let returnObj = {success:false, targetLoadingBay:null};

        let globalPositionTop = parcel.toGlobal(new PIXI.Point(0, parcel.pivot.y))
        let globalPositionBottom = parcel.toGlobal(new PIXI.Point(0, parcel.pivot.y -parcel.packageHeight ))

        for(let i=0; i<this.loadingBayArr.length; i++)
        {
            let targetLoadingBay = this.loadingBayArr[i];
            let isPackageInTrailerBounds = targetLoadingBay.checkTruckAvailableSpace(globalPositionTop, globalPositionBottom);
            if(isPackageInTrailerBounds) 
            {
                returnObj.success = true;
                returnObj.targetLoadingBay = targetLoadingBay
            }
        }
        return returnObj
    }


    clickHandler = (e) =>{

        if(!e.target.clickDisabled && !this.isGameOver && !this.isPaused)
        {
            let truckInBounds = this.checkPackageInTruckBounds(e.target, true)
            if(truckInBounds.success)
            {
                //Parcel is within the boundary of the open back trailer
                let truckVailableSlots = truckInBounds.targetLoadingBay.truckSprite.getAvailableSlots(e.target, true);


                if(truckVailableSlots.targetColumn >= 0)
                {
                    let packageIndex = this.activePackages.indexOf(e.target)
                    //kokoDebug('PARCEL CLICKED: '+e.target+' Index: '+packageIndex);
                    if(packageIndex != -1)
                    {

                        e.target.scale.x = e.target.scale.y = 1
                        e.target.clickDisabled = true;
                        let parcelToLoad = this.activePackages.splice(packageIndex, 1);
                        truckInBounds.targetLoadingBay.loadParcel(parcelToLoad[0]);
                        
                    }
                }
                else
                {
                   
                    e.target.scale.x = e.target.scale.y = 1
                    gsap.to(e.target.scale, {duration:.2, x:1.2, y:1.2, yoyo:true, repeat:1, ease:Bounce.easeIn, overwrite:true})
                    koko.audio.play('impossibleMove',.5);
                }
            }
            else
            {

                e.target.scale.x = e.target.scale.y = 1
                gsap.to(e.target.scale, {duration:.2, x:1.2, y:1.2, yoyo:true, repeat:1, ease:Bounce.easeIn, overwrite:true})
                koko.audio.play('impossibleMove',.5);
            }  
        }   
    }

    sendTruckClickHandler = (e) =>{
        e.target.truck.sendForDelivery();
    }

    update(delta, time)
    {
        if(!this.isGameOver && !this.isPaused)
        {
            this.hideTruckDebugData();
            this.controlConveyerBelt(delta)
            this.controlPackageRelease(time);

            for(let i=0; i<this.loadingBayArr.length; i++)
            {
                this.loadingBayArr[i].update(time);
            }
        }    
    }

    resize() {

    }
}