import _ from 'underscore'
import gsap from 'gsap'
import {Back, Bounce} from 'gsap'
import koko from '../../../koko-framework/koko'
import { v, a } from '../../../koko-framework/shorthand'
import app from '../../../koko-framework/app'
import main from '../../../main'
import * as PIXI from 'pixi.js'
import { setupClicker } from '../../../koko-framework/ui/ButtonController'
import * as GameConfig from '../model/GameConfig'

export class LL_HUDView extends koko.view {
    constructor () {
        super();
    }

    assets = [
        { id: 'hudAssets', src: 'img/hud_assets.json' },
    ];
 
    star1 = null;                                                       
    star2 = null;
    star3 = null;
    progressWidth = 276;
    barMask = null;
    starsTotal;

    buildView () {
        var g = app.guiSize; 
        var vp = app.viewPort;

       while(this.container.children[0]){
            this.container.removeChild( this.container.children[0]);
        }

        this.children = koko.display.buildLayout([
            {name:'pause', type:'sprite', id:'pauseBtn', x:vp.right- 70, y:vp.top +100,anchorX:.5, anchorY:.5, width: 73, height: 78},
            {name:'hudContainer', type:'container',  x:vp.left+245, y:vp.top +100},
            {name:'progressBarBack', parent:'hudContainer', type:'sprite', id:'HUDpanel', x:- 205, y:- 50, width: 411, height: 103},
            {name:'progressBarFill',  parent:'hudContainer', type:'sprite', id:'barEmpty', x:-90, y:-9, width: 272, height: 29},
            {name:'loadingBarTop',  parent:'hudContainer', type:'sprite', id:'barFill', x:-90, y:-9, width: 272, height: 29},
            {name:'scoreTitle',  parent:'hudContainer' ,type:'text', text:'MOVES', font:'Arial', anchorX:.5,anchorY:.5, wordWrap:true, fontSize:16, align:'center', tint:0xffffff, x:-155, y:-13},
            {name:'scoreText',  parent:'hudContainer', type:'text', fontWeight:'Bold', text:'0', font:'Arial', anchorX:.5,anchorY:.5, wordWrap:true,  fontSize:36, align:'center',tint:0xffffff, x:-155, y:10},
            {name:'gameOverContainer', type:'container'},
            {name:'blackOverlay', type: 'shape', parent:'gameOverContainer', width: g.width, height: g.height, x: 0, y: 0, fill: 0, alpha: 0.4},
            {name:'sb1',  parent:'gameOverContainer', type:'sprite', id:'starburst1', anchorX:.5, anchorY:.5, x:vp.centerX, y:vp.centerY, width: 531, height: 520},
            {name:'sb2',  parent:'gameOverContainer', type:'sprite', id:'starburst2', anchorX:.5, anchorY:.5, x:vp.centerX, y:vp.centerY, width: 447, height: 459},
            {name:'gameOverTitle',  parent:'gameOverContainer', type:'sprite', id:'gameOver', anchorX:.5, anchorY:.5, x:vp.centerX, y:vp.centerY, width: 476, height: 194},   
        ], this.container);

        this.children.gameOverContainer.visible = false;
        let barMask = new PIXI.Graphics();
        barMask.beginFill(0xff0000, 1);
        barMask.drawRoundedRect(-2, -2, 276, 33, 3);
        barMask.endFill();
        barMask.x = - 90;
        barMask.y = -11;
        this.children.hudContainer.addChild(barMask);
        this.children.barMask = barMask;
        this.children.loadingBarTop.mask = barMask;
        this.barMask = barMask;
       // this.children.loadingBarTop.visible = false;

        this.buildStars();
        this.resize();
        this.children.pause.interactive = true;
        this.children.pause.on('mousedown', this.pauseGame);
        this.children.pause.on('tap', this.pauseGame);
    }
    showGameOver = () =>{
        this.children.gameOverContainer.visible = true;
        this.children.sb1.scale.x =  this.children.sb1.scale.y = 0;
        this.children.sb2.scale.x =  this.children.sb2.scale.y = 0;
        this.children.gameOverTitle.scale.x =  this.children.gameOverTitle.scale.y = 0;
        gsap.to( this.children.gameOverTitle.scale, {duration:.3, x:.5, y:.5, ease:Bounce.easeOut})
        gsap.to( this.children.sb1.scale, {delay:.2, duration:.3, x:.5, y:.5, ease:Back.easeOut})
        gsap.to( this.children.sb2.scale, {delay:.3, duration:.3, x:.5, y:.5, ease:Back.easeOut})

        gsap.to( this.children.sb1.scale, {delay:.7, duration:.8, x:.4, y:.4, yoyo:true, repeat:-1})
        gsap.to( this.children.sb2.scale, {delay:.9, duration:.8, x:.4, y:.4,  yoyo:true, repeat:-1})
    }
    pauseGame()
    {
        document.dispatchEvent(new CustomEvent("pause"))
        koko.views.get('coffeegame_view').isPaused = true;
    }

    buildStars()
    {

        let starContainer = new PIXI.Container();
        for(var i=1; i<=3; i++)
        {
            let newStarContainer = new PIXI.Container();
          
            let starOff = new PIXI.Sprite.from('star_empty.png');
            starOff.pivot.x = starOff.width/2
            starOff.pivot.y = starOff.height/2
            starOff.scale.x = starOff.scale.y = GameConfig.ASSETS_SCALE;
            newStarContainer.addChild(starOff);

            let starOn = new PIXI.Sprite.from('star.png');
            starOn.pivot.x = starOn.width/2
            starOn.pivot.y = starOn.height/2
            starOn.scale.x = starOn.scale.y = GameConfig.ASSETS_SCALE;
            starOn.visible = false;
            newStarContainer.addChild(starOn);
            newStarContainer.starOn = starOn;

            newStarContainer.x = this.progressWidth * (1-((GameConfig['STAR'+i+'_SCORE']-GameConfig.HUD_100_SCORE)/(GameConfig.STAR1_SCORE-GameConfig.HUD_100_SCORE)));
          
            starContainer.addChild(newStarContainer);
            this['star'+i] = newStarContainer;
        }
        starContainer.x = -90;
        starContainer.y = 3;
        this.children.hudContainer.addChild(starContainer);
       
        this.updateProgressBar(0)

        this.children.starContainer = starContainer;
    }


    setStarStatus(score)
    {
        let totalStars = 0;
        if(!GameConfig.HUD_PROGRESS_ASCENDING)
        {
            for(let i=1; i<=3; i++)
            {
                let shouldStarBeVisible = score < GameConfig['STAR'+i+'_SCORE'];
                let starTarg = this['star'+i].starOn
                if( starTarg.visible != shouldStarBeVisible)
                {
                   
                    starTarg.scale.x = starTarg.scale.y = 1;
                    gsap.to(starTarg.scale, {duration:.3, x:GameConfig.ASSETS_SCALE, y:GameConfig.ASSETS_SCALE, ease:Back.easeOut})
                }

                starTarg.visible = shouldStarBeVisible;
                if(starTarg.visible)  totalStars ++;
            }
        }
        return totalStars;
    }
    updateProgressBar(score)
    {
        let scorePerc = 1-( (score-GameConfig.HUD_100_SCORE) / (GameConfig.STAR1_SCORE-GameConfig.HUD_100_SCORE));
        if(scorePerc > 1) scorePerc = 1;
        if(scorePerc < 0) scorePerc = 0;

        gsap.to(this.barMask, {duration:.3, width:this.progressWidth*scorePerc});
        //this.barMask.width = this.progressWidth*scorePerc;
        this.setStarStatus(score);
        this.children.scoreText.text =score;
    }

    resize () {
        // var vp = app.viewPort;
        /* resize code here */
    }

    transitionIn () {
     
        this.container.y -= 500;
     
        this.buildView();
        this.show();

        this.container.alpha = 0;
        gsap.to(this.container, {alpha:1, duration: 0.35});
        gsap.to(this.container, {delay:.8, duration:.5, y:this.container.y + 500, ease:Back.easeOut})

        app.addResizeListener('minigame', function() {
            this.resize();
        }.bind(this));

        this.disabled = false;
    }

    transitionOut = () => {
        // app.goFullScreen();

        //this.children.playButton.interactive = false;
        gsap.to(this.container, {alpha:0, onComplete:this.transitionOutDone, duration: 0.35});
    }

    transitionOutDone = () => {
        if (!this.disabled) {
            app.removeResizeListener('minigame');
            while (app.resizeListeners['minigame']) {
                app.removeResizeListener('minigame');
            }

            this.disabled = true;
            this.disable();
            this.destroyView();
            this.hide();
        }
    }
}
