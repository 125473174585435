import koko from './koko-framework/koko';
import * as shorthand from'./koko-framework/shorthand';
import './App.css';
import './Babcock_neet.css';

import Bootstrap from './Bootstrap';

function App() {
  /*
      This example uses react, but the es6 game framework should work with any es6 coding framework (eg. vue, angular etc.)
  */
  console.log('koko namespace: ', koko);
  console.log('koko shorthand: ', shorthand)

  return (
    <div>
      <Bootstrap></Bootstrap>
    </div>
  );
}

export default App;
