import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import game from '../../../controller/GameController';
import { au, v } from '../../../koko-framework/shorthand';
import WorldController from "../../../controller/WorldController";

import CvThumbnail from '../../../assets/babcock_menus/cv_result/CV_thumbnail.png';
import StarUnlocked from '../../../assets/babcock_menus/cv_result/star_Unlocked.png';
import StarLocked from '../../../assets/babcock_menus/cv_result/star_locked.png';

import GameIcon from '../../../assets/babcock_menus/cv_result/game_icon.png';
import AttIcon from '../../../assets/babcock_menus/cv_result/skillsBuilderBadges_icon.png';
import SelfieIcon from '../../../assets/babcock_menus/cv_result/selfie_icon.png';

import GameIconSmall from '../../../assets/babcock_menus/cv_result/game_icon_small.png';
import AttIconSmall from '../../../assets/babcock_menus/cv_result/skillsBuilderBadges_icon_small.png';
import SelfieIconSmall from '../../../assets/babcock_menus/cv_result/selfie_icon_small.png';

import SectionCompleteMarker from '../../../assets/babcock_menus/cv_result/complete_icon.png';
import SectionIncompleteMarker from '../../../assets/babcock_menus/cv_result/Incomplete_icon.png';

import ContinueButton from '../../../assets/babcock_menus/selfie_location/reward/continue_btn.png';
import { GameState, GetBadgesCompleteCount, GetCvPercentage } from "../../model/GameState";
import { SELFIE_LOCATIONS } from "../../model/constants";

import ReactGA from 'react-ga4';
import ApiController from "../../controller/ApiController";

export default class ResultScreen extends ReactFullScreenView {
    constructor(props) {
        super(props);

        v.hideView('greenbg');
        // v.showView('blackbg');
    }

    componentDidMount() {
        super.componentDidMount();

        GameState.gameComplete = true;
        ApiController.sendGameData();

        ReactGA.send({ hitType: "pageview", page: "/end-result/" });

        ReactGA.event({
            category: "Main Game Action",
            action: "CV Result",
            label: "Percentage Complete",
            value: Math.round(GetCvPercentage() * 100),
        });
    }

    closePanel = () => {
        v.hideView(this.props.viewId);
        // WorldController.enable();
        v.showView('maingameover');

        au.play('primarybtn', 1, false, 0, false);
    }

    renderContent() {
        let selfieSectionCompleteClass = GameState.selfiesTaken.length === SELFIE_LOCATIONS.length ? ' section-complete' : '';
        let badgesCompleted = GetBadgesCompleteCount();
        let badgesSectionCompleteClass = badgesCompleted === GameState.badgeProgress.length ? ' section-complete' : '';
        let cvProgress = GetCvPercentage();
        let cvPerc = Math.round(cvProgress * 100);
        return (
            <div className="full-screen-holder black-out no-select default-cursor">
                <div className="gen-white-panel rel">

                    <div className="view-row shrink">
                        <img className="result-screen-logo" src={CvThumbnail} alt="CV Icon" />
                    </div>

                    <div className="view-row grow"></div>

                    <div className="view-row shrink">
                        <p className="blue-header bold-text">Your CV Results</p>
                    </div>

                    <div className="view-row shrink centered">
                        <p className="results-blurb">Let's take a look at how well you completed<br />your CV thoughout the game.</p>
                    </div>

                    <div className="view-row grow"></div>

                    <div className="view-row result-progress-holder rel" aria-label={'Final CV Result: ' + cvPerc + '% Complete'}>
                        <div className="result-progress-back"></div>
                        <div className="result-progress-fill" style={{width: cvProgress * 440}}></div>
                        <div className="result-progress-border"></div>
                        <div className="result-progress-text">{cvPerc} %</div>
                    </div>

                    <div className="view-row grow"></div>

                    <div className="view-row shrink">
                        <div className="flex-columns result-stars-holder">
                            <div className="grow"></div>
                            <div className="shrink">
                                <img className="result-star" src={StarUnlocked} alt="Star 1 Unlocked" />
                            </div>
                            <div className="shrink">
                                <img className="result-star" src={StarUnlocked} alt="Star 2 Unlocked" />
                            </div>
                            <div className="shrink">
                                <img className="result-star" src={StarUnlocked} alt="Star 3 Unlocked" />
                            </div>
                            <div className="shrink">
                                <img className="result-star" src={badgesCompleted === GameState.badgeProgress.length ? StarUnlocked : StarLocked} alt={badgesCompleted === GameState.badgeProgress.length ? 'Star 4 Unlocked' : 'Star 4 Not Unlocked'} />
                            </div>
                            <div className="shrink">
                                <img className="result-star" src={GameState.selfiesTaken.length === SELFIE_LOCATIONS.length ? StarUnlocked : StarLocked} alt={GameState.selfiesTaken.length === SELFIE_LOCATIONS.length ? 'Star 5 Unlocked' : 'Star 5 Not Unlocked'} />
                            </div>
                            <div className="grow"></div>
                        </div>
                    </div>
                    <div className="view-row shrink">
                        <div className="flex-columns result-stars-holder">
                            <div className="grow"></div>
                            <div className="shrink result-star-icon-holder">
                                <img className="result-game-icon result-icon" src={GameIcon} alt="Work Reference 1" />
                            </div>
                            <div className="shrink result-star-icon-holder">
                                <img className="result-game-icon result-icon" src={GameIcon} alt="Work Reference 2" />
                            </div>
                            <div className="shrink result-star-icon-holder">
                                <img className="result-game-icon result-icon" src={GameIcon} alt="Work Reference 3" />
                            </div>
                            <div className="shrink result-star-icon-holder">
                                <img className="result-att-icon result-icon" src={AttIcon} alt="Skills Builder Badges" />
                            </div>
                            <div className="shrink result-star-icon-holder">
                                <img className="result-selfie-icon result-icon" src={SelfieIcon} alt="Hobbies and Interests" />
                            </div>
                            <div className="grow"></div>
                        </div>
                    </div>

                    <div className="view-row grow"></div>

                    <div className="view-row result-stat-row">
                        <div className="flex-columns result-sections-holder">
                            <div className="shrink">
                                <img className="result-game-icon-small" src={GameIconSmall} alt="" />
                            </div>
                            <div className="grow">
                                <p className="result-section-label">Experience</p>
                            </div>
                            <div className="shrink">
                                <p className="result-section-stat section-complete" aria-label="3 out of 3 complete">3 / 3 complete</p>
                            </div>
                            <div className="shrink">
                                <img className="section-marker" src={SectionCompleteMarker} alt="Section completed" />
                            </div>
                        </div>
                    </div>

                    <div className="view-row result-stat-row">
                        <div className="flex-columns result-sections-holder">
                            <div className="shrink">
                                <img className="result-att-icon-small" src={AttIconSmall} alt="" />
                            </div>
                            <div className="grow">
                                <p className="result-section-label">Skills Builder Badges</p>
                            </div>
                            <div className="shrink">
                                <p className={"result-section-stat" + badgesSectionCompleteClass} aria-label={badgesCompleted + ' out of ' + GameState.badgeProgress.length + ' complete'}>{badgesCompleted} / {GameState.badgeProgress.length} complete</p>
                            </div>
                            <div className="shrink">
                                <img className="section-marker" src={badgesCompleted === GameState.badgeProgress.length ? SectionCompleteMarker : SectionIncompleteMarker} alt={badgesCompleted === GameState.badgeProgress.length ? 'Section completed' : 'Section not completed'} />
                            </div>
                        </div>
                    </div>

                    <div className="view-row result-stat-row">
                        <div className="flex-columns result-sections-holder">
                            <div className="shrink">
                                <img className="result-selfie-icon-small" src={SelfieIconSmall} alt="" />
                            </div>
                            <div className="grow">
                                <p className="result-section-label" aria-label="Hobbies and Interests">Hobbies + Interests</p>
                            </div>
                            <div className="shrink">
                                <p className={"result-section-stat" + selfieSectionCompleteClass} aria-label={GameState.selfiesTaken.length + ' out of 5 complete'}>{GameState.selfiesTaken.length} / 5 complete</p>
                            </div>
                            <div className="shrink">
                                <img className="section-marker" src={GameState.selfiesTaken.length === SELFIE_LOCATIONS.length ? SectionCompleteMarker : SectionIncompleteMarker} alt={GameState.selfiesTaken.length === SELFIE_LOCATIONS.length ? 'Section completed' : 'Section not completed'} />
                            </div>
                        </div>
                    </div>

                    <div className="view-row grow"></div>
                    <div className="view-row grow"></div>
                    <div className="view-row grow"></div>

                    <div className="view-row shrink">
                        <img className="take-selfie-button active-button" src={ContinueButton} onClick={this.closePanel} alt="Click to continue" />
                    </div>

                    <div className="view-row grow"></div>

                </div>
            </div>
        );
    }
}