import { KOKO_DEBUG, kokoDebug } from '../model/config'
import * as PIXI from 'pixi.js'
import { Sprite } from 'pixi.js';
import app from '../../../koko-framework/app'
import { FRAME_TIME, FRAME_DELTA_LIMIT } from '../model/config'
import gsap from 'gsap'
import { ROTATE_TIME, ASSET_SCALE, POP_SCALE} from '../model/GameConfig'

export class PipeSprite extends PIXI.Container{
    // Member properties
    currentRotation = 0;
    spriteID = null;
    isStraightPiece = false;

    // Member functions
    constructor() {
        super();
        this.init();
    }

    init = () => {
        this.currentRotation = 0;
    }

    rotate = (isSetupRotation = false) => {
        let success = false;
        if(isSetupRotation)
        {
            //This is the starting rotation setup, prevents animation and just sets the curentRotation and actula rotation with animating 
            if( this.currentRotation != 1)
            {
                
                //This if function prevents the setuip rotation ever going back to the correct position
                this.currentRotation --;
                if(this.currentRotation < 0) this.currentRotation = 3;
                console.log(this.getTargetRotation())
                this.rotation = this.getTargetRotation();
                success = true; 
            }
        }
        else
        {
            this.currentRotation ++;
            if(this.currentRotation == 4) this.currentRotation = 0;
            let targetRot = this.getTargetRotation();
            if(targetRot == 0) targetRot = this.getTargetRotation(true);

            console.log(targetRot)
            gsap.to(this, {duration:ROTATE_TIME, rotation:targetRot, onComplete:function(){this.fixRotations()}.bind(this)});
            gsap.to(this.scale, {duration:ROTATE_TIME/2, x:POP_SCALE*ASSET_SCALE, y:POP_SCALE*ASSET_SCALE});
            gsap.to(this.scale,{delay:ROTATE_TIME/2, duration:ROTATE_TIME, x:ASSET_SCALE, y:ASSET_SCALE});
            success = true
        }
        return success;
    }

    addGraphic(targetID)
    {
        let tileSprite = PIXI.Sprite.from(targetID+'.png');
        this.scale.x = this.scale.y = ASSET_SCALE;
        tileSprite.x = -tileSprite.width/2;
        tileSprite.y = -tileSprite.height/2;
        this.addChild(tileSprite);
    }

    fixRotations = () =>{
        this.rotation = this.getTargetRotation();
    }

    getTargetRotation = (override) =>{

        let targetDeg = override ? 360 : this.currentRotation * 90;
        let targetRad = targetDeg * (Math.PI/180);
        return targetRad;
    }

    isCorrectPosiiton = () =>{ 
        
        let success = false;
        if( this.currentRotation == 0 || (this.currentRotation == 2 && this.isStraightPiece))
        {
            success = true;
        }
        return success
    }



}