import gsap from 'gsap'
import { KOKO_DEBUG, kokoDebug } from '../model/config'
import { LIQUID_VOLUME_TOTAL} from '../model/GameConfig'

export class LiquidObject {
    // member properties
    colour = 0x000000; //eg [uint] 0xFFFFFF
    volume = LIQUID_VOLUME_TOTAL; //Any [int], larger values allow for more intricate pours
    animatedVolume = 0;
    maskID = 0;
    // member functions
    constructor(colour, volume, maskID, forceFill) {
        this.colour = colour;
        this.volume = volume;
        this.animatedVolume = 0.001;
        gsap.to(this, {duration:.5, animatedVolume:volume});
        this.maskID = maskID;
    }

    addLiquid = (amount) => {
        console.log('ADD LIQUID')
        this.volume += amount;
        if(this.volume > LIQUID_VOLUME_TOTAL) this.volume = LIQUID_VOLUME_TOTAL;    
        gsap.to(this, {duration:.5, animatedVolume:this.volume});
        console.log(this.volume, this.animatedVolume)
    }

    removeLiquid=(vol)=>{
       
        this.volume -= vol;
        console.log('removeLiquid', this.animatedVolume,  this.volume)
        gsap.to(this, {duration:.2, animatedVolume:this.volume});
        if(this.volume < 0) 
        {
            this.volume = 0;
            return true; //Tell the caller that no liquid is left in this object
        }
        return false; //Tell the caller that there is liquid left in this object
    }

    mergeLiquid(liquidObj, vol){
        //This function attempts to pour the liquidObj into this object
        //The liquid being poured should be liquidObj
        let success = false;
        if(liquidObj.colour == this.colour)
        {
            //Only merge the liquid if the colours are matching
            this.addLiquid(vol);
            liquidObj.removeLiquid(vol);
            kokoDebug('Merge Liquid: Orig - '+ this.volume+': ToAdd - '+liquidObj.volume);
            success = true; //Tell the caller that the liquids were merged
        }

        return success; //Success status if the colours were merged or not
    }
    getLiquidVolume()
    {
        return this.volume;
    }



}