import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import game from '../../../controller/GameController';
import { au, v } from '../../../koko-framework/shorthand';
import WorldController from "../../../controller/WorldController";
import BabcockController from "../../controller/BabcockController";
import { NEIGHBOUR_DIALOG } from "../../model/dialog";

import BusBack from '../../../assets/babcock_menus/map_transitions/busBack.png';
import BusImg from '../../../assets/babcock_menus/map_transitions/busFront.png';
import BusWheel from '../../../assets/babcock_menus/map_transitions/busWheel.png';
import ProgressBg from '../../../assets/babcock_menus/map_transitions/progressDots.png';
import BusIconActive from '../../../assets/babcock_menus/map_transitions/busicon_active.png';
import BusIconInactive from '../../../assets/babcock_menus/map_transitions/busicon_inactive.png';

import TaxiImg from '../../../assets/babcock_menus/map_transitions/taxi.png';
import TaxiWheel from '../../../assets/babcock_menus/map_transitions/taxiWheel.png';

import TaxiIconActive from '../../../assets/babcock_menus/map_transitions/Taxiicon_active.png';
import TaxiIconInactive from '../../../assets/babcock_menus/map_transitions/Taxiicon_inactive.png';
import { GameState } from "../../model/GameState";

import ReactGA from 'react-ga4';

export default class MapTransition extends ReactFullScreenView {
    constructor(props) {
        super(props);
        this.state.progressFilling = false;
        this.state.reachDestination = false;
    }

    componentDidMount() {
        super.componentDidMount();
        if (!this.state.progressFilling) {
            setTimeout(() => this.setState({progressFilling: true}), 300);
            setTimeout(this.arriveAtDest, 2300);
            setTimeout(this.closePanel, 3500);
        }

        if (GameState.gettingLift) {
            au.play('taxidriveloop', 1, true, 0);
        } else {
            au.play('busdriveloop', 1, true, 0);
        }

        ReactGA.send({ hitType: "pageview", page: "/map-transition/" + (GameState.gettingLift ? "taxi" : "bus") });
    }

    arriveAtDest = () => {
        this.setState({reachDestination: true});
        if (GameState.gettingLift) {
            au.stop('taxidriveloop');
            au.play('taxiarrive', 1, false, 0);
        } else {
            au.stop('busdriveloop');
            au.play('busarrive', 1, false, 0);
        }
    }

    closePanel = () => {
        v.hideView(this.props.viewId);
        WorldController.enable();

        if (GameState.gettingLift) {
            GameState.gettingLift = false;
            setTimeout(this.showLiftDialog, 500);
        }
    }

    showLiftDialog = () => {
        BabcockController.showInteractionDialog('Lift done...', NEIGHBOUR_DIALOG.getLiftAfter);
    }

    renderContent() {
        let fillClasses = 'transition-progress-fill' + (this.state.progressFilling ? ' tp-full' : '');
        return (
            <div className="full-screen-holder map-transition-bg no-select default-cursor rel">
                <div className="trans-bg-scroll-back-layer" style={this.state.progressFilling ? {left: -400} : {}}></div>
                <div className="trans-bg-scroll-front-layer" style={this.state.progressFilling ? {left: -700} : {}}></div>
                {GameState.gettingLift === false &&
                    <>
                        <img className="transition-vehicle-img" src={BusBack} alt="" />
                        <img className="bus-wheel" src={BusWheel} style={{left: "calc(50% - 113px)"}} alt="" />
                        <img className="bus-wheel" src={BusWheel} style={{left: "calc(50% + 82px)"}} alt="" />
                    </>
                }
                <img className="transition-vehicle-img" src={GameState.gettingLift ? TaxiImg : BusImg} alt={GameState.gettingLift ? "Taxi" : "Bus"} />
                {GameState.gettingLift === true &&
                    <>
                        <img className="taxi-wheel" src={TaxiWheel} style={{left: "calc(50% - 89px)"}} alt="" />
                        <img className="taxi-wheel" src={TaxiWheel} style={{left: "calc(50% + 52px)"}} alt="" />
                    </>
                }
                <div className="transition-progress-holder">
                    <div className="transition-progress-inner">
                        <img className="transition-progress-bg" src={ProgressBg} alt="" />
                        <div className={fillClasses}></div>
                        <img className="transition-icon transition-start-icon" src={GameState.gettingLift ? TaxiIconActive : BusIconActive} alt="" />
                        <img className="transition-icon transition-end-icon" src={this.state.reachDestination ? GameState.gettingLift ? TaxiIconActive : BusIconActive : GameState.gettingLift ? TaxiIconInactive : BusIconInactive} alt="" />
                    </div>
                </div>
            </div>
        );
    }
}