import _ from 'underscore'
import gsap from 'gsap'
import { Power2 } from 'gsap'
import koko from '../koko-framework/koko'
import { v, a, c } from '../koko-framework/shorthand'
import app from '../koko-framework/app'
import main from '../main'
import game from '../controller/GameController'
import {REACT_PRELOADER} from '../babcock-neet/view/react-views/PreloadReactAssets'
import * as PIXI from 'pixi.js'
import { setupClicker, setHtmlClicker, removeHtmlClickers } from '../koko-framework/ui/ButtonController'
import { GameState } from '../babcock-neet/model/GameState'

export class PreloaderView extends koko.view {
    constructor () {
        super();

        this.updateLoadingBar = this.updateLoadingBar.bind(this);
        this.finishLoading = this.finishLoading.bind(this);
    }

    assets = [
        // { id: 'preloader', src: 'img/preloader_assets.json' },
        { id: 'preloader_babcock', src: 'img/preloader.json' },
        { id: 'font', src: 'img/fonts/HelvBold.fnt' },
        // { id: 'bg', src: 'img/bluebg.png' },
    ];

    gfxPerc = 0;

    loadedSounds = false;
    loadedGraphics = false;

    disabled = false;
    mobileTapToStart = false;

    loaderBarBg = null;
    loaderBarMask = null;
    loaderBarFill = null;
    
    buildView () {
        var g = app.guiSize, vp = app.viewPort;

        this.children = koko.display.buildLayout([
            /*
            { type: 'sprite', name:'bg', id: 'bg', x: vp.centerX, y: vp.centerY, regX: 1388, regY: 765},
            { type: 'sprite', name: 'greyLine', id: 'loadingbar', x: vp.centerX, y: vp.centerY + 100, regX: 292, regY: 0, width: 584, height: 92 },
            */

            { type: 'sprite', name: 'bg', id: 'bg-grad', anchorX: 0.5, anchorY: 0.5, x: vp.centerX, y: vp.centerY, width: g.width, height: g.height },

            { type: 'sprite', name: 'logo', id: 'logo', anchorX: 0.5, anchorY: 0.5, x: vp.centerX, y: vp.centerY - 150, scaleX: 0.5, scaleY: 0.5},

            { type: 'container', name: 'barContainer', x: vp.centerX -156, y: vp.centerY + 150 },

            { type: 'bmptext', text: '0%', font: 'HelveticaBold', size: 24, align: 'center', tint: 0xffffff, name: 'loadingText', x: vp.centerX, y: vp.centerY + 150},
            // { type: 'sprite', name: 'pokeball', id: 'pokeball_sillhouette', x: vp.centerX, y: vp.centerY, regX: 115.5, regY: 116, width: 115.5, height: 116},

            { type: 'sprite', name: 'startBtn', id: 'start_btn', anchorX: 0.5, anchorY: 0.5, scaleX: 0.5, scaleY: 0.5, x: vp.centerX, y: vp.centerY + 300},
        ], this.container);

        this.children.loadingText.anchor.x = this.children.loadingText.anchor.y = 0.5;
        // gsap.to(this.children.pokeball, {rotation: Math.PI * 2, ease: Power2.easeInOut, repeat: -1, duration: 1});

        
        this.loaderBarBg = new PIXI.Graphics();
        this.loaderBarBg.beginFill(0x0B346B);
        this.loaderBarBg.drawRoundedRect(0, -20, 312, 40, 20);
        this.loaderBarBg.endFill();
        this.children.barContainer.addChild(this.loaderBarBg);

        this.loaderBarFill = new PIXI.Graphics();
        this.loaderBarFill.beginFill(0xE71F85);
        this.loaderBarFill.drawRoundedRect(0, -20, 312, 40, 20);
        this.loaderBarFill.endFill();
        this.loaderBarFill.x = -312;
        this.children.barContainer.addChild(this.loaderBarFill);

        this.loaderBarMask = new PIXI.Graphics();
        this.loaderBarMask.beginFill(0x0B346B);
        this.loaderBarMask.drawRoundedRect(0, -20, 312, 40, 20);
        this.loaderBarMask.endFill();
        this.children.barContainer.addChild(this.loaderBarMask);
        this.loaderBarFill.mask = this.loaderBarMask;

        this.children.startBtn.visible = false;
        this.children.startBtn.alpha = 0;
    }

    updateLoadingBar () {
        var p = this.gfxPerc;

        if (main.soundsToLoad > 0) {
            p *= 0.5;
            p += koko.audio.loaded / main.soundsToLoad * 0.5;
            if (koko.audio.loaded >= main.soundsToLoad) {
                this.loadedSounds = true;
            }
        }

        // this.children.fillLine.scale.x = p;
        this.children.loadingText.text = Math.floor(p * 100) + '%';
        this.loaderBarFill.x = -312 + 312 * p;

        if (this.loadedSounds && this.loadedGraphics) {
            this.children.loadingText.text = '100%';
            this.loaderBarFill.x = 0;

            setTimeout(this.finishLoading, 500);
        }

        gsap.killTweensOf(this.finishLoading);
        gsap.delayedCall(2, this.finishLoading);

        // console.log('perc: ', p);
    }

    transitionIn () {
        if (_.size(this.children) === 0) {
            this.buildView();
            this.show();
        }

        // load the assets
        main.setupAudio();

        this.loadedGraphics = false;
        this.loadedSounds = main.soundsToLoad > 0 ? false : true;
        this.loadedTimeWait = false;

        document.body.insertBefore(app.renderer.view, null);

        // load the graphics for the rest of the game
        a.loadManifest(function (perc) {
            this.gfxPerc = perc;
            this.updateLoadingBar();
        }.bind(this), function () {
            this.loadedGraphics = true;
        }.bind(this));


        // load audio
        if (main.soundsToLoad > 0) {
            koko.audio.progressHandler = this.updateLoadingBar;
            koko.audio.load();
            //koko.audio.giveupTimer(5000);
        }

        this.show();

        REACT_PRELOADER.ref.queueUpAllAssets();
    }

    finishLoading () {
        gsap.killTweensOf(this.finishLoading);
        if (this.loadedSounds && this.loadedGraphics) {
            // gsap.killTweensOf(this.children.pokeball);
            //tap to start
            /*
            if (c.system.isMobile && this.mobileTapToStart) {
                var tappedToStart = function (e) {
                    this.transitionOut();
                    app.renderer.view.removeEventListener(c.eventName('down'), tappedToStart);
                }.bind(this);

                app.renderer.view.addEventListener(c.eventName('down'), tappedToStart);
            } else {
                this.transitionOut();
            }
            */
            this.children.loadingText.text = '100%';
            this.loaderBarFill.x = 0;

            this.children.startBtn.visible = true;
            gsap.to(this.children.startBtn, {duration: 0.35, alpha: 1});
            // setupClicker(this.children.startBtn, this.transitionOut, '', false, true);
            setHtmlClicker(this.children.startBtn, this.transitionOut, true, '', 'Click to Start');

        } else {
            gsap.delayedCall(1, this.finishLoading);
        }
    }

    transitionOut = () => {
        if (!this.disabled) {
            this.disabled = true;
            
            removeHtmlClickers();
            gsap.to(this.container, {alpha: 0, duration: 0.35, onComplete: this.transitionOutDone});
        }
    }

    transitionOutDone = () => {
        this.loaderBarBg.destroy();
        this.loaderBarFill.destroy();
        this.loaderBarMask.destroy();

        this.disable();
        this.destroyView();
        this.hide();

        this.gotoApp();
    }

    gotoApp () {
        console.log("----------------------------------------------------");
        console.log("KOKO / BABCOCK CV GAME PROJECT - v1.0.0")
        console.log("----------------------------------------------------");
        // v.each(['mainmenu'], 'transitionIn');
        // v.each([/*'ingamehud',*/ 'ingame'], 'transitionIn');
        v.showView('mainmenu');
        // v.showView('maingameover');
        // v.showView('questions');
        if (main.cookiesAccepted === false) {
            v.showView('cookiespanel');
        } else {
            GameState.firstPlay = false;
        }
        // v.showView('advisordialog');
        /*
        game.initWorld();
        game.gotoWorldView(true);
        */
    }
}
