import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import game from '../../../controller/GameController';
import { au, v } from '../../../koko-framework/shorthand';
import WorldController from "../../../controller/WorldController";

import BgBurst from '../../../assets/babcock_menus/select_character/bg_burst.png';
import CoffeeRewardIcon from '../../../assets/babcock_menus/mini_game_flow/Coffee_rewardUnlock.png';
import BabcockController from "../../controller/BabcockController";

export default class GameReward extends ReactFullScreenView {
    constructor(props) {
        super(props);
        
        this.state.rewardData = BabcockController.gameRewardQueue[0];
        // console.log('Showing reward: ', this.state.rewardData);
    }

    componentDidMount() {
        super.componentDidMount();

        setTimeout(this.closePanel, 2500);

        au.play('tutorial', 1, false, 0, false);
    }

    closePanel = () => {
        v.hideView(this.props.viewId);

        BabcockController.gameRewardQueue.splice(0, 1);
        if (BabcockController.gameRewardQueue.length > 0) {
            setTimeout(this.checkNextReward, 420);
        } else {
            v.showViews(['endofgame', 'hudview']);
        }
    }

    checkNextReward = () => {
        if (BabcockController.gameRewardQueue.length > 0) {
            // console.log('Next reward!');
            v.showView('gamereward');
        } else {
            v.showViews(['endofgame', 'hudview']);
        }
    }

    renderContent() {
        return (
            <div className="full-screen-holder reward-bg no-select default-cursor rel">

                <img className="reward-icon" src={this.state.rewardData.icon} alt={this.state.rewardData.title}/>

                <div className="view-row grow"></div>
                <div className="view-row shrink">
                    <p className="white-header bold-text centered">{this.state.rewardData.title}<br />Unlocked!</p>
                </div>

                <div className="reward-mid-holder"></div>

                <div className="view-row shrink">
                    <p className="white-sub-heading bold-text">- {this.state.rewardData.itemName} -</p>
                </div>
                <div className="view-row shrink">
                    <p className="white-sub-heading">{this.state.rewardData.addedText}</p>
                </div>

                <div className="view-row grow"></div>
            </div>
        );
    }
}