import React from "react";
import { au, v } from '../../../koko-framework/shorthand';
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import TwitterButton from "../../../assets/babcock_menus/Tw_btn.png";
import FacebookButton from "../../../assets/babcock_menus/fb_btn.png";
import CloseButton from '../../../assets/babcock_menus/close_btn.png';
import { fb, tw } from "../../../koko-framework/social";

import ReactGA from 'react-ga4';

const SHARE_MESSAGE = 'Play our new click-adventure game \'The Next Steps\' and find out about exciting apprenticeship opportunities with Babcock International - play now!'
const SHARE_RAW_HASHTAGS = '#TheNextSteps #BabcockInternational #Apprenticeships';
const SHARE_URL = 'https://thenext-steps.co.uk';
const FB_HASHTAG = 'Apprenticeships';
const TW_HASHTAG = 'TheNextSteps,BabcockInternational,Apprenticeships';

export default class SharePanel extends ReactFullScreenView {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        super.componentDidMount();

        ReactGA.send({ hitType: "pageview", page: "/share-panel/" });
    }

    closePanel = () => {
        v.hideView(this.props.viewId);

        au.play('closebtn', 1, false, 0, false);
    }

    shareFb = () => {
        fb.share_ui(SHARE_MESSAGE + ' ' + SHARE_RAW_HASHTAGS, FB_HASHTAG, SHARE_URL);

        ReactGA.event({
            category: "UI Action",
            action: "Facebook Share Clicked",
        });
    }

    shareTw = () => {
        tw.share_simple(SHARE_MESSAGE, TW_HASHTAG, SHARE_URL);

        ReactGA.event({
            category: "UI Action",
            action: "Twitter Share Clicked",
        });
    }

    renderContent() {
        return (
            <div className="full-screen-holder no-select default-cursor">
                <div className="full-screen-holder black-out rel">
                    <div className="name-entry-panel rel">
                        <div className="view-row grow"></div>
                        <div className="view-row grow"></div>
                        <div className="view-row shrink">
                            <p className="blue-header bold-text">Share Game</p>
                        </div>
                        <div className="view-row shrink centered">
                            <p className="grey-info-text">Share the game online to let others<br />learn more about some of the<br />fantastic opportunities available with<br />Babcock International.</p>
                        </div>
                        <div className="view-row grow"></div>

                        <img className="share-button active-button" src={FacebookButton} onClick={this.shareFb} alt="Click to share on Facebook" />

                        <img className="share-button active-button" src={TwitterButton} onClick={this.shareTw} alt="Click to share on X" />

                        <div className="view-row grow"></div>
                        <div className="view-row grow"></div>

                        <div className="abs" style={{right: -10, top: -10}}>
                            <img className="close-button active-button" src={CloseButton} onClick={this.closePanel} alt="Click to close this panel" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}