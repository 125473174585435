/**
 * Map Layouts can consist of re-used map parts, although it's probably unlikely we will use them this way.
 * Layouts still need to be 2D Arrays, however.
 */
export const MapLayouts = {
    city_north: [['city_north']],
    city_centre: [['city_centre']],

    /*
    cobbler_square: [['cobbler_square']],
    market: [['market']],
    mansions: [['mansions']],
    safehouse_gardens: [['safehouse_gardens']],
    stoke_park: [['stoke_park']],
    */

    /*
    map_middle: [['map_middle']],
    map_left: [['map_left']],
    /*
    KloosTestMap:
        [['CityTest1']],
    DemoMap:
        [['DemoMap', 'DemoMap', 'DemoMap'],
            ['DemoMap', 'DemoMap', 'DemoMap'],
            ['DemoMap', 'DemoMap', 'DemoMap'],
        ],
    house1:
        [['House1']],
    village: 
        [['VillageMap']],
    forest:
        [['ForestMap']],
    houseinterior1:
        [['houseinterior1']],
        */
}