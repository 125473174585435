import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import game from '../../../controller/GameController';
import { au, v } from '../../../koko-framework/shorthand';
import ApiController from "../../controller/ApiController";

import BabcockLogo from '../../../assets/babcock_menus/main_menu/babcock_logo.png';
import PlymouthLogo from '../../../assets/babcock_menus/main_menu/plymouth_logo.png';
import BigLogo from '../../../assets/babcock_menus/main_menu/logo_placeholder.png';
import PlayButton from '../../../assets/babcock_menus/main_menu/playgame_btn.png';
import SoundOffButton from '../../../assets/babcock_menus/sound_off.png';
import SoundOnButton from '../../../assets/babcock_menus/sound_on.png';
import ShareButton from '../../../assets/babcock_menus/main_menu/share_btn.png';
import { ResetGameState } from "../../model/GameState";

import Lottie from 'react-lottie-player';
import LogoInAnim from '../../../assets/babcock_menus/logoAnim.json';
import { MuteButton } from "./components/mute-button";
import main from "../../../main";

import ReactGA from 'react-ga4';

export default class MainMenu extends ReactFullScreenView {
    constructor(props) {
        super(props);

        this.state.showExitAnim = false;
        this.state.showAnim = main.cookiesAccepted === true;

        // v.showView('blackbg');
    }

    componentDidMount() {
        super.componentDidMount();

        au.play('menu_music', 1, true, 0, false);

        ReactGA.send({ hitType: "pageview", page: "/main-menu/" });
    }

    playClickHandler = () => {
        au.play('primarybtn', 1, false, 0, true);

        /*
        ResetGameState();
        v.hideView('mainmenu');
        v.showView('selectcharacter');
        */

        /*
        if (!this.state.showExitAnim) {
            this.setState({showExitAnim: true});
            setTimeout(this.startNow, 2500);
        }
        */

        this.startNow();
        // get our token and stuff
        ApiController.init();
    }

    startNow = () => {
        ResetGameState();
        v.hideView('mainmenu');
        v.showView('selectcharacter');
    }

    cookiesAccepted = () => {
        this.setState({showAnim: true});
    }

    showSharePanel = () => {
        v.showView('sharepanel');
    }

    renderContent() {
        return (
            <div className="full-screen-holder main-menu no-select default-cursor">
                <div className="view-row shrink" style={{zIndex: 10}}>
                    <div className="shrink left">
                        <img className="babcock-menu-logo" src={BabcockLogo} alt="Babcock logo" />
                    </div>
                    <div className="grow centered">
                    </div>
                    <div className="shrink right">
                        <img className="plymouth-menu-logo" src={PlymouthLogo} alt="Plymouth City Council logo" />
                    </div>
                </div>
                <div className="view-row grow"></div>
                <div className="view-row grow" style={{zIndex: 1}}>
                    <div className="centered menu-logo-holder rel" aria-label="The Next Steps logo">
                        <div className="big-menu-logo">
                            {this.state.showAnim && !this.state.showExitAnim &&
                                <Lottie 
                                    animationData={LogoInAnim}
                                    play
                                    loop = {false}
                                    style={{ width: 583, height: 518 }}
                                />
                            }
                            {this.state.showAnim && this.state.showExitAnim &&
                                <Lottie 
                                    animationData={LogoInAnim}
                                    play
                                    loop = {false}
                                    direction = {-1}
                                    style={{ width: 583, height: 518 }}
                                />
                            }
                        </div>
                        {/*<img className="big-menu-logo" src={BigLogo} />*/}
                    </div>
                </div>
                <div className="view-row grow" style={{zIndex: 10}}>
                    <div className="grow centered">
                        <p className="menu-blurb">Play 'The Next Steps' - our exciting click adventure game that aims to help you understand what makes a good CV and learn more about the apprentice application process.</p>
                    </div>
                </div>
                <div className="view-row grow"></div>
                <div className="view-row grow">
                    <div className="grow centered">
                        <img className="menu-play-button active-button" src={PlayButton} onClick={this.playClickHandler} alt="Click to Play" />
                        <br />
                        <MuteButton />
                        <img className="menu-share-button active-button" src={ShareButton} onClick={this.showSharePanel} alt="Click to Share on Social Media" />
                    </div>
                </div>
                <div className="view-row shrink">
                    <div className="grow centered">
                        <p className="copyright" aria-label="Copyright 2024 Babcock International Group PLC">&#169; 2024 Babcock International Group PLC</p>
                    </div>
                </div>
            </div>
        );
    }
}