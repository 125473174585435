import { GameState } from "../model/GameState";
const API_URL = 'https://babcockgame.kokodigital.co.uk/api/'; // 'https://thenext-steps.co.uk/api/'; // 'https://neetshtmlgame1.azurewebsites.net/api/'; // 'https://babcockgame.kokodigital.co.uk/api/'

class ApiController {
    token = null;
    guid = null;
    resubmitContactData = false;

    init = () => {
        this.token = null;
        this.guid = null;
        this.getToken();
    }

    getToken = () => {
        console.log('[API] Fetch token');
        // don't bother if we are running locally as it will crash with a cors error
        if (window.location.hostname !== 'localhost') {
            fetch(API_URL + 'token')
            .then(response => response.json())
            .then(data => {
                if (data.response.code === "200") {
                    this.guid = data.response.guid;
                    this.token = data.response.token;
                    if (this.resubmitContactData) {
                        this.resubmitContactData = false;
                        this.submitContactData();
                    }
                    console.log('[API] Got token: ', data, this);
                } else {
                    this.getToken();
                }
            })
        }
    }

    submitContactData = () => {
        console.log('[API] Submitting contact details: ', this, GameState);
        if (this.token && this.guid) {
            let dataToSubmit = {
                data: {
                    player_firstname: GameState.name,
                    player_lastname: GameState.lastName,
                    player_email: GameState.email,
                    player_age: GameState.age,
                    player_terms_agreement: GameState.termsAgreed,
                    player_privacy_agreement: GameState.privacyAgreed,
                    player_marketing_optin: GameState.marketingOptIn,

                    player_token: this.token,
                    player_guid: this.guid,
                }
            }
            console.log('[API] Submitting data: ', dataToSubmit);
            fetch(API_URL + 'gamedata', {
                headers: { "Content-Type": "application/json; charset=utf-8" },
                method: 'POST',
                body: JSON.stringify(dataToSubmit)
            })
                .then(response => response.json())
                .then(data => console.log(data))
        } else {
            this.resubmitContactData = true;
            this.getToken();
        }
    }

    // using a timeout here so we can drop the call to trigger it in lots of places
    // without triggering silly amount of requests to the server.
    sendDataTimeoutId = -1;
    triggerGameDataDump = () => {
        clearTimeout(this.sendDataTimeoutId);
        this.sendDataTimeoutId = setTimeout(this.sendGameData, 1000);
    }

    sendGameData = () => {
        console.log('[API] Submitting game data: ', this, GameState);
        if (this.token && this.guid) {
            let selfieData = [];
            for (let i = 0; i < GameState.selfiesTaken.length; i++) {
                selfieData.push(GameState.selfiesTaken[i]);
            }

            let tasksCompleted = [];
            for (let i = 0; i < GameState.npcTasksCompleted.length; i++) {
                tasksCompleted.push(GameState.npcTasksCompleted[i].id);
            }

            let gameResults = {
                pipesGame: {
                    stars: GameState.gameResults[0],
                    score: GameState.gameRecords[0],
                },
                coffeeGame: {
                    stars: GameState.gameResults[1],
                    score: GameState.gameRecords[1],
                },
                foodBankGame: {
                    stars: GameState.gameResults[2],
                    score: GameState.gameRecords[2],
                },
            };

            let questionsAnswered = [];
            for (let i = 0; i < GameState.questionsAnswered.length; i++) {
                questionsAnswered.push({
                    question: GameState.questionsAnswered[i].questionData.question,
                    answer: GameState.questionsAnswered[i].questionData.answers[GameState.questionsAnswered[i].chosenAnswer],
                })
            }

            let badgeProgress = [];
            for (let i = 0; i < GameState.badgeProgress.length; i++) {
                badgeProgress.push({
                    badge: GameState.badgeProgress[i].config.name,
                    progress: Math.round((GameState.badgeProgress[i].progress / GameState.badgeProgress[i].config.targetValue) * 100) + '%',
                })
            }

            let gameDataDump = {
                selectedCharacter: GameState.selectedCharacter + 1,
                photosTaken: selfieData,
                tasksCompleted: tasksCompleted,
                gamesResults: gameResults,
                questionsAnswered: questionsAnswered,
                badgeProgress: badgeProgress,
                overAllCvProgress: '0%',
                cvSent: GameState.applicationSent,
                gotLift: GameState.gotLift,
                gameComplete: GameState.gameComplete,
            }

            let dataToSubmit = {
                data: {
                    player_gamedata: JSON.stringify(gameDataDump),

                    player_token: this.token,
                    player_guid: this.guid,
                }
            }

            console.log('[API] Submitting game data: ', dataToSubmit);
            fetch(API_URL + 'gamedata', {
                headers: { "Content-Type": "application/json; charset=utf-8" },
                method: 'POST',
                body: JSON.stringify(dataToSubmit)
            })
                .then(response => response.json())
                .then(data => console.log(data))
        } else {
            this.resubmitContactData = true;
            this.getToken();
        }
    }
}

const API_CONTROLLER = new ApiController();
export {API_CONTROLLER as default}