import gsap from 'gsap'
import koko from '../../koko-framework/koko'
import app from '../../koko-framework/app'
import { au, v } from '../../koko-framework/shorthand';
import * as PIXI from 'pixi.js'
import { removeHtmlClickers, setHtmlClicker, setupClicker } from '../../koko-framework/ui/ButtonController'
import WorldController from '../../controller/WorldController';
import { GameState, GetCvPercentage, GetCvStarsEarned } from '../model/GameState';
import * as BABCOCK_CONSTANTS from '../model/constants';
import BabcockController from '../controller/BabcockController';

export class HudView extends koko.view {
    knockedBack = false;

    buildView() {
        var g = app.guiSize, vp = app.viewPort;

        this.children = koko.display.buildLayout([
            {name: 'hudContainer', type: 'container'},

            {name: 'leftContainer', type: 'container', parent: 'hudContainer', x: vp.left + 20, y: vp.top + 20},

                {name: 'bagContainer', type: 'container', parent: 'leftContainer', x: 0, y: 0},
                    {name: 'bagIcon', parent: 'bagContainer', type: 'sprite', id: 'inventory', anchorX: 0.5, anchorY: 0.5, scaleX: 0.5, scaleY: 0.5, x: 48, y: 50},
                    {name: 'bagAlert', parent: 'bagContainer', type: 'sprite', id: 'alert_icon', anchorX: 0.5, anchorY: 0.5, scaleX: 0.5, scaleY: 0.5, x: 85, y: 15},

                {name: 'taskListContainer', type: 'container', parent: 'leftContainer', y: 120},
                    {name: 'taskListIcon', parent: 'taskListContainer', type: 'sprite', id: 'taskList_btn', anchorX: 0.5, anchorY: 0.5, scaleX: 0.5, scaleY: 0.5, x: 48, y: 50},

                {name: 'cvContainer', type: 'container', parent: 'leftContainer', x: 110, y: 0},
                    {name: 'cvBG', parent: 'cvContainer', type: 'sprite', id: 'CV', anchorX: 0.5, anchorY: 0.5, scaleX: 0.5, scaleY: 0.5, x: 134, y: 51},
                    {name: 'cvAlert', parent: 'cvContainer', type: 'sprite', id: 'alert_icon', anchorX: 0.5, anchorY: 0.5, scaleX: 0.5, scaleY: 0.5, x: 85, y: 15},
                    {name: 'cvStar1', parent: 'cvContainer', type: 'sprite', id: 'star_unlocked', anchorX: 0.5, anchorY: 0.5, scaleX: 0.5, scaleY: 0.5, x: 114, y: 37},
                    {name: 'cvStar2', parent: 'cvContainer', type: 'sprite', id: 'star_unlocked', anchorX: 0.5, anchorY: 0.5, scaleX: 0.5, scaleY: 0.5, x: 146, y: 37},
                    {name: 'cvStar3', parent: 'cvContainer', type: 'sprite', id: 'star_unlocked', anchorX: 0.5, anchorY: 0.5, scaleX: 0.5, scaleY: 0.5, x: 179, y: 37},
                    {name: 'cvStar4', parent: 'cvContainer', type: 'sprite', id: 'star_unlocked', anchorX: 0.5, anchorY: 0.5, scaleX: 0.5, scaleY: 0.5, x: 212, y: 37},
                    {name: 'cvStar5', parent: 'cvContainer', type: 'sprite', id: 'star_unlocked', anchorX: 0.5, anchorY: 0.5, scaleX: 0.5, scaleY: 0.5, x: 244, y: 37},

                    {name: 'cvProgressContainer', type: 'container', parent: 'cvContainer', x: 101, y: 68},
                        // {name: 'cvProgressBack', parent: 'cvProgressContainer', type: 'sprite', id: 'progressbar', anchorX: 0, anchorY: 0.5, scaleX: 0.5, scaleY: 0.5, x: 0, y: 0},
                    {name: 'cvProgressTextContainer', type: 'container', parent: 'cvContainer', x: 101, y: 68},
                        { type: 'bmptext', text: '0%', font: 'HelveticaBold', size: 14, align: 'center', tint: 0xffffff, name: 'progressText', parent: 'cvProgressTextContainer', x: 78, y: 0, anchorX: 0.5, anchorY: 0.5},

            {name: 'rightContainer', type: 'container', parent: 'hudContainer', x: vp.right - 20, y: vp.top + 20},
                {name: 'settingButton', parent: 'rightContainer', type: 'sprite', id: 'settings_btn', anchorX: 0.5, anchorY: 0.5, scaleX: 0.5, scaleY: 0.5, x: -36, y: 55},
                {name: 'zoomout_btn', type: 'sprite', id: 'zoomout_btn', parent: 'rightContainer', x: -36, y: 200, anchorX: 0.5, anchorY: 0.5, scaleX: 0.5, scaleY: 0.5},
                {name: 'zoomin_btn', type: 'sprite', id: 'zoomin_btn', parent: 'rightContainer', x: -36, y: 140, anchorX: 0.5, anchorY: 0.5, scaleX: 0.5, scaleY: 0.5},

            {name: 'leadTaskFail', type: 'sprite', parent: 'hudContainer', id: 'youngpersonTask_dialogueFail.png', x: vp.centerX, y: vp.bottom - 20, anchorX: 0.5, anchorY: 1, scaleX: 0.5, scaleY: 0.5},
            {name: 'litterTaskInfo', type: 'sprite', parent: 'hudContainer', id: 'littergame_dialogue.png', x: vp.centerX, y: vp.bottom - 20, anchorX: 0.5, anchorY: 1, scaleX: 0.5, scaleY: 0.5},
            {name: 'leadNpcTaskInfo', type: 'sprite', parent: 'hudContainer', id: 'youngpersonTask_dialogue.png', x: vp.centerX, y: vp.bottom - 20, anchorX: 0.5, anchorY: 1, scaleX: 0.5, scaleY: 0.5},

        ], this.container);

        this.progressBarBack = new PIXI.Graphics();
        this.progressBarBack.beginFill(0x0B346B);
        this.progressBarBack.drawRoundedRect(0, -10, 156, 20, 4);
        this.progressBarBack.endFill();
        this.children.cvProgressContainer.addChild(this.progressBarBack);

        this.progressBarFill = new PIXI.Graphics();
        this.progressBarFill.beginFill(0xE71F85);
        this.progressBarFill.drawRoundedRect(0, -10, 156, 20, 4);
        this.progressBarFill.endFill();
        this.children.cvProgressContainer.addChild(this.progressBarFill);

        this.progressBarMask = new PIXI.Graphics();
        this.progressBarMask.beginFill(0);
        this.progressBarMask.drawRoundedRect(0, -10, 156, 20, 4);
        this.progressBarMask.endFill();
        this.children.cvProgressContainer.addChild(this.progressBarMask);
        this.progressBarFill.mask = this.progressBarMask;

        this.reflectGameState();
        this.resize();

        this.children.leadTaskFail.visible = false;
    }

    resize () {
        if (this.container.visible) {
            var g = app.guiSize, vp = app.viewPort;

            this.children.leftContainer.x = vp.left + 20;
            this.children.leftContainer.y = vp.top + 20;

            this.children.rightContainer.x = vp.right - 20;
            this.children.rightContainer.y = vp.top + 20;

            this.children.litterTaskInfo.x = vp.centerX;
            this.children.litterTaskInfo.y = vp.bottom - 20;

            this.children.leadNpcTaskInfo.x = vp.centerX;
            this.children.leadNpcTaskInfo.y = vp.bottom - 20;
        }
    }

    transitionIn (){
        this.buildView();
        this.show();
        this.enable();
        this.container.alpha = 0;
        if (GameState.currentGameTask === BABCOCK_CONSTANTS.INTRO_TASK) {
            this.fade();
        }
        gsap.to(this.container, {alpha:1, duration: 0.35});

        app.addResizeListener('hudview', function(){
            this.resize();
        }.bind(this));

        // These clickers will need removing if we go with the html style clickers
        setupClicker(this.children.settingButton, this.showSettings, '', false);
        setupClicker(this.children.bagContainer, this.showInventory, '', false);
        setupClicker(this.children.taskListContainer, this.showTaskList, '', false);
        setupClicker(this.children.cvContainer, this.showCv, '', false);
        setupClicker(this.children.zoomin_btn, this.zoomIn, '', false);
        setupClicker(this.children.zoomout_btn, this.zoomOut, '', false);
        

        this.reflectGameState();
    }

    transitionOut (){

        gsap.to(this.container, {alpha: 0, onComplete: () => {this.transitionOutDone()}, /*onCompleteScope:this,*/ duration: 0.35});

        app.removeResizeListener('hudview');

        removeHtmlClickers();
    }

    transitionOutDone = () => {
        // if (!this.disabled) {
            /*
            app.removeResizeListener('hudview');
            while (app.resizeListeners['hudview']) {
                app.removeResizeListener('hudview');
            }
            */

            this.progressBarBack.destroy();
            this.progressBarFill.destroy();
            this.progressBarMask.destroy();

            // this.disabled = true;
            this.disable();
            this.destroyView();
            this.hide();
        // }
    }

    showLeadTaskFailMessage = () => {
        if (this.container.visible) {
            this.children.leadTaskFail.visible = true;
            this.children.leadTaskFail.alpha = 0;
            gsap.to(this.children.leadTaskFail, {alpha: 1, duration: 0.3});
            gsap.to(this.children.leadTaskFail, {alpha: 0, duration: 0.3, delay: 3, onComplete: () => {this.children.leadTaskFail.visible = false}});
        }
    }

    showSettings = () => {
        v.showView('settings')
        WorldController.disable();

        au.play('secondarybtn', 1, false, 0, false);
    }

    showInventory = () => {
        v.showView('inventorypanel')
        WorldController.disable();

        au.play('primarybtn', 1, false, 0, false);
    }

    showCv = () => {
        v.showView('cvview')
        WorldController.disable();

        au.play('primarybtn', 1, false, 0, false);
    }

    showTaskList = () => {
        v.showView('tasklist');
        WorldController.disable();

        au.play('primarybtn', 1, false, 0, false);
    }

    zoomIn = () => {
        WorldController.zoomIn();

        au.play('secondarybtn', 1, false, 0, true);
    }

    zoomOut = () => {
        WorldController.zoomOut();

        au.play('secondarybtn', 1, false, 0, true);
    }

    fade = (alpha = 0.5) => {
        gsap.killTweensOf(this.children.hudContainer);
        gsap.to(this.children.hudContainer, {alpha: alpha, duration: 0.35});
        // this.container.alpha = alpha;
        if (alpha !== 1) {
            this.knockedBack = true;
            removeHtmlClickers();
        } else {
            this.knockedBack = false;
            this.setupHtmlClickers();
        }
    }

    reflectGameState = () => {
        if (this.container.visible) {
            this.children.bagAlert.visible = GameState.newInventoryItem;
            this.children.cvAlert.visible = GameState.newCvItem;
            let starsEarned = GetCvStarsEarned();
            for (let i = 1; i <= 5; i++) {
                let sprite = this.children['cvStar' + i];
                if (i <= starsEarned) {
                    sprite.visible = true;
                } else {
                    sprite.visible = false;
                }
            }

            this.children.litterTaskInfo.visible = GameState.currentSubTask === BABCOCK_CONSTANTS.COLLECT_LITTER_TASK;
            this.children.leadNpcTaskInfo.visible = GameState.currentSubTask === BABCOCK_CONSTANTS.LEAD_TO_DEST;

            this.children.bagContainer.visible = GameState.currentGameTask !== BABCOCK_CONSTANTS.INTRO_TASK && GameState.currentGameTask !== BABCOCK_CONSTANTS.GET_TO_INTERVIEW_TASK;
            this.children.taskListContainer.visible = GameState.currentGameTask !== BABCOCK_CONSTANTS.INTRO_TASK && GameState.currentGameTask !== BABCOCK_CONSTANTS.GET_TO_INTERVIEW_TASK && GameState.currentSubTask !== BABCOCK_CONSTANTS.COLLECT_LITTER_TASK && GameState.currentSubTask != BABCOCK_CONSTANTS.LEAD_TO_DEST;
            this.children.cvContainer.visible = GameState.currentGameTask !== BABCOCK_CONSTANTS.INTRO_TASK && GameState.currentGameTask !== BABCOCK_CONSTANTS.GET_TO_INTERVIEW_TASK;

            let progPerc = GetCvPercentage();
            this.children.progressText.text = Math.round(progPerc * 100) + '%';
            this.progressBarFill.x = -156 + progPerc * 156;
        }

        this.setupHtmlClickers();
    }

    setupHtmlClickers = () => {
        removeHtmlClickers();

        // ignoring these style clickers now (using html clickers means they can be screen readable, however, it is causing more work than I hoped
        // as they need removing whenever another panel opens - I might put it back in later)
        return;

        if (this.container.visible && !this.knockedBack) {
            setHtmlClicker(this.children.settingButton, this.showSettings, true, '', 'Click for Settings');
            setHtmlClicker(this.children.zoomin_btn, this.zoomIn, true, '', 'Click to Zoom In');
            setHtmlClicker(this.children.zoomout_btn, this.zoomOut, true, '', 'Click to Zoom Out');

            if (this.children.bagContainer.visible) {
                setHtmlClicker(this.children.bagContainer, this.showInventory, true, '', 'Click to view backpack contents')
            }
            if (this.children.taskListContainer.visible) {
                setHtmlClicker(this.children.taskListContainer, this.showTaskList, true, '', 'Click to view the tasklist');
            }
            if (this.children.cvContainer.visible) {
                setHtmlClicker(this.children.cvContainer, this.showCv, true, '', 'Click to view your CV');
            }

            // setupClicker(this.children.settingButton, this.showSettings, '', false);
            // setupClicker(this.children.bagContainer, this.showInventory, '', false);
            // setupClicker(this.children.taskListContainer, this.showTaskList, '', false);
            // setupClicker(this.children.cvContainer, this.showCv, '', false);
            // setupClicker(this.children.zoomin_btn, this.zoomIn, '', false);
            // setupClicker(this.children.zoomout_btn, this.zoomOut, '', false);
        }
    }

    flyLitterPieceToHud = (globalPos, litterSprite) => {
        // console.log('Fly litter: ', globalPos, litterSprite);
        let localPos = this.container.toLocal(globalPos);
        let flySprite = new PIXI.Sprite(litterSprite.defaultSprite.texture);
        flySprite.anchor.x = flySprite.anchor.y = 0.5;
        flySprite.scale.x = litterSprite.scale.x * WorldController.gameView.children.mapContainer.scale.x;
        flySprite.scale.y = litterSprite.scale.y * WorldController.gameView.children.mapContainer.scale.y;
        flySprite.x = localPos.x;
        flySprite.y = localPos.y;
        this.container.addChild(flySprite);
        // console.log('Fly sprite: ', flySprite);
        let targetX = this.children.taskListIcon.x + this.children.taskListContainer.x + this.children.leftContainer.x;
        let targetY = this.children.taskListIcon.y + this.children.taskListContainer.y + this.children.leftContainer.y;
        gsap.to(flySprite.scale, {x: 0.6, y: 0.6, duration: 0.39});
        gsap.to(flySprite, {x: targetX, y: targetY, duration: 0.4, onComplete: () => {this.container.removeChild(flySprite); BabcockController.updateLitterCount();}})
    }
}