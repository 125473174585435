import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import { au, v } from '../../../koko-framework/shorthand';
import InventoryPanelIcon from '../../../assets/babcock_menus/inventory/inventory_iconLarge.png';
import CloseButton from '../../../assets/babcock_menus/close_btn.png';
import WorldController from "../../../controller/WorldController";
import { GameState, SetNewInventoryItem } from "../../model/GameState";
import BabcockController from "../../controller/BabcockController";

import ReactGA from 'react-ga4';

export default class InventoryPanel extends ReactFullScreenView {
    componentDidMount() {
        super.componentDidMount();

        BabcockController.pauseCharacter();

        ReactGA.send({ hitType: "pageview", page: "/inventory/" });
    }

    closeClicked = () => {
        v.hideView('inventorypanel');
        WorldController.enable();
        SetNewInventoryItem(false);

        au.play('closebtn', 1, false, 0, false);

        BabcockController.unpauseCharacter();
    }

    renderContent() {
        return (
            <div className="full-screen-holder no-select default-cursor black-out">
                <div className="inventory-panel rel">
                    <div className="view-row shrink">
                        <img className="inventory-logo" src={InventoryPanelIcon} alt="Your backpack contents" />
                    </div>
                    <div className="view-row grow">
                        <div className="inventory-items-container">
                            <div className="one-inventory-item">
                                {
                                    GameState.inventory.length >= 1 &&
                                    <img className="inventory-item" src={GameState.inventory[0].inventoryIcon} alt={GameState.inventory[0].description} />
                                }
                            </div>
                            <div className="one-inventory-item">
                                {
                                    GameState.inventory.length >= 2 &&
                                    <img className="inventory-item" src={GameState.inventory[1].inventoryIcon} alt={GameState.inventory[1].description} />
                                }
                            </div>
                            <div className="one-inventory-item">
                                {
                                    GameState.inventory.length >= 3 &&
                                    <img className="inventory-item" src={GameState.inventory[2].inventoryIcon} alt={GameState.inventory[2].description} />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="view-row grow"></div>
                    <div className="abs" style={{right: -10, top: -10}}>
                        <img className="close-button active-button" src={CloseButton} onClick={this.closeClicked} alt="Click to close this panel" />
                    </div>
                </div>
            </div>
        );
    }
}