import { InGameEntity } from "./InGameEntity";
import gsap from 'gsap';
import { Grid, Astar } from "fast-astar";
import WorldController from "../../../controller/WorldController";
import { ORTHAGONAL } from "../TileMapEngine";
/**
 * @author Carl Trelfa
 * 
 * A universal path-finding character for tile-based games. Lays the foundation for npcs and all sorts of moving characters.
 * 
 * Some of this entity code has become a bit messay when trying to deal with various issues.
 * It's probably fine for our use and we can customise the entities a lot via the editor, so hopefully we don't have to revisit this!
 * 
 * characterFrameData should look something like this:

export const IZZY = {
    SPRITES: {
        STILL: {
            xDir: 0,
            yDir: 0,
            texture: 'izzy_stand_front_1.png',
            animFrames: [
                'izzy_stand_front_1.png',           // Leave the anim frames out or just using one for a static sprite
            ]
        },
        UP: {
            xDir: 0,
            yDir: -1,
            texture: 'izzy_stand_back_1.png',
            animFrames: [
                'izzy_stand_back_1.png',
                'izzy_stand_back_2.png',
                'izzy_stand_back_1.png',
                'izzy_stand_back_3.png',
            ]
        },
        DOWN: {
            xDir: 0,
            yDir: 1,
            texture: 'izzy_stand_front_1.png',
            animFrames: [
                'izzy_stand_front_1.png',
                'izzy_stand_front_2.png',
                'izzy_stand_front_1.png',
                'izzy_stand_front_3.png',
            ]
        },
        LEFT: {
            xDir: -1,
            yDir: 0,
            scaleX: 1,
            texture: 'izzy_stand_side_1.png',
            animFrames: [
                'izzy_stand_side_1.png',
                'izzy_stand_side_2.png',
                'izzy_stand_side_1.png',
                'izzy_stand_side_3.png',
            ]
        },
        RIGHT: {
            xDir: 1,
            yDir: 0,
            scaleX: -1,
            texture: 'izzy_stand_side_1.png',
            animFrames: [
                'izzy_stand_side_1.png',
                'izzy_stand_side_2.png',
                'izzy_stand_side_1.png',
                'izzy_stand_side_3.png',
            ]
        }
    }
}

 *
 */
const LAST_FAILED_PATH_SEARCH = {
    lastFailedPathSearch: 0,
}

export class PathFindingEntity extends InGameEntity {
    // we need to store our detination in case we need to re-calculate our path (if for example another entity blocks our path)
    characterDestinationGridX = null;
    characterDestinationGridY = null;

    characterNextTargetNodeGridX = null;
    characterNextTargetNodeGridY = null;
    characterNextTargetNodePixelX = null;
    characterNextTargetNodePixelY = null;

    characterStartGridX = null;
    characterStartGridY = null;

    // This is needed in order to set our start grid pos correctly for double grids
    lastTargetGridPos_PF = null;

    timeToTravel64Pixels = 0.25;
    allowDiagonalMovement = true;
    currentPath = null;
    currentBlockingGrid = null;
    isNewPath = false;

    movementPaused = false;
    pauseCountdown = 0;
    resetViewOnPause = true;
    startingMovement = false;

    pauseCountdownBeforeGoingOffScreen = 0;

    updateable = true;

    alwaysDoPathSearches = false;
    stopMovingWhenBlocked = false;
    ignoreEntityBlocking = false;
    useFastPathfinding = false;

    // This isn't used by every type of npc, but is useful if you ever want to recalculate your path on a regular basis
    // as it will prevent the game slowing down if no path can be found as this will potentially cause unnecessary thrashing
    // of the a-star algorithm which can take a moment if no path can be found.
    recalculatePathEvery = 2;
    recalculatePathCountdown = 0;
    // Our character follower uses this, but we are keeping it as a generic function so we can use it for other things as well.
    // The reason the character follower does this is because it is possible for the quickest route to be blocked at times, so we
    // check regularly if there is a quicker route.
    regularlyRecalculatePath = false;

    lookAheadForBlockagesDistance = 4;  // when checking if the path is blocked, we only check this many path nodes ahead
    pathBlocked = false;
    recalculatePathWhenBlocked = false;
    pauseTimeWhenBlocked = 1;

    randomiseDestGridPos = false;
    randomDestVariation = 1;
    rerandomiseMinTime = 3;
    rerandomiseMaxTime = 7;

    rerandomiseDestOffsetsCountdown = 0;
    randomDestOffsetX = 0;
    randomDestOffsetY = 0;

    randomlyVaryTweenDest = false;
    randomTweenDestVariation = 8;
    targetXOffset = 0;
    targetYOffset = 0;

    reachedDestinationCallback = null;
    pausedAtPathNodeCallback = null;        // used by our interaction system
    pathNodeReachedCallback = null;

    /* -- Some event hooks -- */
    enteredView() {
        super.enteredView();
        if (this.pauseWhenOffScreen) {
            this.pauseCountdown = this.pauseCountdownBeforeGoingOffScreen;
        }
    }

    leftView() {
        super.leftView();
        if (this.pauseWhenOffScreen) {
            this.pauseCountdownBeforeGoingOffScreen = this.pauseCountdown;
            this.pauseMovement(Infinity);
        }
    }

    waitForInteraction() {
        super.waitForInteraction();
        this.pauseMovement(Infinity);
    }

    endInteraction() {
        this.pauseMovement(0.5);
    }

    update(delta) {
        super.update(delta);
        if (this.movementPaused) {
            this.pauseCountdown -= delta;
            if (this.pauseCountdown <= 0) {
                this.restartMovement();
            }
        }
        if (this.recalculatePathCountdown > 0) {
            this.recalculatePathCountdown -= delta;
        }
        if (this.randomiseDestGridPos && this.randomDestVariation !== 0) {
            this.rerandomiseDestOffsetsCountdown -= delta;
            if (this.rerandomiseDestOffsetsCountdown <= 0) {
                this.randomiseDestinationOffsets();
            }
        }
        this.startingMovement = false;
    }

    otherCharacterNearBy(otherCharactersArray) {
        // Do something!
        if (!this.ignoreEntityBlocking && !this.movementPaused && this.checkForBlockages()) {
            this.pathBlocked = true;
            this.pauseMovement(this.pauseTimeWhenBlocked);
            // console.log('Other characetrs nearby: ', otherCharactersArray, this.currentPath);
            // console.log('Path blocked!');
        }
    }

    pathCalculated() {
        // we are adding a slight random element here so if we have lots of characters doing recalculation it doesn't happen all at once.
        this.recalculatePathCountdown = (this.recalculatePathEvery * 1000) + Math.floor(Math.random() * 250);
    }

    /* -- Functions for adding and removing characters from play -- */
    addToGame(startSquare, container, xOffset = 0, yOffset = 0) {
        let added = super.addToGame(startSquare, container, xOffset, yOffset);
        this.characterDestinationGridX = this.characterNextTargetNodeGridX = this.characterStartGridX = this.characterGridX;
        this.characterDestinationGridY = this.characterNextTargetNodeGridY = this.characterStartGridY = this.characterGridY;
        this.lastTargetGridPos_PF = null;
        return added;
    }

    applySettingsConfig(configArray) {
        super.applySettingsConfig(configArray);

        let applyableSettings = ['timeToTravel64Pixels', 'allowDiagonalMovement', 'recalculatePathEvery', 'regularlyRecalculatePath', 'recalculatePathWhenBlocked', 'pauseTimeWhenBlocked', 'randomiseDestGridPos', 'randomDestVariation', 'rerandomiseMinTime', 'rerandomiseMaxTime', 'resetViewOnPause', 'lookAheadForBlockagesDistance', 'randomlyVaryTweenDest', 'randomTweenDestVariation', 'useFastPathfinding', 'ignoreEntityBlocking'];
        this._applySettings(configArray, applyableSettings);
    }

    /* -- Functions for managing anims and view -- */
    updateCharacterSpriteByDir(overrideXDir = null, overrideYDir = null) {
        let dirX = overrideXDir || this.characterTargetPixelX - (this.characterSprite.x - this.targetXOffset);
        let dirY = overrideYDir || this.characterTargetPixelY - (this.characterSprite.y - this.targetYOffset);
        if (dirX > 0) dirX = 1;
        if (dirX < 0) dirX = -1;
        if (dirY > 0) dirY = 1;
        if (dirY < 0) dirY = -1;
        if (WorldController.type === ORTHAGONAL) {
            if (dirX !== 0 && dirY !== 0) {
                // Do we favor side or up / down?
                // We could base it on our destination, if x is further, then side else up / down
                /*
                if (this.currentPath.length === 0) {
                    if (Math.random() >= 0.5) {
                        dirX = 0;
                    } else {
                        dirY = 0;
                    }
                } else {
                    let xDistToDest = Math.abs(this.characterGridX - this.currentPath[this.currentPath.length - 1][0]);
                    let yDistToDest = Math.abs(this.characterGridY - this.currentPath[this.currentPath.length - 1][1]);
                    /*
                    if (xDistToDest === yDistToDest) {
                        if (Math.random() >= 0.5) {
                            dirX = 0;
                        } else {
                            dirY = 0;
                        }
                    }
                    */
                /*
                 if (yDistToDest > xDistToDest) {
                     dirX = 0;
                 } else {
                     dirY = 0;
                 }
             }
             */
                // I think it looks best if we always face left / right for diagonals
                dirY = 0;
            }
        }
        let spriteId = 'STILL';

        // find data for this state
        for (let p in this.stateFrameData.SPRITES) {
            if (!this.stateFrameData.SPRITES[p].idleFrame && this.stateFrameData.SPRITES[p].xDir === dirX && this.stateFrameData.SPRITES[p].yDir === dirY) {
                spriteId = p;
                break;
            }
        }

        this.updateCharacterSprite(spriteId);
    }

    /* -- Moving and path-finding -- */
    moveToGridPos(gridX, gridY) {
        if (!this.movementPaused) {
            let destChanged = this.setDestinationGridPos(gridX, gridY);
            if (destChanged) {
                this.calculatePathToDestination();
                if (!this.pathBlocked && this.checkForBlockages()) {
                    this.pauseMovement(this.pauseTimeWhenBlocked);
                }
                this.moveToNextTargetNode();
            } else {
                if (this.resetViewOnPause) {
                    this.updateCharacterSprite();
                    this.playAnim();
                } else {
                    this.updateCharacterSpriteByDir();
                }
                // this.pauseMovement(0.1);
                if (!this.npc && this.reachedDestinationCallback) {
                    if (!this.currentPath || this.currentPath.length === 0) {
                        if (!this.resetViewOnPause) {
                            this.pauseAnim();
                        }
                        this.reachedDestinationCallback(this);
                    }
                }
            }
        }
    }

    setDestinationGridPos(gridX, gridY) {
        let prevDestX = this.characterDestinationGridX;
        let prevDestY = this.characterDestinationGridY;
        this.characterDestinationGridX = gridX + this.randomDestOffsetX;
        this.characterDestinationGridY = gridY + this.randomDestOffsetY;
        if (this.characterDestinationGridX < 0) this.characterDestinationGridX = 0;
        if (this.characterDestinationGridY < 0) this.characterDestinationGridY = 0;
        if (this.characterDestinationGridX > this.scrollingTilemapController.mapTilesAcross - 1) this.characterDestinationGridX = this.scrollingTilemapController.mapTilesAcross - 1;
        if (this.characterDestinationGridY > this.scrollingTilemapController.mapTilesDown - 1) this.characterDestinationGridY = this.scrollingTilemapController.mapTilesDown - 1;

        // check we are not blocked or inaccessible
        let destPixelPos = this.scrollingTilemapController.tileMapEngine.renderer.getPixelCenterOfGridSquare(this.characterDestinationGridX, this.characterDestinationGridY);
        let destTiles = this.scrollingTilemapController.tileMapEngine.getTileSpritesAtPixelPos(destPixelPos.x, destPixelPos.y, true);
        for (let i = 0; i < destTiles.length; i++) {
            if (destTiles[i].config.inaccessible === true) {
                this.characterDestinationGridX = prevDestX;
                this.characterDestinationGridY = prevDestY;
                // try without our random destination adjustments?
                if (this.randomiseDestinationOffsets && (this.randomDestOffsetX !== 0 || this.randomDestOffsetY !== 0)) {
                    this.randomDestOffsetX = 0;
                    this.randomDestOffsetY = 0;
                    this.setDestinationGridPos(gridX, gridY);
                    // return true;
                }
                break;
            }
        }

        return (prevDestX !== this.characterDestinationGridX || prevDestY !== this.characterDestinationGridY) ? true : false;
    }

    randomiseDestinationOffsets() {
        if (this.randomiseDestGridPos && this.randomDestVariation !== 0) {
            this.randomDestOffsetX = Math.floor(Math.random() * (Math.abs(this.randomDestVariation * 2) + 1)) - Math.abs(this.randomDestVariation);
            this.randomDestOffsetY = Math.floor(Math.random() * (Math.abs(this.randomDestVariation * 2) + 1)) - Math.abs(this.randomDestVariation);
            this.rerandomiseDestOffsetsCountdown = (Math.random() * (this.rerandomiseMaxTime - this.rerandomiseMinTime) + this.rerandomiseMinTime) * 1000;
            if (this.randomDestOffsetX === 0 && this.randomDestOffsetY === 0) {
                this.randomiseDestinationOffsets();
            }
        }
    }

    calculatePathToDestination() {
        if (!this.alwaysDoPathSearches && Date.now() - LAST_FAILED_PATH_SEARCH.lastFailedPathSearch < 200) {
            // console.log('skip path search');
            this.pathCalculated();
            return;
        }
        let gridMult = this.scrollingTilemapController.doubleBlockingGrid && !this.useFastPathfinding ? 2 : 1;
        let rawPathfindingGrid = (!this.alwaysDoPathSearches || this.pathBlocked) && this.recalculatePathWhenBlocked && !this.ignoreEntityBlocking ? this.scrollingTilemapController.getPathFindingGridWithEntities(this, this.useFastPathfinding) : this.useFastPathfinding ? this.scrollingTilemapController.singleGridPathfindingData : this.scrollingTilemapController.currentRawPathfindingData;
        // let rawPathfindingGrid = this.scrollingTilemapController.getPathFindingGridWithEntities(this)
        let pathFinderGrid = new Grid({ col: rawPathfindingGrid.length, row: rawPathfindingGrid[0].length });
        for (let gx = 0; gx < rawPathfindingGrid.length; gx++) {
            for (let gy = 0; gy < rawPathfindingGrid[gx].length; gy++) {
                if (rawPathfindingGrid[gx][gy] === 1) {
                    pathFinderGrid.set([gx, gy], 'value', 1);
                }
            }
        }
        // if our grid space is blocked, then we need to check the square around it if we have a double grid
        // one of these 4 spaces may be open meaning we should be able to walk into the gridsquare
        let multDestGridX = this.characterDestinationGridX * gridMult;
        let multDestGridY = this.characterDestinationGridY * gridMult;
        if (gridMult === 2) {
            if (rawPathfindingGrid[multDestGridX][multDestGridY] === 1) {
                for (let xoff = 0; xoff < 2; xoff++) {
                    for (let yoff = 0; yoff < 2; yoff++) {
                        if (rawPathfindingGrid[multDestGridX + xoff][multDestGridY + yoff] === 0) {
                            multDestGridX += xoff;
                            multDestGridY += yoff;
                            break;
                        }
                    }
                }
            }
        }
        let pathFinder = new Astar(pathFinderGrid);
        let startPos = this.lastTargetGridPos_PF === null ? [this.characterGridX * gridMult, this.characterGridY * gridMult] : this.lastTargetGridPos_PF;
        let newPath = pathFinder.search(
            startPos,
            [multDestGridX, multDestGridY],
            {
                rightAngle: !this.allowDiagonalMovement,    // default:false,Allow diagonal
                optimalResult: true,
            }
        );
        if (!this.currentPath) {
            // console.log('No path found: ', Date.now());
            LAST_FAILED_PATH_SEARCH.lastFailedPathSearch = Date.now();
        }
        this.isNewPath = true;
        this.currentPath = newPath;
        if (!this.ignoreEntityBlocking) {
            this.currentBlockingGrid = this.scrollingTilemapController.getPathFindingGridWithEntities(this, this.useFastPathfinding);
        } else {
            this.currentBlockingGrid = rawPathfindingGrid;
        }
        this.pathCalculated();
    }

    moveToNextTargetNode = () => {
        let gridMult = this.scrollingTilemapController.doubleBlockingGrid && !this.useFastPathfinding ? 2 : 1;
        // if we are going to interact with en entity, we need to stop 1 grid square away from it
        let atInteractLocation = false;
        if (this.approachingEntity) {
            if (this.allowDiagonalMovement) {
                if ( (this.approachingEntity.readyToInteract && Math.abs(this.approachingEntity.characterGridX - this.characterGridX) <= 1 && Math.abs(this.approachingEntity.characterGridY - this.characterGridY) <= 1)
                        || (!this.approachingEntity.readyToInteract && Math.abs(this.approachingEntity.characterNextTargetNodeGridX - this.characterGridX) <= 1 && Math.abs(this.approachingEntity.characterNextTargetNodeGridY - this.characterGridY) <= 1)
                ) {
                    atInteractLocation = true;
                }
            } else {
                if ( (this.approachingEntity.readyToInteract && Math.abs(this.approachingEntity.characterGridX - this.characterGridX) === 0 && Math.abs(this.approachingEntity.characterGridY - this.characterGridY) <= 1)
                        || (this.approachingEntity.readyToInteract && Math.abs(this.approachingEntity.characterGridX - this.characterGridX) <= 1 && Math.abs(this.approachingEntity.characterGridY - this.characterGridY) === 0)
                        || (!this.approachingEntity.readyToInteract && Math.abs(this.approachingEntity.characterNextTargetNodeGridX - this.characterGridX) === 1 && Math.abs(this.approachingEntity.characterNextTargetNodeGridY - this.characterGridY) <= 1)
                        || (!this.approachingEntity.readyToInteract && Math.abs(this.approachingEntity.characterNextTargetNodeGridX - this.characterGridX) <= 1 && Math.abs(this.approachingEntity.characterNextTargetNodeGridY - this.characterGridY) === 1)
                ) {
                    atInteractLocation = true;
                }
            }
        }
        /*
        if (this.approachingEntity && 
            ( (this.approachingEntity.readyToInteract && Math.abs(this.approachingEntity.characterGridX - this.characterGridX) <= 1 && Math.abs(this.approachingEntity.characterGridY - this.characterGridY) <= 1)
            || (!this.approachingEntity.readyToInteract && Math.abs(this.approachingEntity.characterNextTargetNodeGridX - this.characterGridX) <= 1 && Math.abs(this.approachingEntity.characterNextTargetNodeGridY - this.characterGridY) <= 1)
        ) ) {
        */
        if (atInteractLocation) {
            // clear the path, therefore ending our movements immeditately
            this.currentPath = [];
        }
        if (this.currentPath && this.characterGridX === this.characterNextTargetNodeGridX && this.characterGridY === this.characterNextTargetNodeGridY) {
            // console.log('kill tweens', this.npcId);
            gsap.killTweensOf(this.characterSprite);
            if (this.isNewPath && this.currentPath.length > 0) {
                // we need to ignore the first path node
                this.currentPath.splice(0, 1);
                this.isNewPath = false;
            }
            let nextPathNode = null;
            if (this.currentPath.length > 0) {
                nextPathNode = [this.currentPath[0]];
                if (this.currentBlockingGrid[nextPathNode[0][0]][nextPathNode[0][1]] === 0) {
                    this.lastTargetGridPos_PF = nextPathNode[0];
                }
            }
            if (gridMult === 2) {
                while (this.currentPath.length > 0 && Math.floor(nextPathNode[0][0] / gridMult) === this.characterGridX && Math.floor(nextPathNode[0][1] / gridMult) === this.characterGridY) {
                    this.currentPath.splice(0, 1);
                    if (this.currentPath.length > 0) {
                        nextPathNode = [this.currentPath[0]];
                        if (this.currentBlockingGrid[nextPathNode[0][0]][nextPathNode[0][1]] === 0) {
                            this.lastTargetGridPos_PF = nextPathNode[0];
                        }
                    }
                }
            }
            // Last thing is to check if we are moving straight when we should be moving diagonally
            // This is an unfortunate side effect of the double-grid path finder, some times we move in up/down - across steps instead of diagonally
            if (this.allowDiagonalMovement && this.currentPath.length > 0) {
                let actualNodeGridX = Math.floor(nextPathNode[0][0] / gridMult);
                let actualNodeGridY = Math.floor(nextPathNode[0][1] / gridMult);
                let actualNodeGridX_2 = actualNodeGridX;
                let actualNodeGridY_2 = actualNodeGridY;
                if (actualNodeGridX === this.characterGridX || actualNodeGridY === this.characterGridY) {
                    // we are moving up/down or across
                    // look ahead and see if we can skip to a diagonal
                    let skipAhead = 0;
                    for (let i = 1; i <= 4; i++) {
                        if (this.currentPath.length > i) {
                            actualNodeGridX_2 = Math.floor(this.currentPath[i][0] / gridMult);
                            actualNodeGridY_2 = Math.floor(this.currentPath[i][1] / gridMult);
                            if (Math.abs(actualNodeGridX_2 - this.characterGridX) === 1 && Math.abs(actualNodeGridY_2 - this.characterGridY) === 1) {
                                skipAhead = i;
                                break;
                            }
                        }
                    }
                    // we can just splice these out!
                    if (skipAhead > 0) {
                        // make sure we are not passing through a blocked square
                        // this is complicated by the fact that the grid positions of sprites are relative to their chunk and not a global grid
                        // so we need to use the pixel positions and convert to global grid positions
                        // maybe some future optimisation can have the global gridX / Y stored on the tile
                        // also if sprites are larger than a single tile we can find tiles at pixel positions that don't apply
                        let tileCenter = this.scrollingTilemapController.tileMapEngine.renderer.getPixelCenterOfGridSquare(this.characterGridX, this.characterGridY);
                        let pixelX_1 = tileCenter.x;
                        let pixelY_1 = tileCenter.y;
                        let tilesPassingThrough = this.scrollingTilemapController.tileMapEngine.getSurroundingTilesFromPixelPos(pixelX_1, pixelY_1, 2, 2, []);
                        for (let i = 0; i < tilesPassingThrough.length; i++) {
                            let tGridX = tilesPassingThrough[i].gridX;
                            let tGridY = tilesPassingThrough[i].gridY;
                            if ((tGridX === this.characterGridX || tGridX === actualNodeGridX || tGridX === actualNodeGridX_2) && (tGridY === this.characterGridY || tGridY === actualNodeGridY || tGridY === actualNodeGridY_2)) {
                                if (tilesPassingThrough[i].config && tilesPassingThrough[i].config.blocker === true) {
                                    skipAhead = 0;
                                }
                            }
                        }
    
                        if (skipAhead > 0) {
                            this.currentPath.splice(0, skipAhead);
                            if (this.currentPath.length > 0) {
                                nextPathNode = [this.currentPath[0]];
                                if (this.currentBlockingGrid[nextPathNode[0][0]][nextPathNode[0][1]] === 0) {
                                    this.lastTargetGridPos_PF = nextPathNode[0];
                                }
                            }
                        }
                    }
                }
            }
            if (this.currentPath.length > 0) {
                nextPathNode = this.currentPath.splice(0, 1);
                // don't move into blocked squares. This is almost certainly going to be the end of the path anyway...
                if (this.currentBlockingGrid[nextPathNode[0][0]][nextPathNode[0][1]] === 0) {
                    this.characterNextTargetNodeGridX = Math.floor(nextPathNode[0][0] / gridMult);
                    this.characterNextTargetNodeGridY = Math.floor(nextPathNode[0][1] / gridMult);
                    let targetPixelTop = this.scrollingTilemapController.tileMapEngine.renderer.getPixelCenterTopOfGridSquare(this.characterNextTargetNodeGridX, this.characterNextTargetNodeGridY);
                    let targetPX = Math.round(targetPixelTop.x + this.characterXOffset);
                    let targetPY = Math.round(targetPixelTop.y + this.characterYOffset);
                    this.targetXOffset = 0;
                    this.targetYOffset = 0;

                    if (this.randomlyVaryTweenDest) {
                        this.targetXOffset = -this.randomTweenDestVariation;
                        this.targetXOffset += Math.floor(Math.random() * this.randomTweenDestVariation * 2);
                        this.targetYOffset = -this.randomTweenDestVariation;
                        this.targetYOffset += Math.floor(Math.random() * this.randomTweenDestVariation * 2);
                    }
    
                    this.characterTargetPixelX = targetPX;
                    this.characterTargetPixelY = targetPY;
    
                    let dist = Math.sqrt(((this.characterSprite.x - targetPX) ** 2) + ((this.characterSprite.y - targetPY) ** 2));
                    let moveTime = this.timeToTravel64Pixels * (dist / 64);
    
                    // console.log('new tweens: ', this.npcId);
                    this.readyToInteract = false;
                    gsap.to(this.characterSprite, { x: targetPX + this.targetXOffset, y: targetPY + this.targetYOffset, duration: moveTime, onComplete: this.reachedTargetNode, ease: 'none' });
                    gsap.delayedCall(moveTime / 2, this.updateActualGridPos);
                    this.updateCharacterSpriteByDir();
                    this.playAnim();
                } else {
                    // make sure our callback gets triggered
                    this.characterDestinationGridX = this.characterGridX;
                    this.characterDestinationGridY = this.characterGridY;
                    this.moveToNextTargetNode();
                }
            } else {
                if (this.resetViewOnPause) {
                    this.updateCharacterSprite();
                    this.playAnim();
                } else {
                    this.pauseAnim();
                }
                this.readyToInteract = true;
                if (this.reachedDestinationCallback) {
                    this.reachedDestinationCallback(this);
                }
            }
        }
    }

    updateActualGridPos = () => {
        this.actualGridX = this.characterNextTargetNodeGridX;
        this.actualGridY = this.characterNextTargetNodeGridY;
    }

    reachedTargetNode = () => {
        this.characterGridX = this.characterNextTargetNodeGridX;
        this.characterGridY = this.characterNextTargetNodeGridY;
        if (!this.movementPaused) {
            if (this.pathNodeReachedCallback) {
                this.pathNodeReachedCallback(this);
            }
            if (this.regularlyRecalculatePath && this.recalculatePathCountdown <= 0) {
                this.recalculatePath();
                this.moveToNextTargetNode();
            } else {
                this.moveToNextTargetNode();
            }
        } else {
            this.readyToInteract = true;
            if (this.pausedAtPathNodeCallback) {
                this.pausedAtPathNodeCallback(this);
            }
            if (this.resetViewOnPause) {
                this.updateCharacterSprite();
                this.playAnim();
            } else {
                this.pauseAnim();
            }
        }
    }

    pauseMovement(timeInSeconds) {
        this.movementPaused = true;
        if (this.pauseCountdown === Infinity || this.pauseCountdown < Math.floor(timeInSeconds * 1000)) {
            this.pauseCountdown = Math.floor(timeInSeconds * 1000) + Math.floor(Math.random() * 100);
        }
        if ((!this.currentPath || this.currentPath.length === 0) && this.characterGridX == this.characterNextTargetNodeGridX && this.characterGridY == this.characterNextTargetNodeGridY) {
            if (this.resetViewOnPause) {
                this.updateCharacterSprite();
                this.playAnim();
            } else {
                this.pauseAnim();
            }
        }
        // console.log('movement paused: ', this.pauseCountdown, this);
    }

    restartMovement() {
        this.pauseCountdown = 0;
        this.movementPaused = false;
        if (this.pathBlocked) {
            // console.log('Checking if path still blocked...', this, this.npcId);
            if (this.recalculatePathWhenBlocked || !this.currentPath) {
                // this.calculatePathToDestination();
                this.recalculatePath();
            }
            if (!this.currentPath || this.checkForBlockages()) {
                if (this.stopMovingWhenBlocked) {
                    this.pathBlocked = false;
                    this.currentPath = [];
                    this.characterDestinationGridX = this.characterNextTargetNodeGridX = this.characterStartGridX;
                    this.characterDestinationGridY = this.characterNextTargetNodeGridY = this.characterStartGridY;
                    this.moveToNextTargetNode();
                    this.updateCharacterSpriteByDir();
                } else {
                    this.pauseMovement(this.pauseTimeWhenBlocked);
                    // console.log('Still blocked!');
                }
                return;
            }
            this.pathBlocked = false;
        }
        this.startingMovement = true;
        // this.moveToGridPos(this.characterDestinationGridX, this.characterDestinationGridY);
        if (this.characterDestinationGridX !== this.characterGridX || this.characterDestinationGridY !== this.characterGridY) {
            this.moveToNextTargetNode();
        }
    }

    recalculatePath() {
        // console.log('Recalculate destination');
        this.calculatePathToDestination();
        //this.moveToGridPos(this.characterDestinationGridX, this.characterDestinationGridY);
    }

    applyBlockingArea(blockingGrid, doubleGrid) {
        super.applyBlockingArea(blockingGrid, doubleGrid);
        if (this.characterNextTargetNodeGridX !== this.characterGridX || this.characterNextTargetNodeGridY !== this.characterGridY) {
            this.applyBlockingAreaToGridPos(blockingGrid, doubleGrid, this.characterNextTargetNodeGridX, this.characterNextTargetNodeGridY);
        }
    }

    getBlockedSquares() {
        let blockedSquares = super.getBlockedSquares();
        if (this.characterNextTargetNodeGridX !== this.characterGridX || this.characterNextTargetNodeGridY !== this.characterGridY) {
            blockedSquares = this.getBlockedSquaresForGridPos(this.characterNextTargetNodeGridX, this.characterNextTargetNodeGridY, blockedSquares);
        }
        return blockedSquares;
    }

    checkForBlockages() {
        let gridMult = this.scrollingTilemapController.doubleBlockingGrid && !this.useFastPathfinding ? 2 : 1;
        if (this.currentPath && this.currentPath.length) {
            let closeEntities = this.scrollingTilemapController.getCloseEntities(this);
            // console.log('Checking for blockages: ', closeEntities);
            for (let i = 0; i < closeEntities.length; i++) {
                let entityBlockingSquares = closeEntities[i].getBlockedSquares();
                // console.log('Blocked squares, path: ', entityBlockingSquares, this.currentPath);
                for (let j = 0; j < entityBlockingSquares.length; j++) {
                    for (let k = 0; k < this.currentPath.length; k++) {
                        if (k >= this.lookAheadForBlockagesDistance) {
                            break;
                        }
                        let pathGridX = Math.floor(this.currentPath[k][0] / gridMult);
                        let pathGridY = Math.floor(this.currentPath[k][1] / gridMult);
                        if (entityBlockingSquares[j].x === pathGridX && entityBlockingSquares[j].y === pathGridY) {
                            // console.log('Blocked!', this);
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }
}