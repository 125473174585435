export const KOKO_DEBUG = true;
export const FRAME_DELTA_LIMIT = 3;
export const FRAME_TIME = 16.666; // milliseconds


export function kokoDebug(debugStatement){
    if(KOKO_DEBUG)
    {
        console.log('[KOKO DEBUG] '+debugStatement);
    }
}