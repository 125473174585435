import gsap from 'gsap'
import koko from '../koko-framework/koko'
import app from '../koko-framework/app'
import game from '../controller/GameController'
import * as PIXI from 'pixi.js'
import WorldController from '../controller/WorldController'
import { VERSION } from '../babcock-neet/model/constants'

export class WorldView extends koko.view {
    uiOpen = false;

    constructor (){
        super();
    }

    assets = [
        /*
        { id: 'demo_assets', src: 'img/demo_assets.json' },
        { id: 'tileset_assets_0', src: 'img/tiles-0.json' },
        { id: 'tileset_assets_1', src: 'img/tiles-1.json' },
        { id: 'tileset_assets_2', src: 'img/tiles-2.json' },
        { id: 'character_assets_1', src: 'img/characters-0.json' },
        */
        { id: 'iso_tileset_assets_0', src: 'img/iso_tiles-0.json?' + VERSION },
        { id: 'iso_tileset_assets_1', src: 'img/iso_tiles-1.json?' + VERSION },
        { id: 'iso_tileset_assets_2', src: 'img/iso_tiles-2.json?' + VERSION },
        { id: 'iso_tileset_assets_3', src: 'img/iso_tiles-3.json?' + VERSION },
        { id: 'iso_tileset_assets_4', src: 'img/iso_tiles-4.json?' + VERSION },
        { id: 'iso_tileset_assets_5', src: 'img/iso_tiles-5.json?' + VERSION },
        { id: 'iso_character_assets_1', src: 'img/iso_characters-0.json?' },
        { id: 'iso_character_assets_suits_1', src: 'img/iso_characters_suits-0.json?' + VERSION },
        { id: 'hud_assets', src: 'img/ingame_assets.json?' + VERSION},
    ]

    buildView (){
        var g = app.guiSize, vp = app.viewPort;

        this.children = koko.display.buildLayout([
            {name:'gameContainer', type:'container'},

            {name:'bgContainer', type:'container', parent:'gameContainer'},

            // { name:'bg', type:'shape', x: -42, y: -70, width: 827, height: 1410, fill: 0, alpha: 0.7 },
            { name:'mapMask', type:'shape', x: 0, y: 0, width: 743, height: 1269, fill: 0 },

            {name:'mapContainer', type:'container', parent:'gameContainer'},
                {name:'Water_Layer', type:'container', parent:'mapContainer'},
                {name:'Water_Effect_Layer', type:'container', parent:'mapContainer'},
                {name:'Ground_Layer', type:'container', parent:'mapContainer'},
                {name:'Ground_Objects_Layer', type:'container', parent:'mapContainer'},
                {name:'Ground_Objects_Layer_2', type:'container', parent:'mapContainer'},
                {name:'Depth_Sort_Layer', type:'container', parent:'mapContainer'},
                {name:'Top_Layer', type:'container', parent:'mapContainer'},
                {name:'Top_Layer_2', type:'container', parent:'mapContainer'},
                {name:'Blockers', type:'container', parent:'mapContainer'},
                {name:'Flyers', type:'container', parent:'mapContainer'},

            {name:'objectsContainer', type:'container', parent:'gameContainer'},
            {name:'playerContainer', type:'container', parent:'gameContainer'},
            {name:'effectsContainer', type:'container', parent:'gameContainer'},
            {name:'overlaysContainer', type:'container', parent:'gameContainer'},

            
            { name:'textPop', type:'container', x: vp.centerX, y: vp.centerY },
                { name:'textPop_greyOut', parent:'textPop', type:'shape', width:g.width, height:g.height, x: -g.width / 2, y: -g.height / 2, fill: 0, alpha: 0.3},
                { name:'textPop_panelBG_outline', parent:'textPop', type:'shape', x: -253, y: -203, width: 506, height: 406, fill: 0 },
                { name:'textPop_panelBG', parent:'textPop', type:'shape', x: -250, y: -200, width: 500, height: 400, fill: 0xffffff },
                { type: 'bmptext', parent: 'textPop', text: 'Some text to test text', font: 'HelveticaBold', size: 32, tint: 0, name: 'textPopText', x: 0, y: -180},
                

            // { type: 'bmptext', parent: 'objectsContainer', text: 'Some text to test text', font: 'HelveticaBold', size: 48, tint: 0xffffff, name: 'someText', x: 0, y: 0},
            // { type: 'sprite', parent: 'objectsContainer', id: 'pokeball_sillhouette', x: 50, y: 150},
        ], this.container);

        this.children.mapContainer.mask = this.children.mapMask;
        this.children.textPopText.anchor.x = 0.5;
        this.children.textPop.visible = false;
        // console.log(this.children.textPop_panelBG);
        // this.children.textPop_panelBG.width = 400;
        // this.children.textPop_panelBG.height = 300;

        this.resize();
    }

    resize () {
        // console.log('resize called');
        // v.get('ingamehud').resize();
        // game.resize();
        WorldController.resize();
    }

    transitionIn (){
        this.buildView();
        this.show();
        this.enable();
        this.container.alpha = 1;
        
        this.startGame();
    }

    transitionOut (){
        gsap.to(this.container, {alpha:0, onComplete:this.transitionOutDone, duration: 0.35});
        // v.get('ingamehud').transitionOut();
        this.stopGame();
    }

    transitionOutDone = () => {
        // gsap.killAll();
        WorldController.removeMap();
        // this.destroyGameObjects();  
        // game.destroy();
        // game = null;
        this.destroyView();
        this.hide();

        /*
        PIXI.Ticker.shared.stop();
        app.renderer.renderer.plugins.interaction.destroy();

        app.paused = true;
        app.renderer.destroy();
        PIXI.utils.destroyTextureCache();
        */
    }

    startGame () {
        this.enable();
   
        app.removeUpdateListener('worldview');

        /*
        game = new GameController();
        */
        // game.init();
           
		app.addUpdateListener('worldview', function(time, delta){ 
            WorldController.update(delta);
        }.bind(this));

        app.addResizeListener('worldview', function(){
            this.resize();
        }.bind(this));
    }

    stopGame (){ 
        app.removeUpdateListener('worldview');
        while (app.updateListeners['worldview']) {
            app.removeUpdateListener('worldview');
        }

        app.removeResizeListener('worldview');
        while (app.resizeListeners['worldview']) {
            app.removeResizeListener('worldview');
        }
    }

    textPopText = null;
    typeOutLetter = 0;
    showTextPop(text) {
        this.textPopText = text;
        this.typeOutLetter = 0;
        this.children.textPopText.text = text;
        // console.log(this.children.textPopText);
        this.setPanelSize();
        this.children.textPopText.text = '';
        this.children.textPop.visible = true;
        this.children.textPop.alpha = 0;
        gsap.to(this.children.textPop, {alpha: 1, duration: 0.3/*, delay: 0.05*/});
        // setTimeout(this.setPanelSize, 50);
        this.uiOpen = true;

        setTimeout(this.typeOutText, 20);
    }

    typeOutText = () => {
        this.typeOutLetter++;
        this.children.textPopText.text = this.textPopText.substr(0, this.typeOutLetter);
        if (this.typeOutLetter < this.textPopText.length) {
            setTimeout(this.typeOutText, 20);
        }
    }

    setPanelSize = () => {
        this.children.textPop_panelBG.width = this.children.textPopText.textWidth + 40;
        this.children.textPop_panelBG.x = -this.children.textPop_panelBG.width / 2;
        this.children.textPop_panelBG_outline.width = this.children.textPopText.textWidth + 46;
        this.children.textPop_panelBG_outline.x = -this.children.textPop_panelBG_outline.width / 2;
        this.children.textPop_panelBG.height = this.children.textPopText.textHeight + 40;
        this.children.textPop_panelBG_outline.height = this.children.textPopText.textHeight + 46;
    }

    hideTextPop() {
        gsap.to(this.children.textPop, {alpha: 0, duration: 0.3, onComplete: () => {this.children.textPop.visible = false}});
        this.uiOpen = false;
    }

    // Some stuff for distortion effects (used for water)
    waterDistortCount = null;
    waterDistortTileSize = 64;
    waterDistortPointsCount = 16;
    waterDistortPoints = null;
    waterDistortInited = false;
    waterDistortSpeed = 0.07;
    waterDistortXdepth = 4;
    waterDistortXFrequency = 0.035;
    waterDistortYdepth = 2;
    waterDistortYFrequency = 0.023;

    waterDistortionSprite = null;

    initWaterDistort() {
        this.waterDistortInited = false;
        this.waterDistortionSprite = null;

        let waterBounds = this.children.Water_Layer.getLocalBounds();
        // console.log('water bounds: ', waterBounds);
        let waterTexture = app.renderer.renderer.generateTexture(this.children.Water_Layer, PIXI.SCALE_MODES, 0.5, waterBounds);
        // console.log('water texture: ', waterTexture);

        this.waterDistortCount = Math.random() * 6;
        this.waterDistortTileSize = waterBounds.width;
        this.waterDistortPointsCount = waterBounds.width / 8;

        this.waterDistortPoints = [];
        let pointDistance = this.waterDistortTileSize / this.waterDistortPointsCount;
        for (let i = 0; i < this.waterDistortPointsCount; i++) {
            this.waterDistortPoints.push(new PIXI.Point(i * pointDistance, 0));
        }

        let waterEffectSprite = new PIXI.SimpleRope(waterTexture, this.waterDistortPoints);
        waterEffectSprite.x = waterBounds.left - 8;
        waterEffectSprite.y = waterBounds.top + waterBounds.height / 2;
        waterEffectSprite.scale.x = waterEffectSprite.scale.y = 1.05;
        this.children.Water_Effect_Layer.addChild(waterEffectSprite);
        this.children.Water_Layer.visible = false;

        this.updateWaterDistortion(1);
        this.waterDistortInited = true;
    }

    removeWaterDistort() {
        while (this.children.Water_Effect_Layer.children.length > 0) {
            let s = this.children.Water_Effect_Layer.children[0];
            this.children.Water_Effect_Layer.removeChild(s);
            s.destroy();
        }
        this.waterDistortInited = false;
        this.children.Water_Layer.visible = true;
    }

    updateWaterDistortion($deltaMult) {
        if (this.waterDistortInited) {
            this.waterDistortCount += this.waterDistortSpeed * $deltaMult;
            let pointDistance = this.waterDistortTileSize / this.waterDistortPointsCount;
            // make the snake
            for (var i = 0; i < this.waterDistortPoints.length; i++) {
                this.waterDistortPoints[i].y = Math.sin((i * this.waterDistortYFrequency) + this.waterDistortCount) * this.waterDistortYdepth;
                this.waterDistortPoints[i].x = i * pointDistance + Math.cos((i * this.waterDistortYFrequency) + this.waterDistortCount) * this.waterDistortYdepth;
            }
            // is it visible?
            this.hideShowWater();
        }
    }

    hideShowWater() {
        if (this.waterDistortInited) {
            let viewRect = game.viewRect;
            let mapContainer = this.children.mapContainer;
            if (viewRect && mapContainer) {
                let visibleRectangle = new PIXI.Rectangle(viewRect.x, viewRect.y, viewRect.width, viewRect.height);
                visibleRectangle.x -= mapContainer.x;
                visibleRectangle.y -= mapContainer.y;
                let sprite = this.children.Water_Effect_Layer.children[0];
                let spriteBounds = sprite.getLocalBounds();
                if (spriteBounds.left + sprite.x <= visibleRectangle.right && spriteBounds.right + sprite.x >= visibleRectangle.left
                    && spriteBounds.top + sprite.y <= visibleRectangle.bottom && spriteBounds.bottom + sprite.y >= visibleRectangle.top) {
                    sprite.visible = true;
                } else {
                    sprite.visible = false;
                }
            }
        }
    }
}