import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import { au, v } from '../../../koko-framework/shorthand';

import ReactGA from 'react-ga4';

import { PANEL_STATE } from "../../../Bootstrap";
import { BadgeProgress } from "./components/badge-progress";

import CloseButton from '../../../assets/babcock_menus/close_btn.png';

export default class BadgeInfoPop extends ReactFullScreenView {
    componentDidMount() {
        super.componentDidMount();

        ReactGA.send({ hitType: "pageview", page: "/badge-info/" + PANEL_STATE.contentData.config.name });
    }

    closePanel = () => {
        v.hideView(this.props.viewId);

        au.play('closebtn', 1, false, 0, true);
    }

    renderContent() {
        return (
            <div className="full-screen-holder black-out no-select default-cursor rel">
                <div className="gen-white-panel mid-panel badge-info-panel flex-rows rel">
                    <div className="view-row shrink badge-info-panel-top rel">
                        <BadgeProgress badgeData={PANEL_STATE.contentData}></BadgeProgress>
                    </div>

                    <div className="view-row grow"></div>

                    <div className="view-row shrink centered">
                        <p className="blue-header bold-text">{PANEL_STATE.contentData.config.name}</p>
                    </div>
                    <div className="view-row shrink centered">
                        <p className="selfie-blurb">{PANEL_STATE.contentData.config.description}</p>
                    </div>

                    <div className="view-row grow"></div>
                    <div className="view-row grow"></div>
                    <div className="view-row grow"></div>

                    <div className="abs" style={{right: -10, top: -10}}>
                        <img className="close-button active-button" src={CloseButton} onClick={this.closePanel} alt="Click to close this panel" />
                    </div>
                </div>
            </div>
        );
    }
}