/* -- tilesets -- */
/*
import buildings1 from './tilesets/json/buildings1.json';
import buildings2 from './tilesets/json/buildings2.json';
import fences from './tilesets/json/fences.json';
import ground_details from './tilesets/json/ground_details.json';
import ground from './tilesets/json/ground.json';
import interior from './tilesets/json/interior.json';
import misc from './tilesets/json/misc.json';
import objects1 from './tilesets/json/objects1.json';
import objects2 from './tilesets/json/objects2.json';
*/
import iso_tiles_even from './tilesets/json/iso_tiles_even.json';
import iso_tiles_odd from './tilesets/json/iso_tiles_odd.json';
import iso_utility from './tilesets/json/iso_utility.json';
import iso_main_buildings from './tilesets/json/iso_main_buildings.json';
import iso_buildings_2 from './tilesets/json/iso_buildings_2.json';

/* -- tilemaps -- */
/*
import cobbler_square from './tilemaps/json/pennamap_cobbler_square.json';
import mansions from './tilemaps/json/pennamap_mansions.json';
import market from './tilemaps/json/pennamap_market.json';
import stoke_park from './tilemaps/json/pennamap_stoke_park.json';
import safehouse_gardens from './tilemaps/json/pennamap_safehouse_gardens.json';
*/
import isomap_city_centre from './tilemaps/json/isomap_city_centre.json';
import isomap_city_north from './tilemaps/json/isomap_city_north.json';

/* -- map layouts -- */
import { MapLayouts } from './tilemaps/MapLayouts';

/* -- exports -- */
export const TILESETS =     [iso_tiles_even, iso_tiles_odd, iso_utility, iso_main_buildings, iso_buildings_2]; // [buildings1, buildings2, fences, ground_details, ground, interior, misc, objects1, objects2];

export const MAPS =         [isomap_city_centre, isomap_city_north]; // [cobbler_square, mansions, market, stoke_park, safehouse_gardens];
export const MAP_LABELS =   ['city_centre', 'city_north']; // ['cobbler_square', 'mansions', 'market', 'stoke_park', 'safehouse_gardens'];

export const START_MAP =    MapLayouts.city_north;
