import { MULTIPLE_CHOICE } from '../view/react-views/QuestionPanel';
import * as BABCOCK_CONSTANTS from './constants';
import * as BADGES from './badges-config';

export const QUESTION_1 = {
    trigger: {
        type: BABCOCK_CONSTANTS.SUB_TASK,
        task: BABCOCK_CONSTANTS.FOLLOWER_NPC
    },
    introDialog: [
        {
            character: 'advisor',
            text: 'Hi again, [CHARACTER]. Here\'s a quick-fire question to help expand your profile.'
        }
    ],
    questionData: {
        type: MULTIPLE_CHOICE,
        question: 'If you identify an issue, do you prefer to resolve it alone or resolve it as part of a team?',
        answers: [
            'I find somebody to help me',
            'I find the information that I need on my own',
            'I ignore the problem and hope somebody else notices it instead'
        ],
        progressBadge: [
            BADGES.PROBLEM_SOLVING,
            BADGES.PROBLEM_SOLVING,
            null,
        ]
    },
    feedback: [
        {
            character: 'advisor',
            text: 'Great, thanks! I\'ll get these added to your profile. Catch you soon!'
        }
    ],
    chosenAnswer: -1
}

export const QUESTION_2 = {
    trigger: {
        type: BABCOCK_CONSTANTS.GAME_TASK,
        task: BABCOCK_CONSTANTS.COFFEE_SHOP_GAME
    },
    introDialog: [
        {
            character: 'advisor',
            text: 'Hey, [CHARACTER], here\'s a quick question for your profile.'
        }
    ],
    questionData: {
        type: MULTIPLE_CHOICE,
        question: 'If you needed to undertake a task over and over again, would you say that quantity or quality were more important?',
        answers: [
            'Quantity',
            'Quality',
            'A balance, depending on the time available and the health and safety implications'
        ],
        progressBadge: [
            null,
            null,
            BADGES.AIMING_HIGH
        ]
    },
    feedback: [
        {
            character: 'advisor',
            text: 'Thanks [CHARACTER], I\'ll add this response to your profile here at the Skills Launchpad. Speak soon.'
        }
    ],
    chosenAnswer: -1
}

export const QUESTION_3 = {
    trigger: {
        type: BABCOCK_CONSTANTS.GAME_TASK,
        task: BABCOCK_CONSTANTS.PLUMBING_GAME
    },
    introDialog: [
        {
            character: 'advisor',
            text: 'Hey, [CHARACTER], let\'s see how you answer this one.'
        }
    ],
    questionData: {
        type: MULTIPLE_CHOICE,
        question: 'Which emotions might you feel when encountering an unexpected challenge?',
        answers: [
            'Irritated, anxious, fed up',
            'Joyful, amused',
            'Relaxed, energetic'
        ],
        progressBadge: [
            null,
            null,
            BADGES.LEADERSHIP,
        ]
    },
    feedback: [
        {
            character: 'advisor',
            text: 'Brilliant, thanks. I\'ll add this now, catch you soon.'
        }
    ],
    chosenAnswer: -1
}

export const QUESTION_4 = {
    trigger: {
        type: BABCOCK_CONSTANTS.SUB_TASK,
        task: BABCOCK_CONSTANTS.HOSPITAL_GROUNDSKEEPER
    },
    introDialog: [
        {
            character: 'advisor',
            text: 'Hey, [CHARACTER], here\'s a slightly trickier question.'
        }
    ],
    questionData: {
        type: MULTIPLE_CHOICE,
        question: 'How does the language we use change in different settings?',
        answers: [
            'Language does not need to change in different settings',
            'Formal, technical language is used for friends',
            'Formal, technical language is used for clients and colleagues'
        ],
        progressBadge: [
            null,
            null,
            BADGES.SPEAKING
        ]
    },
    feedback: [
        {
            character: 'advisor',
            text: 'Thanks [CHARACTER], I\'ll add this response to your profile. Speak to you later!'
        }
    ],
    chosenAnswer: -1
}

export const QUESTION_5 = {
    trigger: {
        type: BABCOCK_CONSTANTS.SUB_TASK,
        task: BABCOCK_CONSTANTS.LIGHTHOUSE_NPC
    },
    introDialog: [
        {
            character: 'advisor',
            text: 'Hey, [CHARACTER], here\'s an interesting question to expand your profile.'
        }
    ],
    questionData: {
        type: MULTIPLE_CHOICE,
        question: 'Do you have any ways of helping to maintain concentration? ',
        answers: [
            'Making sure you are comfortable, and avoiding distractions e.g. putting phone on flight mode',
            'Always listening to music/watching tv while you work so you don\'t get bored',
            'Trying to concentrate for long periods of time without any breaks'
        ],
        progressBadge: [
            BADGES.LISTENING,
            null,
            null
        ]
    },
    feedback: [
        {
            character: 'advisor',
            text: 'That\'s great, thanks [CHARACTER]. Speak to you soon.'
        }
    ],
    chosenAnswer: -1
}

export const QUESTION_6 = {
    trigger: {
        type: BABCOCK_CONSTANTS.GAME_TASK,
        task: BABCOCK_CONSTANTS.DELIVERY_GAME
    },
    introDialog: [
        {
            character: 'advisor',
            text: 'Hello again. I have a question to help with your profile here at the Skills Launchpad.'
        }
    ],
    questionData: {
        type: MULTIPLE_CHOICE,
        question: 'How can we make sure we fully understand a difficult situation?',
        answers: [
            'Reacting immediately by getting fed up and angry',
            'Taking time to ask questions about the context and always keeping the end goal in mind',
            'Focusing only on the immediate problem and ignoring the wider context'
        ],
        progressBadge: [
            null,
            BADGES.STAYING_POSITIVE,
            null
        ]
    },
    feedback: [
        {
            character: 'advisor',
            text: 'Amazing, thank you [CHARACTER], speak to you soon!'
        }
    ],
    chosenAnswer: -1
}

const QUESTIONS = [QUESTION_1, QUESTION_2, QUESTION_3, QUESTION_4, QUESTION_5, QUESTION_6];

export function GetQuestionToAsk(triggerTask) {
    for (let i = 0; i < QUESTIONS.length; i++) {
        if (QUESTIONS[i].trigger.task === triggerTask) {
            return QUESTIONS[i];
        }
    }
    return null;
}