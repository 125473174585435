import { PathFindingEntity } from "./PathFindingEntity";

export class CharacterFollowerNPCEntity extends PathFindingEntity {
    npc = true;
    characterFollower = true;
    characterIdToFollow = '';
    characterToFollow = null;
    maxDistance = 160;
    updateable = true;
    regularlyRecalculatePath = true;
    recalculatePathWhenBlocked = true;
    forceMovement = false
    pauseTimeWhenBlocked = 0.5;

    // resetViewOnPause = false;

    // randomlyVaryTweenDest = true;
    useFastPathfinding = true;
    lookAheadForBlockagesDistance = 2;
    pauseAtStart = false;

    applySettingsConfig(configArray) {
        super.applySettingsConfig(configArray);

        let applyableSettings = ['maxDistance', 'pauseAtStart'];
        this._applySettings(configArray, applyableSettings);
    }

    followCharacter = () => {
        if (this.pauseAtStart) {
            this.pauseMovement(Infinity);
        } else
        if (this.characterToFollow) {
            let dist = Math.sqrt(((this.characterSprite.x - this.characterToFollow.characterSprite.x) ** 2) + ((this.characterSprite.y - this.characterToFollow.characterSprite.y) ** 2));
            if (dist >= this.maxDistance || this.forceMovement) {
                this.movementPaused = false;
                // this.characterFollower = true;
                this.pauseCountdown = 0;
                this.reachedDestinationCallback = this.reachedDestination;
                if (this.randomiseDestGridPos) {
                    let invalidDestOffsets = true;
                    let attempts = 0;
                    while (invalidDestOffsets && attempts < 5) {
                        invalidDestOffsets = false;
                        // check we are not going to try to move on to a blocked or inaccessible square
                        let destGridX = this.characterToFollow.characterGridX + this.randomDestOffsetX;
                        let destGridY = this.characterToFollow.characterGridY + this.randomDestOffsetY;
                        let destPixelPos = this.scrollingTilemapController.tileMapEngine.renderer.getPixelCenterOfGridSquare(destGridX, destGridY);
                        let destTiles = this.scrollingTilemapController.tileMapEngine.getTileSpritesAtPixelPos(destPixelPos.x, destPixelPos.y, true);
                        for (let i = 0; i < destTiles.length; i++) {
                            if (destTiles[i].config.inaccessible === true || destTiles[i].config.blocker) {
                                invalidDestOffsets = true;
                                if (attempts >= 5) {
                                    this.randomDestOffsetX = 0;
                                    this.randomDestOffsetY = 0;
                                } else {
                                    this.randomiseDestinationOffsets();
                                }
                            }
                        }
                        attempts++;
                    }
                }
                this.moveToGridPos(this.characterToFollow.characterGridX, this.characterToFollow.characterGridY, this.forceMovement);
                // this.setDestinationGridPos(this.characterToFollow.characterGridX, this.characterToFollow.characterGridY);
                // this.calculatePathToDestination();
                // this.moveToNextTargetNode();
                this.forceMovement = false;
            }
        }
    }

    reachedDestination = () => {
        if (this.resetViewOnPause) {
            this.updateCharacterSprite();
            this.playAnim();
        } else {
            this.pauseAnim();
        }
    }

    update(delta) {
        super.update(delta);
        if (!this.movementPaused && this.characterFollower) {
            if (!this.currentPath || this.currentPath.length === 0 /*|| (this.regularlyRecalculatePath && this.recalculatePathCountdown <= 0)*/) {
                this.forceMovement = this.randomiseDestGridPos;
                this.followCharacter();
                /*
                if (!this.currentPath) {
                    this.pathBlocked = true;
                    this.pauseMovement(0.2);
                }
                */
                this.pathCalculated();
            }
        }
    }

    recalculatePath() {
        // console.log('Kloos_2 recalc');
        this.forceMovement = true; // this.randomiseDestGridPos;
        this.followCharacter();
        /*
        if (!this.currentPath) {
            this.pathBlocked = true;
            this.pauseMovement(0.2);
        }
        */
    }
}