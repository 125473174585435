import { KOKO_DEBUG, kokoDebug } from '../model/config'
import * as PIXI from 'pixi.js'
import TileObject from './TileObject'
import { TruckSprite } from './TruckSprite'
import {TILE_WIDTH, TILE_HEIGHT} from '../model/GameConfig'
import * as GameConfig from '../model/GameConfig'
import gsap from 'gsap/all'

export class LoadingBaySprite extends PIXI.Container {

    truckSprite = null;

    truckSpotTopYGlobal = 0;
    truckSpotBottomYGlobal = 0;

    sendForDeliveryActiveSprite = null;
    sendForDeliveryWaitingSprite = null;

    isTruckWaiting = true;
    timerChart = null;
    timerCenterPoint = new PIXI.Point(0,0);

    truckSendTime = 0;
    truckBackCountdown = 0;

    constructor() {
        super();
        this.setupLoadingBay();
    }

    setupLoadingBay = () =>{

        //1. Add loading bay graphics
        let loadingBayGraphic = new PIXI.Sprite.from('loadingBay.png');
        this.addChild(loadingBayGraphic);

        //2. Add truck sprite object
        this.truckSprite = new TruckSprite();
      
        this.truckSpotBottomYGlobal = this.truckSpotTopYGlobal + this.truckSprite.height;
        this.addChild(this.truckSprite);


        //3. Add the 'Send Truck' button
        this.sendForDeliveryActiveSprite = new PIXI.Sprite.from('sendTruck_btn.png');
        this.sendForDeliveryActiveSprite.x = 500 + this.sendForDeliveryActiveSprite.width/2;
        this.sendForDeliveryActiveSprite.y = 90 + this.sendForDeliveryActiveSprite.height/2;
        this.sendForDeliveryActiveSprite.interactive = true;
        this.sendForDeliveryActiveSprite.on('mousedown', this.clickHandler);
        this.sendForDeliveryActiveSprite.on('tap', this.clickHandler);
        this.sendForDeliveryActiveSprite.pivot.x =  this.sendForDeliveryActiveSprite.width/2;
        this.sendForDeliveryActiveSprite.pivot.y =  this.sendForDeliveryActiveSprite.height/2;
        this.addChild(this.sendForDeliveryActiveSprite);

        this.sendForDeliveryWaitingSprite = new PIXI.Sprite.from('sendTruck_timerBk.png');
        this.sendForDeliveryWaitingSprite.x = 500;
        this.sendForDeliveryWaitingSprite.y = 90;
        this.sendForDeliveryWaitingSprite.visible = false;
        this.addChild(this.sendForDeliveryWaitingSprite);


        this.timerChart = new PIXI.Graphics();
        this.timerChart.x = 500 + this.sendForDeliveryActiveSprite.pivot.x;
        this.timerChart.y = 90 + this.sendForDeliveryActiveSprite.pivot.x;
        this.timerChart.visible = false;
        this.addChild(this.timerChart);

     

        this.truckSprite.on('reactivateTruck', () =>{this.sendForDeliveryWaitingSprite.visible = false;
        this.timerChart.visible =false;})
        this.truckSprite.on('sendTruckForDelivery', () => { this.truckSendTime = new Date().getTime(); this.truckBackCountdown = GameConfig.TRUCK_DELIVERY_TIME*1000; this.timerChart.visible = true})
    }

    drawPie = (centerPoint, radius, perc) =>{
        perc = 1 - perc
        let endAngle = 360*perc;
        this.timerChart.clear();
        this.timerChart
                    .beginFill(0xFFFFFF, 1)
                    .moveTo(centerPoint.x, centerPoint.y)
                    .lineTo(centerPoint.x, centerPoint.y - radius)
                    .arc(centerPoint.x, centerPoint.y, radius,  this._angle(360), this._angle(endAngle), true )
                    .lineTo(centerPoint.x, centerPoint.y)
                    .endFill();

    }
    _angle = ( degrees ) => {
        return (degrees-90) * (Math.PI/180);
        //return (angle - 90) * PIXI.DEG_TO_RAD;
    };

    update = (delta) =>{
        if(!this.truckSprite.isActive)
        {
            if (this.truckBackCountdown > 0) {
                this.truckBackCountdown -= delta;
                let timerPerc = (this.truckBackCountdown) / (GameConfig.TRUCK_DELIVERY_TIME*1000);
                if (timerPerc < 0) {
                    timerPerc = 0;
                }
                this.drawPie(this.timerCenterPoint, 30, timerPerc);
                if (this.truckBackCountdown <= 0) {
                    this.truckSprite.bringBackIn();
                }
            } else {
                this.drawPie(this.timerCenterPoint, 30, 0);
            }
        }
    }


    checkTruckAvailableSpace = (packageTopGlobalPos, packageBottomGlobalPos) => {
        let success = false ;
        if( this.truckSprite.isGlobalPointInTrailer(packageTopGlobalPos) &&  this.truckSprite.isGlobalPointInTrailer(packageBottomGlobalPos) && this.truckSprite.isActive)
        {
            success = true;
        }
        return  success;
    }

    loadParcel = (parcel) => {

        let globalParcelPos = parcel.toGlobal(parcel.pivot)
        let parcelLocalPos =  this.truckSprite.toLocal(globalParcelPos);
        parcel.position = parcelLocalPos;
        this.truckSprite.parcelContainer.addChild(parcel);
        kokoDebug("LOAD PARCEL");
        //this.truckSprite.x += 400;
    }

    clickHandler = (e) =>{

        if(this.truckSprite.isActive)
        {
            gsap.to(e.target.scale, {duration:.1, x:.8, y:.8, yoyo:true, repeat:1, onComplete:() =>{this.sendForDeliveryWaitingSprite.visible = true; }})
            this.truckSprite.sendForDelivery();
        }
    }

}