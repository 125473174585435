import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import { au, v } from '../../../koko-framework/shorthand';
import WorldController from "../../../controller/WorldController";
import * as BABCOCK_CONSTANTS from "../../model/constants";
import BabcockController from "../../controller/BabcockController";
import { GameState } from "../../model/GameState";

import FoodBankTruck from "../../../assets/babcock_menus/mini_game_flow/tutorials/02_Foodbank/foodbank_1.png";
import FoodBankTimer from "../../../assets/babcock_menus/mini_game_flow/tutorials/02_Foodbank/foodbank_2.png";
import FoodBankStars from "../../../assets/babcock_menus/mini_game_flow/tutorials/02_Foodbank/foodbank_3.png";

import CoffeeShopCup from "../../../assets/babcock_menus/mini_game_flow/tutorials/03_CoffeeShop/coffeeshop_1.png";
import CoffeeShopCup2 from "../../../assets/babcock_menus/mini_game_flow/tutorials/03_CoffeeShop/coffeeshop_2.png";
import CoffeeShopCup3 from "../../../assets/babcock_menus/mini_game_flow/tutorials/03_CoffeeShop/coffeeshop_3.png";

import PipeGame1 from "../../../assets/babcock_menus/mini_game_flow/tutorials/04_Hotel/hotel_1.png";
import PipeGame2 from "../../../assets/babcock_menus/mini_game_flow/tutorials/04_Hotel/hotel_2.png";
import PipeGame3 from "../../../assets/babcock_menus/mini_game_flow/tutorials/04_Hotel/hotel_3.png";

import _1 from "../../../assets/babcock_menus/mini_game_flow/tutorials/tut1.png";
import _2 from "../../../assets/babcock_menus/mini_game_flow/tutorials/tut2.png";
import _3 from "../../../assets/babcock_menus/mini_game_flow/tutorials/tut3.png";

import ContinueButton from '../../../assets/babcock_menus/selfie_location/reward/continue_btn.png';

const TUT_DATA = [
    [
        { img: PipeGame1, height: 102, text: 'Tap on a pipe to rotate it 90 degrees.' },
        { img: PipeGame2, height: 102, text: 'Complete the game by connecting all pipes.' },
        { img: PipeGame3, height: 86, text: 'Use fewer moves to earn more stars!' },
    ],
    [
        { img: CoffeeShopCup, height: 102, text: 'Tap on a coffee cup to select it.' },
        { img: CoffeeShopCup2, height: 154, text: 'Tap on another cup to pour the top colour in.' },
        { img: CoffeeShopCup3, height: 126, text: 'Sort all colours into their own cups to be successful!' },
    ],
    [
        { img: FoodBankTruck, height: 126, text: 'Tap the parcels to load them - use the button to send trucks out.'},
        { img: FoodBankTimer, height: 96, text: 'Bear in mind you will have to wait for them to return.' },
        { img: FoodBankStars, height: 86, text: 'The more parcels you deliver, the higher your star rating will be!' },
    ],
]

export default class GameTutorial extends ReactFullScreenView {
    constructor(props) {
        super(props);

        this.state.lastInteraction = BabcockController.lastInteraction;
    }

    closePanel = () => {
        v.hideView(this.props.viewId);
        // WorldController.enable();

        au.play('closebtn', 1, false, 0, false);

        let locationData = BABCOCK_CONSTANTS.getLocationDataFromId(this.state.lastInteraction);
        if (locationData.game.gameViews) {
            v.hideViews([/*'startgame',*/ 'hudview']);
            au.stop('babcock_ingame_music');
            WorldController.scrollingTilemapController.resetSounds();
            v.showViews(locationData.game.gameViews);
            GameState.playingMiniGame = locationData;
            setTimeout(() => v.get('worldview').container.visible = false, 350);
        } else {
            let starsEarned = GameState.gameResults[locationData.game.id] < 3 ? GameState.gameResults[locationData.game.id] + 1 : 3;
            let score = 10 + Math.floor(Math.random() * 20);
            BabcockController.miniGameComplete(starsEarned, score);
            // v.hideView('startgame');
            v.showView('endofgame');
        }
    }

    renderContent() {
        let gameId = BABCOCK_CONSTANTS.getLocationDataFromId(this.state.lastInteraction).game.id;
        return (
            <div className="full-screen-holder black-out no-select default-cursor rel">
                <div className="tut-white-panel mid-panel flex-rows">
                    <div className="view-row grow"></div>

                    <div className="view-row shrink">
                        <p className="blue-header bold-text">How to Play</p>
                    </div>

                    <div className="view-row grow"></div>

                    <div className="view-row shrink flex-colums">
                        <div className="grow"></div>
                        <div className="shrink flex-columns">
                            <div className="tutorial-c1">
                                <div className="flex-rows">
                                    <div className="shrink">
                                        <img src={TUT_DATA[gameId][0].img} style={{width: 173, height: TUT_DATA[gameId][0].height}} alt="" />
                                    </div>
                                    <div className="grow"></div>
                                </div>
                            </div>
                            <div className="tutorial-c2">
                                <div className="flex-rows">
                                    <div className="shrink">
                                        <img src={_1} style={{width: 38, height: 38}} alt="Step 1" />
                                    </div>
                                    <div className="grow"></div>
                                </div>
                            </div>
                            <div className="tutorial-c3">
                                <div className="flex-rows">
                                    <div className="shrink">
                                        <p className="grey-info-text">
                                            {TUT_DATA[gameId][0].text}
                                        </p>
                                    </div>
                                    <div className="grow"></div>
                                </div>
                            </div>
                        </div>
                        <div className="grow"></div>
                    </div>

                    <div className="view-row grow"></div>

                    <div className="view-row shrink flex-colums">
                        <div className="grow"></div>
                        <div className="shrink flex-columns">
                            <div className="tutorial-c1">
                                <div className="flex-rows">
                                    <div className="shrink">
                                        <img src={TUT_DATA[gameId][1].img} style={{width: 173, height: TUT_DATA[gameId][1].height}} alt="" />
                                    </div>
                                    <div className="grow"></div>
                                </div>
                            </div>
                            <div className="tutorial-c2">
                                <div className="flex-rows">
                                    <div className="shrink">
                                        <img src={_2} style={{width: 38, height: 38}} alt="Step 2" />
                                    </div>
                                    <div className="grow"></div>
                                </div>
                            </div>
                            <div className="tutorial-c3">
                                <div className="flex-rows">
                                    <div className="shrink">
                                        <p className="grey-info-text">
                                            {TUT_DATA[gameId][1].text}
                                        </p>
                                    </div>
                                    <div className="grow"></div>
                                </div>
                            </div>
                        </div>
                        <div className="grow"></div>
                    </div>

                    <div className="view-row grow"></div>

                    <div className="view-row shrink flex-colums">
                        <div className="grow"></div>
                        <div className="shrink flex-columns">
                            <div className="tutorial-c1">
                                <div className="flex-rows">
                                    <div className="shrink">
                                        <img src={TUT_DATA[gameId][2].img} style={{width: 173, height: TUT_DATA[gameId][2].height}} alt="" />
                                    </div>
                                    <div className="grow"></div>
                                </div>
                            </div>
                            <div className="tutorial-c2">
                                <div className="flex-rows">
                                    <div className="shrink">
                                        <img src={_3} style={{width: 38, height: 38}} alt="Step 3" />
                                    </div>
                                    <div className="grow"></div>
                                </div>
                            </div>
                            <div className="tutorial-c3">
                                <div className="flex-rows">
                                    <div className="shrink">
                                        <p className="grey-info-text">
                                            {TUT_DATA[gameId][2].text}
                                        </p>
                                    </div>
                                    <div className="grow"></div>
                                </div>
                            </div>
                        </div>
                        <div className="grow"></div>
                    </div>

                    <div className="view-row grow"></div>

                    <div className="view-row shrink">
                        <img className="take-selfie-button active-button" src={ContinueButton} onClick={this.closePanel} alt="Click to Continue" />
                    </div>

                    <div className="view-row grow"></div>
                </div>
            </div>
        );
    }
}