import koko from '../../../koko-framework/koko'
import { KOKO_DEBUG, kokoDebug } from '../model/config';
import { LiquidContainer } from './LiquidContainer';
import * as PIXI from 'pixi.js'
import { Sprite } from 'pixi.js';
import { MASK_SPRITE_PATTERNS, ASSET_SCALE, ASSET_LIQUID_CONTAINER_WIDTH, ASSET_LIQUID_CONTAINER_HEIGHT, ASSET_ANGLED_SIDE_X_OFFSET, LIQUID_VOLUME_TOTAL, ASSET_LIQUID_TOP_RADIUS} from '../model/GameConfig'
import app from '../../../koko-framework/app'
import { FRAME_TIME, FRAME_DELTA_LIMIT } from '../model/config'
export class LiquidContainerSprite extends PIXI.Container{
    // Member properties
    liquidContainerObject = null;
    maskSpriteContainers = [];

    creamToppingSprite = null;
    flakeSprite = null;
    isEmpty = true;
    // Member functions
    constructor() {
        super();
        this.init();
    }

    init()
    { 
        //Layer 1 - Back of cup
        let cupBack = PIXI.Sprite.from('coffeCup_back.png');
        cupBack.scale.x = cupBack.scale.y = ASSET_SCALE;
        this.addChild(cupBack)

        //Layer 2 - Coloured liquid
        this.liquidContainerObject = new LiquidContainer();
        this.liquidSpriteContainer = new PIXI.Container();
        this.liquidSpriteContainer.scale.x = this.liquidSpriteContainer.scale.y = ASSET_SCALE;
        this.liquidSpriteContainer.y = 155;
        this.liquidSpriteContainer.x = 33;
        this.addChild( this.liquidSpriteContainer);

        //Layer 3 - Liquid pattern masks
        this.createPatternMasks();

        //Layer 4 - Cream topping
         this.creamToppingSprite = PIXI.Sprite.from('cream.png');
         this.creamToppingSprite.scale.x = this.creamToppingSprite.scale.y = ASSET_SCALE;
         this.creamToppingSprite.x  =35;
         this.creamToppingSprite.y  =-35;
        this.creamToppingSprite.visible = false;
         this.addChild(this.creamToppingSprite)

        //Layer 5 - 99 Flake
        this.flakeSprite = PIXI.Sprite.from('flake.png');
        this.flakeSprite.scale.x = this.flakeSprite.scale.y = ASSET_SCALE;
        this.flakeSprite.x  =100;
        this.flakeSprite.y  =-35;
       this.flakeSprite.visible = false;
        this.addChild(this.flakeSprite)

        //Layer 6 - Front of cup
        let cupFront = PIXI.Sprite.from('coffeCup_front.png');
        cupFront.scale.x =   cupFront.scale.y = ASSET_SCALE;
        this.addChild(cupFront)
    }

    createPatternMasks = () =>{

        for(let i=0; i<MASK_SPRITE_PATTERNS.length; i++)
        {
            let newMaskedPattern = new Sprite.from(MASK_SPRITE_PATTERNS[i]);
            newMaskedPattern.scale.x = newMaskedPattern.scale.y = ASSET_SCALE;
            this.addChild(newMaskedPattern)

            let newMaskContainer = new PIXI.Container();
            newMaskContainer.scale.x = newMaskContainer.scale.y = ASSET_SCALE;
            newMaskContainer.y = 155;
            newMaskContainer.x = 33;
    
            this.addChild(newMaskContainer);
            this.maskSpriteContainers.push(newMaskContainer);
            newMaskedPattern.mask = newMaskContainer;
        }  
    }




    pourLiquidIntoContainer(sourceLiquidContainer)
    {
        return this.liquidContainerObject.addLiquid(sourceLiquidContainer.getTopLiquid());
    }

    addStartingLiquid(liquidObj, maxStartVolumeOfEachColour)
    {
        if (this.liquidContainerObject.calculateVolumeOfColour(liquidObj.colour) < maxStartVolumeOfEachColour) {
            return this.liquidContainerObject.addLiquid(liquidObj);
        }
        return false;
    }

    clearLiquidContainers = () =>{
        while( this.liquidSpriteContainer.children[0]) 
        { 
            //Remove all the liquids from the container graphic ready to be updated
            this.liquidSpriteContainer.removeChild(this.liquidSpriteContainer.children[0]);
        }
        for(let i=0; i<this.maskSpriteContainers.length; i++)
        {
            while( this.maskSpriteContainers[i].children[0]) 
            {
                this.maskSpriteContainers[i].removeChild(this.maskSpriteContainers[i].children[0]);
            }
        }
    }
   
    emptySprite = () =>
    {
        this.creamToppingSprite.visible = false;
        this.flakeSprite.visible = false;
        this.clearLiquidContainers();
    }
    updateContainerLiquids = (isForced) =>{
       
        if(!this.liquidContainerObject.getTopLiquid()) 
        {
            this.emptySprite();
            return;
        }

       if(this.liquidContainerObject.removeEmptyLiquids()) isForced = true;
       if(this.liquidContainerObject.liquidObjArray.length == 0) isForced = true;

        if(isForced) console.log('IS FORCED', this.liquidContainerObject.liquidObjArray.length);
        if(this.liquidContainerObject.getTopLiquid().volume != this.liquidContainerObject.getTopLiquid().animatedVolume || isForced == true)
        {

          //  console.log(this.liquidContainerObject.getTopLiquid(), isForced)
           this.emptySprite();
            let liquidObjArray = this.liquidContainerObject.liquidObjArray;
            let pixelsPerVolumeUnit = ASSET_LIQUID_CONTAINER_HEIGHT / LIQUID_VOLUME_TOTAL;
            let yPos = 0;
            let topXOffset = 0;
            let bottomXOffset = 0;

            let startX = 0;
            let startY = 0;

        

            for(var i = 0; i<liquidObjArray.length; i++)
            {   

                let liquidObj = liquidObjArray[i];
                let liquidHeight = liquidObj.animatedVolume * pixelsPerVolumeUnit;

                let bottomY = 0 + liquidHeight;
                topXOffset = ASSET_ANGLED_SIDE_X_OFFSET - (ASSET_ANGLED_SIDE_X_OFFSET * ((Math.abs(yPos) + liquidHeight) / ASSET_LIQUID_CONTAINER_HEIGHT));
                bottomXOffset =  ASSET_ANGLED_SIDE_X_OFFSET - (ASSET_ANGLED_SIDE_X_OFFSET * (Math.abs(yPos) / ASSET_LIQUID_CONTAINER_HEIGHT));
                yPos -= liquidHeight;


                for(let j=0; j<2; j++)
                {
                    let liquidGraphic = new PIXI.Graphics();
                    liquidGraphic.beginFill(liquidObj.colour);

                    liquidGraphic.moveTo(topXOffset, 0);
                    liquidGraphic.lineTo(bottomXOffset, liquidHeight);
                    liquidGraphic.bezierCurveTo(bottomXOffset, liquidHeight + ASSET_LIQUID_TOP_RADIUS, ASSET_LIQUID_CONTAINER_WIDTH - bottomXOffset, liquidHeight + ASSET_LIQUID_TOP_RADIUS, ASSET_LIQUID_CONTAINER_WIDTH - bottomXOffset, liquidHeight);
                    liquidGraphic.lineTo(ASSET_LIQUID_CONTAINER_WIDTH - topXOffset, 0);
                    liquidGraphic.bezierCurveTo(ASSET_LIQUID_CONTAINER_WIDTH - topXOffset, 0 + ASSET_LIQUID_TOP_RADIUS, topXOffset, 0 + ASSET_LIQUID_TOP_RADIUS, topXOffset, 0);
                
                    liquidGraphic.y =yPos;
                    switch(j)
                    {
                        case 0:
                            this.liquidSpriteContainer.addChild(liquidGraphic);
                            break;
                        case 1:
                            this.maskSpriteContainers[liquidObj.maskID].addChild(liquidGraphic);
                            break;
                    }
                    
                }
            }
            if(liquidObjArray.length > 0)
            {
              
                let hexString = this.rgbToHex(liquidObjArray[liquidObjArray.length-1].colour)
                let topColour = this.lightenDarkenColor(hexString, 40);
                let topLiquidGraphic = new PIXI.Graphics();
                topLiquidGraphic.beginFill(parseInt(topColour, 16));
                topLiquidGraphic.moveTo(topXOffset, 0);
                topLiquidGraphic.bezierCurveTo(topXOffset, ASSET_LIQUID_TOP_RADIUS, ASSET_LIQUID_CONTAINER_WIDTH - topXOffset, ASSET_LIQUID_TOP_RADIUS, ASSET_LIQUID_CONTAINER_WIDTH - topXOffset, 0);
                topLiquidGraphic.bezierCurveTo( ASSET_LIQUID_CONTAINER_WIDTH - topXOffset, -ASSET_LIQUID_TOP_RADIUS, topXOffset, -ASSET_LIQUID_TOP_RADIUS, topXOffset, 0);
                this.liquidSpriteContainer.addChild(topLiquidGraphic);
                topLiquidGraphic.y =yPos;
            }

         
        }
        if(this.liquidContainerObject.checkCompleteStatus())
        {
            if(!this.creamToppingSprite.visible) 
            {
                if(koko.audio.isPlaying('magic')) koko.audio.play('magic1');
                koko.audio.play('magic');
            }
            this.creamToppingSprite.visible = true;
            if(Math.random()<.3)  this.flakeSprite.visible = true;
        }
        
    }

    //COLOUR MODIFIERS - Used to darken/lighten the top of the liquid
    rgbToHex(rgb) { 
        var hex = Number(rgb).toString(16);
        if (hex.length < 2) {
             hex = "0" + hex;
        }
        return hex;
      };
    lightenDarkenColor(col, amt) {
      
        var usePound = false;
        if (col[0] == "#") {
            col = col.slice(1);
            usePound = true;
        }
        var num = parseInt(col, 16);
        var r = (num >> 16) + amt;
        if (r > 255) {
            r = 255;
        } else if (r < 0) {
            r = 0;
        }
        var b = ((num >> 8) & 0x00FF) + amt;
        if (b > 255) {
            b = 255;
        } else if (b < 0) {
            b = 0;
        }
        var g = (num & 0x0000FF) + amt;
        if (g > 255) {
            g = 255;
        } else if (g < 0) {
            g = 0;
        }
        return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
    }
}