import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import game from '../../../controller/GameController';
import { au, v } from '../../../koko-framework/shorthand';
import WorldController from "../../../controller/WorldController";

import CoffeeShopIcon from "../../../assets/babcock_menus/mini_game_flow/coffee_shop/thumbnail.png";
import CloseButton from '../../../assets/babcock_menus/close_btn.png';
import StarLocked from '../../../assets/babcock_menus/mini_game_flow/star_locked.png';
import StarUnlocked from '../../../assets/babcock_menus/mini_game_flow/star_Unlocked.png';
import CoffeeUnlocked from '../../../assets/babcock_menus/mini_game_flow/coffee_shop/coffee_Unlocked.png';
import CVLocked from '../../../assets/babcock_menus/mini_game_flow/CV_locked.png';
import CVUnlocked from '../../../assets/babcock_menus/mini_game_flow/CV_Unlocked.png';
import AttributeLocked from '../../../assets/babcock_menus/mini_game_flow/trophy_locked.png';
import AttributeUnlocked from '../../../assets/babcock_menus/mini_game_flow/trophyIcon.png';
import StarGameButton from '../../../assets/babcock_menus/mini_game_flow/playgame_btn.png';

import * as BABCOCK_CONSTANTS from '../../model/constants';
import { GameState, IsItemGot } from "../../model/GameState";
import BabcockController from "../../controller/BabcockController";

import ReactGA from 'react-ga4';

export default class GameStartPanel extends ReactFullScreenView {
    componentDidMount() {
        super.componentDidMount();

        ReactGA.send({ hitType: "pageview", page: "/game-start/" });
    }

    closePanel = () => {
        v.hideView('startgame');
        WorldController.enable();

        au.play('closebtn', 1, false, 0, false);

        setTimeout(this.clearLastGameData, 420);
    }

    playGame = () => {
        au.play('primarybtn', 1, false, 0, false);
        
        // we are skipping playing the game and just submitting a random result / collecting the item
        /*
        let locationData = BABCOCK_CONSTANTS.getLocationDataFromId(BabcockController.lastInteraction);
        if (locationData.game.gameViews) {
            v.hideViews(['startgame', 'hudview']);
            au.stop('babcock_ingame_music');
            v.showViews(locationData.game.gameViews);
            GameState.playingMiniGame = locationData;
        } else {
            let starsEarned = GameState.gameResults[locationData.game.id] < 3 ? GameState.gameResults[locationData.game.id] + 1 : 3;
            let score = 10 + Math.floor(Math.random() * 20);
            BabcockController.miniGameComplete(starsEarned, score);
            v.hideView('startgame');
            v.showView('endofgame');
        }
        */
        v.showView('gametutorial');
        setTimeout(() => v.hideViews(['startgame']), 100);
    }

    clearLastGameData = () => {
        BabcockController.lastInteraction = null;
        BabcockController.showViewAfterDialog = '';
    }

    renderContent() {
        let locationData = BABCOCK_CONSTANTS.getLocationDataFromId(BabcockController.lastInteraction);
        return (
            <div className="full-screen-holder black-out no-select default-cursor rel">
                <div className="gen-white-panel rel">
                    <div className="view-row shrink">
                        <img className="gamestart-logo" src={locationData.gameStartThumb} alt={locationData.name} />
                    </div>
                    <div className="view-row shrink">
                        <p className="blue-header bold-text">{locationData.name}</p>
                    </div>
                    <div className="view-row shrink centered">
                        <p className="settings-blurb">{locationData.game.gameDescription}</p>
                    </div>
                    <div className="view-row grow"></div>
                    <div className="inventory-items-container centered">
                        <img className="pre-game-star" src={GameState.gameResults[locationData.game.id] > 0 ? StarUnlocked : StarLocked} alt={GameState.gameResults[locationData.game.id] > 0 ? 'Star Earned' : 'Star not earned'} />
                        <img className="pre-game-star" src={GameState.gameResults[locationData.game.id] > 1 ? StarUnlocked : StarLocked} alt={GameState.gameResults[locationData.game.id] > 1 ? 'Star Earned' : 'Star not earned'} />
                        <img className="pre-game-star" src={GameState.gameResults[locationData.game.id] > 2 ? StarUnlocked : StarLocked} alt={GameState.gameResults[locationData.game.id] > 2 ? 'Star Earned' : 'Star not earned'} />
                        <p className="prev-best-text">Previous best: {GameState.gameRecords[locationData.game.id]} {locationData.game.scoreUnits}</p>
                    </div>
                    <div className="view-row grow"></div>
                    <div className="inventory-items-container centered">
                        <p className="rewards-unlocked-text">Rewards Unlocked</p>
                        <img className="pre-game-unlocks" src={GameState.gameResults[locationData.game.id] > 0 ? CVUnlocked : CVLocked} alt={GameState.gameResults[locationData.game.id] > 0 ? 'Reference added to CV' : 'Reference not yet added to CV'} />
                        <img className="pre-game-unlocks" src={IsItemGot(locationData.game.itemEarned) ? locationData.game.itemEarned.gamePanelsItemUnlocked : locationData.game.itemEarned.gamePanelsItemLocked} alt={IsItemGot(locationData.game.itemEarned) ? locationData.game.itemEarned.description + ' Awarded' : locationData.game.itemEarned.description + ' Not Awarded'} />
                        <img className="pre-game-unlocks" src={GameState.gameResults[locationData.game.id] > 2 ? AttributeUnlocked : AttributeLocked} alt={GameState.gameResults[locationData.game.id] > 2 ? '3 Star Trophy awarded' : '3 Star Trophy not awarded'} />
                    </div>
                    <div className="view-row grow"></div>
                    <div className="view-row shrink">
                        <img className="gamestart-button active-button" src={StarGameButton} onClick={this.playGame} alt="Click to play this game" />
                    </div>
                    <div className="abs" style={{right: -10, top: -10}}>
                        <img className="close-button active-button" src={CloseButton} onClick={this.closePanel} alt="Click to close this panel" />
                    </div>
                    <div className="view-row grow"></div>
                </div>
            </div>
        );
    }
}