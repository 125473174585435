import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import { au, v } from '../../../koko-framework/shorthand';
import InventoryPanelIcon from '../../../assets/babcock_menus/inventory/inventory_iconLarge.png';
import TaskListPanelIcon from '../../../assets/babcock_menus/tasklist/listIconCircle.png';
import TaskCompleteIcon from '../../../assets/babcock_menus/tasklist/checkBox_ticked.png';
import TaskIncompleteIcon from '../../../assets/babcock_menus/tasklist/checkBox.png';
import CloseButton from '../../../assets/babcock_menus/close_btn.png';
import WorldController from "../../../controller/WorldController";
import { GameState, GetCvStarsEarned, SetNewInventoryItem } from "../../model/GameState";
import { TASK_LIST } from "../../model/constants";
import BabcockController from "../../controller/BabcockController";

export default class TaskListView extends ReactFullScreenView {
    componentDidMount() {
        BabcockController.pauseCharacter();
        super.componentDidMount();
    }

    closeClicked = () => {
        v.hideView(this.props.viewId);
        WorldController.enable();
        SetNewInventoryItem(false);

        au.play('closebtn', 1, false, 0, false);

        BabcockController.unpauseCharacter();
    }

    getTaskListItems = () => {
        // console.log('GameState: ', GameState);
        let activeTasks = [];
        for (let i = 0; i < TASK_LIST.length; i++) {
            let showTask = false;
            let completed = false;
            if (TASK_LIST[i].validGamePhases.indexOf(GameState.currentGameTask) >= 0) {
                // console.log('Show task? ', TASK_LIST[i]);
                showTask = true;
                if (TASK_LIST[i].showWhen) {
                    for (let p in TASK_LIST[i].showWhen) {
                        switch (p) {
                            case 'cvStars':
                                if (GetCvStarsEarned() < TASK_LIST[i].showWhen[p]) {
                                    showTask = false;
                                }
                                break;
                            case 'applicationSent':
                                if (GameState.applicationSent === false) {
                                    showTask = false;
                                }
                                break;
                            default:
                                if (GameState[p].indexOf(TASK_LIST[i].showWhen[p]) === -1) {
                                    showTask = false;
                                }
                                break;
                        }
                    }
                }
                if (showTask) {
                    // console.log('Task is shown, completed? ', TASK_LIST[i].completion);
                    completed = true;
                    for (let p in TASK_LIST[i].completion) {
                        switch (p) {
                            case 'applicationSent':
                                if (GameState.applicationSent === false) {
                                    completed = false;
                                }
                                break;
                            case 'playedGame':
                                let gameId = TASK_LIST[i].completion.playedGame.id;
                                if (GameState.gameResults[gameId] === 0) {
                                    completed = false;
                                }
                                break;
                            default:
                                if (GameState[p].indexOf(TASK_LIST[i].completion[p]) === -1) {
                                    completed = false;
                                }
                                break;
                        }
                    }
                    let taskForList = {
                        completed: completed,
                        description: TASK_LIST[i].description,
                    }
                    activeTasks.push(taskForList);
                }
            }
        }
        return activeTasks;
    }

    renderContent() {
        let tasks = this.getTaskListItems();
        return (
            <div className="full-screen-holder no-select default-cursor black-out">
                <div className="inventory-panel rel">
                    <div className="view-row shrink">
                        <img className="inventory-logo" src={TaskListPanelIcon} alt="Tasklist" />
                    </div>
                    <div className="view-row grow"><p>&nbsp;</p></div>
                    {
                        tasks.map(function(item, i) {
                            return (
                                <div key={'task' + i} className="view-row shrink">
                                    <div className="task-item-container flex-columns">
                                        <div className="shrink">
                                            <img className="task-list-check" src={item.completed ? TaskCompleteIcon : TaskIncompleteIcon} alt={item.completed ? 'Task completed' : 'Task not completed'} />
                                        </div>
                                        <div className="grow">
                                            <p className="task-list-text">{item.completed ? item.completeDescription || item.description : item.description}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="view-row grow"></div>
                    <div className="view-row grow"><p>&nbsp;</p></div>
                    <div className="abs" style={{right: -10, top: -10}}>
                        <img className="close-button active-button" src={CloseButton} onClick={this.closeClicked} alt="Click to close this panel" />
                    </div>
                </div>
            </div>
        );
    }
}