import React from "react";

// the assets we need to preload
// we want to be careful about the order we load these as our preloader only handles pixi assets
// our react-based assets will load in the background, but we want to make sure the ones we need
// first are loaded first, so start with the main menu assets and cookies panel

// main menu
import BabcockLogo from '../../../assets/babcock_menus/main_menu/babcock_logo.png';
import PlymouthLogo from '../../../assets/babcock_menus/main_menu/plymouth_logo.png';
import BigLogo from '../../../assets/babcock_menus/main_menu/logo_placeholder.png';
import PlayButton from '../../../assets/babcock_menus/main_menu/playgame_btn.png';
import SoundOffButton from '../../../assets/babcock_menus/sound_off.png';
import SoundOnButton from '../../../assets/babcock_menus/sound_on.png';
import ShareButton from '../../../assets/babcock_menus/main_menu/share_btn.png';

// cookies
import CookiesLogo from '../../../assets/babcock_menus/cookies_pop/cookies_img.png';
import LearnMoreButton from '../../../assets/babcock_menus/cookies_pop/learnmore_btn.png';
import AcceptButton from '../../../assets/babcock_menus/cookies_pop/accept_btn.png';

// select char
import BackButton from '../../../assets/babcock_menus/select_character/back_btn.png';
import LeftArrow from '../../../assets/babcock_menus/select_character/arrow_left.png';
import RightArrow from '../../../assets/babcock_menus/select_character/arrow_right.png';
import Char1 from '../../../assets/babcock_menus/select_character/mc_1.png';
import Char2 from '../../../assets/babcock_menus/select_character/mc_2.png';
import Char3 from '../../../assets/babcock_menus/select_character/mc_3.png';
import Char4 from '../../../assets/babcock_menus/select_character/mc_4.png';
import Char5 from '../../../assets/babcock_menus/select_character/mc_5.png';
import ActivePippet from '../../../assets/babcock_menus/select_character/pipet_active.png';
import InactivePippet from '../../../assets/babcock_menus/select_character/pipet_inactive.png';
import SelectButton from '../../../assets/babcock_menus/select_character/select_btn.png';

// enter name
import LetsGoButton from '../../../assets/babcock_menus/enter_name/confirm_btn.png';

// advisor dialog
import AdvisorIcon from '../../../assets/babcock_menus/in_game/dialog/advisor_dialogue.png';
import DialogArrow from '../../../assets/babcock_menus/in_game/dialog/dialogue_scroll.png';

// phone ringing
import PhoneIcon from '../../../assets/babcock_menus/in_game/dialog/Phone_dialogue.png';
// import DialogArrow from '../../../assets/babcock_menus/in_game/dialog/dialogue_scroll.png';

// inventory
import InventoryPanelIcon from '../../../assets/babcock_menus/inventory/inventory_iconLarge.png';
import CloseButton from '../../../assets/babcock_menus/close_btn.png';

// cv view
import ProfilePic from '../../../assets/babcock_menus/cv/top/profilepic.png';
import CvCarrier from '../../../assets/babcock_menus/cv/top/CV_carrier.png';
import ExperienceIcon from '../../../assets/babcock_menus/cv/experience/experience_icon.png';
import AttributesIcon from '../../../assets/babcock_menus/cv/attributes/attribute_icon.png';
import HobbiesIcon from '../../../assets/babcock_menus/cv/selfies/selfie_icon.png';
import SegmentCompleteStar from '../../../assets/babcock_menus/cv/attribute_segment_complete.png';
import SegmentIncompleteStar from '../../../assets/babcock_menus/cv/attribute_segment_incomplete.png';
import CoffeeUnlocked from '../../../assets/babcock_menus/cv/experience/coffee_Unlocked.png';
import CoffeeLocked from '../../../assets/babcock_menus/cv/experience/coffee_Locked.png';
import FoodUnlocked from '../../../assets/babcock_menus/cv/experience/food_Unlocked.png';
import FoodLocked from '../../../assets/babcock_menus/cv/experience/food_locked.png';
import MoneyUnlocked from '../../../assets/babcock_menus/cv/experience/money_Unlocked.png';
import MoneyLocked from '../../../assets/babcock_menus/cv/experience/money_Locked.png';
import ExpUnlockedStar from '../../../assets/babcock_menus/cv/experience/unlocked_icon.png';
import AttComplete from '../../../assets/babcock_menus/cv/attributes/attribute_complete.png';
import AttIncomplete from '../../../assets/babcock_menus/cv/attributes/attribute_incomplete.png';
import SelfieUnlocked from '../../../assets/babcock_menus/cv/selfies/selfie_unlocked.png';
import SelfieLocked from '../../../assets/babcock_menus/cv/selfies/selfie_Locked.png';

// settings
import ResumeButton from '../../../assets/babcock_menus/settings/resume_btn.png';
import QuitButton from '../../../assets/babcock_menus/settings/quit_btn.png';
import CTA from '../../../assets/babcock_menus/settings/CTA.png';
import Divider from '../../../assets/babcock_menus/settings/divider.png';

// cta info
import InfoButton1 from '../../../assets/babcock_menus/cta_info/01info_btn.png';
import InfoButton2 from '../../../assets/babcock_menus/cta_info/02video_btn.png';
import InfoButton3 from '../../../assets/babcock_menus/cta_info/03skills_btn.png';

// map transition
import BusBack from '../../../assets/babcock_menus/map_transitions/busBack.png';
import BusImg from '../../../assets/babcock_menus/map_transitions/busFront.png';
import BusWheel from '../../../assets/babcock_menus/map_transitions/busWheel.png';
import TaxiImg from '../../../assets/babcock_menus/map_transitions/taxi.png';
import TaxiWheel from '../../../assets/babcock_menus/map_transitions/taxiWheel.png';
import ProgressBg from '../../../assets/babcock_menus/map_transitions/progressDots.png';
import BusIconActive from '../../../assets/babcock_menus/map_transitions/busicon_active.png';
import BusIconInactive from '../../../assets/babcock_menus/map_transitions/busicon_inactive.png';
import TaxiIconActive from '../../../assets/babcock_menus/map_transitions/Taxiicon_active.png';
import TaxiIconInactive from '../../../assets/babcock_menus/map_transitions/Taxiicon_inactive.png';

// I think the order is becoming less important now

// selfie location
import SelfieLocationIcon  from '../../../assets/babcock_menus/selfie_location/intro/Selfielocation_temp.png';
import TakeSelfieButton from '../../../assets/babcock_menus/selfie_location/intro/takeselfie_btn.png';

// selfie result
import SmeatonsSelfie from '../../../assets/babcock_menus/selfie_location/reward/polaroid_temp.png';

import ProgressBg_selfie from '../../../assets/babcock_menus/selfie_location/reward/progress_bg.png';
import ProgressTop from '../../../assets/babcock_menus/selfie_location/reward/progress_top.png';
import ProgressSegment from '../../../assets/babcock_menus/selfie_location/reward/progress_segment.png';
import ContinueButton from '../../../assets/babcock_menus/selfie_location/reward/continue_btn.png';

// mini game start
import CoffeeShopIcon from "../../../assets/babcock_menus/mini_game_flow/coffee_shop/thumbnail.png";
import StarLocked from '../../../assets/babcock_menus/mini_game_flow/star_locked.png';
import StarUnlocked from '../../../assets/babcock_menus/mini_game_flow/star_Unlocked.png';
import CVUnlocked from '../../../assets/babcock_menus/mini_game_flow/CV_Unlocked.png';
import AttributeUnlocked from '../../../assets/babcock_menus/mini_game_flow/attribute_Unlocked.png';
import StartGameButton from '../../../assets/babcock_menus/mini_game_flow/playgame_btn.png';

// mini game reward
import BgBurst from '../../../assets/babcock_menus/select_character/bg_burst.png';
import CoffeeRewardIcon from '../../../assets/babcock_menus/mini_game_flow/Coffee_rewardUnlock.png';

// mini game completed
import CoffeeShopIcon_mgccomplete from '../../../assets/babcock_menus/mini_game_flow/coffee_shop/building_icon.png';
import CoffeeUnlocked_mgcomplete from '../../../assets/babcock_menus/mini_game_flow/coffee_shop/coffee_Unlocked.png';
import PlayAgainButton from '../../../assets/babcock_menus/mini_game_flow/playagain_btn.png';
import ReturnToMapButton from '../../../assets/babcock_menus/mini_game_flow/returntomap_btn.png';

// question panel
import AdvisorIcon_qpan from '../../../assets/babcock_menus/questions/advisor.png';
import ContinueButton_qpan from '../../../assets/babcock_menus/questions/continue_btn.png';

import SubmitButtonActive from '../../../assets/babcock_menus/questions/submit_active.png';
import SubmitButtonInactive from '../../../assets/babcock_menus/questions/submit_inactive.png';

// result screen
import CvThumbnail from '../../../assets/babcock_menus/cv_result/CV_thumbnail.png';
import StarUnlocked_res from '../../../assets/babcock_menus/cv_result/star_Unlocked.png';
import StarLocked_res from '../../../assets/babcock_menus/cv_result/star_locked.png';

import GameIcon from '../../../assets/babcock_menus/cv_result/game_icon.png';
import AttIcon from '../../../assets/babcock_menus/cv_result/attribute_icon.png';
import SelfieIcon from '../../../assets/babcock_menus/cv_result/selfie_icon.png';

import GameIconSmall from '../../../assets/babcock_menus/cv_result/game_icon_small.png';
import AttIconSmall from '../../../assets/babcock_menus/cv_result/attribute_icon_small.png';
import SelfieIconSmall from '../../../assets/babcock_menus/cv_result/selfie_icon_small.png';

import SectionCompleteMarker from '../../../assets/babcock_menus/cv_result/complete_icon.png';
import SectionIncompleteMarker from '../../../assets/babcock_menus/cv_result/Incomplete_icon.png';

// main game over
import CharacterThumb from '../../../assets/babcock_menus/game_over/character_thumbailTemp.png';
import CongratsBanner from '../../../assets/babcock_menus/game_over/congratulations.png';

import PlayAgainButton_mgo from '../../../assets/babcock_menus/game_over/playagain_btn.png';
import ShareButton_mgo from '../../../assets/babcock_menus/settings/share_btn.png';

import ArtillerySelfieThumb from '../../../assets/babcock_menus/selfie_location/intro/Selfielocation_artillery.png';
import CollegeSelfieThumb from '../../../assets/babcock_menus/selfie_location/intro/Selfielocation_college.png';
import DevonportSelfieThumb from '../../../assets/babcock_menus/selfie_location/intro/Selfielocation_devonport.png';
import ScienceParkSelfieThumb from '../../../assets/babcock_menus/selfie_location/intro/Selfielocation_science.png';
import SmeatonsSelfieThumb from '../../../assets/babcock_menus/selfie_location/intro/Selfielocation_smeatons.png';
import ArtillerySelfiePic from '../../../assets/babcock_menus/selfie_location/reward/polaroid_TheArtilleryTower.png';
import CollegeSelfiePic from '../../../assets/babcock_menus/selfie_location/reward/polaroid_CityCollegePlymouth.png';
import DevonportSelfiePic from '../../../assets/babcock_menus/selfie_location/reward/polaroid_DevonportColumn.png';
import ScienceParkSelfiePic from '../../../assets/babcock_menus/selfie_location/reward/polaroid_PlymouthSciencePark.png';
import SmeatonsSelfiePic from '../../../assets/babcock_menus/selfie_location/reward/polaroid_SmeatonsTower.png';
import ArtillerySelfieCv from '../../../assets/babcock_menus/cv/selfies/CVpolaroid_TheArtilleryTower.png';
import CollegeSelfieCv from '../../../assets/babcock_menus/cv/selfies/CVpolaroid_CityCollegePlymouth.png';
import DevonportSelfieCv from '../../../assets/babcock_menus/cv/selfies/CVpolaroid_DevonportColumn.png';
import ScienceParkSelfieCv from '../../../assets/babcock_menus/cv/selfies/CVpolaroid_PlymouthSciencePark.png';
import SmeatonsSelfieCv from '../../../assets/babcock_menus/cv/selfies/CVpolaroid_SmeatonsTower.png';

import CoffeeGameThumb from '../../../assets/babcock_menus/mini_game_flow/coffee_shop/thumbnail.png';
import CoffeeGameBuildingIcon from '../../../assets/babcock_menus/mini_game_flow/coffee_shop/building_icon.png';
import CoffeeGameItemLocked from '../../../assets/babcock_menus/mini_game_flow/coffee_shop/coffee_locked.png';
import CoffeeGameItemUnlocked from '../../../assets/babcock_menus/mini_game_flow/coffee_shop/coffee_Unlocked.png';
import CoffeeInventoryIcon from '../../../assets/babcock_menus/inventory/itembag_coffee.png';

import FoodBankGameThumb from '../../../assets/babcock_menus/mini_game_flow/foodbank/foodbank_thumbnail.png';
import FoodBankGameBuildingIcon from '../../../assets/babcock_menus/mini_game_flow/foodbank/foodbank_icon.png';
import VegGameItemLocked from '../../../assets/babcock_menus/mini_game_flow/foodbank/veg_Locked.png';
import VegGameItemUnlocked from '../../../assets/babcock_menus/mini_game_flow/foodbank/veg_Unlocked.png';
import VegInventoryIcon from '../../../assets/babcock_menus/inventory/itembag_foodbank.png';
import VegRewardIcon from '../../../assets/babcock_menus/mini_game_flow/foodbank_rewardUnlock.png';

import HotelGameThumb from '../../../assets/babcock_menus/mini_game_flow/hotel/hotel_thumbnail.png';
import HotelGameBuildingIcon from '../../../assets/babcock_menus/mini_game_flow/hotel/hotel_icon.png';
import MoneyGameItemLocked from '../../../assets/babcock_menus/mini_game_flow/hotel/money_locked.png';
import MoneyGameItemUnlocked from '../../../assets/babcock_menus/mini_game_flow/hotel/money_Unlocked.png';
import MoneyInventoryIcon from '../../../assets/babcock_menus/inventory/itembag_money.png';
import MoneyRewardIcon from '../../../assets/babcock_menus/mini_game_flow/hotel_rewardUnlock.png';

import ClothesInventoryIcon from '../../../assets/babcock_menus/inventory/itembag_clothes.png';

import Char1Dialog from '../../../assets/babcock_menus/in_game/dialog/mc_1.png';
import Char2Dialog from '../../../assets/babcock_menus/in_game/dialog/mc_2.png';
import Char3Dialog from '../../../assets/babcock_menus/in_game/dialog/mc_3.png';
import Char4Dialog from '../../../assets/babcock_menus/in_game/dialog/mc_4.png';
import Char5Dialog from '../../../assets/babcock_menus/in_game/dialog/mc_5.png';

import NeightbourDialogIcon from '../../../assets/babcock_menus/in_game/dialog/neighbour.png';
import LighthouseDialogIcon from '../../../assets/babcock_menus/in_game/dialog/lighthouseWorker.png';
import YoungPersonDialogIcon from '../../../assets/babcock_menus/in_game/dialog/workPlacement.png';
import GroundsmanDialogIcon from '../../../assets/babcock_menus/in_game/dialog/groundskeeper.png';
import CoffeeShopDialogIcon from '../../../assets/babcock_menus/in_game/dialog/coffeeManager.png';
import HotelDialogIcon from '../../../assets/babcock_menus/in_game/dialog/plumber.png';
import FoodBankDialogIcon from '../../../assets/babcock_menus/in_game/dialog/foodBankOrganiser.png';
import InterviewDialogIcon from '../../../assets/babcock_menus/in_game/dialog/babcock_int.png';
import AdvisorDialogIcon from '../../../assets/babcock_menus/in_game/dialog/skillsLaunchpadAdvisor.png';
import AdvisorDialogIconNoPhone from '../../../assets/babcock_menus/in_game/dialog/skillsLaunchpadAdvisor2.png';
import ClothesShopDialogIcon from '../../../assets/babcock_menus/in_game/dialog/shopOwner.png';
import RadioIcon from '../../../assets/babcock_menus/in_game/dialog/radio.png';

import CoffeePop from "../../../assets/babcock_menus/popup_coffee.png";
import MoneyPop from "../../../assets/babcock_menus/popup_money.png";
import ClothesPop from "../../../assets/babcock_menus/popup_formalwear.png";
import VegPop from "../../../assets/babcock_menus/popup_veg.png";

import PromptImg1 from '../../../assets/babcock_menus/prompts/01_babcock.png';
import PromptImg2 from '../../../assets/babcock_menus/prompts/02_tap.png';
import PromptImg3 from '../../../assets/babcock_menus/prompts/03_longpress.png';
import PromptImg4 from '../../../assets/babcock_menus/prompts/04_prompts.png';
import PromptImg5 from '../../../assets/babcock_menus/prompts/01.png';

import LEADERSHIP_LOCKED from '../../../assets/babcock_menus/badges/b_leadership_small.png';
import LEADERSHIP_UNLOCKED from '../../../assets/babcock_menus/badges/b_leadership_large.png';
import SPEAKING_LOCKED from '../../../assets/babcock_menus/badges/b_speaking_small.png';
import SPEAKING_UNLOCKED from '../../../assets/babcock_menus/badges/b_speaking_large.png';
import PROBLEM_SOLVING_LOCKED from '../../../assets/babcock_menus/badges/b_problemSolving_small.png';
import PROBLEM_SOLVING_UNLOCKED from '../../../assets/babcock_menus/badges/b_problemSolving_large.png';
import CREATIVITY_LOCKED from '../../../assets/babcock_menus/badges/b_creativity_small.png';
import CREATIVITY_UNLOCKED from '../../../assets/babcock_menus/badges/b_creativity_large.png';
import STAYING_POSITIVE_LOCKED from '../../../assets/babcock_menus/badges/b_stayingPositive_small.png';
import STAYING_POSITIVE_UNLOCKED from '../../../assets/babcock_menus/badges/b_stayingPositive_large.png';
import AIMING_HIGH_LOCKED from '../../../assets/babcock_menus/badges/b_aimingHigh_small.png';
import AIMING_HIGH_UNLOCKED from '../../../assets/babcock_menus/badges/b_aimingHigh_large.png';
import LISTENING_LOCKED from '../../../assets/babcock_menus/badges/b_listening_small.png';
import LISTENING_UNLOCKED from '../../../assets/babcock_menus/badges/b_listening_large.png';
import TEAMWORK_LOCKED from '../../../assets/babcock_menus/badges/b_teamwork_small.png';
import TEAMWORK_UNLOCKED from '../../../assets/babcock_menus/badges/b_teamwork_large.png';

import PromptImg6 from '../../../assets/babcock_menus/prompts/05_orbs.png';

import Char1DialogSuited from '../../../assets/babcock_menus/in_game/dialog/mc_1Suit.png';
import Char2DialogSuited from '../../../assets/babcock_menus/in_game/dialog/mc_2Suit.png';
import Char3DialogSuited from '../../../assets/babcock_menus/in_game/dialog/mc_3Suit.png';
import Char4DialogSuited from '../../../assets/babcock_menus/in_game/dialog/mc_4Suit.png';
import Char5DialogSuited from '../../../assets/babcock_menus/in_game/dialog/mc_5Suit.png';

import GameMarkersPrompt from '../../../assets/babcock_menus/prompts/04b_gamemarkers.png';
import SkipButton from '../../../assets/babcock_menus/video/skip.png';

export const REACT_PRELOADER = {
    ref: null,
}

export default class PreloadReactAssets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            preloadMenuAssets: false,
            preloadAllAssets: false,
        }

        REACT_PRELOADER.ref = this;
    }

    /* componentDidMount() {
        setTimeout(this.queueUpAllAssets, 500);
    } */

    queueUpMenuAssets = () => {
        this.setState({preloadMenuAssets: true});
    }

    queueUpAllAssets = () => {
        setTimeout(
            () => this.setState({preloadAllAssets: true})
        , 1000);
    }

    render() {
        return (
            <div className="react-preload-holder">
                {this.state.preloadMenuAssets &&
                    <>
                        <img src={BabcockLogo} />
                        <img src={PlymouthLogo} />
                        <img src={BigLogo} />
                        <img src={PlayButton} />
                        <img src={SoundOffButton} />
                        <img src={SoundOnButton} />
                        <img src={ShareButton} />

                        <img src={CookiesLogo} />
                        <img src={LearnMoreButton} />
                        <img src={AcceptButton} />
                        <img src={BackButton} />
                        <img src={LeftArrow} />
                        <img src={RightArrow} />
                        <img src={Char1} />
                        <img src={Char2} />
                        <img src={Char3} />
                        <img src={Char4} />
                        <img src={Char5} />
                        <img src={ActivePippet} />
                        <img src={InactivePippet} />
                        <img src={SelectButton} />
                        <p style={{fontFamily: 'OpenSansReg'}}>Font 1</p>
                        <p style={{fontFamily: 'OpenSansBold'}}>Font 2</p>
                        <p style={{fontFamily: 'OpenSansLight'}}>Font 3</p>
                        <div className="main-menu"></div>
                        <div className="gradient-bg"></div>
                        <div className="char-container"></div>
                    </>
                }

                {this.state.preloadAllAssets &&
                    <>

                        <img src={LetsGoButton} />

                        <img src={AdvisorIcon} />
                        <img src={DialogArrow} />

                        <img src={PhoneIcon} />

                        <img src={InventoryPanelIcon} />
                        <img src={CloseButton} />

                        <img src={ProfilePic} />
                        <img src={CvCarrier} />
                        <img src={ExperienceIcon} />
                        <img src={AttributesIcon} />
                        <img src={HobbiesIcon} />
                        <img src={SegmentCompleteStar} />
                        <img src={SegmentIncompleteStar} />
                        <img src={CoffeeUnlocked} />
                        <img src={CoffeeLocked} />
                        <img src={FoodLocked} />
                        <img src={FoodUnlocked} />
                        <img src={MoneyLocked} />
                        <img src={MoneyUnlocked} />
                        <img src={ExpUnlockedStar} />
                        <img src={AttComplete} />
                        <img src={AttIncomplete} />
                        <img src={SelfieLocked} />
                        <img src={SelfieUnlocked} />

                        <img src={ResumeButton} />
                        <img src={QuitButton} />
                        <img src={CTA} />
                        <img src={Divider} />

                        <img src={InfoButton1} />
                        <img src={InfoButton2} />
                        <img src={InfoButton3} />

                        <img src={BusImg} />
                        <img src={BusBack} />
                        <img src={BusWheel} />
                        <img src={TaxiWheel} />
                        <img src={TaxiImg} />
                        <img src={ProgressBg} />
                        <img src={BusIconActive} />
                        <img src={BusIconInactive} />
                        <img src={TaxiIconActive} />
                        <img src={TaxiIconInactive} />

                        <img src={SelfieLocationIcon} />
                        <img src={TakeSelfieButton} />

                        <img src={SmeatonsSelfie} />
                        <img src={ProgressBg_selfie} />
                        <img src={ProgressTop} />
                        <img src={ProgressSegment} />
                        <img src={ContinueButton} />

                        <img src={CoffeeShopIcon} />
                        <img src={StarLocked} />
                        <img src={StarUnlocked} />
                        <img src={CVUnlocked} />
                        <img src={AttributeUnlocked} />
                        <img src={StartGameButton} />

                        <img src={BgBurst} />
                        <img src={CoffeeRewardIcon} />

                        <img src={CoffeeShopIcon_mgccomplete} />
                        <img src={CoffeeUnlocked_mgcomplete} />
                        <img src={PlayAgainButton} />
                        <img src={ReturnToMapButton} />

                        <img src={AdvisorIcon_qpan} />
                        <img src={ContinueButton_qpan} />
                        <img src={SubmitButtonActive} />
                        <img src={SubmitButtonInactive} />

                        <img src={CvThumbnail} />
                        <img src={StarUnlocked_res} />
                        <img src={StarLocked_res} />
                        <img src={GameIcon} />
                        <img src={AttIcon} />
                        <img src={SelfieIcon} />
                        <img src={GameIconSmall} />
                        <img src={AttIconSmall} />
                        <img src={SelfieIconSmall} />
                        <img src={SectionCompleteMarker} />
                        <img src={SectionIncompleteMarker} />

                        <img src={CharacterThumb} />
                        <img src={CongratsBanner} />
                        <img src={PlayAgainButton_mgo} />
                        <img src={ShareButton_mgo} />

                        <div className="map-transition-bg"></div>
                        <div className="trans-bg-scroll-back-layer"></div>
                        <div className="trans-bg-scroll-front-layer"></div>
                        <div className="reward-bg"></div>
                        <div className="main-game-over-bg"></div>
                        <div className="cv-hob-bg"></div>
                        <div className="cv-exp-bg"></div>
                        <div className="cta-header"></div>
                        <div className="result-progress-fill"></div>

                        <img src={ArtillerySelfieThumb} />
                        <img src={CollegeSelfieThumb} />
                        <img src={DevonportSelfieThumb} />
                        <img src={ScienceParkSelfieThumb} />
                        <img src={SmeatonsSelfieThumb} />
                        <img src={ArtillerySelfiePic} />
                        <img src={CollegeSelfiePic} />
                        <img src={DevonportSelfiePic} />
                        <img src={ScienceParkSelfiePic} />
                        <img src={SmeatonsSelfiePic} />
                        <img src={ArtillerySelfieCv} />
                        <img src={CollegeSelfieCv} />
                        <img src={DevonportSelfieCv} />
                        <img src={ScienceParkSelfieCv} />
                        <img src={SmeatonsSelfieCv} />
                        <img src={CoffeeGameThumb} />
                        <img src={CoffeeGameBuildingIcon} />
                        <img src={CoffeeGameItemLocked} />
                        <img src={CoffeeGameItemUnlocked} />
                        <img src={CoffeeInventoryIcon} />
                        <img src={FoodBankGameThumb} />
                        <img src={FoodBankGameBuildingIcon} />
                        <img src={VegGameItemLocked} />
                        <img src={VegGameItemUnlocked} />
                        <img src={VegInventoryIcon} />
                        <img src={VegRewardIcon} />
                        <img src={HotelGameThumb} />
                        <img src={HotelGameBuildingIcon} />
                        <img src={MoneyGameItemLocked} />
                        <img src={MoneyGameItemUnlocked} />
                        <img src={MoneyInventoryIcon} />
                        <img src={MoneyRewardIcon} />
                        <img src={ClothesInventoryIcon} />
                        <img src={Char1Dialog} />
                        <img src={Char2Dialog} />
                        <img src={Char3Dialog} />
                        <img src={Char4Dialog} />
                        <img src={Char5Dialog} />
                        <img src={NeightbourDialogIcon} />
                        <img src={LighthouseDialogIcon} />
                        <img src={YoungPersonDialogIcon} />
                        <img src={GroundsmanDialogIcon} />
                        <img src={CoffeeShopDialogIcon} />
                        <img src={HotelDialogIcon} />
                        <img src={FoodBankDialogIcon} />
                        <img src={InterviewDialogIcon} />
                        <img src={AdvisorDialogIcon} />
                        <img src={AdvisorDialogIconNoPhone} />
                        <img src={ClothesShopDialogIcon} />
                        <img src={RadioIcon} />
                        <img src={CoffeePop} />
                        <img src={MoneyPop} />
                        <img src={ClothesPop} />
                        <img src={VegPop} />
                        <img src={PromptImg1} />
                        <img src={PromptImg2} />
                        <img src={PromptImg3} />
                        <img src={PromptImg4} />
                        <img src={PromptImg5} />
                        <img src={PromptImg6} />
                        <img src={LEADERSHIP_LOCKED} />
                        <img src={LEADERSHIP_UNLOCKED} />
                        <img src={SPEAKING_LOCKED} />
                        <img src={SPEAKING_UNLOCKED} />
                        <img src={PROBLEM_SOLVING_LOCKED} />
                        <img src={PROBLEM_SOLVING_UNLOCKED} />
                        <img src={CREATIVITY_LOCKED} />
                        <img src={CREATIVITY_UNLOCKED} />
                        <img src={STAYING_POSITIVE_LOCKED} />
                        <img src={STAYING_POSITIVE_UNLOCKED} />
                        <img src={AIMING_HIGH_LOCKED} />
                        <img src={AIMING_HIGH_UNLOCKED} />
                        <img src={LISTENING_LOCKED} />
                        <img src={LISTENING_UNLOCKED} />
                        <img src={TEAMWORK_LOCKED} />
                        <img src={TEAMWORK_UNLOCKED} />

                        <img src={Char1DialogSuited} />
                        <img src={Char2DialogSuited} />
                        <img src={Char3DialogSuited} />
                        <img src={Char4DialogSuited} />
                        <img src={Char5DialogSuited} />

                        <img src={GameMarkersPrompt} />
                        <img src={SkipButton} />
                    </>
                }
            </div>
        );
    }
}