/**
 * Bootstrap our game - this component uses a componentDidMount event to kick everything in to life
 * @author Carl Trelfa
 */
import React from 'react'
import app from './koko-framework/app'
import main from './main'
import koko from './koko-framework/koko';
import * as PIXI from 'pixi.js'

import ReactViewController from './koko-framework/ui/react-views/ReactViewController';
import GenInteractionPanel from './view/react-views/GenInteractionPanel';
import MainMenu from './babcock-neet/view/react-views/MainMenu';
import CookiesPanel from './babcock-neet/view/react-views/CookiesPanel';
import SelectCharacter from './babcock-neet/view/react-views/SelectCharacter';
import NameEntry from './babcock-neet/view/react-views/NameEntry';
import CvView from './babcock-neet/view/react-views/CvView';
import PhoneRinging from './babcock-neet/view/react-views/PhoneRinging';
import AdvisorDialogPanel from './babcock-neet/view/react-views/AdvisorDialogPanel';
import SettingsPanel from './babcock-neet/view/react-views/SettingsPanel';
import CtaInfoPanel from './babcock-neet/view/react-views/CtaInfoPanel';
import InventoryPanel from './babcock-neet/view/react-views/InventoryPanel';
import GameStartPanel from './babcock-neet/view/react-views/GameStartPanel';
import GameEndPanel from './babcock-neet/view/react-views/GameEndPanel';
import GameReward from './babcock-neet/view/react-views/GameReward';
import SelfieLocation from './babcock-neet/view/react-views/SelfieLocation';
import SelfieResult from './babcock-neet/view/react-views/SelfieResult';
import MapTransition from './babcock-neet/view/react-views/MapTransition';
import QuestionPanel from './babcock-neet/view/react-views/QuestionPanel';
import MainGameOver from './babcock-neet/view/react-views/MainGameOver';
import ResultScreen from './babcock-neet/view/react-views/ResultScreen';
import PreloadReactAssets from './babcock-neet/view/react-views/PreloadReactAssets';
import SendCVPrompt from './babcock-neet/view/react-views/SendCVPrompt';
import TaskListView from './babcock-neet/view/react-views/TaskListView';
import HudTimer from './babcock-neet/view/react-views/HudTimer';
import LitterCounter from './babcock-neet/view/react-views/LitterCounter';
import InstructionPrompt from './babcock-neet/view/react-views/InstructionPrompt';
import BottomBehindGradient from './babcock-neet/view/react-views/BottomBehindGradient';
import BottomBehindGradient2 from './babcock-neet/view/react-views/BottomBehindGradient2';
import BottomBehindGradient3 from './babcock-neet/view/react-views/BottomBehindGradient3';
import BlackBg from './babcock-neet/view/react-views/BlackBg';
import { v } from './koko-framework/shorthand';
import GreenBg from './babcock-neet/view/react-views/GreenBg';
import PausePanel from './babcock-neet/view/react-views/PausePanel';
import BadgeInfoPop from './babcock-neet/view/react-views/BadgeInfoPop';
import MoreDetailsForm from './babcock-neet/view/react-views/MoreDetailsForm';
import GameTutorial from './babcock-neet/view/react-views/GameTutorial';
import BlackFade from './babcock-neet/view/react-views/BlackFade';
import GameRewardBG from './babcock-neet/view/react-views/GameRewardBG';
import NewItemPop from './babcock-neet/view/react-views/NewItemPop';
import SharePanel from './babcock-neet/view/react-views/SharePanel';
import SkipTutorial from './babcock-neet/view/react-views/SkipTutorial';
import VideoView from './babcock-neet/view/react-views/VideoView';

export const REACT_VIEW_CONTROLLER = new ReactViewController();
// this may well need to be refactored, but for getting our generic interaction up and running it'll be fine
export const PANEL_STATE = {
    panelOpen: false,
    contents: 'Nothing!',
    complexDialog: null,
    promptMessages: [],
    promptImages: [],
    promptArias: [],
    contentData: null,
}

export default class Bootstrap extends React.Component {
    constructor(props) {
        super(props);
        // this.pixi_cnt = React.createRef();
        // this.bootstrapped = false;

        this.state = {
            bootstrapped: false,
        };

        REACT_VIEW_CONTROLLER.init(this);

        // REACT_VIEW_CONTROLLER.addViewId('blackbg');
        // REACT_VIEW_CONTROLLER.addViewId('greenbg');
        REACT_VIEW_CONTROLLER.addViewId('bottomgrad');
        REACT_VIEW_CONTROLLER.addViewId('bottomgrad2');
        REACT_VIEW_CONTROLLER.addViewId('bottomgrad3');
        REACT_VIEW_CONTROLLER.addViewId('interactionpanel', this.positionInteractionPanel);
        REACT_VIEW_CONTROLLER.addViewId('mainmenu');
        REACT_VIEW_CONTROLLER.addViewId('cookiespanel');
        REACT_VIEW_CONTROLLER.addViewId('selectcharacter');
        REACT_VIEW_CONTROLLER.addViewId('nameentry');
        REACT_VIEW_CONTROLLER.addViewId('cvview');
        REACT_VIEW_CONTROLLER.addViewId('phoneringing');
        REACT_VIEW_CONTROLLER.addViewId('advisordialog');
        REACT_VIEW_CONTROLLER.addViewId('prompts');
        REACT_VIEW_CONTROLLER.addViewId('settings');
        REACT_VIEW_CONTROLLER.addViewId('ctainfo');
        REACT_VIEW_CONTROLLER.addViewId('inventorypanel');
        REACT_VIEW_CONTROLLER.addViewId('startgame');
        REACT_VIEW_CONTROLLER.addViewId('endofgame');
        REACT_VIEW_CONTROLLER.addViewId('gamerewardbg');
        REACT_VIEW_CONTROLLER.addViewId('gamereward');
        REACT_VIEW_CONTROLLER.addViewId('selfielocation');
        REACT_VIEW_CONTROLLER.addViewId('selfieresult');
        REACT_VIEW_CONTROLLER.addViewId('maptransition');
        REACT_VIEW_CONTROLLER.addViewId('questions');
        REACT_VIEW_CONTROLLER.addViewId('maingameover');
        REACT_VIEW_CONTROLLER.addViewId('resultscreen');
        REACT_VIEW_CONTROLLER.addViewId('sendcvprompt');
        REACT_VIEW_CONTROLLER.addViewId('tasklist');
        REACT_VIEW_CONTROLLER.addViewId('hudtimer');
        REACT_VIEW_CONTROLLER.addViewId('hudlitter');
        REACT_VIEW_CONTROLLER.addViewId('pausepanel');
        REACT_VIEW_CONTROLLER.addViewId('badgeinfopop');
        REACT_VIEW_CONTROLLER.addViewId('contactform');
        REACT_VIEW_CONTROLLER.addViewId('gametutorial');
        REACT_VIEW_CONTROLLER.addViewId('blackfade');
        REACT_VIEW_CONTROLLER.addViewId('newitempop');
        REACT_VIEW_CONTROLLER.addViewId('sharepanel');
        REACT_VIEW_CONTROLLER.addViewId('skiptutorial');
        REACT_VIEW_CONTROLLER.addViewId('introvideo');
    }

    positionInteractionPanel = () => {
        let vp = app.viewPort;
        let panelInstance = koko.views.get('interactionpanel');
        panelInstance.setPosition(vp.centerX /*+ Math.floor(Math.random() * 200) - 100*/, 200 /*vp.centerY + Math.floor(Math.random() * 200) - 100)*/);
    }

    componentDidMount() {
        if (!this.bootstrapped) {
            // set it going
            window.deviceWidth = window.innerWidth;
            window.deviceHeight = window.innerHeight;

            // PIXI.SCALE_MODES.DEFAULT = PIXI.SCALE_MODES.NEAREST;
            // you can set gui size and other stuff here

            app.guiSize = { width: 840, height: 1400 };
            app.minSize = { width: 600, height: 990 };
            app.bgColor = 0x0B86CC;
            app.anti_alias = true;
            app.retina = true;
            app.roundPixels = true;
            app.forceFXAA = false;
            app.autoDensity = true;
            // app.transparent = true;

            app.init();
            app.setupComplete = function () {
                main.init();
                app.resize();
            }
            app.loaded();
            app.appendRenderer();

            // I tried to get the renderer appended to a specific div, but that didn't seem to work
            // So I stuck with appending it before the first child on the body class
            // Prob needs changing really
            // console.log('state: ', this.state);
            // if (this.state.renderer != app.renderer) {
            // this.setState({appView: app.renderer.renderer.view});
            // } else {
            // this.updatePixiCnt(this.pixi_cnt);
            // }
            // this.pixi_cnt.appendChild(app.renderer.renderer.view);

            // this.bootstrapped = true;
            this.setState({ bootstrapped: true });

            /* View Hiding and Showing is now universal for pixi and react views */
            koko.views.registerReactViewController(REACT_VIEW_CONTROLLER);
            /*
             * You can now do:
             *      koko.views.showView / showViews / showAllViews / showAllViewsBut
             *      koko.views.hideView / hideViews / hideAllViews / hideAllViewsBut
             * 
             * Pixi views will be transitioned in / out and so will React views, 
             * so the same line of code regardless! 
             * 
             * Take a look in the preloader to see this in action.
             */

            console.log('koko app: ', app);

            // v.showView('blackbg');
        }
    }

    // canvas will be attached inside our game-div
    // will probably need some css, depending on your needs
    render() {
        return (
            <>
                {this.state.bootstrapped && <PreloadReactAssets></PreloadReactAssets>}
                {/* We can only access our viewport data once bootstrapped! */}
                {this.state.newitempop_active && <NewItemPop viewId="newitempop" viewController={REACT_VIEW_CONTROLLER}></NewItemPop>}
                {/*this.state.greenbg_active && <GreenBg viewId="greenbg" viewController={REACT_VIEW_CONTROLLER}></GreenBg>*/}
                {/*this.state.blackbg_active && <BlackBg viewId="blackbg" viewController={REACT_VIEW_CONTROLLER}></BlackBg>*/}
                {this.state.bottomgrad_active && <BottomBehindGradient viewId="bottomgrad" viewController={REACT_VIEW_CONTROLLER}></BottomBehindGradient> }
                {this.state.bottomgrad2_active && <BottomBehindGradient2 viewId="bottomgrad2" viewController={REACT_VIEW_CONTROLLER}></BottomBehindGradient2> }
                {this.state.bottomgrad3_active && <BottomBehindGradient3 viewId="bottomgrad3" viewController={REACT_VIEW_CONTROLLER}></BottomBehindGradient3> }
                {this.state.interactionpanel_active && <GenInteractionPanel viewId="interactionpanel" viewController={REACT_VIEW_CONTROLLER}></GenInteractionPanel>}
                {this.state.hudtimer_active && <HudTimer viewId="hudtimer" viewController={REACT_VIEW_CONTROLLER}></HudTimer>}
                {this.state.hudlitter_active && <LitterCounter viewId="hudlitter" viewController={REACT_VIEW_CONTROLLER}></LitterCounter>}
                {this.state.inventorypanel_active && <InventoryPanel viewId="inventorypanel" viewController={REACT_VIEW_CONTROLLER}></InventoryPanel>}
                {this.state.tasklist_active && <TaskListView viewId="tasklist" viewController={REACT_VIEW_CONTROLLER}></TaskListView>}
                {this.state.cvview_active && <CvView viewId="cvview" viewController={REACT_VIEW_CONTROLLER}></CvView>}
                {this.state.blackfade_active && <BlackFade viewId="blackfade" viewController={REACT_VIEW_CONTROLLER}></BlackFade>}
                {this.state.phoneringing_active && <PhoneRinging viewId="phoneringing" viewController={REACT_VIEW_CONTROLLER}></PhoneRinging>}
                {this.state.advisordialog_active && <AdvisorDialogPanel viewId="advisordialog" viewController={REACT_VIEW_CONTROLLER}></AdvisorDialogPanel>}
                {this.state.sendcvprompt_active && <SendCVPrompt viewId="sendcvprompt" viewController={REACT_VIEW_CONTROLLER}></SendCVPrompt>}
                {this.state.prompts_active && <InstructionPrompt viewId="prompts" viewController={REACT_VIEW_CONTROLLER}></InstructionPrompt>}
                {this.state.settings_active && <SettingsPanel viewId="settings" viewController={REACT_VIEW_CONTROLLER}></SettingsPanel>}
                {this.state.pausepanel_active && <PausePanel viewId="pausepanel" viewController={REACT_VIEW_CONTROLLER}></PausePanel>}
                {this.state.startgame_active && <GameStartPanel viewId="startgame" viewController={REACT_VIEW_CONTROLLER}></GameStartPanel>}
                {this.state.gamerewardbg_active && <GameRewardBG viewId="gamerewardbg" viewController={REACT_VIEW_CONTROLLER}></GameRewardBG>}
                {this.state.endofgame_active && <GameEndPanel viewId="endofgame" viewController={REACT_VIEW_CONTROLLER}></GameEndPanel>}
                {this.state.gamereward_active && <GameReward viewId="gamereward" viewController={REACT_VIEW_CONTROLLER}></GameReward>}
                {this.state.selfielocation_active && <SelfieLocation viewId="selfielocation" viewController={REACT_VIEW_CONTROLLER}></SelfieLocation>}
                {this.state.selfieresult_active && <SelfieResult viewId="selfieresult" viewController={REACT_VIEW_CONTROLLER}></SelfieResult>}
                {this.state.maingameover_active && <MainGameOver viewId="maingameover" viewController={REACT_VIEW_CONTROLLER}></MainGameOver>}
                {this.state.resultscreen_active && <ResultScreen viewId="resultscreen" viewController={REACT_VIEW_CONTROLLER}></ResultScreen>}
                {this.state.ctainfo_active && <CtaInfoPanel viewId="ctainfo" viewController={REACT_VIEW_CONTROLLER}></CtaInfoPanel>}
                {this.state.badgeinfopop_active && <BadgeInfoPop viewId="badgeinfopop" viewController={REACT_VIEW_CONTROLLER}></BadgeInfoPop>}
                {this.state.gametutorial_active && <GameTutorial viewId="gametutorial" viewController={REACT_VIEW_CONTROLLER}></GameTutorial>}
                {this.state.mainmenu_active && <MainMenu viewId="mainmenu" viewController={REACT_VIEW_CONTROLLER}></MainMenu>}
                {this.state.selectcharacter_active && <SelectCharacter viewId="selectcharacter" viewController={REACT_VIEW_CONTROLLER}></SelectCharacter>}
                {this.state.nameentry_active && <NameEntry viewId="nameentry" viewController={REACT_VIEW_CONTROLLER}></NameEntry>}
                {this.state.maptransition_active && <MapTransition viewId="maptransition" viewController={REACT_VIEW_CONTROLLER}></MapTransition>}
                {this.state.sharepanel_active && <SharePanel viewId="sharepanel" viewController={REACT_VIEW_CONTROLLER}></SharePanel>}
                {this.state.cookiespanel_active && <CookiesPanel viewId="cookiespanel" viewController={REACT_VIEW_CONTROLLER}></CookiesPanel>}
                {this.state.questions_active && <QuestionPanel viewId="questions" viewController={REACT_VIEW_CONTROLLER}></QuestionPanel>}
                {this.state.skiptutorial_active && <SkipTutorial viewId="skiptutorial" viewController={REACT_VIEW_CONTROLLER}></SkipTutorial>}
                {this.state.contactform_active && <MoreDetailsForm viewId="contactform" viewController={REACT_VIEW_CONTROLLER}></MoreDetailsForm>}
                {this.state.introvideo_active && <VideoView viewId="introvideo" viewController={REACT_VIEW_CONTROLLER}></VideoView>}
            </>
        );
    }
}
