import app from '../koko-framework/app'
import * as PIXI from 'pixi.js'
import gsap from 'gsap'

import { FRAME_TIME, FRAME_DELTA_LIMIT, DEBUG } from '../model/config'

import * as TileSet from '../koko-framework/tile-engine/TileSet';

import { MapLayouts } from '../model/tilemaps/MapLayouts';
import * as MapChunk from '../koko-framework/tile-engine/MapChunk'

import { ScrollingMapController } from '../koko-framework/tile-engine/ScrollingMapController';

import * as CHARACTERS from '../model/charactersSprites';

import { ORTHAGONAL } from '../koko-framework/tile-engine/TileMapEngine'
import { PathFindingEntity } from '../koko-framework/tile-engine/entities/PathFindingEntity'
import { WayPointFollwerNPCEntity } from '../koko-framework/tile-engine/entities/WayPointFollowerNPCEntity'
import { CharacterFollowerNPCEntity } from '../koko-framework/tile-engine/entities/CharacterFollowerNPCEntity'
import { InGameEntity } from '../koko-framework/tile-engine/entities/InGameEntity';
import { v } from '../koko-framework/shorthand';
import { LeadToDestinationNPCEntity } from '../koko-framework/tile-engine/entities/LeadToDestinationNPCEntity';

const CHARACTER_Y_OFFSET = 45;
const DEPTH_SORT_LAYER = 4;
const LAYER_LABELS = [
    [
        'water_back',
        'ground_back',
        'ground_detail_1',
        'ground_detail_2',
        'depth_sorted_objects',
        'top',
        'top_2',
        'blockers',
        'flying_things',
    ],
    [
        'Water',
        'Ground-Behind',
        'Ground-Details',
        'Ground-Details-2',
        'Depth-Sorted-Objects-Characters',
        'Above-Character',
        'Above-Character-2',
        'Blockers',
        'Flying-Things',
    ]
];
const BLOCKING_LAYERS = [false, true, true, true, true, false, false, true, false];
// expand tiles to cover cracks
const TILE_BUFFERS = [0, 4, 1, 0, 0, 0, 0, 0, 0];
export const AVAILABLE_ZOOMS = [1, 0.85, 0.7, 0.55];

// hiding and showing tiles is by far the most costly operation, so we can do it every other frame
// by setting this to 2, theoretically halving thetime we spend doing that
const HIDE_TILES_FREQUENCY = 2;
// if you are only hiding tiles every other frame then you most likely need a bit of a buffer
// arond the edge of the viewable area
const VIEW_EDGE_BUFFER = 8;

class WorldController {
    gameView = null;
    scrollingTilemapController = null;
    viewRect = null;

    zoomEnabled = true;
    zoomLevel = 1;

    nonStandardEntityClasses = null;

    canLeaveMap = true;
    headingToDoor = false;
    doorLeadsTo = null;
    exitDir = null;
    entryPoint = null;
    targetSquareSprites = null;

    currentMap = null;
    nextMap = null;
    lastMap = null;
    lastMapExitGridX = -1;
    lastMapExitGridY = -1;
    transitioningMap = false;

    /* -- Izzy is now a PathFindingEntity -- */
    mainCharacterEntity = null;
    maincharacterSpriteData = null;

    type = 0;
    tileWidth = 64;
    tileHeight = 64;
    inited = false;
    active = false;
    enabled = false;

    useDoubleGridForPathFinding = true;
    allowDiagonalMovement = true;
    allowLongTapMovement = true;

    pauseOffScreenEntities = false;
    // The part of interacting this class will deal with is to pause a clicked npc to make them wait for us to get close,
    // then callback to the interactCallback function passing any npcs / tiles we are interacting with.
    // If allowGenericInteractions is true, then this will apply to all npcs (except ones with noInteraction == true),
    // otherwise only npcs with storyCharacter == true will apply. It will then be up to the callback function to deal with.
    allowGenericNpcInteractions = true;
    entityToInteractWith = null;

    interactCallback = null;            /* should accept 2 parameters - (tiles[], entity) */
    clickCallback = null;               /* should accept the original event data, will be called only when this controller is disabled */
    mapAboutToRemoveCallback = null;    /* called when the map is going to remove in case you need to do anything */
    mapInitialisingCallback = null;     /* called when the map inits so you can make any appropriate changes to the map */
    mapInitialisedCallback = null;      /* called when our map is completely set up, you can disable the map here and show some pop ups or something */
    npcsAddedCallback = null;           /* will be passed an array of inited npcs so we can process them in some way */
    updateCallback = null;              /* Called every time the world updates, useful for hiding / showing stuff if you need to */
    collectableCallback = null;         /* Called when a character walks into a square with a collectable in it */

    suspended = false;

    inactiveNpcs = [];

    hideTilesFrequencyCount = 0;

    init(tilesets, maps, mapLabels, type = ORTHAGONAL, tileWidth = 64, tileHeight = 64) {
        // console.log('init world controller: ', tileWidth, tileHeight, this);
        if (!this.inited) {
            TileSet.InitJsonTilesets(tilesets, 64);
            MapChunk.ProcessMapChunksFromJsonData(maps, mapLabels);
            this.type = type;
            this.tileWidth = tileWidth;
            this.tileHeight = tileHeight;
            CHARACTERS.generateCharacterAnims();
            this.inited = true;
        }
    }

    reset(startMap) {
        if (!this.active && this.inited) {
            this.suspended = false;

            this.currentMap = startMap;
            this.nextMap = null;
            this.lastMap = null;
            this.lastMapExitGridX = -1;
            this.lastMapExitGridY = -1;
            this.transitioningMap = false;

            this.headingToDoor = false;
            this.doorLeadsTo = null;
            this.exitDir = null;
            this.entryPoint = null;
            this.targetSquareSprites = null;

            this.viewRect = null;
        }
    }

    // --- activate / deactivate the map - actually adds / removes the map from view
    activate(view) {
        console.log('Activate world controller: ', this.tileWidth, this.tileHeight);
        if (!this.active) {
            this.gameView = view;
            this.gameView.transitionIn();

            let layers = [
                this.gameView.children.Water_Layer,
                this.gameView.children.Ground_Layer,
                this.gameView.children.Ground_Objects_Layer,
                this.gameView.children.Ground_Objects_Layer_2,
                this.gameView.children.Depth_Sort_Layer,
                this.gameView.children.Top_Layer,
                this.gameView.children.Top_Layer_2,
                this.gameView.children.Blockers,
                this.gameView.children.Flyers,
            ];

            if (this.scrollingTilemapController) {
                this.scrollingTilemapController.setLayers(layers, LAYER_LABELS);
            } else {
                this.scrollingTilemapController = new ScrollingMapController(TileSet.ProcessedTilesets, layers, this.tileWidth, this.tileHeight, this.type, LAYER_LABELS, BLOCKING_LAYERS, TILE_BUFFERS);
            }

            this.scrollingTilemapController.setMap(this.currentMap, this.useDoubleGridForPathFinding);
            this.gameView.initWaterDistort();
            this.viewRect = new PIXI.Rectangle(app.viewPort.left, app.viewPort.top, app.viewPort.width, app.viewPort.height);

            /* call our map initing callback before we init the character and npcs, as some map changes may cause them to not be placed the same */
            if (this.mapInitialisingCallback) {
                this.mapInitialisingCallback();
            }

            this.addCharacter();
            this.initNpcs(this.inactiveNpcs);

            this.scrollingTilemapController.setZoom(AVAILABLE_ZOOMS[this.zoomLevel], this.gameView.children.mapContainer);

            this.gameView.children.mapContainer.interactive = true;
            this.gameView.children.mapContainer.on('pointerdown', this.mapClicked);
            this.gameView.children.mapContainer.on('touchstart', this.mapClicked);

            this.gameView.children.mapContainer.on('pointerup', this.mouseUpHandler);
            this.gameView.children.mapContainer.on('touchend', this.mouseUpHandler);
            this.gameView.children.mapContainer.on('pointerupoutside', this.mouseUpHandler);
            this.gameView.children.mapContainer.on('touchendoutside', this.mouseUpHandler);

            this.gameView.children.mapContainer.on('pointermove', this.mouseMovedHandler);
            this.gameView.children.mapContainer.on('touchmove', this.mouseMovedHandler);

            this.enabled = true;
            this.active = true;
            this.suspended = false;
            this.resize();

            this.hideTilesFrequencyCount = HIDE_TILES_FREQUENCY;

            if (this.mapInitialisedCallback) {
                this.mapInitialisedCallback();
            }
        }
    }

    deactivate() {
        if (this.active) {
            // this.disableControls();
            this.gameView.transitionOut();
            this.active = false;
            this.enabled = false;
            this.suspended = true;
        }
    }

    removeMap = () => {
        if (this.mapAboutToRemoveCallback) {
            this.mapAboutToRemoveCallback();
        }

        this.headingToDoor = false;
        this.doorLeadsTo = null;
        this.exitDir = null;
        this.entryPoint = null;
        this.targetSquareSprites = null;

        this.nextMap = null;
        this.lastMap = this.currentMap;

        this.lastMapExitGridX = this.mainCharacterEntity.characterGridX;
        this.lastMapExitGridY = this.mainCharacterEntity.characterGridY;

        this.gameView.removeWaterDistort();
        this.mainCharacterEntity.removeFromPlay();
        this.mainCharacterEntity = null;
        this.scrollingTilemapController.clearCurrentMap();
    }

    // --- Disable / enable interaction and updates
    disable() {
        this.enabled = false;
    }

    enable() {
        this.enabled = true;
    }

    resize() {
        if (this.active) {
            this.viewRect = new PIXI.Rectangle(app.viewPort.left, app.viewPort.top, app.viewPort.width, app.viewPort.height);
            if (this.gameView && this.gameView.children && this.gameView.children.mapMask) {
                this.gameView.children.mapMask.x = this.viewRect.x;
                this.gameView.children.mapMask.y = this.viewRect.y;
                this.gameView.children.mapMask.width = this.viewRect.width;
                this.gameView.children.mapMask.height = this.viewRect.height;
            }
            if (this.scrollingTilemapController) {
                if (this.mainCharacterEntity) {
                    this.scrollingTilemapController.focusOnSprite(this.mainCharacterEntity.characterSprite, this.viewRect, this.gameView.children.mapContainer);
                }
                this.scrollingTilemapController.hideOffScreenTiles(this.viewRect, this.gameView.children.mapContainer);
                this.scrollingTilemapController.hideOffScreenEntities(this.viewRect, this.gameView.children.mapContainer);
            }
            if (this.gameView) {
                this.gameView.hideShowWater();
            }
        }
    }

    update(delta) {
        // I'm going to carry on updating in even disabled and only apply that to clicks as
        // too many things carry on going due to tweens, so may as well allow the world to carry on.
        // if (this.enabled) {
            let frameDeltaRatio = 1;
            frameDeltaRatio = delta / FRAME_TIME;
            if (frameDeltaRatio > FRAME_DELTA_LIMIT) {
                frameDeltaRatio = FRAME_DELTA_LIMIT;
            }

            this.scrollingTilemapController.updateEntities(delta);
            this.scrollingTilemapController.tileMapEngine.updateFloatingSprites(frameDeltaRatio);
            this.scrollingTilemapController.tileMapEngine.renderer.depthSortLayer(DEPTH_SORT_LAYER);
            this.gameView.updateWaterDistortion(frameDeltaRatio);
            if (this.zoomEnabled && this.scrollingTilemapController.zoom !== AVAILABLE_ZOOMS[this.zoomLevel]) {
                this.scrollingTilemapController.smoothZoom(AVAILABLE_ZOOMS[this.zoomLevel], this.gameView.children.mapContainer);
                this.scrollingTilemapController.focusOnSprite(this.mainCharacterEntity.characterSprite, this.viewRect, this.gameView.children.mapContainer);
            } else {
                this.scrollingTilemapController.smoothFocusOnSprite(this.mainCharacterEntity.characterSprite, this.viewRect, this.gameView.children.mapContainer, 0.2);
            }

            this.hideTilesFrequencyCount++;
            if (this.hideTilesFrequencyCount >= HIDE_TILES_FREQUENCY) {
                this.scrollingTilemapController.hideOffScreenTiles(this.viewRect, this.gameView.children.mapContainer, VIEW_EDGE_BUFFER);
                this.scrollingTilemapController.hideOffScreenEntities(this.viewRect, this.gameView.children.mapContainer, VIEW_EDGE_BUFFER);
                this.hideTilesFrequencyCount = 0;
            }

            if (this.updateCallback) {
                this.updateCallback(delta, frameDeltaRatio);
            }

            this.mapTappedThisFrame = false;
        // }
    }

    zoomOut = () => {
        if (this.zoomEnabled && this.zoomLevel < AVAILABLE_ZOOMS.length - 1) {
            this.zoomLevel++;
        }
    }

    zoomIn = () => {
        if (this.zoomEnabled && this.zoomLevel > 0) {
            this.zoomLevel--;
        }
    }

    mapTappedThisFrame = false;
    newPath = false;
    lastClickGlobal = null;
    lastClickPixelPos = null;
    lastClickedGridSquare = null;
    mouseDown = false;
    mapClicked = (e) => {
        /*
        if (this.gameView.uiOpen) {
            this.gameView.hideTextPop();
            return;
        }
        */
       // divert clicks when we are disabled
        if (!this.enabled && this.clickCallback) {
            this.mouseDown = false;
            this.clickCallback(e);
        } else
        if (this.enabled /*&& this.viewRect.contains(e.data.global.x, e.data.global.y)*/) {
            if (!this.mapTappedThisFrame && !this.transitioningMap) {
                this.mouseDown = true;
                this.mapTappedThisFrame = true;

                let localPos = this.gameView.children.mapContainer.toLocal(e.data.global);
                this.lastClickGlobal = {x: e.data.global.x, y: e.data.global.y};
                this.lastClickPixelPos = localPos;

                /*
                let tilesClicked = this.scrollingTilemapController.tileMapEngine.getTileSpritesAtPixelPos(localPos.x, localPos.y, true);
                // yOffset is used if we click on a door, so we can make sure we move in front of it
                let yOffset = 0;
                this.headingToDoor = false;

                // find the clicked tile that is closest to our click position (according to the sprite's baseX, baseY)
                // downside is we need a ground tile on every square we want to be able to click on, but this works nicely for
                // orthagonal and isometric maps
                let closestSprite = null;
                let dist = Infinity;
                let halfTileWidth = this.scrollingTilemapController.tileMapEngine.renderer.tileWidth / 2;
                let halfTileHeight = this.scrollingTilemapController.tileMapEngine.renderer.tileHeight / 2;
                for (let i = 0; i < tilesClicked.length; i++) {
                    if (Math.abs(tilesClicked[i].baseTileX - localPos.x) <= halfTileWidth + 2 && Math.abs(tilesClicked[i].baseTileY - localPos.y) <= halfTileHeight + 2) {
                        let distance = Math.sqrt(((tilesClicked[i].baseTileX - localPos.x) ** 2) + ((tilesClicked[i].baseTileY - localPos.y) ** 2));
                        if (distance < dist) {
                            closestSprite = tilesClicked[i];
                            dist = distance;
                        }
                    }
                }
                if (closestSprite === null) {
                    return;
                }
                let gridPos = { x: closestSprite.gridX, y: closestSprite.gridY };
                */

                let gridPos = this.lastClickedGridSquare = this.getClickedGridSquare(localPos);
                if (gridPos) {
                    this.moveMainCharacterTo(gridPos);
                }

                /*
                // yOffset is used if we click on a door, so we can make sure we move in front of it
                let yOffset = 0;
                this.headingToDoor = false;

                let targetGridSquare = [gridPos.x, gridPos.y];
                this.targetSquareSprites = tilesClicked;
                for (let i = 0; i < tilesClicked.length; i++) {
                    // we are only interested in doors that are on the exact grid square we clicked
                    let spGridX = this.targetSquareSprites[i].gridX;
                    let spGridY = this.targetSquareSprites[i].gridY;
                    if (spGridX === targetGridSquare[0] && spGridY === targetGridSquare[1]) {
                        if (tilesClicked[i].config.inaccessible === true) {
                            // We don't accept clicks on squares tagged as "inaccessible" to save the costly process of finding a path
                            // that will end up searching the whole map for a route and can cause freezes.
                            return;
                        }

                        if ((typeof tilesClicked[i].config.door !== 'undefined' && tilesClicked[i].config.door === true) || (tilesClicked[i].localConfig && typeof tilesClicked[i].localConfig.door !== 'undefined' && tilesClicked[i].localConfig.door === true) || (typeof tilesClicked[i].config.exit !== 'undefined' && tilesClicked[i].config.exit === true) || (tilesClicked[i].localConfig && typeof tilesClicked[i].localConfig.exit !== 'undefined' && tilesClicked[i].localConfig.exit === true)) {
                            if ((typeof tilesClicked[i].config.door !== 'undefined' && tilesClicked[i].config.door === true) || (tilesClicked[i].localConfig && typeof tilesClicked[i].localConfig.door !== 'undefined' && tilesClicked[i].localConfig.door === true)) {
                                yOffset = 1;
                            }
                            if (tilesClicked[i].localConfig) {
                                if (tilesClicked[i].localConfig.door_to) {
                                    this.doorLeadsTo = tilesClicked[i].localConfig.door_to;
                                    this.headingToDoor = true;
                                } else
                                    if (tilesClicked[i].localConfig.exit_to) {
                                        this.doorLeadsTo = tilesClicked[i].localConfig.exit_to;
                                        this.headingToDoor = true;
                                    }
                                if (tilesClicked[i].localConfig.target_entry_point) {
                                    this.entryPoint = tilesClicked[i].localConfig.target_entry_point;
                                    this.headingToDoor = true;
                                } else {
                                    this.entryPoint = null;
                                }
                                this.exitDir = [0, 0];
                                if (tilesClicked[i].config.exit_dir) {
                                    this.exitDir = tilesClicked[i].config.exit_dir;
                                }
                                if (tilesClicked[i].localConfig.exit_dir) {
                                    this.exitDir = tilesClicked[i].localConfig.exit_dir;
                                }
                            }
                        }
                    }
                }

                this.interactionEnded();
                // have we clicked on an entity?
                let entitiesClicked = this.scrollingTilemapController.getEntitiesAtPixel(localPos.x, localPos.y, [this.mainCharacterEntity]);
                if (entitiesClicked.length > 0) {
                    for (let i = 0; i < entitiesClicked.length; i++) {
                        if ( (this.allowGenericNpcInteractions && entitiesClicked[i].allowInteraction) || entitiesClicked[i].storyCharacter) {
                            this.entityToInteractWith = entitiesClicked[i];
                            entitiesClicked[i].waitForInteraction();
                            this.mainCharacterEntity.approachingEntity = entitiesClicked[i];
                            break;
                        }
                    }
                }
                console.log('Entities clicked: ', entitiesClicked, this.entityToInteractWith);

                // over-rule any pauses!
                this.mainCharacterEntity.movementPaused = false;
                this.mainCharacterEntity.moveToGridPos(gridPos.x, gridPos.y + yOffset);
                */
            }
        }
    }

    mouseUpHandler = (e) => {
        this.mouseDown = false;

        // have we clicked on an entity?
        if (this.enabled && !this.transitioningMap && this.lastClickPixelPos) {
            let entitiesClicked = this.scrollingTilemapController.getEntitiesAtPixel(this.lastClickPixelPos.x, this.lastClickPixelPos.y, [this.mainCharacterEntity]);
            if (entitiesClicked.length > 0) {
                for (let i = 0; i < entitiesClicked.length; i++) {
                    if ( (this.allowGenericNpcInteractions && entitiesClicked[i].allowInteraction) || entitiesClicked[i].storyCharacter) {
                        this.entityToInteractWith = entitiesClicked[i];
                        entitiesClicked[i].waitForInteraction();
                        this.mainCharacterEntity.approachingEntity = entitiesClicked[i];
                        break;
                    }
                }
            }
        }
    }

    mouseMovedHandler = (e) => {
        if (this.enabled && this.allowLongTapMovement && this.mouseDown && !this.transitioningMap /*&& this.viewRect.contains(e.global.x, e.global.y)*/) {
            // console.log('Mouse moved: ', e);
            // let localPos = this.gameView.children.mapContainer.toLocal(e.data.global);
            this.lastClickGlobal = {x: e.data.global.x, y: e.data.global.y};
            // this.lastClickPixelPos = localPos;
            if (this.mainCharacterEntity.characterGridX === this.mainCharacterEntity.characterDestinationGridX && this.mainCharacterEntity.characterGridY === this.mainCharacterEntity.characterDestinationGridY) {
                this.entityReachedPathNode(this.mainCharacterEntity);
            }
        } else {
            this.mouseDown = false;
        }
    }

    getClickedGridSquare = (localPos) => {
        let tilesClicked = this.scrollingTilemapController.tileMapEngine.getTileSpritesAtPixelPos(localPos.x, localPos.y, true);

        // find the clicked tile that is closest to our click position (according to the sprite's baseX, baseY)
        // downside is we need a ground tile on every square we want to be able to click on, but this works nicely for
        // orthagonal and isometric maps
        let closestSprite = null;
        let dist = Infinity;
        let halfTileWidth = this.scrollingTilemapController.tileMapEngine.renderer.tileWidth / 2;
        let halfTileHeight = this.scrollingTilemapController.tileMapEngine.renderer.tileHeight / 2;
        for (let i = 0; i < tilesClicked.length; i++) {
            if (Math.abs(tilesClicked[i].baseTileX - localPos.x) <= halfTileWidth + 2 && Math.abs(tilesClicked[i].baseTileY - localPos.y) <= halfTileHeight + 2) {
                let distance = Math.sqrt(((tilesClicked[i].baseTileX - localPos.x) ** 2) + ((tilesClicked[i].baseTileY - localPos.y) ** 2));
                if (distance < dist) {
                    closestSprite = tilesClicked[i];
                    dist = distance;
                }
            }
        }
        if (closestSprite === null) {
            // console.log('No tiles clicked!');
            return null;
        }
        let gridPos = { x: closestSprite.gridX, y: closestSprite.gridY, tilesClicked: tilesClicked };

        return gridPos;
    }

    moveMainCharacterTo(gridPos) {
        // yOffset is used if we click on a door, so we can make sure we move in front of it
        let yOffset = 0;
        this.headingToDoor = false;

        let targetGridSquare = [gridPos.x, gridPos.y];
        let tilesClicked = gridPos.tilesClicked;
        this.targetSquareSprites = tilesClicked;
        for (let i = 0; i < tilesClicked.length; i++) {
            // we are only interested in doors that are on the exact grid square we clicked
            let spGridX = this.targetSquareSprites[i].gridX;
            let spGridY = this.targetSquareSprites[i].gridY;
            if (spGridX === targetGridSquare[0] && spGridY === targetGridSquare[1]) {
                if (tilesClicked[i].config.inaccessible === true) {
                    // We don't accept clicks on squares tagged as "inaccessible" to save the costly process of finding a path
                    // that will end up searching the whole map for a route and can cause freezes.
                    return;
                }
            }
        }
        yOffset = this.checkForMapExits(tilesClicked, targetGridSquare[0], targetGridSquare[1]);

        this.interactionEnded();
        
        // if we are only moving one grid square...
        // have we clicked on an entity? This will prevent us entering the same grid square!
        if (Math.abs(this.mainCharacterEntity.characterGridX - gridPos.x) <= 1 && Math.abs(this.mainCharacterEntity.characterGridX - gridPos.x) <= 1) {
            let entitiesClicked = this.scrollingTilemapController.getEntitiesAtPixel(this.lastClickPixelPos.x, this.lastClickPixelPos.y, [this.mainCharacterEntity]);
            if (entitiesClicked.length > 0) {
                for (let i = 0; i < entitiesClicked.length; i++) {
                    if ((this.allowGenericNpcInteractions && entitiesClicked[i].allowInteraction) || entitiesClicked[i].storyCharacter) {
                        this.entityToInteractWith = entitiesClicked[i];
                        entitiesClicked[i].waitForInteraction();
                        this.mainCharacterEntity.approachingEntity = entitiesClicked[i];
                        break;
                    }
                }
            }
        }
        // console.log('Entities clicked: ', entitiesClicked, this.entityToInteractWith);

        // over-rule any pauses!
        this.mainCharacterEntity.movementPaused = false;
        this.mainCharacterEntity.moveToGridPos(gridPos.x, gridPos.y + yOffset);
    }

    checkForMapExits = (tilesArray, gridX, gridY) => {
        let yOffset = 0;
        if (this.canLeaveMap) {
            for (let i = 0; i < tilesArray.length; i++) {
                let spGridX = tilesArray[i].gridX;
                let spGridY = tilesArray[i].gridY;
                if (spGridX === gridX && spGridY === gridY) {
                    if ((typeof tilesArray[i].config.door !== 'undefined' && tilesArray[i].config.door === true) || (tilesArray[i].localConfig && typeof tilesArray[i].localConfig.door !== 'undefined' && tilesArray[i].localConfig.door === true) || (typeof tilesArray[i].config.exit !== 'undefined' && tilesArray[i].config.exit === true) || (tilesArray[i].localConfig && typeof tilesArray[i].localConfig.exit !== 'undefined' && tilesArray[i].localConfig.exit === true)) {
                        if ((typeof tilesArray[i].config.door !== 'undefined' && tilesArray[i].config.door === true) || (tilesArray[i].localConfig && typeof tilesArray[i].localConfig.door !== 'undefined' && tilesArray[i].localConfig.door === true)) {
                            yOffset = 1;
                        }
                        if (tilesArray[i].localConfig) {
                            if (tilesArray[i].localConfig.door_to) {
                                this.doorLeadsTo = tilesArray[i].localConfig.door_to;
                                this.headingToDoor = true;
                            } else
                                if (tilesArray[i].localConfig.exit_to) {
                                    this.doorLeadsTo = tilesArray[i].localConfig.exit_to;
                                    this.headingToDoor = true;
                                }
                            if (tilesArray[i].localConfig.target_entry_point) {
                                this.entryPoint = tilesArray[i].localConfig.target_entry_point;
                                this.headingToDoor = true;
                            } else {
                                this.entryPoint = null;
                            }
                            this.exitDir = [0, 0];
                            if (tilesArray[i].config.exit_dir) {
                                this.exitDir = tilesArray[i].config.exit_dir;
                            }
                            if (tilesArray[i].localConfig.exit_dir) {
                                this.exitDir = tilesArray[i].localConfig.exit_dir;
                            }
                        }
                    }
                }
            }
        }
        return yOffset;
    }

    addCharacter = () => {
        if (this.mainCharacterEntity === null) {
            this.mainCharacterEntity = new PathFindingEntity(this.maincharacterSpriteData || CHARACTERS.MAIN_CHARACTER, this.scrollingTilemapController);
            this.mainCharacterEntity.alwaysDoPathSearches = true;
            this.mainCharacterEntity.pauseTimeWhenBlocked = 0;
            this.mainCharacterEntity.stopMovingWhenBlocked = true;
            this.mainCharacterEntity.ignoreEntityBlocking = true;
            this.mainCharacterEntity.allowDiagonalMovement = this.allowDiagonalMovement;
            // if (this.allowLongTapMovement) {
                this.mainCharacterEntity.pathNodeReachedCallback = this.entityReachedPathNode;
            // }
            this.mainCharacterEntity.npcId = 'main_character';
        }
        let startSquare = this.findStartSquare();
        // add one to offset so we always go in front of other characters on the same square
        let yOffset = this.maincharacterSpriteData.Y_OFFSET || CHARACTER_Y_OFFSET;
        let xOffset = this.maincharacterSpriteData.X_OFFSET || 0;
        this.mainCharacterEntity.addToGame(startSquare, this.gameView.children.Depth_Sort_Layer, xOffset, yOffset);
        this.mainCharacterEntity.reachedDestinationCallback = this.entityReachedDestination;
        this.scrollingTilemapController.tileMapEngine.renderer.depthSortLayer(4);
        this.scrollingTilemapController.focusOnSprite(this.mainCharacterEntity.characterSprite, this.viewRect, this.gameView.children.mapContainer);
        this.scrollingTilemapController.hideOffScreenTiles(this.viewRect, this.gameView.children.mapContainer);

        console.log('Main character: ', this.mainCharacterEntity);

        // test follower entity
        /*
        let newFollower = new CharacterFollowerNPCEntity(CHARACTERS.KLOOS, this.scrollingTilemapController);
        newFollower.addToGame(startSquare, this.gameView.children.Depth_Sort_Layer, 0, CHARACTER_Y_OFFSET);
        newFollower.characterToFollow = this.mainCharacterEntity;
        newFollower.randomiseDestGridPos = true;
        newFollower.playAnim();
        */
    }

    findStartSquare = () => {
        let specialSquares = this.scrollingTilemapController.tileMapEngine.getAllSpecialSprites();
        // entryPoint takes precedent - this is in localConfig, but all tiles with localConfig are automatically treated as specialSprites
        if (typeof this.entryPoint === 'string') {
            let entrancePoints = [];
            for (let i = 0; i < specialSquares.length; i++) {
                if (specialSquares[i].localConfig && specialSquares[i].localConfig.entry_point === this.entryPoint) {
                    entrancePoints.push(specialSquares[i]);
                }
            }
            if (entrancePoints.length > 0) {
                entrancePoints.sort(() => Math.random() - 0.5);
                return { x: entrancePoints[0].tileData.gridX, y: entrancePoints[0].tileData.gridY, tile: entrancePoints[0] };
            }
        }
        // last exit gridX / gridY next (works nicely for exiting single entrance houses)
        if (this.currentMap === this.lastMap && this.lastMapExitGridX !== null && this.lastMapExitGridY !== null) {
            return { x: this.lastMapExitGridX, y: this.lastMapExitGridY, tile: null };
        }
        // entrance tiles next (works nicely for entering single entrance houses)
        let entranceSquares = [];
        for (let i = 0; i < specialSquares.length; i++) {
            if (specialSquares[i].config && specialSquares[i].config.entrance === true) {
                entranceSquares.push(specialSquares[i]);
            }
        }
        if (entranceSquares.length > 0) {
            entranceSquares.sort(() => Math.random() - 0.5);
            return { x: entranceSquares[0].tileData.gridX, y: entranceSquares[0].tileData.gridY, tile: entranceSquares[0] };
        }
        // test for start squares - this is in localConfig, but all tiles with localConfig are automatically treated as specialSprites
        // used for the very start of the game
        for (let i = 0; i < specialSquares.length; i++) {
            if ((specialSquares[i].localConfig && specialSquares[i].localConfig.start === true) || (specialSquares[i].config && specialSquares[i].config.start === true)) {
                // console.log('found start: ', specialSquares[i]);
                return { x: specialSquares[i].tileData.gridX, y: specialSquares[i].tileData.gridY, tile: specialSquares[i] };
            }
        }
        return { x: 0, y: 0, tile: null };
    }

    initNpcs(npcIngoreList = []) {
        let npcs = this.scrollingTilemapController.tileMapEngine.getSpecialSpritesWithProperty('npc');
        let initedNpcs = [];
        for (let i = 0; i < npcs.length; i++) {
            if (npcs[i].config.npc === true && (
                (npcs[i].localConfig && typeof npcs[i].localConfig.npc_id === 'string' && npcIngoreList.indexOf(npcs[i].localConfig.npc_id) === -1)
                || (npcs[i].config && typeof npcs[i].config.npc_id === 'string' && npcIngoreList.indexOf(npcs[i].config.npc_id) === -1)
            ) ) {
                let newNpc = null; 
                let characterData = null; // CHARACTERS.KLOOS;
                if (CHARACTERS[npcs[i].config.characterDataId]) {
                    characterData = CHARACTERS[npcs[i].config.characterDataId];
                }
                if (npcs[i].localConfig && CHARACTERS[npcs[i].localConfig.characterDataId]) {
                    characterData = CHARACTERS[npcs[i].localConfig.characterDataId];
                }
                let nonStandardEntity = false;
                if (npcs[i].config.entityClass || npcs[i].localConfig.entityClass) {
                    let entityClassId = npcs[i].config.entityClass || npcs[i].localConfig.entityClass;
                    // console.log('Looking for entity class: ', entityClassId);
                    if (this.nonStandardEntityClasses[entityClassId]) {
                        newNpc = new this.nonStandardEntityClasses[entityClassId](characterData, this.scrollingTilemapController);
                        nonStandardEntity = true;
                        // console.log('Adding non-standard entity: ', newNpc);
                    }
                }
                if (!nonStandardEntity) {
                    if (npcs[i].config.staticNpc || npcs[i].localConfig.staticNpc) {
                        newNpc = new InGameEntity(characterData, this.scrollingTilemapController);
                    } else
                    if (npcs[i].config.followCharacterToDest || npcs[i].localConfig.followCharacterToDest) {
                        newNpc = new LeadToDestinationNPCEntity(characterData, this.scrollingTilemapController);
                        newNpc.characterIdToFollow = npcs[i].localConfig.characterIdToFollow || npcs[i].config.characterIdToFollow;
                    } else
                    if (npcs[i].config.followCharacter || npcs[i].localConfig.followCharacter) {
                        newNpc = new CharacterFollowerNPCEntity(characterData, this.scrollingTilemapController);
                        newNpc.characterIdToFollow = npcs[i].localConfig.characterIdToFollow || npcs[i].config.characterIdToFollow;
                    } else {
                        newNpc = new WayPointFollwerNPCEntity(characterData, this.scrollingTilemapController);
                    }
                }
                newNpc.npc = true;
                newNpc.pauseWhenOffScreen = this.pauseOffScreenEntities;
                newNpc.allowDiagonalMovement = this.allowDiagonalMovement;
                newNpc.pausedAtPathNodeCallback = this.entityPausedAtPathNode;
                newNpc.leftSceneCallback = this.entityLeftScene;

                let startTiles = this.scrollingTilemapController.tileMapEngine.getSurroundingTilesFromPixelPos(npcs[i].x, npcs[i].y, 0, 0);
                let startTile = null;
                for (let j = 0; j < startTiles.length; j++) {
                    if (startTiles[j] !== npcs[i]) {
                        startTile = startTiles[j];
                        break;
                    }
                }

                let container = this.gameView.children.Depth_Sort_Layer;
                let renderer = this.scrollingTilemapController.tileMapEngine.renderer;
                let renderLayer = -1;
                if (typeof npcs[i].config.npc_render_layer === 'number') {
                    renderLayer = npcs[i].config.npc_render_layer;
                    container = renderer.layerContainers[renderLayer] || this.gameView.children.Depth_Sort_Layer;
                }
                if (typeof npcs[i].config.npc_render_layer === 'string') {
                    if (renderer.layerLabels.length > 0) {
                        if (typeof renderer.layerLabels[0] === 'string') {
                            renderLayer = renderer.layerLabels.indexOf(npcs[i].config.npc_render_layer);
                        } else {
                            for (let i = 0; i < renderer.layerLabels.length; i++) {
                                renderLayer = renderer.layerLabels[i].indexOf(npcs[i].config.npc_render_layer);
                                if (renderLayer >= 0) {
                                    break;
                                }
                            }
                        }
                    }
                    // console.log('force render layer: ', tConfig.render_layer, renderLayer);
                    if (renderLayer >= 0) {
                        container = renderer.layerContainers[renderLayer] || this.gameView.children.Depth_Sort_Layer;
                    }
                }

                if (newNpc.removeOriginalTile === false) {
                    startTile = npcs[i];
                }
                let yOffset = characterData.Y_OFFSET || CHARACTER_Y_OFFSET;
                let xOffset = characterData.X_OFFSET || 0;
                newNpc.addToGame({x: npcs[i].gridX, y: npcs[i].gridY, tile: startTile}, container, xOffset, yOffset);
                newNpc.originalTile = npcs[i];
                newNpc.applySettingsConfig([npcs[i].config, npcs[i].localConfig || {}]);

                newNpc.npcId = npcs[i].config.npc_id || npcs[i].localConfig.npc_id;
                if (newNpc.wayPointFollower) {
                    newNpc.findWaypoints();
                }
                if (newNpc.leadToDestination) {
                    newNpc.findFinalDestination();
                    // newNpc.pauseAtStart = false;
                    newNpc.randomlyMove();
                }
                initedNpcs.push(newNpc);
                // could do with a better version of this!
                if (newNpc.removeOriginalTile) {
                    let mapChanges = this.scrollingTilemapController.removeTileFromMap(npcs[i], true); // this.scrollingTilemapController.makeLiveChangeToMapChunk(npcs[i].mapChunk.id, npcs[i].tileData.layer, npcs[i].gridX, npcs[i].gridY);
                    // console.log('Map changes: ', mapChanges);
                }
                /*
                for (let j = 0; j < mapChanges.oldSprites.length; j++) {
                    mapChanges.oldSprites[j].parent.removeChild(mapChanges.oldSprite);
                    mapChanges.oldSprites[j].destroy();
                }
                */
            }
        }
        for (let i = 0; i < initedNpcs.length; i++) {
            if (initedNpcs[i].characterFollower) {
                if (initedNpcs[i].characterIdToFollow) {
                    if (initedNpcs[i].characterIdToFollow === 'main_character') {
                        initedNpcs[i].characterToFollow = this.mainCharacterEntity;
                    } else {
                        for (let j = 0; j < initedNpcs.length; j++) {
                            if (initedNpcs[j].npcId === initedNpcs[i].characterIdToFollow) {
                                initedNpcs[i].characterToFollow = initedNpcs[j];
                                break;
                            }
                        }
                    }
                    initedNpcs[i].followCharacter();
                }
            } else {
                if (initedNpcs[i].wayPointFollower) {
                    initedNpcs[i].startFollowingPath();
                }
            }
            initedNpcs[i].playAnim();
        }
        console.log('Npcs: ', npcs, initedNpcs);
        if (this.npcsAddedCallback) {
            this.npcsAddedCallback(initedNpcs);
        }
    }

    activateNpcs(npcIds = []) {
        if (npcIds.length > 0) {
            let npcs = this.scrollingTilemapController.tileMapEngine.getSpecialSpritesWithProperty('npc');
            let npcIgnoreList = [];
            for (let i = 0; i < npcs.length; i++) {
                if (npcs[i].config.npc === true && npcs[i].localConfig && typeof npcs[i].localConfig.npc_id === 'string' && npcIds.indexOf(npcs[i].localConfig.npc_id) === -1) {
                    npcIgnoreList.push(npcs[i].localConfig.npc_id);
                }
            }
            this.initNpcs(npcIgnoreList);
        }
    }

    transitionToMap = (newMap) => {
        this.transitioningMap = true;
        this.mouseDown = false;
        this.nextMap = newMap;
        if (this.headingToDoor) {
            gsap.to(this.mainCharacterEntity.characterSprite, { x: this.mainCharacterEntity.characterSprite.x + 16 * this.exitDir[0], y: this.mainCharacterEntity.characterSprite.y + 16 * this.exitDir[1], duration: 0.25 });
            this.mainCharacterEntity.updateCharacterSpriteByDir(this.exitDir[0], this.exitDir[1]);
            this.mainCharacterEntity.playAnim();
        }
        // gsap.to(this.gameView.children.mapContainer, { alpha: 0, duration: 0.3, onComplete: this.switchMapNow });
        this.disable();
        v.showView('maptransition');
        setTimeout(this.switchMapNow, 500);
    }

    switchMapNow = () => {
        if (this.mapAboutToRemoveCallback) {
            this.mapAboutToRemoveCallback();
        }

        this.gameView.removeWaterDistort();
        this.scrollingTilemapController.setMap(this.nextMap, this.useDoubleGridForPathFinding);
        this.gameView.initWaterDistort();
        let mapChangingFrom = this.currentMap;
        let exitGridX = this.characterGridX;
        let exitGridY = this.characterGridY;
        this.lastTargetGridPos_PF = null;
        this.currentMap = this.nextMap;
        // this.pathFinderGrid_raw = this.scrollingTilemapController.getMapDataForPathFinding(true);

        /* call our map initing callback before we init the character and npcs, as some map changes may cause them to not be placed the same */
        if (this.mapInitialisingCallback) {
            this.mapInitialisingCallback();
        }

        this.addCharacter();
        this.initNpcs();
        // We only set lastMap after adding the character because we need to know if we are going back to the last map
        // in order to position the character correctly when exiting buildings.
        this.lastMap = mapChangingFrom;
        this.lastMapExitGridX = exitGridX;
        this.lastMapExitGridY = exitGridY;
        gsap.to(this.gameView.children.mapContainer, { alpha: 1, duration: 0.3, onComplete: this.mapTransitionDone });
        this.headingToDoor = false;
    }

    mapTransitionDone = () => {
        this.transitioningMap = false;
    }

    entityReachedDestination = (entity) => {
        if (entity === this.mainCharacterEntity) {
            // Update our target squares - we need to do this because if the square we clicked is blocked
            // the character will move into the next square and we still want to interact with the square he is in...
            if (!this.headingToDoor) {
                this.targetSquareSprites = this.scrollingTilemapController.tileMapEngine.getSurroundingTilesFromPixelPos(this.mainCharacterEntity.characterSprite.x, this.mainCharacterEntity.characterSprite.y, 0, 0, [0, 5, 6, 7, 8]);
                this.checkForMapExits(this.targetSquareSprites, this.mainCharacterEntity.characterGridX, this.mainCharacterEntity.characterGridY);
            }
            
            // We have reached our target - check if we need to react...
            if (this.headingToDoor) {
                this.transitionToMap(MapLayouts[this.doorLeadsTo]);
                return;
            } else {
                // check for house entrances
                for (let i = 0; i < this.targetSquareSprites.length; i++) {
                    if (this.targetSquareSprites[i].config && this.targetSquareSprites[i].config.entrance === true) {
                        this.headingToDoor = true;
                        this.exitDir = [0, 0];
                        if (this.targetSquareSprites[i].config.exit_dir) {
                            this.exitDir = this.targetSquareSprites[i].config.exit_dir;
                        }
                        if (this.targetSquareSprites[i].localConfig && this.targetSquareSprites[i].localConfig.exit_dir) {
                            this.exitDir = this.targetSquareSprites[i].localConfig.exit_dir;
                        }
                        this.transitionToMap(this.lastMap);
                        return;
                    }
                }
            }
            if (/*!this.mouseDown &&*/ (this.entityToInteractWith === null || this.entityToInteractWith.readyToInteract) ) {
                this.interactWithTargetObjects();
            }
            // if we get to here, we need to switch to the standing anim
            this.mainCharacterEntity.updateCharacterSpriteByDir();
        }
    }

    entityLeftScene = (entity) => {
        // console.log('Entity left the scene: ', entity);
        this.scrollingTilemapController.removeEntity(entity);
    }

    entityPausedAtPathNode = (entity) => {
        if (/*!this.mouseDown &&*/ (entity === this.entityToInteractWith && this.mainCharacterEntity.readyToInteract) ) {
            this.interactWithTargetObjects();
        }
    }

    entityReachedPathNode = (entity) => {
        // check for collectables in this square
        let tilesInSquare = this.scrollingTilemapController.tileMapEngine.getSurroundingTilesFromPixelPos(entity.characterSprite.x, entity.characterSprite.y, 1, 1); // getTileSpritesAtPixelPos(entity.characterSprite.x, entity.characterSprite.y, true);
        for (let i = 0; i < tilesInSquare.length; i++) {
            // console.log('Looking for colleactables: ', tilesInSquare, entity);
            if (tilesInSquare[i].config.collectable && !tilesInSquare.collected) {
                if (tilesInSquare[i].gridX === entity.characterGridX && tilesInSquare[i].gridY === entity.characterGridY) {
                    // console.log('Found collectable in square: ', tilesInSquare[i], entity);
                    if (this.collectableCallback) {
                        this.collectableCallback(entity, tilesInSquare[i]);
                    }
                }
            }
        }

        if (entity === this.mainCharacterEntity) {
            if (this.mouseDown && this.allowLongTapMovement) {
                // see if our destination should change
                let localPos = this.gameView.children.mapContainer.toLocal(this.lastClickGlobal);
                let gridPos = this.getClickedGridSquare(localPos);
                if (gridPos && (gridPos.x !== this.lastClickedGridSquare.x || gridPos.y !== this.lastClickPixelPos.y)) {
                    for (let i = 0; i < gridPos.tilesClicked.length; i++) {
                        // ignore blocked squares
                        if (gridPos.tilesClicked[i].gridX === gridPos.x && gridPos.tilesClicked[i].gridY === gridPos.y &&
                            (gridPos.tilesClicked[i].config.inaccessible === true
                                || (gridPos.tilesClicked[i].config.blocker && !gridPos.tilesClicked[i].config.blocker_grid
                                        && (!gridPos.tilesClicked.localConfig || !gridPos.tilesClicked.localConfig.blocker_grid)
                                )
                            )
                        ) {
                            return;
                        }
                    }
                    this.lastClickPixelPos = localPos;
                    this.lastClickedGridSquare = gridPos;
                    this.moveMainCharacterTo(gridPos);
                }
            }
        }
    }

    interactWithTargetObjects() {
        // let targetTiles = this.scrollingTilemapController.tileMapEngine.getTileSpritesAtPixelPos(this.lastClickPixelPos.x, this.lastClickPixelPos.y, true);
        let targetTiles = this.scrollingTilemapController.tileMapEngine.getSurroundingTilesFromPixelPos(this.mainCharacterEntity.characterSprite.x, this.mainCharacterEntity.characterSprite.y, 0, 0, [0, 5, 6, 7]);
        // console.log('Interact with (tiles, entity): ', targetTiles, this.entityToInteractWith);
        if (this.entityToInteractWith) {
            // make our characters face each other?
            // this.entityToInteractWith.updateCharacterSprite();
            let xDiff = this.entityToInteractWith.characterSprite.x - this.mainCharacterEntity.characterSprite.x;
            let yDiff = this.entityToInteractWith.characterSprite.y - this.mainCharacterEntity.characterSprite.y;
            this.mainCharacterEntity.lastDirX = xDiff === 0 ? 0 : xDiff > 0 ? 1 : -1;
            this.mainCharacterEntity.lastDirY = yDiff === 0 ? 0 : yDiff > 0 ? 1 : -1;
            this.mainCharacterEntity.updateCharacterSprite();
            this.entityToInteractWith.lastDirX = xDiff === 0 ? 0 : xDiff < 0 ? 1 : -1;
            this.entityToInteractWith.lastDirY = yDiff === 0 ? 0 : yDiff < 0 ? 1 : -1;
            this.entityToInteractWith.updateCharacterSprite();
        }
        if (this.interactCallback) {
            this.interactCallback(targetTiles, this.entityToInteractWith);
        }
        /*
        // very basic implementation for now, but will support more actions and quest-specific changes / map updates etc.
        let clickGridX = Math.floor(this.lastClickPixelPos.x / this.scrollingTilemapController.tileMapEngine.renderer.tileWidth);
        let clickGridY = Math.floor(this.lastClickPixelPos.y / this.scrollingTilemapController.tileMapEngine.renderer.tileHeight);
        for (let i = 0; i < this.targetSquareSprites.length; i++) {
            let spGridX = Math.floor(this.targetSquareSprites[i].x / this.scrollingTilemapController.tileMapEngine.renderer.tileWidth);
            let spGridY = Math.floor(this.targetSquareSprites[i].y / this.scrollingTilemapController.tileMapEngine.renderer.tileHeight);
            if (spGridX === clickGridX && spGridY === clickGridY) {
                if (this.targetSquareSprites[i].localConfig && this.targetSquareSprites[i].localConfig.action === 'inspect' && (this.targetSquareSprites[i].localConfig.description)) {
                    this.gameView.showTextPop(this.targetSquareSprites[i].localConfig.description);
                    return;
                }
                if (this.targetSquareSprites[i].config && this.targetSquareSprites[i].config.action === 'inspect' && (this.targetSquareSprites[i].config.description)) {
                    this.gameView.showTextPop(this.targetSquareSprites[i].config.description);
                    return;
                }
            }
        }
        */
    }

    interactionEnded = (fullyComplete = false) => {
        if (this.entityToInteractWith !== null) {
            if (fullyComplete) {
                this.entityToInteractWith.interactionComepleted();
            }
            this.entityToInteractWith.endInteraction();
            this.entityToInteractWith = null;
            this.mainCharacterEntity.approachingEntity = null;
        }
    }
}

const WORLD_CONTROLLER = new WorldController();
export { WORLD_CONTROLLER as default }