import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import game from '../../../controller/GameController';
import { au, v } from '../../../koko-framework/shorthand';
import WorldController from "../../../controller/WorldController";

import CoffeShopIcon from '../../../assets/babcock_menus/mini_game_flow/coffee_shop/building_icon.png';
import StarLocked from '../../../assets/babcock_menus/mini_game_flow/star_locked.png';
import StarUnlocked from '../../../assets/babcock_menus/mini_game_flow/star_Unlocked.png';
import CoffeeUnlocked from '../../../assets/babcock_menus/mini_game_flow/coffee_shop/coffee_Unlocked.png';
import CVLocked from '../../../assets/babcock_menus/mini_game_flow/CV_locked.png';
import CVUnlocked from '../../../assets/babcock_menus/mini_game_flow/CV_Unlocked.png';
import AttributeLocked from '../../../assets/babcock_menus/mini_game_flow/trophy_locked.png';
import AttributeUnlocked from '../../../assets/babcock_menus/mini_game_flow/trophyIcon.png';
import PlayAgainButton from '../../../assets/babcock_menus/mini_game_flow/playagain_btn.png';
import ReturnToMapButton from '../../../assets/babcock_menus/mini_game_flow/returntomap_btn.png';

import * as BABCOCK_CONSTANTS from '../../model/constants';
import { GameState, IsItemGot } from "../../model/GameState";
import BabcockController from "../../controller/BabcockController";

export default class GameEndPanel extends ReactFullScreenView {
    constructor(props) {
        super(props);
        this.state.locationData = BABCOCK_CONSTANTS.getLocationDataFromId(BabcockController.lastInteraction);
    }

    componentDidMount() {
        super.componentDidMount();

        v.get('worldview').container.visible = true;
    }

    closePanel = () => {
        v.hideView('gamerewardbg');
        v.hideView('endofgame');
        v.hideView('pausepanel');
        WorldController.enable();
        BabcockController.showAfterGameDialog();

        au.play('closebtn', 1, false, 0, false);
    }

    playAgain = () => {
        v.hideView('gamerewardbg');
        v.hideView('endofgame');
        v.showView('startgame');

        au.play('primarybtn', 1, false, 0, false);
    }

    renderContent() {
        let locationData = this.state.locationData;
        return (
            <div className="full-screen-holder gradient-bg no-select default-cursor">
                <div className="view-row game-end-top-holder rel">
                    <img className="game-end-top-icon" src={locationData.gameEndBuildingIcon} alt={locationData.name + '. ' + (GameState.gameResults[locationData.game.id] > 0 ? 'Great Job!' : 'Please try harder!') + '. ' + GameState.gameResults[locationData.game.id] +' Stars Awarded!'} />
                </div>
                <div className="view-row shrink">
                    <p className="white-header bold-text">{GameState.gameResults[locationData.game.id] > 0 ? 'Great Job!' : 'Better luck next time!'}</p>
                </div>
                {GameState.gameResults[locationData.game.id] > 0 &&
                <div className="view-row shrink center">
                    <p className="white-sub-heading">{locationData.game.gameEndDescription}</p>
                </div>
                }
                <div className="view-row grow"></div>
                <div className="inventory-items-container centered">
                    <img className="pre-game-star" src={GameState.gameResults[locationData.game.id] > 0 ? StarUnlocked : StarLocked} alt={GameState.gameResults[locationData.game.id] > 0 ? 'Star Unlocked' : 'Star Locked'} />
                    <img className="pre-game-star" src={GameState.gameResults[locationData.game.id] > 1 ? StarUnlocked : StarLocked} alt={GameState.gameResults[locationData.game.id] > 1 ? 'Star Unlocked' : 'Star Locked'} />
                    <img className="pre-game-star" src={GameState.gameResults[locationData.game.id] > 2 ? StarUnlocked : StarLocked} alt={GameState.gameResults[locationData.game.id] > 2 ? 'Star Unlocked' : 'Star Locked'} />
                    <p className="prev-best-text-end">Score: {GameState.gameLastScores[locationData.game.id]} {locationData.game.scoreUnits}</p>
                    {GameState.gamePreviousBests[locationData.game.id] > 0 &&
                        <p className="prev-best-text-end">Previous best: {GameState.gamePreviousBests[locationData.game.id]} {locationData.game.scoreUnits}</p>
                    }
                    {GameState.gamePreviousBests[locationData.game.id] === 0 &&
                        <p className="prev-best-text-end">No previous best yet</p>
                    }
                </div>
                <div className="view-row grow"></div>
                <div className="inventory-items-container centered">
                    <p className="rewards-unlocked-text-end">Rewards Unlocked</p>
                    <img className="pre-game-unlocks" src={GameState.gameResults[locationData.game.id] > 0 ? CVUnlocked : CVLocked} alt={GameState.gameResults[locationData.game.id] > 0 ? 'Reference added to CV' : 'Reference not yet added to CV'} />
                    <img className="pre-game-unlocks" src={GameState.gameResults[locationData.game.id] > 1 ? locationData.game.itemEarned.gamePanelsItemUnlocked : locationData.game.itemEarned.gamePanelsItemLocked} alt={GameState.gameResults[locationData.game.id] > 1 ? locationData.game.itemEarned.description + ' Awarded' : 'No item awarded'} />
                    <img className="pre-game-unlocks" src={GameState.gameResults[locationData.game.id] > 2 ? AttributeUnlocked : AttributeLocked} alt={GameState.gameResults[locationData.game.id] > 2 ? '3 Star Trophy Awarded' : 'Trophy Not Awarded'} />
                </div>
                <div className="view-row grow"></div>
                {(GameState.starsBeforeLastGame > 0 || GameState.starsEarnedLastGame === 0) &&
                <div className="view-row shrink centered">
                    <img className="game-end-play-again-button active-button" src={PlayAgainButton} onClick={this.playAgain} alt="Click to play again" />
                </div>
                }
                <div className="view-row shrink centered">
                    <img className="game-end-map-button active-button" src={ReturnToMapButton} onClick={this.closePanel} alt="Click to return to the map" />
                </div>
                <div className="view-row grow"></div>
            </div>
        );
    }
}