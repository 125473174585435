import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import game from '../../../controller/GameController';
import { v } from '../../../koko-framework/shorthand';
import app from '../../../koko-framework/app';

import AdvisorIcon from '../../../assets/babcock_menus/in_game/dialog/advisor_dialogue.png';
import DialogArrow from '../../../assets/babcock_menus/in_game/dialog/dialogue_scroll.png';
import TimerBg from '../../../assets/babcock_menus/timer_HUD.png';

import PositionableReactView from "../../../koko-framework/ui/react-views/PositionableReactView";
import { PANEL_STATE } from "../../../Bootstrap";
import WorldController from "../../../controller/WorldController";
import { getDialogDataFromId, getEntityDataFromId, NEIGHBOUR_NPC } from "../../model/constants";
import { GameState } from "../../model/GameState";
import BabcockController from "../../controller/BabcockController";

export default class HudTimer extends PositionableReactView {
    constructor(props) {
        super(props);

        // Fix this panel to the bottom middle. Our css class bottom-panels-bg positions the panel relative to that.
        this.state.left = 20;
        this.state.top = 140;
        let mins = this.state.mins = Math.floor(GameState.taskCountdown / 60);
        this.state.secs = Math.floor(GameState.taskCountdown - (mins * 60));
    }

    closePanel = () => {
        v.hideView(this.props.viewId);
        WorldController.enable();
    }

    updateTimer = () => {
        let mins = Math.floor(GameState.taskCountdown / 60);
        let secs = Math.floor(GameState.taskCountdown - (mins * 60));
        this.setState({
            mins: mins,
            secs: secs,
        });
    }

    renderContent() {
        return (
            <div className="hud-timer-panel rel no-select default-cursor">
                <img className="hud-timer-bg" src={TimerBg} />
                <p className="hud-timer-readout" aria-label={this.state.mins + ' Minutes and ' + this.state.seconds + ' Seconds'}>{this.state.mins < 10 ? '0' + this.state.mins : this.state.mins}:{this.state.secs < 10 ? '0' + this.state.secs : this.state.secs}</p>
            </div>
        );
    }
}