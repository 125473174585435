import { OrthRenderer } from "./OrthRenderer";
import { IsoRenderer  } from "./IsoRenderer";
import { DEBUG } from "../../model/config";
import { ProcessedMapChunks } from "./MapChunk";
import { ProcessedTilesets } from "./TileSet";

/** @author Carl Trelfa
 * 
 * OrthEngine is the main workhorse of the tile engine.
 * 
 * You can add and remove map chunks, hide and show sprites based on a visible rectangle (if you are scrolling you map, for example),
 * get the tile at a pixel position or grid position, get surrounding sprites etc.
 * 
 * Conbined with the scrolling map class or the randomised map classes you have all you need to make a tile based game.
 * 
 * Usage:
 * 
 * -- First process your tile sets (see TileSet.js for that) --
 * -- Then process you map chunks (see MapChunk.js for that) --
 * 
 *      import { OrthEngine } from './here'
 * 
 *      let myOrthEngine = new OrthEngine(ProcessedTileSets, layerContainers[], tileWidth, tileHeight);
 *      myOrthEngine.addMapChunk(MapChunk, chunkX, chunkY);
 * 
 * Your map chunk is now rendered to the display. Map chunks can be re-used for different parts of your map.
 * 
 */
export const ISOMETRIC = 'isometric';
export const ORTHAGONAL = 'orthagonal';

export const DEBUG_CSS = 'background: #6441A5; color: #fff; padding: 5px; font-weight: bold';

export class TileMapEngine {
    type = null;
    renderer = null;
    tileSets = null;
    activeMapChunks = null;
    dynamicSpritesCallback = null;

    /**
     * Constructor
     * @param {object} tileSets - most likey already process in ProcessedTilesets (see TileSet.js)
     * @param {array} layerContainers - an array of Pixi Containers for your map layers
     * @param {number} tileWidth - width of a grid square
     * @param {number} tileHeight - height of a grid square
     * @param {string} type - use consts ISOMETRIC or ORTHAGONAL
     * @param {array} layerLabels (optional) - used by renderer, if we force layers for tiles we can use an id string so we don't need to keep renumbering
     * @param {array} tileBuffers (optional) - iso tiles can gain cracks between them, tileBuffers are used to make tiles on some layers larger to avoid this
     *                                         this is an array of numbers representing how many pixels to expand.
     */
    constructor(tileSets, layerContainers, tileWidth, tileHeight, type = ORTHAGONAL, layerLabels = [], tileBuffers = null) {
        console.log('New TileMapEngine: ', tileWidth, tileHeight);
        this.tileSets = tileSets;

        // The only real difference between the two types is the renderer!
        this.type = type;
        this.renderer = type === ORTHAGONAL ?
                            new OrthRenderer(layerContainers, tileWidth, tileHeight, layerLabels, tileBuffers) :
                            new IsoRenderer(layerContainers, tileWidth, tileHeight, layerLabels, tileBuffers);

        this.activeMapChunks = [];
        // console.log('tilesets: ', tileSets);
        if (DEBUG) {
            window.TileMapEngine = this;
            console.log('%c[TILE ENGINE DEBUG]%c window.TileMapEngine: ', DEBUG_CSS, '', this);
        }
    }

    /**
     * Set the tilesets to be used by the engine.
     * @param {object} tileSets - most likely the ProcessedTileSets object exported from TileSet.js
     */
    setTileSets(tileSets) {
        this.tileSets = tileSets;
    }

    /**
     * Add a map chunk to the engine. It will be rendered by the renderer at the position specified
     * @param {MapChunk} mapChunk - most likely taken from ProcessedMapChunks or ChunksByDifficulty, exported from MapChunk.js
     * @param {number} chunkX - the x position to render the map chunk
     * @param {number} chunkY - the y position to render the map chunk
     */
    addMapChunk(mapChunk, chunkX, chunkY) {
        // console.log('OrthEngine adding chunck: ', mapChunk, chunkX, chunkY);
        var chunkRenderInfo = this.renderer.renderMapChunk(mapChunk, this.tileSets, chunkX, chunkY);
        var newMapChunk = {
            mapChunk: mapChunk,
            chunkX: chunkX,
            chunkY: chunkY,
            sprites: chunkRenderInfo.sprites,
            renderedSprites: chunkRenderInfo.renderedSprites,
            bounds: chunkRenderInfo.bounds,
            layerBounds: chunkRenderInfo.layerBounds,
            spriteGrid: chunkRenderInfo.spriteGrid,
            specialSprites: chunkRenderInfo.specialSprites,
            dynamicSprites: chunkRenderInfo.dynamicSprites,
            switcherSprites: chunkRenderInfo.switcherSprites,
        };
        this.activeMapChunks.push(newMapChunk);
        console.log('OrthEngine added chunk', newMapChunk, this.activeMapChunks.length);
        if (this.dynamicSpritesCallback && newMapChunk.dynamicSprites.length > 0) {
            this.dynamicSpritesCallback(newMapChunk.dynamicSprites);
        }
    }

    /**
     * Hide the entire map.
     */
    hideAllSprites() {
        for (var i = 0; i < this.activeMapChunks.length; i++) {
            for (var j = 0; j < this.activeMapChunks[i].sprites.length; j++) {
                this.activeMapChunks[i].sprites[j].visible = false;
            }
        }
    }

    /**
     * Hide sprites outside of our view area
     * @param {PIXI.Rectangle} visibleRectangle - the visible area relative to our main map container
     * @param {boolean} dropOutIfOnlyOffRightFound - mostly used for randomised scrolling maps as an optimisation
     */
    hideShowSprites(visibleRectangle, dropOutIfOnlyOffRightFound = false) {
        // console.log('hideShowSprites: ', visibleRectangle);
        // this is specifically an optimisation for the horizontal scrolling maps - might need editing for others
        var onlyOffRightSpritesFound = false;
        for (var i = 0; i < this.activeMapChunks.length; i++) {
            onlyOffRightSpritesFound = true;
            for (var j = 0; j < this.activeMapChunks[i].renderedSprites.length; j++) {
                // if (this.activeMapChunks[i].sprites[j].visible == false) {
                    var sprite = this.activeMapChunks[i].renderedSprites[j];
                    var spriteBounds = sprite.cachedBounds;
                    if (!sprite.cachedBounds) {
                        sprite.cachedBounds = sprite.getLocalBounds();
                        /*
                        if (sprite.children[0] && sprite.children[0]._texture && sprite.children[0]._texture.trim) {
                            sprite.cachedBounds.x += sprite.children[0]._texture.trim.x;
                            sprite.cachedBounds.y += sprite.children[0]._texture.trim.y;
                            sprite.cachedBounds.width = sprite.children[0]._texture.trim.width;
                            sprite.cachedBounds.height = sprite.children[0]._texture.trim.height;
                        }
                        */
                        spriteBounds = sprite.cachedBounds;
                    }
                    // console.log(spriteBounds);
                    // spriteBounds.x += sprite.x;
                    // spriteBounds.y += sprite.y;
                    if (spriteBounds.left + sprite.x <= visibleRectangle.right) {
                        onlyOffRightSpritesFound = false;
                    }
                    /*
                    if (spriteBounds.left + sprite.x <= visibleRectangle.right && spriteBounds.right + sprite.x >= visibleRectangle.left
                            && spriteBounds.top + sprite.y <= visibleRectangle.bottom && spriteBounds.bottom + sprite.y >= visibleRectangle.top)
                    {
                    */
                    //if (spriteBounds.intersects(visibleRectangle)) {
                    if (/*spriteBounds.left +*/ sprite.x - sprite.width / 2 <= visibleRectangle.right && /*spriteBounds.right +*/ sprite.x + sprite.width / 2 >= visibleRectangle.left
                            && /*spriteBounds.top +*/ sprite.y - sprite.height <= visibleRectangle.bottom && /*spriteBounds.bottom +*/ sprite.y >= visibleRectangle.top)
                    {   
                        sprite.visible = true;
                        if (!sprite.inView) {
                            if (sprite.layerContainer) {
                                sprite.layerContainer.addChild(sprite);
                            }
                            sprite.inView = true;
                            /*
                            if (sprite.controller && sprite.controller.enteredView) {
                                sprite.controller.enteredView();
                            }
                            */
                            if (sprite.associateCharacters) {
                                for (let i = 0; i < sprite.associateCharacters.length; i++) {
                                    if (sprite.associateCharacters[i].startSquareEnteredView) {
                                        sprite.associateCharacters[i].startSquareEnteredView();
                                    }
                                }
                            }
                        }
                    } else {
                        sprite.visible = false;
                        if (sprite.inView) {
                            if (sprite.parent) {
                                sprite.parent.removeChild(sprite);
                            }
                            sprite.inView = false;
                            /*
                            if (sprite.controller && sprite.controller.leftView) {
                                sprite.controller.leftView();
                            }
                            */
                            if (sprite.associateCharacters) {
                                for (let i = 0; i < sprite.associateCharacters.length; i++) {
                                    if (sprite.associateCharacters[i].startSquareLeftView) {
                                        sprite.associateCharacters[i].startSquareLeftView();
                                    }
                                }
                            }
                        }
                    }
                // }
            }
            // we can drop out early if we have a sprite off the right!
            if (dropOutIfOnlyOffRightFound && onlyOffRightSpritesFound) {
                break;
            }
        }
    }

    /**
     * Remove the chunk at the index of our activeMapChunks array
     * @param {number} activeChunkIndex 
     */
    removeActiveChunk(activeChunkIndex) {
        var activeMapChunk = this.activeMapChunks[activeChunkIndex];
        this.renderer.removeChunkSprites(activeMapChunk.sprites);
        this.activeMapChunks.splice(activeChunkIndex, 1);
    }

    getAllSpecialSprites() {
        let specialSpritesCombined = [];
        for (let i = 0; i < this.activeMapChunks.length; i++) {
            specialSpritesCombined = specialSpritesCombined.concat(this.activeMapChunks[i].specialSprites);
        }
        return specialSpritesCombined;
    }

    getSpecialSpritesWithProperty(property) {
        let allSpecials = this.getAllSpecialSprites();
        let specialsWithProperty = [];
        for (let i = 0; i < allSpecials.length; i++) {
            // console.log('Special ' + i + ': ', allSpecials[i]);
            if (typeof allSpecials[i].config[property] !== 'undefined' || (allSpecials[i].localConfig && typeof allSpecials[i].localConfig[property] !== 'undefined')) {
                specialsWithProperty.push(allSpecials[i]);
            }
        }
        // console.log('looking for tiles with prop: ', property, specialsWithProperty);
        return specialsWithProperty;
    }

    /**
     * Switcher sprites can be configured in Tiled, useful for spikes that pop up on a countdown.
     * State Switcher tiles need the following properties setting up in Tiled:
     *      stateSwitcher = true
     *      switchCountdown = in seconds
     *      active = boolean (does it start activated?)
     *      activateAnim = string, an anim id prefix
     *      activateAnimFrames = the number of frames in the anim
     *      deactivateAnim = string, an anim id prefix
     *      deactivateAnimFrames = the number of frames in the anim
     * Plus you will probably need a property such as kills = true, so your game can react appropriately.
     * The engine just handles the visual side of it and setting the active flag.
     * @param {number} timeDelta - the time that has passed since the last time this was called (call it every frame)
     * @returns {array} of sprites that have changed state
     */
    updateSwitcherSprites(timeDelta) {
        let switched = [];
        for (var i = 0; i < this.activeMapChunks.length; i++) {
            for (var j = 0; j < this.activeMapChunks[i].switcherSprites.length; j++) {
                this.activeMapChunks[i].switcherSprites[j].switchCountdown -= timeDelta;
                if (this.activeMapChunks[i].switcherSprites[j].switchCountdown <= 0) {
                    // console.log('switching state of switcher: ', this.activeMapChunks[i].switcherSprites[j]);
                    switched.push(this.activeMapChunks[i].switcherSprites[j]);
                    this.activeMapChunks[i].switcherSprites[j].switchCountdown += this.activeMapChunks[i].switcherSprites[j].config.switchTime;
                    this.activeMapChunks[i].switcherSprites[j].active = !this.activeMapChunks[i].switcherSprites[j].active;
                    this.activeMapChunks[i].switcherSprites[j].defaultSprite.visible = false;
                    if (this.activeMapChunks[i].switcherSprites[j].active) {
                        this.activeMapChunks[i].switcherSprites[j].stateSwitchAnims[0].visible = true;
                        this.activeMapChunks[i].switcherSprites[j].stateSwitchAnims[0].gotoAndPlay(0);
                        this.activeMapChunks[i].switcherSprites[j].stateSwitchAnims[1].visible = false;
                        this.activeMapChunks[i].switcherSprites[j].stateSwitchAnims[1].gotoAndStop(0);
                    } else {
                        this.activeMapChunks[i].switcherSprites[j].stateSwitchAnims[0].visible = false;
                        this.activeMapChunks[i].switcherSprites[j].stateSwitchAnims[0].gotoAndStop(0);
                        this.activeMapChunks[i].switcherSprites[j].stateSwitchAnims[1].visible = true;
                        this.activeMapChunks[i].switcherSprites[j].stateSwitchAnims[1].gotoAndPlay(0);
                    }
                }
            }
        }
        return switched;
    }

    /**
     * This is mainly for random scrolling maps like Pokemon Crazy Cliffs.
     * @param {number} timeDeltaRatio - pretty standard (should be 1 for perfect frame timing, <1 for short frame, >1 for long frame)
     * @param {number} activateX - this should be your position with the map + some way ahead. Sprites will start moving when they pass this position.
     * @returns {array} of moving sprites
     */
    updateMovingSprites(timeDeltaRatio, activateX) {
        var currentMovingSprites = [];
        for (var i = 0; i < this.activeMapChunks.length; i++) {
            for (var j = 0; j < this.activeMapChunks[i].specialSprites.length; j++) {
                if (this.activeMapChunks[i].specialSprites[j].moving) {
                    if (this.activeMapChunks[i].specialSprites[j].active) {
                        this.activeMapChunks[i].specialSprites[j].y += this.activeMapChunks[i].specialSprites[j].velocity.y * timeDeltaRatio;
                        this.activeMapChunks[i].specialSprites[j].x += this.activeMapChunks[i].specialSprites[j].velocity.x * timeDeltaRatio;
                        currentMovingSprites.push(this.activeMapChunks[i].specialSprites[j]);
                    } else
                    if (this.activeMapChunks[i].specialSprites[j].x < activateX) {
                        this.activeMapChunks[i].specialSprites[j].active = true;
                    }
                }
            }
        }
        return currentMovingSprites;
    }

    updateFloatingSprites(timeDeltaRatio) {
        for (var i = 0; i < this.activeMapChunks.length; i++) {
            for (var j = 0; j < this.activeMapChunks[i].specialSprites.length; j++) {
                if (this.activeMapChunks[i].specialSprites[j].config.floats) {
                    if (this.activeMapChunks[i].specialSprites[j].startedMoving === false) {
                        this.activeMapChunks[i].specialSprites[j].startedMoving = true;

                        this.activeMapChunks[i].specialSprites[j].vxDir = 0; // Math.random() < 0.5 ? -1 : 1;
                        this.activeMapChunks[i].specialSprites[j].vyDir = 0; // Math.random() < 0.5 ? -1 : 1;
                        this.activeMapChunks[i].specialSprites[j].minVx = this.activeMapChunks[i].specialSprites[j].config.minVx || this.activeMapChunks[i].specialSprites[j].config.minSpeed;
                        this.activeMapChunks[i].specialSprites[j].maxVx = this.activeMapChunks[i].specialSprites[j].config.maxVx || this.activeMapChunks[i].specialSprites[j].config.maxSpeed;
                        this.activeMapChunks[i].specialSprites[j].minVy = this.activeMapChunks[i].specialSprites[j].config.minVy || this.activeMapChunks[i].specialSprites[j].config.minSpeed;
                        this.activeMapChunks[i].specialSprites[j].maxVy = this.activeMapChunks[i].specialSprites[j].config.maxVy || this.activeMapChunks[i].specialSprites[j].config.maxSpeed;
                        this.activeMapChunks[i].specialSprites[j].currentMaxVx = 0;
                        this.activeMapChunks[i].specialSprites[j].currentMaxVy = 0;
                        this.activeMapChunks[i].specialSprites[j].currentMaxXDistance = 0;
                        this.activeMapChunks[i].specialSprites[j].currentMaxYDistance = 0;
                        // console.log('floating sprite: ', this.activeMapChunks[i].specialSprites[j]);
                    }
                    let flipX = 1;
                    if ( (this.activeMapChunks[i].specialSprites[j].vxDir <= 0 && this.activeMapChunks[i].specialSprites[j].x <= this.activeMapChunks[i].specialSprites[j].startX) || (this.activeMapChunks[i].specialSprites[j].vxDir > 0 && this.activeMapChunks[i].specialSprites[j].x >= this.activeMapChunks[i].specialSprites[j].startX) ) {
                        if (Math.abs(this.activeMapChunks[i].specialSprites[j].startX - this.activeMapChunks[i].specialSprites[j].x) >= this.activeMapChunks[i].specialSprites[j].currentMaxXDistance) {
                            // slow down and flip dir
                            this.activeMapChunks[i].specialSprites[j].vx -= this.activeMapChunks[i].specialSprites[j].config.accelleration * 3 * timeDeltaRatio;
                            if (this.activeMapChunks[i].specialSprites[j].vx <= 0) {
                                this.activeMapChunks[i].specialSprites[j].vx = 0;
                                if (this.activeMapChunks[i].specialSprites[j].vxDir !== 0) {
                                    this.activeMapChunks[i].specialSprites[j].vxDir = this.activeMapChunks[i].specialSprites[j].x < this.activeMapChunks[i].specialSprites[j].startX ? 1 : -1;
                                } else {
                                    this.activeMapChunks[i].specialSprites[j].vxDir = Math.random() < 0.5 ? -1 : 1;
                                }
                                this.activeMapChunks[i].specialSprites[j].currentMaxVx = this.activeMapChunks[i].specialSprites[j].minVx + Math.random() * (this.activeMapChunks[i].specialSprites[j].maxVx - this.activeMapChunks[i].specialSprites[j].minVx);
                                this.activeMapChunks[i].specialSprites[j].currentMaxXDistance = this.activeMapChunks[i].specialSprites[j].config.minXDistance + Math.random() * (this.activeMapChunks[i].specialSprites[j].config.maxXDistance - this.activeMapChunks[i].specialSprites[j].config.minXDistance);
                                // console.log('floating sprite changed xdir: ', this.activeMapChunks[i].specialSprites[j]);
                            } /*else {
                                flipX = -1;
                            }*/
                        }
                    }
                    if ( (this.activeMapChunks[i].specialSprites[j].vyDir <= 0 && this.activeMapChunks[i].specialSprites[j].y <= this.activeMapChunks[i].specialSprites[j].startY) || (this.activeMapChunks[i].specialSprites[j].vyDir > 0 && this.activeMapChunks[i].specialSprites[j].y >= this.activeMapChunks[i].specialSprites[j].startY) ) {
                        if (Math.abs(this.activeMapChunks[i].specialSprites[j].startY - this.activeMapChunks[i].specialSprites[j].y) >= this.activeMapChunks[i].specialSprites[j].currentMaxYDistance) {
                            // slow down and flip dir
                            this.activeMapChunks[i].specialSprites[j].vy -= this.activeMapChunks[i].specialSprites[j].config.accelleration * 2 * timeDeltaRatio;
                            if (this.activeMapChunks[i].specialSprites[j].vy <= 0) {
                                this.activeMapChunks[i].specialSprites[j].vy = 0;
                                if (this.activeMapChunks[i].specialSprites[j].vxDir !== 0) {
                                    this.activeMapChunks[i].specialSprites[j].vyDir = this.activeMapChunks[i].specialSprites[j].y < this.activeMapChunks[i].specialSprites[j].startY ? 1 : -1;
                                } else {
                                    this.activeMapChunks[i].specialSprites[j].vyDir = Math.random() < 0.5 ? -1 : 1;
                                }
                                this.activeMapChunks[i].specialSprites[j].currentMaxVy = this.activeMapChunks[i].specialSprites[j].minVy + Math.random() * (this.activeMapChunks[i].specialSprites[j].maxVy - this.activeMapChunks[i].specialSprites[j].minVy);
                                this.activeMapChunks[i].specialSprites[j].currentMaxYDistance = this.activeMapChunks[i].specialSprites[j].config.minYDistance + Math.random() * (this.activeMapChunks[i].specialSprites[j].config.maxYDistance - this.activeMapChunks[i].specialSprites[j].config.minYDistance);
                                // console.log('floating sprite changed ydir: ', this.activeMapChunks[i].specialSprites[j]);
                            }
                        }
                    }
                    if (Math.abs(this.activeMapChunks[i].specialSprites[j].vx) < this.activeMapChunks[i].specialSprites[j].currentMaxVx) {
                        this.activeMapChunks[i].specialSprites[j].vx += this.activeMapChunks[i].specialSprites[j].config.accelleration * timeDeltaRatio;
                    }
                    if (Math.abs(this.activeMapChunks[i].specialSprites[j].vy) < this.activeMapChunks[i].specialSprites[j].currentMaxVy) {
                        this.activeMapChunks[i].specialSprites[j].vy += this.activeMapChunks[i].specialSprites[j].config.accelleration * timeDeltaRatio;
                    }
                    this.activeMapChunks[i].specialSprites[j].x += this.activeMapChunks[i].specialSprites[j].vx * this.activeMapChunks[i].specialSprites[j].vxDir * timeDeltaRatio;
                    this.activeMapChunks[i].specialSprites[j].y += this.activeMapChunks[i].specialSprites[j].vy * this.activeMapChunks[i].specialSprites[j].vyDir * timeDeltaRatio;
                    this.activeMapChunks[i].specialSprites[j].scale.x = this.activeMapChunks[i].specialSprites[j].vxDir * this.activeMapChunks[i].specialSprites[j].config.positiveDir * flipX;
                }
            }
        }
    }

    /**
     * Find all tiles underneath a pixel position within the map.
     * Useful for finding tiles that have been clicked on, maybe other stuff too!
     * @param {number} pixelX 
     * @param {number} pixelY 
     * @param {boolean} findAll - if false will drop out when it finds the first tile, otherwaise will find all of them
     * @returns {array} of Tile Sprites. These tile sprites have references to map chunk, config data, grid position etc.
     */
    getTileSpritesAtPixelPos(pixelX, pixelY, findAll) {
        var tilesFound = [];
        for (var i = 0; i < this.activeMapChunks.length; i++) {
            for (var j = 0; j < this.activeMapChunks[i].sprites.length; j++) {
                // an optimisation because I'm not sure how efficient getLocalBounds is
                // (not very it turns out!)
                if (Math.abs(this.activeMapChunks[i].sprites[j].x - pixelX) <= this.activeMapChunks[i].sprites[j].width && Math.abs(this.activeMapChunks[i].sprites[j].y - pixelY) <= this.activeMapChunks[i].sprites[j].height) {
                    var spriteBounds = this.activeMapChunks[i].sprites[j].cachedBounds || this.activeMapChunks[i].sprites[j].getLocalBounds();
                    // console.log('Checking sprite bounds: ', this.activeMapChunks[i].sprites[j], spriteBounds);
                    // if (Math.abs(pixelX - this.activeMapChunks[i].sprites[j].x) <= (this.renderer.tileWidth / 2) + 2 && Math.abs(pixelY - this.activeMapChunks[i].sprites[j].y) <= (this.renderer.tileHeight / 2) + 2) {
                    if (pixelX + 2 >= spriteBounds.left + this.activeMapChunks[i].sprites[j].x && pixelX - 2 <= spriteBounds.right + this.activeMapChunks[i].sprites[j].x && pixelY + 2 >= spriteBounds.top + this.activeMapChunks[i].sprites[j].y && pixelY - 2 <= spriteBounds.bottom + this.activeMapChunks[i].sprites[j].y) {
                        // console.log('sprite: ', this.activeMapChunks[i].sprites[j]);
                        // this.activeMapChunks[i].sprites[j].alpha = 0.5;
                        if (findAll) {
                            tilesFound.push(this.activeMapChunks[i].sprites[j]);
                        } else {
                            return [this.activeMapChunks[i].sprites[j]];
                        }
                    }
                }
            }
        }
        return tilesFound;
    }

    /**
     * Get all the tile sprites over an area. Possibly getSurroundingTilesFromPixelPos is more useful, but does use this function itself.
     * @param {number} gridX - relative to the map chunk, 0,0 is the top left of the chunk (not necessarily the entire map)
     * @param {number} gridY - relative to the map chunk
     * @param {number} distanceToGetX - how far left / right to look
     * @param {number} distanceToGetY - how far up / down to look
     * @param {object} activeMapChunk - active map chunk data, as returned from our renderer and stored in activeMapChunks array
     * @param {array} ignoreLayers - an array of layer indexes to ignore
     * @returns {array} of Tile Sprites. These tile sprites have references to map chunk, config data, grid position etc.
     */
    getSurroundingSpritesFromGridPos(gridX, gridY, distanceToGetX, distanceToGetY, activeMapChunk, ignoreLayers) {
        // console.log('mapchunk gx, gy: ', gridX, gridY, distanceToGetX, distanceToGetY);
        var tilesFound = [];
        gridX -= distanceToGetX;
        gridY -= distanceToGetY;
        var gridEndX = gridX + distanceToGetX * 2;
        var gridEndY = gridY + distanceToGetY * 2;
        if (gridEndX < 0 || gridEndY < 0 || gridX > activeMapChunk.spriteGrid[0].length || gridY > activeMapChunk.spriteGrid[0][0].length) {
            return [];
        }
        if (gridX < 0) gridX = 0;
        if (gridY < 0) gridY = 0;
        if (gridEndX > activeMapChunk.spriteGrid[0].length - 1) gridEndX = activeMapChunk.spriteGrid[0].length - 1;
        if (gridEndY > activeMapChunk.spriteGrid[0][0].length - 1) gridEndY = activeMapChunk.spriteGrid[0][0].length - 1;
        for (var l = 0; l < activeMapChunk.spriteGrid.length; l++) {
            if (ignoreLayers && ignoreLayers.lastIndexOf(l) >= 0) continue;
            for (var gx = gridX; gx <= gridEndX; gx++) {
                for (var gy = gridY; gy <= gridEndY; gy++) {
                    if (activeMapChunk.spriteGrid[l] && activeMapChunk.spriteGrid[l][gx] && activeMapChunk.spriteGrid[l][gx][gy] != null) {
                        tilesFound.push(activeMapChunk.spriteGrid[l][gx][gy]);
                    }
                }
            }
        }
        // console.log('mapChunk tilesfound: ', tilesFound);
        return tilesFound;
    }

    /**
     * Find all the tiles within a certain distance of a map-wide pixel position.
     * There is an issue with this function - it won't find tiles in adjacent map chunks, just the chunk the actual pixel position is in.
     * Suggest game logic calls this for various pixels around the area you want tiles for - basically look ahead.
     * Also won't work too well with overlapping chunks.
     * @param {number} pixelX - pixel x position within the map
     * @param {number} pixelY - pixel y position within the map
     * @param {number} distanceToGetX - distance in pixels to find tiles
     * @param {number} distanceToGetY - distance in pixels to find tiles
     * @param {array} ignoreLayers - an array of layer indexes to ignore
     * @returns {array} of Tile Sprites. These tile sprites have references to map chunk, config data, grid position etc.
     */
    getSurroundingTilesFromPixelPos(pixelX, pixelY, distanceToGetX, distanceToGetY/* get all tiles within this number of grid squares */, ignoreLayers) {
        /*
        console.log('radius: ', distanceToGetX, distanceToGetY);
        var g = new PIXI.Graphics();
        g.beginFill(0xff0000, 1);
        g.drawCircle(pixelX, pixelY, 3);
        g.endFill();
        this.renderer.layerContainers[this.renderer.layerContainers.length - 1].addChild(g);
        */

        var tilesFound = [];
        var spritesAtPixelPos = this.getTileSpritesAtPixelPos(pixelX, pixelY);
        // console.log('spritesFound: ', spritesAtPixelPos);
        if (spritesAtPixelPos.length > 0) {
            var gX = spritesAtPixelPos[0].tileData.gridX;
            var gY = spritesAtPixelPos[0].tileData.gridY;
            // console.log('eng gx, gy:', gX, gY);
            for (var i = 0; i < this.activeMapChunks.length; i++) {
                // The maths to go from iso co-ords to grid co-ords is really hard to figure out,
                // as such this functoin has some flaws - it won't find tiles surrounding and empty square for example.
                // This could do with being updated with maths instead!
                // Luckily, I think I can get away with this for the Dribbler game.
                if (/*this.activeMapChunks[i].bounds.contains(pixelX, pixelY)*/this.activeMapChunks[i].sprites.indexOf(spritesAtPixelPos[0]) >= 0) {
                    //var tilesFound_pass = this.activeMapChunks[i].mapChunk.getSurroundingTilesFromGridPos(gX, gY, distanceToGetX, distanceToGetY, this.tileSets, this.activeMapChunks[i].spriteGrid, ignoreLayers);
                    // this is more efficient!
                    var tilesFound_pass = this.getSurroundingSpritesFromGridPos(gX, gY, distanceToGetX, distanceToGetY, this.activeMapChunks[i], ignoreLayers);
                    // console.log('surroundingtiles_pass: ', tilesFound_pass);
                    tilesFound = tilesFound.concat(tilesFound_pass);
                }
            }
        }
        // console.log('Tiles found: ', tilesFound);
        return tilesFound;
    }

    /**
     * Is our test sprite within the tile?
     * @param {PIXI.DisplayObject} tileSprite 
     * @param {PIXI.DisplayObject} testSprite - the testSprites x / y pos will be checked to see if it is within the tile
     * @param {number} safeBuffer - the size of the tile is padded by this amount
     * @returns 
     */
    checkInsideTile(tileSprite, testSprite, safeBuffer) {
        var inside = false;
        // var xDiff = Math.abs(tileSprite.x - testSprite.x);
        // var yDiff = Math.abs(tileSprite.y - testSprite.y);
        if (safeBuffer === undefined) {
            safeBuffer = 0;
        }
        var spriteBounds = tileSprite.cachedBounds || tileSprite.getLocalBounds();
        spriteBounds.pad(safeBuffer, safeBuffer);
        // if (Math.abs(pixelX - this.activeMapChunks[i].sprites[j].x) <= (this.renderer.tileWidth / 2) + 2 && Math.abs(pixelY - this.activeMapChunks[i].sprites[j].y) <= (this.renderer.tileHeight / 2) + 2) {
        if (testSprite.x >= spriteBounds.left + tileSprite.x && testSprite.x <= spriteBounds.right + tileSprite.x && testSprite.y >= spriteBounds.top + tileSprite.y && testSprite.y <= spriteBounds.bottom + tileSprite.y) {
        // if (xDiff <= (this.renderer.tileWidth / 2) + safeBuffer && yDiff <= (this.renderer.tileHeight / 2) + safeBuffer/*  && xDiff <= ((this.renderer.tileHeight / 2 - yDiff) * 2) + safeBuffer */) {
            inside = true;
        }
        return inside;
    }

    /**
     * Make a permanent change to a tilemap chunk - this will then be reflected in any active versions of this chunk
     * by calling makeLiveChangeToMapChunk.  Bear in mind that if you make a permanent change you will have to re-parse
     * the map chunks to return them back to their original form.
     * @param {string} mapChunkId 
     * @param {number} layer 
     * @param {number} gridX 
     * @param {number} gridY 
     * @param {string} newTilesetId - pass null to remove the tile
     * @param {number} newTileId    - pass null to remove the tile
     * 
     * @returns {object} spritesChanged {oldSprites_array, newSprites_array}
     *                   You will need to remove the old sprites from the view yourself, this allows you to transition between them.
     */
    makePermanentChangeToMapChunk(mapChunkId, layer, gridX, gridY, newTilesetId = null, newTileId = null) {
        let spritesChanged = null;
        let mapChunk = ProcessedMapChunks[mapChunkId];
        if (mapChunk) {
            // console.log(mapChunk);
            let newTileData = null;
            if (newTilesetId !== null && newTileId != null) {
                let tileset = ProcessedTilesets[newTilesetId];
                if (tileset) {
                    // console.log(tileset);
                    if (tileset.configLookup[newTileId]) {
                        // console.log(tileset.configLookup[newTileId]);
                        // console.log('--- We have a valid tile ---')
                        newTileData = {
                            gridX: gridX,
                            gridY: gridY,
                            layer: layer,
                            mapChunk: mapChunk,
                            tileSet: newTilesetId,
                            tile: newTileId,
                        }
                    }
                }
            } else {
                // console.log('--- Removing a tile ---');
            }
            mapChunk.layers[layer][gridX][gridY] = newTileData;
            spritesChanged = this.makeLiveChangeToMapChunk(mapChunkId, layer, gridX, gridY, newTilesetId, newTileId);
        }
        return spritesChanged;
    }

    /**
     * Change a tile on live map chunks - this will change the tile on all active versions of the live map chunk.
     * In most case where you will need this you will only have a single active version of the map chunk anyway, 
     * so this shouldn't be a problem.
     * Sprites are actually swapped using swapTile below or if no tile exists in the position, a new tile is added.
     * @param {string} mapChunkId 
     * @param {number} layer 
     * @param {number} gridX 
     * @param {number} gridY 
     * @param {string} newTilesetId - pass null to remove the tile
     * @param {number} newTileId    - pass null to remove the tile
     * 
     * @returns {object} spritesChanged {oldSprites:array, newSprites:array}
     *                   You will need to remove the old sprites from the view yourself, this allows you to transition between them.
     */
    makeLiveChangeToMapChunk(mapChunkId, layer, gridX, gridY, newTilesetId = null, newTileId = null) {
        let spritesChanged = {oldSprites: [], newSprites: []};
        for (let i = 0; i < this.activeMapChunks.length; i++) {
            // console.log('active map chunk ' + i + ': ', this.activeMapChunks[i]);
            if (this.activeMapChunks[i].mapChunk.id === mapChunkId) {
                // console.log('--- making change to active chunk: ', this.activeMapChunks[i]);
                let oldSprite = this.activeMapChunks[i].spriteGrid[layer][gridX][gridY];
                spritesChanged.oldSprites.push(oldSprite);
                // console.log('--- Old sprite: ', oldSprite);
                if (oldSprite) {
                    // swap the tile if there is already a tile in this position - this will remove the old tile if you are replacing with null, null
                    spritesChanged.newSprites.push(this.swapTile(oldSprite, newTilesetId, newTileId, this.activeMapChunks[i]));
                } else {
                    // no tile in this space already, so add one...
                    if (newTilesetId !== null && newTileId !== null) {
                        spritesChanged.newSprites.push(this.addTileToChunk(this.activeMapChunks[i], layer, gridX, gridY, newTilesetId, newTileId));
                    }
                }
            }
        }
        return spritesChanged;
    }

    /**
     * Swap a specific tile on a specific active chunk.
     * The map must already contain a tile at this position for this function to work.
     * If you are adding a new tile, as oppose to swapping one, use addTile instead.
     * In any case, you probably never need to call this or addTile directly, just use makeLiveChangeToMapChunk instead.
     * This wont affect other active versions of the same chunk, or permanently change the map chunk.
     * @param {PIXI.Container} oldTileSprite - pull this out of the activeMapChunk's spriteGrid (or use the getter functions above)
     * @param {string} newTilesetId - pass null to remove the tile 
     * @param {string} newTileId    - pass null to remove the tile 
     * @param {object} activeMapChunk (optional), default to null - if you don't know it, it will be found for you
     * @returns {PIXI.Container} new sprite
     *                   You will need to remove the old sprite from the view yourself, this allows you to transition between them.
     */
    swapTile(oldTileSprite, newTilesetId = null, newTileId = null, activeMapChunk = null) {
        let newSprite = null;
        if (activeMapChunk === null) {
            // find the map chunk
            if (oldTileSprite === null) {
                // we can't possibly find a map chunk here!
                return null;
            }
            activeMapChunk = oldTileSprite.activeMapChunkData;
        }
        // remove old tile
        if (oldTileSprite !== null) {
            this.renderer.removeTile(activeMapChunk, oldTileSprite);
        }
        if (oldTileSprite !== null && newTilesetId !== null && newTileId !== null) {
            // setup new sprite
            newSprite = this.renderer.setupTileSprite(activeMapChunk, oldTileSprite.layer, oldTileSprite.gridX, oldTileSprite.gridY, oldTileSprite.baseTileX, oldTileSprite.baseTileY, ProcessedTilesets, newTilesetId, newTileId);
        }
        // insert into map and add to container
        if (newSprite) {
            this.renderer.addTileToChunk(activeMapChunk, newSprite);
        }
        return newSprite;
    }

    /**
     * Add a tile to a specific map chunk at a specific position.
     * @param {object} activeMapChunk 
     * @param {number} layer 
     * @param {number} gridX 
     * @param {number} gridY 
     * @param {string} newTilesetId 
     * @param {number} newTileId 
     * @returns {PIXI.Container} new sprite
     */
    addTileToChunk(activeMapChunk, layer, gridX, gridY, newTilesetId = null, newTileId = null) {
        let newSprite = null;
        if (newTilesetId !== null && newTileId !== null) {
            let tilePixelPos = this.renderer.calculateTilePixelPos(activeMapChunk, gridX, gridY);
            newSprite = this.renderer.setupTileSprite(activeMapChunk, layer, gridX, gridY, tilePixelPos.x, tilePixelPos.y, ProcessedTilesets, newTilesetId, newTileId);
            if (newSprite) {
                this.renderer.addTileToChunk(activeMapChunk, newSprite);
            }
        }
        return newSprite;
    }
}