// Surround text with ^ and ~ to make it bold (eg. 'Some non-bold and ^bold~ text')


export const NEIGHBOUR_DIALOG = {
    beforeTask: [
        {
            character: 'you',
            text: 'Hey, are you okay?',
        },
        {
            character: 'neighbour',
            text: 'I\'m having a nightmare - I\'ve forgotten to pick up enough vegetables for our family meal, but I need to make the food before my shift. Have you got any vegetables I could use?',
        },
        /* {
            character: 'neighbour',
            text: 'I don\'t suppose you have any vegetables I could use to finish the meal?',
        }, */
        {
            character: 'you',
            text: 'Sorry, I\'m not sure we have any in but I\'ll keep an eye out and if I find any I\'ll bring them right over!',
        },
        {
            character: 'neighbour',
            text: 'If you do find some, I\'ll owe you a lift, anywhere you need to go my friend!',
        }
    ],

    completeTask: [
        {
            character: 'you',
            text: 'Hey, are you okay? ',
        },
        {
            character: 'neighbour',
            text: 'I\'m having a nightmare - I\'ve forgotten to pick up enough vegetables for our family meal, but I need to make the food before my shift. Have you got any vegetables I could use?',
        },
        /* {
            character: 'neighbour',
            text: 'I don\'t suppose you have any vegetables I could use to finish the meal?',
        }, */
        {
            character: 'you',
            text: 'I did some voluntary work at the Food Bank earlier and they gave me some veg. You\'re welcome to have it if it\'ll help?',
        },
        {
            character: 'neighbour',
            text: 'Seriously?! Wow, thank you so much! If I can ever give you a lift anywhere in my taxi, please just let me know!',
        },
        {
            character: 'you',
            text: 'No problem at all, glad I could help!',
        }
    ],

    beforeTaskRevisit: [
        {
            character: 'neighbour',
            text: 'Hi, did you have any luck finding some veg?',
        },
        {
            character: 'you',
            text: 'No, sorry. I\'ll keep looking and come back when I have some.',
        },
    ],

    completeTaskRevisit: [
        {
            character: 'neighbour',
            text: '*Stomach Rumble*                 |                 |Hi, did you have any luck finding some veg?'
        },
        /* {
            character: 'neighbour',
            text: 'Hi, did you have any luck finding some veg?',
        }, */
        {
            character: 'you',
            text: 'Yes I did! I did some voluntary work at the Food Bank earlier and they gave me some veg. Here you go!',
        },
        {
            character: 'neighbour',
            text: 'Seriously?! Wow, thank you so much! Like I say, if I can ever give you a lift anywhere please just let me know!'
        },
        {
            character: 'you',
            text: 'No problem at all, glad I could help!'
        }
    ],

    afterTask: [
        {
            character: 'neighbour',
            text: 'Thanks again for the vegetables, if I can ever give you a lift anywhere please just let me know!',
        }
    ],

    taskUnavailable: [
        {
            character: 'neighbour',
            text: 'Thanks again for the vegetables, if I can ever give you a lift anywhere please just let me know!',
        }
    ],

    getLiftBefore: [
        {
            character: 'you',
            text: 'Hi again, please can I get a lift to my job interview? It\'s across town and I don\'t have time to get there myself - having a bit of a last minute panic! '
        },
        {
            character: 'neighbour',
            text: 'No problem! You came to my aid in my hour of need and I\'ll come to yours! Get in and let\'s go!'
        }
    ],

    getLiftAfter: [
        {
            character: 'you',
            text: 'Thanks so much for driving me here, I really appreciate it.'
        },
        {
            character: 'neighbour',
            text: 'Don\'t mention it - now, go and smash the interview! Let me know how you get on.'
        },
        {
            character: 'you',
            text: 'Will do, see ya.'
        }
    ]
}

export const YOUNG_PERSON_DIALOG = {
    beforeTask: [
        /* {
            character: 'you',
            text: 'Hey, how are you doing?'
        }, */
        {
            character: 'leadToInterview',
            text: 'Ugh...hey, sorry for bumping into you.',
        },
        {
            character: 'you',
            text: 'Are you okay?'
        },
        {
            character: 'leadToInterview',
            text: 'I\'m supposed to be starting my work placement at the Artillery Tower, but I am pretty useless with directions and I\'m going to be late.'
        },
        {
            character: 'you',
            text: 'The Artillery Tower... I know that area, one of my friends\' family members is working on a big job at the Seafront Hotel. I can take you there if it\'d help?'
        },
        {
            character: 'leadToInterview',
            text: 'Really? That\'s awesome, thank you!'
        }
    ],

    completeTask: [
        {
            character: 'you',
            text: 'Well, heeeeere we are.'
        },
        {
            character: 'leadToInterview',
            text: 'Oh wow, it looks amazing. Thanks so much for helping me find it, I feel a little less nervous now.'
        },
        {
            character: 'you',
            text: 'Hey, don\'t mention it.||Have a great time!'
        }
    ],

    taskUnavailable: [
        {
            character: 'you',
            text: 'Good morning!'
        },
        {
            character: 'leadToInterview',
            text: 'Morning! I have a work placement today - I hope I can get there on time.'
        }
    ]
}

export const GROUNDSKEEPER_DIALOG = {
    beforeTask: [
        {
            character: 'you',
            text: 'Hey, nice to meet you!'
        },
        {
            character: 'hospitalgroundsman',
            text: 'Well, it would be if there wasn\'t so much litter everywhere. What is it with people?! This area has incredible, wonderful views and they decide to throw their rubbish around.'
        },
        {
            character: 'you',
            text: 'It is a shame, you\'re right.                 |                 |Do you need a hand picking it all up?'
        },
        {
            character: 'hospitalgroundsman',
            text: 'That would be fantastic if you could. If you see any litter about please pick it up for me!'
        }
    ],
    completeTask: [
        {
            character: 'hospitalgroundsman',
            text: 'Ah much better, see, look at this view! A truly wonderful place! Thank you so much for the help, you\'ve made my job miles easier today!'
        },
        {
            character: 'you',
            text: 'No problem at all, glad we could tidy this wonderful space up for everyone. '
        }
    ],
    afterTask: [
        {
            character: 'hospitalgroundsman',
            text: 'Thank you so much for the help, you\'ve made my job miles easier today!'
        }
    ],
    taskUnavailable: [
        {
            character: 'you',
            text: 'Morning!'
        },
        {
            character: 'hospitalgroundsman',
            text: 'Good morning to you. Look at all this pesky rubbish - why don\'t people just throw it in the bin?'
        }
    ]
}

export const LIGHTHOUSE_DIALOG = {
    beforeTask: [
        {
            character: 'lighthouse',
            text: '*Yawn*',
        },
        {
            character: 'you',
            text: 'Hi, the tower is looking amazing!'
        },
        {
            character: 'lighthouse',
            text: 'Oh...hey, thanks. Yeah, we\'ve been working the last few nights to finish work. I\'m beginning to feel the effects of these night shifts now!',
        },
        {
            character: 'you',
            text: 'Yeah, I can imagine.||Is there anything I can do to help?',
        },
        {
            character: 'lighthouse',
            text: 'Well, a cup of coffee would really help me with this last shift. I would go myself but we\'re just in the thick of it at the moment - but then it\'s all done for another year!',
        },
        {
            character: 'you',
            text: 'I can go and grab you a coffee, no problem.'
        },
        {
            character: 'lighthouse',
            text: 'You could? Ahh that\'d be amazing, thank you!'
        }
    ],

    completeTask: [
        {
            character: 'lighthouse',
            text: '*Yawn*',
        },
        {
            character: 'you',
            text: 'Hi, the tower is looking amazing!'
        },
        {
            character: 'lighthouse',
            text: 'Oh...hey, thanks. Yeah, we\'ve been working the last few nights to finish work. I\'m beginning to feel the effects of these night shifts now!',
        },
        {
            character: 'you',
            text: 'Yeah, I can imagine.||Is there anything I can do to help?',
        },
        {
            character: 'lighthouse',
            text: 'Well, a cup of coffee would really help me with this last shift. I would go myself but we\'re just in the thick of it at the moment but then it\'s all done for another year!',
        },
        {
            character: 'you',
            text: 'I have a cup of coffee you can have!||Here, this ought to keep you going.'
        },
        {
            character: 'lighthouse',
            text: 'Amazing, thank you.                 |                 |*Sips coffee*'
        },
        {
            character: 'lighthouse',
            text: 'Wow, that is good coffee! Just what the doctor ordered.||Thanks buddy, really appreciate it.'
        },
        {
            character: 'you',
            text: 'No worries, glad to help!'
        }

    ],

    beforeTaskRevisit: [
        {
            character: 'lighthouse',
            text: 'Hi!||Did you manage to find me a cup of coffee?'
        },
        {
            character: 'you',
            text: 'No, not yet sorry.'
        },
        {
            character: 'lighthouse',
            text: 'Oh ok, no problem.'
        },
    ],

    completeTaskRevisit: [
        {
            character: 'lighthouse',
            text: 'Hi!||Did you manage to find me a cup of coffee?'
        },
        {
            character: 'you',
            text: 'Yep, this ought to keep you going.'
        },
        {
            character: 'lighthouse',
            text: 'Amazing, thank you.                 |                 |*Sips coffee*'
        },
        {
            character: 'lighthouse',
            text: 'Wow, that is good coffee! Just what the doctor ordered.||Thanks buddy, really appreciate it.'
        },
        {
            character: 'you',
            text: 'No worries, glad to help!'
        }
    ],

    taskUnavailable: [
        {
            character: 'you',
            text: 'Good morning!'
        },
        {
            character: 'lighthouse',
            text: '*yawn* Morning.||It\'s going to be a long day!'
        }
    ],

    afterTask: [
        {
            character: 'lighthouse',
            text: 'Thank you for the coffee, really appreciate it!'
        }
    ],
}

export const COFFEE_SHOP_DIALOG = {
    beforeTask: [
        {
            character: 'you',
            text: 'Hi Steve, how are you?||Can I ask a favour ple-'
        },
        {
            character: 'coffeeshop',
            text: 'Hey [CHARACTER], am I pleased to see you! It is so busy today, I need help to sort these customer drinks out. Are you free at all?',
        },
        /* {
            character: 'coffeeshop',
            text: 'Are you free at all?'
        }, */
        {
            character: 'you',
            text: 'Erm... well I only really came in to ask if you would mind giving me a reference for my CV at all...'
        },
        {
            character: 'coffeeshop',
            text: 'Yeah, sure! I could really do without this rush and then will be more than happy to write you a reference, of course!'
        },
        {
            character: 'you',
            text: 'Ok, no problem, I can help out.'
        },
        {
            character: 'coffeeshop',
            text: 'Really? You are a legend, thank you!||The machine has been on the blink and has started mixing several drinks together?!'
        },
        /* {
            character: 'coffeeshop',
            text: 'The machine has been on the blink and has started mixing several drinks together?!'
        }, */
        {
            character: 'coffeeshop',
            text: 'It\'d be great if you could make sure the drink colours are sorted into the same cup so we can serve the customers and beat the rush!'
        }
    ],

    afterTask: [
        {
            character: 'coffeeshop',
            text: 'Phew, thanks so much [CHARACTER], that\'s a lot of happy customers. Oh I almost forgot, let me sort that reference for you. Thanks again for your help.',

        },
        {
            character: 'you',
            text: 'Brilliant, thanks so much!||I\'ll see you at the weekend for my next shift.'
        },
        /* {
            character: 'coffeeshop',
            text: 'Cool, thanks again for your help.'
        } */
    ],

    taskFailed: [
        {
            character: 'coffeeshop',
            text: 'Hey, thanks for trying but the customers are still complaining about their drinks being mixed up! Try playing again to sort the drink faster.'
        }
    ]
}

export const HOTEL_DIALOG = {
    beforeTask: [
        {
            character: 'you',
            text: 'Hi Rowan! Taylor mentioned you had a big job and when I saw your van I wondered if I could give you a hand to help and get some valuable work experience.'
        },
        /* {
            character: 'you',
            text: 'It\'d be great to help out and also good to get some valuable work experience too!'
        }, */
        {
            character: 'hotel',
            text: 'Blimey, are you feeling okay?!         |          |Haha, I always need an extra hand. Let\'s go in if you\'re ready for a good day\'s work!'
        },
        /* {
            character: 'you',
            text: 'Let\'s go!'
        } */
    ],

    beforeTaskRevisit: [
        {
            character: 'hotel',
            text: 'Back again? You\'re keen!||I do have something else you could help with...'
        }
    ],

    afterTask: [
        {
            character: 'hotel',
            text: 'Thanks for all the help today kiddo!||Here\'s a little something for your hard work. Don\'t go showing it off!'
        }
    ],

    taskUnavailable: [
        {
            character: 'you',
            text: 'Hi Rowan!'
        },
        {
            character: 'hotel',
            text: 'Morning [CHARACTER]!'
        }
    ],

    taskFailed: [
        {
            character: 'hotel',
            text: 'Hey! Thanks for trying, buddy, but you took too many attempts to fix the problem, the pipes are still leaking! Try playing again to fix the leaks faster.'
        }
    ]
}

export const FOODBANK_DIALOG = {
    beforeTask: [
        {
            character: 'you',
            text: 'Hi, nice to meet you! My Skills Launchpad advisor has encouraged me to help with local charities, so, here I am! I was just wondering if you could do with some help?'
        },
        {
            character: 'foodbank',
            text: 'You couldn\'t have come at a better time!'
        },
        {
            character: 'foodbank',
            text: 'We have quite a few deliveries we need to get out today and one of our regulars, Carl, has managed to get stuck in a carpark! An extra set of hands is most welcomed.'
        },
        /* {
            character: 'foodbank',
            text: 'You couldn\'t have come at a better time.'
        },
        {
            character: 'foodbank',
            text: 'We have quite a few deliveries we need to get out today and unfortunately one of our regulars, Carl, has managed to get stuck in a carpark!'
        },
        {
            character: 'foodbank',
            text: 'The extra set of hands will definitely be appreciated.'
        }, */
        {
            character: 'foodbank',
            text: 'Could you please help me get these food parcels onto the trucks? It\'s really important we don\'t miss anything because we can\'t afford to have any waste here.'
        },
        {
            character: 'you',
            text: 'Certainly, where should I start?'
        },
        /* {
            character: 'foodbank',
            text: 'If you\'re able to head to the back, I\'ll get the parcels ready and sent down. You just have to make sure each of the vans are full before we dispatch them.'
        } */
    ],
    afterTask: [
        {
            character: 'foodbank',
            text: 'Thank you so much for all the help today, literally a lifesaver! I\'ve given you a sterling reference. If you ever want to help again just drop in!'
        },
        /* {
            character: 'foodbank',
            text: 'I\'ve given you a sterling reference, and please, if you ever want to help out again just drop in!'
        } */
    ],
    taskFailed: [
        {
            character: 'foodbank',
            text: 'Hi [CHARACTER], thanks for trying but you didn\'t manage to deliver enough food parcels. Try playing again to load more trucks full of food parcels.'
        }
    ]
}

export const BIZ_WEAR_DIALOG = {
    beforeTask: [
        /* {
            character: 'clothesshop',
            text: 'Well, hello there, how are you?'
        },
        {
            character: 'you',
            text: 'Hi, I\'m good, thanks.'
        }, */
        {
            character: 'clothesshop',
            text: 'Hello there, I\'m Charlie, how can I help you today?'
        },
        {
            character: 'you',
            text: 'Hi, well I have a job interview coming up and want to look my best for it. But I don\'t have any money at the moment.',
        },
        /* {
            character: 'clothesshop',
            text: 'Of course, it\'ll be my pleasure! We have all kinds of items, what kind of budget do you have?'
        },
        {
            character: 'you',
            text: 'Budget?'
        },
        {
            character: 'clothesshop',
            text: 'Yes, how much are you looking to spend?'
        },
        {
            character: 'you',
            text: 'Well, I don\'t have any money at the moment.'
        }, */
        {
            character: 'clothesshop',
            text: 'Ok, well that may be a little bit of a problem! How about you come back when you have some money and we can talk then?'
        }
    ],
    completeTask: [
        /* {
            character: 'clothesshop',
            text: 'Well, hello there, how are you?'
        },
        {
            character: 'you',
            text: 'Hi, I\'m good, thanks.'
        }, */
        {
            character: 'clothesshop',
            text: 'Hello there, I\'m Charlie, how can I help you today?'
        },
        {
            character: 'you',
            text: 'Hi, well I have a job interview coming up and want to look my best for it. If you can find me something within this budget, that would be perfect.'
        },
        /* {
            character: 'clothesshop',
            text: 'Of course, it\'ll be my pleasure! We have all kinds of items, what kind of budget do you have?'
        },
        {
            character: 'you',
            text: 'Budget?'
        },
        {
            character: 'clothesshop',
            text: 'Yes, how much are you looking to spend?'
        },
        {
            character: 'you',
            text: 'If you can find me something within this budget, that would be perfect.'
        }, */
        {
            character: 'clothesshop',
            text: 'I like a challenge!||We\'ll be able to find you something wonderful for your interview.'
        },
        {
            character: 'clothesshop',
            text: 'You\'ll look FANTASTIC!||You\'ll make such a good impression and they are going to love you!'
        },
        /* {
            character: 'clothesshop',
            text: 'You\'ll look so good, they won\'t even have to ask you any questions, they will hire you on the spot the moment they see you!'
        }, */
        /* {
            character: 'you',
            text: 'Wow, really?'
        },
        {
            character: 'clothesshop',
            text: 'Well, no, I can\'t guarantee that.'
        },
        {
            character: 'you',
            text: '...'
        }, */
        /* {
            character: 'clothesshop',
            text: 'I can, however, guarantee that you are going to look FANTASTIC! You\'ll make such a good impression and they are going to love you!'
        }, */
        {
            character: 'you',
            text: 'Great! Thank you for your help.||Can I change here when ready?'
        },
        {
            character: 'clothesshop',
            text: 'Hey, it\'s what I do.||And of course! Come back here when you want to change.'
        }
    ],
    beforeTaskRevisit: [
        {
            character: 'clothesshop',
            text: 'Well hello there!||I take it you have a budget to work with now?'
        },
        {
            character: 'you',
            text: '...'
        },
        {
            character: 'clothesshop',
            text: 'Hmmmmm, I need to serve this other customer right now, please come back when you have some money and we can help you then. Thanks!'
        }
    ],
    completeTaskRevisit: [
        {
            character: 'clothesshop',
            text: 'Well hello there!||I take it you have a budget to work with now?'
        },
        {
            character: 'you',
            text: 'If you can find me something within this budget, that would be perfect.'
        },
        /* {
            character: 'clothesshop',
            text: 'I like a challenge! Of course we\'ll be able to find you something wonderful for your interview.'
        },
        {
            character: 'clothesshop',
            text: 'You\'ll look so good, they won\'t even have to ask you any questions, they will hire you on the spot the moment they see you!'
        },
        {
            character: 'you',
            text: 'Wow, really?'
        },
        {
            character: 'clothesshop',
            text: 'Well, no, I can\'t guarantee that.'
        },
        {
            character: 'you',
            text: '...'
        },
        {
            character: 'clothesshop',
            text: 'I can, however, guarantee that you are going to look FANTASTIC! You\'ll make such a good impression and they are going to love you!'
        }, */
        {
            character: 'clothesshop',
            text: 'I like a challenge!||We\'ll be able to find you something wonderful for your interview.'
        },
        {
            character: 'clothesshop',
            text: 'There - you\'ll look FANTASTIC!||You\'ll make such a good impression and they are going to love you!'
        },
        {
            character: 'you',
            text: 'Great! Thank you for your help.||Can I change here when ready?'
        },
        {
            character: 'clothesshop',
            text: 'Hey, it\'s what I do.||Come back here when you want to change.'
        }
    ],
    afterTask: [
        {
            character: 'clothesshop',
            text: 'You\'re sure to make a great first impression in that suit!'
        }
    ]
}

export const HOME_DIALOG = {
    beforeTask: [
        {
            character: 'you',
            text: 'This is my home!||I should come back here and change into some smart clothes before I attend my interview.'
        }
    ],

    completeTask: [
        {
            character: 'you',
            text: 'I\'d better put on my formal clothes so I\'m ready for my interview!'
        },
        {
            character: 'you',
            text: 'Wow! I look fantastic! I\'ll surely do well in my interview now. I\'d better make my way to the Babcock office!',
        }
    ],

    afterTask: [
        {
            character: 'you',
            text: 'Wow! I look fantastic!||I\'ll surely do well in my interview now.',
        }
    ]
}

export const SKILLS_LAUNCHPAD_DIALOG = {
    beforeTask: [
        {
            character: 'advisor_nophone',
            text: 'Hi [CHARACTER], good to meet you! Thanks for coming in today. Let\'s start by answering a few quick questions and we\'ll see if we can find anything that suits you.'
        },
        /* {
            character: 'you',
            text: 'Hi, yeah good to meet you too. Thanks for taking time to help me find a job, I really appreciate it.'
        }, */
        /* {
            character: 'advisor_nophone',
            text: 'Well that\'s what we\'re here for! Let\'s start by making sure we have your up-to-date contact details so that we can pass these along with your applications.'
        }, */
    ],

    beforeTaskRevisit: [
        {
            character: 'advisor_nophone',
            text: 'Once you are happy with your CV we\'ll get your application for the position at Babcock sent off!'
        }
    ],

    afterContactForm: [
        {
            character: 'you',
            text: '*writing*                 |                 |*hands completed questionnaire back*'
        },
        /* {
            character: 'you',
            text: '*hands completed form back*'
        }, */
        {
            character: 'advisor_nophone',
            text: 'Fantastic, thanks for that.||Let\'s check to see if there are any opportunities suitable for you...'
        },
        /* {
            character: 'advisor_nophone',
            text: 'Hmmm...'
        },
        {
            character: 'advisor_nophone',
            text: '...'
        }, */
        {
            character: 'advisor_nophone',
            text: 'Aha!||There\'s a fantastic apprenticeship opportunity with ^Babcock~ - how does that sound?'
        },
        {
            character: 'you',
            text: 'That sounds great, what are the next steps?||I\'ve brought my CV but I\'m not sure if it\'s formatted correctly?'
        },
        {
            character: 'advisor_nophone',
            text: 'That\'s no problem at all, in fact, let\'s take a look together...'
        }
    ],

    afterTask: [
        {
            character: 'advisor_nophone',
            text: 'Once you are happy with your CV we\'ll get your application for the position at Babcock sent off!'
        }
    ],

    afterTask_cvReady: [
        {
            character: 'advisor_nophone',
            text: 'Hi [CHARACTER], it\'s nice to see you again.||Let\'s take a look at your CV.'
        }
    ]
}

export const INTRO_DIALOG_1 = [
    {
        character: 'radio',
        text: 'Good morning folks and welcome to Harbour Radio, we\'re here in b-e-a-utiful Plymouth! It\'s a glorious day out there, the sun is shining and the sea is looking simply stunning.'
    },
    /* {
        character: 'radio',
        text: 'Coming up we have your favourite tunes from the best artists as well as your Wednesday Well Wishes, messages you\'d like to send to your friends...'
    },
    {
        character: 'radio',
        text: '...family, loved ones, pets, the next door neighbours cat, all those people special to you!'
    },
        {
        character: 'radio',
        text: 'Get in touch to send them your heartfelt messages, all through the ageing medium of radio waves.'
    }, */
    {
        character: 'radio',
        text: 'Right, now we\'re about to play...                 |                 |\'Which famous celebrity does your pet sound like!\''
    },
    {
        character: 'you',
        text: 'Why do I listen to this radio station? I need to find another podcast!'
    }
]

export const INTRO_DIALOG_2 = [
    {
        character: 'advisor',
        text: 'Hi [CHARACTER], how\'re you? I\'m just calling to check if you are still ok for our meeting this morning to look at some jobs you might be interested in and take a look at your CV too?'
    },
    /* {
        character: 'you',
        text: 'Hey, I\'m great thanks. You?'
    },
    {
        character: 'advisor',
        text: 'Good, thank you!'
    }, */
    /* {
        character: 'advisor',
        text: 'I\'m just calling to check you are still ok for our meeting this morning for us to review your CV, and also take a look at some job opportunities that you may be interested in?'
    }, */
    {
        character: 'you',
        text: 'Good thanks.||Yes, I\'ve been looking forward to it!'
    },
    {
        character: 'advisor',
        text: 'Brilliant, well if you want to make your way to the ^Skills Launchpad~ office we can get started. See you soon. Bye.'
    },
    /* {
        character: 'advisor',
        text: 'We\'ll have a quick chat through what you enjoy, your passions and interests, and see what jobs will suit you. How does that sound?'
    },
    {
        character: 'you',
        text: 'That sounds amazing, I\'ll see you there shortly.'
    },
    {
        character: 'advisor',
        text: 'Ok, see you soon. Bye.'
    }, */
    {
        character: 'you',
        text: 'I\'ll see you there shortly. Bye!'
    }
]

export const CV_READY_DIALOG = [
    {
        character: 'advisor',
        text: 'Looks like you\'re ready to submit the application. Pop back to the Skills Launchpad office and we\'ll get it sent off.'
    },
]

export const CV_READY_DIALOG_AT_OFFICE = [
    {
        character: 'advisor_nophone',
        text: 'Wow look at this CV! Looks like you\'re ready to submit the application, or you could keep going to strengthen your CV even more!'
    },
    {
        character: 'advisor_nophone',
        text: 'Are you ready to send your CV now?'
    }
]

export const DONT_SEND_CV_DIALOG = [
    {
        character: 'you',
        text: 'Not quite yet, still exploring!'
    },
    {
        character: 'advisor',
        text: 'No problem, we can catch up again later!'
    }
]

export const SEND_CV_DIALOG = [
    {
        character: 'you',
        text: 'I\'m a bit nervous but here we go...'
    },
    {
        character: 'you',
        text: 'Sent!'
    },
    {
        character: 'advisor',
        text: 'Excellent! Good luck with it, all and if you ever need help with something like this again you know where to find us!'
    }
]

export const INTERVIEW_CALL_DIALOG = [
    {
        character: 'interviewer',
        text: 'Thanks for submitting your application. We\'d love it if you could come in for an interview this afternoon.'
    },
    {
        character: 'you',
        text: 'That was quick!                 |                 |Wait, this afternoon?! I need to get some new clothes ready for the interview.'
    }
]

export const INTERVIEW_CALL_DIALOG_GOT_CLOTHES = [
    {
        character: 'interviewer',
        text: 'Thanks for submitting your application. We\'d love it if you could come in for an interview this afternoon.'
    },
    {
        character: 'you',
        text: 'That was quick!                 |                 |Wait, this afternoon?! I need to go and change into my formal clothes, ready for my Interview.'
    }
]

export const BABCOCK_OFFICE_DIALOG = {
    beforeTask: [
        {
            character: 'you',
            text: 'The Babcock office.||This is where my interview will be!'
        }
    ],
    completeTask: [
        {
            character: 'you',
            text: 'This is it - interview time!||Wish me luck.'
        }
    ]
}

export const CV_TUTORIAL_DIALOG_1 = [
    {
        character: 'advisor_nophone',
        text: 'A great start but seems like you\'re missing some components. I have some ideas on how we can help you stand apart from other applicants.',
        // 'This is a great start but seems like you\'re missing some components, and I have some ideas on how we can help you really stand apart from other applicants.'
    },
    {
        character: 'advisor_nophone',
        text: 'Certain attributes will be more desirable to employers, so it is important we highlight these.',
        // 'This is a great start but seems like you\'re missing some components, and I have some ideas on how we can help you really stand apart from other applicants.'
    },
    /* {
        character: 'advisor_nophone',
        text: 'For example, certain attributes will be more desirable to employers, so it is really important we highlight these.'
    } */
]

export const CV_TUTORIAL_DIALOG_2 = [
    {
        character: 'advisor_nophone',
        text: 'There are ^three main areas~ you need to complete here at the top. Complete these before you submit your application for the job with Babcock.'
    },
    /* {
        character: 'advisor_nophone',
        text: 'You\'ll see there are three main areas you really need to complete here at the top.'
    },
    {
        character: 'advisor_nophone',
        text: 'These are your main sections to complete before you can submit your application for an apprenticeship with Babcock.'
    },*/
    {
        character: 'advisor_nophone',
        text: 'You need to gain a workplace reference, get involved in some voluntary work and we can also see if there are any more work experience opportunities available too.'
    },
    {
        character: 'advisor_nophone',
        text: 'The more of these experiences you are able to add to your CV, the more you will stand out.'
    },
    /* {
        character: 'advisor_nophone',
        text: 'The more of these experiences you are able to add to your CV, the more you will stand out.'
    }, */
]

export const CV_TUTORIAL_DIALOG_3 = [
    {
        character: 'advisor_nophone',
        text: 'Employers love to see a person who is growing in different areas of their lives and making a difference to the people around them.'
    },
    {
        character: 'advisor_nophone',
        text: '^Complete side tasks~ to grow in these areas ^to add badges~ to your CV.'
    },
    /* {
        character: 'advisor_nophone',
        text: 'Throughout this experience there will be many opportunities to grow in these areas and earn these Skills Builder badges to add to your CV, so keep a look out for side tasks.'
    }, */
]

export const CV_TUTORIAL_DIALOG_4 = [
    {
        character: 'advisor_nophone',
        text: 'We want to know about you, what you do in your spare time, how you like to relax, what things interest you! These can be anything from reading to sports, movies or making music.'
    },
    /* {
        character: 'advisor_nophone',
        text: 'Work isn\'t just about being a robot! It\'s great to talk about what you like to do in your spare time, how you like to relax and recoup, to discuss what things interest you!'
    },
    {
        character: 'advisor_nophone',
        text: 'These can be anything from reading, to sports, movies, or making music.'
    },*/
    {
        character: 'advisor_nophone',
        text: 'I\'ve heard you have a real flair for photography, so to complete this task you\'ll need to ^take selfies at five popular locations~ around Plymouth!'
    },
]

export const CV_TUTORIAL_DIALOG_5 = [
    {
        character: 'advisor_nophone',
        text: 'You can keep a check on how you\'re progressing through the experience by your ^CV star rating~ and ^progress bar~.'
    },
    {
        character: 'advisor_nophone',
        text: 'Complete the three main CV sections ^to earn stars~. You can see how far you\'ve got to go by your overall percentage too!'
    },
    {
        character: 'advisor_nophone',
        text: 'It\'s up to you which order you want to complete your CV. Earn all five stars or the just main three experience stars, it\'s up to you!'
    },
    {
        character: 'advisor_nophone',
        text: 'Go explore Plymouth and I\'ll check in with you to see how you\'re doing - Good Luck!'
    }
]