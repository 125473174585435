import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import game from '../../../controller/GameController';
import { au, v } from '../../../koko-framework/shorthand';
import AdvisorIcon from '../../../assets/babcock_menus/in_game/dialog/advisor_dialogue.png';
import DialogArrow from '../../../assets/babcock_menus/in_game/dialog/dialogue_scroll.png';
import PositionableReactView from "../../../koko-framework/ui/react-views/PositionableReactView";
import { PANEL_STATE } from "../../../Bootstrap";
import WorldController from "../../../controller/WorldController";
import { CHANGE_CLOTHES_TASK, getDialogDataFromId, getEntityDataFromId, NEIGHBOUR_NPC } from "../../model/constants";
import { GameState } from "../../model/GameState";
import BabcockController from "../../controller/BabcockController";
import SendCvButton from '../../../assets/babcock_menus/prompts/accept_btn.png';
import CloseButton from '../../../assets/babcock_menus/close_btn.png';
import CvIcon from '../../../assets/babcock_menus/in_game/dialog/CV_dialogue.png';
import { DONT_SEND_CV_DIALOG, SEND_CV_DIALOG } from "../../model/dialog";

export default class InstructionPrompt extends ReactFullScreenView {
    typeId = -1;
    canClickTimeoutId = -1;

    constructor(props) {
        super(props);

        // Fix this panel to the bottom middle. Our css class bottom-panels-bg positions the panel relative to that.
        this.state.left = '50%';
        this.state.top = '100%';
        this.state.promptMessages = [];
        for (let i = 0; i < PANEL_STATE.promptMessages.length; i++) {
            this.state.promptMessages.push(PANEL_STATE.promptMessages[i]);
        }
        this.state.promptImages = [];
        for (let i = 0; i < PANEL_STATE.promptImages.length; i++) {
            this.state.promptImages.push(PANEL_STATE.promptImages[i]);
        }
        this.state.promptArias = [];
        for (let i = 0; i < PANEL_STATE.promptArias.length; i++) {
            this.state.promptArias.push(PANEL_STATE.promptArias[i]);
        }
        this.state.currentPrompt = this.state.promptMessages.shift();
        this.state.currentPromptImage = this.state.promptImages.shift();
        this.state.currentPromptAria = this.state.promptArias.shift();
        this.state.canClick = false;

        this.resize();
    }

    componentDidMount() {
        super.componentDidMount();

        v.showView('bottomgrad3');

        au.play('tutorial', 1, false, 0, false);

        this.delayClick();

        // BabcockController.pauseCharacter();

        v.get('hudview').fade();
    }

    closePanel = () => {
        v.hideView(this.props.viewId);
        WorldController.enable();
        BabcockController.dialogCompleted();

        v.hideView('bottomgrad3');

        // BabcockController.unpauseCharacter();

        v.get('hudview').fade(1);
    }

    nextPrompt = () => {
        if (this.state.canClick && this.state.visible) {
            au.play('primarybtn', 1, false, 0, true);

            if (this.state.promptMessages.length) {
                let prompts = this.state.promptMessages;
                let nextPrompt = prompts.shift();
                let promptImages = this.state.promptImages;
                let nextPromptImage = promptImages.shift();
                let promptArias = this.state.promptArias;
                let nextPromptAria = promptArias.shift();
                this.setState({
                    currentPrompt: nextPrompt,
                    currentPromptImage: nextPromptImage,
                    currentPromptAria: nextPromptAria,
                    promptMessages: prompts,
                    canClick: false,
                });
                this.delayClick();
            } else {
                this.closePanel();
            }
        }
    }

    delayClick = () => {
        // if (this.state.canClick === false) {
            clearTimeout(this.canClickTimeoutId);
            this.canClickTimeoutId = setTimeout(() => this.setState({canClick: true}), 400);
        // }
    }

    renderContent() {
        let finalPromptText = this.state.currentPrompt.split('|').join('<br />');

        let boldSplit = finalPromptText.split('^');
        finalPromptText = boldSplit.join('<b>');
        let boldCloseSplit = finalPromptText.split('~');
        finalPromptText = boldCloseSplit.join('</b>');
        if (boldSplit.length > boldCloseSplit.length) {
            finalPromptText += '</b>';
        }

        return (
            <div className="full-screen-holder no-select default-cursor rel">
                <div className="prompt-holder no-select default-cursor flex-rows" style={{ left: this.state.left, top: this.state.top }}>
                
                    <div className="view-row grow"></div>
                        <div className="prompt-panel view-row grow centered flex-columns">
                            <div className="shrink">
                                <img className="prompt-img" src={this.state.currentPromptImage} alt={this.state.currentPromptAria} />
                            </div>
                            <div className="grow">
                                <div className="flex-rows" style={{height: '100%'}}>
                                    <div className="view-row grow"></div>
                                    <div className="view-row shrink">
                                        <p className="prompt-text" dangerouslySetInnerHTML={{__html: finalPromptText}}></p>
                                    </div>
                                    <div className="view-row grow"></div>
                                    <div className="view-row grow"></div>
                                </div>
                            </div>
                        </div>
                        <div className="view-row grow">
                            <img className="cv-prompt-button active-button" src={SendCvButton} onClick={this.nextPrompt} alt="Click to continue" />
                        </div>
                    <div className="view-row grow"></div>
                </div>
            </div>

        );
    }
}