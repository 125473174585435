import { InGameEntity } from "../../koko-framework/tile-engine/entities/InGameEntity";
import { FRAME_TIME, FRAME_DELTA_LIMIT } from "../../model/config";

export class FlyAwayBird extends InGameEntity {
    removeOriginalTile = false;
    updateable = false;

    flyingOff = false;
    xSpeed = 0;
    ySpeed = 0;

    addToGame(startSquare, container, xOffset = 0, yOffset = 0) {
        super.addToGame(startSquare, container, xOffset, yOffset);

        // remove ourself from our container
        // we will add ourself back and remove the original tile when another entity approaches
        // then remove ourself again and add the original tile back when both ourself and the start square are off screen
        container.removeChild(this.characterSprite);
        
        // console.log('FlyAwayBird added: ', this);
    }

    otherCharacterNearBy(otherCharactersArray) {
        // console.log('Entity sensed nearby');
        if (this.inView === true && !this.flyingOff) {
            let flyNow = false;
            for (let i = 0; i < otherCharactersArray.length; i++) {
                if (otherCharactersArray[i].npcId === 'main_character') {
                    flyNow = true;
                }
            }
            if (flyNow) {
                this.flyOffNow();
            }
        }
    }

    leftView() {
        if (this.flyingOff) {
            super.leftView();
            this.characterSprite.x = -1000;
            this.container.removeChild(this.characterSprite);
            this.updateable = false;
            if (this.startSquareInView === false) {
                this.replaceOriginalTile();
            }
        }
    }

    startSquareLeftView() {
        super.startSquareLeftView();
        if (this.inView === false) {
            this.replaceOriginalTile();
        }
    }

    flyOffNow = () => {
        this.xSpeed = Math.random() * 5 * this.originalTile.scale.x * -1;
        this.ySpeed = -6 - Math.random() * 3;
        this.characterSprite.scale.x = this.xSpeed > 0 ? -1 : 1;
        this.flyingOff = true;
        this.container.addChild(this.characterSprite);
        this.characterSprite.x = this.originalTile.x;
        this.characterSprite.y = this.originalTile.y;
        this.originalTile.originalParent = this.originalTile.parent;
        this.originalTile.parent.removeChild(this.originalTile);
        this.updateable = true;
        this.playAnim();
    }

    replaceOriginalTile = () => {
        if (this.flyingOff) {
            this.originalTile.originalParent.addChild(this.originalTile);
            this.characterSprite.x = this.originalTile.x;
            this.characterSprite.y = this.originalTile.y;
            this.originalTile.visible = false;
            this.flyingOff = false;
        }
    }

    update(delta) {
        if (this.flyingOff && this.characterSprite.parent) {
            let frameDeltaRatio = 1;
            frameDeltaRatio = delta / FRAME_TIME;
            if (frameDeltaRatio > FRAME_DELTA_LIMIT) {
                frameDeltaRatio = FRAME_DELTA_LIMIT;
            }
            this.characterSprite.x += this.xSpeed * frameDeltaRatio;
            this.characterSprite.y += this.ySpeed * frameDeltaRatio;
        }
    }
}