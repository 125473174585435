/**
 * es6 module version of koko framework
 * @author Carl Trelfa
 * @version 1.0.0
 */

import { timer } from './timer'
import { clipManager } from './clipManager'
import client from './client'
import assets from './assets'
import audio from './audio'
import display from './display'
import math from './math'
import { view, views } from './view'
import cookies from './cookies'
import * as physics from './physics'
import objectPooler from './objectPooler'
import { fb, tw, lin } from './social'

const koko = {
    timer: timer,
    clipManager: clipManager,
    client: client,
    assets: assets,
    audio: audio,
    display: display,
    math: math,
    view: view,
    views: views,
    cookies: cookies,
    physics: physics,
    objectPooler: objectPooler,
    social: {
        fb: fb,
        tw: tw,
        lin: lin,
    },
}

export { koko as default };