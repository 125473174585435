import gsap from 'gsap'
import koko from '../../koko-framework/koko'
import app from '../../koko-framework/app'
import { au, v } from '../../koko-framework/shorthand';
import * as PIXI from 'pixi.js'
import { setupClicker } from '../../koko-framework/ui/ButtonController'
import WorldController from '../../controller/WorldController';
import { GameState, GetCvStarsEarned } from '../model/GameState';
import * as BABCOCK_CONSTANTS from '../model/constants';

export class GreenBg extends koko.view {
    buildView() {
        var g = app.guiSize, vp = app.viewPort;

        this.children = koko.display.buildLayout([
            { name:'greenbg', type:'shape', x: 0, y: 0, width: g.width, height: g.height, fill: 0xcbd366 },
        ], this.container);

        this.resize();
    }

    transitionIn (){
        this.buildView();
        this.show();
        this.enable();
        this.container.alpha = 0;
        gsap.to(this.container, {alpha:1, duration: 0.35});

        app.addResizeListener('gbg', function(){
            this.resize();
        }.bind(this));
    }

    transitionOut (){
        gsap.to(this.container, {alpha: 0, onComplete: this.transitionOutDone, /*onCompleteScope:this,*/ duration: 0.35});

        app.removeResizeListener('gbg');
    }

    transitionOutDone = () => {
        this.disable();
        this.destroyView();
        this.hide();
    }

    resize = () => {
        let viewRect = new PIXI.Rectangle(app.viewPort.left, app.viewPort.top, app.viewPort.width, app.viewPort.height);
        this.children.greenbg.x = viewRect.x;
        this.children.greenbg.y = viewRect.y;
        this.children.greenbg.width = viewRect.width;
        this.children.greenbg.height = viewRect.height;
    }
}