import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import game from '../../../controller/GameController';
import { au, v } from '../../../koko-framework/shorthand';
import WorldController from "../../../controller/WorldController";

import BabcockLogo from '../../../assets/babcock_menus/main_menu/babcock_logo.png';
import PlymouthLogo from '../../../assets/babcock_menus/main_menu/plymouth_logo.png';

import CharacterThumb from '../../../assets/babcock_menus/game_over/character_thumbailTemp.png';
import CharacterThumb1 from '../../../assets/babcock_menus/game_over/character_thumbail1.png';
import CharacterThumb2 from '../../../assets/babcock_menus/game_over/character_thumbail2.png';
import CharacterThumb3 from '../../../assets/babcock_menus/game_over/character_thumbail3.png';
import CharacterThumb4 from '../../../assets/babcock_menus/game_over/character_thumbail4.png';
import CharacterThumb5 from '../../../assets/babcock_menus/game_over/character_thumbail5.png';
import CongratsBanner from '../../../assets/babcock_menus/game_over/congratulations.png';

import CTA from '../../../assets/babcock_menus/gameOver_apprenticeshipBtn.png';
import visitSLSite from '../../../assets/babcock_menus/skillsLaunchpad_Btn.png';

import PlayAgainButton from '../../../assets/babcock_menus/game_over/playagain_btn.png';
import ShareButton from '../../../assets/babcock_menus/settings/share_btn.png';
import SoundOffButton from '../../../assets/babcock_menus/sound_off.png';
import SoundOnButton from '../../../assets/babcock_menus/sound_on.png';
import { GameState } from "../../model/GameState";

import { MuteButton } from "./components/mute-button";

import ReactGA from 'react-ga4';

export default class MainGameOver extends ReactFullScreenView {
    componentDidMount() {
        super.componentDidMount();

        ReactGA.send({ hitType: "pageview", page: "/main-game-over/" });
    }

    closePanel = () => {
        v.hideView(this.props.viewId);
        // WorldController.enable();

        au.play('closebtn', 1, false, 0, false);
        au.stop('babcock_ingame_music');

        v.showView('mainmenu');
    }

    showCtaInfo = () => {
        v.showView('ctainfo');

        au.play('secondarybtn', 1, false, 0, false);
    }

    showSharePanel = () => {
        v.showView('sharepanel');
    }

    renderContent() {
        let charThumbs = [CharacterThumb1, CharacterThumb2, CharacterThumb3, CharacterThumb4, CharacterThumb5];
        return (
            <div className="full-screen-holder main-game-over-bg no-select default-cursor">
                <div className="view-row shrink">
                    <div className="shrink left">
                        <img className="babcock-menu-logo" src={BabcockLogo} alt="Babcock logo" />
                    </div>
                    <div className="grow centered">
                    </div>
                    <div className="shrink right">
                        <img className="plymouth-menu-logo" src={PlymouthLogo} alt="Plymouth City Council logo" />
                    </div>
                </div>

                <div className="view-row grow"></div>

                <div className="view-row main-game-over-head rel">
                    <img className="main-game-over-avatar" src={charThumbs[GameState.selectedCharacter]} alt="Character Portrait" />
                    <img className="mgo-congrats-banner" src={CongratsBanner} alt="Congratulations on completing the game" />
                </div>

                <div className="view-row grow flex-rows">
                    <div className="shrink main-go-blurb centered">
                        <p>If you have or are predicted to have</p>
                    </div>
                    <div className="shrink main-go-blurb bold-text centered">
                        <p>GCSE's (or equivalent) grades 4 - 9<br />or A - C in English, Maths and a Science</p>
                    </div>
                    <div className="shrink main-go-blurb centered">
                        <p>and are interested in an apprenticeship with<br />Babcock, find out more below!</p>
                    </div>
                </div>

                <div className="view-row shrink">
                    <img className="gameover-cta active-button" src={CTA} onClick={this.showCtaInfo} alt="Click to find out more about Babcock Apprenticeships" />
                </div>

                <div className="view-row shrink">
                    <div className="flex-columns maingo-button-group">
                        <div className="grow">
                            <MuteButton settings={true}></MuteButton>
                        </div>
                        
                        <div className="grow">
                            <img className="settings-share-button active-button" src={ShareButton} onClick={this.showSharePanel} alt="Click to Share on Social Media" />
                        </div>
                        
                        <div className="grow">
                            <img className="settings-quit-button active-button" src={PlayAgainButton} onClick={this.closePanel} alt="Click to play again" />
                        </div>
                    </div>
                </div>

                <div className="view-row shrink">
                    <div className="flex-columns grow">
                        <div className="grow"></div>
                        <div className="shrink">
                            <a href="https://www.skillslaunchpadplym.co.uk/" target="_blank">
                                <img className="gameover-visitsl active-button" src={visitSLSite} alt="Click to visit the Skills Launchpad website" />
                            </a>
                        </div>
                        <div className="grow"></div>
                    </div>
                </div>

                <div className="view-row grow"></div>

                <div className="view-row shrink main-game-over-strip-bg centered">
                    <p className="main-go-blurb-small">
                        If you're unsure of what you want your next steps to be, or you are not predicted to achieve the above qualifications, please speak to a Skills Launchpad advisor or your Career Advisor to discuss further opportunities with Babcock and other local businesses.
                    </p>
                </div>

                <div className="view-row grow"></div>

                <div className="view-row shrink">
                    <div className="grow centered">
                        <p className="copyright" aria-label="Copyright 2024 Babcock International Group PLC">&#169; 2024 Babcock International Group PLC</p>
                    </div>
                </div>
            </div>
        )
    }
}