import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";

export default class BlackFade extends ReactFullScreenView {
    render() {
        let fadeInClass = this.state.visible ? ' fade-in' : ''
        return (
            <div className={'view-holder over-canvas' + fadeInClass + ' slow-fade'} style={{transformOrigin: '0 0', transform:'scale(' + this.state.scale + ')', left: this.state.zeroLeft, top: this.state.zeroTop, width: this.state.width, height: this.state.height}}>
                {this.renderContent()}
            </div>
        );
    }

    renderContent() {
        return (
            <div className="full-screen-holder no-select default-cursor black-bg">
            </div>
        )
    }
}