import React from 'react'
import app from '../../app'

/**
 * @author Carl Trelfa
 * All our react based views extend from this, so they use a common state, resize function etc. 
 */
export default class BaseReactView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            zeroLeft: 0,
            zeroTop: 0,
            left: 0,
            top: 0,
            scale: 1,
            anchorCenter: false,
            width: 0,
            height: 0,
            visible: false,
            transitioning: false,
        }

        this.resize();
    }

    resize = () => {
        let vp = app.viewPort;
        let g = app.guiSize;

        let left = (window.innerWidth / 2) - (g.width / 2 * vp.s);
        if (left < 0) {
            left = 0;
        }
        let top = (window.innerHeight / 2) - (g.height / 2 * vp.s);
        if (top < 0) {
            top = 0;
        }

        this.setState(
            {
                zeroLeft: left,
                zeroTop: top,
                scale: vp.s,
                width: vp.width,
                height: vp.height,
            }
        )
    }

    transitionIn = () => {
        if (!this.state.transitioning) {
            this.setState({visible: true, transitioning: true});
            setTimeout(() => this.setState({transitioning: false}), 400);
        }
    }

    transitionOut = () => {
        this.setState({visible: false, transitioning: true});
        setTimeout(() => {this.props.viewController.viewHidden(this.props.viewId)}, 400);
    }

    componentDidMount() {
        app.addResizeListener(this.props.viewId, this.resize);
        setTimeout(this.transitionIn, 10);
        this.props.viewController.viewMounted(this.props.viewId, this);
    }

    componentWillUnmount() {
        app.removeResizeListener(this.props.viewId);
    }
}