import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import game from '../../../controller/GameController';
import { au, v } from '../../../koko-framework/shorthand';
import BackButton from '../../../assets/babcock_menus/select_character/back_btn.png';
import LeftArrow from '../../../assets/babcock_menus/select_character/arrow_left.png';
import RightArrow from '../../../assets/babcock_menus/select_character/arrow_right.png';
import Char1 from '../../../assets/babcock_menus/select_character/mc_1.png';
import Char2 from '../../../assets/babcock_menus/select_character/mc_2.png';
import Char3 from '../../../assets/babcock_menus/select_character/mc_3.png';
import Char4 from '../../../assets/babcock_menus/select_character/mc_4.png';
import Char5 from '../../../assets/babcock_menus/select_character/mc_5.png';
import ActivePippet from '../../../assets/babcock_menus/select_character/pipet_active.png';
import InactivePippet from '../../../assets/babcock_menus/select_character/pipet_inactive.png';
import SelectButton from '../../../assets/babcock_menus/select_character/select_btn.png';
import { GameState } from "../../model/GameState";
import WorldController from "../../../controller/WorldController";
import { CHARACTER_ORDER } from "../../model/constants";

import ReactGA from 'react-ga4';

const CHAR_SPACING = 800;
const NUMBER_OF_CHARACTERS = 5;

export default class SelectCharacter extends ReactFullScreenView {
    constructor() {
        super();

        this.state.currentChar = 0;
    }

    componentDidMount() {
        super.componentDidMount();

        ReactGA.send({ hitType: "pageview", page: "/select-character/" });
    }

    prevChar = () => {
        let char = this.state.currentChar;
        char--;
        if (char >= 0) {
            this.setState({currentChar: char});
        }

        au.play('secondarybtn', 1, false, 0, true);
    }

    nextChar = () => {
        let char = this.state.currentChar;
        char++;
        if (char < NUMBER_OF_CHARACTERS) {
            this.setState({currentChar: char});
        }

        au.play('secondarybtn', 1, false, 0, true);
    }

    backClicked = () => {
        v.showView('mainmenu');
        v.hideView('selectcharacter');

        au.play('secondarybtn', 1, false, 0, false);
    }

    selectCharClicked = () => {
        GameState.selectedCharacter = this.state.currentChar;
        WorldController.maincharacterSpriteData = CHARACTER_ORDER[this.state.currentChar];
        v.showView('nameentry');
        v.hideView('selectcharacter');
        v.showView('blackfade');

        au.play('primarybtn', 1, false, 0, false);

        ReactGA.event({
            category: "UI Action",
            action: "Character Selected",
            label: this.state.currentChar,
        });
    }

    renderContent() {
        let fadeLeftButton = this.state.currentChar === 0;
        let fadeRightButton = this.state.currentChar === NUMBER_OF_CHARACTERS -1;
        return (
            <div className="full-screen-holder gradient-bg no-select default-cursor">
                <div className="view-row shrink">
                    <div className="shrink left">
                        <img className="select-char-back-button active-button" src={BackButton} onClick={this.backClicked} alt="Click to go back to main menu" />
                    </div>
                </div>
                <div className="view-row grow">
                    <p className="select-char-header bold-text">Select Character</p>
                </div>
                <div className="view-row grow char-container rel">
                    <div className="char-scroll-container">
                        <div className="characters rel" style={{left: -CHAR_SPACING * this.state.currentChar}}>
                            <div className="one-char" style={{left: 0}}>
                                <img className="scaled-char" src={Char1} alt={this.state.currentChar === 0 ? "Character 1" : ""} />
                            </div>
                            <div className="one-char" style={{left: CHAR_SPACING * 1}}>
                                <img className="scaled-char" src={Char2} alt={this.state.currentChar === 1 ? "Character 2" : ""} />
                            </div>
                            <div className="one-char" style={{left: CHAR_SPACING * 2}}>
                                <img className="scaled-char-3" src={Char3} alt={this.state.currentChar === 2 ? "Character 3" : ""} />
                            </div>
                            <div className="one-char" style={{left: CHAR_SPACING * 3}}>
                                <img className="scaled-char" src={Char4} alt={this.state.currentChar === 3 ? "Character 4" : ""} />
                            </div>
                            <div className="one-char" style={{left: CHAR_SPACING * 4}}>
                                <img className="scaled-char" src={Char5} alt={this.state.currentChar === 4 ? "Character 5" : ""} />
                            </div>
                        </div>
                    </div>
                    <div className="char-left-arrow">
                        <img className={"char-scroll-arrow active-button" + (fadeLeftButton ? " half-fade" : '')} src={LeftArrow} onClick={this.prevChar} alt="Click for previous character" />
                    </div>
                    <div className="char-right-arrow">
                        <img className={"char-scroll-arrow active-button" + (fadeRightButton ? " half-fade" : '')} src={RightArrow} onClick={this.nextChar} alt="Click for next character" />
                    </div>
                </div>
                <div className="view-row grow">
                    <div className="grow centered">
                        <img className="char-pippet" src={this.state.currentChar === 0 ? ActivePippet : InactivePippet} alt="" />
                        <img className="char-pippet" src={this.state.currentChar === 1 ? ActivePippet : InactivePippet} alt="" />
                        <img className="char-pippet" src={this.state.currentChar === 2 ? ActivePippet : InactivePippet} alt="" />
                        <img className="char-pippet" src={this.state.currentChar === 3 ? ActivePippet : InactivePippet} alt="" />
                        <img className="char-pippet" src={this.state.currentChar === 4 ? ActivePippet : InactivePippet} alt="" />
                    </div>
                </div>
                <div className="view-row shrink">
                    <div className="grow centered">
                        <img className="select-char-button active-button" src={SelectButton} onClick={this.selectCharClicked} alt="Click to choose this character" />
                    </div>
                </div>
            </div>
        );
    }
}