import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import { au, v } from '../../../koko-framework/shorthand';

import FormTickBox from "../../../assets/babcock_menus/details_form/formTickBox.png";
import FormTick from "../../../assets/babcock_menus/details_form/formTick.png";

import SubmitButton from "../../../assets/babcock_menus/details_form/submitBtn.png";
import { GameState } from "../../model/GameState";
import BabcockController from "../../controller/BabcockController";
import { SKILLS_LAUNCHPAD_DIALOG } from "../../model/dialog";
import ApiController from "../../controller/ApiController";

import ReactGA from 'react-ga4';

export default class MoreDetailsForm extends ReactFullScreenView {
    constructor(props) {
        super(props);

        this.state.name = GameState.name;
        this.state.lastName = GameState.lastName;
        this.state.email = GameState.email;
        this.state.age = GameState.age;
        // this.state.phone = GameState.phone;

        this.state.privacyTick = false;
        this.state.termsTick = false;
        this.state.marketingTick = false;

        this.state.emailValid = true;
        this.state.ageValid = true;
        // this.state.phoneValid = true;
        this.state.nameValid = true;
        this.state.lastNameValid = true;
    }

    componentDidMount() {
        super.componentDidMount();

        ReactGA.send({ hitType: "pageview", page: "/details-form/" });
    }

    emailUpdated = () => {
        let email = window.document.getElementById('email_input').value;
        this.setState({email: email});
        this.validateEmail(email);
    }

    validateEmail = (emailInput = null) => {
        let email = emailInput || this.state.email;
        let res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let emailValid = res.test(String(email).toLowerCase());
        this.setState({emailValid: emailValid});
        return emailValid;
    }

    ageUpdated = () => {
        let age = window.document.getElementById('age_input').value;
        this.setState({age: age});
        this.validateAge(age);
    }

    validateAge = (ageInput = null) => {
        let age = ageInput || this.state.age;
        // console.log('--- VALIDATE AGE ---', age);
        let ageValid = age.length === 2 && Number(age) !== NaN && age.split('.').length === 1 && Number(age) >= 13 && Number(age) <= 99;
        if (ageValid) {
            let ageSplit = age.split('');
            // console.log('AGE DIGITS: ', ageSplit);
            let validDigits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
            for (let i = 0; i < ageSplit.length; i++) {
                if (i == 0 && ageSplit[i] === '0') {
                    ageValid = false;
                    break;
                }
                if (validDigits.indexOf(ageSplit[i]) === -1) {
                    ageValid = false;
                    break;
                }
            }
        }
        this.setState({ageValid: ageValid});
        // console.log('AGE VALID: ', ageValid);
        return ageValid;
    }

    phoneUpdated = () => {
        let phone = window.document.getElementById('phone_input').value;
        this.setState({phone: phone});
        this.validatePhone(phone);
    }

    validatePhone = (phoneInput = null) => {
        let phone = phoneInput || this.state.phone;
        let phoneValid = phone.length >= 5 && Number(phone) !== NaN && phone.split('.').length === 1;
        this.setState({phoneValid: phoneValid});
        return phoneValid;
    }

    nameUpdated = () => {
        let name = window.document.getElementById('name_input').value;
        this.setState({name: name});
        this.validateName(name);
    }

    validateName = (nameInput = null) => {
        let name = nameInput || this.state.name;
        let nameValid = name.length >= 2;
        this.setState({nameValid: nameValid});
        return nameValid;
    }

    lastNameUpdated = () => {
        let lastName = window.document.getElementById('lastname_input').value;
        this.setState({lastName: lastName});
        this.validateLastName(lastName);
    }

    validateLastName = (nameInput = null) => {
        let name = nameInput || this.state.lastName;
        let nameValid = name.length >= 3;
        this.setState({lastNameValid: nameValid});
        return nameValid;
    }

    togglePrivacyCheck = () => {
        this.setState({privacyTick: !this.state.privacyTick});
    }

    toggleTermsCheck = () => {
        this.setState({termsTick: !this.state.termsTick});
    }

    toggleMarketingCheck = () => {
        this.setState({marketingTick: !this.state.marketingTick});
    }

    validateForm = () => {
        return this.validateEmail() && this.validateAge() && this.validateName() && this.validateLastName() && this.state.privacyTick && this.state.termsTick;
    }

    submitForm = () => {
        if (this.validateForm()) {
            GameState.name = this.state.name;
            GameState.email = this.state.email;
            GameState.lastName = this.state.lastName;
            GameState.age = this.state.age;
            GameState.privacyAgreed = this.state.privacyTick ? '1' : '0';
            GameState.termsAgreed = this.state.termsTick ? '1' : '0';
            GameState.marketingOptIn = this.state.marketingTick ? '1' : '0';
            
            ApiController.submitContactData();

            v.hideView(this.props.viewId);
            au.play('primarybtn', 1, false, 0, false);

            BabcockController.showInteractionDialog('Contact form submitted', SKILLS_LAUNCHPAD_DIALOG.afterContactForm, 'cvview');
            if (!GameState.firstPlay) {
                v.showView('skiptutorial');
            }
        }
    }

    renderContent() {
        let canSubmit = this.state.email !== '' && this.state.emailValid 
                        && this.state.age !== '' && this.state.ageValid
                        && this.state.name !== '' && this.state.nameValid
                        && this.state.lastName !== '' && this.state.lastNameValid
                        // && this.state.phone !== '' && this.state.phoneValid
                        && this.state.privacyTick && this.state.termsTick ? true : false;
        return (
            <div className="full-screen-holder white-bg no-select default-cursor">
                
                <div className="contact-form-holder grow flex-rows">

                    <div className="view-row grow"></div>
                    <div className="view-row grow"></div>

                    <div className="view-row shrink">
                        <p className="blue-header bold-text">Contact Form</p>
                    </div>

                    <div className="view-row shrink centered">
                        <p className="form-info-text">Please fill in your correct contact details below so that after the game we can get in touch regarding your application.</p>
                    </div>

                    <div className="view-row grow"></div>

                    <div className="view-row shrink centered">
                        <p className="form-field-header">Confirm your first name</p>
                    </div>

                    <div className="view-row shrink extended-form-input-holder">
                        <input className={"wide-form-input" + (this.state.nameValid ? '' : ' error')} placeholder="Type here..." id="name_input" value={this.state.name} onChange={this.nameUpdated} type="text" maxLength="40"></input>
                    </div>

                    {!this.state.nameValid &&
                    <div className="view-row shrink">
                        <p className="form-error-text">* Please fill in this form field</p>
                    </div>
                    }

                    <div className="view-row grow"></div>

                    <div className="view-row shrink centered">
                        <p className="form-field-header">Last name</p>
                    </div>

                    <div className="view-row shrink extended-form-input-holder">
                        <input className={"wide-form-input" + (this.state.lastNameValid ? '' : ' error')} placeholder="Type here..." id="lastname_input" value={this.state.lastName} onChange={this.lastNameUpdated} type="text" maxLength="40"></input>
                    </div>

                    {!this.state.lastNameValid &&
                    <div className="view-row shrink">
                        <p className="form-error-text">* Please fill in this form field</p>
                    </div>
                    }

                    <div className="view-row grow"></div>

                    <div className="view-row shrink centered">
                        <p className="form-field-header">Email address</p>
                    </div>

                    <div className="view-row shrink extended-form-input-holder">
                        <input className={"wide-form-input" + (this.state.emailValid ? '' : ' error')} placeholder="Type here..." id="email_input" value={this.state.email} onChange={this.emailUpdated} type="email"></input>
                    </div>

                    {!this.state.emailValid &&
                    <div className="view-row shrink">
                        <p className="form-error-text">* Please enter a valid email address</p>
                    </div>
                    }

                    <div className="view-row grow"></div>

                    <div className="view-row shrink centered">
                        <p className="form-field-header">Age</p>
                    </div>

                    <div className="view-row shrink extended-form-input-holder">
                        <input className={"wide-form-input" + (this.state.ageValid ? '' : ' error')} placeholder="Type here..." id="age_input" value={this.state.age} onChange={this.ageUpdated} type="number" min="13" max="99"></input>
                    </div>

                    {!this.state.ageValid &&
                    <div className="view-row shrink">
                        <p className="form-error-text">* Please enter an age between 13 and 99</p>
                    </div>
                    }

                    <div className="view-row grow"></div>

                    {/*
                    <div className="view-row shrink centered">
                        <p className="form-field-header">Phone Number</p>
                    </div>

                    <div className="view-row shrink extended-form-input-holder">
                        <input className={"wide-form-input" + (this.state.phoneValid ? '' : ' error')} placeholder="Type here..." id="phone_input" value={this.state.phone} onChange={this.phoneUpdated} type="tel"></input>
                    </div>

                    {!this.state.phoneValid &&
                    <div className="view-row shrink">
                        <p className="form-error-text">* Please fill in this form field</p>
                    </div>
                    }

                    <div className="view-row grow"></div>
                    */}

                    <div className="view-row shrink flex-columns">
                         <div className="shrink">
                            <img className="form-check active-button" src={this.state.privacyTick ? FormTick : FormTickBox} onClick={this.togglePrivacyCheck} alt="Check to agree to our privacy policy" />
                        </div>
                        <div className="grow">
                            <p className="form-info-text">By completing this form you agree with the storage and handling of your data by this website in accordance with our <a href="https://www.babcockinternational.com/privacy/" target="_blank">Privacy Policy</a>.</p>
                        </div>
                    </div>

                    <div className="view-row shrink flex-columns">
                         <div className="shrink">
                            <img className="form-check active-button" src={this.state.termsTick ? FormTick : FormTickBox} onClick={this.toggleTermsCheck} alt="Check to agree to our terms and conditions" />
                        </div>
                        <div className="grow">
                            <p className="form-info-text">By submitting this form I confirm that I agree with the <a href="https://www.babcockinternational.com/terms/" target="_blank">Terms &amp; Conditions</a>.</p>
                        </div>
                    </div>

                    <div className="view-row shrink flex-columns">
                         <div className="shrink">
                            <img className="form-check active-button" src={this.state.marketingTick ? FormTick : FormTickBox} onClick={this.toggleMarketingCheck} alt="Check to receive emails from Babcock International" />
                        </div>
                        <div className="grow">
                            <p className="form-info-text">I confirm I am happy for Babcock International to email me about apprenticeships and future opportunities.</p>
                        </div>
                    </div>

                    <div className="view-row grow"></div>

                    {canSubmit &&
                    <div className="view-row shrink centered">
                        <img className="submit-form-button active-button" src={SubmitButton} onClick={this.submitForm} alt="Click to submit your details" />
                    </div>
                    }
                    {!canSubmit &&
                    <div className="view-row shrink centered">
                        <img className="submit-form-button half-fade" src={SubmitButton} alt="Please fill in your details and agree to our privacy policy and terms and conditiond before continuing" />
                    </div>
                    }

                    <div className="view-row grow"></div>
                    <div className="view-row grow"></div>

                </div>

            </div>
        );
    }
}