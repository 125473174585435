import * as PIXI from 'pixi.js';
import gsap from 'gsap';
/**
 * @author Carl Trelfa
 * 
 * A universal character for tile-based games. Lays the foundation for npcs and all sorts of characters with various behaviours / states.
 * 
 * characterFrameData should look something like this:

export const IZZY = {
    SPRITES: {
        STILL: {
            xDir: 0,
            yDir: 0,
            texture: 'izzy_stand_front_1.png',
            animFrames: [
                'izzy_stand_front_1.png',           // Leave the anim frames out or just using one for a static sprite
            ]
        },
        UP: {
            xDir: 0,
            yDir: -1,
            texture: 'izzy_stand_back_1.png',
            animFrames: [
                'izzy_stand_back_1.png',
                'izzy_stand_back_2.png',
                'izzy_stand_back_1.png',
                'izzy_stand_back_3.png',
            ]
        },
        DOWN: {
            xDir: 0,
            yDir: 1,
            texture: 'izzy_stand_front_1.png',
            animFrames: [
                'izzy_stand_front_1.png',
                'izzy_stand_front_2.png',
                'izzy_stand_front_1.png',
                'izzy_stand_front_3.png',
            ]
        },
        LEFT: {
            xDir: -1,
            yDir: 0,
            scaleX: 1,
            texture: 'izzy_stand_side_1.png',
            animFrames: [
                'izzy_stand_side_1.png',
                'izzy_stand_side_2.png',
                'izzy_stand_side_1.png',
                'izzy_stand_side_3.png',
            ]
        },
        RIGHT: {
            xDir: 1,
            yDir: 0,
            scaleX: -1,
            texture: 'izzy_stand_side_1.png',
            animFrames: [
                'izzy_stand_side_1.png',
                'izzy_stand_side_2.png',
                'izzy_stand_side_1.png',
                'izzy_stand_side_3.png',
            ]
        }
    }
}

 *
 */
export class InGameEntity {
    inited = false;
    npc = false;
    npcId = null;
    removeOriginalTile = true;
    originalTile = null;

    originalConfigData = [];

    characterSprite = null;
    characterXOffset = 0;
    characterYOffset = 0;
    container = null;

    lastDirX = 0;
    lastDirY = 0;

    stateFrameData = null;
    stateFrameAnims = null;

    startSquare = null;
    startPixelPos = null;

    characterGridX = null;
    characterGridY = null;
    actualGridX = null;
    actualGridY = null;

    inView = false;
    startSquareInView = true;
    
    // Entities default to blocking the grid square they occupy
    blocking = true;
    // We can block larger areas by setting the start offset of the blocking grid and the width / height.
    // We need the start offset to accommodate even width blocking grids, so a 2 wide grid and start on the grid square,
    // or on the suare before it, so it only really make sense to set it to -1 or 0 in this case, generally you would need
    // odd blocking areas to block all surrounding tiles. Although the applyBlockingArea function can be over-ridden for
    // more complex blocking areas.
    blockingGridStartXOffset = 0;
    blockingGridStartYOffset = 0;
    blockingWidth = 1;       // Default blocking width is just the grid square we occupy.
    blockingHeight = 1;      // Default blocking width is just the grid square we occupy.

    updateable = false;

    // use proximity sensor by default
    useProximitySensing = true;
    proximityAlarmDistance = 128;

    pauseWhenOffScreen = false;

    scrollingTilemapController = null;
    approachingEntity = null;
    allowInteraction = true;
    storyCharacter = false;
    readyToInteract = true;

    // Ending interaction doesn't always mean we have actually done any interaction as it can be ended early,
    // eg. our character is approaching and we are paused waiting, but then the player clicks elsewhere before
    // the actual interaction. So if you want to do something after interaction, you need to check this instead.
    interactionComplete = false;

    constructor(characterFrameData, scrollingTilemapController) {
        this.stateFrameData = characterFrameData;
        this.scrollingTilemapController = scrollingTilemapController;
    }

    /* -- Some event hooks -- */
    enteredView() {
        this.inView = true;
    }

    leftView() {
        this.inView = false;
    }

    startSquareEnteredView() {
        // console.log('Start square entered view');
        this.startSquareInView = true;
    }

    startSquareLeftView() {
        // console.log('Start square left view');
        this.startSquareInView = false;
    }

    otherCharacterNearBy(otherCharactersArray) {
        // Do something!
        // Interact possibly? Maybe fly off for pigeons, maybe pause movements for path finders.
    }

    update(delta) {
        // This will be called every frame for all entities set to updateable
        // standard path-finding entities might not use this...
        // But might be handy for something, so here it is as a standard feature.
        this.updateAABB();
    }

    updateAABB() {
        this.characterSprite.minGX = this.actualGridX;
        this.characterSprite.minGY = this.actualGridY;
        this.characterSprite.maxGX = this.actualGridX;
        this.characterSprite.maxGY = this.actualGridY;
    }

    // for pathfinders this will pause movement
    waitForInteraction() {

    }

    endInteraction() {

    }

    interactionComepleted() {
        this.interactionComplete = true;
    }

    /* -- Functions for adding and removing characters from play -- */
    addToGame(startSquare, container, xOffset = 0, yOffset = 0) {
        if (this.stateFrameAnims === null) {
            if (this.stateFrameData) {
                this.createStateAnims();
            } else {
                return false;
            }
        }

        this.startSquare = startSquare;
        this.container = container;

        this.startPixelPos = this.convertSquareToPixelPos(startSquare);

        if (this.characterSprite === null) {
            this.characterSprite = new PIXI.Container();
            this.characterSprite.animated = false;
            this.characterSprite.controller = this;
        }
        this.actualGridX = this.characterGridX = startSquare.x;
        this.actualGridY = this.characterGridY = startSquare.y;
        this.characterXOffset = xOffset;
        this.characterYOffset = yOffset;
        this.characterSprite.x = this.startPixelPos.x + xOffset;
        this.characterSprite.y = this.startPixelPos.y + yOffset;

        if (startSquare.tile) {
            this.inView = startSquare.tile.inView;
            if (startSquare.tile.associateCharacters === undefined) {
                startSquare.tile.associateCharacters = [];
            }
            startSquare.tile.associateCharacters.push(this);
        }

        this.container.addChild(this.characterSprite);
        this.characterSprite.layerContainer = this.container;
        this.updateCharacterSprite();
        this.scrollingTilemapController.registerEntity(this);

        this.inited = true;
        // console.log('Added Entity: ', this);

        this.updateAABB();
        return true;
    }

    updateSpriteData(characterFrameData) {
        this.stateFrameData = characterFrameData;
        this.createStateAnims();
        this.updateCharacterSprite();
    }

    applySettingsConfig(configArray) {
        let applyableSettings = ['blocking', 'blockingGridStartXOffset', 'blockingGridStartYOffset', 'blockingWidth', 'blockingHeight', 'updateable', 'useProximitySensing', 'proximityAlarmDistance', 'allowInteraction', 'storyCharacter'];
        this._applySettings(configArray, applyableSettings);
        this.originalConfigData = configArray;
    }

    _applySettings(configArray, applyableSettings) {
        for (let i = 0; i < configArray.length; i++) {
            if (configArray[i]) {
                for (let j = 0; j < applyableSettings.length; j++) {
                    if (typeof configArray[i][applyableSettings[j]] !== 'undefined') {
                        // console.log('Apply setting: ', applyableSettings[j], configArray[i][applyableSettings[j]]);
                        this[applyableSettings[j]] = configArray[i][applyableSettings[j]];
                    }
                }
            }
        }
    }

    convertSquareToPixelPos(squareData) {
        if (squareData.tile) {
            return { x: squareData.tile.baseTileX, y: squareData.tile.baseTileY - this.scrollingTilemapController.tileMapEngine.renderer.tileHeight / 2 };
        }
        return this.scrollingTilemapController.tileMapEngine.renderer.getPixelCenterTopOfGridSquare(squareData.x, squareData.y);
    }

    removeFromPlay() {
        gsap.killTweensOf(this.characterSprite);
        if (this.characterSprite && this.characterSprite.parent) {
            this.characterSprite.parent.removeChild(this.characterSprite);
            while (this.characterSprite.children.length > 0) {
                this.characterSprite.removeChild(this.characterSprite.children[0]);
            }
            this.characterSprite.destroy();
        }
        this.characterSprite = null;
        this.container = null;
        this.inited = false;
    }

    applyBlockingArea(blockingGrid, doubleGrid) {
        this.applyBlockingAreaToGridPos(blockingGrid, doubleGrid, this.characterGridX, this.characterGridY);
    }

    applyBlockingAreaToGridPos(blockingGrid, doubleGrid, gridX, gridY) {
        let mult = doubleGrid ? 2 : 1;
        let blockX = gridX * mult;
        let blockY = gridY * mult;
        blockX += this.blockingGridStartXOffset * mult;
        blockY += this.blockingGridStartYOffset * mult;
        for (let x = 0; x < this.blockingWidth * mult; x++) {
            for (let y = 0; y < this.blockingHeight * mult; y++) {
                blockingGrid[blockX + x][blockY + y] = 1;
            }
        }
    }

    getBlockedSquares() {
        return this.getBlockedSquaresForGridPos(this.characterGridX, this.characterGridY)
    }

    getBlockedSquaresForGridPos(gridX, gridY, blockedSquares = []) {
        let blockX = gridX;
        let blockY = gridY;
        blockX += this.blockingGridStartXOffset;
        blockY += this.blockingGridStartYOffset;
        for (let x = 0; x < this.blockingWidth; x++) {
            for (let y = 0; y < this.blockingHeight; y++) {
                let gx = blockX + x;
                let gy = blockY + y;
                let inArray = false;
                for (let i = 0; i < blockedSquares.length; i++) {
                    if (blockedSquares[i].x === gx && blockedSquares[i].y === gy) {
                        inArray = true;
                        break;
                    }
                }
                if (!inArray) {
                    blockedSquares.push({x: gx, y: gy});
                }
            }
        }
        return blockedSquares;
    }

    /* -- Functions for managing anims and current view for our character states -- */
    getOneAnimTextureArray(spriteData) {
        let animTextures = [];
        if (spriteData.animFrames) {
            for (let i = 0; i < spriteData.animFrames.length; i++) {
                animTextures.push(PIXI.Texture.from(spriteData.animFrames[i]));
            }
        }
        return animTextures;
    }

    createStateAnims() {
        if (this.stateFrameAnims) {
            this.destroyStateAnims();
        }
        this.stateFrameAnims = {};

        for (let p in this.stateFrameData.SPRITES) {
            if (this.stateFrameData.SPRITES[p].animFrames) {
                let animTextures = this.getOneAnimTextureArray(this.stateFrameData.SPRITES[p]);
                let anim = new PIXI.AnimatedSprite(animTextures);
                anim.gotoAndStop(1);
                anim.animationSpeed = this.stateFrameData.SPRITES[p].animSpeed || this.stateFrameData.SPRITES.ANIM_SPEED || this.stateFrameData.ANIM_SPEED || 0.15;
                this.stateFrameAnims[p] = anim;
            }
        }
    }

    destroyStateAnims() {
        for (let p in this.stateFrameAnims) {
            if (this.stateFrameAnims[p].parent) {
                this.stateFrameAnims[p].parent.removeChild(this.stateFrameAnims[p]);
            }
            this.stateFrameAnims[p].destroy();
        }
        this.stateFrameAnims = null;
    }

    updateCharacterSprite(spriteId = 'STILL') {
        let spriteData = null;

        // find data for this state
        for (let p in this.stateFrameData.SPRITES) {
            if (p === spriteId) {
                spriteData = this.stateFrameData.SPRITES[p];
                // console.log('Found sprite: ', spriteData);
                break;
            }
        }
        if (spriteId === 'STILL' && (this.lastDirX !== 0 || this.lastDirY !== 0)) {
            // find an idle sprite that has the same dir as the last dir...
            for (let p in this.stateFrameData.SPRITES) {
                if (this.stateFrameData.SPRITES[p].idleFrame) {
                    if (this.stateFrameData.SPRITES[p].xDir === this.lastDirX && this.stateFrameData.SPRITES[p].yDir === this.lastDirY) {
                        spriteData = this.stateFrameData.SPRITES[p];
                        // console.log('Found idle sprite: ', spriteData);
                        break;
                    }
                }
            }
        }
        if (spriteData) {
            this.lastDirX = spriteData.xDir;
            this.lastDirY = spriteData.yDir;
            // if we have a currentSprite, remove it
            let currentSprite = this.characterSprite.currentSprite; // children[0];
            if (currentSprite && currentSprite.parent && currentSprite.parent == this.characterSprite) {
                this.characterSprite.removeChild(currentSprite);
            }
            if (this.stateFrameAnims && this.stateFrameAnims[spriteId]) {
                // non-animated sprites must be destroyed here
                if (currentSprite && this.characterSprite.animated === false) {
                    currentSprite.destroy();
                }
                // animations are re-used, so we just swap them
                let newAnim = this.stateFrameAnims[spriteId];
                newAnim.anchor.x = 0.5;
                newAnim.anchor.y = 1;
                this.characterSprite.addChild(newAnim);
                if (newAnim !== currentSprite) {
                    newAnim.gotoAndStop(0);
                }
                this.characterSprite.animated = true;
                this.characterSprite.currentSprite = newAnim;
            } else {
                if (this.characterSprite.animated === false && currentSprite && currentSprite.texture) {
                    // we have a current static sprite, so just swap it's texture
                    currentSprite.texture = PIXI.Texture.from(spriteData.texture);
                } else {
                    // else make a new sprite
                    currentSprite = new PIXI.Sprite.from(spriteData.texture);
                    currentSprite.anchor.x = 0.5;
                    currentSprite.anchor.y = 1;
                }
                this.characterSprite.addChild(currentSprite);
                this.characterSprite.animated = false;
                this.characterSprite.currentSprite = currentSprite;
            }
            let scaleX = spriteData.scaleX || 1;
            let scaleY = spriteData.scaleY || 1;
            this.characterSprite.scale.x = scaleX;
            this.characterSprite.scale.y = scaleY;
        }
        this.playAnim();
    }

    playAnim() {
        if (this.characterSprite && this.characterSprite.animated && this.characterSprite.currentSprite) {
            this.characterSprite.currentSprite.play();
        }
    }

    pauseAnim() {
        if (this.characterSprite && this.characterSprite.animated && this.characterSprite.currentSprite) {
            this.characterSprite.currentSprite.gotoAndStop(0);
        }
    }
}