import React from "react";
import { v } from '../../../koko-framework/shorthand';
import PositionableReactView from "../../../koko-framework/ui/react-views/PositionableReactView";
import { GameState } from "../../model/GameState";

export default class NewItemPop extends PositionableReactView {
    timeoutId = null;

    constructor(props) {
        super(props);

        // Fix this panel in place just under the rucksack in the hud.
        // this.state.left = '100';
        // this.state.top = '100';

        this.resize();
    }

    componentDidMount = () => {
        super.componentDidMount();

        this.setPosition(50, 130, false);
        this.timeoutId = setTimeout(this.closePanel, 3000);
    }

    closePanel = () => {
        clearTimeout(this.timeoutId);
        v.hideView(this.props.viewId);
    }

    renderContent() {
        return (
            <img src={GameState.itemsCollected[GameState.itemsCollected.length - 1].popPanel} className="new-item-pop" alt={GameState.itemsCollected.description + ' added to your backpack'} onClick={this.closePanel} />
        );
    }
}