import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import game from '../../../controller/GameController';
import { v, au } from '../../../koko-framework/shorthand';
import LetsGoButton from '../../../assets/babcock_menus/enter_name/confirm_btn.png';
import { GameState } from "../../model/GameState";
import ApiController from "../../controller/ApiController";

import ReactGA from 'react-ga4';

export default class NameEntry extends ReactFullScreenView {
    constructor(props) {
        super(props);

        this.state.name = GameState.name;
    }

    componentDidMount() {
        super.componentDidMount();

        ReactGA.send({ hitType: "pageview", page: "/name-form/" });
    }

    nameEntered = () => {
        au.play('primarybtn', 1, false, 0, false);
        GameState.name = this.state.name;
        v.hideView('nameentry');
        // v.hideView('blackbg');
        
        /* v.showView('greenbg');
        game.initWorld();
        game.gotoWorldView(true);
        v.showView('hudview');
        if (!GameState.firstPlay) {
            v.showView('skiptutorial');
        }
        au.stop('menu_music');
        au.play('babcock_ingame_music', 1, true, 0, false); */
        v.showView('introvideo');

        // ApiController.submitContactData();

        // setTimeout(() => v.hideView('blackfade'), 400);

        // a quick way of getting our main form up for testing
        // v.showView('contactform');
    }

    nameUpdated = () => {
        let name = window.document.getElementById('name_input').value;
        this.setState({name: name});
    }

    renderContent() {
        return (
            <div className="full-screen-holder main-menu no-select default-cursor">
                <div className="full-screen-holder black-out rel">
                    <div className="name-entry-panel">
                        <div className="view-row grow"></div>
                        <div className="view-row shrink">
                            <p className="blue-header bold-text">Enter First Name</p>
                        </div>
                        <div className="view-row shrink centered">
                            <p className="grey-info-text">Your name will be used as part of the<br />game and also part of the Babcock<br />Apprenticeship process.</p>
                        </div>
                        <div className="view-row name-input-holder">
                            <input className="name-input" placeholder="Type here..." id="name_input" value={this.state.name} onChange={this.nameUpdated} maxLength="40"></input>
                        </div>
                        <div className="view-row name-entry-button-holder">
                            {this.state.name.length >= 2 &&
                                <img className="name-entry-button active-button" src={LetsGoButton} onClick={this.nameEntered} alt="Click to start" />
                            }
                            {this.state.name.length < 2 &&
                                <img className="name-entry-button half-fade" src={LetsGoButton} alt="Please enter your first name above and then click here to start" />
                            }
                        </div>
                        <div className="view-row grow"></div>
                    </div>
                </div>
            </div>
        );
    }
}