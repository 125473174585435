import { KOKO_DEBUG, kokoDebug } from '../model/config'
import { LiquidObject } from './LiquidObject';
import { LIQUID_VOLUME_TOTAL} from '../model/GameConfig'


export class LiquidContainer {
    // Member properties
    liquidObjArray = []; //An array used to store all the liquid objects
    containerVolume = LIQUID_VOLUME_TOTAL; //Any [int], larger values allow for more intricate pours
    liquidVolume = 0;
    complete = false;
    // Member functions
    constructor() {

    }
    calculateTotalVolume()
    {
        let totalVolume = 0;
        for(var i =0; i<this.liquidObjArray.length; i++)
        {
            totalVolume += this.liquidObjArray[i].volume
        }
        this.liquidVolume = totalVolume;
        return totalVolume;
    }

    calculateVolumeOfColour(colour) {
        let volume = 0;
        for (let i = 0; i < this.liquidObjArray.length; i++) {
            if (this.liquidObjArray[i].colour === colour) {
                volume += this.liquidObjArray[i].volume;
            }
        }
        return volume;
    }

    checkCompleteStatus()
    {
        //This function can be called to see if the conatiner has been completed with a single colour
        let completeStatus = false
        this.calculateTotalVolume(); 
        if(this.containerVolume == this.liquidVolume && this.getTopLiquid().volume == this.getTopLiquid().animatedVolume)
        {
            //Container is full of liquid
            if(this.liquidObjArray.length == 1)
            {
                //There is only a single colour in the container so it is complete
                //kokoDebug('Container is COMPLETE: '+this.liquidObjArray[0].colour);
                completeStatus = true;
            }
        }
        this.complete = completeStatus;
        return completeStatus;
    }

    addLiquid(liquidObject){
        let success = false;
        let storedLiquid = this.liquidObjArray;
        if(this.calculateTotalVolume() < this.containerVolume)
        {
            let amountLeft = this.containerVolume - this.calculateTotalVolume();
            let limitedSpace = amountLeft < liquidObject.volume;
            //The liquid will fit inside the container
            if(storedLiquid.length == 0)
            {
                //The container is currently empty so add the first liquid to the container
                let newLiquidObj = new LiquidObject(liquidObject.colour, liquidObject.volume, liquidObject.maskID);
                liquidObject.removeLiquid(liquidObject.volume);
                storedLiquid.push(newLiquidObj);
                
            }
            else
            {
                let amountToPour = limitedSpace ? amountLeft : liquidObject.volume;
                let liquidMerged = this.getTopLiquid().mergeLiquid(liquidObject, amountToPour);
               
                if(!liquidMerged)
                {
                     //Liquid was not the same colour as the top liquid so it was not merged
                     //Create a new liquidObj with the correct volume
                    let newLiquidObj = new LiquidObject(liquidObject.colour, amountToPour, liquidObject.maskID);
                    liquidObject.removeLiquid(amountToPour);
                    storedLiquid.push(newLiquidObj);
                }
            }
            success = true;
        } 
        this.calculateTotalVolume(); 
        return success;
    }

    getTopLiquid()
    {
        for(var i= this.liquidObjArray.length - 1; i>=0; i--)
        {
            if(this.liquidObjArray[i].volume > 0) return this.liquidObjArray[i];
        }
        return null;
    }

    removeEmptyLiquids()
    {
        let objRemoved = false
        while(this.liquidObjArray.length >0 && this.liquidObjArray[this.liquidObjArray.length-1].volume <= 0 )
        {
            console.log('REMOVE EMPTY LIQUID OBJ');
            this.liquidObjArray.pop();
            objRemoved = true
        }
        return objRemoved;
    }
}