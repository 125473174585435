import Matter/*, { Vector }*/ from 'matter-js'
/**
 * Physics helper classes
 * Uses matter.js - https://www.npmjs.com/package/matter-js
 * Also poly-decomp - https://www.npmjs.com/package/poly-decomp for concave polygons
 * 
 * Mostly documented.
 * @author Carl Trelfa
 * 
 * Usage:
 * 
 *      var myPhysicsController = new PhysicsController();
 * 
 * 
 * Add a function to deal with collisions:
 * 
 *      myPhysicsController.collisionCallback = myCollisionCallbackFunction;
 *      myPhysicsController.collisionEndCallback = myCollisionEndCallbackFunction;
 * 
 * Your physics callback functions should accept 2 params, a pair of colliding PhysicsObjects (PhysicsObjectA, PhysicsObjectB).
 *
 * 
 * Use `createCircleObject`, `createRectangleObject`, `createPolygonObject` or `createObjectFromVertices` to add objects to your world.
 * These function return the resulting PhysicsObject instance - you can add extra props to make it easy to identify things in your world when they collide,
 * eg. newPhysicsObject.id = 'wall' or 'player' or 'enemy' or 'pickup' etc.
 * 
 * Note you will need to deal with adding the views to your containers yourself. You can do this before or after you create the physics object, up to you.
 * 
 * 
 * In your update loop, call:
 * 
 *      myPhysicsController.update(delta);
 * 
 * Handling collisions as they come through.
 * 
 * 
 * When your are done, call:
 * 
 *      myPhysicsController.destroy();
 * 
 * To tidy up!
 */


/**
 * Physics object, just a simple updater.
 * Our PhysicsController runs the physics sim then steps through our PhysicsObjects, updating them.
 * 
 * You will most likely never need to create a PhysicsObject instance directly, but if you do you
 * can use myPhyicsController.addPhysicsObjectToWorld to add it to the simulation.
 * 
 * @class PhysicsObject
 * @property {Matter.Body} physicsBody
 * @property {PIXI.DisplayObject} view
 * @property {Boolean} ignoreRotation - we don't always want to rotate our view
 * @property {Boolean} live - Is this a live physics object?
 */
export class PhysicsObject {
    physicsBody = null;
	view = null;
	ignoreRotation = false;
	live = false;

    update() {
    	if (this.live && this.physicsBody && this.view) {
    		this.view.position.x = this.physicsBody.position.x;
    		this.view.position.y = this.physicsBody.position.y;
    		if (!this.ignoreRotation) {
	    		this.view.rotation = this.physicsBody.angle;
	    	}
    	}
    }
}


/**
 * PhysicsController class to simplify setting up your physics simulation,
 * makes setting / tearing down your world and adding / removing objects a breeze.
 * 
 * Decided to make this a class in case we ever have a case where we need
 * several physics simulations running at the same time.
 * 
 * @property {Function} collisionCallback should accept 3 params (PhysicsObjectA, PhysicsObjectB, originalData) - the colliding objects, plus original data
 * @property {Function} collisionEndCallback should accept 3 params (PhysicsObjectA, PhysicsObjectB, originalData) - the colliding objects, plus original data
 * 
 * @class PhysicsController
 */
export class PhysicsController {
    engine = null;
    world = null;
    physicsObjects = null;
    collisionCallback = null;
    collisionEndCallback = null;
    lastDelta = 1;
    live = false;

    constructor(gravity = {x: 0, y: 1}) {
        this.engine = Matter.Engine.create();
        this.engine.world.gravity.x = gravity.x;
        this.engine.world.gravity.y = gravity.y;
        
        this.world = this.engine.world;
        this.physicsObjects = [];

        Matter.Events.on(this.engine, "collisionStart", this.processCollisions.bind(this));
        Matter.Events.on(this.engine, "collisionEnd", this.processCollisionEnds.bind(this));

        this.lastDelta = 1;
        this.live = true;
    }

    /* THE CORE UPDATING FUNCTIONS */

    /**
     * Update your simulation and view (PIXI displayObjects)
     * @method update
     * @param {Number} delta Should be ~1 if your framerate is consistent and not slowing down, >1 if low framerate
     */
    update(delta = 1) {
        if (this.live) {
            let correction = delta / this.lastDelta;
            this.lastDelta = delta;
            Matter.Engine.update(this.engine, 16.666 * delta, correction);
            for (let i = 0; i < this.physicsObjects.length; i++) {
                this.physicsObjects[i].update();
            }
        }
    }

    /**
     * Process collisions and report individual collision to this.collisionCallback.
     * 
     * This deals with new collisions (collisionStart). You can quite easily store a reference to a collision pair
     * and use Matter.Query.collides(body, bodies) to test if two bodies are still colliding (can be handy for sensor collisions),
     * or use the collisionEndCallback...
     * 
     * We also support collisionEnd, but if you want to do anything more fancy than that you should add your own
     * handlers directly to this.engine, using Matter.Events.on(engine, "collisionEvent", handler);
     * 
     * IMPORTANT! DON'T DESTROY PHYSICS OBJECTS DIRECTLY IN YOUR CALLBACK!
     * SAVE THEM IN AN ARRAY AND DESTOY THEM AT THE END OF YOUR GAME LOOP!
     * 
     * This is an internal function and never needs to be called outside of this class.
     */
    processCollisions(e) {
        if (this.live && this.collisionCallback) {
            // temporary store so we only report back unique collisions to the main game
            let collisions = [];
            for (let i = 0; i < e.pairs.length; i++) {
                let bodyA = e.pairs[i].bodyA;
                let bodyB = e.pairs[i].bodyB;
                if (bodyA !== bodyB) {
                    let alreadyProcessed = false;
                    for(let j = 0; j < collisions.length; j++) {
                        if ( (collisions[j].bodyA === bodyA || collisions[j].bodyA === bodyB)
                                && (collisions[j].bodyB === bodyA || collisions[j].bodyB === bodyB)
                        ) {
                            alreadyProcessed = true;
                            break;
                        }
                    }
                    if (!alreadyProcessed) {
                        collisions.push({bodyA: bodyA, bodyB: bodyB});
                        let physicsObjectA = this.findPhysicsObjectForBody(bodyA);
                        let physicsObjectB = this.findPhysicsObjectForBody(bodyB);
                        if (physicsObjectA && physicsObjectB) {
                            this.collisionCallback(physicsObjectA, physicsObjectB, e);
                        }
                    }
                }
            }
        }
    }

    /**
     * Process collisions and report individual collision to this.collisionEndCallback.
     * 
     * This deals with collisions ending (collisionEnd). You can use this to remove references to ongoing collisions you have stored.
     * 
     * We also support collisionStart, but if you want to do anything more fancy than that you should add your own
     * handlers directly to this.engine, using Matter.Events.on(engine, "collisionEvent", handler);
     * 
     * IMPORTANT! DON'T DESTROY PHYSICS OBJECTS DIRECTLY IN YOUR CALLBACK!
     * SAVE THEM IN AN ARRAY AND DESTOY THEM AT THE END OF YOUR GAME LOOP!
     * 
     * This is an internal function and never needs to be called outside of this class.
     */
    processCollisionEnds(e) {
        if (this.live && this.collisionEndCallback) {
            // temporary store so we only report back unique collisions to the main game
            let collisions = [];
            for (let i = 0; i < e.pairs.length; i++) {
                let bodyA = e.pairs[i].bodyA;
                let bodyB = e.pairs[i].bodyB;
                if (bodyA !== bodyB) {
                    let alreadyProcessed = false;
                    for(let j = 0; j < collisions.length; j++) {
                        if ( (collisions[j].bodyA === bodyA || collisions[j].bodyA === bodyB)
                                && (collisions[j].bodyB === bodyA || collisions[j].bodyB === bodyB)
                        ) {
                            alreadyProcessed = true;
                            break;
                        }
                    }
                    if (!alreadyProcessed) {
                        collisions.push({bodyA: bodyA, bodyB: bodyB});
                        let physicsObjectA = this.findPhysicsObjectForBody(bodyA);
                        let physicsObjectB = this.findPhysicsObjectForBody(bodyB);
                        if (physicsObjectA && physicsObjectB) {
                            this.collisionEndCallback(physicsObjectA, physicsObjectB, e);
                        }
                    }
                }
            }
        }
    }

    /*  The following two functions are meant for internal use, but might be handy for use outside of this class. */
    
    /**
     * Find a PhysicsObject instance corresponding to a physics body.
     * @method findPhysicsObjectForBody
     * 
     * @param {Matter.Body} body 
     * @return {PhysicsObject}
     */
    findPhysicsObjectForBody(body) {
        for (let i = 0; i < this.physicsObjects.length; i++) {
            if (this.physicsObjects[i].physicsBody === body) {
                return this.physicsObjects[i];
            }
        }
        return null;
    }

    /**
     * If you create a PhysicsObject instance outside of the create functions below, then make sure you add it to the simulation.
     * @method addPhysicsObjectToWorld
     * 
     * @param {PhysicsObject} physicsObject 
     * @param {Vector} position {x: Number, y:Number}
     * @param {Number} angle 
     */
    addPhysicsObjectToWorld(physicsObject, position = null, angle = null) {
        if (position != null) {
            Matter.Body.setPosition(physicsObject.physicsBody, position);
        }
        if (angle != null) {
            Matter.Body.setAngle(physicsObject.physicsBody, angle);
        }
        Matter.World.addBody(this.world, physicsObject.physicsBody);
        physicsObject.live = true;
        physicsObject.update();
        let i = this.physicsObjects.indexOf(physicsObject);
        if (i === -1) {
            this.physicsObjects.push(physicsObject);
        }
    }

    /* CREATION FUNCTIONS */

    /**
     * Obviously creates circles
     * @method createCircleObject
     * 
     * @param {Number} radius The radius of the circle
     * @param {PIXI.DisplayObject} view A Pixi displayObject
     * @param {Object} physicsSettings An object in the following format (all optional and correspond to Matter.Body properties):
     *              {
     *                  density: Number,
     *                  restitution: Number
     *                  friction: Number,
     *                  frictionAir: Number,
     *                  frictionStatic: Number,
     *                  isSensor: Boolean,
     *                  isStatic: Boolean,
     *                  position: Vector {x: Number, y: Number},
     *                  angle: Number
     *                  collisionFilter: { see Matter-js docs }
     *              }
     * @param {Boolean} ignoreRotation Ignore applying rotation to our view when the object is updated
     * @return {PhysicsObject}
     */
    createCircleObject(radius, view, physicsSettings = {}, ignoreRotation = false, maxSides = 20) {
        let position = physicsSettings.position || {x: 0, y: 0};
        let angle = physicsSettings.angle || 0;
        let physicsObject = new PhysicsObject();
        physicsObject.view = view;
        physicsObject.ignoreRotation = ignoreRotation;
        physicsObject.physicsBody = Matter.Bodies.circle(position.x, position.y, radius, physicsSettings, maxSides);
        this.addPhysicsObjectToWorld(physicsObject, position, angle);
        return physicsObject;
    }

    /**
     * Create rectangles (or squares if you set width/height the same)
     * @method createRectangleObject
     * 
     * @param {Number} width
     * @param {Number} height
     * @param {PIXI.DisplayObject} view A Pixi displayObject
     * @param {Object} physicsSettings An object in the following format (all optional and correspond to Matter.Body properties):
     *              {
     *                  density: Number,
     *                  restitution: Number
     *                  friction: Number,
     *                  frictionAir: Number,
     *                  frictionStatic: Number,
     *                  isSensor: Boolean,
     *                  isStatic: Boolean,
     *                  position: Vector {x: Number, y: Number},
     *                  angle: Number
     *                  collisionFilter: { see Matter-js docs }
     *              }
     * @param {Boolean} ignoreRotation Ignore applying rotation to our view when the object is updated
     * @return {PhysicsObject}
     */
    createRectangleObject(width, height, view, physicsSettings = {}, ignoreRotation = false) {
        let position = physicsSettings.position || {x: 0, y: 0};
        let angle = physicsSettings.angle || 0;
        let physicsObject = new PhysicsObject();
        physicsObject.view = view;
        physicsObject.ignoreRotation = ignoreRotation;
        physicsObject.physicsBody = Matter.Bodies.rectangle(position.x, position.y, width, height, physicsSettings);
        this.addPhysicsObjectToWorld(physicsObject, position, angle);
        return physicsObject;
    }

    /**
     * Create regular polygon (ie. all sides the same length)
     * @method createPolygonObject
     * 
     * @param {Number} sides The number of sides
     * @param {Number} radius The radius of the polygon
     * @param {PIXI.DisplayObject} view A Pixi displayObject
     * @param {Object} physicsSettings An object in the following format (all optional and correspond to Matter.Body properties):
     *              {
     *                  density: Number,
     *                  restitution: Number
     *                  friction: Number,
     *                  frictionAir: Number,
     *                  frictionStatic: Number,
     *                  isSensor: Boolean,
     *                  isStatic: Boolean,
     *                  position: Vector {x: Number, y: Number},
     *                  angle: Number
     *                  collisionFilter: { see Matter-js docs }
     *              }
     * @param {Boolean} ignoreRotation Ignore applying rotation to our view when the object is updated
     * @return {PhysicsObject}
     */
    createPolygonObject(sides, radius, view, physicsSettings = {}, ignoreRotation = false) {
        let position = physicsSettings.position || {x: 0, y: 0};
        let angle = physicsSettings.angle || 0;
        let physicsObject = new PhysicsObject();
        physicsObject.view = view;
        physicsObject.ignoreRotation = ignoreRotation;
        physicsObject.physicsBody = Matter.Bodies.polygon(position.x, position.y, sides, radius, physicsSettings);
        this.addPhysicsObjectToWorld(physicsObject, position, angle);
        return physicsObject;
    }

    /**
     * Create an irregular polygons from a list of points. Make sure you have poly-decomp installed for concave polys.
     * @method createObjectFromVertices
     * 
     * @param {[[Vector]]} vertices [[x, y], [x, y], [x, y] ... ]
     * @param {PIXI.DisplayObject} view A Pixi displayObject
     * @param {Object} physicsSettings An object in the following format (all optional and correspond to Matter.Body properties):
     *              {
     *                  density: Number,
     *                  restitution: Number
     *                  friction: Number,
     *                  frictionAir: Number,
     *                  frictionStatic: Number,
     *                  isSensor: Boolean,
     *                  isStatic: Boolean,
     *                  position: Vector {x: Number, y: Number},
     *                  angle: Number
     *                  collisionFilter: { see Matter-js docs }
     *              }
     * @param {Boolean} ignoreRotation Ignore applying rotation to our view when the object is updated
     * @return {PhysicsObject}
     */
    createObjectFromVertices(vertices, view, physicsSettings = {}, ignoreRotation = false) {
        let position = physicsSettings.position || {x: 0, y: 0};
        let angle = physicsSettings.angle || 0;
        let physicsObject = new PhysicsObject();
        physicsObject.view = view;
        physicsObject.ignoreRotation = ignoreRotation;
        physicsObject.physicsBody = Matter.Bodies.fromVertices(position.x, position.y, vertices, physicsSettings);
        this.addPhysicsObjectToWorld(physicsObject, position, angle);
        return physicsObject;
    }


    /* TEARDOWN AND CLEANUP FUNCTIONS */

    /**
     * Destroy the whole simulation. Generally used at the end of your game.
     * @method destroy
     */
    destroy() {
        if (this.live) {
            if (this.physicsObjects.length > 0) {
                for (let i = this.physicsObjects.length - 1; i >= 0; i--) {
                    let o = this.destroyObject(this.physicsObjects[i], true);
                    o.physicsBody = null;
                    o.view = null;
                }
            }
            
            Matter.Events.off(this.engine, "collisionStart", this.processCollisions.bind(this));
            Matter.Engine.clear(this.engine);

            this.engine = null;
            this.world = null;
            this.physicsObjects = null;
            this.collisionCallback = null;
            this.live = false;
        }
    }

    /**
     * Destroy an individual PhysicsObject. Sometimes you will need to do this when you detect a collision.
     * Always use this when removing objects from the world ot you might cause a crash!
     * @method destroyObject
     * 
     * @param {PhysicsObject} physicsObject The object to remove
     * @param {Boolean} destroyView Detroy the view as well? If you are pooling, you might not want to!
     * @return {physicsObject} The physics object that was removed. It's mostly return for internal reasons.
     */
    destroyObject(physicsObject, destroyView = false) {
        if (physicsObject.live) {
            Matter.World.remove(this.world, physicsObject.physicsBody);
            if (destroyView && physicsObject.view) {
                if (physicsObject.view.parent) {
                    physicsObject.view.parent.removeChild(physicsObject.view);
                }
                physicsObject.view.destroy({children: true});
            }
            physicsObject.live = false;
            let i = this.physicsObjects.indexOf(physicsObject);
            if (i >= 0) {
                this.physicsObjects.splice(i, 1);
            }
        }
        // The destroy function above uses this return value, to null refs.
        return physicsObject;
    }
}