import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import game from '../../../controller/GameController';
import { au, v } from '../../../koko-framework/shorthand';
import AdvisorIcon from '../../../assets/babcock_menus/in_game/dialog/advisor_dialogue.png';
import DialogArrow from '../../../assets/babcock_menus/in_game/dialog/dialogue_scroll.png';
import PositionableReactView from "../../../koko-framework/ui/react-views/PositionableReactView";
import { PANEL_STATE } from "../../../Bootstrap";
import WorldController from "../../../controller/WorldController";
import { getDialogDataFromId, getEntityDataFromId, NEIGHBOUR_NPC } from "../../model/constants";
import { GameState } from "../../model/GameState";
import BabcockController from "../../controller/BabcockController";
import * as BABCOCK_CONSTANTS from '../../model/constants';

export default class AdvisorDialogPanel extends ReactFullScreenView {
    typeId = -1;
    canClickTimeoutId = -1;

    constructor(props) {
        super(props);

        // Fix this panel to the bottom middle. Our css class bottom-panels-bg positions the panel relative to that.
        this.state.left = '50%';
        this.state.top = '100%';
        this.state.dialog = PANEL_STATE.complexDialog || [{character: 'neighbour', text: PANEL_STATE.contents}];
        this.state.dialogIndex = 0;
        this.state.typeIndex = 0;
        this.state.dialogText = this.state.dialog[this.state.dialogIndex].text.replace('[CHARACTER]', GameState.name);
        this.state.canClick = false;

        this.resize();
    }

    componentDidMount = () => {
        super.componentDidMount();

        v.showView('bottomgrad');

        if (this.typeId !== -1) {
            clearInterval(this.typeId);
        }
        this.typeId = setTimeout(this.typeOutMessage, 300);

        if (this.state.dialog[this.state.dialogIndex].character === 'radio') {
            // au.play('radio', 1, false, 0, false);
        } else {
            au.play('tutorial', 1, false, 0, false);
        }

        this.delayClick();

        // BabcockController.pauseCharacter();

        v.get('hudview').fade();
    }

    closePanel = () => {
        if (this.typeId !== -1) {
            clearInterval(this.typeId);
        }
        v.hideView(this.props.viewId);
        WorldController.enable();
        WorldController.interactionEnded(true);
        // BabcockController.unpauseCharacter();

        v.hideView('bottomgrad');

        v.get('hudview').fade(1);
    }

    advanceDialog = () => {
        if (this.state.canClick && this.state.visible) {
            au.play('primarybtn', 1, false, 0, true);

            if (this.state.typeIndex >= this.state.dialogText.length) {
                
                let nextDialogIndex = this.state.dialogIndex + 1;
                // not the ideal place to do this, but it does mean we can change after the first page of dialogue which looks better
                if (GameState.wearingSuit === false && (BabcockController.lastInteraction === BABCOCK_CONSTANTS.HOME || (BabcockController.lastInteraction === BABCOCK_CONSTANTS.BIZ_WEAR_SHOP && this.state.dialogIndex >= this.state.dialog.length - 3)) && GameState.applicationSent === true) {
                    // we are changing into our suit. Defo need a better system for this kind of stuff for the next game!
                    GameState.wearingSuit = true;
                    let suitedCharacterData = BABCOCK_CONSTANTS.CHARACTER_SUITS[GameState.selectedCharacter];
                    WorldController.maincharacterSpriteData = suitedCharacterData;
                    WorldController.mainCharacterEntity.updateSpriteData(suitedCharacterData);

                    // skip the next two dialogues if we are at the biz wear shop
                    if (BabcockController.lastInteraction === BABCOCK_CONSTANTS.BIZ_WEAR_SHOP) {
                        nextDialogIndex = this.state.dialog.length;
                    }
                }

                
                if (nextDialogIndex < this.state.dialog.length) {
                    if (this.typeId !== -1) {
                        clearInterval(this.typeId);
                    }
                    let dialogText = this.state.dialog[nextDialogIndex].text.replace('[CHARACTER]', GameState.name)
                    this.setState({dialogIndex: nextDialogIndex, typeIndex: 0, dialogText: dialogText, canClick: false});
                    this.typeId = setTimeout(this.typeOutMessage, 30);
                    this.delayClick();
                } else {
                    this.closePanel();
                    BabcockController.dialogCompleted();
                }
            } else {
                this.setState({typeIndex: this.state.dialogText.length});
            }
        }
    }

    typeOutMessage = () => {
        let typeIndex = this.state.typeIndex + 1;
        if (typeIndex <= this.state.dialogText.length) {
            this.setState({typeIndex: typeIndex});
            this.typeId = setTimeout(this.typeOutMessage, 10);
        } else {
            this.typeId = -1;
        }
    }

    delayClick = () => {
        // if (this.state.canClick === false) {
            clearTimeout(this.canClickTimeoutId);
            this.canClickTimeoutId = setTimeout(() => this.setState({canClick: true}), 400);
        // }
    }

    renderContent() {
        let iconData = getDialogDataFromId(this.state.dialog[this.state.dialogIndex].character);
        let personIcon = {AdvisorIcon};

        let animate = iconData.id == 'radio' ? true : false;

        // console.log('ANIMATE ASADVISORE:', animate, iconData)
        let charLabel = iconData.ariaLabel;
        if (this.state.dialog[this.state.dialogIndex].character === 'you') {
            if (GameState.wearingSuit) {
                personIcon = iconData.dialogIconSuited[GameState.selectedCharacter];
            } else {
                personIcon = iconData.dialogIcon[GameState.selectedCharacter];
            }
        } else {
            personIcon = iconData.dialogIcon;
        }

        let finalDialogText = this.state.dialogText.substr(0, this.state.typeIndex).split('|').join('<br />');

        let boldSplit = finalDialogText.split('^');
        finalDialogText = boldSplit.join('<b>');
        let boldCloseSplit = finalDialogText.split('~');
        finalDialogText = boldCloseSplit.join('</b>');
        if (boldSplit.length > boldCloseSplit.length) {
            finalDialogText += '</b>';
        }

        return (
            <div className="full-screen-holder no-select default-cursor rel" onClick={this.advanceDialog}>
                <div className="bottom-panels-bg no-select default-cursor" style={{ left: this.state.left, top: this.state.top }}>
                    <div className="flex-columns">
                        <div className="shrink">
                            <img className={`advisor-dialog-icon ${animate ? "bounce" : ""}`} src={personIcon} alt={charLabel} />
                        </div>
                    <div className="grow">
                        <p className="grey-info-text advisor-dialog-text" aria-label={this.state.dialogText.split('|').join('').split('^').join('').split('~').join('')} dangerouslySetInnerHTML={{__html: finalDialogText}}></p>
                    </div>
                    </div>
                    {// this.state.dialogIndex < this.state.dialog.length - 1 &&
                        <div className="dialog-arrow-holder">
                            <img className="dialog-arrow" src={DialogArrow} alt="" />
                        </div>
                    }
                </div>
            </div>
        );
    }
}