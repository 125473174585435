import gsap from 'gsap'
import {Back, Bounce} from 'gsap'
import koko from '../../../koko-framework/koko'
import app from '../../../koko-framework/app'
import { KOKO_DEBUG, kokoDebug } from '../model/config'
import { FRAME_TIME, FRAME_DELTA_LIMIT } from '../model/config'
import { PipeSprite } from './PipeSprite';
import { ASSET_SCALE, TILE_AMOUNT_X,TILE_AMOUNT_Y, TILE_X_NAME, TILE_WIDTH, TILE_HEIGHT, STARTING_ROTATIONS, ROTATE_TIME, STRAIGHT_PIECES, STAR1_SCORE} from '../model/GameConfig'
import * as PIXI from 'pixi.js'
import { setupClicker } from '../../../koko-framework/ui/ButtonController'
import ReactGA from 'react-ga4'

export class PipeDreamController extends koko.view {
    assets = [
        { id: 'pipe_dream_assets', src: 'img/hotel-game/pipe_dream_assets.json' },
        { id: 'pipe_dream_anims', src: 'img/hotel-game/pipe_dream_anims.json' },
    ];

    tileGridArray = [];
    movesTaken = 0;
    clickAllowed = true;

    steam1Anim = null;
    steam2Anim = null;
    steam3Anim = null;
    steam4Anim = null;
    allowAnimation = true;
    gameComplete = false;
    isPaused = false;
    isGameOver = false;
    dripCounter = 0;

    buildView() {
        var g = app.guiSize, vp = app.viewPort;
        this.children = koko.display.buildLayout([
            {name: 'guiSize', type: 'shape', width: g.width, height: g.height, x: 0, y: 0, fill: 0, alpha: 0.2},
            {name:'bg', type:'sprite', id:'gameBk', x:vp.centerX, y:vp.bottom, anchorX: 0.5, anchorY: 1, scale:ASSET_SCALE},
            {name:'tileContainer', type:'container'},
            // { type: 'text', text: 'GAME COMPLETE', font: 'Arial', size: 100, align: 'center', visible:false, tint: 0xFFFFFF, anchorX: 0.5, anchorY: 0.5, name: 'level_progress', x: vp.centerX, y: vp.top + 20},
            // { type: 'text', text: 'GAME COMPLETE', font: 'Arial', size: 100, align: 'center', visible:false, tint: 0xFFFFFF, anchorX: 0.5, anchorY: 0.5, name: 'loadingText', x: vp.centerX, y: vp.top + 80},
            {name: 'blackOverlay', type: 'shape', width: g.width, height: g.height, x: 0, y: 0, fill: 0, alpha: 1},

        ], this.container);

        gsap.to(this.children.blackOverlay, {duration:.8, alpha:0})
        // this.children.loadingText.visible = false;


        gsap.to(this.children.bg.scale, {duration:.3, yoyo:true, repeat:-1, y:ASSET_SCALE+.005})
        this.resize();
        document.addEventListener("unpause", this.unpauseGame);

          koko.audio.play('pp_music',.5, true); 
          koko.audio.play('water_drip',.5, true);
    }

    unpauseGame = () =>{
        this.isPaused = false;
    }

    resize() {

    }

    transitionIn() {

        this.tileGridArray = [];
        this.movesTaken = 0;
        this.clickAllowed = true;
    
        this.steam1Anim = null;
        this.steam2Anim = null;
        this.steam3Anim = null;
        this.steam4Anim = null;
        this.allowAnimation = true;
        this.gameComplete = false;
        this.isPaused = false;
        this.isGameOver = false;
        this.dripCounter = 0;
        
        super.transitionIn();
        app.addResizeListener('gameresize', function(){
            this.resize();
        }.bind(this));

        this.setupTiles();
       app.removeUpdateListener('assetUpdater');           
		app.addUpdateListener('assetUpdater', (time, delta) => { 
            let deltaRatio = 1;
            deltaRatio = delta / FRAME_TIME;
            if (deltaRatio > FRAME_DELTA_LIMIT) {
                deltaRatio = FRAME_DELTA_LIMIT;
            }
            this.update(deltaRatio);
        });

        ReactGA.event({
            category: "Hotel Game",
            action: "Hotel Game - Started",
          });
    }

    setupTiles = () => {

        this.steam1Anim = koko.display.createAnim('steam01_', 8, 4, 0, 1);
        this.steam1Anim.pivot.y =  this.steam1Anim.height;
        //steam1Anim.scale.x = steam1Anim.scale.y = ASSET_SCALE;
        this.steam1Anim.x= -280
        this.steam1Anim.y= 350
        this.steam1Anim.animationSpeed = .5;
        this.steam1Anim.play();
        //this.steam1Anim.alpha = .6;
        this.children.tileContainer.addChild( this.steam1Anim);

        let tileContainerBG = PIXI.Sprite.from('pipeBk.png');
       
        tileContainerBG.pivot.x = tileContainerBG.width/2;
        //tileContainerBG.pivot.y = tileContainerBG.height/2;
        tileContainerBG.scale.x = tileContainerBG.scale.y = ASSET_SCALE;
        this.children.tileContainer.addChild(tileContainerBG);

        let tileGridContainer = new PIXI.Container();

        //1. SETUP THE TILE GRID
        for(let i=0; i< TILE_AMOUNT_X; i++)
        {
            let rowArray = [];
            for(let j=0; j<TILE_AMOUNT_Y; j++)
            {
                let newTileSprite = new PipeSprite();
                //Add the correct tile graphic, position and add it to the container
                let graphicID = TILE_X_NAME.substr(j,1)+ (i+1);
                
                newTileSprite.addGraphic(graphicID);
                newTileSprite.x = i * TILE_WIDTH;
                newTileSprite.y = j * TILE_HEIGHT;
                tileGridContainer.addChild(newTileSprite);
                //Add interaction event handlers to control the user input for each tile
                newTileSprite.interactive = true;
                newTileSprite.on('mousedown',  this.clickHandler);
                newTileSprite.on('tap',  this.clickHandler);

                if(STRAIGHT_PIECES.indexOf(graphicID) != -1) newTileSprite.isStraightPiece = true;

                //Push the tile to the row array
                rowArray.push(newTileSprite);
                console.log(newTileSprite)
            }
            //Push the row to the tile grid
            this.tileGridArray.push(rowArray)
        }
        
        tileGridContainer.pivot.x =  tileGridContainer.width/2;
        tileGridContainer.pivot.y =  tileGridContainer.height/2;
        this.children.tileContainer.addChild(tileGridContainer);

        tileGridContainer.x =  ((this.children.tileContainer.width - tileGridContainer.width)/2)-9;
        tileGridContainer.y = 65 +  this.children.tileContainer.height/2;
        console.log( tileGridContainer.pivot.x, this.children.tileContainer.width, tileGridContainer.x)
        //2. Reposition the tile container so it is in a responsive position
       // this.children.tileContainer.pivot.x =  this.children.tileContainer.width/2;
      //  this.children.tileContainer.pivot.y =  this.children.tileContainer.height/2;
        this.children.tileContainer.x = app.viewPort.centerX;
        this.children.tileContainer.y = app.viewPort.top -  this.children.tileContainer.height;
        gsap.to(this.children.tileContainer, {duration:1, y:app.viewPort.top});


        //3. Start the initial rotations
        let rotationsLeft = STARTING_ROTATIONS;
        while(rotationsLeft > 0)
        {
            let randomTilePos = this.getRandomTile();
            let randomTile =  this.tileGridArray[randomTilePos.x][randomTilePos.y]
            if(randomTile.rotate(true)) rotationsLeft --;
        }


        this.steam2Anim = koko.display.createAnim('steam_', 24, 4, 0, 1);
        this.steam2Anim.pivot.x = 159;
        this.steam2Anim.pivot.y = 301;
        this.steam2Anim.scale.x = this.steam2Anim.scale.y = ASSET_SCALE;
        this.steam2Anim.x=  this.tileGridArray[4][2].x
        this.steam2Anim.y=  this.tileGridArray[4][2].y
        this.steam2Anim.animationSpeed = .5;
        this.steam2Anim.play();
        tileGridContainer.addChild( this.steam2Anim);

        this.steam3Anim = koko.display.createAnim('steam_', 24, 4, 0, 1);
        this.steam3Anim.pivot.x = 159;
        this.steam3Anim.pivot.y = 301;
        this.steam3Anim.scale.x = this.steam3Anim.scale.y = ASSET_SCALE;
        this.steam3Anim.scale.x = -ASSET_SCALE;
        this.steam3Anim.x=  this.tileGridArray[1][3].x
        this.steam3Anim.y=  this.tileGridArray[1][3].y
        this.steam3Anim.animationSpeed = .5;
        this.steam3Anim.gotoAndPlay(5);
        tileGridContainer.addChild( this.steam3Anim);

        this.steam4Anim = koko.display.createAnim('steam05_', 45, 4, 0, 1);
        this.steam4Anim.pivot.x = 76;
        this.steam4Anim.pivot.y = 224;
        this.steam4Anim.scale.x = this.steam4Anim.scale.y = ASSET_SCALE;
        this.steam4Anim.animationSpeed = 1;
        this.steam4Anim.loop = false;

        this.steam4Anim.onComplete = () => {
          // finished!
          this.allowAnimation = true;
        };


        tileGridContainer.addChild( this.steam4Anim);


        this.checkGameComplete();
    }

    getRandomTile = () =>{
        let randomTilePos = {}
        randomTilePos.x = Math.ceil(Math.random()*TILE_AMOUNT_X)-1;
        randomTilePos.y = Math.ceil(Math.random()*TILE_AMOUNT_Y)-1;
        return randomTilePos;
    }
    update(delta)
    {
        if(Math.random() >=.99 &&  this.allowAnimation &&  !this.gameComplete)
        {
           let randomTilePos =  this.getRandomTile();
           this.steam4Anim.x=  this.tileGridArray[randomTilePos.x][randomTilePos.y].x
           this.steam4Anim.y=  this.tileGridArray[randomTilePos.x][randomTilePos.y].y
           this.steam4Anim.scale.x = Math.random()>.5 ? -ASSET_SCALE : ASSET_SCALE;
           this.steam4Anim.gotoAndPlay(1);
           this.allowAnimation = false;
           if(koko.audio.isPlaying('steam'))        koko.audio.play('steam1',1); 
           koko.audio.play('steam',1); 

        }
        this.dripCounter ++
        if(this.dripCounter > 90)
        {
            this.dripCounter = 0;
            let randomDrip = Math.ceil(Math.random()*5)
            koko.audio.play('drip'+randomDrip,1); 
        }
    }

    transitionOutDone = () => {
        // this.stopUpdate();
        app.removeUpdateListener('assetUpdater');
        document.removeEventListener("unpause", this.unpauseGame);

        app.removeResizeListener('gameresize');
        while (app.resizeListeners['gameresize']) {
            app.removeResizeListener('gameresize');
        }
        koko.audio.stop('pp_music');
        koko.audio.stop('water_drip');
        this.destroyView();
        this.hide();
        // super.transitionOutDone();
        // this.destroyView();
        // this.hide();
    }
    
    checkGameComplete = () => {

        let success = true;

        let rotCheck = []

        for(let i=0; i< TILE_AMOUNT_X; i++)
        {
            let rowArray = [];
            for(let j=0; j<TILE_AMOUNT_Y; j++)
            {
                let tileCheck =  this.tileGridArray[i][j];
                if(!tileCheck.isCorrectPosiiton()) success = false;
                rowArray.push(tileCheck.currentRotation);
            }
            rowArray.push('|')
            rotCheck.push(rowArray);
        }
        kokoDebug('GAME COMPLETE: '+success+' MOVES TAKEN SO FAR: '+this.movesTaken);
        kokoDebug(rotCheck)
        this.clickAllowed = true;
        // this.children.level_progress.text = 'TARGET: '+STARTING_ROTATIONS+' | MOVES: '+this.movesTaken;
        if(success || this.movesTaken >= STAR1_SCORE)
        {
            gsap.to(this.steam1Anim, {duration:.5, alpha:0})
            gsap.to(this.steam1Anim.scale, {duration:.5, x:0, y:0, delay:Math.random()*.2})
            gsap.to(this.steam2Anim.scale, {duration:.2, x:0, y:0, delay:Math.random()*.2})
            gsap.to(this.steam3Anim.scale, {duration:.2, x:0, y:0, delay:Math.random()*.2})

       
            gsap.fromTo(this.children.bg.scale, {y:ASSET_SCALE},{duration:.05, yoyo:true, repeat:-1, y:ASSET_SCALE+.003})
            //this.steam1Anim.visible = false;
           //  this.children.loadingText.visible = true;
            this.clickAllowed = false;
            this.gameComplete = true;
            kokoDebug('------------  GAME OVER  ------------');


            koko.views.get('pg_hudView').showGameOver();
            
            setTimeout(()=> {document.dispatchEvent(new CustomEvent("gameover", {bubbles: true, detail: { score: this.movesTaken, stars: koko.views.get('pg_hudView').setStarStatus( this.movesTaken) }}));
        }, 2000)


           // document.dispatchEvent(new CustomEvent("gameover", {bubbles: true, detail: { score: this.movesTaken, stars: koko.views.get('pg_hudView').setStarStatus( this.movesTaken) }}));
            this.isGameOver = true;
            koko.audio.play('pp_level_complete',1);
            koko.audio.stop('pp_music');

            ReactGA.event({
                category: "Hotel Game",
                action: "Hotel Game - Over",
                label: "Hotel Game - Moves",
                value: this.movesTaken
            });

            
        }
    }

    clickHandler = (e) =>{
        if(this.clickAllowed && !this.isPaused && !this.isGameOver)
        {
            koko.audio.play('pipe_turn',1);
            this.clickAllowed = false
            console.log(e.target)
            e.target.rotate();
            this.movesTaken ++;
            koko.views.get('pg_hudView').updateProgressBar( this.movesTaken );
        
            setTimeout(() => { this.checkGameComplete(); }, ROTATE_TIME * 1000 + 100);
        }

    }   
}