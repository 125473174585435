import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import game from '../../../controller/GameController';
import { au, v } from '../../../koko-framework/shorthand';
import WorldController from "../../../controller/WorldController";

import BabcockController from "../../controller/BabcockController";
import * as BABCOCK_CONSTANTS from '../../model/constants';

import SelfieLocationIcon  from '../../../assets/babcock_menus/selfie_location/intro/Selfielocation_temp.png';
import CloseButton from '../../../assets/babcock_menus/close_btn.png';
import TakeSelfieButton from '../../../assets/babcock_menus/selfie_location/intro/takeselfie_btn.png';
import { IsSelfieTaken } from "../../model/GameState";

export default class SelfieLocation extends ReactFullScreenView {
    constructor(props) {
        super(props);

        this.state.selfieTaken = IsSelfieTaken(BabcockController.lastInteraction);
    }

    closePanel = () => {
        v.hideView(this.props.viewId);
        WorldController.enable();

        au.play('closebtn', 1, false, 0, false);
    }

    takeSelfie = () => {
        BabcockController.takeSelfie();
        v.hideView(this.props.viewId);
        v.showView('selfieresult');

        au.play('selfietake', 1, false, 0, false);
    }

    renderContent() {
        let locationData = BABCOCK_CONSTANTS.getLocationDataFromId(BabcockController.lastInteraction);
        return (
            <div className="full-screen-holder black-out no-select default-cursor">
                <div className="gen-white-panel rel">

                    <div className="view-row shrink">
                        <img className="selfie-logo" src={locationData.selfieThumb} alt={locationData.name} />
                    </div>

                    <div className="view-row grow"></div>

                    <div className="view-row shrink">
                        <p className="blue-header bold-text">{locationData.name}</p>
                    </div>

                    <div className="view-row shrink centered">
                        <p className="selfie-blurb">{locationData.description}</p>
                    </div>
                    
                    <div className="view-row grow"></div>
                    <div className="view-row grow"></div>

                    {!this.state.selfieTaken &&
                    <div className="view-row shrink">
                        <img className="take-selfie-button active-button" src={TakeSelfieButton} onClick={this.takeSelfie} alt="Click to take a photo" />
                    </div>
                    }

                    <div className="view-row grow"></div>

                    <div className="abs" style={{right: -10, top: -10}}>
                        <img className="close-button active-button" src={CloseButton} onClick={this.closePanel} alt="Click to close this panel" />
                    </div>
                </div>
            </div>
        );
    }
}