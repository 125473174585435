import React from "react";
import ReactFullScreenView from "../../../koko-framework/ui/react-views/ReactFullScreenView";
import game from '../../../controller/GameController';
import { au, v } from '../../../koko-framework/shorthand';
import ResumeButton from '../../../assets/babcock_menus/settings/resume_btn.png';
import QuitButton from '../../../assets/babcock_menus/settings/quit_btn.png';
import ShareButton from '../../../assets/babcock_menus/settings/share_btn.png';
import SoundOffButton from '../../../assets/babcock_menus/sound_off.png';
import SoundOnButton from '../../../assets/babcock_menus/sound_on.png';
import CTA from '../../../assets/babcock_menus/settings/CTA.png';
import Divider from '../../../assets/babcock_menus/settings/divider.png';
import WorldController from "../../../controller/WorldController";
import { MuteButton } from "./components/mute-button";
import BabcockController from "../../controller/BabcockController";

import ReactGA from 'react-ga4';

export default class SettingsPanel extends ReactFullScreenView {
    componentDidMount() {
        super.componentDidMount();

        BabcockController.pauseCharacter();

        ReactGA.send({ hitType: "pageview", page: "/settings-panel/" });
    }

    closePanel = () => {
        v.hideView('settings');
        WorldController.enable();

        au.play('primarybtn', 1, false, 0, false);

        // skip intro
        // BabcockController.skipIntroPhase();

        BabcockController.unpauseCharacter();
    }

    showCtaInfo = () => {
        v.showView('ctainfo');

        au.play('secondarybtn', 1, false, 0, false);
    }

    showSharePanel = () => {
        v.showView('sharepanel');
    }

    quitGame = () => {
        v.hideView('settings');
        v.hideView('greenbg');
        au.stop('babcock_ingame_music');
        WorldController.deactivate();
        au.play('primarybtn', 1, false, 0, false);
        v.showView('mainmenu');
    }

    renderContent() {
        return (
            <div className="full-screen-holder black-out no-select default-cursor rel">
                <div className="settings-panel">
                    <div className="view-row grow"></div>
                    <div className="view-row shrink">
                        <p className="blue-header bold-text">Game Settings</p>
                    </div>
                    <div className="view-row shrink centered">
                        <p className="settings-blurb">The game is currently paused.<br />Press 'Resume Game' when you<br />are ready to continue or exit<br />back to the main menu.</p>
                    </div>
                    <div className="view-row shrink">
                        <img className="settings-resume-button active-button" src={ResumeButton} onClick={this.closePanel} alt="Click to resume the game" />
                    </div>
                    <div className="view-row shrink">
                        <div className="flex-columns settings-button-group">

                            <div className="grow">
                                <MuteButton settings={true}></MuteButton>
                            </div>
                            
                            <div className="grow">
                                <img className="settings-share-button active-button" src={ShareButton} onClick={this.showSharePanel} alt="Click to Share on Social Media" />
                            </div>

                            <div className="grow">
                                <img className="settings-quit-button active-button" src={QuitButton} onClick={this.quitGame} alt="Click to quit back to the main menu" />
                            </div>

                        </div>
                    </div>
                    <div className="view-row grow">
                        <img className="settings-divider" src={Divider} alt="" />
                    </div>
                    <div className="view-row grow">
                        <img className="settings-cta active-button" src={CTA} onClick={this.showCtaInfo} alt="Click to find out more about Babcock Apprenticeships" />
                    </div>
                    <div className="view-row grow"></div>
                </div>
            </div>
        );
    }
}