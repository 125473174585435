// Surround text with ^ and ~ to make it bold (eg. 'Some non-bold and ^bold~ text')

import PromptImg1 from '../../assets/babcock_menus/prompts/01_babcock.png';
import PromptImg2 from '../../assets/babcock_menus/prompts/02_tap.png';
import PromptImg3 from '../../assets/babcock_menus/prompts/03_longpress.png';
import PromptImg4 from '../../assets/babcock_menus/prompts/04_prompts.png';
import PromptImg5 from '../../assets/babcock_menus/prompts/01.png';
import PromptImg6 from '../../assets/babcock_menus/prompts/05_orbs.png';

import GameMarkersPrompt from '../../assets/babcock_menus/prompts/04b_gamemarkers.png';

export const INTRO_PROMPTS = [
    'Find the ^Skills Launchpad~ to look for your dream job',
    '^Tap~ to move your character around the scene or ^long press~ to keep your character moving in that direction',
    // 'Long press to keep your character moving in that direction',
    'Look out for ^locations~, ^items~ and ^people~ around the map you can interact with. ^Find the bus stop~ and travel to the City Centre to find the ^Skills Launchpad!~',
    'Follow the dots to reach the ^Skills Launchpad~'
]

export const INTRO_PROMPT_IMAGES = [
    PromptImg1,
    PromptImg2,
    // PromptImg3,
    PromptImg4,
    PromptImg6,
]

export const INTRO_PROMPT_ARIA_LABELS = [
    'Skills Launchpad Office',
    'Tap to move',
    // 'Long press',
    'Interaction',
    'Follow the dots',
]

export const CV_INTRUCTION_PROMPTS = [
    'Complete your CV by exploring the surrounding area. You must complete at least the 3 Experience tasks to submit your application',
    'Look for the important action prompts which will help guide you along the way',
    'Markers will help guide you to the game locations as you explore the map.',
]

export const CV_INSTRUCTION_PROMPT_IMAGES = [
    PromptImg5,
    PromptImg4,
    GameMarkersPrompt,
]

export const CV_INTRUCTION_ARIA_LABELS = [
    'Complete your cv',
    'Interaction',
    'Game markers',
]